/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Checkbox
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const data = this.props.data
        let kind_list = {}
        let kind_arr = []
        for (let i in data.items) {
            let item = Utility.cloneObjectJson(data.items[i])
            const product = this.props.product_list.find(k => k.id === item.product)
            const branch = this.props.branch_list.find(k => k.id === item.branch)
            const kind = this.props.kind_list.find(k => k.id === product.kind)
            item['product'] = product
            item['branch'] = branch
            if (kind_list[kind.id] == null)
                kind_list[kind.id] = {
                    items: [],
                    kind_name: kind.name,
                    n: 0,
                    weight: 0,
                    type_sale:item.product.type_sale
                }
            kind_list[kind.id].n += parseInt(item.after)
            kind_list[kind.id].weight += parseInt(item.after) * parseFloat(product.weight)
            kind_list[kind.id].items.push(item)
        }
        
        for (let i in kind_list) {
            let is_title = true
            let is_first_next = true

            kind_list[i].items.sort(function(first, second) {
                if ( first.product.code < second.product.code ){
                    return -1;
                  }
                  if ( first.product.code > second.product.code ){
                    return 1;
                  }
                  return 0;
            });
            kind_list[i].items.sort(function(first, second) {
                return first.product.weight - second.product.weight;
            });
            let total_after = 0
            let total_weight = 0
            
            let summary_total_after = 0
            let summary_total_weight = 0
            for (let j in kind_list[i].items) {
                summary_total_after += Number(kind_list[i].items[j].after)
                summary_total_weight += Number(kind_list[i].items[j].after) * Number(kind_list[i].items[j].product.weight)
                if (is_title)
                    kind_arr.push({
                        is_title: is_title,
                        title: kind_list[i].kind_name
                    });
                is_title = false

                if (kind_list[i].items[j].product.category == 1){
                    kind_list[i].items[j].is_exclude = true
                }
                kind_arr.push(kind_list[i].items[j])

                    if(j == 0){
                        total_after = parseInt(kind_list[i].items[j].after)
                        total_weight = parseInt(kind_list[i].items[j].after) * parseFloat(kind_list[i].items[j].product.weight)
                    } else if(j!=0){
                        if(Number(kind_list[i].items[j].product.weight) != Number(kind_list[i].items[Number(j)-1].product.weight)){
                            total_after = parseInt(kind_list[i].items[j].after)
                            total_weight = parseInt(kind_list[i].items[j].after)* parseFloat(kind_list[i].items[j].product.weight)
                        } else {
                        total_after += parseInt(kind_list[i].items[j].after)
                        total_weight += parseInt(kind_list[i].items[j].after)* parseFloat(kind_list[i].items[j].product.weight)
                        }
                        
                    }

                    if(Number(j)+1 < kind_list[i].items.length){
                        if(Number(kind_list[i].items[j].product.weight) != Number(kind_list[i].items[Number(j)+1].product.weight)){
                            if (kind_arr[kind_arr.length-1].product.category != 1){
                                kind_arr[kind_arr.length-1].is_first = true
                                kind_arr[kind_arr.length-1].product.name_first = kind_arr[kind_arr.length-1].product.name.split('(')[0]
                                kind_arr[kind_arr.length-1].product.code_first = kind_arr[kind_arr.length-1].product.code.split('-')[0]
                                is_first_next = false
                            }
                            kind_arr[kind_arr.length-1].total_after = total_after
                            kind_arr[kind_arr.length-1].total_weight = total_weight

                            if (this.props.group != true)
                                kind_arr.push({
                                    is_sum:true,
                                    total_after:total_after,
                                    total_weight:total_weight
                                })     
                        }
                    } else {
                        if (kind_arr[kind_arr.length-1].product.category != 1){
                            kind_arr[kind_arr.length-1].is_first = true
                            kind_arr[kind_arr.length-1].product.name_first = kind_arr[kind_arr.length-1].product.name.split('(')[0]
                            kind_arr[kind_arr.length-1].product.code_first = kind_arr[kind_arr.length-1].product.code.split('-')[0]
                            is_first_next = false
                        }
                        kind_arr[kind_arr.length-1].total_after = total_after
                        kind_arr[kind_arr.length-1].total_weight = total_weight
                        
                        if (this.props.group != true)
                            kind_arr.push({
                                is_sum:true,
                                total_after:total_after,
                                total_weight:total_weight
                            })
                    }
            }
            kind_arr.push({
                is_sum_total:true,
                sum:'รวม ' + kind_list[i].kind_name,
                summary_total_after:summary_total_after,
                summary_total_weight:summary_total_weight,
            })

        }
        console.log(kind_arr)

        return (<Table.Body>
            <Table.Row>
                <Table.Cell colSpan='2' style={textLeft}><b>{data.title}</b></Table.Cell>
                <Table.Cell colSpan='5'></Table.Cell>
            </Table.Row>
            {kind_arr.map((row, i) => row.is_title ? <Table.Row>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textLeft}>{row.title}</Table.HeaderCell>
                <Table.HeaderCell colSpan='5'></Table.HeaderCell>
            </Table.Row> : 
            row.is_sum ? <Table.Row>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textRight}><b>รวม</b></Table.HeaderCell>
            <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(row.total_after)}</div></Table.HeaderCell>
            <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(row.total_weight)}</div></Table.HeaderCell>
        </Table.Row> :
        row.is_sum_total?
        <Table.Row>
        <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell style={textRight}><b>{row.sum}</b></Table.HeaderCell>
            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.numberFormat(row.summary_total_after)}</b></Table.HeaderCell>
            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.weightFormat(row.summary_total_weight)}</b></Table.HeaderCell>
    </Table.Row>: this.props.group != true ?
            <Table.Row id={row.product.code}>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textLeft}>&nbsp;&nbsp;{row.product.name}</Table.HeaderCell>
                <Table.HeaderCell style={textCenter}>{row.product.code}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.weightFormat(row.product.weight)}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.numberFormat(row.after)}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.weightFormat(Utility.removeCommas(row.after) * Utility.removeCommas(row.product.weight))}</Table.HeaderCell>
                <Table.HeaderCell style={textCenter}>&nbsp;&nbsp;{row.branch.name}</Table.HeaderCell>
            </Table.Row>: row.is_first ?
            <Table.Row id={row.product.code}>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textLeft}>&nbsp;&nbsp;{row.product.name_first}</Table.HeaderCell>
                <Table.HeaderCell style={textCenter}>{row.product.code_first}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.weightFormat(row.product.weight)}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.numberFormat(row.total_after)}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.weightFormat(row.total_weight)}</Table.HeaderCell>
                <Table.HeaderCell style={textCenter}>&nbsp;&nbsp;{row.branch.name}</Table.HeaderCell>
            </Table.Row> : row.is_exclude ?
            <Table.Row id={row.product.code}>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textLeft}>&nbsp;&nbsp;{row.product.name}</Table.HeaderCell>
                <Table.HeaderCell style={textCenter}>{row.product.code}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.weightFormat(row.product.weight)}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.numberFormat(row.after)}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}>{Utility.weightFormat(Utility.removeCommas(row.after) * Utility.removeCommas(row.product.weight))}</Table.HeaderCell>
                <Table.HeaderCell style={textCenter}>&nbsp;&nbsp;{row.branch.name}</Table.HeaderCell>
            </Table.Row> : null
                
            )}
            <Table.Row>
                <Table.HeaderCell colSpan='4' style={textRight}>รวม {data.title}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}><div id={'amount_' + data.id} style={textU}>{Utility.numberFormat(data.n)}</div></Table.HeaderCell>
                <Table.HeaderCell style={textRight}><div id={'weight_' + data.id} style={textU}>{Utility.weightFormat(data.weight)}</div></Table.HeaderCell>
                <Table.HeaderCell ></Table.HeaderCell>
            </Table.Row>

        </Table.Body>)
    }
}


class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                date_lt: moment(),
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
            },
            items: [],
            group: false,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([branches, staffs, product_types]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }

            this.product_types = values[2];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                product_types: product_types
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_date = url.searchParams.get("date_lt");
        if (temp_date !== null) {
            this.setState({
                search: {
                    date_lt: moment(temp_date, 'DD/MM/YYYY'),
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                date_lt: moment(),
                branch: this.props.auth.branch.id,
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date_lt = Utility.formatDate2(search.date_lt)

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/stock_product_report/0/items?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.stock_product),
                    product: this.setFieldValue(d.product),
                    category: this.setFieldValue(d.category),
                    kind: this.setFieldValue(d.kind),
                    branch: this.setFieldValue(d.branch),
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            items.push(item)
        }
        return items
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let data = this.props.data

        let title = 'รายงานสต็อกสินค้า';
        let filename = 'sell_type_amount';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let amount = 0
        //let weight = 0
        let weight_b = 0
        let sell = 0
        let net = 0
        let category_list = {}
        let n=0
        let weight = 0
        for (let i in this.state.items) {

            let item = this.state.items[i]
            const product = this.state.product.find(k => k.id === item.product)
            const category = this.state.category.find(k => k.id === product.category)

            if (category_list[product.category] == null)
                category_list[product.category] = {
                    items: [],
                    items_code: [],
                    title: category.name,
                    id: product.category,
                    n: 0,
                    weight: 0
                }

            if (!category_list[product.category].items_code.includes(product.code)) {
                category_list[product.category].n += parseInt(item.after)
                category_list[product.category].weight += parseInt(item.after) * parseFloat(product.weight)
                category_list[product.category].items.push(item)
                category_list[product.category].items_code.push(product.code)

                weight+=(parseInt(item.after) * parseFloat(product.weight))
                n+= parseInt(item.after)
            }
        }

        let items2 = []
        for (let i in category_list) {
            items2.push(category_list[i])
        }

        let sum_total = 0;
        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }
        console.log(weight,n)
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสต็อกสินค้า</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.date_lt}
                                    selected={this.state.search.date_lt}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'date_lt',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field
                                control={Checkbox}
                                label='สรุปจำนวน'
                                // value='1'
                                checked={this.state.group}
                                onChange={(e, data) => {
                                    this.setState({ group: !this.state.group });
                                }}
                            />
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='7'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='7'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='7'><center id='date'>ประจำวันที่ : {Utility.formatDate(this.state.search.date_lt)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2' >ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >รหัสสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น.น./ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >สาขา</Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Header>

                                    {items2.map((row, i) => <GroupRow key={i} data={row} group={this.state.group} product_list={this.state.product} kind_list={this.state.kind} branch_list={this.state.branch}  />)}
                                    
                                    <Table.Row>
                                    <Table.Cell colSpan='12' style={textRight}></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                    <Table.HeaderCell colSpan='3' style={textRight}></Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>รวมทั้งหมด</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(n)}</div></Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(weight)}</div></Table.HeaderCell>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)