/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import DatePicker from 'react-datepicker';
import Settings from '../../../Settings';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            number: '',
            amount: 0,
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
    }

    resetForm(e) {
        this.setState({
            search: {
                number: '',
                start_date: moment(),
                end_date: moment(),
            },
            number: ''
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let q = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/savings/?full_number=" + this.state.number.trim()
        this.setState({
            loading: true,
            number: '',
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                if (d.length == 1) {
                    this.setState({
                        saving_number: d[0].number,
                        saving_name: d[0].name,
                        open: true,
                    })
                    let url_item = Settings.baseReportUrl + "/saving/" + d[0].id + "/item/?columnKey=record_date&sortDi=ASC&" + q
                    Utility.get(url_item, (s_item, d_item) => {
                        if (s_item == true) {
                            this.setState({
                                items: this.setFieldValue(d_item),
                                loading: false,
                            })
                        }
                    });
                } else {
                    alert("ไม่พบเลขบัญชีนี้")
                    this.setState({
                        loading: false,
                    })
                }
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    setFieldValue(_items) {


        let items = []
        let total = 0
        for (let i in _items) {
            let item = _items[i]
            item.date = Utility.formatDate(item.record_date)
            item.branch_name = item.branch.name
            if (item.kind == 'O' || item.kind == 'D') {
                item.deposit = item.amount
                item.withdraw = 0
            } else if (item.kind == 'W' || item.kind == 'C') {
                item.deposit = 0
                item.withdraw = item.amount
            }
            item.balance = item.balance
            item.staff_name = item.staff.name
            items.push(item)
        }
        this.setState({ total: total })

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let title = 'รายงานธุรกรรมแยกรายบุคคล (statement)';
        let filename = 'statement-' + this.state.saving_number + '-' + Utility.formatDate3(this.state.search.start_date) + '-to-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let deposit_total = 0
        let withdraw_total = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานธุรกรรมแยกรายบุคคล</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>เลขบัญชีออมทอง</label>
                                <Input id='number' fluid onChange={(e, data) => {
                                    this.setState({ number: data.value });
                                }}
                                    name="number"
                                    placeholder='เลขบัญชีออมทอง'
                                    value={this.state.number} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center id='number'>เลขที่บัญชี : {this.state.saving_number}</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='3'><center id='name'>ชื่อบัญชี : {this.state.saving_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินถอน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>คงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ผู้ทำรายการ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((row, i) => {
                                            deposit_total += Number(row.deposit)
                                            withdraw_total += Number(row.withdraw)
                                            return (<Table.Row key={i}>
                                                <Table.Cell style={textCenter}>{row.date}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.branch_name}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.kind_display}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(row.deposit)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(row.withdraw)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(row.balance)}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.staff_name}</Table.Cell>
                                            </Table.Row>)
                                        })}
                                        <Table.Row >
                                            <Table.Cell colSpan='3' style={textRight}><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(deposit_total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(withdraw_total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total' style={textU}><b>{this.state.items.length} รายการ</b></div></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)