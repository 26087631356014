/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../../Settings';
import Utility from '../../../../Utility';
import {
    Button,
    Modal,
    Pagination,
    Dimmer,
    Loader,
    Popup,
} from 'semantic-ui-react';

import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';


class ItemsCell extends Component {
    constructor(props) {
        super(props);


    }
    render() {
        const { rowIndex, field, data, ...props } = this.props;
        const type = this.props.dataType | 'string'
        let value = data[rowIndex][field]

        if (type == 'number')
            value = Utility.numberFormat(value)
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }
        return (
            <Cell {...props}>
                <Popup
                    position={this.props.position}
                    content={value}
                    style={Width300}
                    trigger={
                        <div style={ellipsisStyle} className={this.props.className}>{value}</div>}
                />
            </Cell>
        );
    }
}

class StockRewardItemModal extends Component {
    constructor(props) {
        super(props);
        this.page_size = 6
        this.state = {
            items: [],
            activePage: 1,
            table_width: 100,
            table_hiegh: 100
        }


        this.handlePaginationChange = this.handlePaginationChange.bind(this)
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ activePage: activePage })
        this.loadData(activePage)
    }


    componentDidMount() {
        this.loadData(this.state.activePage)

        let elHeight = document.getElementById('modalStockRewardItem')
        this.setState({
            table_width: window.innerWidth,
            table_hiegh: window.innerHeight - 250
        });
    }

    loadData(page) {

        var q = Utility.jsonToQueryString({
            page: page,
            page_size: this.page_size,
            stock_reward: this.props.stock_reward.id
        })
        this.setState({ loader_active: true })
        Utility.get(Settings.baseUrl + '/stock_reward_item/?' + q, (s, r, c) => {



            this.setState({
                loader_active: false,
                items: this.setFieldValue(r.results),
                totalPages: r.next == null && r.previous == null ? 0 : Math.ceil(r.count / this.page_size),
            })


        });
    }

    setFieldValue(_items) {
        let items = []

        for (let i in _items) {
            var item = _items[i]

            item['reward_code'] = this.props.stock_reward.reward.code
            item['reward_name'] = this.props.stock_reward.reward.name
            item['date'] = Utility.formatDate(item.record_date)
            item['time'] = Utility.formatTime(item.record_date)
            item['by'] = item.user.username
            if (item.kind == 'E')
                item['redeem_code'] = item.redeem.code
            item['amount_in'] = 0
            item['amount_out'] = 0
            if (item.kind == 'I')
                item['amount_in'] = item.amount
            else
                item['amount_out'] = item.amount

            items.push(item)
        }

        return items
    }

    componentDidUpdate() {

    }

    render() {

        const items = this.state.items

        return (
            <div >
                <Modal open={true} size='large' /*dimmer='blurring'*/  >
                    <Button
                        circular
                        icon='close'
                        basic
                        floated='right'
                        name=''
                        onClick={() => this.props.onClose()} />
                    <Modal.Header>ประวัติ : {this.props.stock_reward.reward_name}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description id="modalStockRewardItem">
                            <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                                <Loader content='Loading' inverted />
                            </Dimmer>
                            <Table
                                rowsCount={items.length}
                                rowHeight={60}
                                headerHeight={35}
                                width={this.state.table_width}
                                height={this.state.table_hiegh}>
                                <Column
                                    header={<Cell>วันที่</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={items} field="date" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>เวลา</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={60} data={items} field="time" />
                                    }
                                    width={60}
                                />
                                <Column
                                    header={<Cell>รายการ</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={items} field="kind_display" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>รหัสแลก</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={items} field="redeem_code" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell className="text-right">จ.น. เริ่มต้น</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={80} data={items} className='text-right pink' dataType='number' field="before" />
                                    }
                                    width={80}
                                />
                                <Column
                                    header={<Cell className="text-right text-green">จ.น. นำเข้า</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={80} data={items} className='text-right blue' dataType='number' field="amount_in" />
                                    }
                                    width={80}
                                />
                                <Column
                                    header={<Cell className="text-right">จ.น. นำออก</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={80} data={items} className='text-right red' dataType='number' field="amount_out" />
                                    }
                                    width={80}
                                />
                                <Column
                                    header={<Cell className="text-right">จ.น. คงเหลือ</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={28} data={items} className='text-right green' dataType='number' field="after" />
                                    }
                                    width={80}
                                />
                                <Column
                                    header={<Cell>โดย</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={items} field="by" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>หมายเหตุ</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={400} data={items} field="description" />
                                    }
                                    width={400}
                                />
                            </Table>
                            <br />
                            {this.state.totalPages > 0 && <Pagination
                                activePage={this.state.activePage}
                                onPageChange={this.handlePaginationChange}
                                totalPages={this.state.totalPages}
                            />}
                        </Modal.Description>
                    </Modal.Content>

                </Modal>
            </div>

        );
    }
}

export default StockRewardItemModal;
