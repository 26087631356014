/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import { connect } from 'react-redux'
import {
  Form,
  Input,
  Button,
  Modal
} from 'semantic-ui-react';
import {loadLedgerCategory} from '../../actions'

class LedgerCategoryForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            msg_error: {},
        }
        this.submitLedgerCategory = this.submitLedgerCategory.bind(this)
    }


  async submitLedgerCategory(e) {
    e.preventDefault()

    let data = {
      title: this.state.ledger_category_name,
      kind: this.state.ledger_category_kind
    }
    let msg_error = {}
    
    if(data.title === '' || data.title === undefined){
      msg_error['title'] = ['*กรุณาเลือก รายการ']
    }
    if(data.kind === undefined){
      msg_error['kind'] = ['*กรุณาเลือก เลือกประเภท']
    }
    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error
      })
      return;
    }
    
    else{
      const url = Settings.baseUrl + '/ledger_category/';
      this.setState({msg_error: {}})
      Utility.post(url, data, (s, d) => {

        if(s){
          this.props.onSave(d)
          this.props.handleLedgerCategoryChanges()
        }
        else{
          this.setState({
            msg_error: d
          })
        }

      })
    }

    
  }
    render(){

        return (
            <Modal size='tiny' open={true} /*dimmer='blurring'*/>
            <Button circular icon='close' basic floated='right' name='' onClick={this.props.onClose}/>
            <Modal.Header>
              ตั้งค่ารายรับ-รายจ่าย
          </Modal.Header>
            <Modal.Content scrolling>
              <Form>
                <Form.Field error={this.state.msg_error.title != null}>
                  <label>รายการ  <MsgInput text={this.state.msg_error.title} /></label>
                  <Input id='order-name' onChange={this.handlerModalInputChange} value={this.state.ledger_category_name} name='รายการ' ref='ledger_category_name'
                    onChange={(e, d) => { this.setState({ ledger_category_name: d.value }) }} maxLength={255}/>
                </Form.Field>
                <Form.Field error={this.state.msg_error.kind != null}>
                  <label>ประเภท  <MsgInput text={this.state.msg_error.kind} /></label>
                  <Form.Dropdown id='order-type' search selection name="kind" value={this.state.ledger_category_kind}
                  options={Settings.ledger_kind_option}
                  onChange={(e, d) => { this.setState({ ledger_category_kind: d.value }) }} />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button id='order-save' primary loading={this.state.loadingSubmitledgerCategory} onClick={this.submitLedgerCategory}>
                บันทึก
            </Button>
            </Modal.Actions>
          </Modal>
        )
    }
}


const mapStateToProps = state =>{
  return ({
    auth: state.auth,
    branches: state.branches
  })
}

const mapDispatchToProps =  dispatch => ({
  handleLedgerCategoryChanges: async() => {
    loadLedgerCategory(dispatch)
  }
})

export default connect(
  mapStateToProps,mapDispatchToProps
)(LedgerCategoryForm)