/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class RewardPrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }
            
            

            
            this.setState({
                loader_active: false,
                branches: branches,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)

        // let url = Settings.baseReportUrl + "/bill_items/?kind=SE,XC&status_stock=Y&" + fromData

        this.setState({
            open: true,
            // loading: true
        })
        // Utility.get(url, (s, d) => {
        //     console.log(d)
        //     if (s == true) {
        //         this.setState({
        //             loading: false,
        //             items: this.setFieldValue(d)
        //         })
        //     }
        // })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.branch) {
                if (item.bill.branch.id != this.state.search.branch)
                    continue;
            }
            if (this.state.search.categories) {
                if (item.product.category.id != this.state.search.categories)
                    continue;
            }
            if (this.state.search.product_type) {
                if (item.product.kind.id != this.state.search.product_type)
                    continue;
            }
            if (this.state.search.pattern) {
                if (item.product.pattern.id != this.state.search.pattern)
                    continue;
            }
            if (this.state.search.products) {
                if (item.product.id != this.state.search.products)
                    continue;
            }
            if (this.state.search.type_sale) {
                if (item.product.type_sale != this.state.search.type_sale)
                    continue;
            }

            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
        if (v.name == 'categories') {
            this.resetCategory(v.value);
        }
    }

    resetCategory(category_id) {
        let type = [];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_type: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_type: type });
        }
    }
    render() {


        let title = 'รายงานแลกของรางวัล';
        let filename = 'reward';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let bill_number = {}
        for(let i in this.state.items){
            let item = this.state.items[i]
            if (bill_number[item.bill.bill_number] == null){
                bill_number[item.bill.bill_number] = {
                    items: [],
                    title: item.bill.bill_number,
                    record_date: item.bill.record_date,
                    kind:item.bill.kind,
                    kind_display:item.bill.kind == "SE" ? "ขายทอง" : "เปลี่ยนทอง",
                    total:item.bill.total
                }
            }
            bill_number[item.bill.bill_number].items.push(item)
        }

        let items = []
        for (let i in bill_number) {
            let total = 0
            let discount_product_value = 0
            let total1 = parseFloat(bill_number[i].total)
            let total_fee = 0
            let diff = 0
            let tax_value = 0
            if(bill_number[i].kind == "XC") {
                for(let j in bill_number[i].items){
                    if(bill_number[i].items[j].kind == "SE"){
                        discount_product_value += parseFloat(bill_number[i].items[j].sell) - parseFloat(bill_number[i].items[j].fee)
                    }
                }
            } else {
                for(let j in bill_number[i].items){
                    if(bill_number[i].items[j].kind == "SE"){
                        total_fee += parseFloat(bill_number[i].items[j].fee)
                    }
                }
            }
            total = total1 + discount_product_value
            discount_product_value = bill_number[i].kind == "XC" ? discount_product_value : total - total_fee
            diff = bill_number[i].kind == "XC" ? total1 : total_fee
            diff = parseFloat((diff * 100 / 107).toFixed(2))
            tax_value = parseFloat((diff * 0.07).toFixed(2))
            bill_number[i].total = total
            bill_number[i].discount_product_value = discount_product_value
            bill_number[i].diff = diff
            bill_number[i].tax_value = tax_value
            bill_number[i].product_value = parseFloat(total - tax_value)
            items.push(bill_number[i])
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานแลกของรางวัล</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        {/* <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row> */}
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft}>No.</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>วันที่ทำรายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่ใบสั่งซื้อ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>แต้ม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าจัดส่ง</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {/* {items.map((row,i)=>
                                        {
                                            console.log(row.total)
                                            return (
                                                <Table.Row >
                                                    <Table.Cell style={textLeft}>{row.title}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row.kind_display}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.product_value)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.discount_product_value)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.diff)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.tax_value)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.total)}</Table.Cell>
                                                </Table.Row>
                                            
                                            );
                                        }
                                    )} */}
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>
                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(RewardPrintPreview)
