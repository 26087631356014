const default_state = {
    is_ready: false,
    list: [],
    filter: {}
  
  }
  const report_email_list = (state = default_state, action) => {
    switch (action.type) {
      case 'SET_REPORT_EMAIL_LIST':
        return {
          ...state,
          is_ready: true,
          list: action.list,
        }
        default:
          return state
    }
  }
  
  export default report_email_list