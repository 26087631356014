/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Header, Button, Loader, Dimmer, Icon, Input, Pagination,
  Popup,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';

import OptionItemsCell from '../OptionItemsCell'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import BillModal from './BillModal';

function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + (data[rowIndex]['is_void'] == true ? 'cell_red' : '') + ' text-cell'}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class IconItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <ContextMenuTrigger id="menu_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Icon name={data[rowIndex].status_stock == 'N' ? 'warning circle' : 'check circle'} color={data[rowIndex].status_stock == 'N' ? 'yellow' : 'green'} />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class Transfer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_detail: '',
      start_date: moment(),
      end_date: moment(),
      is_clear_stock: false,
      is_clear_bill: false,
      total_item: 0,
      table_hiegh: Settings.table_hiegh,
      branch: 0,
      page: 1,
      totalPages: 1,
      qrstring: '',
      colSortDirs: {
        'number': 'ASC',
      },
      counter:0,
    }
    this.columnKey = 'number'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.onClickitem = this.onClickitem.bind(this);
    this.loaddata = this.loaddata.bind(this);
    this.loaddata2 = this.loaddata2.bind(this);
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loaddata2(activePage)
  }

  componentDidMount() {
    this.loaddata(1)

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };
  
  loaddata(page) {
    var self = this;
    this.setState({
      loader_active: true,
      total_item: 0
    });

    var s = '';
    if (this.state.end_date != null && this.state.end_date != '') {
      s += (s == '' ? '' : '&') + 'end_date=' + Utility.formatDate2(this.state.end_date);
    } else {
      this.setState({ 'end_date': this.state.start_date })
      s += (s == '' ? '' : '&') + 'end_date=' + Utility.formatDate2(this.state.start_date);
    }
    if (this.state.start_date != null && this.state.start_date != '') {
      s += (s == '' ? '' : '&') + 'start_date=' + Utility.formatDate2(this.state.start_date);
    }

    if (this.state.branch != null && this.state.branch != '') {
      s += (s == '' ? '' : '&') + 'branch=' + this.state.branch;
    }

    if (this.state.is_clear_stock) {
      s += (s == '' ? '' : '&') + 'status_stock=N';
    }

    if (this.state.is_clear_bill) {
      s += (s == '' ? '' : '&') + 'status_bill=N';
    }
    this.setState({ qrstring: s })

    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_export/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + s + '&page=' + page);
    Promise.all([branches, invoices]).then((values) => {
      this.branches = values[0];
      let branches = [{
        key: 0,
        value: 0,
        text: '- ทั้งหมด -',
      }];
      for (let i in values[0]) {
        if (values[0][i].is_enabled == 1)
          branches.push({
            key: values[0][i].id,
            value: values[0][i].id,
            text: values[0][i].name,
          });
      }

      var items = this.setFieldValue(values[1].results);
      this.setState({
        branches: branches,
        items: items,
        loader_active: false,
        total_item: items.length,
        totalPages: values[1].next == null && values[1].previous == null ? 0 : Math.ceil(values[1].count / 10),
        counter: values[1].count
      });
      let elHeight = document.getElementById('table_width')
      this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 560 });
    });
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        start_date: moment(temp_start, 'DD/MM/YYYY'),
        end_date: moment(temp_end, 'DD/MM/YYYY')
      })
    }
  }

  loaddata2(page) {
    var self = this;
    this.setState({
      loader_active: true,
      total_item: 0
    });

    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_export/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + this.state.qrstring + '&page=' + page);
    Promise.all([branches, invoices]).then((values) => {
      this.branches = values[0];
      let branches = [{
        key: 0,
        value: 0,
        text: '- ทั้งหมด -',
      }];
      for (let i in values[0]) {
        if (values[0][i].is_enabled == 1)
          branches.push({
            key: values[0][i].id,
            value: values[0][i].id,
            text: values[0][i].name,
          });
      }

      var items = this.setFieldValue(values[1].results);
      this.setState({
        branches: branches,
        items: items,
        loader_active: false,
        total_item: items.length,
        totalPages: values[1].next == null && values[1].previous == null ? 0 : Math.ceil(values[1].count / 10),
        counter: values[1].count
      });
      let elHeight = document.getElementById('table_width')
      this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 400 });
    });
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        start_date: moment(temp_start, 'DD/MM/YYYY'),
        end_date: moment(temp_end, 'DD/MM/YYYY')
      })
    }
  }

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]['status_stock_title'] = Settings.status_stock[item[i].status_stock]
      item[i]['status_bill_title'] = Settings.status_bill[item[i].status_bill]
      if (item[i].status_stock == 'Y')
        item[i]['update_date_title'] = Utility.formatDate(item[i].update_date)
      item[i]['invoice_date_title'] = Utility.formatDate(item[i].invoice_date)
      item[i]['branche_title'] = Utility.getObject(this.branches, item[i].branch).name
    }
    return item;
  }

  clearFormSearch() {
    this.setState({
      branch: 0,
      start_date: moment(),
      end_date: moment(),
      is_clear_stock: false,
      is_clear_bill: false
    })
  }

  onClickitem(e, d) {
    let data = this.state.items[d.positon];

    e.preventDefault();
    this.setState({
      invoice_detail: data,
      bill_number: data.number,
      action: 'edit',
      open_invoice: true
    });
  }

  resetForm() {

  }

  render() {
    let items = this.state.items;
    return (

      <div className='invoice-box'>

        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>นำออกทองใหม่</Header>
            </Form.Field>
            <Form.Field width={10}>
              <Button id='btnCreateTransfer' size='small' content={<span> สร้างใบนำออกทองใหม่</span>} onClick={() => {
                this.setState({ open_invoice: true, action: 'add' });
              }} floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>

        <ContextMenu id="menu_list">
          <MenuItem data={{ action: 'edit' }} onClick={this.onClickitem}>
            แก้ไข
        </MenuItem>
        </ContextMenu>
        <Form className='attached fluid' size='small'>
          <Form.Group>
            <Form.Dropdown id='dropDownFromBranch' width={4} value={this.state.branch} label='สาขา' search selection options={this.state.branches} onChange={(e, data) => {
              this.setState({ branch: data.value });
            }} />
            <Form.Field width={4}>
              <label>จากวันที่</label>
              <DatePicker
                id='dateStart'
                dateFormat="DD/MM/YYYY"
                selected={this.state.start_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.end_date < date) {
                    this.setState({ end_date: date });
                  }
                  this.setState({ start_date: date });
                }}
              />
            </Form.Field>
            <Form.Field width={4} >
              <label>ถึงวันที่</label>
              <DatePicker
                id='dateEnd'
                dateFormat="DD/MM/YYYY"
                minDate={this.state.start_date}
                selected={this.state.end_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  this.setState({ end_date: date });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Checkbox id='noUpdate' label='ยังไม่ปรับปรุงสต็อก' floated='right' width='3'
              checked={this.state.is_clear_stock}
              value={this.state.is_clear_stock} onChange={(e, v) => {

                this.setState({
                  is_clear_stock: !this.state.is_clear_stock
                });
              }} />
            <Form.Checkbox id='noClearBill' label='ยังไม่เคลียร์บิล' width='3' value={this.state.is_clear_bill} onChange={(e, v) => {
              this.setState({
                is_clear_bill: !this.state.is_clear_bill
              });
            }} />
            <Form.Field width='10'>              
              <Button id='clear_search' floated='right' type='button' size='small' onClick={() => {
                // this.componentDidMount();
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
              <Button id='btnToday' floated='right' type='button' size='small' onClick={() => {
                this.clearFormSearch();
                setTimeout(() => {
                  this.loaddata(1);
                  this.setState({ page: 1 })
                }, 500)
              }}  ><Icon name='search' /> วันนี้</Button>
              <Button id='btnSearch' floated='right' content='ค้นหา' type='button' size='small' onClick={() => {
                this.loaddata(1);
                this.setState({ page: 1 })
              }} ><Icon name='search' /> ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer active={this.state.loader_active} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>}

        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={40}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell delete={false} onClickMenu={this.onClickitem} />}
            />
            <Column

              header={<Cell></Cell>}
              cell={
                <IconItemsCell data={items} field="status_stock_title" />
              }
              width={30}
            />

            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='status_stock'
                sortDir={this.state.colSortDirs.status_stock}>
                สถานะปรับปรุงสต็อก
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="status_stock_title" />
              }
              width={150}
            />

            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='branch__name'
                sortDir={this.state.colSortDirs.branch__name}>
                สาขา
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="branche_title" />
              }
              width={150}
            />

            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='number'
                sortDir={this.state.colSortDirs.number}>
                เลขที่บิล
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} id='billID' data={items} field="number" />
              }
              width={120}
            />

            <Column
              header={<Cell>
                วันที่
              </Cell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="invoice_date_title" />
              }
              width={150}
            />
            <Column
              header={<Cell>
                วันที่ปรับปรุงสต็อก
              </Cell>}
              cell={
                <ItemsCell position='top left' width={150} id='dateUpdate' data={items} field="update_date_title" />
              }
              width={150}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='status_bill'
                sortDir={this.state.colSortDirs.status_bill}>
                สถานะเคลียร์บิล
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="status_bill_title" />
              }
              width={150}
            />

            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={250} data={items} field="description" />
              }
              width={250}
            />
          </Table>
          <br />
          <Form size='small'>
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
              <br />
              <Form.Field width={14}>
                <br />
                {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
          {/* {this.state.totalPages > 1 && <Pagination
            activePage={this.state.page}
            onPageChange={this.handlePaginationChange.bind(this)}
            totalPages={this.state.totalPages}
          />} */}
        </div>
        <br />
        <Form size='small' >
          <Form.Group>
            {/* <Form.Field width={2}>
              <lable>จำนวน</lable>
              <Input id='amountRow' className='text-right' value={this.state.total_item.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} />
            </Form.Field> */}
            <Form.Field width={16}>
              <br />
              {this.state.open_invoice ? <BillModal
                action={this.state.action}
                invoice_detail={this.state.invoice_detail}
                branches={this.state.branches}
                products={this.state.products}
                products_name={this.state.products_name}
                products_code={this.state.products_code}
                bill_number={this.state.bill_number}
                open={true}
                onClose={() => {
                  this.setState({ open_invoice: false });
                  this.loaddata(this.state.page);
                }}
                onAddInvoice={(d) => {
                  if (d != null) {
                    this.setState({
                      invoice_detail: d,
                      action: 'edit'
                    });
                  }
                  this.loaddata(this.state.page);
                }}
              /> : ''}

              {/* <Button content='เพิ่ม' onClick={() => {
                this.setState({ open_invoice: true, action: 'add' });
              }} floated='right' icon='plus' labelPosition='right' type='button' primary /> */}
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default Transfer;
