/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class StockOldProductItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches,]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/stock_old_product_item/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d),
                    before: this.setBefore(d)
                })
            }
        })
    }

    setBefore(items) {

        const first_item = items[0]
        if (items.length == 0 || first_item.kind != 'before')
            return null
        return first_item
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {

            let item = _items[i]

            if (item.kind == 'before')
                continue

            item['in'] = {
                amount: '',
                weight: '',
                total_weight: '',
                cost: '',
                fee: '',
                cost_fee: '',
                total_cost: ''

            }
            item['out'] = {
                amount: '',
                weight: '',
                total_weight: '',
                cost: '',
                fee: '',
                cost_fee: '',
                total_cost: ''

            }
            item['melt'] = {
                amount: '',
                weight: '',
                total_weight: '',
                cost: '',
                fee: '',
                cost_fee: '',
                total_cost: ''

            }
            item['balance'] = {
                amount: (item.after),
                total_weight: (item.old_product.weight),
                cost: (Number(item.old_product.cost) - Number(item.old_product.fee)),
                fee: (Number(item.old_product.fee)),
                cost_fee: (Number(item.old_product.cost)),
                total_cost: (Number(item.total_cost)),
                total_cost: (Number(item.total_cost)),
                total_cost: (Number(item.total_cost))

            }
            if (item.action == 'in' || item.action == 'out' || item.action == 'melt') {
                item[item.action] = {
                    amount: (item.amount),
                    weight: (item.old_product.weight),
                    total_weight: (item.old_product.weight),
                    cost: (Number(item.old_product.cost) - Number(item.old_product.fee)),
                    fee: (Number(item.old_product.fee)),
                    cost_fee: (Number(item.old_product.cost)),
                    total_cost: (Number(item.old_product.cost)),
                }
            }

            items.push(item)
        }

        // group melt
        const groups_item = {}
        items.map((item, i) => {
            const key = item.object_number

            if (item.action != 'melt' || !groups_item[key]) {
                groups_item[key + (item.action != 'melt' ? item.id : '')] = item
            } else {
                groups_item[key].melt.amount = (Number((groups_item[key].melt.amount)) + Number((item.melt.amount)))
                groups_item[key].melt.weight = (Number((groups_item[key].melt.weight)) + Number((item.melt.weight)))
                groups_item[key].melt.total_weight = (Number((groups_item[key].melt.total_weight)) + Number((item.melt.total_weight)))
                groups_item[key].weight_real = (Number((groups_item[key].weight_real)) + Number((item.weight_real)))
                groups_item[key].melt.cost = (Number((groups_item[key].melt.cost)) + Number((item.melt.cost)))
                groups_item[key].melt.fee = (Number((groups_item[key].melt.fee)) + Number((item.melt.fee)))
                groups_item[key].melt.cost_fee = (Number((groups_item[key].melt.cost_fee)) + Number((item.melt.cost_fee)))
                groups_item[key].melt.total_cost = (Number((groups_item[key].melt.total_cost)) + Number((item.melt.total_cost)))

                groups_item[key].balance.amount = item.balance.amount
                groups_item[key].balance.total_weight = item.balance.total_weight
                groups_item[key].balance.cost = item.balance.cost
                groups_item[key].balance.fee = item.balance.fee
                groups_item[key].balance.cost_fee = item.balance.cost_fee
                groups_item[key].balance.total_cost = item.balance.total_cost
            }
        })

        const _items2 = []
        for (let k in groups_item) {
            _items2.push(groups_item[k])
        }
        return _items2
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    setSummary(items, before) {

        let summary = {
            in: {},
            out: {},
            melt: {},
            weight_real: 0,
        }

        if (before) {
            summary.in.amount = before.after
            summary.in.weight = before.total_weight
            summary.in.total_weight = before.total_weight
            summary.in.cost = (before.total_cost - before.total_fee)
            summary.in.fee = before.total_fee
            summary.in.cost_fee = before.total_cost
            summary.in.total_cost = before.total_cost
        }
        items.map((item, i) => {
            if (summary[item.action]['amount'] == null)
                summary[item.action] = JSON.parse(JSON.stringify(item[item.action]))
            else {
                for (let k in item[item.action]) {
                    summary[item.action][k] = Number((summary[item.action][k])) + Number((item[item.action][k]))
                }
            }
            summary['weight_real'] = Number((summary['weight_real'])) + Number((item['weight_real']))
        })
        return summary

    }
    render() {


        let title = 'รายงานสต็อกทองเก่าคงเหลือ (แบบเคลื่อนไหว)';
        let filename = 'stock_old_product_item';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const bgWhite = {
            'background-color': '#fff'
        }

        const lineTop = {
            'border-top': '1px solid black'
        }

        const bgGray = {
            'background-color': '#eee'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        let before = this.state.before
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }
        const { items } = this.state
        const summary = this.setSummary(items, before)
        const group_date = {}
        const group_bill = {}
        let group_number = ''

        // set before is 0
        let _before = {}
        if (before == null) {
            _before = {
                record_date: moment(Utility.formatDate2(this.state.search.start_date)).add(-1, 'days'),
                after: 0,
                total_weight: 0,
                total_weight: 0,
                total_cost: 0,
                total_fee: 0
            }
        } else {
            _before = before
        }
        let sum_balance_amount = _before.after;
        let sum_balance_weight = _before.total_weight;
        let sum_balance_cost = _before.total_cost;
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสต็อกทองเก่าคงเหลือ (แบบเคลื่อนไหว)</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='extra-wide'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='31'>
                                                <center>
                                                    <p>{title}</p>
                                                    <p>(ซื้อทองเก่า,ทองเปลี่ยน,ทองชำรุด,ทองหลุดจำนำ)</p>
                                                </center>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='31'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='31'><center id='date'>ช่วงเวลา: ตั้งแต่ {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4' style={textCenter}></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='7' style={textCenter}>ทองเข้า</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='7' style={textCenter}>ทองออก</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='6' style={textCenter}>ทองส่งหลอม</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='5' style={textCenter}>คงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='2' style={textCenter}></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่อ้างอิง</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อสินค้า</Table.HeaderCell>

                                            {/* in */}
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.(g)/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุน/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่าแรง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนทั้งสิ้น</Table.HeaderCell>

                                            {/* out */}
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.(g)/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุน/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่าแรง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนทั้งสิ้น</Table.HeaderCell>

                                            {/* melt */}
                                            <Table.HeaderCell style={textCenter}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.ชั่ง</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.ต่าง</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุน/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่าแรง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนส่งหลอม</Table.HeaderCell>

                                            {/* balance */}
                                            <Table.HeaderCell style={textCenter}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ราคา/หน่วย</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textCenter}>ค่าแรง/หน่วย</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textCenter}>มูลค่าคงเหลือ/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>มูลค่าคงเหลือ</Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell style={textCenter}>{Utility.formatDate(_before.record_date)}</Table.Cell>
                                            <Table.Cell style={textCenter}>ยกมา</Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell></Table.Cell>

                                            {/* in */}
                                            <Table.Cell style={textRight}>{Utility.numberFormat(_before.after)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(_before.total_weight)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(_before.total_weight)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat((Number(_before.total_cost) - Number(_before.total_fee)))}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(Number(_before.total_fee))}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(Number(_before.total_cost))}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(Number(_before.total_cost))}</Table.Cell>

                                            {/* out */}
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>

                                            {/* melt */}
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>

                                            {/* balance */}
                                            <Table.Cell style={textRight}>{(_before.after)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(_before.total_weight)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat((Number(_before.total_cost) - Number(_before.total_fee)))}</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{Utility.priceFormat(Number(_before.total_fee))}</Table.Cell> */}
                                            <Table.Cell style={textRight}>{Utility.priceFormat(Number(_before.total_cost))}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(Number(_before.total_cost))}</Table.Cell>
                                        </Table.Row>


                                        {items.map((item, i) => {

                                            let date = Utility.formatDate(item.record_date)
                                            if (group_date[date])
                                                date = ''
                                            else group_date[date] = true

                                            let object_number = item.object_number
                                            if (group_number == item.object_number)
                                                object_number = ''
                                            else group_number = item.object_number
                                            sum_balance_weight += Number(item.balance.total_weight)
                                            sum_balance_cost = item.balance.total_cost
                                            sum_balance_amount = item.balance.amount
                                            return (<Table.Row key={i} >
                                                <Table.Cell style={textCenter}>{date}</Table.Cell>
                                                <Table.Cell style={textCenter}>{object_number}</Table.Cell>
                                                <Table.Cell style={textCenter}>{item.kind_display}</Table.Cell>
                                                <Table.Cell>{item.action == 'melt' ? '' : item.old_product.name}</Table.Cell>

                                                {/* in */}
                                                <Table.Cell style={textRight}>{Utility.numberFormat(item.in.amount)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.in.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.in.total_weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.in.cost)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.in.fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.in.cost_fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.in.total_cost)}</Table.Cell>

                                                {/* out */}
                                                <Table.Cell style={textRight}>{Utility.numberFormat(item.out.amount)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.out.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.out.total_weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.out.cost)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.out.fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.out.cost_fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.out.total_cost)}</Table.Cell>

                                                {/* melt */}
                                                <Table.Cell style={textRight}>{Utility.numberFormat(item.melt.amount)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.melt.total_weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.weight_real)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(Number(item.melt.total_weight) - Number(item.weight_real))}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.melt.cost)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.melt.fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.melt.cost_fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.melt.total_cost)}</Table.Cell>

                                                {/* balance */}
                                                <Table.Cell style={textRight}>{Utility.numberFormat(item.balance.amount)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.balance.total_weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.balance.cost)}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{Utility.priceFormat(item.balance.fee)}</Table.Cell> */}
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.balance.cost_fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.balance.total_cost)}</Table.Cell>

                                            </Table.Row>)

                                        })}
                                    </Table.Body>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4' style={textRight}><b><div style={textU}>รวม</div></b></Table.HeaderCell>

                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary.in.amount == undefined ? 0 : summary.in.amount)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary.in.weight)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary.in.total_weight)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.in.cost)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.in.fee)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.in.cost_fee)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.in.total_cost)}</b></div></Table.HeaderCell>

                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary.out.amount == undefined ? 0 : summary.out.amount)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary.out.weight)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary.out.total_weight)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.out.cost)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.out.fee)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.out.cost_fee)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.out.total_cost)}</b></div></Table.HeaderCell>

                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary.melt.amount == undefined ? 0 : summary.melt.amount)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary.melt.total_weight)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary.weight_real)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(Number(summary.melt.total_weight) - Number(summary.weight_real))}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.melt.cost)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.melt.fee)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.melt.cost_fee)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary.melt.total_cost)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.numberFormat(sum_balance_amount)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(sum_balance_weight)}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='3' style={textRight}><div style={textU}><b>{Utility.priceFormat(sum_balance_cost)}</b></div></Table.HeaderCell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4' style={textRight}><div style={textU}><b>ต้นทุนเฉลี่ย ต่อกรัม</b></div></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='7' style={textRight}><div style={textU}><b>{Utility.priceFormat(Number((summary.in.total_cost)) / Number((summary.in.total_weight)))}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='7' style={textRight}></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='6' style={textRight}><div style={textU}><b>{Utility.priceFormat(Number((summary.melt.total_cost)) / Number((summary.melt.total_weight)))}</b></div></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='7' style={textCenter}></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(StockOldProductItem)
