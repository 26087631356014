/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Button,
  Dimmer,
  Loader,
  Header,
  Pagination,
  Popup,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import StockGold99Log from './StockGold99Log';

var dateFormat = require('dateformat');

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props} onClick={() => {
        this.props.onContextMenu(data[rowIndex].stock.id);
      }} className="cell-time-click">
        <Popup
          position={this.props.position}
          content={(['cost', 'cost_bath', 'gold_price', 'cost_value', 'selling', 'selling_gram', 'selling_value', 'diff_value'].includes(field)) ?
            Utility.priceFormat(data[rowIndex][field])
            :
            (['weight_remain'].includes(field)) ?
              Utility.weightFormat(data[rowIndex][field])
              :
              data[rowIndex][field]
          }
          style={Width300}
          trigger=
          {(['cost', 'cost_bath', 'gold_price', 'cost_value', 'selling', 'selling_gram', 'selling_value', 'diff_value'].includes(field)) ?
            <div style={ellipsisStyle} className={this.props.textAlign}>{Utility.priceFormat(data[rowIndex][field])}</div>
            :
            (['weight_remain'].includes(field)) ?
              <div style={ellipsisStyle} className={this.props.textAlign}>{Utility.weightFormat(data[rowIndex][field])}</div>
              :
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>
          }
        />
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class StockGold99 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      vendor: 0,
      table_heigh: Settings.table_heigh,
      totalPages: 1,
      page: 1,
      qrstring: '',
      colSortDirs: {
        'stock__number': 'ASC',
      },
      counter: 0,
      gold_99_buy: 0,
    }

    this.columnKey = 'stock__number'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.loaddata = this.loaddata.bind(this)
    this.onContextMenu = this.onContextMenu.bind(this);
    this.clearFormSearch = this.clearFormSearch.bind(this);
  }

  resetForm() {
    this.setState({
      vendor: 0,
      page: 1
    }, () => {
      this.componentDidMount()
    })
  }

  clearFormSearch() {
    this.setState({
      vendor: 0,
      page: 1
    })
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  loaddata(page) {
    this.setState({
      loader_active: true
    });
    var s = '';
    if (this.state.vendor) {
      s += '&vendor=' + this.state.vendor
    }
    this.setState({ qrstring: s })
    var stock = Utility.getFetch(Settings.baseUrl + '/gold_99/stock/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&status=2' + s + '&page=' + page + '&page_size=' + 10);
    Promise.all([stock]).then((values) => {
      this.setState({
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter: values[0].count
      });
    });
  }

  componentDidMount() {
    this.setState({
      loader_active: true
    });
    let d = new Date();
    let q = dateFormat(d, "yyyy-mm-dd HH:MM")
    var vendors = Utility.getFetch(Settings.baseReportUrl + '/vendors/?is_enabled=1');
    var gold_price = Utility.getFetch(Settings.baseUrl + "/gold_price/?datetime=" + q);
    Promise.all([vendors, gold_price]).then((values) => {
      this.vendors = values[0];
      let vendors = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      for (let i in this.vendors) {
        vendors.push({
          value: this.vendors[i].id,
          key: this.vendors[i].id,
          text: this.vendors[i].name
        });
      }

      this.setState({
        vendors: vendors
      });
      if (values[1].length > 0) {
        this.setState({
          gold_99_buy: Math.round(values[1][0].gold_99_buy * 100) / 100
        })
      }
      this.loaddata(1)
    });

    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth, table_heigh: window.innerHeight - 380 });
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  submitSearch(event) {
    event.preventDefault();
    this.setState({ page: 1 });
    this.loaddata(1);
  }

  getTypeSale(key) {
    let type = Settings.type_sale.filter((o) => { return o.value == key });
    return type[0];
  }

  setFieldValue(items) {

    for (let i = 0; i < items.length; i++) {
      items[i]['stock_number'] = items[i]['stock']['number']
      items[i]['vendor_name'] = items[i]['stock']['vendor']
      items[i]['weight_remain'] = Number(items[i]['weight_remain'])
      items[i]['import_date'] = Utility.formatDate(items[i]['stock']['import_date'])
      items[i]['cost_bath'] = Math.round(Number(items[i]['stock']['cost']) * 100) / 100
      items[i]['cost'] = Math.round(Number(items[i]['stock']['cost']) * 0.0656 * 100) / 100
      items[i]['cost_value'] = Math.round(items[i]['cost_bath'] * items[i]['weight_remain'] * 0.0656 * 100) / 100
      items[i]['selling'] = this.state.gold_99_buy
      items[i]['selling_gram'] = this.state.gold_99_buy * 0.0656
      items[i]['selling_value'] = Math.round((Math.round(this.state.gold_99_buy * 0.0656 * 100) / 100) * items[i]['weight_remain'] * 100) / 100
      items[i]['diff_value'] = Math.round((items[i]['selling_value'] - items[i]['cost_value']) * 100) / 100
    }
    return items;
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage }, () => {
      this.loaddata(activePage)
    })
  }

  onContextMenu(id) {
    this.setState({
      id: id
    })

    this.setState({
      open_detail: true
    })
  }

  render() {
    const items = this.state.items;
    return (
      <div id="table_width">
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>สต็อกทอง 99.99</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>}
        <Form className='fluid' size='small'>
          <Form.Group>
            <Form.Field>
              <label>โรงงาน</label>
              <Dropdown placeholder='- ทั้งหมด -'
                search
                selection
                options={this.state.vendors}
                value={this.state.vendor}
                name='vendor'
                onChange={(e, data) => {
                  this.setState({ vendor: data.value });
                }} />
            </Form.Field>
            <Form.Field width={5}>
              <label><br /></label>
              <Button id='btnSearch' onClick={this.submitSearch}><Icon name='search' />ค้นหา</Button>
              <Button id='btnAll' onClick={this.resetForm} >ทั้งหมด</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={410}>
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='stock__number'
              sortDir={this.state.colSortDirs.stock__number}>
              เลขที่ใบนำเข้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} id='stock_number' data={items} field="stock_number" onContextMenu={this.onContextMenu} />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='stock__vendor__name'
              sortDir={this.state.colSortDirs.stock__vendor__name}>
              โรงงาน
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={100} data={items} field="vendor_name" onContextMenu={this.onContextMenu} />
            }
            width={100}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.onSortChange}
              field='weight_remain'
              sortDir={this.state.colSortDirs.weight_remain}>
              น้ำหนักคงเหลือ
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={100} data={items} field="weight_remain" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={100}
          />
          <Column
            header={<SortHeaderCell
              className='text-center'
              onSortChange={this.onSortChange}
              field='stock__import_date'
              sortDir={this.state.colSortDirs.stock__import_date}>
              วันที่นำเข้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top center' width={120} data={items} field="import_date" textAlign='text-center' onContextMenu={this.onContextMenu} />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.onSortChange}
              field='stock__cost'
              sortDir={this.state.colSortDirs.stock__cost}>
              ต้นทุนทอง(กรัม)
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={100} data={items} field="cost" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={100}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.onSortChange}
              field='stock__cost'
              sortDir={this.state.colSortDirs.stock__cost}>
              ต้นทุนทอง(บาท)
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={100} data={items} field="cost_bath" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={100}
          />
          <Column
            header={<Cell className='text-right'>
              มูลค่าต้นทุน
            </Cell>}
            cell={
              <ItemsCell position='top right' width={160} data={items} field="cost_value" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={160}
          />
          <Column
            header={<Cell className='text-right'>
              ราคาขายต่อกรัม(คาดการณ์)
            </Cell>}
            cell={
              <ItemsCell position='top right' width={160} data={items} field="selling_gram" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={160}
          />
          <Column
            header={<Cell className='text-right'>
              ราคาขายต่อบาท(คาดการณ์)
            </Cell>}
            cell={
              <ItemsCell position='top right' width={170} data={items} field="selling" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={170}
          />
          <Column
            header={<Cell className='text-right'>
              มูลค่าขายออก(คาดการณ์)
            </Cell>}
            cell={
              <ItemsCell position='top right' width={160} data={items} field="selling_value" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={160}
          />
          <Column
            header={<Cell className='text-right'>
              มูลค่าส่วนต่าง(คาดการณ์)
            </Cell>}
            cell={
              <ItemsCell position='top right' width={160} data={items} field="diff_value" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={160}
          />

        </Table>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
            <br />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.page}
                onPageChange={this.handlePaginationChange.bind(this)}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>
        </Form>
        {/* {this.state.totalPages > 1 && <Pagination
          activePage={this.state.page}
          onPageChange={this.handlePaginationChange.bind(this)}
          totalPages={this.state.totalPages}
        />} */}
        {this.state.open_detail ? <StockGold99Log
          id={this.state.id}
          open={true}
          onClose={() => {
            this.setState({ open_detail: false });
          }}
        /> : ''}
      </div>
    );
  }
}
export default StockGold99;
