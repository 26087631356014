/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
  Form, 
  Segment, 
  Header,
  Grid, 
  Menu,
  Icon,
} from 'semantic-ui-react';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';
import Settings from '../../Settings';
import Utility from '../../Utility';

import {activations_branch} from '../../actions'
import LeaseOut from './LeaseOut'
import LesePOS from './LesePOS'
import LeaseForm from './LeaseForm'
import LeaseStock from './LeaseStock'
import { connect } from 'react-redux'
import DropdownBranch from '../Widget/DropDown/DropDownBranch'
import {lease_modal_edit} from '../../actions'
import LeaseSearchByNumber from './LeaseSearchByNumber'

class Lease extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: window.location.pathname
    }

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, { name, to }) {
    this.setState({
      activeItem: to
    });
  }

  handleBranchChange(e,{value}){
    const {branches} = this.props
    const branch = branches.find((item)=>item.id==value)
    this.props.handleBranchChange(branch)
  }

  render() {
    const {auth} = this.props
    const {role,branch} = auth
    const {lease_modal} = this.props
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={7}>
              <Header floated='left' as='h3'>
                <div style={{padding: '7px'}} className="pull-left"><Icon name='calendar alternate'  /> ขายฝาก </div>
                {/* <div className="pull-left">{role=='A' || role=='M' || role=='S' ? <DropdownBranch  fluid size='small' fluid selection onChange={this.handleBranchChange.bind(this)} name="branch" value={branch.id}  />:''}</div> */}
              </Header>
            </Form.Field>
            
          </Form.Group>
        </Form>
        <Router>
          <Grid>
            <Grid.Column width={3}>
              <Segment color='black'>
                <Menu secondary vertical className='menu-left'>
                  <Menu.Item 
                    id='btnLeasePOS'
                    name='lease'
                    active={this.state.activeItem == '/lease/pos'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/lease/pos'
                  >
                    รายการขายฝาก
      </Menu.Item>

                  <Menu.Item
                    id='btnLeaseInterest'
                    name='lease'
                    active={this.state.activeItem == '/lease/interest'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/lease/interest'
                  >
                    ต่อดอก
      </Menu.Item>

                  <Menu.Item
                    id='btnLeaseRedeem'
                    name='lease'
                    active={this.state.activeItem == '/lease/redeem'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/lease/redeem'
                  >
                    ไถ่คืน
      </Menu.Item>
                  <Menu.Item
                    id='btnLeaseDelete'
                    name='out'
                    active={this.state.activeItem == '/lease/add_delete'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/lease/add_delete'
                  >
                    เพิ่ม/ลด เงินต้น
      </Menu.Item>
      {role=='A' || role=='M' || role=='S' ?<Menu.Item
                    id='btnLeaseOut'
                    name='out'
                    active={this.state.activeItem == '/lease/out'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/lease/out'
                  >
                    คัดออก
      </Menu.Item>:''}
      {role=='A' || role=='M' || role=='S' ?<Menu.Item
                    id='btnLeaseStock'
                    name='stock'
                    active={this.state.activeItem == '/lease/stock'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/lease/stock'
                  >
                    สต็อกขายฝาก
      </Menu.Item>:''}
                </Menu>
              </Segment>
            </Grid.Column>
            <Grid.Column stretched width={13} className="content-body" >
              <Segment color='black'>
                <Route path="/lease/pos" component={() => (<LesePOS action='pos' />)} />
                <Route path="/lease/interest" component={() => (<LeaseSearchByNumber action='interest' title='ต่อดอก' branch={this.props.auth.branch} lease_modal={lease_modal} handleOpenLease={this.props.handleOpenLease}  />)} />
                <Route path="/lease/redeem" component={() => (<LeaseSearchByNumber action='redeem' title='ไถ่คืน' branch={this.props.auth.branch} lease_modal={lease_modal} handleOpenLease={this.props.handleOpenLease}  />)}  />
                <Route path="/lease/add_delete" component={() => (<LeaseSearchByNumber action='add_delete' title='เพิ่มลดเงินต้น' branch={this.props.auth.branch} lease_modal={lease_modal} handleOpenLease={this.props.handleOpenLease}  />)} />
                {role=='A' || role=='M' || role=='S' ?<Route path="/lease/out" component={() => (<LeaseOut />)} />:''}
                {role=='A' || role=='M' || role=='S' ?<Route path="/lease/stock" component={() => (<LeaseStock />)} />:''}
              </Segment>
            </Grid.Column>
          </Grid>
        </Router>
      </div>
    );
  }
}


const mapStateToProps = state =>{
  return ({
    //lease_modal: state.lease_modal,
    auth: state.auth,
    branches: state.branches
  })
}
const mapDispatchToProps = dispatch => ({
  handleBranchChange: (branch) => {
    dispatch(activations_branch(dispatch,branch))
  },
  handleOpenLease: (lease)=>{
    lease_modal_edit(dispatch,lease.id)
  }
  
})
export default connect(
  mapStateToProps,mapDispatchToProps
)(Lease)

