/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Route,
  NavLink
} from 'react-router-dom';

import {
  Icon,
  Popup,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { ContextMenu, Item, ContextMenuProvider } from 'react-contexify';
import Utility from '../../../Utility';

class MyAwesomeMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ContextMenu id='menu_id'>
        <Item onClick={this.props.onClick} data='delete'>
          ลบ
        </Item>
      </ContextMenu>
    );
  }
}

class IconCell extends Component {
  render() {
    const { rowIndex, field, data, type, action, ...props } = this.props;

    return (
      <Cell {...props}  >
        <a id='btnDelete' onClick={(e) => { if (window.confirm('ยืนยันลบรายการนี้')) this.props.onDelete(e, rowIndex) }}><Icon name='window close outline' /></a>
        {action == 'SE' ? <Icon name='file alternate' color='green' /> : ''}
        {action == 'BU' ? <Icon name='file alternate' color='red' /> : ''}
      </Cell>
    );
  }
}

class ItemsCell extends Component {
  render() {
    const { rowIndex, field, data, type, ...props } = this.props;

    let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
      : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
        : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
          : data[rowIndex][field];
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props}  >
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id">
          <Popup
            position={this.props.position}
            content={v}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>{v}</div>}
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}


class SellBillTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sales: []
    };
    this.handlerDelete = this.handlerDelete.bind(this);
  }

  handlerDelete(e, d) {

    if (window.confirm('ยืนยันลบรายการนี้'))
      this.props.onDelete(e, d);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('root-table-bill')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    let items = this.props.billItems

    return (
      <div id="root-table-bill">
        <Dimmer active={this.props.is_loading} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>

        <MyAwesomeMenu id="menu_id" onClick={(e, r, data) => {
          let index = e.getAttribute('data');
          //const bill = items[index];
          this.props.onDelete(e, index);
        }} />
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={300}>
          {this.props.is_delete && <Column
            header={<Cell></Cell>}
            cell={
              <IconCell data={items} action={this.props.action} onDelete={this.props.onDelete} />
            }
            width={50}
            fixed={true}
          />}
          <Column
            header={<Cell>รายการ</Cell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="type_title" />
            }
            width={80}
            fixed={true}
          />

          {/* <Column
              header={<Cell>สถานะอัพเดทสต็อก</Cell>}
              cell={
                <ItemsCell position='top left' width={120} item='status' data={items} field="status_stock_display" />
              }
              width={120}
            /> */}
          {this.props.action == 'BU' ? '' :
            <Column
              header={<Cell>รหัสสินค้า</Cell>}
              cell={
                <ItemsCell position='top left' width={120} id="idproduct" data={items} field="product_code" />
              }
              width={120}
            />}
          {this.props.action == 'BU' ? '' : <Column
            header={<Cell>ชื่อสินค้า</Cell>}
            cell={
              <ItemsCell position='top left' width={140} data={items} field="product_name" />
            }
            width={140}
          />}
          {this.props.action == 'SE' ? '' :
            <Column
              header={<Cell>กลุ่มสินค้า</Cell>}
              cell={
                <ItemsCell position='top left' width={180} data={items} field="category_name" />
              }
              width={180}
            />}
          {this.props.action == 'SE' ? '' :
            <Column
              header={<Cell>ชื่อทองเก่า</Cell>}
              cell={
                <ItemsCell position='top left' width={180} data={items} field="buy_name" />
              }
              width={180}
            />}
          {this.props.action == 'BU' ? '' :
            <Column
              header={<Cell className='text-right'>จำนวน</Cell>}
              cell={
                <ItemsCell position='top right' width={70} data={items} field="amount" textAlign='text-right' type='number' />
              }
              width={70}
            />}
          {this.props.action == 'BU' ? '' :
            <Column
              header={<Cell className='text-right'>ราคาขาย</Cell>}
              cell={
                <ItemsCell position='top right' width={120} data={items} field="sell_price" textAlign='text-right' type='price' />
              }
              width={120}
            />}
          {this.props.action == 'BU' ? '' :
            <Column
              header={<Cell className='text-right'>ค่าแรงขาย</Cell>}
              cell={
                <ItemsCell position='top right' width={120} id='textCost' data={items} field="sales_force" textAlign='text-right' type='price' />
              }
              width={120}
            />}
          {this.props.action != 'XC' ? '' :
            <Column
              header={<Cell className='text-right'>ค่าเปลี่ยน</Cell>}
              cell={
                <ItemsCell position='top right' width={100} data={items} field="exchange" textAlign='text-right' type='price' />
              }
              width={100}
            />}
          {this.props.action != 'XC' ? '' :
            <Column
              header={<Cell className='text-right'>เงินที่ได้รับ</Cell>}
              cell={
                <ItemsCell position='top right' width={100} data={items} field="exchange_price" textAlign='text-right' type='price' />
              }
              width={100}
            />}
          {this.props.action == 'SE' ? '' :
            <Column
              header={<Cell className='text-right'>ราคาซื้อ</Cell>}
              cell={
                <ItemsCell position='top right' width={120} data={items} field="buy_price" textAlign='text-right' type='price' />
              }
              width={120}
            />}
          {this.props.action == 'BU' ? '' :
            <Column
              header={<Cell className='text-right'>น.น.ชั่ง</Cell>}
              cell={
                <ItemsCell position='top right' width={120} data={items} field="sell_weight_real" textAlign='text-right' type='weight' />
              }
              width={120}
            />}
          {this.props.action == 'SE' ? '' :
            <Column
              header={<Cell className='text-right'>น.น.ซื้อ</Cell>}
              cell={
                <ItemsCell position='top right' width={120} data={items} field="buy_weight" textAlign='text-right' type='weight' />
              }
              width={120}
            />
          }
          {this.props.action != 'XC' ? '' :
            <Column
              header={<Cell>รหัสเปลี่ยนทอง</Cell>}
              cell={
                <ItemsCell position='top left' width={140} data={items} field="code_change" />
              }
              width={140}
            />}
        </Table><br />
      </div>
    );
  }
}

export default SellBillTable;
