/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import OptionItemsCell from '../OptionItemsCell'
import ProductFromModal from './ProductFromModal'
import ImportProduct from '../../Import/ImportProduct'

import {
  Form,
  Input,
  Button,
  Icon,
  Confirm, Dimmer, Loader, Dropdown, Menu,
  Header, TextArea,
  Popup,
  Pagination,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { connect } from 'react-redux'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Pattern from '../Pattern';
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={field === 'price_tag' ?
              Utility.priceFormat(data[rowIndex][field])
              : data[rowIndex][field]
            }
            style={Width300}
            trigger={field === 'price_tag' ?
              <div style={ellipsisStyle} className={this.props.textAlign}>{Utility.priceFormat(data[rowIndex][field])}</div>
              : <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>
            }
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class ProductsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],

      search: {
        category_id: 0,
        product_types_id: 0,
        pattern_id: 0,
        product_id: 0,
        type_sale: 0,
        weight_b: 0,
        weight: '',
      },
      msg_error: {},
      activeItem: 'แสดง',
      totalPages: 1,
      page: 1,
      limit: 10,
      colSortDirs: {
        'code': 'ASC',
      },
    }
    this.product_code_all = [];
    this.product_name_all = [];

    this.resetForm = this
      .resetForm
      .bind(this);
    this.columnKey = 'code'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.loaddata = this.loaddata.bind(this)
    this.clearFormSearch = this.clearFormSearch.bind(this)
    this.addPattern = this.addPattern.bind(this)
  }

  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.handlerSubmitSearch()

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handleItemClick = (e, { name }) => {
    this.setState({
      loader_active: true,
    });
    let status = 1
    if (name === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    // let search = Utility.filterSearch(this.state.search)
    // let str = Utility.jsonToQueryString(search);
    Utility.get(Settings.baseUrl + '/products/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&' + this.state.qr + '&page=' + 1 + '&page_size=' + this.state.limit, (s, data) => {
      // for (let i in data) {
      //   data[i].branch_name = data[i].branch.name;
      // }
      this.product_list = data.results
      this.setState({
        loader_active: false,
        items: this.setFieldValue(data.results),
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / this.state.limit),
        counter: data.count,
        page: 1
      });
    })
    this.setState({ activeItem: name })
  }


  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    var s = '';
    let search = this.state.search;
    for (let i in search) {
      if (search[i] == '') {
        delete search[i]
      }
    }
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }
    let str = Utility.jsonToQueryString(search);
    this.setState({ qr: str })
    var categories = Utility.getFetch(Settings.baseUrl + '/categories/');
    var product_types = Utility.getFetch(Settings.baseUrl + '/product_types/');
    var products = Utility.getFetch(Settings.baseUrl + '/products/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&' + str + '&page=' + 1 + '&page_size=' + this.state.limit);
    var pattern = Utility.getFetch(Settings.baseUrl + '/pattern/?is_enabled=1');
    Promise.all([categories, product_types, products, pattern]).then((values) => {
      this.categories = values[0];
      this.product_types = values[1];

      this.product_list = values[2].results
      let cat = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      for (let i in values[0]) {
        cat.push({
          key: values[0][i].id,
          value: values[0][i].id,
          text: values[0][i].name,
        });
      }
      let type = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      for (let i in values[1]) {
        type.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        });
      }

      let products_code = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];

      let products = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];

      let pattern = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      for (let i in values[3]) {
        pattern.push({
          key: values[3][i].id,
          value: values[3][i].id,
          text: values[3][i].name,
        });
      }
      let type_sales = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }]
      type_sales = type_sales.concat(Settings.type_sale)
      let weights = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }]
      weights = weights.concat(Settings.weight)

      let search = {
        category_id: 0,
        product_types_id: 0,
        pattern_id: 0,
        product_id: 0,
        type_sale: 0,
        weight_b: 0,
        weight: '',
      }

      this.setState({
        categories: cat,
        product_types: type,
        loader_active: false,
        products: products,
        products_code: products_code,
        pattern: pattern,
        items: this.setFieldValue(values[2].results),
        // activeItem: 'แสดง',
        type_sales: type_sales,
        weights: weights,
        totalPages: values[2].next == null && values[2].previous == null ? 0 : Math.ceil(values[2].count / this.state.limit),
        counter: values[2].count,
        search: search,
        page: 1
      });
    });
  }

  setFieldValue(v) {
    for (let i in v) {
      v[i].category_name = v[i].category.name
      v[i].prodcut_type_name = v[i].kind.name
      v[i].pattern_name = v[i].pattern.name
      v[i].type_sale_title = Utility.getObjectByValue(Settings.type_sale, v[i].type_sale).text
    }

    return v;
  }

  resetProduct(text) {
    let search = this.state.search
    if (text != '') {
      this.setState({
        isLoading: true
      });
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let products = [{
          key: 0,
          value: 0,
          text: '-ทั้งหมด-',
        }];
        let products_code = [{
          key: 0,
          value: 0,
          text: '-ทั้งหมด-',
        }];
        let s = {}
        if (search.category_id != 0)
          s.category_id = search.category_id
        if (search.type_sale != 0)
          s.type_sale = search.type_sale
        if (search.product_types_id != 0)
          s.product_types_id = search.product_types_id
        if (search.weight != '')
          s.weight = search.weight
        if (search.pattern_id != '')
          s.pattern_id = search.pattern_id
        s.text = text
        s.limited = 10
        var p = Utility.getFetch(Settings.baseUrl + '/products_no_join/?' + Utility.jsonToQueryString(s));
        Promise.all([p]).then((values) => {
          let p = values[0].product
          for (let i in p) {
            products_code.push({
              key: p[i].id,
              value: p[i].id,
              text: p[i].code,
            });
            products.push({
              key: p[i].id,
              value: p[i].id,
              text: p[i].name,
            });
          }
          if (p.filter(p => p.id == this.state.search.product_id).length == 0) {
            let ss = this.state.search
            ss.product_id = 0
            this.setState({
              search: ss
            })
          }
          this.setState({
            products: products,
            products_code: products_code,
            isLoading: false,
          })
        })
      }, 1600)
    } else {
      let products = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      let products_code = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      this.setState({
        products: products,
        products_code: products_code,
      })
    }
  }

  resetCategory(category_id) {
    let type = [{
      key: 0,
      value: 0,
      text: '-ทั้งหมด-',
    }];
    for (let i in this.product_types) {
      if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
        type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
    }

    if (type.length > 0)
      this.setState({ product_types: type });
    else {
      for (let i in this.product_types) {
        type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
      }
      this.setState({ product_types: type });
    }
  }


  handlerSearch(event, v) {

    if (v.name) {
      if (v.name == 'weight') {
        v.value = v.value.toString().replace(/,/g, '')
        if (isNaN(Number(v.value)))
          return;
        if (v.value.split('.')[0].length > 6)
          return;
        if (v.value.toString().split(".")[1] != undefined)
          if (v.value.toString().split(".")[1].length > 3)
            return
      }
      let search = this.state.search;
      if (v.name == 'weight') {
        search.weight_b = 0
      }
      search[v.name] = v.value;
      this.setState({
        search: search
      });
      // this.resetProduct(search)
      if (v.name == 'category_id') {
        this.resetCategory(v.value);
      }
      return;
    } else {
      const target = event.target;
      const value = target.type == 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let search = this.state.search;
      search[name] = value;
      this.setState({
        search: search
      });
    }
  }

  handlerSubmitSearch(e) {
    this.setState({
      loader_active: true
    });
    let search = this.state.search;
    for (let i in search) {
      if (search[i] == 0) {
        if (i != 'weight')
          delete search[i]
      }
    }
    if (search['weight_b'] == 0) {
      if (search['weight'] != 0)
        delete search['weight']
    }
    if (search['weight'] == '')
      delete search['weight']
    let search2 = {
      category_id: search['category_id'] ? search['category_id'] : 0,
      product_types_id: search['product_types_id'] ? search['product_types_id'] : 0,
      pattern_id: search['pattern_id'] ? search['pattern_id'] : 0,
      product_id: search['product_id'] ? search['product_id'] : 0,
      type_sale: search['type_sale'] ? search['type_sale'] : 0,
      weight_b: search['weight_b'] ? search['weight_b'] : 0,
      weight: search['weight'] ? search['weight'] : '',
    }
    this.setState({ search: search2 })
    let str = Utility.jsonToQueryString(search);
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }
    Utility.get(Settings.baseUrl + '/products/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&' + str + '&page=' + 1 + '&page_size=' + this.state.limit, (s, data) => {
      this.product_list = data.results
      this.setState({
        loader_active: false,
        items: this.setFieldValue(data.results),
        qr: str,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / this.state.limit),
        counter: data.count,
        page: 1
      });
    })
  }
  loaddata(page) {
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }
    Utility.get(Settings.baseUrl + '/products/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&' + this.state.qr + '&page=' + page + '&page_size=' + this.state.limit, (s, data) => {
      this.product_list = data.results
      this.setState({
        loader_active: false,
        items: this.setFieldValue(data.results),
        // qr:str
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / this.state.limit),
        counter: data.count,
      });
    })
  }

  resetForm() {

    // let search = this.state.search;
    // for (let i in search) {
    //   search[i] = 0;
    // }
    let search = {
      category_id: 0,
      product_types_id: 0,
      pattern_id: 0,
      product_id: 0,
      type_sale: 0,
      weight_b: 0,
      weight: '',
    }
    this.setState({
      name: '',
      code: '',
      description: '',
      button_class: false,
      type_sale: 1,
      type_weight: 1,
      price_tag: '',
      cost: '',
      weight: '',
      weight_g: '',
      categories_id: '',
      product_types_id: '',
      search_id: '',
      search_categories_id: '',
      search_product_types_id: '',
      search_weight: '',
      search_weight_g: '',
      search_code: '',
      search_name: '',
      search: search,
      msg_error: {}
    })
    // this.resetProduct(search)
  }

  clearFormSearch() {

    // let search = this.state.search;
    // for (let i in search) {
    //   search[i] = 0;
    // }
    let search = {
      category_id: 0,
      product_types_id: 0,
      pattern_id: 0,
      product_id: 0,
      type_sale: 0,
      weight_b: 0,
      weight: '',
    }
    this.setState({
      name: '',
      code: '',
      description: '',
      button_class: false,
      type_sale: 1,
      type_weight: 1,
      price_tag: '',
      cost: '',
      weight: '',
      weight_g: '',
      categories_id: '',
      product_types_id: '',
      search_id: '',
      search_categories_id: '',
      search_product_types_id: '',
      search_weight: '',
      search_weight_g: '',
      search_code: '',
      search_name: '',
      search: search,
      msg_error: {}
    })

  }

  handleClick(e, d) {
    let row = this.product_list[d.positon];
    if (d.action == 'edit') {


      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        product: row
      });
    } else {
      this.setState({ confirm_open: true, object_id: row.id, object_category: row.category.id, object_kind: row.kind.id, object: row });
    }
  }
  handlePaginationChange(e, { activePage }) {

    this.setState({ page: activePage, loader_active: true, })
    this.loaddata(activePage)
  }

  addPattern(data) {
    var pattern = this.state.pattern
    pattern.push({
      key: data.id,
      value: data.id,
      text: data.name
    })
    this.setState({ pattern })
  }

  render() {
    const items = this.state.items;
    const { auth } = this.props
    return (
      <div>

        {this.state.import_open ?
          <ImportProduct
            data={this.state.items}
            onClose={() => {
              this.setState({
                import_open: false,
              });
            }}
            onUpdate={() => {
              this.setState({
                import_open: false,
              });
              this.componentDidMount()
            }} /> : ''}

        {this.state.modal_open ?
          <ProductFromModal
            product={this.state.product}
            onClose={() => { this.setState({ modal_open: false }) }}
            action={this.state.modal_action}
            onSave={(data) => {
              this.setState({ modal_open: false })
              // this.componentDidMount()
              // this.addPattern
              this.handlerSubmitSearch()
              this.componentDidMount()
            }}
            addPattern={this.addPattern}
          /> : ''}

        <Form size='small'>
          <Form.Group>

            <Form.Field width={6}>
              <Header floated='left' as='h2'>สินค้า</Header>
            </Form.Field>
            <Form.Field width={16}>

            </Form.Field>

            <Button id='addproduct' content='เพิ่ม' onClick={(e) => {
              e.preventDefault();
              this.setState({
                modal_open: true,
                modal_action: 'add',
                modal_title: 'สร้างสินค้า'
              });
            }}
              size='small'
              icon='plus' labelPosition='left' type='button' primary />
            {auth.role == 'A' || auth.role == 'S' ?
              <Button size='small' content='Import' onClick={(e) => {
                e.preventDefault();
                this.setState({
                  import_open: true,
                });
              }}
                icon='file alternate outline' labelPosition='left' type='button' primary />
              : ''}
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>

        <Form size='small'>
          <Form.Group>
            <Form.Field width={4}>
              <label>กลุ่มสินค้า</label>
              <Dropdown id='searchproductgroup' placeholder='กลุ่มสินค้า' search selection defaultValue={this.state.search.category_id} value={this.state.search.category_id} options={this.state.categories} onChange={this.handlerSearch} name='category_id' />
            </Form.Field>
            <Form.Field width={4}>
              <label>ประเภทสินค้า</label>
              <Dropdown id='searchproducttype' placeholder='ประเภทสินค้า' search selection defaultValue={this.state.search.product_types_id} value={this.state.search.product_types_id}
                options={this.state.product_types}
                onChange={this.handlerSearch} name='product_types_id' />
            </Form.Field>
            <Form.Field width={4}>
              <label>ประเภทงานขาย</label>
              <Dropdown id='searchproductsell' placeholder='ประเภทงานขาย' search selection defaultValue={this.state.search.type_sale} value={this.state.search.type_sale} options={this.state.type_sales} onChange={this.handlerSearch} name='type_sale' />
            </Form.Field>
            <Form.Field width={4}>
              <label>ลายทอง</label>
              <Dropdown id='searchpattern' placeholder='ลายทอง' search selection defaultValue={this.state.search.pattern_id} value={this.state.search.pattern_id} options={this.state.pattern} onChange={this.handlerSearch} name='pattern_id' />
            </Form.Field>
          </Form.Group>
          <Form.Group >
            <Form.Field width={4}>
              <label>น.น. บาท</label>
              <Dropdown id='searchproductweightb' placeholder='น.น. บาท' search selection defaultValue={this.state.search.weight_b} value={this.state.search.weight_b} options={this.state.weights} onChange={(e, v) => {
                let search = this.state.search;
                search.weight = (parseFloat(v.value) * 15.2).toFixed(3);
                search.weight_b = v.value
                if (v.value == 0) {
                  search.weight = ''
                }
                // this.resetProduct(search)
                this.setState({
                  search: search
                })
              }} name='weight' />
            </Form.Field>
            <Form.Field width={4}>
              <label>น.น. กรัม</label>
              <Input id='searchproductweightg'
                placeholder='น.น. กรัม'
                search selection
                className='text-right'
                onBlur={() => {
                  setTimeout(() => {
                    if (this.state.focus_g) {
                      this.setState({
                        focus_g: false,
                      });
                    }
                  }, 0);
                }}
                onFocus={() => {
                  if (!this.state.focus_g) {
                    this.setState({
                      focus_g: true,
                    });
                  }
                }}
                value={this.state.search.weight ?
                  this.state.focus_g ?
                    this.state.search.weight.toString().replace(/,/g, '')
                    : this.state.search.weight.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : ''}
                onChange={this.handlerSearch}
                name='weight'
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>รหัสสินค้า</label>
              <Dropdown
                id='searchproductid'
                placeholder='รหัสสินค้า'
                search selection
                defaultValue={this.state.search.product_id}
                value={this.state.search.product_id}
                options={this.state.products_code}
                onChange={this.handlerSearch}
                name='product_id'
                onSearchChange={(e, v) => {
                  this.resetProduct(v.searchQuery)
                }}
                loading={this.state.isLoading}
                isFetching={this.state.isLoading}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>ชื่อสินค้า</label>
              <Dropdown
                id='searchproductname'
                placeholder='ชื่อสินค้า'
                search selection
                defaultValue={this.state.search.product_id}
                value={this.state.search.product_id}
                options={this.state.products}
                onChange={this.handlerSearch}
                name='product_id'
                onSearchChange={(e, v) => {
                  this.resetProduct(v.searchQuery)
                }}
                loading={this.state.isLoading}
                isFetching={this.state.isLoading}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group className='text-right'>
            <Form.Field width={16}>
              <Button id='searchproduct' size='small' onClick={this.handlerSubmitSearch} type='button'><Icon name='search' />  ค้นหา</Button>
              <Button id='searchallproduct' size='small' onClick={(e) => {
                this.resetForm(e);
                setTimeout(() => {
                  this.componentDidMount();
                }, 400)
              }} type='button' >ทั้งหมด</Button>
              <Button id='clearSearch' onClick={this.clearFormSearch} size='small' type='button' >รีเซ็ต</Button>
            </Form.Field>
          </Form.Group>

        </Form>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={402}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='category__name'
                sortDir={this.state.colSortDirs.category__name}>
                กลุ่มสินค้า
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} data={items} field="category_name" />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='kind__name'
                sortDir={this.state.colSortDirs.kind__name}>
                ประเภทสินค้า
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} data={items} field="prodcut_type_name" />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='code'
                sortDir={this.state.colSortDirs.code}>
                รหัสสินค้า
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={180} id='tableproductid' data={items} field="code" />
              }
              width={180}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='name'
                sortDir={this.state.colSortDirs.name}>
                ชื่อสินค้า
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={300} data={items} field="name" />
              }
              width={300}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='pattern__name'
                sortDir={this.state.colSortDirs.pattern__name}>
                ลายทอง
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={170} id='tablepattern' data={items} field="pattern_name" />
              }
              width={170}
            />
            <Column
              header={<SortHeaderCell
                className="text-right"
                onSortChange={this.onSortChange}
                field='weight'
                sortDir={this.state.colSortDirs.weight}>
                น.น.(ก.)
          </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={100} data={items} field="weight" textAlign="text-right" />
              }
              width={100}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='type_sale'
                sortDir={this.state.colSortDirs.type_sale}>
                ประเภทงานขาย
          </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} data={items} field="type_sale_title" />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                className="text-right"
                onSortChange={this.onSortChange}
                field='price_tag'
                sortDir={this.state.colSortDirs.price_tag}>
                ราคาป้าย
          </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={120} data={items} field="price_tag" textAlign="text-right" />
              }
              width={120}
            />
            {/* <Column
            header={<Cell className="text-right">ต้นทุนชิ้นละ</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="cost" textAlign="text-right" />
            }
            width={120}
          /> */}
            {/* <Column
            header={<Cell className="text-right">คะแนน</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="score" textAlign="text-right" />
            }
            width={120}
          /> */}
            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="description" />
              }
              width={200}
            />
          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />

            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.page}
                onPageChange={this.handlePaginationChange.bind(this)}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>
        </Form>
        <Confirm
          id='deleteproduct'
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/products/' + this.state.object_id + "/";
            var self = this;
            Utility.delete(url, (status, data) => {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              } else {
                alert(data.error)
              }
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(mapStateToProps)(ProductsList);
