/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,
    Segment,
    Header,
    Button,
    Icon,
    Table,
    Pagination,
    Dropdown,
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import { connect } from 'react-redux'
import 'moment/locale/th';

class SummaryBranchReport extends Component {
    constructor(props) {
        super(props);
        const currentYear = moment().year();
        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
                start: moment().format('YYYY-MM'),
                end: moment().format('YYYY-MM'),
                branch: []
            },
            branch_option: [],
            item: [],
            total_item: 0,
            page_size: 5,
            activePage: 1,
            currentYear,
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.loadDataInterval = setInterval(() => {
            this.loadReportSummarySellGold(this.state.activePage)
        }, 5000);
    }

    componentDidMount() {
        let option = this.props.branches.map((b) => {
            return {
                text: b.name,
                value: b.id,
            }
        })
        this.setState({
            branch_option: option
        })
        this.loadReportSummarySellGold(this.state.activePage);
    }

    componentWillUnmount() {
        clearInterval(this.loadDataInterval)
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ activePage: activePage })
        this.loadReportSummarySellGold(activePage);
    }

    loadReportSummarySellGold(page) {
        let url = Settings.baseReportUrl + `/report/summary_branch/?page=${page}&page_size=${this.state.page_size}`
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    total_item: d.count,
                    item: d.results,
                    total_page: d.next == null && d.previous == null ? 0 : Math.ceil(d.count / this.state.page_size),
                })
            }
        })
    }

    resetForm(e) {
        this.setState({
            search: {
                start: moment().format('YYYY-MM'),
                end: moment().format('YYYY-MM'),
                branch: [],
            }
        })
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        this.resetForm()
        let url = Settings.baseUrl + "/report/summary_branch/"
        Utility.post(url, search, (s, d, status) => {
            if(status !== 200){
                alert('มีข้อผิดพลาดในระบบ')
            }
            this.loadReportSummarySellGold(1);
            this.setState({
                activePage: 1,
            })
        })
    }

    delReportSummarySellGold(id) {
        if (window.confirm('ยืนยันลบ')) {
            let url = Settings.baseReportUrl + `/report/summary_branch/${id}/`
            Utility.delete(url, (s, d) => {
                if (s == true) {
                    var tmppage = this.state.item.length == 1 ? this.state.activePage == 1 ? 1 : this.state.activePage - 1 : this.state.activePage
                    this.loadReportSummarySellGold(tmppage);
                    this.setState({
                        activePage: tmppage,
                    })
                }
            })
        }
    }

    formatThaiMonth = (dateString) => {
        const thaiMonth = moment(dateString).locale('th').format('MMMM');
        const thaiYear = moment(dateString).locale('th').format('YYYY');
        return `${thaiMonth} ${thaiYear}`;
    };

    getMonthOptions = () => {
        const { currentYear } = this.state;
        const options = [];
        const minYear = '2020-08';
        const maxYear = currentYear;
        const currentMonth = moment().format('YYYY-MM');

        for (let year = maxYear; year >= 2020; year--) {
            const startMonth = year === maxYear ? moment().month() + 1 : 12;

            for (let month = startMonth; month >= 1; month--) {
                const monthString = `${year}-${month.toString().padStart(2, '0')}`;
                const thaiMonth = this.formatThaiMonth(monthString);
                options.push({
                    key: monthString,
                    value: monthString,
                    text: `${thaiMonth}`,
                });
            }
        }
        return options.filter((option) => option.value <= currentMonth && option.value >= minYear);
    };



    getEndMonthOptions = () => {
        const start = moment(this.state.search.start);
        const end = moment(start).subtract(11, 'months');
        const options = this.getMonthOptions();

        return options.filter((option) =>
            moment(option.value).isBetween(end, start, 'month', '[]')
        );
    };

    render() {
        const tableStyle = {
            color: '#111',
            width: '100%',
            lineHeight: '20px',
        };
        const headerCellStyle = {
            textAlign: 'center',
            color: '#fff',
        }
        const tableHeaderStyle = {
            backgroundColor: '#db2828',
        }
        const textCenter = {
            textAlign: 'center'
        }

        return (
            <div>
                <Segment color='black' style={{
                    height: '90vh',
                    overflow: 'auto'
                }}>
                    <Form >
                        <Form.Group>
                            <Form.Field>
                                <Header floated='left' as='h4'>รายงานสรุปผลการขาย</Header>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <div className="box-login">
                        <Segment textAlign='left' >
                            <Header size='small'>เงื่อนไขค้นหา</Header>
                            <Form size='small' onSubmit={this.handlerSubmit}>
                                <Form.Field>
                                    <label>สาขา <small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                    <Dropdown
                                        placeholder='กรุณาเลือกสาขา'
                                        multiple
                                        search
                                        selection
                                        options={this.state.branch_option}
                                        value={this.state.search.branch}
                                        name='branch'
                                        onChange={(e, { value }) => {
                                            this.setState({
                                                search: {
                                                    ...this.state.search,
                                                    branch: value,
                                                }
                                            })
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>จากเดือน</label>
                                    <Dropdown
                                        selection
                                        options={this.getMonthOptions()}
                                        value={this.state.search.start}
                                        onChange={(e, { value }) => {
                                            let end = this.state.search.end
                                            if(moment(this.state.search.end).isAfter(value)){
                                                end = value
                                            }else if(moment(value).subtract(11, 'months').isAfter(end)){
                                                end = value
                                            }
                                            this.setState({
                                                search: {
                                                    ...this.state.search,
                                                    start: value,
                                                    end: end
                                                }
                                            })
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>ย้อนหลังถึงเดือน</label>
                                    <Dropdown
                                        selection
                                        options={this.getEndMonthOptions()}
                                        value={this.state.search.end}
                                        onChange={(e, { value }) => {
                                            this.setState({
                                                search: {
                                                    ...this.state.search,
                                                    end: value,
                                                }
                                            })
                                        }}
                                    // disabled={!startMonth}
                                    />
                                </Form.Field>
                                {/* <Form.Input
                                    label="จากเดือน"
                                    clearable={false}
                                    control={Form.Input}
                                    type="month"
                                    value={this.state.search.start}
                                    onChange={(e, { value }) => {
                                        let end = this.state.search.end
                                        if(moment(this.state.search.end).isAfter(value)){
                                            end = value
                                        }else if(moment(value).subtract(11, 'months').isAfter(end)){
                                            end = value
                                        }
                                        this.setState({
                                            search: {
                                                ...this.state.search,
                                                start: value,
                                                end: end
                                            }
                                        })
                                    }}
                                    max={moment().format('YYYY-MM')}
                                />
                                <Form.Input
                                    label="ย้อนหลังถึงเดือน"
                                    clearable={false}
                                    control={Form.Input}
                                    type="month"
                                    value={this.state.search.end}
                                    min={moment(this.state.search.start).subtract(11, 'months').format('YYYY-MM')}
                                    max={this.state.search.start}
                                    onChange={(e, { value }) => {
                                        this.setState({
                                            search: {
                                                ...this.state.search,
                                                end: value,
                                            }
                                        })
                                    }}
                                /> */}
                                <Button disabled={this.state.search.branch.length === 0}>พิมพ์</Button>
                                <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                            </Form>
                        </Segment>
                    </div>
                    {this.state.total_item > 0 &&
                        <>
                            <Table basic style={tableStyle}>
                                <Table.Header style={tableHeaderStyle}>
                                    <Table.Row>
                                        <Table.HeaderCell style={headerCellStyle} width={6}>ชื่อรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>วันที่ออกรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>สถานะรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={4}>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.state.item.map((item) => (
                                        <Table.Row >
                                            <Table.Cell>{item.filename}</Table.Cell>
                                            <Table.Cell style={textCenter}>{Utility.formatDate(item.created_at)} {Utility.formatTimeSecond(item.created_at)}</Table.Cell>
                                            <Table.Cell style={textCenter}>{item.status_display}</Table.Cell>
                                            <Table.Cell style={textCenter}>
                                                <Button onClick={() => this.delReportSummarySellGold(item.id)} color='red'><Icon name="trash" />ลบ</Button>
                                                <Button disabled={item.status == 0 ? true : false} target="__blank" href={`${Settings.mediaUrl + item.sub_path}`} color='blue'><Icon name="cloud download" />ดาวน์โหลด</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            <Form size='small'>
                                <Form.Group>
                                    <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.total_item)} />
                                    <Form.Field width={16}>
                                        <br />
                                        {this.state.total_page > 1 &&
                                            <Pagination
                                                floated='right'
                                                size='tiny'
                                                activePage={this.state.activePage}
                                                onPageChange={this.handlePaginationChange.bind(this)}
                                                totalPages={this.state.total_page}
                                            />
                                        }
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </>
                    }
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(SummaryBranchReport)