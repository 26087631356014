/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Checkbox, Popup, Label, Table, Radio
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      search: {
        user_type: "",
        user_id: "",
        user_name: "",
        start_date: moment(),
        end_date: moment(),
        branch:props.auth.branch == undefined ? 1 : props.auth.branch.id,
      },
      branchesAll:[],
      group:true,
      items: [],
      cal: [],
      new_data: [],
      user_type: [
        {
          value: "",
          key: 0,
          text: 'ทั้งหมด'
        },
        {
          value: "True",
          key: 1,
          text: 'พนักงาน'
        },
        {
          value: "False",
          key: 2,
          text: 'ลูกค้า'
        }
      ],
        choicedropdown: [
            {
                key: '1',
                text: 'ทั้งหมด',
                value: 'all',
            }, {
                key: '2',
                text: 'รับสินค้าแล้ว',
                value: 'receive',
            }, {
                key: '3',
                text: 'ยังไม่ได้รับสินค้า',
                value: 'not_recieve',
            }, {
                key: '4',
                text: 'หมดอายุ',
                value: 'expire',
            },{
              key: '5',
              text: 'ยกเลิก',
              value: 'cancel',
          },{
            key: '6',
            text: 'ยกเลิกจากผู้ใช้',
            value: 'user_cancel',
        }
          ],
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
    this.handlerDropdown = this.handlerDropdown.bind(this)
  }


  componentDidMount() {
    this.setState({ st: true })
    var url_string = window.location.toString();
    var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
    Promise.all([branches]).then((values) => {
    this.setState({branchesAll:values})
    });
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        search: {
          start_date: moment(temp_start, 'DD/MM/YYYY'),
          end_date: moment(temp_end, 'DD/MM/YYYY')
        }
      })
    }
  }
  handlerDropdown(event, value){
    this.setState({
            status: value.value,
        });
  }

  resetForm(e) {
    this.setState({
      status: 'all',
      search: {
        user_type: "",
        user_name: "",
        user_id: "",
        branch:this.props.auth.branch == undefined ? 1 : this.props.auth.branch.id,
        start_date: moment(),
        end_date: moment(),
      },
      group:true,
    })
  }

  async handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)
    search.start_date = Utility.formatDate2(search.start_date)
    search.end_date = Utility.formatDate2(search.end_date)
    let fromData = Utility.jsonToQueryString(search)
    var url = Settings.baseReportUrl + '/reward_order/?'
       url = url + `start_date=${search.start_date}&end_date=${search.end_date}&`
       url = url + `status=${this.state.status!=='all'?this.state.status:''}&`
       url = url + `branch=${!this.state.group?this.state.search.branch:''}`
    
    // let search_categories = []
    this.setState({
      loading: true
    })
    await Utility.get(url, (s, d) => {
      if (s == true) {
        this.setState({
          items: d,
        })
        this.setState({
          loading:false,
          open: true,
        })
      }
    })
    var url = Settings.baseReportUrl + "/customers/"
    Utility.get(url, (s, d) => {
      if (s == true){
        this.setState({
          all_customer: d.count
        })
      }
    })
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search,
      items:[]
    })
  }
  render() {
    let data = this.props.data
    let title = 'รายงานแลก AA Gold (รับที่สาขา)';
    let filename = `Redeem_Store_Reward_${moment().format('DDMMYYYY')}`;

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }

    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center',
      'width': '200px'

    }
    const borderRight = {
      'border-right': '1px'
    }
    const textU = {
      'text-decoration': 'underline'
    }
    let col = !this.state.group?'12':'13'
    let totalproducts = 0
    let totalpoints =0
    let totalprice =0
    let text = ''
    var branch_name = ''
    if (this.state.branchesAll.length>0) {
          let b = Utility.getObject(this.state.branchesAll[0], this.state.search.branch)
          if (b){
          branch_name = b.name}
        }
    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>รายงานแลก AA Gold (รับที่สาขา)</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >
            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
            <Form.Field>
              <Form.Dropdown
                    id='searchStatus'
                    placeholder={'เลือกดู'} fluid
                    label={'สถานะ'}
                    width={this.props.width}
                    selection
                    name={this.props.name}
                    loading={this.state.isLoading}
                    isFetching={this.state.isLoading}
                    value={this.state.status}
                    options={this.state.choicedropdown}
                    disabled={this.state.isLoading}
                    onChange={this.handlerDropdown} />
              </Form.Field>
              <Form.Field
                                control={Checkbox}
                                label='สาขาทั้งหมด'
                                // value='1'
                                checked={this.state.group}
                                onChange={(e, data) => {
                                    this.setState({ group: !this.state.group });
                                    
                                }}
                            />
              {!this.state.group&&<Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
               </Form.Field>}
              <>
              <Form.Field>
                  <label>จากวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.start_date}
                      selected={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          if (this.state.search.end_date < date) {
                              this.handlerInput(null, {
                                  name: 'end_date',
                                  value: date
                              });
                          }
                          this.handlerInput(null, {
                              name: 'start_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              <Form.Field >
                  <label>ถึงวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.end_date}
                      selected={this.state.search.end_date}
                      minDate={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          this.handlerInput(null, {
                              name: 'end_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              </>
              <Button>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='paperA4-portrait'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan={col} ><center>รายงานแลก AA Gold (รับที่สาขา)</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell colSpan={col} ><center>สาขา : {!this.state.group?branch_name:'ทั้งหมด'}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell colSpan={col} ><center>วันที่ {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan = '2' style={{textAlign: "left"}}>สถานะ : {this.state.status=='all'?'ทั้งหมด':this.state.choicedropdown.find((item) => { return item.value === this.state.status } ).text}</Table.HeaderCell>
                      <Table.HeaderCell colSpan = {this.state.group?'11':'10'} ></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '5%'}}>No.</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '10%'}}>รหัสลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "left",width: '13%',minWidth: '13%',maxWidth: '250px'}}>ลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '7%'}}>เลขที่รายการ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '10%',minWidth: '10%',maxWidth: '250px'}}>วันที่ทำรายการ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "left",width: '12%'}}>สินค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '10%'}}>สถานะในการรับสินค้า</Table.HeaderCell>
                      {this.state.group&&<Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '5%'}}>สาขา</Table.HeaderCell>}
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>จำนวน</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>ราคา/ชิ้น</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>แต้ม/ชิ้น</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>ราคารวม</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>แต้มรวม</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {
                    this.state.items.map((row, i) =>{
                        
                    // totalpoints = totalpoints + parseInt(row.status=='cancel'?'0':row.point)
                      return(<Table.Row key={i}>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '5%'}}>{i+1}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '10%'}}>{row.customer.code}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "left",minWidth: '13%',maxWidth: '250px',overflowWrap: 'break-word'}}>{row.customer.name}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",minWidth: '7%',maxWidth: '250px',overflowWrap: 'break-word'}}>{row.order_number}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '12%'}}>{Utility.formatDate(row.create_at)}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "left",maxWidth: '12%',maxWidth: '250px',overflowWrap: 'break-word'}}>{row.productorder.product.split(',').map((product,index)=> {return <div>{index+1}.{product}</div>})}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '5%'}}>{row.status_display}</Table.Cell>
                        {this.state.group&&<Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '5%'}}>{row.branchname}</Table.Cell>}
                        <Table.Cell colSpan = '1' style={{textAlign: "right",width: '5%',}}>{row.productorder.count.split(',').map((product)=> {
                            totalproducts = totalproducts+parseInt(product)
                            return <div>{Utility.numberFormat(product)}</div>})}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",width: '5%'}}>{row.productorder.price.split(',').map((product)=> {
                            return <div>{Utility.priceFormat(product)}</div>})}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",width: '5%'}}>{row.productorder.point.split(',').map((product)=> {
                            return <div>{Utility.priceFormat(product)}</div>})}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",width: '5%'}}>{row.productorder.price.split(',').map((product,index)=> {
                            text = parseInt(product)*parseInt(row.productorder.count.split(',')[index])
                            totalprice = totalprice+parseInt(text)
                            return <div>{Utility.priceFormat(text)}</div>})}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",width: '5%'}}>{row.productorder.point.split(',').map((product,index)=> {                   
                            text = parseInt(product)*parseInt(row.productorder.count.split(',')[index])
                            totalpoints = totalpoints+parseInt(text)
                            return <div>{Utility.priceFormat(text)}</div>})}</Table.Cell>
                      </Table.Row>)
                      }
                      )
                    }
                    <Table.Row >
                      <Table.Cell colSpan = {this.state.group?'7':'6'}> </Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "left"}}><b><u>รวมทั้งหมด</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.numberFormat(totalproducts)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '2' style={{textAlign: "left"}}></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.priceFormat(totalprice)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.priceFormat(totalpoints)}</u></b></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(()=>{
                mywindow.close();
              },2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)