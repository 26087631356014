/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Button,
  Icon,
  Input
} from 'semantic-ui-react';
import 'react-dates/lib/css/_datepicker.css';
import InputSearchCustomer from '../../Customer/InputSearchCustomer';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import CustomerModal from '../../Customer/CustomerModal';
var dateFormat = require('dateformat');

class BillInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bill_number: ''
    }

    this.handlerSelectSale = this.handlerSelectSale.bind(this);
    this.handlerCustomer = this.handlerCustomer.bind(this);
    this.handlerAddCustomer = this.handlerAddCustomer.bind(this);
    this.handlerCreateCustomer = this.handlerCreateCustomer.bind(this);

    this.handleResultSelect = this.handleResultSelect.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.goldPriceChangeHandler = this.goldPriceChangeHandler.bind(this)
  }

  handlerCreateCustomer(data) {
    this.setState({
      modal_open: false,
      customer_id: data.id
    })

    this.props.onCustomerChange({
      id: data.id,
      name: data.name,
      code: data.code,
      phone: data.phone
    });

  }

  handlerSelectSale(e, d) {

        this.props.onSelectSale(d.value);
      }

  handlerCustomer(e, d) {

    let data = Utility.getObject(this.customers, d.value);
    this.props.onCustomerChange({
      id: data.id,
      name: data.name,
      code: data.code,
      phone: data.phone
    });
  }

  componentDidMount() {
   
  }


  handlerAddCustomer(e) {
    e.preventDefault();
    this.setState({
      modal_open: true
    })
  }

  handleResultSelect(e, v) {
    this.props.onCustomerChange({
      id: v.value
    });
  }

  handleSearchChange(e, { value }) {
    this.loadCustomerPage(value);
  }

  loadCustomerPage(text) {

    this.setState({
      isLoading: true
    });

    let q1 = {
      columnKey: 'name',
      page: 0,
      sortDi: 'ASC',
      text: text
    };
    let q = Utility.jsonToQueryString(q1)

    Utility.get(Settings.baseUrl + '/customers/?is_enabled=1&limit=500&' + q, (status, data) => {

      if (status == true) {
        let result = []
        for (let i in data.result) {
          result.push({
            id: data.result[i].id,
            "title": data.result[i].name,
            "description": data.result[i].code,
          })
        }
        this.setState({
          isLoading: false,
          results: result,
        })
      }
    });
  }

  goldPriceChangeHandler(e,v) {
    this.props.onGoldPriceChange(v.value)
  }

  render() {
    return (
      <div>
        {this.state.modal_open?<CustomerModal open={true} onCreate={this.handlerCreateCustomer} onClose={() => { this.setState({ modal_open: false }) }} />:''}
        <Form className='attached fluid' size='small'>
          <Form.Group>
            <Form.Field width={16}
            disabled={this.props.is_void == 1 || this.props.action == 'edit' || this.props.is_disable}
            >
              <label>วันที่</label>
              <Input value={dateFormat(this.props.bill_date,"dd/mm/yyyy HH:MM")} readOnly />

            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            {this.props.kind != 'DF' && 
              <Form.Field width={16}
                disabled={ this.props.is_void == 1 || ( this.props.action == 'edit' && this.props.none_general_customer )}>
                <label>ลูกค้า</label>
                <div className="input-search-customer">
                <InputSearchCustomer placeholder='พิมพ์ชื่อลูกค้า'  onChange={this.handleResultSelect} name="customer_id" value={this.props.customer_id} />
                <Button id='addCustomerPOS' p size='small' className='btn-add-customer' icon onClick={this.handlerAddCustomer}>
                  <Icon name='add' />
                </Button>
                </div>
              </Form.Field>
            }
          </Form.Group>
          <Form.Group>
            <Form.Dropdown 
            loading={this.props.loading}
            label='*บันทึกพนักงานขาย' fluid multiple selection width={16}
              options={this.props.staffs}
              value={this.props.saleList}
              onChange={this.handlerSelectSale} 
              placeholder='บันทึกพนักงานขาย'
              disabled={this.props.is_void == 1 || this.props.action == 'edit' || this.props.is_disable}
              style={{'word-break':'break-word'}}
            />
          </Form.Group>
          <Form.Group>
            <Form.TextArea label='หมายเหตุ' placeholder='' width={16} value={this.props.description} placeholder='หมายเหตุ' onChange={(e, d) => {
              this.props.ondescription(d.value)
            }} 
            disabled={this.props.is_void == 1 || (this.props.role != 'A' && this.props.role != 'M' && this.props.role != 'S' && (this.props.action != 'add' || this.props.is_disable))}
            />
          </Form.Group>
          <Form.Group>
            {this.props.kind != 'DF' && this.props.action != 'edit' && 
              <Form.Dropdown 
              loading={this.props.loading}
              label='ราคาทอง' fluid selection width={16}
                options={this.props.gold_price_period}
                value={this.props.gold_price_value}
                onChange={this.goldPriceChangeHandler} 
                disabled={this.props.gold_price_period.length == 1 || this.props.bill_number != '' || 
              (this.props.billItems.length > 0)}
                style={{'word-break':'break-word'}}
                openOnFocus={false}
              />
            }
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default BillInfo;
