/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Cell
  } from 'fixed-data-table';
  import {
    Icon
  } from 'semantic-ui-react';

class OptionItemsCell extends Component {
    constructor(props) {
      super(props);
  
      this.state = {}
      this.handleClick = this.handleClick.bind(this);
    }
  
    handleClick(e,v){
  
      //this.props.onClickMenu(e);
    }
  
    render() {
      const { rowIndex, field, data, ...props } = this.props;
      return (
        <Cell><center style={{padding: '4px'}}>
          <a id={rowIndex+"_btnEdit"} onClick={(e)=>{this.props.onClickMenu(e,{ action: 'edit',positon: rowIndex })}}><Icon  name='edit'  /></a>
          <a id={rowIndex+"_btnDelete"} onClick={(e)=>{this.props.onClickMenu(e,{ action: 'delete',positon: rowIndex })}} ><Icon name='window close outline'  /></a>
        </center></Cell>
      );
    }
  }

  export default OptionItemsCell;