/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Button, Modal
} from 'semantic-ui-react';
import { hotkeys } from 'react-keyboard-shortcuts'
import Utility from '../../../Utility';


class CoverBookPrintPreview extends Component {

    constructor(props) {
        super(props)

        this.handlerPrint = this.handlerPrint.bind(this)

    }

    componentDidMount() {
        setTimeout(() => {
            this.handlerPrint()
        }, 800)
        setTimeout(() => {
            this.props.onClose()
        }, 1800)
    }


    handlerPrint() {
        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
        mywindow.document.write('<style>@page {size: A5;margin: 0mm;}@media only print { body { font-family: monospace; }}</style>');
        mywindow.document.write('<html><head><title>' + this.props.title + '</title>');
        mywindow.document.write('</head><body style="font-size: 18px;font-family: monospace;">');
        mywindow.document.write(document.getElementById('view-print').innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        setTimeout(()=>{
            mywindow.close();
        },2000)
    }

    render() {

        const { savings } = this.props
        return (<div>
            <Modal open={true} /*dimmer='blurring'*/>
                <Button
                    id='closePreview'
                    circular
                    icon='close'
                    basic
                    floated='right'
                    name=''
                    onClick={this.props.onClose} />
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    <div id='view-print'>
                        <div id='paperbook' style={{ 'page-break-before': 'always', marginLeft: '0.2cm' }}>
                            <table style={{ 'font-size': '16px', 'font-family': 'monospace', 'line-height': '26px', marginTop: '12.6cm' }} >
                                <tbody >
                                    <tr style={{ 'height': '27px' }}>
                                        <td style={{ width: '4cm', }}></td>
                                        <td>{savings.name}</td>
                                    </tr>
                                    <tr style={{ 'height': '27px' }}>
                                        <td style={{ width: '4cm' }}></td>
                                        <td>{savings.number}</td>
                                    </tr>
                                    <tr style={{ 'height': '27px' }}>
                                        <td style={{ width: '2cm' }}></td>
                                        <td>{savings.branch.name}</td>
                                    </tr>
                                    <tr style={{ 'height': '27px' }}>
                                        <td style={{ width: '2cm' }}></td>
                                        <td>{savings.customer.address} {savings.customer.district != '' && 'ต.'}{savings.customer.district} {savings.customer.city != '' && 'อ.'}{savings.customer.city} {savings.customer.province != '' && 'จ.'}{savings.customer.province} {savings.customer.postal_code}</td>
                                    </tr>
                                    <tr style={{ 'height': '27px' }}>
                                        <td style={{ width: '4cm' }}></td>
                                        <td>{savings.customer.mobile}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </Modal.Content>

                <Modal.Actions>
                    <Button primary icon='print' size='small' onClick={this.handlerPrint} labelPosition='right' content='พิมพ์ (F8)' />
                </Modal.Actions>
            </Modal>
        </div>)
    }

    hot_keys = {
        'f8': {
            priority: 1,
            handler: (event) => {
                this.handlerPrint()
                event.preventDefault()
            }
        },
        'esc': {
            priority: 1,
            handler: (event) => {
                this.props.onClose()
                event.preventDefault()
            }
        }
    }
}

export default hotkeys(CoverBookPrintPreview)