/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Cell
  } from 'fixed-data-table';
  import {
    Icon
  } from 'semantic-ui-react';

class OptionItemsCell extends Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
  
  
    render() {
      const { action, rowIndex, field, data, ...props } = this.props;
      return (
        <Cell><center style={{padding: '4px'}}>
          {action.indexOf('edit')>=0 && <a onClick={(e)=>{this.props.onClickMenu(e,{action:'edit',positon: rowIndex})}}><Icon  name='edit'  /></a>}
        </center></Cell>
      );
    }
  }

  export default OptionItemsCell;