/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Button, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';

class GroupRow extends Component {
    render() {
        const textRight = {
            'text-align': 'right',
            'font-weight': 'normal',
        }

        const textLeft = {
            'text-align': 'left',
            'font-weight': 'normal',
        }
        const textCenter = {
            'text-align': 'center',
            'font-weight': 'normal',
        }
        const textU = {
            'text-decoration': 'underline',
        }
        const stock = this.props.data

        return (<Table.Body>
            {
                stock.map((category_r) => {
                    return <>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2' style={{ 'text-align': 'left' }}>{category_r.category}</Table.HeaderCell>
                            <Table.HeaderCell colSpan='5'></Table.HeaderCell>
                        </Table.Row>
                        {
                            category_r.data.map((kind_r) => 
                                <>
                                    <Table.Row>
                                        <Table.HeaderCell ></Table.HeaderCell>
                                        <Table.HeaderCell style={{ 'text-align': 'left' }}>{kind_r.kind}</Table.HeaderCell>
                                        <Table.HeaderCell colSpan='5'></Table.HeaderCell>
                                    </Table.Row>
                                    {
                                        kind_r.data.map((weight_r) => 
                                            <>
                                                {
                                                    weight_r.data.map((product) => 
                                                        <Table.Row>
                                                            <Table.HeaderCell ></Table.HeaderCell>
                                                            <Table.HeaderCell style={textLeft}>&nbsp;&nbsp;{product.product_name}</Table.HeaderCell>
                                                            <Table.HeaderCell style={textCenter}>{product.product_code}</Table.HeaderCell>
                                                            <Table.HeaderCell style={textRight}>{Utility.weightFormat(product.weight)}</Table.HeaderCell>
                                                            <Table.HeaderCell style={textRight}>{Utility.numberFormat(product.amount)}</Table.HeaderCell>
                                                            <Table.HeaderCell style={textRight}>{Utility.weightFormat(product.total_weight)}</Table.HeaderCell>
                                                            <Table.HeaderCell style={textCenter}>&nbsp;&nbsp;{product.branch}</Table.HeaderCell>
                                                        </Table.Row>
                                                    )
                                                }
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan='4' style={textRight}><b>รวม</b></Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}><b style={textU}>{Utility.numberFormat(weight_r.sum_amount)}</b></Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}><b style={textU}>{Utility.weightFormat(weight_r.sum_weight)}</b></Table.HeaderCell>
                                                </Table.Row>
                                            </>
                                        )
                                    }
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='4' style={textRight}><b>รวม {kind_r.kind}</b></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><b style={textU}>{Utility.numberFormat(kind_r.sum_amount)}</b></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><b style={textU}>{Utility.weightFormat(kind_r.sum_weight)}</b></Table.HeaderCell>
                                    </Table.Row>
                                </>
                            )
                        }
                        <Table.Row>
                            <Table.HeaderCell colSpan='4' style={textRight}><b>รวม {category_r.category}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.numberFormat(category_r.sum_amount)}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.weightFormat(category_r.sum_weight)}</b></Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                        </Table.Row>
                    </>
                })
            }
        </Table.Body>)
    }
}

class ProductDetailPrintPreview extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let title = 'รายงานสต็อกทองใหม่';
        let filename = 'stock-prodcut';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        //category=null

        return (<div>
            <Modal open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    <div id='view-print'>
                        <div id='paperA4-portrait'>
                            <Table basic id='table-to-xls' style={divStyle}>
                                <Table.Header>
                                    <Table.Row >
                                        <Table.HeaderCell colSpan='7'><center>{title}</center></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.HeaderCell colSpan='7'><center>{"สาขา: " + this.props.branch_name}</center></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={textCenter}></Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}></Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>รหัสสินค้า</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>น.น./ชิ้น</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>น้ำหนักรวม(กรัม)</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <GroupRow data={this.props.items} />
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3' style={textRight}></Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>รวมทั้งหมด</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(this.props.sum_amount)}</div></Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(this.props.sum_weight)}</div></Table.HeaderCell>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                </Table.Row>
                            </Table>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="ui primary button small"
                        table="table-to-xls"
                        filename={filename}
                        sheet={title}
                        buttonText="Download as XLS" >
                    </ReactHTMLTableToExcel>

                    <Button id='btnPrint' primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(() => {
                            mywindow.close();
                        }, 2000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnClose' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default ProductDetailPrintPreview;