/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import ImportStaff from '../Import/ImportStaff'
import {
  Form, Input, Header, Dropdown, Button, Icon, Modal, Confirm, Dimmer, Loader, TextArea, Menu,
  Popup,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { connect } from 'react-redux'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

  }

  render() {
    const { rowIndex, auth } = this.props;
    return (
      <Cell>
        {auth.role != 'S' && auth.role != 'A' ?
          <center>
            <a title="ไม่สามารถแก้ไขได้" ><Icon disabled name='edit' /></a>
            <a title="ไม่สามารถลบได้" ><Icon disabled name='window close outline' /></a>
          </center>
          :
          <center>
            <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
            <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a>
          </center>
        }
      </Cell>
    );
  }
}

class ItemsCell extends Component {
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      username: '',
      password: '',
      first_name: '',
      modal_open: false,
      search: { branch: 0 },
      is_enabled: 1,
      msg_error: {},
      activeItem: 'แสดง',
      mobile: '',
      email: '',
    }
    this.submitData = this.submitData.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }
  handleItemClick = (e, { name }) => {
    this.setState({
      loader_active: true,
    });
    let status = 1
    if (name === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    let search = Utility.filterSearch(this.state.search)
    let str = Utility.jsonToQueryString(search);
    Utility.get(Settings.baseUrl + '/notification_reciever/?is_enabled=' + status + '&' + str, (s, data) => {
      this.setState({
        loader_active: false,
        items: data,
      });
    })
    this.setState({ activeItem: name })
  }

  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };
  submitData() {
    let msg_error = {}

    if (this.state.name == '' || this.state.name == undefined) {
      msg_error['name'] = 'กรุณากรอกชื่อ'
    }

    if (this.state.email == '' || this.state.email == undefined) {
      if (this.state.mobile == '' || this.state.mobile == undefined) {
        msg_error['email'] = 'กรุณากรอกอีเมล'
        msg_error['mobile'] = 'กรุณากรอกเบอร์มือถือ'
      }
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error
      })
      return;
    }

    var formData = {
      mobile: this.state.mobile == null ? '' : this.state.mobile,
      name: this.state.name == null ? '' : this.state.name,
      email: this.state.email == null ? '' : this.state.email,
      is_enabled: this.state.is_enabled
    };
    const self = this;

    this.setState({ button_loading: 'loading', msg_error: {} })
    if (this.state.modal_action === 'add') {
      const url = Settings.baseUrl + '/notification_reciever/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_loading: '' })
        if (status) {
          if (data.id === null) {
            self.setState({
              button_loading: false
            });
          } else {
            self.setState({
              modal_open: false,
              button_loading: false
            });
            self.componentDidMount();
            self.resetForm();
            alert('บันทึกข้อมูลสำเร็จ!')
          }

        } else { // error
          if (data.length !== 0) {
            if (data.code != null) {
              alert('กรุณาแจ้ง manager /admin / superadmin ให้ตั้งค่าเลขที่เริ่มต้นรหัสพนักงานของสาขา' + this.state.object_branch[this.state.branch].name + 'ให้ถูกต้อง')
            }
            if (data['branch'] == 'Invalid pk "0" - object does not exist.') {
              data['branch'] = 'ไม่สามารถเลือก -ทั้งหมด- ได้'
            }
            this.setState({
              msg_error: data
            })
          }
        }
      });
    } else {
      const url = Settings.baseUrl + '/notification_reciever/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              button_loading: '',
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {

    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }
    search['branch'] = 0
    this.setState({
      mobile: '',
      name: '',
      email: '',
      button_loading: false,
      search: search,
      msg_error: {},
      is_enabled: 1,
    });
    this.componentDidMount()
  }

  componentDidMount() {
    var self = this;

    this.setState({
      loader_active: true
    });
    let status_a = 1
    if (this.state.activeItem != 'แสดง') {
      status_a = 0
    }
    Utility.get(Settings.baseUrl + '/notification_reciever/?is_enabled=' + status_a, (status, data) => {
      var notification_reciever = [];

      for (let i in data) {
        notification_reciever.push({
          key: data[i].id,
          value: data[i].id,
          text: data[i].name,
        });
      }

      self.setState({
        loader_active: false,
        notification_reciever: notification_reciever,
        items: data
        // activeItem: 'แสดง',
      });
    });

  }

  handlerSearch(event, v) {
    if (v.name) {
      let search = this.state.search;
      search[v.name] = v.value;
      if (v.name == 'text') {
        search[v.name] = v.value.trim();
      }
      this.setState({
        search: search
      });
      return;
    } else {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let search = this.state.search;
      search[name] = value;
      this.setState({
        search: search
      });
    }
  }

  handlerSubmitSearch(e) {
    this.setState({
      loader_active: true
    });
    let search = Utility.filterSearch(this.state.search)
    let str = Utility.jsonToQueryString(search);
    let active = this.state.activeItem == 'แสดง' ? 1 : 0
    Utility.get(Settings.baseUrl + '/notification_reciever/?is_enabled=' + active + '&' + str, (s, data) => {
      this.setState({
        loader_active: false,
        items: data,
        // activeItem: 'แสดง',
      });
    })
  }

  handleDelete(e, staff) {

    if (window.confirm('ยืนยันลบรายชื่อผู้รับแจ้งเตือน')) {
      Utility.delete(Settings.baseUrl + "/notification_reciever/" + staff.id + "/", (s, r, code) => {

        if (s) {
          this.componentDidMount();
        } else {
          alert(r.error)
        }
      })

    }

  }

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action === 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: row.name,
        mobile: row.mobile,
        email: row.email,
        object_id: row.id,
        object: row,
        is_enabled: row.is_enabled
      });
    } else {
      this.handleDelete(e, row)
      //this.setState({ confirm_open: true, object_id: row.id ,object: row});
    }
  }

  render() {
    const items = this.state.items;
    const { auth } = this.props
    // const stateOptions = this.state.options_branch === null ? [] : this.state.options_branch;
    const optionBranch = this.state.branch_select === null ? [] : this.state.branch_select;
    return (
      <div>

        {this.state.import_open ?
          <ImportStaff
            data={this.state.items}
            onClose={() => {
              this.setState({
                import_open: false,
              });
            }}
            onUpdate={() => {
              this.setState({
                import_open: false,
              });
              this.componentDidMount()
            }} /> : ''}
        <Form >
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>ผู้รับแจ้งเตือน</Header>
            </Form.Field>
            <Form.Field width={10}>
              {auth.role == 'A' || auth.role == 'S' ?
                <Button
                  id='addstaff'
                  size='small' content='เพิ่ม' onClick={(e) => {
                    e.preventDefault();
                    this.resetForm();
                    this.setState({
                      modal_open: true,
                      modal_action: 'add',
                      modal_title: 'สร้างรายชื่อผู้รับแจ้งเตือน',

                    });
                  }}
                  floated='right' icon='plus' labelPosition='left' type='button' primary />
                : ''}

            </Form.Field>
          </Form.Group>
        </Form>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='mini' /*dimmer='blurring'*/  >
          <Button
            id='closeAddstaff'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.name}>
                  <label>*ชื่อ <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id="nameStaff" placeholder='ชื่อ' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={100} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.mobile}>
                  <label>*เบอร์มือถือ <MsgInput text={this.state.msg_error.mobile} /></label>
                  <Input id="mobileNoti"
                    placeholder='เบอร์มือถือ'
                    onChange={(e) => {
                      let mobile = e.target.value
                      if (isNaN(Number(mobile))) {
                      } else {
                        this.setState({ mobile: mobile })
                      }
                    }}
                    type="tel"
                    value={this.state.mobile}
                    maxLength={10} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.email}>
                  <label>*อีเมล <MsgInput text={this.state.msg_error.email} /></label>
                  <Input id="mobileNoti" placeholder='อีเมล' onChange={(e) => this.setState({ email: e.target.value })} type="email" value={this.state.email} maxLength={100} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown id="statusNoti" selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />
                </Form.Field>
                <label style={{ 'color': 'grey' }}>กรุณากรอก อีเมล หรือ เบอร์มือถือ อย่างใดอย่างหนึ่ง หรือ ทั้งสอง</label>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id='submitAddstaff'
              size='small' primary onClick={this.submitData}
              className={this.state.button_loading ? 'loading' : ''}>บันทึก</Button>
            <Button
              id="cancelAddstaff"
              size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <label>ชื่อผู้รับแจ้งเตือน</label>
              <Input maxLength={255} id='searchStaff' placeholder='ชื่อ,อีเมล,เบอร์มือถือ' value={this.state.search.text} onChange={this.handlerSearch} name='text' />
            </Form.Field>

            <Form.Field width={6}>
            <label><br/></label>
              <Button id='btnsearchStaff' onClick={this.handlerSubmitSearch} size='small' type='button'><Icon name='search' /> ค้นหา</Button>
              <Button id='allStaff' onClick={this.resetForm} size='small' type='button' >ทั้งหมด</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell auth={auth} onClickMenu={this.handleClick} />}
            />

            <Column
              header={<Cell>ชื่อ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="name" />
              }
              width={200}
            />
            <Column
              header={<Cell>อีเมล</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="email" />
              }
              width={200}
            />
            <Column
              header={<Cell>เบอร์มือถือ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="mobile" />
              }
              width={200}
            />
          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {

            const url = Settings.baseUrl + '/staffs/' + this.state.object_id + "/";
            var staffs = this.state.object
            staffs.is_enabled = 0
            staffs.branch = staffs.branch.id

            var self = this;
            Utility.put(url, staffs, function (status, data) {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              }
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(mapStateToProps)(Notifications);
