/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import {
  Table,
  Column,
  Cell,
} from 'fixed-data-table';

import {
  Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid,
  Popup,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import StockWaitMeltTable from './StockWaitMeltTable'

class ItemsCell extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props} >
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}
class StockWaitMeltDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      table_hiegh: Settings.table_hiegh,
      number: '',
      branch: '',
      creator: '',
      bill_date: '',
      record_date: '',
      description: '',
      total_weight: '',
      weight_real: '',
    }
  }


  componentDidMount() {
    this.setState({
      loader_active: true
    });
    var invoice = Utility.getFetch(Settings.baseUrl + '/invoices_old/' + this.props.id);
    var items = Utility.getFetch(Settings.baseUrl + '/invoices_old/' + this.props.id + '/items');
    Promise.all([invoice, items]).then((values) => {
      this.setState({
        number: values[0].number,
        branch: values[0].branch_name,
        creator: values[0].user_display,
        bill_date: Utility.formatDate(values[0].bill_date),
        record_date: Utility.formatDate(values[0].record_date),
        description: values[0].description,
        total_weight: values[0].weight,
        weight_real: values[0].weight_real,
        items: this.setValue2(values[1])
      })
      // this.setState({loader_active: false})
      // this.setState({totalPages: Math.ceil(values[0].count / 10)})
      // this.setState({
      //     loader_active: false,
      //     items: this.setValue2(values[0].results),
      //     totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
      // });
      // this.setState({ table_width: window.innerWidth, table_hiegh: window.innerHeight - 260 });
    })
  }

  setValue2(items) {
    for (let i = 0; i < items.length; i++) {
      items[i]['category_title'] = items[i].category_title
      items[i]['product_name'] = items[i].product_old.name
      items[i]['weight'] = Utility.weightFormat(items[i].product_old.weight)
      items[i]['cost'] = Utility.priceFormat(items[i].product_old.cost)
    }

    return items;
  }

  render() {
    const items = this.state.items;
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name=''
          onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              <span id='textBillID'>เลขที่ห่อ {this.state.number}</span>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form className=' fluid' size='small' >
                        <Form.Field width={16} >
                          <label>สาขา</label>
                          <Input id='branch'
                            width={16}
                            value={this.state.branch}
                            readOnly
                          />
                        </Form.Field>
                        <Form.Field width={16} >
                          <label>ทำรายการโดย</label>
                          <Input id='creator'
                            width={16}
                            value={this.state.creator}
                            readOnly
                          />
                        </Form.Field>
                        <Form.Field width={16}>
                          <label>วันที่บิล</label>
                          <Input
                            id='date'
                            value={this.state.bill_date}
                            readOnly
                          />
                        </Form.Field>
                        <Form.Field width={16}>
                          <label>วันที่ทำรายการ</label>
                          <Input
                            id='date'
                            value={this.state.record_date}
                            readOnly
                          />
                        </Form.Field>

                        <br />
                        <Form.TextArea
                          label='หมายเหตุ'
                          width={16}
                          readOnly
                          value={this.state.description}
                        />
                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={6}>
                            <Header floated='left' as='h3'>รายการสินค้า</Header>
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <StockWaitMeltTable items={items} table_width={this.state.table_width} />
                      <br />
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input
                            labelPosition='left'
                            id='weighttotal'
                            label='น้ำหนักรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.total_weight}
                            readOnly />
                          <Form.Input
                            labelPosition='left'
                            id='weightreal'
                            label='น้ำหนักชั่งห่อ'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.weight_real}
                            readOnly />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>

        </Modal.Content>
      </Modal>

    );
  }
}


export default StockWaitMeltDetail;