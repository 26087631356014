/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        this.setState({ st: true })
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([product_types]).then((values) => {

            this.product_types = values[0];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                product_types: product_types,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
        let search = this.state.search
        if (localStorage.getItem("product_kind") != null)
            search['product_type'] = JSON.parse(localStorage.getItem("product_kind"))
        this.setState({
            search: search
        })
    }

    resetForm(e) {
        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            }
        })
        localStorage.setItem('product_kind','[]')
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        if (search.product_type && search.product_type.length == 0) {
            delete search["product_type"]
        }

        let fromData = Utility.jsonToQueryString(search)
        //get

        let url = Settings.baseReportUrl + "/commission/report/?" + fromData
        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.data)
                })
            }
        })
    }

    setFieldValue(_items) {
        return _items
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        if (v.name == 'product_type')
            localStorage.setItem('product_kind', JSON.stringify(search[v.name]))
        this.setState({
            search: search
        })
    }
    render() {

        let title = 'รายงานค่าคอมมิชชันรวม';
        let filename = 'commission';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const staffWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }

        const { role, branch } = this.props.auth
        let total_sell = 0
        let total_lease = 0
        let total_commission_sell = 0
        let total_commission_lease = 0
        let total_commission_zone = 0

        for (let i in this.state.items) {
            let item = this.state.items[i]
            item.branch_sell = 0
            item.branch_lease = 0
            item.branch_commission_sell = 0
            item.branch_commission_lease = 0
            for (let j in item.data) {
                let item_data = item.data[j]
                item.branch_sell += item_data.sell
                item.branch_lease += item_data.lease
                item.branch_commission_sell += item_data.commission_sell
                item.branch_commission_lease += item_data.commission_lease
                total_sell += item_data.sell
                total_lease += item_data.lease
                total_commission_sell += item_data.commission_sell
                total_commission_lease += item_data.commission_lease
            }
            total_commission_zone += item.commission_zone
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานค่าคอมมิชชันรวม</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทสินค้า<small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown placeholder='ประเภทสินค้า' multiple search selection options={this.state.product_types} value={this.state.search.product_type} name='product_type' onChange={this.handlerInput} />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนชิ้นขาย</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดจำนำ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าคอมขาย</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าคอมจำนำ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่าคอมเขต</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>

                                        {this.state.items.map((row, i) => {
                                            return (
                                                <React.Fragment>
                                                    {row.data.map((row2, j) =>
                                                        <Table.Row key={j}>
                                                            <Table.Cell style={textCenter}>{row2.branch!==null?row2.branch.name:row2.name}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row2.sell)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row2.lease)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row2.commission_sell)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row2.commission_lease)}</Table.Cell>
                                                            {j == 0 ? <Table.Cell style={textCenter} rowspan={row.data.length}>{Utility.priceFormat(row.commission_zone)}</Table.Cell> : null}
                                                        </Table.Row>
                                                    )}
                                                    <Table.Row key={i}>
                                                        <Table.Cell style={textRight}><b>รวม</b></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(row.branch_sell)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(row.branch_lease)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(row.branch_commission_sell)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(row.branch_commission_lease)}</b></div></Table.Cell>
                                                        <Table.Cell style={textCenter}><div style={textU}><b>{Utility.priceFormat(row.commission_zone)}</b></div></Table.Cell>
                                                    </Table.Row>
                                                </React.Fragment>
                                            )


                                        })}

                                        <Table.Row >
                                            <Table.Cell style={textRight}><b>รวมทั้งหมด</b></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(total_sell)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_lease)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_commission_sell)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_commission_lease)}</b></div></Table.Cell>
                                            <Table.Cell style={textCenter}><div style={textU}><b>{Utility.priceFormat(total_commission_zone)}</b></div></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)