/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { hotkeys } from 'react-keyboard-shortcuts'
import {
    Form,
    Input,
    Header,
    Button,
    Segment,
    Icon,
    Label,
    Grid,
    Modal,
    Menu,
    Dimmer,
    Loader,
    TextArea,
    Table,
} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-dates/lib/css/_datepicker.css';

import Utility from '../../Utility';
import Settings from '../../Settings';
import CustomerModal from '../Customer/CustomerModal';
import ProductList from './ProductList';
import LeasePrinciple from './LeasePrinciple';
import LeaseInterest from './LeaseInterest';
import LeasePay from './LeasePay';
import LeaseInvoicePrintPreview from './LeaseInvoicePrintPreview'
import LeaseInvoicePrintPreviewV1 from './LeaseInvoicePrintPreviewV1'
import InputSearchCustomer from '../Customer/InputSearchCustomer';
import LeaseInvoiceQrPreview from './LeaseInvoiceQrPreview'
import dateFormat from 'dateformat';
import AccountDetail from '../Savings/AccountDetail'

import { open_modal_lease_product, lease_modal_close, lease_modal_edit } from '../../actions'
import { connect } from 'react-redux'


class LeaseForm extends Component {
    constructor(props) {
        super(props);

        const { lease_modal } = this.props
        const lease_data = lease_modal.data

        this.lease_product = [];
        this.setting = props.setting
        let auto_action_lease = localStorage.getItem('auto_action_lease')
        this.state = {
            amount: '',
            bill_number: '',
            citizen_id: '',
            table_width: 600,
            table_hiegh: 150,
            button_save_title: 'สร้าง',
            activeItem: auto_action_lease == 'add_delete' ? 'tab3' : 'tab2',
            w_content: 300,
            setting: props.setting,
            weight: 0,
            msg_error: {},
            description: '',
            modal_title: lease_modal.modal_action == 'add' ? '' : 'แก้ไข',
            total_interest: '',
            interest: '',
            phone: '',
            loadingQueue:false,
            loadingQueueEdit:false,
            lease_price_diff: 3000,
        }
        this.handlerCustomer = this.handlerCustomer.bind(this);
        this.handlerBillNo = this.handlerBillNo.bind(this);
        this.handlerAddCustomer = this.handlerAddCustomer.bind(this);
        this.handlerCreateCustomer = this.handlerCreateCustomer.bind(this)
        this.handlerMonth = this.handlerMonth.bind(this)
        this.handlerAmount = this.handlerAmount.bind(this)
        this.handlerInterest = this.handlerInterest.bind(this)
        this.handlerProductList = this.handlerProductList.bind(this)
        this.handlerSave = this.handlerSave.bind(this);
        this.handlerInput = this.handlerInput.bind(this)
        this.handlerClose = this.handlerClose.bind(this)
        this.handleItemClick = this.handleItemClick.bind(this)
        this.handleSubmitPay = this.handleSubmitPay.bind(this)
        this.handleInterest = this.handleInterest.bind(this)
        this.handlerReset = this.handlerReset.bind(this)
        this.loadGoldPrice = this.loadGoldPrice.bind(this)
        this.updateEnddate = this.updateEnddate.bind(this)
        this.handleSubmitPayNew = this.handleSubmitPayNew.bind(this)
        this.GetQueue = this.GetQueue.bind(this)
    }

    handlerCreateCustomer(data) {
        this.setState({
            modal_open: false,
            customer_id: data.id,
            citizen_id: data.citizen_id,
            customer: data.id,
            name: data.name,
            code: data.code,
            phone: data.mobile === null ? '' : data.mobile
        });

    }

    loadGoldPrice(bill_date) {
        let d = new Date(bill_date);
        let q = dateFormat(d, "yyyy-mm-dd HH:MM")
        let url = Settings.baseUrl + "/gold_price/?datetime=" + q;
        this.setState({ is_loading: true })
        Utility.get(url, (s, res) => {
            if (s && res.length > 0) {
                this.setState(res[0])
            }
        })
    }

    handlerSave(e, is_print = false) {
        e.preventDefault()
        const { auth, lease_modal } = this.props
        const lease_data = lease_modal.data
        if (this.state.customer_id == null || this.state.customer_id == '') {
            alert('กรุณาระบุลูกค้า')
            return false
        }

        if (this.state.citizen_id == null || this.state.citizen_id == '') {
            alert('กรุณาระบุเลขประจำตัวประชาชน')
            this.inputTaxNumber.focus()
            return false
        }
        if (this.state.phone == null || this.state.phone == '') {
            alert('กรุณาระบุเบอร์โทร')
            this.inputPhone.focus()
            return false
        }
        if (this.state.amount == null || this.state.amount == '' || this.state.amount == 0) {
            alert('กรุณาระบุจำนวนเงิน')
            this.inputAmount.focus()
            return false
        }

        if (this.state.month == null || this.state.month == '' || this.state.month == 0) {
            alert('กรุณาระบุระยะเวลา')
            this.inputMonth.focus()
            return false
        }

        if (this.state.interest == null || this.state.interest == '' || this.state.interest == 0) {
            alert('กรุณาระบุอัตราดอกเบี้ย')
            this.inputInterest.focus()
            return false
        }

        if (this.state.start_date == null || this.state.start_date == '') {
            alert('กรุณาระบุวันนำเข้า')
            return false
        }

        if (this.state.end_date == null || this.state.end_date == '') {
            alert('กรุณาระบุวันครบกำหนด')
            return false
        }
        if (this.state.description.length > 255) {
            alert('ความยาวหมายเหตุต้องไม่เกิน 255 ตัวอักษร')
            return false
        }

        if (lease_modal.modal_action == 'add') {

            if (this.lease_product.length === 0) {
                alert('กรุณาเพิ่มรายการสินค้า')
                return false
            }
            if (this.state.number.toString().substring(2) > 99999) {
                alert('กรุณาแจ้ง manager /admin / superadmin ให้ตั้งค่าเลขที่เริ่มต้นขายฝากของสาขา ' + this.props.auth.branch.name + ' ให้ถูกต้อง')
                return false
            }
            this.setState({
                is_print: is_print,
                open_pay: true
            });

        } else { // update
            let formData = {
                customer: this.state.customer_id,
                phone: this.state.phone,
                citizen_id: this.state.citizen_id,
                interest: this.state.interest,
                start_date: Utility.formatDate2(this.state.start_date),
                end_date: Utility.formatDate2(this.state.end_date),
                description: this.state.description
            };
            this.setState({
                loading_save: true
            })
            let url = Settings.baseUrl + "/lease/" + lease_data.id + "/"
            Utility.patch(url, formData, (s, d, code) => {

                if (s) {
                    alert('บันทึกข้อมูลสำเร็จ!');
                    this.setState({
                        id: d.id,
                        loading_save: false
                    })
                    this.props.reload(this.props.lease_modal.data.id);
                }
                else if (code == 403) {
                    alert('admin / manager เท่านั้นที่สามารถแก้ไขใบขายฝากได้')
                }
                else {
                    if (d['number']) {
                        alert(d['number'])
                    }
                    this.setState({
                        loading_save: false,
                        error: d
                    })
                }

            });
        }
    }

    handlerReset() {
        let state = this.state
        for (let k in state) {
            state[k] = ''
        }
        this.lease_product = []
        let elHeight = document.getElementById('content-body')
        this.setState(state)
        this.setState({
            bill_number: '',
            citizen_id: '-',
            table_width: 600,
            table_hiegh: 150,
            customer_id: '',
            button_save_title: 'สร้าง',
            activeItem: 'tab2',
            w_content: window.innerWidth,
            setting: this.props.setting,
            weight: 0
        })

        this.componentDidMount()
    }

    handlerCustomer(e, d) {
        let data = {}
        for (let i in d.options) {
            if (d.options[i].value == d.value) {
                data = d.options[i].data
                break
            }
        }
        this.setState({
            customer: data,
            customer_id: data.id,
            name: data.name,
            code: data.code,
            citizen_id: data.citizen_id,
            phone: data.mobile === null ? '' : data.mobile
        });
        // this.state.phone = data.mobile

        this.inputTaxNumber.focus()
    }

    handlerBillNo(e, d) {

    }

    handlerInput(e, d) {
        if (!d.value.match(/^([a-z0-9-])+$/i) && d.value.length > 0) {
        } else {
            this.setState({
                [d.name]: d.value
            });
        }
    }

    handlerMonth(e, d) {
        if (d.value.length > 2) {
            return;
        }
        const month = Utility.parseInt(d.value)
        this.setState({
            month,
            start_date: moment(),
            end_date: moment().add(parseInt(month), "M")
        })
    }

    componentDidMount() {
        const { role } = this.props.auth
        const { lease_modal } = this.props
        const lease_data = lease_modal.data

        if (lease_modal.modal_action == 'add') {
            this.loadNextNumber()
        }

        Utility.get(Settings.baseUrl + "/customers/", (e, resJson) => {
            this.customers = resJson;
            let customers = []
            let customers_code = []
            for (let i in resJson) {
                customers.push({
                    value: resJson[i].id,
                    key: resJson[i].id,
                    text: resJson[i].name,
                    content: <Header as='h4' content={resJson[i].name} subheader={resJson[i].code} />
                });
                customers_code.push({
                    value: resJson[i].id,
                    key: resJson[i].id,
                    text: resJson[i].code
                });
            }
            this.setState({
                customers: customers,
                customers_code: customers_code,
                button_save_title: lease_modal.modal_action == 'edit' ? 'บันทึก' : 'สร้าง',
                disabled_product_list: lease_modal.modal_action == 'edit' ? false : true
            });

            if (lease_modal.modal_action == 'edit') {
                this.setFormValue()

            }
        })

        let elHeight = document.getElementById('content-body')
        this.setState({
            is_admin: role === 'A' || role === 'M' || role === 'S',
            w_content: window.innerWidth
        })
        if (lease_modal.modal_action == 'edit') { 
            this.GetQueue(lease_data.number,'Edit')
        } else {
            let QCreate = sessionStorage.getItem("QueueCreate");
            if (QCreate != null){
                this.setState({loadingQueue:true})
                this.GetQueue(QCreate,'Create')
            }
        }
        this.loadGoldPrice(moment())

    }

    customerChangeHandler(value) {
        const c = Utility.getObject(this.customers, value);

        this.setState({
            customer: c.id,
            customerName: c.name,
            customerCode: c.code,
            customerPhone: c.phone
        });
    }

    handlerInterest(e, d) {
        const { auth } = this.props
        let amount = parseFloat(this.state.amount)
        if (isNaN(amount))
            amount = 0
        if (this.props.lease_modal.modal_action != 'add' && (this.props.lease_modal.data.status == 3 || this.props.lease_modal.data.status == 4)) {
            return;
        }
        else if (auth.role != 'A' && auth.role != 'S' && auth.role != 'M') {
            if (this.setting.is_user_edit_interest == 0) {
                this.setState({
                    amount: amount,
                    interest: parseFloat(this.setting.interest).toFixed(2),
                    total_interest: Math.ceil(amount * (parseFloat(this.setting.interest) / 100)).toFixed(2)
                })
                return;
            }
        }
        if (isNaN(Number(d.value))) {
        } else {
            d.value = d.value.toString().replace(/^0+/, '')
            if (parseFloat(d.value) > 100) {
                d.value = 100;
            }
            if (parseFloat(d.value) < 0 || d.value == '') {
                d.value = 0;
            }
            if (d.value.toString().split(".")[1] != undefined)
                if (d.value.toString().split(".")[1].length > 2)
                    return
            this.setState({
                amount: amount,
                interest: d.value,
                total_interest: Math.ceil(amount * (parseFloat(d.value) / 100)).toFixed(2)
            })
        }
    }

    handlerAmount(e, d) {
        d.value = d.value.toString().replace(/,/g, '')
        d.value = d.value.toString().replace(/^0+/, '')
        if (isNaN(Number(d.value))) {
        } else {
            if (d.value.length === 0) {
                d.value = 0
            }
            if (d.value.toString().split(".")[1] != undefined)
                if (d.value.toString().split(".")[1].length > 2)
                    return
            const amount = d.value
            let interest = this.state.interest || parseFloat(this.setting.interest).toFixed(2);

            let month = this.state.month ? this.state.month : this.setting.month
            this.setState({
                amount: amount,
                interest: interest,
                total_interest: Math.ceil(amount * (interest / 100)).toFixed(2),
                month: month,
                start_date: moment(),
                end_date: moment().add(parseInt(month), "M")
            })
        }

    }

    handlerAddCustomer(e) {
        e.preventDefault();
        this.setState({
            modal_open: true
        })
    }

    handleInterest(saving) {
        if (saving != undefined) {
            this.setState({ saving: saving, modal_saving: true })
        }
        this.props.reload(this.props.lease_modal.data.id);
        Utility.get(Settings.baseUrl + "/lease/" + this.props.lease_modal.data.id + "/", (status, d, c) => {
            this.setState({ end_date: moment(d.end_date) })
        })
        // this.componentDidMount()
    }

    updateEnddate() {
        this.props.reload(this.props.lease_modal.data.id);
        Utility.get(Settings.baseUrl + "/lease/" + this.props.lease_modal.data.id + "/", (status, d, c) => {
            this.setState({ end_date: moment(d.end_date) })
        })
    }


    handlerProductList(weight, lease_product) {
        this.setState({
            weight: weight
        })
        this.lease_product = lease_product;
    }

    handlerClose() {
        this.props.onClose()
    }

    setFormValue() {

        const d = this.props.lease_modal.data
        var start_date = moment(new Date(d.start_date));
        var end_date = moment(new Date(d.end_date));
        var total_interest = Math.ceil(parseFloat(d.amount) * (parseFloat(d.interest) / 100)).toFixed(2);
        this.setState({
            number: d.number,
            customer_id: d.customer.id,
            start_date: start_date,
            end_date: end_date,
            citizen_id: d.citizen_id,
            phone: d.phone,
            amount: d.amount,
            staff_id: d.staff == null ? '' : d.staff.id,
            month: d.month,
            interest: d.interest,
            total_interest: total_interest,
            loading: false,
            description: d.description
        })
    }

    loadLeaseDetail() {
        const { lease_data } = this.props.lease_modal
        this.setState({
            loading: true
        })

        Utility.get(Settings.baseUrl + "/lease/" + lease_data.id + "/", (status, d, c) => {


            if (status) {
                if (d.customer != null) {
                    var modal_title = Settings.status_lease[d.status]
                    var start_date = moment(new Date(d.start_date));
                    var end_date = moment(new Date(d.end_date));
                    var total_interest = Math.ceil(parseFloat(d.amount) * (parseFloat(d.interest) / 100)).toFixed(2);
                    this.setState({
                        number: d.number,
                        customer_id: d.customer.id,
                        start_date: start_date,
                        end_date: end_date,
                        citizen_id: d.citizen_id,
                        phone: d.phone,
                        amount: d.amount,
                        staff_id: d.staff == null ? '' : d.staff.id,
                        month: d.month,
                        interest: d.interest,
                        total_interest: total_interest,
                        loading: false,
                        description: d.description,
                        modal_title: modal_title
                    })
                    this.props.onReload(d);

                }

                return;
            }
        });
    }

    handleItemClick(e, { tab }) {
        this.setState({
            activeItem: tab
        });
    }

    handleSubmitPay(pay_data) {

        const { auth } = this.props
        const { branch, user } = auth

        let lease = {
            customer: this.state.customer_id,
            phone: this.state.phone,
            citizen_id: this.state.citizen_id,
            branch: branch.id,
            amount: Utility.removeCommas(this.state.amount),
            user: user.id,
            staff: pay_data.staff_id,
            weight: 0,
            month: this.state.month,
            interest: this.state.interest,
            interest_date: Utility.formatDate2(this.state.start_date),
            start_date: Utility.formatDate2(this.state.start_date),
            end_date: Utility.formatDate2(this.state.end_date),
            status: 1,
            description: this.state.description
        };
        this.setState({
            loading_save: true,
            open_pay: false
        })

        let url = Settings.baseUrl + "/lease/"
        Utility.post(url, lease, (s, d) => {

            if (s) {

                this.bathSubmitProduct(d.id)
                let ledger = {
                    branch: branch.id,
                    object_id: d.id,
                    customer: this.state.customer_id,
                    user: user.id,
                    object_number: d.number,
                    ledger_date: Utility.formatDate2(moment()),
                    kind: 'EX',
                    staff: pay_data.staff_id,
                    total: Utility.removeCommas(pay_data.amount),
                    cash: Utility.removeCommas(pay_data.amount),
                    ledger_category: 4,
                    payment: 'CS'
                };

                this.submitLedger(ledger);
                alert('บันทึกข้อมูลสำเร็จ!');
                this.setState({
                    id: d.id,
                    loading_save: false
                })
                this.props.onCreate(d)
                this.props.reload(d.id)

            } else {

                if (d['number'] != null)
                    alert(d['number'])
                this.setState({
                    loading_save: false,
                    error: d
                })
            }

        });
    }

    handleSubmitPayNew(pay_data) {

        const { auth } = this.props
        const { branch, user } = auth

        let lease = {
            customer: this.state.customer_id,
            phone: this.state.phone,
            citizen_id: this.state.citizen_id,
            branch: branch.id,
            amount: Utility.removeCommas(this.state.amount),
            user: user.id,
            staff: pay_data.staff_id,
            weight: 0,
            month: this.state.month,
            interest: this.state.interest,
            interest_date: Utility.formatDate2(this.state.start_date),
            start_date: Utility.formatDate2(this.state.start_date),
            end_date: Utility.formatDate2(this.state.end_date),
            status: 1,
            description: this.state.description,
        };
        // new
        let ledger = {
                    branch: branch.id,
                    // object_id: d.id,
                    customer: this.state.customer_id,
                    user: user.id,
                    // object_number: d.number,
                    ledger_date: Utility.formatDate2(moment()),
                    kind: 'EX',
                    staff: pay_data.staff_id,
                    total: Utility.removeCommas(pay_data.amount),
                    cash: Utility.removeCommas(pay_data.amount),
                    ledger_category: 4,
                    payment: 'CS'
                };
        let lease_product = this.lease_product
        let arr_product = []
        for (let i in lease_product) {
            let formData = {
                product_name: lease_product[i].product_name,
                branch: branch.id,
                category: lease_product[i].category.id,
                weight: lease_product[i].weight,
                amount: lease_product[i].amount,
                user: user.id
            };
            arr_product.push(formData)
        }
        let postdata = {
            lease: lease,
            products: arr_product,
            ledger:ledger,
        }
        this.setState({
            loading_save: true,
            open_pay: false
        })

        let url = Settings.baseUrlV3 + "/lease/create/"
        Utility.postJson(url, postdata, (s, d) => {
            this.GetQueue(d.data.qid,'Create')
            sessionStorage.setItem("QueueCreate", d.data.qid);
            this.setState({loadingQueue:true})
        });
    }

    GetQueue(id,mode) {
        setTimeout(() => {
            let url = Settings.baseUrlV3 + "/queue/"+id+"/"
            Utility.get(url, (e, res) => {
                if(res.result) {
                    if(res.result.status == 0) {
                        if(mode == 'Edit'){
                            this.setState({loadingQueueEdit:true})
                        }
                        console.log('Interval Get Lease Create - - > ' ,'RUN');
                        this.GetQueue(id,mode)
                    }else{
                        if(res.result.status == 1){
                            // this.props.onCreate(res.result.massage)
                            if(mode == 'Create'){
                                alert('บันทึกข้อมูลสำเร็จ!');
                                sessionStorage.removeItem("QueueCreate");
                                this.props.reload(res.result.massage)
                                this.setState({
                                    id: res.result.massage,
                                    loading_save: false,
                                    loadingQueue:false
                                })
                            } else {
                                this.setState({loadingQueueEdit:false})
                            }
                            console.log('Interval Get Lease Create - - >' ,'SUC');
                        }else{
                            if(mode == 'Edit'){
                            if(this.state.loadingQueueEdit) {
                                alert(res.result.message)
                            }
                            
                            this.setState({loadingQueueEdit:false})
                        }
                            console.log('Interval Get Lease Create - - >' ,'ERR');
                        }
                    }
                } else {
                    this.setState({loadingQueueEdit:false,loadingQueue:false
                    })
                }
            })
            
        }, 1000);
    }

    submitLedger(ledger) {
        // save ledger
        let url = Settings.baseUrl + "/ledger/"
        ledger['customer'] = ledger['customer'] ? ledger['customer'] : 1
        Utility.post(url, ledger, (s, d) => {
            this.setState({
                open_pay: false
            })
        });
    }

    async loadNextNumber() {
        const { auth } = this.props
        const { branch, user } = auth
        const url = Settings.baseUrl + '/lease/next_number/?branch=' + branch.id
        const data = await Utility.getAsync(url)
        if (data.data.number.toString().substring(2) > 99999)
            alert('กรุณาแจ้ง manager /admin / superadmin ให้ตั้งค่าเลขที่เริ่มต้นขายฝากของสาขา ' + branch.name + ' ให้ถูกต้อง')
        this.setState({
            number: data.data.number
        })
    }

    async bathSubmitProduct(lease) {
        const { auth } = this.props
        const { branch, user } = auth

        let lease_product = this.lease_product
        for (let i in lease_product) {
            let formData = {
                product_name: lease_product[i].product_name,
                branch: branch.id,
                category: lease_product[i].category.id,
                weight: lease_product[i].weight,
                amount: lease_product[i].amount,
                lease: lease,
                user: user.id
            };
            let url = Settings.baseUrl + '/lease/' + lease + '/product/'
            await Utility.postAsync(url, formData)
        }
    }

    gold_price_divide(num, div) {
        const round = 100
        const g = Math.ceil(((num / div) % round))
        let value = g >= 50 ? Math.ceil(((num / div) / round)) * round : Math.floor(((num / div) / round)) * round
        return value
    }

    gold_price_multiply(num, div) {
        const round = 100
        const g = Math.ceil(((num * div) % round))
        let value = g >= 50 ? Math.ceil(((num * div) / round)) * round : Math.floor(((num * div) / round)) * round
        return value
    }

    render() {
        const { lease_modal } = this.props
        const lease_data = lease_modal.data
        const modal_title = Settings.status_lease[lease_data.status]
        const gold_bay_sell = this.state.gold_bar_buy
        let diff = this.state.lease_price_diff
        if (this.state.setting.lease_price_diff.includes('%')) {
            diff = this.state.gold_bar_buy * (Number(this.state.setting.lease_price_diff.replace('%', '')) / 100)
        } else {
            diff = Number(this.state.setting.lease_price_diff)
        }
        const lease165 = Math.ceil((this.state.gold_bar_buy - diff) / 100) * 100
        const gold2 = Math.ceil((this.state.gold_bar_buy - diff) / 2 / 100) * 100
        const gold1 = Math.ceil((this.state.gold_bar_buy - diff) / 4 / 100) * 100
        const gold05 = Math.ceil((this.state.gold_bar_buy - diff) / 8 / 100) * 100
        const goldg = Math.ceil((this.state.gold_bar_buy - diff) / 15.2 / 100) * 100
        const overdue_end_date = moment(lease_data.end_date, "YYYY-MM-DD").add(lease_data.overdue_end_date, 'days').format("YYYY-MM-DD")
        const { auth } = this.props
        const { role, branch_list } = auth
        var role_overdue_end_date = true
        if (role == 'S' || role == 'A' || (role == 'M' && branch_list.length > 1)) {
            role_overdue_end_date = false
        }
        return (

            <Modal id='ModalLeaseForm' size="large" open={true} >
                <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={(this.props.onClose)} />

                <Modal.Header>
                    <Header as='h4'>
                        <Header.Content>
                            <Header.Subheader>
                                {lease_modal.modal_action == 'add' ? 'สร้างบิลขายฝาก' : 'บิลขายฝาก'} - ยอดเงินต้น <Label color='green' as='span' >{Utility.priceFormat(lease_data.amount)}</Label>
                                {modal_title != '' && <Label color='blue' as='span' >สถานะ {modal_title}</Label>}
                                <br /> <small>บิลเลขที่ {lease_modal.modal_action == 'add' ? this.state.number : lease_data.number}</small>
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <div >
                        {this.state.open_pay ?
                            <LeasePay
                                open={true}
                                amount={this.state.amount}
                                onSubmit={this.handleSubmitPayNew}
                                onClose={() => { this.setState({ open_pay: false }) }}
                            /> : ''
                        }
                        <Dimmer active={this.props.loading || this.state.loading||this.state.loadingQueue} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Dimmer active={this.state.loadingQueue} inverted>
                            <Loader inverted>กำลังดำเนินการสร้างใบขายฝาก</Loader>
                        </Dimmer>
                        <div>
                            <Form className='attached fluid' size='small'>
                                <Grid >
                                    <Grid.Row >
                                        <Grid.Column width={6}>
                                            <Form.Field width={16} >
                                                <label>*ลูกค้า</label>
                                                <Form.Field width={16} disabled={(!this.state.is_admin || this.state.customer_id != '1') && lease_modal.modal_action != 'add'}>
                                                    <div className='option-add' >
                                                        <Button
                                                            icon onClick={this.handlerAddCustomer} type='button'><Icon name='add' /></Button>
                                                        <InputSearchCustomer
                                                            as='Dropdown'
                                                            label='ลูกค้า'
                                                            fluid placeholder='พิมพ์ชื่อลูกค้า' width={13} onChange={this.handlerCustomer} name="customer_id" value={this.state.customer_id} />
                                                    </div>
                                                </Form.Field>
                                            </Form.Field>
                                            <Form.Field width={16}>
                                                <label>*เลขบัตรประชาชน</label>
                                                <Input
                                                    id='inputID'
                                                    disabled={lease_data.status == 3 || lease_data.status == 4}
                                                    ref={(c) => this.inputTaxNumber = c}
                                                    value={this.state.citizen_id}
                                                    placeholder='เลขบัตรประชาชน'
                                                    maxLength={13}
                                                    name='citizen_id'
                                                    onChange={this.handlerInput}
                                                    onKeyPress={(e) => {
                                                        if (e.key == 'Enter') {
                                                            this.inputPhone.focus()
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Form.Field >
                                            <Form.Field width={16}>
                                                <label>*เบอร์โทรศัพท์</label>
                                                <Input
                                                    id='inputPhone'
                                                    disabled={lease_data.status == 3 || lease_data.status == 4}
                                                    ref={(c) => this.inputPhone = c}
                                                    value={this.state.phone}
                                                    placeholder='เบอร์โทรศัพท์'
                                                    name='phone'
                                                    maxLength={10}
                                                    onChange={(e) => {
                                                        if (e.target.value == '') {
                                                            this.setState({
                                                                phone: e.target.value
                                                            })
                                                        } else {
                                                            if (/^0[0-9]*$/.test(e.target.value)) {
                                                                this.setState({
                                                                    phone: e.target.value
                                                                })
                                                            }
                                                        }
                                                    }}
                                                    onKeyPress={(e) => {

                                                        if (e.key == 'Enter') {
                                                            this.inputAmount.focus()
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </Form.Field >
                                            <Form.Field width={16}>
                                                <label>*จำนวนเงิน</label>
                                                <Input
                                                    id='inputAmount'
                                                    maxLength={15}
                                                    ref={(c) => this.inputAmount = c}
                                                    disabled={lease_modal.modal_action != 'add'}
                                                    placeholder='จำนวนเงิน'
                                                    onBlur={() => {
                                                        setTimeout(() => {
                                                            if (this.state.focus_cash) {
                                                                this.setState({
                                                                    focus_cash: false,
                                                                });
                                                            }
                                                        }, 0);
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select()
                                                        if (!this.state.focus_cash) {
                                                            this.setState({
                                                                focus_cash: true,
                                                            });
                                                        }
                                                    }}
                                                    //   value={this.state.focus_cash ? this.state.weight.toString().replace(/,/g,'') : this.state.weight.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                    value={lease_modal.modal_action == 'edit' ? Utility.priceFormat(lease_data.amount) : this.state.focus_cash ? this.state.amount.toString().replace(/,/g, '') : this.state.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                    className="text-right"
                                                    onChange={this.handlerAmount}
                                                    // type='number'
                                                    onKeyPress={(e) => {

                                                        if (e.key == 'Enter') {
                                                            this.inputMonth.focus()
                                                            e.preventDefault()
                                                        }
                                                    }} />
                                            </Form.Field>


                                            <Form.Field width={16}>
                                                <label>ระยะเวลา (เดือน)</label>
                                                <Input
                                                    id='inputTime'
                                                    disabled={(lease_modal.modal_action == 'edit')}
                                                    ref={(c) => this.inputMonth = c}
                                                    placeholder='ระยะเวลา' width={16}
                                                    className="text-right"
                                                    value={this.state.month}
                                                    onChange={this.handlerMonth}
                                                    onKeyPress={(e) => {

                                                        if (e.key == 'Enter') {
                                                            this.inputInterest.focus()
                                                            e.preventDefault()
                                                        }
                                                    }} />
                                            </Form.Field>
                                            <Form.Group>
                                                <Form.Field width={16}>
                                                    <label>อัตราดอกเบี้ย (%)</label>
                                                    <Input
                                                        id='inputInterestRatio'
                                                        disabled={(lease_modal.modal_action == 'edit')}
                                                        placeholder='อัตราดอกเบี้ย' width={16}
                                                        ref={(c) => this.inputInterest = c}
                                                        className="text-right"
                                                        value={this.state.interest.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                        onChange={this.handlerInterest}
                                                        onKeyPress={(e) => {

                                                            if (e.key == 'Enter') {
                                                                this.inputTotalInterest.focus()
                                                                e.preventDefault()
                                                            }
                                                        }} />
                                                </Form.Field>
                                                <Form.Field width={16}>
                                                    <label>ดอกเบี้ย/เดือน</label>
                                                    <Input
                                                        id='inputInterestPerMonth'
                                                        disabled
                                                        placeholder='ดอกเบี้ย/เดือน' width={16}
                                                        ref={(c) => this.inputTotalInterest = c}
                                                        className="text-right"
                                                        value={this.state.total_interest.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                        onKeyPress={(e) => {

                                                            if (e.key == 'Enter') {
                                                                this.startDateInput.setFocus()
                                                                e.preventDefault()
                                                            }
                                                        }} />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Field width={16}>
                                                    <label>วันนำเข้า</label>
                                                    <DatePicker
                                                        customInput={<input onKeyPress={(e) => {

                                                            if (e.key == 'Enter') {
                                                                this.endDateInput.setFocus()
                                                                e.preventDefault()
                                                            }
                                                        }} />}
                                                        ref={(c) => {
                                                            this.startDateInput = c;
                                                        }}
                                                        disabled={lease_data.status == 3 || lease_data.status == 4}
                                                        selected={this.state.start_date}
                                                        selectsStart
                                                        dateFormat="DD/MM/YYYY"
                                                        startDate={this.state.start_date}
                                                        maxDate={this.state.end_date}
                                                        endDate={this.state.end_date}
                                                        onChange={(d) => {
                                                            if (this.state.end_date >= d) {
                                                                this.loadGoldPrice(d)
                                                                this.setState({
                                                                    start_date: d
                                                                })
                                                            }

                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field width={16}>
                                                    <label>วันครบกำหนด</label>
                                                    <DatePicker
                                                        ref={(c) => { this.endDateInput = c; }}
                                                        disabled={lease_data.status == 3 || lease_data.status == 4}
                                                        selected={this.state.end_date}
                                                        selectsEnd
                                                        dateFormat="DD/MM/YYYY"
                                                        startDate={this.state.start_date}
                                                        endDate={this.state.end_date}
                                                        minDate={this.state.start_date}
                                                        onChange={(d) => {
                                                            if (this.state.start_date <= d)
                                                                this.setState({
                                                                    end_date: d
                                                                })
                                                        }}

                                                        customInput={<input onKeyPress={(e) => {

                                                            if (e.key == 'Enter') {
                                                                this.props.openModalLeaseProduct()
                                                                e.preventDefault()
                                                            }
                                                        }} />}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Field width={16}>
                                                    <label>หมายเหตุ</label>
                                                    <TextArea placeholder='หมายเหตุ' width={16}
                                                        onChange={(e, v) => {
                                                            this.setState({
                                                                description: v.value
                                                            })
                                                        }}
                                                        value={this.state.description} />
                                                </Form.Field>
                                            </Form.Group>
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <ProductList
                                                reload_lease_data={(e) => { this.props.reload(this.props.lease_modal.data.id) }}
                                                w_content={this.state.w_content}
                                                id={lease_data.id ? lease_data.id : 0}
                                                status={lease_data.status}
                                                disabled={(lease_modal.modal_action == 'edit' && !this.state.is_admin) || lease_data.status == 3 || lease_data.status == 4}
                                                items={this.state.lease_product}
                                                onChangeProductList={this.handlerProductList}
                                            />
                                            <Menu attached='top' tabular>
                                                <Menu.Item id='tab2' name=''
                                                    active={this.state.activeItem == 'tab2'}
                                                    onClick={this.handleItemClick}
                                                    tab="tab2" > รายการดอกเบี้ยรับ</Menu.Item>
                                                {this.props.lease_modal.modal_action == 'add' || this.state.is_admin || this.props.lease_modal.data.branch.id == this.props.auth.branch.id ?
                                                    <Menu.Item id='tab3' name='' active={this.state.activeItem == 'tab3'} onClick={this.handleItemClick} tab="tab3">
                                                        เพิ่ม/ลด เงินต้น
                                                    </Menu.Item>
                                                : null}
                                            </Menu>
                                            {this.state.activeItem == 'tab2' ?
                                                <Segment className='botton attached fluid' disabled={lease_data.id == null || lease_data.status == 3 || lease_data.status == 4}>
                                                    <LeaseInterest
                                                        update_enddate={this.updateEnddate}
                                                        sub_action={this.props.sub_action}
                                                        onSave={this.handleInterest}
                                                        data={lease_data}
                                                        w_content={this.state.w_content}
                                                        id={lease_data ? lease_data.id : 0}
                                                        disabled={lease_modal.modal_action != 'edit'}
                                                        setting={this.state.setting} 
                                                        overdue_end_date={overdue_end_date}
                                                        role_overdue_end_date={role_overdue_end_date}
                                                        loadingQueue={this.state.loadingQueueEdit}
                                                        />
                                                </Segment>
                                                :
                                                <Segment className='botton attached fluid' disabled={lease_data.id == null || lease_data.status == 3 || lease_data.status == 4}>
                                                    <LeasePrinciple
                                                        sub_action={this.props.sub_action}
                                                        onSave={this.handleInterest}
                                                        data={lease_data}
                                                        w_content={this.state.w_content}
                                                        disabled={lease_modal.modal_action != 'edit'}
                                                        setting={this.state.setting}
                                                        overdue_end_date={overdue_end_date}
                                                        role_overdue_end_date={role_overdue_end_date} 
                                                    />
                                                </Segment>
                                            }
                                            <div style={{ overflowX: 'auto', marginTop: '1em' }}>
                                                <Table celled size='small'>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell><div className='text-center'>ราคาทอง</div></Table.HeaderCell>
                                                            <Table.HeaderCell><div className='text-center'>ราคาซื้อ</div></Table.HeaderCell>
                                                            <Table.HeaderCell><div className='text-center'>1 บาท</div></Table.HeaderCell>
                                                            <Table.HeaderCell><div className='text-center'>2 สลึง</div></Table.HeaderCell>
                                                            <Table.HeaderCell ><div className='text-center'>1 สลึง</div></Table.HeaderCell>
                                                            <Table.HeaderCell ><div className='text-center'>1/2 สลึง</div></Table.HeaderCell>
                                                            <Table.HeaderCell ><div className='text-center'>กรัม</div></Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>

                                                        <Table.Row>
                                                            <Table.Cell><div className='text-center'>{Utility.priceFormat(this.state.gold_bar_sell)}</div></Table.Cell>
                                                            <Table.Cell><div className='text-center'>{Utility.priceFormat(gold_bay_sell)}</div></Table.Cell>
                                                            <Table.Cell><div className='text-center'>{Utility.priceFormat(lease165)}</div></Table.Cell>
                                                            <Table.Cell><div className='text-center'>{Utility.priceFormat(gold2)}</div></Table.Cell>
                                                            <Table.Cell><div className='text-center'>{Utility.priceFormat(gold1)}</div></Table.Cell>
                                                            <Table.Cell><div className='text-center'>{Utility.priceFormat(gold05)}</div></Table.Cell>
                                                            <Table.Cell><div className='text-center'>{Utility.weightFormat(goldg)}</div></Table.Cell>
                                                        </Table.Row>

                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row >
                                </Grid>
                            </Form>
                        </div>
                        {this.state.open_lease_print ? <LeaseInvoicePrintPreview id={lease_data.id} onClose={(e) => {
                            this.setState({
                                open_lease_print: false
                            })
                        }} /> : ''}
                        {(this.state.modal_saving) && (
                            <AccountDetail
                                items={this.state.saving}
                                onClose={() => {
                                    this.setState({ modal_saving: false });
                                }}
                                open={true}
                            />
                        )}
                        {this.state.open_lease_print_v1 ? <LeaseInvoicePrintPreviewV1 id={lease_data.id} onClose={(e) => {
                            this.setState({
                                open_lease_print_v1: false
                            })
                        }} /> : ''}
                        {this.state.open_print_qr ? <LeaseInvoiceQrPreview id={lease_data.id} onClose={(e) => {
                            this.setState({
                                open_print_qr: false
                            })
                        }} /> : ''}
                        <CustomerModal open={this.state.modal_open} onCreate={this.handlerCreateCustomer} onClose={() => { this.setState({ modal_open: false }) }} />

                    </div>

                </Modal.Content>
                <Modal.Actions>

                    <Button
                        id='btnRefesh'
                        size='small'
                        icon='plus'
                        labelPosition='left'
                        onClick={() => {
                            this.props.onClose()
                            this.props.onReset()
                        }}
                        content='สร้างใหม่ (F5)' />
                    <Button
                        id='qrcode'
                        primary
                        size='small'
                        icon='qrcode'
                        disabled={lease_modal.modal_action != 'edit' || lease_modal.data.status == 3}
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({ open_print_qr: true })
                        }}
                        content='พิมพ์ qrcode' />

                    <Button
                        id='printContact'
                        size='small'
                        primary
                        disabled={lease_modal.modal_action == 'add'}
                        icon='print'
                        labelPosition='left'
                        onClick={(e) => {
                            if (lease_modal.modal_action != 'edit')
                                this.handlerSave(e, true)
                            else
                                this.setState({ open_lease_print: true })
                        }}
                        content=' พิมพ์สัญญา (F8)'
                    />

                    <Button
                        id='btnSaveLease'
                        size='small'
                        primary
                        icon='payment'
                        labelPosition='left'
                        loading={this.state.loading_save}
                        floated='right'
                        disabled={lease_modal.modal_action == 'edit' && !this.state.is_admin}
                        content={lease_modal.modal_action == 'edit' ? 'บันทึก (F2)' : "สร้าง (F2)"}
                        onClick={this.handlerSave} />
                </Modal.Actions>
            </Modal>
        );
    }


    hot_keys = {
        'f2': {
            priority: 4,
            handler: (event) => {

                if (!(this.props.lease_modal.modal_action == 'edit' && !this.state.is_admin))
                    this.handlerSave(event)
                event.preventDefault()
            }
        },
        'f8': {
            priority: 4,
            handler: (event) => {

                if (this.props.lease_modal.modal_action != 'edit')
                    this.handlerSave(event, true)
                else
                    this.setState({ open_lease_print: true })
                event.preventDefault()
            }
        },
        'f5': {
            priority: 4,
            handler: (event) => {
                this.props.onClose()
                this.props.onReset()
                event.preventDefault()
            }
        },
        'esc': {
            priority: 4,
            handler: (event) => {
                this.handlerClose(event)
                event.preventDefault()
            }
        }
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches,
        lease_modal: state.lease_modal
    })
}
const mapDispatchToProps = dispatch => ({
    //onClose: () => dispatch(lease_modal_close()),
    reload: (id) => lease_modal_edit(dispatch, id),
    openModalLeaseProduct: () => dispatch(open_modal_lease_product(true)),
})
export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(LeaseForm))
