/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class BuyAverage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.resetProduct = this.resetProduct.bind(this)
    }

    resetProduct(text) {
        let search = this.state.search
        if (text != '') {
            this.setState({
                isLoading: true
            });
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                let products = [];
                let s = {}
                if (search.categories != undefined)
                    s.category_id = search.categories
                if (search.type_sale != undefined)
                    s.type_sale = search.type_sale
                if (search.product_type != undefined)
                    s.product_types_id = search.product_type
                if (search.pattern != undefined)
                    s.pattern_id = search.pattern
                s.text = text
                s.limited = 10
                var p = Utility.getFetch(Settings.baseReportUrl + '/products_no_join/?' + Utility.jsonToQueryString(s));
                Promise.all([p]).then((values) => {
                    let p = values[0].product
                    for (let i in p) {
                        products.push({
                            key: p[i].id,
                            value: p[i].id,
                            text: p[i].code,
                        });
                    }
                    this.setState({
                        products: products,
                        isLoading: false,
                    })
                })
            }, 1600)
        } else {
            this.setState({
                products: [],
            })
        }
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var vendors = Utility.getFetch(Settings.baseReportUrl + '/vendors/');
        var categories = Utility.getFetch(Settings.baseReportUrl + '/categories/');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/');
        // var products = Utility.getFetch(Settings.baseReportUrl + '/products_no_join/');
        var pattern = Utility.getFetch(Settings.baseReportUrl + '/pattern/');
        Promise.all([branches, vendors, categories, product_types, pattern]).then((values) => {
            this.branches = values[0];
            this.product_types = values[3];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.vendors = values[1];
            let vendors = []
            for (let i in this.vendors) {
                vendors.push({
                    value: this.vendors[i].id,
                    key: this.vendors[i].id,
                    text: this.vendors[i].name
                });
            }
            let cat = [];
            for (let i in values[2]) {
                cat.push({
                    key: values[2][i].id,
                    value: values[2][i].id,
                    text: values[2][i].name,
                });
            }
            let product_type = [];
            for (let i in values[3]) {
                product_type.push({
                    key: values[3][i].id,
                    value: values[3][i].id,
                    text: values[3][i].name,
                });
            }

            let pattern = [];
            for (let i in values[4]) {
                pattern.push({
                    key: values[4][i].id,
                    value: values[4][i].id,
                    text: values[4][i].name,
                });
            }
            let products = [];

            this.setState({
                loader_active: false,
                branches: branches,
                vendors: vendors,
                categories: cat,
                product_type: product_type,
                pattern: pattern,
                products: products,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
        let search = this.state.search
        if (localStorage.getItem("product_kind") != null)
            search['product_type'] = JSON.parse(localStorage.getItem("product_kind"))
        this.setState({
            search: search
        })
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            products: []
        })
        localStorage.setItem('product_kind','[]')
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if (search.vendor && search.vendor.length == 0) {
            delete search["vendor"]
        }
        if (search.categories && search.categories.length == 0) {
            delete search["categories"]
        }
        if (search.product_type && search.product_type.length == 0) {
            delete search["product_type"]
        }
        if (search.pattern && search.pattern.length == 0) {
            delete search["pattern"]
        }
        if (search.type_sale && search.type_sale.length == 0) {
            delete search["type_sale"]
        }
        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/invoices/0/items/?status_stock=Y&" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            if (this.state.search.branch) {
                if (item.invoice.branch != this.state.search.branch)
                    continue;
            }

            // if (this.state.search.vendor) {
            //     if (item.invoice.vendor != this.state.search.vendor)
            //         continue;
            // }

            if (item.invoice.kind != 'IM')
                continue;

            // if (this.state.search.categories) {
            //     if (item.product.category.id != this.state.search.categories)
            //         continue;
            // }
            // if (this.state.search.product_type) {
            //     if (item.product.kind.id != this.state.search.product_type)
            //         continue;
            // }
            // if (this.state.search.pattern) {
            //     if (item.product.pattern != this.state.search.pattern)
            //         continue;
            // }
            // if (this.state.search.products) {
            //     if (item.product.id != this.state.search.products)
            //         continue;
            // }
            // if (this.state.search.type_sale) {
            //     if (item.product.type_sale != this.state.search.type_sale)
            //         continue;
            // }

            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        if (v.name == 'product_type')
            localStorage.setItem('product_kind', JSON.stringify(search[v.name]))
        this.setState({
            search: search
        })
        if (v.name == 'categories') {
            this.resetCategory(v.value);
        }
    }

    resetCategory(category_id) {
        let type = [];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_type: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_type: type });
        }
    }

    render() {


        let title = 'รายงานต้นทุนถัวเฉลี่ย';
        let filename = 'stock_in';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let date_g = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]

            let date_title = item.product.code
            let name = item.product.name
            let kind = item.product.kind.name
            let category = item.product.category.name
            if (date_g[date_title] == null) {
                date_g[date_title] = {
                    items: [],
                    title: date_title,
                    name: name,
                    kind: kind,
                    category: category
                }
            }
            date_g[date_title].items.push(item)
        }

        let data_list = []
        for (let i in date_g) {
            data_list.push(date_g[i])
        }

        function sortByProperty(property) {
            return function (a, b) {
                if (a[property] > b[property])
                    return 1;
                else if (a[property] < b[property])
                    return -1;

                return 0;
            }
        }

        data_list.sort(sortByProperty("title"));

        let total_amount = 0
        let total_weight = 0
        let total_cost = 0
        let total_avg = 0

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานราคาต้นทุนถัวเฉลี่ย</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>ชื่อโรงงาน/ร้านส่ง</label>
                                <Dropdown search selection placeholder='- ทั้งหมด -' multiple options={this.state.vendors} value={this.state.search.vendor} name='vendor' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>กลุ่มสินค้า</label>
                                <Dropdown search selection placeholder='- ทั้งหมด -' multiple options={this.state.categories} value={this.state.search.categories} name='categories' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทสินค้า<small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown multiple search selection
                                    placeholder='- ทั้งหมด -'
                                    options={
                                        this.state.product_type
                                    }
                                    value={this.state.search.product_type} name='product_type' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ลาย</label>
                                <Dropdown multiple search selection placeholder='- ทั้งหมด -' options={this.state.pattern} value={this.state.search.pattern} name='pattern' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทงานขาย</label>
                                <Dropdown multiple search selection placeholder='- ทั้งหมด -' options={Settings.type_sale} value={this.state.search.type_sale} name='type_sale' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>รหัสสินค้า</label>
                                <Dropdown
                                    search selection
                                    placeholder='รหัสสินค้า'
                                    options={this.state.products}
                                    value={this.state.search.products}
                                    name='products'
                                    onChange={this.handlerInput}
                                    onSearchChange={(e, v) => {
                                        this.resetProduct(v.searchQuery)
                                    }}
                                    loading={this.state.isLoading}
                                    isFetching={this.state.isLoading}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่อ้างอิง</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.(g)/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาทอง/บาท</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาทอง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าแรง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุนรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุนรวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {data_list.map((row, i) => {

                                        let amount = 0
                                        let weight = 0
                                        let cost = 0
                                        let fee = 0
                                        let gold = 0
                                        let invoice_cost = 0
                                        let avg_cost = 0
                                        let total_costp = 0
                                        let cost_total = 0
                                        let c = 0

                                        return (<Table.Body>
                                            <Table.Row >
                                                <Table.HeaderCell style={textLeft}>{row.title}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}>{row.kind}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}>{row.category}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}>{row.name}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}></Table.HeaderCell>
                                            </Table.Row>
                                            {row.items.map((row, i) => {
                                                weight += parseFloat(row.weight_real)
                                                amount += row.amount
                                                cost += parseFloat(row.cost) * parseFloat(row.amount)
                                                fee += parseFloat(row.fee) * parseFloat(row.amount)
                                                invoice_cost += parseFloat(row.invoice.cost) * parseFloat(row.amount)
                                                gold = Utility.weightFormat(parseFloat(row.weight_real) / parseFloat(row.amount))
                                                // avg_cost += (parseFloat(row.weight_real) / parseFloat(row.amount)) / parseFloat(row.product.category.weight) * parseFloat(row.invoice.cost) * parseFloat(row.amount)
                                                if (row.product.type_sale == 2) {
                                                    c = row.cost
                                                } else {
                                                    c = (row.weight_real / parseFloat(row.amount)) / parseFloat(row.product.category.weight) * parseFloat(row.invoice.cost)
                                                }
                                                avg_cost += parseFloat(c) * parseFloat(row.amount)

                                                // total_costp += (parseFloat(c)+parseFloat(row.fee)) * parseFloat(row.amount)
                                                cost_total += (parseFloat(c) + parseFloat(row.fee)) * parseFloat(row.amount)

                                                total_weight += parseFloat(row.weight_real)
                                                total_amount += row.amount
                                                total_cost += (parseFloat(c) + parseFloat(row.fee)) * parseFloat(row.amount)
                                                total_avg += ((parseFloat(c) + parseFloat(row.fee)) * parseFloat(row.amount)) / parseFloat(total_weight)
                                                return (<Table.Row id={row.product.code} key={i}>
                                                    <Table.Cell style={textLeft}>{Utility.formatDate(row.update_date)}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row.invoice.number}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row.kind_display}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.numberFormat(row.amount)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(parseFloat(row.weight_real) / parseFloat(row.amount))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(parseFloat(row.weight_real))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(row.invoice.cost))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(c))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(row.fee))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(parseFloat(c) + parseFloat(row.fee)))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(parseFloat(c) + parseFloat(row.fee)) * parseFloat(row.amount))}</Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                </Table.Row>)
                                            })}
                                            <Table.Row>
                                                <Table.Cell colSpan='3' style={textRight}><div style={textU}>รวม</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.numberFormat(amount)}</div></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.weightFormat(weight)}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(invoice_cost) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(avg_cost) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(fee) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(cost_total) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(cost_total)}</div></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan='12'></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    <Table.Row>
                                        <Table.Cell style={textLeft}><b><div style={textU}>รวมทั้งหมด</div></b></Table.Cell>
                                        <Table.Cell colSpan='3' style={textRight}><div style={textU}><b id='total_amount'>{Utility.numberFormat(total_amount)}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                        <Table.Cell style={textRight}><div style={textU} id='total_weight'><b>{Utility.weightFormat(total_weight)}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                        <Table.Cell colSpan='4' style={textRight}><div style={textU}><b id='total_cost'>{Utility.priceFormat(total_cost)}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='12'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={textLeft}><b><div style={textU}>ต้นทุนทองถัวเฉลี่ย</div></b></Table.Cell>
                                        {parseFloat(total_weight) === 0.00 ? <Table.Cell colSpan='12' style={textRight}><div style={textU}><b id='total_cost'>0.00</b></div></Table.Cell>
                                            :
                                            <Table.Cell colSpan='12' style={textRight}><div style={textU}><b id='total_cost'>{Utility.priceFormat(parseFloat(total_cost) / parseFloat(total_weight))}</b></div></Table.Cell>}
                                    </Table.Row>


                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(BuyAverage)
