/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import {
    Segment,
    Form,
    Modal,
    Button,
    Grid,
    Label,
    Input,
    Popup,
    Dimmer,
    Loader,
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';

class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { rowIndex, field, data, ...props } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (
            <Cell {...props} onClick={(e) => { }}>
                <Popup
                    position={this.props.position}
                    content={data[rowIndex][field]}
                    trigger={
                        <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>
                            {field == 'weight' ? Utility.weightFormat(data[rowIndex][field]) : data[rowIndex][field]}
                        </div>}
                />
            </Cell>
        );
    }
}

class CalculatTotal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            total_interest: 0,
            amount: 0,
            ic: 0,
            dc: 0,
        }
        this.open = this.open.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            let elHeight = document.getElementById('total-box2')
            if (elHeight)
                this.setState({ table_width: window.innerWidth });
        }, 200)
    }

    open() {
        let data = this.props.data
        if (data.start_end_date)
            data.start_end_date = Utility.formatDate2(data.start_end_date)
        if (data.end_end_date)
            data.end_end_date = Utility.formatDate2(data.end_end_date)

        if (data.start_out_date)
            data.start_out_date = Utility.formatDate2(data.start_out_date)
        if (data.end_out_date)
            data.end_out_date = Utility.formatDate2(data.end_out_date)

        if (data.start_close_date)
            data.start_close_date = Utility.formatDate2(data.start_close_date)
        if (data.end_close_date)
            data.end_close_date = Utility.formatDate2(data.end_close_date)

        if (data.due_date)
            data.due_date = Utility.formatDate2(data.due_date)

        if (data.number) {
            data.number = data.number.trim()
        }
        if (data.phone) {
            data.phone = data.phone.trim()
        }
        if (data.phone == '')
            delete data.phone
        if (data.citizen_id) {
            data.citizen_id = data.citizen_id.trim()
        }

        data['branch'] = this.props.branch
        let s = Utility.jsonToQueryString(data)
        console.log('open')
        this.setState({ loader_active: true })
        var cal = Utility.getFetch(Settings.baseUrl + '/lease_data_calculate/?' + s);
        Promise.all([cal]).then((values) => {
            console.log(values[0])
            this.setState({
                ic: values[0].ic,
                dc: values[0].dc,
                total_interest: values[0].total_interest,
                amount: values[0].amount,
                items: this.setFieldValue(values[0].items)
            })
            this.setState({ loader_active: false })
        });
        this.componentDidMount()
    }

    setFieldValue(items) {

        let product = {};
        let weight = 0
        for (let i in items) {
            let item = items[i]
            weight += item.weight
            if (product[item.category_code] == undefined) {
                product[item.category_code] = {
                    category_name: item.category_name,
                    weight: item.weight,
                    cost: item.cost,
                    category_weight: item.category_weight,
                }
            } else {
                product[item.category_code]['weight'] += item.weight
                product[item.category_code]['cost'] += item.cost
            }
        }
        let ret = []
        for (let i in product) {
            product[i]['average'] = Utility.priceFormat(product[i].cost / (product[i].weight / product[i].category_weight))
            ret.push(product[i])
        }
        this.setState({ weight: weight })
        return ret
    }

    render() {

        const items = this.state.items
        return (
            <Modal id='modalCal' onOpen={this.open} /*dimmer='blurring'*/ closeIcon trigger={<Button id='calculate' size='small' content='คำนวณ (F10)' onClick={this.handleCalculate} floated='right' icon='calculator' labelPosition='left' type='button' primary />}>
                <Modal.Header>คำนวณ</Modal.Header>
                <Modal.Content >
                    <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                        <Loader content='Loading' inverted />
                    </Dimmer>
                    <Modal.Description>
                        <Grid columns={2} divided>
                            <Grid.Row>

                                <Grid.Column>
                                    <Form size='small'>

                                        <Form.Field inline>
                                            <label>สรุป น.น. ของขายฝาก น.น.รวม: </label>
                                            <label id='totalweight'>{Utility.weightFormat(this.state.weight)} </label>
                                        </Form.Field>
                                    </Form>
                                    <br />
                                    <div id="total-box2">
                                        <Table
                                            rowsCount={items.length}
                                            rowHeight={30}
                                            headerHeight={35}
                                            width={this.state.table_width}
                                            height={Settings.table_hiegh - 35}>
                                            <Column
                                                header={<Cell>%ทอง</Cell>}
                                                cell={
                                                    <ItemsCell position='top left' width={100} data={items} field="category_name" />
                                                }
                                                width={100}
                                            />
                                            <Column
                                                header={<Cell className="text-right">น้ำหนักทอง(กรัม)</Cell>}
                                                cell={
                                                    <ItemsCell position='top right' width={120} data={items} field="weight" textAlign="text-right" />
                                                }
                                                width={120}
                                            />
                                            <Column
                                                header={<Cell className="text-right">ราคาทองเฉลี่ยบาทละ</Cell>}
                                                cell={
                                                    <ItemsCell position='top right' width={180} data={items} field="average" textAlign="text-right" />
                                                }
                                                width={180}
                                            />
                                        </Table>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form size='small'>
                                        <Form.Field >
                                            <label>ยอดดอกเบี้ย</label>
                                            <label id='interest'>{Utility.priceFormat(this.state.total_interest)}</label>
                                        </Form.Field>
                                        <Form.Field >
                                            <label>ยอดขายฝาก</label>
                                            <label id='least'>{Utility.priceFormat((this.state.amount) - this.state.ic + this.state.dc)}</label>
                                        </Form.Field>
                                        <Form.Field >
                                            <label>ยอดเพิ่มเงินต้น</label>
                                            <label id='increse'>{Utility.priceFormat(this.state.ic)}</label>
                                        </Form.Field>
                                        <Form.Field >
                                            <label>ยอดลดเงินต้น</label>
                                            <label id='decrese'>{Utility.priceFormat(this.state.dc)}</label>
                                            {/* <Input value={Utility.priceFormat(this.state.total_delete)} className='text-right' /> */}
                                        </Form.Field>
                                        <Form.Field >
                                            <label>ยอดคงเหลือ</label>
                                            <label id='total'>{Utility.priceFormat(this.state.amount)}</label>
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>

        )
    }
}

export default CalculatTotal;