/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';
import { connect } from 'react-redux'
import {
  Menu,
  Grid,
  Form,
  Segment,
  Header,
  Icon,
  Image,
  Button,
  Input,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import logoAAGold from '../../images/aa-text_1.png';
import Utility from '../../Utility';
import InputSearchCustomerNamePhone from '../Customer/InputSearchCustomerNamePhone';
import Settings from "../../Settings";
import MsgInput from '../Error/MsgInput'
import { redeem_customer } from '../../actions'

class Redeem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: {},
      msg_error: {},
      amount: '',
      points:'0',
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearchCustomer = this.handleSearchCustomer.bind(this);
    this.handleSubmitQRCode = this.handleSubmitQRCode.bind(this);
  }

  async componentDidMount() {
    if(Object.keys(this.props.redeem_customer.data).length > 0) {
      await this.setState({ customer_id: this.props.redeem_customer.data.customer_id });
      this.handleSearchCustomer();
    }
  }

  handleInputChange(e, { name, value, q }) {
    this.setState({
      [name]: value,
    });
  }

  async handleSearchCustomer(e) {
    if(this.state.customer_id) {
      var game = 'วงล้อมหาเฮง'
        await Utility.get(Settings.baseUrl + "/redeem_config/?game=" + game, (status, data) => {
          let results = data[0]
          this.setState({
            points: results.point,
          });
        })
      this.setState({ loader_active: true, msg_error: {}, amount: '' });
      var customer_detail = Utility.getFetch(Settings.baseUrl + "/customers/"+this.state.customer_id+"/redeem/")
      Promise.all([customer_detail]).then((values) => {
        var items = values[0];
        this.setState({
          items: items,
          loader_active: false,
        });
      });
    }
  }

  handleSubmitQRCode() {
    if(this.state.amount && this.state.amount > 0) {
      if(this.state.amount*parseInt(this.state.points) > this.state.items.score) {
        this.setState(prevState => ({
          msg_error: {
              ...prevState.msg_error,
              amount: '*แต้มสะสมไม่เพียงพอ'
          }
        }))
      } else {
        this.setState({ msg_error: {} })
        var data = {}
        data['customer_id'] = this.state.items.id;
        data['name'] = this.state.items.name;
        data['mobile'] = this.state.items.mobile;
        data['score'] = this.state.items.score;
        data['amount'] = this.state.amount;
        data['code'] = this.state.items.code;
        this.props.handleSetRedeemCustomer(data)
        this.props.history.push('/redeem/qrcode')
      }
    } else {
      this.setState(prevState => ({
        msg_error: {
            ...prevState.msg_error,
            amount: '*กรุณากรอกจำนวนครั้ง'
        }
      }))
    }
  }

  render() {
    const { auth } = this.props
    const { role, branch } = auth
    const containerImgLogoTextAAGold = {
      'margin-top': '-25px',
      'margin-bottom': '16px',
      'cursor': 'pointer',
    }
    const container = {
      'max-width': '800px',
      'margin-left': 'auto',
      'margin-right': 'auto',
      'font-family': 'Kanit',
    }
    const marginBottom30 = {
      'margin-bottom': '30px'
    }
    const textDate = {
      'text-align': 'right',
      'color': '#B51515',
      'font-size': '23px'
    }
    const segmentCustomerInfo = {
      'margin-top': '0px',
      'margin-bottom': '30px',
      'width': '100%',
      'padding': '20px 30px',
      'background-color': '#f7f7f7',
    }
    const containerBtnSearch = {
      'justify-content': 'center',
      'margin-bottom': '30px',
      'display': 'flex',
    }
    const marginBottom60 = {
      'margin-bottom': '60px'
    }
    const containerBtnQRCode = {
      'justify-content': 'center',
      'display': 'flex',
    }
    const divInfo = {
      'padding-left': '30px',
      'padding-right': '30px',
    }
    const textCustomerInfo = {
      'width': '100%',
      'line-height': '1',
      'color': '#959595',
      'font-weight': '500',
      'font-size': '23px',
    }
    const BtnRedeem = {
      'font-family': 'Kanit',
      'font-weight': '600',
      'width': '170px',
      'font-size': '23px',
    }
    const BtnCancel = {
      'font-family': 'Kanit',
      'font-weight': '600',
      'width': '200px',
      'font-size': '23px',
    }
    const classFontsize = {
      'font-size': '23px',
    }
    const msgErrorMobile = {
      'font-size': '18px',
      'margin-top': '-20px'
    }
    const segmentEmployeeInfo = {
      'margin-top': '0px',
      'margin-bottom': '30px',
      'width': '100%',
      'padding': '20px 30px',
      'background-color': '#f7f7f7',
    }
    const textEmployeeInfo = {
      'width': '100%',
      'line-height': '1',
      'color': 'rgb(181, 21, 21)',
      'font-weight': '500',
      'font-size': '23px',
    }
    return (
      <div style={container}>
        <Dimmer active={this.state.loader_active} inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
        <div style={containerImgLogoTextAAGold} 
        onClick={() => {
                  this.setState({
                    customer_id : '',
                    items: [],
                  });
                }}>
          <Image className='imgLogoTextAAGold' src={logoAAGold}/>
        </div>
        <div style={divInfo}>
          <p style={textDate}>วันที่ {Utility.formatDate(Date.now())}</p>
          <Form
            className="attached fluid formSearchCustomerRedeem"
            size="small"
            autocomplete="off"
          >
            <Form.Field style={marginBottom30}>
              <InputSearchCustomerNamePhone
                label='ชื่อลูกค้า หรือ เบอร์โทร'
                onChange={this.handleInputChange}
                name="customer_id"
                value={this.state.customer_id}
                style={classFontsize}
              />
            </Form.Field>
            <div style={containerBtnSearch}>
              <Button
                style={BtnRedeem}
                id="search"
                floated="left"
                type="button"
                size="small"
                onClick={() => {
                  this.handleSearchCustomer();
                }}
              >
                {" "}
                <Icon name="search" /> ค้นหา
              </Button>
              
            </div>
          </Form>
          {Object.keys(this.state.items).length > 0 ?
            <>
            {!this.state.items.is_employee ? 
            <>
              <Segment style={segmentCustomerInfo}>
                <div style={{ display: "flex" }}>
                  <div style={{ wordBreak: "break-word" }}>
                    <p style={textCustomerInfo}><b>ลูกค้า : {this.state.items.name}</b></p>
                    <p style={textCustomerInfo}>เบอร์โทร : {this.state.items.mobile ? this.state.items.mobile : '-'}</p>
                    {!this.state.items.mobile && <p style={msgErrorMobile}><MsgInput text='** กรุณาเพิ่มเบอร์โทรให้ถูกต้องก่อนร่วมกิจกรรม **' /></p> }
                    <p style={textCustomerInfo}>แต้มสะสม : {Utility.numberFormat(this.state.items.score)} แต้ม</p>
                    <p style={textCustomerInfo}>หมุนได้สูงสุด : {Utility.numberFormat(Math.floor(this.state.items.score/parseInt(this.state.points)))} ครั้ง</p>
                  </div>
                </div>
              </Segment>
              { this.state.items.mobile &&
                <Form
                  className="attached fluid"
                  size="small"
                  autocomplete="off"
                >
                  <Form.Field style={marginBottom30} error={this.state.msg_error.amount != null}>
                    <label className="labelAmountRedeem">จำนวนครั้ง <MsgInput text={this.state.msg_error.amount} /></label>
                    <Input
                      className="input-redeem"
                      maxLength={3}
                      fluid
                      onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            this.setState({
                                amount: e.target.value
                            })
                        }
                      }}
                      name="amount"
                      value={this.state.amount}
                    />
                  </Form.Field>
                  <div style={containerBtnQRCode}>
                    <Button
                          style={BtnCancel}
                          id="search"
                          floated="left"
                          type="button"
                          size="small"
                          onClick={() => {
                            this.setState({
                              customer_id : '',
                              items: [],
                            });
                          }}
                        >
                          {" "}
                          กลับหน้าหลัก
                     </Button>
                    <Button
                      style={BtnRedeem}
                      floated="left"
                      type="button"
                      size="small"
                      onClick={() => {
                        this.handleSubmitQRCode();
                      }}
                    >
                      QR Code
                    </Button>
                  </div>
                </Form>
              }</>
              :
              <>
              <Segment style={segmentEmployeeInfo}>
                <div style={{ display: "flex" }}>
                  <div style={{ wordBreak: "break-word" }}>
                    <p style={textEmployeeInfo}><b>Notice : ลูกค้าที่มีสถานะ "พนักงาน" ไม่สามารถเข้าร่วมสนุกได้</b></p>
                  </div>
                </div>
              </Segment>
              <div style={containerBtnQRCode}>
                    <Button
                          style={BtnCancel}
                          id="search"
                          floated="left"
                          type="button"
                          size="small"
                          onClick={() => {
                            this.setState({
                              customer_id : '',
                              items: [],
                            });
                          }}
                        >
                          {" "}
                          กลับหน้าหลัก
                     </Button>
                     </div>
              </>
            }
            </>
          : null}
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches,
    redeem_customer: state.redeem_customer
  })
}
const mapDispatchToProps = dispatch => ({
  handleSetRedeemCustomer: (data) => {
    dispatch(redeem_customer(data))
  }
})
export default connect(
  mapStateToProps, mapDispatchToProps
)(Redeem)

