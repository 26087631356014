/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Route,
  NavLink
} from 'react-router-dom';

import {
  Icon,
  Popup,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { ContextMenu, Item, ContextMenuProvider } from 'react-contexify';
import Settings from "../../Settings";
import Utility from "../../Utility";
import OptionItemsCell from "./Cell/OptionItemsCell";
import IconFont from 'react-contexify/lib/Component/IconFont';

class MyAwesomeMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ContextMenu id='menu_id'>
        <Item onClick={this.props.onClick} data='delete'>
          ลบ
        </Item>
      </ContextMenu>
    );
  }
}

class IconCell extends Component {
  render() {
    const { rowIndex, field, data, type, action, ...props } = this.props;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;

    return (
      <Cell {...props} style={{textAlign:'center'}}  >
        {rowIndex == 0 && !data[rowIndex].is_print && today == data[rowIndex].date ? <a id='btnDelete' onClick={(e) => { if (window.confirm('ยืนยันลบรายการนี้')) this.props.onDelete(e, data[rowIndex].id, data[rowIndex].saving.id) }}><Icon name='window close outline' /></a>:null}
      </Cell>
    );
  }
}

class ItemsCell extends Component {
  render() {
    const { rowIndex, field, data, type, ...props } = this.props;

    let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
      : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
        : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
          : data[rowIndex][field];
      
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}  >
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id">
          <Popup
            position={this.props.position}
            content={v}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>{v}</div>}
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}


class AccountItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sales: []
    };
    this.handlerDelete = this.handlerDelete.bind(this);
  }

  handlerDelete(e, saving_item, saving) {
    this.props.onDelete(e, saving_item, saving);
  }

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('root-table-bill')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    let items = this.props.items
    
    return (
      <div id="root-table-bill">
        <Dimmer active={this.props.active} inverted>
                        <Loader inverted content="Loading" />
                      </Dimmer>

                      <Table
                        rowsCount={items.length}
                        rowHeight={35}
                        headerHeight={30}
                        width={this.state.table_width}
                        height={300}
                      >
                        <Column
                          header={<Cell></Cell>}
                          cell={
                            <IconCell
                              action={"delete"}
                              data={items}
                              onDelete={this.handlerDelete}
                            />
                          }
                          width={40}
                        />
                        <Column
                          header={<Cell>วันที่</Cell>}
                          cell={
                            <ItemsCell
                              position="top left"
                              width={80}
                              data={items}
                              field="date"
                            />
                          }
                          width={80}
                        />

                        <Column
                          header={<Cell>เวลา</Cell>}
                          cell={
                            <ItemsCell
                              position="top left"
                              width={80}
                              id="idproduct"
                              data={items}
                              field="time"
                            />
                          }
                          width={80}
                        />
                        <Column
                          header={<Cell>รายการ</Cell>}
                          cell={
                            <ItemsCell
                              position="top left"
                              width={100}
                              data={items}
                              field="kind_display"
                            />
                          }
                          width={100}
                        />
                        <Column
                          header={<Cell 
                            className="text-right"
                            field="in"
                            >เงินฝาก</Cell>}
                          cell={
                            <ItemsCell
                              position="top right"
                              width={100}
                              data={items}
                              field="in"
                              textAlign="text-right"
                            />
                          }
                          width={100}
                        />
                        <Column
                          header={<Cell 
                          className="text-right"
                          field="out">
                            เงินถอน</Cell>}
                          cell={
                            <ItemsCell
                            position="top right"
                            width={100}
                            data={items}
                            field="out"
                            textAlign="text-right"
                          />
                          }
                          width={100}
                        />
                        <Column
                  header={
                    <Cell
                      className="text-right"
                      field="balance"
                    >
                      ยอดคงเหลือ
                    </Cell>
                  }
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="balance"
                      textAlign="text-right"
                    />
                  }
                  width={100}
                />
                        <Column
                          header={<Cell className="text-left">ผู้ทำรายการ</Cell>}
                          cell={
                            <ItemsCell
                              position="top left"
                              width={120}
                              data={items}
                              field="staff"
                              textAlign="text-left"
                            />
                          }
                          width={120}
                        />
                        <Column
                          header={<Cell className="text-center">สาขาที่ทำรายการ</Cell>}
                          cell={
                            <ItemsCell
                              position="top center"
                              width={100}
                              data={items}
                              field="branch_name"
                              textAlign="text-center"
                            />
                          }
                          width={150}
                        />
                        <Column
                          header={<Cell className="text-left">หมายเหตุ</Cell>}
                          cell={
                            <ItemsCell
                              position="top left"
                              width={100}
                              data={items}
                              field="remark"
                              textAlign="text-left"
                            />
                          }
                          width={150}
                        />
                      </Table><br />
      </div>
    );
  }
}

export default AccountItems;
