/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
// import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button,
  Modal,
  Input,
  Dimmer,
  Loader,
  Table,
  Header,
  Label,
  FormGroup,
  FormField,
  Icon,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
// import Utility from '../../../Utility';
// import Settings from '../../../Settings';
import moment from "moment";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import InputSearchCustomer from "../Customer/InputSearchCustomer";
import CustomerModal from "../Customer/CustomerModal";
import Utility from "../../Utility";
import Settings from '../../Settings';
import BookPrintPreview from "./BookPrintPreview";

class PrintConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savings_data: {},
      page: 0
    };
    this.submitPrint = this.submitPrint.bind(this)
  }

  async componentDidMount() {

    const { savings } = this.props
    this.setState({ loading: true })
    const url = Settings.baseUrl + "/saving/" + savings.id + "/print/";
    const savings_print = await Utility.getAsync(url)

    if (savings_print.status_code == 200) {
      const savings_data = savings_print.data
      this.setState({ loading: false, savings_data, page: savings_data.savings.page })
    }
  }

  submitPrint() {
    if (this.props.repair) {
      this.setState({
        open_preview: true,
        is_next_page:this.props.line==this.state.savings_data.savings_max_line,
        print_data: {
          items: this.props.items,
          start_line: this.props.line,
          end_line: this.props.line + 1,
        }
      })
    } else {
      const { savings_data } = this.state
      const { auth, savings } = this.props;
      let not_print_line = 0
      let end_line = 0
      let print_page = 1
      let is_next_page = false
      const start_line = savings_data.savings.line + 1
      const print_item = []
      const print_item_id = []
      const max_line_this_page = savings_data.savings_max_line - savings_data.savings.line

      for (let i in savings_data.items) {
        const item = savings_data.items[i]
        if (!item.is_print && not_print_line < max_line_this_page) {
          not_print_line += 1
          print_item.push(item)
          print_item_id.push(item.id)
        }

        is_next_page = not_print_line == max_line_this_page
      }

      print_page = savings_data.savings.page + (is_next_page ? 1 : 0)
      end_line = savings_data.savings.line + print_item.length

      if (not_print_line > 0) {

        // submit print
        const url = Settings.baseUrl + "/saving/" + savings.id + "/print/";
        const data = {
          page: print_page,
          line: is_next_page ? 0 : end_line,
          items: print_item_id
        }
        Utility.postJson(url, data, (status, data, status_code) => {
          if (status_code == 200) {
            this.setState({
              open_preview: true,
              is_next_page,
              print_data: {
                items: print_item,
                start_line: start_line,
                end_line: end_line
              }
            })
          } else {

          }
        })
      } else {
        alert('พิมพ์เสร็จแล้ว')
        this.props.onClose()
      }
    }
  }

  render() {
    const { auth, savings } = this.props;
    const { loading, savings_data, open_preview, print_data, page, is_next_page } = this.state
    return (
      <Modal open={true} size="mini" /*dimmer='blurring'*/>
        <Button
          id="btnCloseProductSelect"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header>แจ้งเตือน</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {!loading && <Header>{this.props.repair ? 'ยืนยันการพิมพ์ซ่อม' : 'ยืนยันการพิมพ์สมุดหน้า' + page + '/' + savings_data.savings_max_page}</Header>}
            <Dimmer active={loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            {open_preview && <BookPrintPreview
              title='พิมพ์สมุด'
              data={print_data}
              is_end_page={is_next_page}
              onClose={() => {
                this.setState({ open_preview: false })

                if (is_next_page) {
                  this.componentDidMount()
                } else
                  this.props.onClose();
              }}
            />}
          </Modal.Description>
          <CustomerModal
            open={this.state.modal_open}
            onCreate={this.handlerCreateCustomer}
            onClose={() => {
              this.setState({ modal_open: false });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="cancelAddstaff"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.props.onClose();
            }}
          >
            ยกเลิก
          </Button>
          <Button
            id="submitAddstaff"
            size="small"
            primary
            onClick={this.submitPrint}
            className={this.state.button_loading ? "loading" : ""}
          >
            ยืนยันพิมพ์
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        // e.preventDefault()
        // this.handlerSubmit(e)
      },
    },
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(PrintConfirm));
