/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import CSVReader from "react-csv-reader";
import { CSVLink } from 'react-csv';
import moment from 'moment';
import {
    Modal,
    Button,
    Form,
    Popup,
    Icon,
    Label,
    Message,
    Dimmer,
    Loader
} from 'semantic-ui-react';
import { Table, Column, Cell } from 'fixed-data-table';
import Utility from '../../Utility';

function addDigits(v) {
    return v.length == 1 ? '0' + v : v
}

class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            rowIndex,
            field,
            data,
            ...props
        } = this.props;
        return (
            <Cell {...props}>
                {data[rowIndex][field] && <div className={this.props.textAlign} title={data[rowIndex][field] ? data[rowIndex][field].error : 'error'}>
                    {data[rowIndex][field].value}
                    {data[rowIndex][field].error != ''
                        ? <Popup color='red' trigger={< Icon name='info circle' color='red' />}>{data[rowIndex][field] ? data[rowIndex][field].error : 'error'}</Popup>
                        : ''}
                </div>}
            </Cell>
        );
    }
}

class ImportCSV extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            table_width: 0,
            table_hiegh: 0,
            size: 0,
            size_error: 0
        }
        this.handleError = this
            .handleError
            .bind(this)
        this.handleForce = this
            .handleForce
            .bind(this)
        this.handleSubmit = this
            .handleSubmit
            .bind(this)
    }

    handleForce(data) {
        data.splice(0, 1);

        let lists = []

        for (let i in data)
            if (data[i].length > 1)
                lists.push(data[i])

        let _data = this.checkValidate(lists)

        let _size_error = 0
        for (let i in _data) {

            for (let j in _data[i]) {
                if (_data[i][j].error != '') {
                    _size_error++;
                    break
                }
            }
        }
        document.getElementById('csvr').value = null;
        this.setState({
            data: _data,
            is_error: false,
            size: _data.length,
            size_error: _size_error
        })
    };

    checkValidate(data) {
        let _data = []
        for (let i in data) {
            _data.push(this.isRowValid(data[i]))
        }
        return _data
    }

    isRowValid(d) {
        let _d = []

        for (let i in d) {
            let f = this.props.fields[i]
            let v = f.fill ? f.fill(d[i] + '') : d[i]

            let r = this.isFieldValid(f, v.trim())

            _d.push({
                error: r,
                value: v
            })
        }
        return _d
    }

    isFieldValid(f, d) {

        if (f.required && (d == null || d == '')) {
            return 'ต้องไม่เป็นค่าว่าง'
        }
        //Edit By tar
        if (this.props.data !== undefined) {
            if (f.required && (this.props.data.indexOf(d) >= 0)) {
                return 'ต้องไม่ซ้ำ'
            }
        }

        if (f.max_length !== undefined && d.length > f.max_length) {
            return 'ต้องไม่เกิน 255 ตัวอักษร'
        }

        if (f.length_require !== undefined && d.length != f.length_require) {
            return 'จำนวนตัวอักษรต้องเท่ากับ ' + f.length_require + ' ตัวอักษร'
        }

        if (f.pattern !== undefined && !f.pattern.test(d)) {
            return 'รูปแบบไม่ถูกต้อง'
        }

        if (f.required && (d.length > 100)) {
            return 'ต้องไม่เกิน 100 ตัวอักษร'
        }

        if (f.type == Number) {
            d = Utility.removeCommas(d)
            if (f.name == 'amount') {
                if (!(isNaN(Number(d))) && Number.isInteger(Number(d)) == false) {
                    return 'เป็นทศนิยมไม่ได้'
                }
            }
            if (isNaN(Number(d)))
                return 'ไม่ใช่ตัวเลข'
        }

        if (f.type == 'date' && !f.required && d != null && d != '') {
            let v = d.split('/')
            var date = moment(addDigits(v[2]) + "-" + addDigits(v[0]) + "-" + addDigits(v[1]));
            if (!date.isValid()) {
                return 'รูปแบบวันที่ไม่ถูกต้อง'
            }
        }

        if (f.options != null) {
            let is_valid = false
            let option_str = ''
            for (let i in f.options) {
                if (f.options[i].text == d) {
                    is_valid = true
                }
                option_str += (option_str == '' ? '' : ',') + f.options[i].text
            }
            if (is_valid == false && f.required) {
                return 'ไม่อยู่ในตัวเลือกที่กำหนด เช่น ' + option_str
            }
        }
        return ''

    }

    handleError() {
        this.setState({ is_error: true })
    }

    handleSubmit() {
        if (this.state.data.length > 0) {
            let _data = this.state.data
            let _data_list = []
            let check = 0
            for (let i in _data) {
                let o = {}
                for (let j in _data[i]) {
                    if (_data[i][j].error != '') {
                        check = 1
                        break
                    }
                    let f = this.props.fields[j]

                    if (f.type == Number) {
                        _data[i][j].value = Utility.removeCommas(_data[i][j].value)
                    }

                    if (f.type == 'date') {
                        let v = _data[i][j].value.split('/')

                        var date2 = v[2] + "-" + ("0" + v[0]).slice(-2) + "-" + ("0" + v[1]).slice(-2)
                        var date = moment(date2);
                        if (date.isValid()) {
                            o[f.name] = date2
                        } else {
                            o[f.name] = ''
                        }
                    } else
                        o[f.name] = f.options ? this.getValue(_data[i][j].value, f.options) : _data[i][j].value
                }
                _data_list.push(o)
            }
            if (check == 0) {
                this
                    .props
                    .onSubmitData(_data_list)
            } else {
                alert('ไม่สามารถบันทึกได้')
            }

        }
    }

    getValue(text, options) {
        for (let i in options) {
            if (options[i].text == text)
                return options[i].value
        }
        return null
    }

    componentDidMount() {

        this.setState({ table_width: window.innerWidth, table_hiegh: 400 })
    }

    render() {
        let items = this.state.data
        let csvData = []
        for (let i in this.props.fields) {
            let option_str = ''
            if (this.props.fields[i].options != null && this.props.fields[i].hide_options == null && this.props.show != 0) {

                for (let j in this.props.fields[i].options) {
                    option_str += (option_str == '' ? '' : ',') + this.props.fields[i].options[j].text
                }
                csvData.push(this.props.fields[i].title + ' [' + option_str + ']')
            } else {
                csvData.push(this.props.fields[i].title)
            }
        }

        return (
            <div >
                <Modal size={'fullscreen'} open={true} >
                    <Button
                        id='btnCloseCSV'
                        circular
                        icon='close'
                        basic
                        floated='right'
                        name=''
                        onClick={this.props.onClose} />
                    <Modal.Header>Import CSV</Modal.Header>
                    <Modal.Content>
                        <div >
                            <Form>
                                <Form.Field inline error={this.state.is_error}>
                                    <label>เลือกไฟล์ CSV</label>
                                    <div className='ui input'>
                                        <CSVReader
                                            onError={this.handleError}
                                            onFileLoaded={this.handleForce}
                                            inputId='csvr'
                                        />
                                    </div>
                                </Form.Field>
                            </Form>
                        </div>
                        <br />
                        <div id='width_table'>
                            <Table
                                rowsCount={this.state.data.length}
                                rowHeight={35}
                                headerHeight={35}
                                width={this.state.table_width}
                                height={this.state.table_hiegh}>
                                {this
                                    .props
                                    .fields
                                    .map((v, i) => {
                                        return (
                                            <Column
                                                header={< Cell style={{ 'white-space': 'nowrap' }}> {v.title} </Cell>}
                                                cell={< ItemsCell data={items}
                                                    field={i} />}
                                                width={230} />
                                        )
                                    })}
                            </Table>
                            <table >
                                <tr>
                                    <td>จำนวนแถว</td>
                                    <td>
                                        <Label as='span' basic>
                                            {this.state.size}
                                        </Label>
                                    </td>
                                    <td>จำนวนแถว Error</td>
                                    <td>
                                        <Label as='span' basic>
                                            {this.state.size_error}
                                        </Label>
                                    </td>
                                </tr>
                            </table>

                            <Message warning size='mini'>

                                <p> <b>หมายเหตุ</b> 1. แถวที่มี
                                    <Popup color='red' trigger={< Icon name='info circle' color='red' />}>Error</Popup>
                                    จะไม่ถูกบันทึก 2. ข้อมูลที่ซ้ำกันจะไม่ถูกบันทึก</p>
                            </Message>
                        </div>
                        <Dimmer active={this.props.loading}>
                            <Loader>
                                {this.props.loading_title}
                            </Loader>
                        </Dimmer>

                    </Modal.Content>
                    <Modal.Actions>
                        <CSVLink filename={'Template-' + this.props.name + '.csv'} className='ui button' data={[csvData]} ><Icon name='download' /> Template CSV</CSVLink>
                        <Button id='btnSaveCSV' primary content='บันทึก' onClick={this.handleSubmit} />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default ImportCSV