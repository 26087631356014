/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Cell
  } from 'fixed-data-table';
  import {
    Icon
  } from 'semantic-ui-react';

class IconItemsCell extends Component {

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    var color = 'green'
    if(data[rowIndex].kind=='BU'){
      color = 'red'
    }else if(data[rowIndex].kind=='XC'){
      color = 'yellow'
    }
    return (
      <Cell {...props} className={(data[rowIndex].is_void ? 'cell_red cell-time-click' : 'cell-time-click')} title={data[rowIndex].is_void ? 'ถูกยกเลิก โดย' + data[rowIndex].canceler_name : ''} onClick={(e) => {
        this.props.onClick(e, data[rowIndex]);
      }}><div>
            <Icon name='file alternate' color={color} />
          </div>
      </Cell>
    );
  }
}

  export default IconItemsCell;