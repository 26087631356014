/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Button,
  Dimmer,
  Loader,
  Pagination,
  Popup,
  Header,
  Icon,
} from 'semantic-ui-react';
import { connect } from 'react-redux'

class ItemsCell extends Component {
  render() {
    const { rowIndex, field, type, data, ...props } = this.props;
    let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
      : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
        : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
          : data[rowIndex][field];
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    return (
      <Cell {...props} >
        <Popup
          position={this.props.position}
          content={v}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{v}</div>}
        />
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class LeaseStock extends Component {

  constructor(props) {
    super(props);

    this.limit = 10
    this.state = {
      items: [],
      branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
      totalPages: 1,
      page: 1,
      colSortDirs: {
        'lease__record_date': 'ASC',
      },
    }
    this.columnKey = 'lease__record_date'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  resetForm() {
    this.setState({
      branch: 0
    })
  }

  componentDidMount() {

    this.branches = this.props.branches
    let branches = [{
      key: 0,
      value: 0,
      text: '- ทั้งหมด -',
    }];

    for (let i in this.branches) {
      branches.push({
        value: this.branches[i].id,
        key: this.branches[i].id,
        text: this.branches[i].name
      });
    }

    this.setState({ branches })
    this.loadData(this.state.page);

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loadData(1)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  loadData(page) {
    var self = this;
    this.setState({
      loader_active: true
    });

    var s = '';
    if (this.state.branch != null && this.state.branch != '') {
      s += (s == '' ? '' : '&') + 'branch=' + this.state.branch
    }

    var stock_category = Utility.getFetch(Settings.baseUrl + `/lease/0/product/?columnKey=${this.columnKey}&sortDi=${this.sortDi}&lease_status=1,2&${s}&page=${page}&page_size=${this.limit}`);
    Promise.all([stock_category]).then((values) => {
      this.stock_category = values[0];
      this.setState({
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / this.limit),
        counter: values[0].count
      });
    });
    let table_width = document.getElementById('table_width')
    this.setState({ table_width: table_width.clientWidth });
  }

  submitSearch(event) {
    event.preventDefault();
    this.loadData(this.state.page);
  }

  getTypeSale(key) {
    let type = Settings.type_sale.filter((o) => { return o.value == key });
    return type[0];
  }

  setFieldValue(items) {
    let _items = []
    for (let i = 0; i < items.length; i++) {
      if (items[i].lease.status > 2)
        continue
      let item = items[i];
      item.number = item.lease.number
      item.branch_name = item.lease.branch.name
      item.category_name = item.category.name
      item.product_name = item.product_name
      item.customer_name = item.lease.customer.name
      item.interest = item.lease.interest
      item.total = Utility.priceFormat(parseFloat(item.lease.total_interest))
      item.end_date = Utility.formatDate(item.lease.end_date)

      item.status_title = Settings.status_lease[item.lease.status]

      _items.push(item)
    }

    return _items;
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loadData(activePage)
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>

        <Form className='fluid' size='small' onSubmit={this.submitSearch}>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>สต็อกขายฝาก</Header>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Dropdown label='สาขา' search selection width={4} options={this.state.branches} value={this.state.branch} onChange={(e, data) => {
              this.setState({ branch: data.value });
            }} />
            <Form.Field width={5}>
              <br />
              <Button ><Icon name='search' />ค้นหา</Button>
              <Button onClick={this.resetForm} >ทั้งหมด</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={402}>
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='lease__number'
                sortDir={this.state.colSortDirs.lease__number}>
                เลขที่ใบขายฝาก
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="number" />
              }
              width={150}
            />
            <Column

              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='lease__branch__name'
                sortDir={this.state.colSortDirs.lease__branch__name}>
                สาขา
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="branch_name" />
              }
              width={150}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='lease__customer__name'
                sortDir={this.state.colSortDirs.lease__customer__name}>
                ชื่อลูกค้า
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="customer_name" />
              }
              width={150}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='category__name'
                sortDir={this.state.colSortDirs.category__name}>
                %
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="category_name" />
              }
              width={150}
            />
            <Column
              header={<Cell>ชื่อสินค้า</Cell>}
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='product_name'
                sortDir={this.state.colSortDirs.product_name}>
                ชื่อสินค้า
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={150} data={items} field="product_name" />
              }
              width={150}
            />
            <Column
              header={<SortHeaderCell
                className="text-right"
                onSortChange={this.onSortChange}
                field='lease__interest'
                sortDir={this.state.colSortDirs.lease__interest}>
                อัตราดอกเบี้ย
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={160} data={items} field="interest" textAlign='text-right' type='price' />
              }
              width={160}
            />
            <Column
              header={<SortHeaderCell
                className="text-right"
                onSortChange={this.onSortChange}
                field='weight'
                sortDir={this.state.colSortDirs.weight}>
                น้ำหนัก
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={160} data={items} field="weight" textAlign='text-right' type='weight' />
              }
              width={160}
            />
            <Column
              header={<SortHeaderCell
                className="text-right"
                onSortChange={this.onSortChange}
                field='lease__total_interest'
                sortDir={this.state.colSortDirs.lease__total_interest}>
                รวมดอกเบี้ยรับ
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={160} data={items} field="total" textAlign='text-right' type='price' />
              }
              width={160}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='lease__end_date'
                sortDir={this.state.colSortDirs.lease__end_date}>
                ครบกำหนด
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={160} data={items} field="end_date" />
              }
              width={160}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='lease__status'
                sortDir={this.state.colSortDirs.lease__status}>
                สถานะ
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={160} data={items} field="status_title" />
              }
              width={160}
            />
          </Table>
          <br />
          <Form size='small'>
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
              <br />
              <Form.Field width={14}>
                <br />
                {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
          {/* {this.state.totalPages > 1 && <Pagination
            activePage={this.state.page}
            onPageChange={this.handlePaginationChange.bind(this)}
            totalPages={this.state.totalPages}
          />} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}

export default connect(
  mapStateToProps,
)(LeaseStock)
