/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import OptionItemsCell from '../OptionItemsCell'
import MsgInput from '../../Error/MsgInput'
import image from '../../../images/image.png'
import {
  Form,
  Input,
  Button,
  Modal,
  TextArea,
  Dropdown,
  Image
} from 'semantic-ui-react';
class RewardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg_error: {},
      is_enabled: 1,
      loading: false,
      modal_title: props.action=='edit'?'แก้ไข':'สร้างรายการของรางวัล'
    }

    if(props.action=='edit'){
        for(let k in props.reward){
            this.state[k] = props.reward[k]
        }
        this.state['image'] = null
        this.state['image_preview'] = props.reward.image.thumbnail
    }else{
      this.state['image_preview'] = image
    }

    this.submitData = this.submitData.bind(this)
  }


  submitData(e){
    e.preventDefault()

    this.setState({loading: true})
    

    var formData = new FormData();
    if(this.state.image)
      formData.append('image', this.state.image);
    formData.append('name', this.state.name);
    formData.append('detail', this.state.detail);
    formData.append('score', this.state.score);
    formData.append('is_enabled', this.state.is_enabled);

    this.setState({loading: true})

    if(this.props.action=='add'){
      let url = Settings.baseUrl+"/reward/"
      Utility.postUpload(url,formData,(status,res,code)=>{

        if(status){
            this.props.onSaved()
        }else{
            this.setState({
                msg_error: res
            })
        }
        this.setState({loading: false})
      })
    }else{
      let url = Settings.baseUrl+"/reward/"+this.props.reward.id+"/"
      Utility.patchUpload(url,formData,(status,res,code)=>{
        if(status){
            this.props.onSaved()
        }else{
            this.setState({
                msg_error: res
            })
        }
        this.setState({loading: false})
      }) 
    }

  }

  render() {

    return (
      <div>
          <Modal open={true} size='tiny' /*dimmer='blurring'*/  >
      <Button
        circular
        icon='close'
        basic
        floated='right'
        name=''
        onClick={()=>this.props.onClose()}/>
        <Modal.Header>{this.state.modal_title}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size='small'>
              <Form.Field error={this.state.msg_error.name!=null}>
                <label>*ชื่อ <MsgInput text={this.state.msg_error.name} /></label>
                <Input placeholder='ชื่อ' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
              </Form.Field>
              <Form.Field error={this.state.msg_error.detail!=null}>
                <label>รายละเอียด <MsgInput text={this.state.msg_error.detail} /></label>
                <TextArea placeholder='รายละเอียด' onChange={(e) => this.setState({ detail: e.target.value })} value={this.state.detail} />
              </Form.Field>
              <Form.Field error={this.state.msg_error.score!=null}>
                <label>*ใช้คะแนน <MsgInput text={this.state.msg_error.score} /></label>
                <Input 
                // type='number' 
                placeholder='ใช้คะแนน'
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) {
                  } else {
                    this.setState({ score: Number(e.target.value) })}
                  }
                } value={this.state.score} />
              </Form.Field>
              <Form.Field error={this.state.msg_error.score!=null}>
                <label>*รูปภาพ <MsgInput text={this.state.msg_error.image} /></label>
                <Input onChange={(e) => this.setState({ image: e.target.files[0],image_preview: URL.createObjectURL(e.target.files[0]) })} name="image" type="file"/>
               
                <Image src={this.state.image_preview} size='small' bordered />
              </Form.Field>
              <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown selection fluid onChange={(e,v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />
                  
                </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button size='small' primary onClick={this.submitData}
            className={this.state.button_class} loading={this.state.loading}>บันทึก</Button>
          <Button size='small' onClick={(e) =>this.props.onClose()}>ยกเลิก</Button>
        </Modal.Actions>
      </Modal>
    </div>

    );
  }
}

export default RewardForm;
