/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                out_date_lt: moment(),
                number: 0,
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var vendors = Utility.getFetch(Settings.baseReportUrl + '/vendors/?is_enabled=1');
        Promise.all([branches, vendors]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.vendors = values[1];
            let vendors = []
            for (let i in this.vendors) {
                vendors.push({
                    value: this.vendors[i].id,
                    key: this.vendors[i].id,
                    text: this.vendors[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                vendors: vendors
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                number: 0,
                branch: this.props.auth.branch.id,
                out_date_lt: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.out_date_lt = Utility.formatDate2(moment().add(- parseInt(this.state.search.number), 'day'));

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/stock_product/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {
                this.setState({
                    loading: false,
                    items: d
                })
            }
        })
    }
    handlerInput(e, v) {
        if (isNaN(Number(v.value))) {
        } else {
            let search = this.state.search
            search[v.name] = v.value
            this.setState({
                search: search
            })
        }

    }
    render() {
        let data = this.props.data

        let title = 'รายงานสินค้าไม่เคลื่อนไหว';
        let filename = 'stock_motion-' + Utility.formatDate3(moment());

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let amount_all = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสินค้าไม่เคลื่อนไหว</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field>
                                <label>จำนวนวันที่สินค้าไม่เคลื่อนไหว (วัน)</label>
                                <Input
                                    className='text-right'
                                    // type='number' 
                                    name='number' value={this.state.search.number} onChange={this.handlerInput} maxLength={4} />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='day'>จำนวนวันที่สินค้าไม่เคลื่อนไหว : {Utility.numberFormat(this.state.search.number)} วัน</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft}>รหัส</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุน(บาทละ/ชิ้นละ)</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่ซื้อ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่ขายล่าสุด</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                            amount_all += item.amount
                                            return (<Table.Row id={item.product.code}><Table.Cell style={textLeft}>{item.product.code}</Table.Cell>
                                                <Table.Cell style={textLeft}>{item.product.name}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.product.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(item.amount)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.cost)}</Table.Cell>
                                                <Table.Cell style={textCenter}>{Utility.formatDate(item.in_date)}</Table.Cell>
                                                <Table.Cell style={textCenter}>{Utility.formatDate(item.out_date)}</Table.Cell></Table.Row>)
                                        })}
                                        <Table.Row >
                                            <Table.Cell colSpan='3' style={textRight}><b><u>รวมทั้งหมด</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.numberFormat(amount_all)}</u></b></Table.Cell>
                                            <Table.Cell colSpan='3'></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)