/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const staffWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }
        const data = this.props.items
        let kind_arr = data.items
        let weight = 0
        let total = 0


        return (<Table.Body>
            <Table.Row>
                <Table.Cell colSpan='2' style={textLeft}>{data.title}</Table.Cell>
                <Table.Cell colSpan='6'></Table.Cell>
            </Table.Row>
            {kind_arr.map((row, i) => {
                total += parseFloat(row.buy)
                weight += parseFloat(row.weight)
                return (<Table.Row>
                    <Table.Cell ></Table.Cell>
                    <Table.Cell style={textCenter}>{Utility.formatDate(row.record_date)}</Table.Cell>
                    <Table.Cell style={textCenter}>{Utility.formatTime(row.record_date)}</Table.Cell>
                    <Table.Cell style={textLeft}>{row.product_name}</Table.Cell>
                    <Table.Cell style={textRight}>{Utility.weightFormat(row.weight)}</Table.Cell>
                    <Table.Cell style={textRight}>{Utility.priceFormat(row.buy)}</Table.Cell>
                    <Table.Cell style={textLeft, staffWidth}>{row.staff_name}</Table.Cell>
                    <Table.Cell style={textRight}>{Utility.priceFormat((row.category.weight / row.weight) * row.buy)}</Table.Cell>
                </Table.Row>)
            })}
            <Table.Row>
                <Table.Cell colSpan='3'></Table.Cell>
                <Table.Cell style={textCenter}><div style={textU}>รวม</div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}>{Utility.weightFormat(weight)}</div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(total)}</div></Table.Cell>
                <Table.Cell colSpan='2'></Table.Cell>
            </Table.Row>
        </Table.Body>)
    }
}

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([branches, staffs, product_types]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }

            this.product_types = values[2];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                product_types: product_types
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let q = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/bill_items/?kind=BU&status_stock=Y&" + q
        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {

                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            } else {
                this.setState({
                    loading: false,
                    items: []
                })
            }
        })
    }

    setFieldValue(_items) {


        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.branch) {
                if (item.bill.branch.id != this.state.search.branch)
                    continue;
            }


            item.date = Utility.formatDate(item.record_date)
            item.time = Utility.formatTime(item.record_date)
            item.number = item.bill.bill_number
            item.customer_name = item.bill.customer.name
            item.weight_b = Utility.weightFormat(item.weight / item.category.weight)

            try {
                let staffs = JSON.parse(item.bill.staffs)
                if (staffs.length > 0) {
                    let name = '';
                    let is_staff = false
                    for (let s in staffs) {
                        let st = Utility.getObject(this.staffs, staffs[s])
                        if (name != '')
                            name += ','
                        if (st) {
                            name += st.name
                        }

                        // check staff id
                        if (this.state.search.staff && is_staff == false) {
                            is_staff = this.state.search.staff == staffs[s]
                        } else {
                            is_staff = true;
                        }
                    }

                    if (!is_staff)
                        continue
                    item.staff_name = name
                } else if (this.state.search.staff != null)
                    continue
            } catch (e) {

            }

            items.push(item)
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let title = 'รายงานซื้อทองเก่า';
        let filename = 'buy-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const descriptionWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }
        let total = 0
        let weight = 0

        let category = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (category[item.category.id] == null)
                category[item.category.id] = {
                    items: [],
                    title: item.category.name
                }

            category[item.category.id].items.push(item)

            weight += parseFloat(item.weight)
            total += parseFloat(item.buy)

        }
        let items2 = []
        for (let i in category) {
            items2.push(category[i])
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานซื้อทองเก่า</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='8'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='8'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='8'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เวลา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ชื่อสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.ทอง(g)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาซื้อ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>พนักงาน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาซื้อ/บาท</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {items2.map((row, i) => <GroupRow key={i} items={row} />)}
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell colSpan='3'></Table.Cell>
                                            <Table.Cell style={textCenter}><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='weight' style={textU}><b>{Utility.weightFormat(weight)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total' style={textU}><b>{Utility.priceFormat(total)}</b></div></Table.Cell>
                                            <Table.Cell colSpan='2'></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)