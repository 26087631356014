/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Icon, Dropdown, Table, Pagination
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

function formatDate(date) {
    const formattedDate = date.format('YYYY-MM-01');
    const formattedText = date.format('MM/YYYY');
    return {
        key: formattedDate,
        value: formattedDate,
        text: formattedText
    };
}

class OverviewReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: moment().format('YYYY-MM-01'),
            end_date: moment().format('YYYY-MM-01'),
            item: [],
            total_item: 0,
            page_size: 5,
            activePage: 1,
            month_option: [],
            submitting: false,
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.loadDataInterval = setInterval(() => {
            this.loadReport(this.state.activePage)
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.loadDataInterval)
    }
    componentDidMount() {
        let currentDate = moment()
        let oldestDate = moment('2020-01-01')
        let options = []
        while (currentDate >= oldestDate) {
            options.push(formatDate(currentDate));
            currentDate.subtract(1, 'month');
        }
        this.setState({ month_option: options })
        this.loadReport(this.state.activePage);
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ activePage: activePage })
        this.loadReport(activePage);
    }

    loadReport(page) {
        let url = Settings.baseReportUrl + `/report/overview/?page=${page}&page_size=${this.state.page_size}`
        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                                this.setState({
                    loading: false,
                    total_item: d.count,
                    item: d.results,
                    total_page: d.next == null && d.previous == null ? 0 : Math.ceil(d.count / this.state.page_size),
                                })
            }
        })
    }

    resetForm(e) {
        this.setState({
            start_date: moment().format('YYYY-MM-01'),
            end_date: moment().format('YYYY-MM-01'),
        })
    }

    handlerSubmit(e) {
        e.preventDefault();
        let url = Settings.baseUrl + "/report/overview/"
        this.setState({
            submitting: true
        })
        Utility.post(url, { start_date: this.state.start_date, end_date: this.state.end_date }, (s, d) => {
            this.loadReport(1);
            this.setState({
                activePage: 1,
            })
            setTimeout(()=>{
                this.setState({
                    submitting: false,
                })
            },2000)
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    delReport(id) {
        if (window.confirm('ยืนยันลบ')) {
            let url = Settings.baseReportUrl + `/report/overview/${id}/`
            this.setState({
                submitting: true
            })
            Utility.delete(url, (s, d) => {
                if (s) {
                    console.log('dell')
                    var tmppage = this.state.item.length == 1 ? this.state.activePage == 1 ? 1 : this.state.activePage - 1 : this.state.activePage
                    this.loadReport(tmppage);
                    this.setState({
                        activePage: tmppage,
                        submitting: false,
                    })
                    this.resetForm()
                }
            })
        }
    }

    render() {
        const tableStyle = {
            color: '#111',
            width: '100%',
            lineHeight: '20px',
        };
        const headerCellStyle = {
            textAlign: 'center',
            color: '#fff',
        }
        const tableHeaderStyle = {
            backgroundColor: '#db2828',
        }
        const textCenter = {
            textAlign: 'center'
        }
        return (
            <div>
                <Segment color='black' style={{
                    height: '90vh',
                    overflow: 'auto'
                }}>
                    <Form >
                        <Form.Group>
                            <Form.Field>
                                <Header floated='left' as='h4'>รายงานสรุปภาพรวม</Header>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <div className="box-login">
                        <Segment textAlign='left' >
                            <Header size='small'>เงื่อนไขค้นหา</Header>
                            <Form size='small' onSubmit={this.handlerSubmit}>
                                <Form.Field >
                                    <label>จากเดือน</label>
                                    <Dropdown
                                        selection
                                        value={this.state.start_date}
                                        options={this.state.month_option}
                                        onChange={(e, data) => {
                                            if (moment(data.value) > moment(this.state.end_date)) {
                                                this.setState({ start_date: data.value, end_date: data.value });
                                            } else {
                                                this.setState({ start_date: data.value });
                                            }
                                        }} />
                                </Form.Field>
                                <Form.Field >
                                    <label>ถึงเดือน</label>
                                    <Dropdown
                                        selection
                                        value={this.state.end_date}
                                        options={this.state.month_option.filter(d => moment(d.value) >= moment(this.state.start_date))}
                                        onChange={(e, data) => {
                                            this.setState({ end_date: data.value });
                                        }} />
                                </Form.Field>
                                <Button disabled={this.state.submitting}>พิมพ์</Button>
                                <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                            </Form>
                        </Segment>
                    </div>
                    {this.state.total_item > 0 &&
                        <>
                            <Table basic style={tableStyle}>
                                <Table.Header style={tableHeaderStyle}>
                                    <Table.Row>
                                        <Table.HeaderCell style={headerCellStyle} width={6}>ชื่อรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>วันที่ออกรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>สถานะรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={4}>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.state.item.map((item, i) => (
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.filename}</Table.Cell>
                                            <Table.Cell style={textCenter}>{`${Utility.formatDate(item.created_at)} ${Utility.formatTimeSecond(item.created_at)}`}</Table.Cell>
                                            <Table.Cell style={textCenter}>{item.status_display}</Table.Cell>
                                            <Table.Cell style={textCenter}>
                                                <Button onClick={() => this.delReport(item.id)} color='red' disabled={item.status !== 1}><Icon name="trash" />ลบ</Button>
                                                <Button target="__blank" href={`${Settings.mediaUrl}${item.sub_path}`} color='blue' disabled={item.status !== 1}><Icon name="cloud download" />ดาวน์โหลด</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            <Form size='small'>
                                <Form.Group>
                                    <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.total_item)} />
                                    <Form.Field width={16}>
                                        <br />
                                        {this.state.total_page > 1 &&
                                            <Pagination
                                                floated='right'
                                                size='tiny'
                                                activePage={this.state.activePage}
                                                onPageChange={this.handlePaginationChange.bind(this)}
                                                totalPages={this.state.total_page}
                                            />
                                        }
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </>
                    }
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(OverviewReport)
