/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import MsgInput from '../../Error/MsgInput'
import image from '../../../images/image.png'
import InputSearchCustomer from '../../Customer/InputSearchCustomer'
import CustomerModal from '../../Customer/CustomerModal';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  Form,
  Input,
  Button,
  Modal,
  TextArea,
  Dropdown,
  Image,
  Icon
} from 'semantic-ui-react';
class RedeemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg_error: {},
      is_enabled: 1,
      loading: false,
      modal_title: props.action == 'edit' ? 'แก้ไข' : 'สร้างรายการขอแลกของรางวัล',
      kind: '',
      status: '1',
      reward: '',
      amount : '',
      address: '',
      receipt_date: moment()
    }

    if (props.action == 'edit') {
      for (let k in props.redeem) {
        this.state[k] = props.redeem[k]
        if(k=='reward' || (k=='branch' && props.redeem[k]!=null) || (k=='staff' && props.redeem[k]!=null))
          this.state[k] = props.redeem[k].id
        
        if(k=='receipt_date')
        this.state[k] = moment(props.redeem[k])

        if(k=='status' && props.redeem[k]!=1){
          this.state['status_disabled'] = true
        }
      }
    }
    this.submitData = this.submitData.bind(this)
    this.handlerChangeKind = this.handlerChangeKind.bind(this)
    this.handlerChangeCustomer = this.handlerChangeCustomer.bind(this)
    this.handlerAddCustomer = this.handlerAddCustomer.bind(this)
    this.handlerCreateCustomer = this.handlerCreateCustomer.bind(this)
  }

  handlerAddCustomer(e) {
    e.preventDefault();
    this.setState({
        modal_open: true
    })
}

  handlerCreateCustomer(data){
    this.setState({
      customer:data.id
    })
  }

  componentWillMount() {
    this.setState({st:true})
    let url = Settings.baseUrl + '/redeem/'
    Utility.options(url, (s, r, c) => {

      this.setState({
        status_choices: Utility.setOptionFetch(r, 'status'),
        kind_choices: Utility.setOptionFetch(r, 'kind')
      })
    })

    var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
    var reward = Utility.getFetch(Settings.baseUrl + '/reward/?is_enabled=1&page_size=10000');
    var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1');
    Promise.all([branches, reward,staffs]).then((values) => {
      this.branches = values[0];
      let branches = []
      for (let i in this.branches) {
        branches.push({
          value: this.branches[i].id,
          key: this.branches[i].id,
          text: this.branches[i].name
        });
      }

      this.reward = values[1].results;
      let reward = []
      for (let i in this.reward) {
        reward.push({
          value: this.reward[i].id,
          key: this.reward[i].id,
          text: this.reward[i].name
        });
      }

      this.staffs = values[2];
      let staffs = []
      for (let i in this.staffs) {
        staffs.push({
          value: this.staffs[i].id,
          key: this.staffs[i].id,
          text: this.staffs[i].name
        });
      }

      this.setState({
        loader_active: false,
        branches_option: branches,
        reward_option: reward,
        staffs_option: staffs,
        st:false
      });
    });
  }


  submitData(e) {
    e.preventDefault()
    let is_error = false
    let error = {}
   
    if(this.state.status=='CC' && !window.confirm('ยืนยันการยกเลิกรายการ')){
      return;
    }
  
    if((this.state.status=='3'||this.state.status=='2') && !window.confirm('ยืนยันส่งสินค้า')){
      return;
    }

    if((this.state.status=='4' ) && (this.state.remark=='' || this.state.remark==null)){
      alert('*กรุณาระบุหมายเหตุ')
      this.setState({
        msg_error: {remark: '*กรุณาระบุหมายเหตุ'}
      })
      return;
    }
    if((this.state.status=='2' && this.state.kind==2) && (this.state.remark=='' || this.state.remark==null)){
      alert('*กรุณาระบุหมายเหตุ')
      this.setState({
        msg_error: {remark: '*กรุณาระบุหมายเหตุ'}
      })
      return;
    }

    if (this.state.customer == null || this.state.customer == ''){
      error['customer'] = '*กรุณากรอกลูกค้า'
      is_error = true
    }

    if (this.state.reward == null || this.state.reward == ''){
      error['reward'] = '*กรุณาระบุของรางวัล'
      is_error = true
    }

    if (this.state.amount == null || this.state.amount == ''){
      error['amount'] = '*กรุณาระบุจำนวนชิ้น'
      is_error = true
    }

    if(this.state.kind == null || this.state.kind == ''){
      error['kind'] = '*กรุณาระบุวิธีรับของรางวัล'
      is_error = true
    }

    if(this.state.branch == null || this.state.branch == ''){
      if(this.state.kind == 1){
        error['branch'] = '*กรุณาระบุสาขาที่จะรับ'
        is_error = true
      }
    }
    if(this.state.address == null || this.state.address === ''){
      if(this.state.kind == 2){
        error['address'] = '*กรุณาระบุที่อยู่'
        is_error = true 
      }
    }

    if(this.state.staff == null || this.state.staff == ''){
      error['staff'] = '*กรุณาเลือกพนักงาน'
      is_error = true
    }

    if(is_error){
      this.setState({
        msg_error:error
      })
      return;
    }

    var formData = {
      
      "customer": this.state.customer,
      "reward": this.state.reward,
      "amount": this.state.amount,
      "status": this.state.status,
      "kind": this.state.kind,
      "remrak": this.state.remark,
      "staff": this.state.staff,
  }

  if(this.state.kind==1){
    formData['branch'] = this.state.branch
    formData['receipt_date'] = Utility.formatDate2(this.state.receipt_date)
  }else{
    formData['address'] = this.state.address
  }

    this.setState({ loading: true })
    this.setState({ loading: true })
    if (this.props.action == 'add') {
      let url = Settings.baseUrl + "/redeem/"
      Utility.post(url, formData, (status, res, code) => {

        if (status) {
          this.props.onSaved()
        } else {
          this.setState({
            msg_error: res
          })
        }
        this.setState({ loading: false })
      })
    } else {
      delete formData.customer
      let url = Settings.baseUrl + "/redeem/" + this.props.redeem.id + "/"
      Utility.patch(url, formData, (status, res, code) => {
        if (status) {
          this.props.onSaved()
        } else {
          this.setState({
            msg_error: res
          })
        }
        this.setState({ loading: false })
      })
    }

  }

  getAddressCustomer(){
    let d = this.state.customer_data
    if(d){
      if(d.address_primary==1)
        return d.address +" "+d.city + " "+d.province +" "+ d.postal_code
      else if(d.address_primary==2)
        return d.address2 +" "+d.city2 + " "+d.province2 +" "+ d.postal_code2
      else if(d.address_primary==3)
        return d.address3 +" "+d.city3 + " "+d.province3 +" "+ d.postal_code3
    }
  }

  handlerChangeKind(e,v){
    let address = this.state.address || this.getAddressCustomer()

    if(this.state.kind==1){
      address = ''
    }

    this.setState({
      kind: v.value,
      address: address
    })

  }

  handlerChangeCustomer(e,v,data){

    let address = this.state.address || this.getAddressCustomer()
    if(this.state.kind==1){
      address = ''
    }
    this.setState({customer: v.value,customer_data: data,address: address})
  }

  render() {

    return (
      <div>
        <Modal open={true} size='tiny' /*dimmer='blurring'*/  >
          <Button
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.props.onClose()} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small'>
                <Form.Field width={16} error={this.state.msg_error.customer != null}>
                  <label>*ลูกค้า <MsgInput text={this.state.msg_error.customer} /></label>
                  {
                    this.props.action=='add'?<div className='option-add'>
                      <Button icon onClick={this.handlerAddCustomer} type='button'><Icon name='add' /></Button>
                      <InputSearchCustomer as='Dropdown' label='ลูกค้า' fluid placeholder='พิมพ์ชื่อลูกค้า' width={13} onChange={this.handlerChangeCustomer} name="customer_id" value={this.state.customer} />
                    </div>:<Input type='text' value={this.state.customer.name} readOnly />
                    }
                  
                </Form.Field>
                <Form.Field error={this.state.msg_error.reward != null}>
                  <label>*ของรางวัล <MsgInput text={this.state.msg_error.reward} /></label>
                  <Dropdown placeholder='ของรางวัล' selection fluid onChange={(e, v) => this.setState({ reward: v.value })} value={this.state.reward} options={this.state.reward_option} disabled={this.props.action=='edit'} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.amount != null}>
                  <label>*จำนวนชิ้น <MsgInput text={this.state.msg_error.amount} /></label>
                  <Input 
                  // type='number' 
                  placeholder='จำนวนชิ้น'
                  onChange={(e, v) => {
                    if (isNaN(Number(v.value))) {
                    } else {
                      this.setState({ amount: v.value })}
                    }
                  }
                   value={this.state.amount} readOnly={this.props.action=='edit'} />
                </Form.Field>
                {this.props.action=='edit' && <Form.Field error={this.state.msg_error.status != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.status} /></label>
                  <Dropdown selection fluid onChange={(e, v) => this.setState({ status: v.value })} value={this.state.status} options={this.state.status_choices} disabled={this.state.status_disabled}  />
                </Form.Field>}
                
                <Form.Field error={this.state.msg_error.kind != null}>
                  <label>*วิธีรับของรางวัล <MsgInput text={this.state.msg_error.kind} /></label>
                  <Dropdown placeholder='วิธีรับของรางวัล' selection fluid onChange={this.handlerChangeKind} value={this.state.kind} options={this.state.kind_choices} disabled={this.props.action=='edit' && this.state.status_stock=='Y'} />
                </Form.Field>                

                {this.state.kind == 1 &&<Form.Field error={this.state.msg_error.receipt_date != null}>
                  <label>วันที่รับสินค้า <MsgInput text={this.state.msg_error.receipt_date} /></label>
                  <DatePicker
                    disabled={this.props.action=='edit' && this.state.status_stock=='Y'} 
                    selected={this.state.receipt_date}                    
                    dateFormat="DD/MM/YYYY"
                    onChange={(d) => {
                      this.setState({
                        receipt_date: d
                      })
                    }}
                  />
                </Form.Field>}

                {this.state.kind == 1 && <Form.Field error={this.state.msg_error.branch != null}>
                  <label>*สาขาที่จะรับ <MsgInput text={this.state.msg_error.branch} /></label>
                  <Dropdown placeholder='สาขาที่จะรับ' selection fluid onChange={(e, v) => this.setState({ branch: v.value })} value={this.state.branch} options={this.state.branches_option} disabled={this.props.action=='edit' && this.state.status_stock=='Y'}  />
                </Form.Field>}

                {this.state.kind == 2 &&<Form.Field error={this.state.msg_error.address != null}>
                  <label>*ที่อยู่จัดส่ง <MsgInput text={this.state.msg_error.address} /></label>
                  <TextArea placeholder='ที่อยู่ที่จัดส่ง' onChange={(e) => this.setState({ address: e.target.value })} value={this.state.address} disabled={this.props.action=='edit' && this.state.status_stock=='Y'}  />
                </Form.Field>}
                <Form.Field error={this.state.msg_error.staff != null}>
                  <label>*พนักงาน <MsgInput text={this.state.msg_error.staff} /></label>
                  <Dropdown placeholder='พนักงาน' loading={this.state.st} selection fluid onChange={(e, v) => this.setState({ staff: v.value })} value={this.state.staff} options={this.state.staffs_option} disabled={this.props.action=='edit' && this.state.status_stock=='Y'}  />
                </Form.Field>
                <Form.Field error={this.state.msg_error.remark != null}>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.remark} /></label>
                  <TextArea placeholder='หมายเหตุ' onChange={(e) => this.setState({ remark: e.target.value })} value={this.state.remark}  />
                </Form.Field>

                <center><MsgInput text={this.state.msg_error.error} /></center>
              </Form>

              {this.state.modal_open && <CustomerModal open={this.state.modal_open} onCreate={this.handlerCreateCustomer} onClose={() => { this.setState({ modal_open: false }) }} />}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button size='small' primary onClick={this.submitData}
              className={this.state.button_class} loading={this.state.loading}>บันทึก</Button>
            <Button size='small' onClick={(e) => this.props.onClose()}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
      </div>

    );
  }
}

export default RedeemForm;
