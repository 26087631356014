/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Popup,
  Modal,
  Input,
  Grid,
  Radio,
  Pagination,
  Icon,
} from "semantic-ui-react";
import moment from "moment";
import { Table, Column, Cell } from "fixed-data-table";
import Settings from "../../Settings";
import Utility from "../../Utility";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import OptionItemsCell from "./Cell/OptionItemsCell";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import { lease_modal_edit, lease_modal_add_open, lease_modal_close } from "../../actions";
import MsgInput from "../Error/MsgInput";
import DatePicker from "react-datepicker";
import OpenAccount from "./OpenAccount";
import AccountDetail from './AccountDetail'
import ImportSavingAccount from '../Import/ImportSavingAccount'
import ImportModal from './Modal/ImportModal'
import InputSearchCustomer from '../Customer/InputSearchCustomer';

function collect(props) {
  return { positon: props.positon };
}
class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = this.props.width - 10 + "px";
    var ellipsisStyle = {
      whiteSpace: "nowrap",
      width: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    return (
      <ContextMenuTrigger
        id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}
      >
        <Cell {...this.props} onClick={() => { }}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            trigger={
              <div
                style={ellipsisStyle}
                className={
                  (this.props.textAlign == null ? "" : this.props.textAlign) +
                  " text-cell"
                }
              >
                {data[rowIndex][field]}
              </div>
            }
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

var SortTypes = {
  ASC: "ASC",
  DESC: "DESC",
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} className={this.props.className ? this.props.className : ""}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? "↓" : "↑") : ""}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ? reverseSortDirection(this.props.sortDir) : SortTypes.DESC
      );
    }
  }
}

class Saving extends Component {
  constructor(props) {
    super(props);
    this.data_search = null;
    this.state = {
      items: [],
      total_item: 0,
      open: false,
      modal_data: {},
      is_enabled: 1,
      msg_error: {},
      colSortDirs: {
        number: "ASC",
      },
      in_date_start: moment(),
      in_date_end: moment(),
      radio: 1,
      status_in: "1,2,3",
      customer: "",
      critizen_id: "",
      totalPages: 1,
      page: 1,
      branch_id: 0,
      number: ''
    };

    this.columnKey = "number";
    this.sortDi = "ASC";
    this.onSortChange = this.onSortChange.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loaddata2 = this.loaddata2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onSortChange(columnKey, sortDir) {
    this.columnKey = columnKey;
    this.sortDi = sortDir;
    this.loaddata2(this.state.page);
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  loaddata2(page) {
    var savings = Utility.getFetch(
      Settings.baseUrl +
      "/savings/?columnKey=" +
      this.columnKey +
      "&sortDi=" +
      this.sortDi +
      "&" +
      this.state.qr +
      "&page=" +
      page
    );

    Promise.all([savings]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
      });
    });
  }

  componentDidMount() {
    var branches = Utility.getFetch(Settings.baseUrl + "/branches/");
    Promise.all([branches]).then((values) => {
      this.branches = values[0];
      this.vendors = values[1];
      let branches = [];

      branches.push({
        key: 0,
        value: 0,
        text: "- ทุกสาขา -",
      });
      for (let i in values[0]) {
        if (values[0][i].is_enabled == 1)
          branches.push({
            key: values[0][i].id,
            value: values[0][i].id,
            text: values[0][i].name,
          });
      }
      this.setState({
        branch_id: 0,
        branches: branches,
      });
    });
    this.handleSubmit();
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById("client_width");
    this.setState({ table_width: elHeight.clientWidth });
  };

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]["branch_name"] = item[i].branch.name;
      item[i]["balance"] = Utility.priceFormat(item[i].balance);
      item[i]["status_display"] = item[i].status == "O" ? "ใช้งาน" : "ปิดการใช้งาน";
      item[i]["remark"] = item[i].remark == null ? "" : item[i].remark;
      item[i]["open_date"] =
        item[i].open_date == null ? "" : Utility.formatDate(item[i].open_date);
      item[i]["close_date"] =
        item[i].close_date == null ? "" : Utility.formatDate(item[i].close_date);
    }
    return item;
  }

  onContextMenu(e, data) {
    e.preventDefault();
    lease_modal_edit(this.props.dispatch, data.id);
  }

  handleClick(e, data) {
    this.setState({ detail: true });
    this.setState({ itm: this.state.items[data.positon] });
  }

  handleInputChange(e, { name, value, q }) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    this.setState({
      loader_active: true,
    });
    var s = "";
    if (this.state.in_date_end != null && this.state.in_date_end != "") {
      s +=
        (s == "" ? "" : "&") + "end_date=" + Utility.formatDate2(this.state.in_date_end);
    }
    if (this.state.in_date_start != null && this.state.in_date_start != "") {
      s +=
        (s == "" ? "" : "&") +
        "start_date=" +
        Utility.formatDate2(this.state.in_date_start);
    }
    if (this.state.radio == 2) {
      s += (s == "" ? "" : "&") + "status=O";
    }
    if (this.state.radio == 3) {
      s += (s == "" ? "" : "&") + "status=C";
    }
    if (this.state.number != null && this.state.number != "") {
      s += (s == "" ? "" : "&") + "number=" + this.state.number.trim();
    }
    if (this.state.customer != null && this.state.customer != "") {
      s += (s == "" ? "" : "&") + "name=" + this.state.customer.trim();
    }
    if (this.state.citizen_id != null && this.state.citizen_id != "") {
      s += (s == "" ? "" : "&") + "citizen_id=" + this.state.citizen_id.trim();
    } if (this.state.branch_id != 0) {
      s += (s == "" ? "" : "&") + "branch=" + this.state.branch_id;
    }
    if (this.state.customer_id != null && this.state.customer_id != "") {
      s += (s == "" ? "" : "&") + "customer_id=" + this.state.customer_id;
    }

    this.setState({ qr: s });
    var savings = Utility.getFetch(
      Settings.baseUrl +
      "/savings/?columnKey=" +
      this.columnKey +
      "&sortDi=" +
      this.sortDi +
      "&" +
      s +
      "&page=1"
    );

    Promise.all([savings]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
    });
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage });
    this.loaddata2(activePage);
  }

  clearFormSearch() {
    this.setState({
      branch_id: 0,
      number: "",
      citizen_id: "",
      customer: "",
      in_date_start: moment(),
      in_date_end: moment(),
      customer_id: "",
    });
  }

  render() {
    const items = this.state.items;
    const { branch, role } = this.props.auth;
    const { number, customer, citizen_id, in_date_start, in_date_end } = this.state;
    return (
      <div>
        <ImportModal open={this.state.import_open} onClose={() => this.setState({ import_open: false })}
          onUpdate={() => {
            this.setState({
              import_open: false,
            });
            this.componentDidMount()
          }} />
        <Form>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated="left" as="h3">
                บัญชีออมทอง
              </Header>
            </Form.Field>
            <Form.Field width={10}>
              {role == "A" || role == "S" ? (
                <Button
                  id="btnImport"
                  content="Import"
                  size="small"
                  onClick={() => this.setState({ import_open: true })}
                  floated="right"
                  icon="file alternate outline"
                  labelPosition="left"
                  type="button"
                  primary
                />
              ) : (
                  ""
                )}
              <Button
                id="btnCreateLease"
                content="สร้างบัญชีออมทอง (F1)"
                size="small"
                onClick={() => {
                  this.setState({ add: true });
                  // this.props.dispatch(lease_modal_add_open())
                }}
                floated="right"
                icon="plus"
                labelPosition="left"
                type="button"
                primary
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Form
          className="attached fluid"
          size="small"
          onSubmit={this.handleSubmit}
          autocomplete="off"
        >
          <Form.Group>
            <Form.Dropdown
              id="branch"
              width={4}
              value={this.state.branch_id}
              defaultValue={0}
              label="สาขา"
              search
              selection
              options={this.state.branches}
              onChange={(e, data) => {
                this.setState({ branch_id: data.value });
              }}
            />
            <Form.Field width={4}>
              <label>เลขที่บัญชี</label>
              <Input
                maxLength={10}
                id="inputLeaseNum"
                fluid
                onChange={(e, data) => {
                  var regex = /^([0-9-])*$/;
                  if (!regex.test(data.value)) {
                  } else {
                    this.handleInputChange(e, data)
                  }
                }}
                name="number"
                value={number}
                placeholder="เลขที่บัญชี"
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>ชื่อบัญชี</label>
              <Input
                maxLength={100}
                id="inputLeaseNum"
                fluid
                onChange={this.handleInputChange}
                name="customer"
                value={customer}
                placeholder="ชื่อบัญชี"
              />
            </Form.Field>
            <InputSearchCustomer
              label='ลูกค้า'
              width='5'
              placeholder='พิมพ์ ชื่อ หรือ รหัสลูกค้า'
              onChange={this.handleInputChange}
              name="customer_id"
              value={this.state.customer_id}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field width={4}>
              <label>เลขประจำตัวประชาชน</label>
              <Input
                maxLength={13}
                id="inputLeaseNum"
                fluid
                onChange={this.handleInputChange}
                name="citizen_id"
                value={citizen_id}
                placeholder="เลขประจำตัวประชาชน"
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>จากวันที่</label>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                autocomplete="off"
                selected={this.state.in_date_start}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.in_date_end < date) {
                    this.setState({
                      in_date_end: date,
                    })
                  }
                  this.setState({
                    in_date_start: date,
                  })
                }}
                name="in_date_start"
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>ถึงวันที่</label>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                autocomplete="off"
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.in_date_start > date) {
                    this.setState({
                      in_date_start: date,
                    })
                  }
                  this.setState({
                    in_date_end: date,
                  })
                }}
                name="in_date_end"
                selected={this.state.in_date_end}
              />
            </Form.Field>
            <Form.Field width={5}>
              <label><br /></label>
              <Button id='clear_search' floated='right' type='button' size='small' onClick={() => {
                // this.componentDidMount();
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
              <Button
                id="search"
                floated="right"
                type="button"
                size="small"
                onClick={() => {
                  this.clearFormSearch();
                  setTimeout(() => {
                    this.setState({ page: 1 });
                    this.handleSubmit();
                  }, 500);
                }}
              >
                {" "}
                <Icon name="search" /> วันนี้
              </Button>
              <Button
                id="search"
                floated="right"
                type="button"
                size="small"
                onClick={() => {
                  this.handleSubmit();
                  this.setState({ page: 1 });
                }}
              >
                {" "}
                <Icon name="search" /> ค้นหา
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>

        {this.state.add && (
          <OpenAccount
            onClose={() => {
              this.loaddata2(this.state.page);
              this.setState({ add: false });
            }}
            open={true}
          />
        )}
        {this.state.detail && (
          <AccountDetail
            items={this.state.itm}
            onClose={() => {
              this.loaddata2(this.state.page);
              this.setState({ detail: false });
            }}
            open={true}
          />
        )}
        <div className="relative">
          <div id="client_width">
            {/* <LeaseSearch onSubmit={this.handleSearch} /> */}
            <div className="relative">
              <Dimmer active={this.state.loader_active} inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
              <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={30}
                width={this.state.table_width}
                height={402}
              >
                <Column
                  header={<Cell></Cell>}
                  cell={
                    <OptionItemsCell
                      action={"edit"}
                      data={items}
                      field="number"
                      onClickMenu={this.handleClick}
                    />
                  }
                  width={70}
                />

                <Column
                  header={
                    <SortHeaderCell
                      onSortChange={this.onSortChange}
                      field="number"
                      sortDir={this.state.colSortDirs.number}
                    >
                      เลขที่บัญชี
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      id="leaseID"
                      data={items}
                      field="number"
                    />
                  }
                  width={120}
                />
                <Column
                  header={
                    <SortHeaderCell
                      className="text-center"
                      onSortChange={this.onSortChange}
                      field="branch"
                      sortDir={this.state.colSortDirs.branch}
                    >
                      สาขา
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="branch_name"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell>ชื่อบัญชี</Cell>}
                  header={
                    <SortHeaderCell
                      onSortChange={this.onSortChange}
                      field="name"
                      sortDir={this.state.colSortDirs.name}
                    >
                      ชื่อบัญชี
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      id="cusName"
                      data={items}
                      field="name"
                    />
                  }
                  width={120}
                />
                <Column
                  header={
                    <SortHeaderCell
                      className="text-left"
                      onSortChange={this.onSortChange}
                      field="citizen_id"
                      sortDir={this.state.colSortDirs.citizen_id}
                    >
                      เลขประจำตัวประชาชน
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top left"
                      width={180}
                      data={items}
                      field="citizen_id"
                      textAlign="text-left"
                    />
                  }
                  width={180}
                />
                <Column
                  header={
                    <SortHeaderCell
                      className="text-right"
                      onSortChange={this.onSortChange}
                      field="balance"
                      sortDir={this.state.colSortDirs.balance}
                    >
                      ยอดคงเหลือ
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="balance"
                      textAlign="text-right"
                    />
                  }
                  width={100}
                />
                {/* <Column
                  header={
                    <SortHeaderCell
                      className="text-center"
                      onSortChange={this.onSortChange}
                      field="status"
                      sortDir={this.state.colSortDirs.status}
                    >
                      สถานะ
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="status_display"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                /> */}
                <Column
                  header={
                    <SortHeaderCell
                      className="text-center"
                      onSortChange={this.onSortChange}
                      field="open_date"
                      sortDir={this.state.colSortDirs.open_date}
                    >
                      วันที่เปิดบัญชี
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="open_date"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                {/* <Column
                  header={
                    <SortHeaderCell
                      className="text-center"
                      onSortChange={this.onSortChange}
                      field="close_date"
                      sortDir={this.state.colSortDirs.close_date}
                    >
                      วันที่ปิดบัญชี
                    </SortHeaderCell>
                  }
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="close_date"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                /> */}
                <Column
                  header={<Cell>หมายเหตุ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="remark"
                    />
                  }
                  width={100}
                />
              </Table>
            </div>
          </div>
        </div>
        <br />
        <Form size="small">
          <Form.Group>
            <Form.Input
              id="amounttrans"
              label="จำนวน"
              placeholder=""
              className="text-right"
              width={2}
              value={Utility.numberFormat(this.state.counter)}
            />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && (
                <Pagination
                  floated="right"
                  size="tiny"
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />
              )}
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }

  hot_keys = {
    f1: {
      priority: 3,
      handler: (event) => {
        this.setState({ add: true });
        event.preventDefault();
        // this.props.dispatch(lease_modal_add_open());
      },
    },
    // 'f8': {
    //     priority: 3,
    //     handler: (event) => {
    //         event.preventDefault()
    //         this.setState({ open_print: true });

    //     }
    // }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    branches: state.branches,
    lease_modal: state.lease_modal,
  };
};

export default connect(mapStateToProps)(hotkeys(Saving));
