const default_state = {
  is_ready: false,
  is_login: false,
  staff: {},
  branch: {},
  user: {},
  role: '',
  branch_list: [],
}
const auth = (state = default_state, action) => {
    switch (action.type) {
      case 'SET_AUTH':
        return action.data
      case 'ACTIVATIONS_BRANCH':
          const _state = JSON.parse(JSON.stringify(state))
          _state.branch = action.data
          localStorage.setItem("branch", JSON.stringify(action.data));
          return _state
    default:
        return state
    }
  }
  
  export default auth  