/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Button, Modal, Input
} from 'semantic-ui-react';
import MsgInput from '../Error/MsgInput'
import Settings from '../../Settings';
import Utility from '../../Utility';


class CustomerPoint extends Component {

    constructor(props) {
        super(props)
        this.modal_open = false
        this.state = {
            action: 'add',
            modal_title: 'เพิ่มแต้ม ออมทอง',
            msg_error: {},
            amount: '',
            score: '',
        }


        this.handlerSubmitForm = this.handlerSubmitForm.bind(this)
        this.handlerCalculateScore = this.handlerCalculateScore.bind(this)
    }

    handlerCalculateScore(e, v) {
        v.value = v.value.toString().replace(/,/g, '')
        if (isNaN(Number(v.value))) {
        } else {
            if (v.value.length === 0) {
                v.value = ''
            }
            var amount = Number(v.value) * 0.05
            amount = amount <= 500 ? amount : 500
            var unit = amount * 0.1
            var score = this.setting_score * unit
            this.setState({
                score: Math.ceil(score),
                amount: v.value
            })
        }


    }

    componentDidMount() {
        let system_setting = JSON.parse(localStorage.getItem('system_setting'))
        system_setting = system_setting.filter(setting => setting.setting == 'SCOR')
        if (system_setting.length == 0) {
            Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
                for (let i in data) {
                    if (data[i].setting == 'SCOR') {
                        this.sys_setting_id = data[i].id
                        this.setting_score = data[i].value
                    }
                }
            });
        } else {
            this.sys_setting_id = system_setting[0].id
            this.setting_score = system_setting[0].value
        }
    }
    resetForm() {
        this.setState({
            amount: '',
            description: '',
        });
    }

    handlerSubmitForm(e) {
        e.preventDefault();
        var formData = {
            amount: this.state.amount,
            score: this.state.score,
            customer: this.props.customer_id
        };

        const url = Settings.baseUrl + "/saving/add_score/"
        this.setState({ button_class: 'loadding' })
        Utility.post(url, formData, (s, r, c) => {
            this.setState({ button_class: '' })
            if (s) {
                this.props.onSave()
            } else {
                this.setState({
                    msg_error: r
                })
            }
        })
    }

    handlerStateModal() {

        this.modal_open = this.props.open;
    }


    render() {

        if (this.props.action == 'add')
            this.handlerStateModal()

        let setting = []
        for (let i in this.state.setting) {
            if (this.state.setting[i].check)
                setting.push(this.state.setting[i])
        }
        return (
            <div>
                <Modal id='modalAddPoint' open={this.props.open} size='tiny' /*dimmer='blurring'*/>
                    <Button id='btnClosemodalAddPoint' circular icon='close' basic floated='right' name='' onClick={(e) => {
                        this.props.onClose(e)
                    }} />
                    <Modal.Header>{this.state.modal_title}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form size='small'>
                                <Form.Field error={this.state.msg_error.amount != null}>
                                    <label>ยอดถอน <MsgInput text={this.state.msg_error.amount} /></label>
                                    <Input id='inputWithdraw' onChange={this.handlerCalculateScore} value={this.state.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        // type='number' 
                                        maxLength={20}
                                    />
                                </Form.Field>
                                <Form.Field error={this.state.msg_error.score != null}>
                                    <label>คะแนน <MsgInput text={this.state.msg_error.score} /></label>
                                    <Input id='inputPoint' value={this.state.score.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} readOnly />
                                </Form.Field>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button id='btnSave' size='small' primary onClick={this.handlerSubmitForm}
                            className={this.state.button_class}>บันทึก</Button>
                        <Button id='btnCancel' size='small' onClick={(e) => { e.preventDefault(); this.props.onClose(); }}>ยกเลิก</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}
CustomerPoint.defaultProps = {
    action: 'addpoint'
};
export default CustomerPoint;
