/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Cell
  } from 'fixed-data-table';
  import {
    Icon
  } from 'semantic-ui-react';

class OptionItemsCell extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      const { rowIndex} = this.props;
      return (
        <Cell><center style={{padding: '4px'}}>
           {this.props.view==true?<a onClick={(e)=>{this.props.onClickMenu(e,{ action: 'view',positon: rowIndex })}}><Icon id={rowIndex+'_View'}  name='eye'  /></a>:''}
           {this.props.edit==null||this.props.edit==true?<a onClick={(e)=>{this.props.onClickMenu(e,{ action: 'edit',positon: rowIndex })}}><Icon id={rowIndex+'_Edit'}  name='edit'  /></a>:''}
          {this.props.delete==null||this.props.delete==true?<a onClick={(e)=>{this.props.onClickMenu(e,{ action: 'delete',positon: rowIndex })}} ><Icon id={rowIndex+'_Delete'}name='window close outline'  /></a>:''}
        </center></Cell>
      );
    }
  }

  export default OptionItemsCell;