/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
   Button, Modal, Table,Dimmer,Loader,Pagination,Form, FormField,Dropdown, FormDropdown, Label,Grid
} from 'semantic-ui-react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import QrcodePrintPreview from '../../../QrcodePrintPreview'

class BillQrcodeModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            loadder: true,
            items: [],
            print_qrcode: false,
            print_qrcode_items: [],
            page:1,
            totalPages:1,
            limit:10,
            row:10,
        }

        this.onClickPrintItem = this.onClickPrintItem.bind(this)
        this.onClickPrintAll = this.onClickPrintAll.bind(this)
    }

    componentDidMount(){
        this.loadQrcode(1,this.state.row)
    }

    onClickPrintAll(e){

        const {items,loadder} = this.state
        const print_qrcode_items = []

        items.map((item,i)=>{
            print_qrcode_items.push({
                qrcode: item.qrcode,
                text: item.stock_product.product_join.name,
                weight: item.stock_product.product_join.weight
            })
        })
        
        this.setState({
            print_qrcode: true,
            print_qrcode_items
        })
    }

    onClickPrintItem(e,{item}){

        const print_qrcode_items = []
        print_qrcode_items.push({
            qrcode: item.qrcode,
            text: item.stock_product.product_join.name,
            weight: item.stock_product.product_join.weight
        })
        this.setState({
            print_qrcode: true,
            print_qrcode_items
        })
    }

    async loadQrcode(page,limit){
        const url = Settings.baseUrl + `/invoices/${this.props.invoice_detail.id}/qrcode/?page=`+page+`&page_size=`+limit
        const req = await Utility.getAsync(url)
        this.setState({
            items: this.setFieldValue(req.data.results.new_product,req.data.results.invoice_item,req.data.results.product,req.data.results.stock_product,req.data.results.branch),
            loadder: false,
            totalPages: req.data.next == null && req.data.previous == null ? 0 : Math.ceil(req.data.count / limit),
        })
    }

    setFieldValue(items,invoice_items,products,stock_products,branchs){
        for (let i in items) {
            let item = items[i]
            const invoice_item = invoice_items.find(k => k.id === item.invoice_item)
            const stock_product = stock_products.find(k => k.id === item.stock_product)
            const product = products.find(k => k.id === invoice_item.product)
            const stock_product_product = products.find(k => k.id === stock_product.product)
            const stock_product_branch = branchs.find(k => k.id === stock_product.branch)

            item['product'] = product
            stock_product['product_join'] = stock_product_product
            stock_product['branch_join'] = stock_product_branch
            item['stock_product'] = stock_product
            item['invoice_item'] = invoice_item
        }
        return items
    }

    handlePaginationChange(e, { activePage }) {
        
        this.setState({ page: activePage, loadder: true})
        this.loadQrcode(activePage,this.state.row)
        // this.componentDidMount()
      }

    render() {
        var stateOptions = [ { key: '10', value: 10, text: '10' },{ key: '20', value: 20, text: '20' },{ key: '50', value: 50, text: '50' },{ key: '100', value: 100, text: '100' } ]
        const {items,loadder} = this.state
        return (<div>
            <Modal  open={true} onClose={this.close}  /*dimmer='blurring'*/>
            <Button
          id='btnCloseModalImEx'
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={this.props.onClose}/>
            <Modal.Header>QR Code</Modal.Header>
                <Modal.Content className='scrolling '>
                    <div className='segment'>
                    <Dimmer active={loadder} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Table size='small' >
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>รหัสสินค้า</Table.HeaderCell>
                            <Table.HeaderCell>ชื่อสินค้า</Table.HeaderCell>
                            <Table.HeaderCell>QR Code</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {items.map((item,i)=><Table.Row>
                                <Table.Cell>{item.stock_product.product_join.code}</Table.Cell>
                                <Table.Cell>{item.stock_product.product_join.name}</Table.Cell>
                                <Table.Cell>
                                    <p>{item.qrcode}</p></Table.Cell>
                                <Table.Cell textAlign='right'><Button item={item} primary icon='print' size='small' labelPosition='right' content='พิมพ์' onClick={this.onClickPrintItem} /></Table.Cell>
                            </Table.Row>)}
                        </Table.Body>
                    </Table>
                    
                    </div>
                    {this.state.print_qrcode && <QrcodePrintPreview items={this.state.print_qrcode_items} onClose={(e)=>this.setState({print_qrcode: false})} />}
                </Modal.Content>
                <Modal.Actions>
                <Form size='small'>
         
          <Form.Field width={16} >
          <b>แสดง </b>
                  <Dropdown
                    size='mini'
                    style={{ padding: '8px 13px', background: '#fff', border: 'solid 2px #D3D3D3', borderRadius: '5px' }}
                    floated='right'
                    value={this.state.row}
                    options={stateOptions}
                    onChange={(e, data) => {
                        this.setState({ row: data.value ,loadder: true});
                        this.loadQrcode(1,data.value)
                        this.setState({activePage:1})
                    }}
               />
               <b> แถว </b>
          {this.state.totalPages>1 && 
                <Pagination
                    floated='left'
                    size='mini'
                    activePage={this.state.page}
                    onPageChange={this.handlePaginationChange.bind(this)}
                    totalPages={this.state.totalPages}
                  />
                  
                  }  
            {/* <Form.Field width={6}> */}
            
            <Button floated='right' id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ยกเลิก</Button> 
            <Button  floated='right' primary icon='print' size='small' labelPosition='right' content='พิมพ์ทั้งหมด' onClick={this.onClickPrintAll} />
              
                
            </Form.Field>
          
        </Form>     
        </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default BillQrcodeModal;