/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Checkbox
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const data = this.props.data
        let kind_list = {}
        let kind_arr = []

        return (
        <Table>
            
        </Table>
        
        )
    }
}


class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                date_lt: moment(),
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
            },
            items: [],
            group: false,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([branches, staffs, product_types]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }

            this.product_types = values[2];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                product_types: product_types
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_date = url.searchParams.get("date_lt");
        if (temp_date !== null) {
            this.setState({
                search: {
                    date_lt: moment(temp_date, 'DD/MM/YYYY'),
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                date_lt: moment(),
                branch: this.props.auth.branch.id,
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date_lt = Utility.formatDate2(search.date_lt)

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/stock_borrow_product_report/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.stock_product),
                    product: this.setFieldValue(d.product),
                    category: this.setFieldValue(d.category),
                    kind: this.setFieldValue(d.kind),
                    branch: this.setFieldValue(d.branch),
                    stock_borrow: this.setFieldValue(d.stock_borrow)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            items.push(item)
        }
        return items
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let data = this.props.data

        let title = 'รายงานสต็อกสินค้ายืมคงเหลือ';
        let filename = 'sell_type_amount';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let amount = 0
        //let weight = 0
        let weight_b = 0
        let sell = 0
        let net = 0
        let category_list = {}
        let product_list = {}
        let kind_list = {}
        let n=0
        let n_borrow=0
        let weight = 0
        let weight_borrow = 0
        for (let i in this.state.items) {
            let item = this.state.items[i]
            const product = this.state.product.find(k => k.id === item.invoice_item.product)
            const category = this.state.category.find(k => k.id === product.category)
            
            if(product_list[product.id] == null){
                product_list[product.id] = {
                    product_code: product.code,
                    balance:0,
                    balance_total:0,
                    weight:0,
                    product_id:product.id,
                    category_id:category.id,
                    product_name:product.name,
                    product_kind:product.kind,
                    weight_real:product.weight
                }
            }
            product_list[product.id].balance += parseInt(item.after)
            // product_list[product.id].balance_total += parseInt(item.after)
            product_list[product.id].weight += parseFloat(item.invoice_item.weight)
        }

        let items_product = []
        for (let i in product_list) {
            items_product.push(product_list[i])
        }

        for(let j in this.state.stock_borrow) {
            let item = this.state.stock_borrow[j]
            const stock = items_product.find(k => k.product_id === item.stock_borrow_product.product)
            let stock_aa = 0
            if(stock.balance >= item.balance) {
                stock_aa = stock.balance - item.balance
            } 
            stock.balance = stock.balance - stock_aa
            stock.balance_total = item.balance
        }

        for(let i in items_product) {
            const item = items_product[i]
            
            n += parseInt(item.balance)
            n_borrow += parseInt(item.balance_total)
            weight += parseFloat(item.balance) * parseFloat(item.weight_real)
            weight_borrow += parseFloat(item.balance_total) * parseFloat(item.weight_real)
            const kind = this.state.kind.find(k => k.id === item.product_kind)
            
            if(kind_list[kind.id] == null){
                kind_list[kind.id] = {
                    kind_name : kind.name,
                    items : [],
                    category_id:item.category_id
                }
            }
            kind_list[kind.id].items.push(item)
        }

        let kinds = []
        for(let i in kind_list) {
            let balance_total = 0
            let weight_total = 0
            let balance_borrow_total = 0
            let weight_borrow_total = 0
            for(let j in kind_list[i].items) {
                balance_total += kind_list[i].items[j].balance
                balance_borrow_total += kind_list[i].items[j].balance_total
                weight_total += kind_list[i].items[j].weight_real * kind_list[i].items[j].balance
                weight_borrow_total += kind_list[i].items[j].weight_real * kind_list[i].items[j].balance_total
            }
            kind_list[i].items.push({is_sum:true,balance_total:balance_total,
                balance_borrow_total:balance_borrow_total,
                weight_total:weight_total,
                weight_borrow_total:weight_borrow_total})
            kinds.push(kind_list[i])
        }

        for(let i in kind_list) {
            const item = kind_list[i]
            const category = this.state.category.find(k => k.id === item.category_id)
            
            if(category_list[category.id] == null){
                category_list[category.id] = {
                    category_name : category.name,
                    items : [],
                }
            }
            category_list[category.id].items.push(item)
        }

        let items2 = []
        for (let i in category_list) {
            items2.push(category_list[i])
        }

        let sum_total = 0;
        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

      
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสต็อกสินค้ายืมคงเหลือ</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.date_lt}
                                    selected={this.state.search.date_lt}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'date_lt',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            {/* <Form.Field
                                control={Checkbox}
                                label='สรุปจำนวน'
                                // value='1'
                                checked={this.state.group}
                                onChange={(e, data) => {
                                    this.setState({ group: !this.state.group });
                                }}
                            /> */}
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='9'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='9'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='9'><center id='date'>ประจำวันที่ : {Utility.formatDate(this.state.search.date_lt)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2' >ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >รหัสสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น.น./ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >จำนวนสต็อกทองยืมคงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >จำนวนสต็อกทองยืมทั้งหมด</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น้ำหนักสต็อกทองยืมคงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น้ำหนักสต็อกทองยืมทั้งหมด</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >สาขา</Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Header>
                                    {items2.map((row,i)=>
                                    {
                                        let total_balance_category = 0
                                        let total_balance_borrow_category = 0
                                        let total_weight_category = 0
                                        let total_weight_borrow_category = 0
                                        return(<><Table.Row>
                                            <Table.Cell colSpan='21'><b>{row.category_name}</b></Table.Cell>
                                        </Table.Row>
                                        {row.items.map((row2,i2) => {
                                            let sum_balance = 0
                                            let sum_weight = 0
                                            return (
                                                <>
                                                <Table.Row>
                                                    <Table.Cell  colSpan='21' ><b>{row2.kind_name}</b></Table.Cell>
                                                </Table.Row>
                                                {row2.items.map((row3,i)=> {
                                                    
                                                    if(row3.is_sum == true) {
                                                        total_balance_category += row3.balance_total
                                                        total_balance_borrow_category += row3.balance_borrow_total
                                                        total_weight_category += row3.weight_total
                                                        total_weight_borrow_category += row3.weight_borrow_total
                                                    } 
                                                    
                                                    return (
                                                        <>
                                                        {row3.is_sum == true ? <Table.Row>
                                                            <Table.Cell  colSpan='3' ></Table.Cell>
                                                            <Table.Cell style={textRight}><b>รวม {row2.kind_name}</b></Table.Cell>
                                                        <Table.Cell style={textRight}><b>{Utility.numberFormat(row3.balance_total)}</b></Table.Cell>
                                                        <Table.Cell style={textRight}><b>{Utility.numberFormat(row3.balance_borrow_total)}</b></Table.Cell>
                                                        <Table.Cell style={textRight}><b>{Utility.weightFormat(row3.weight_total)}</b></Table.Cell>
                                                        <Table.Cell style={textRight}><b>{Utility.weightFormat(row3.weight_borrow_total)}</b></Table.Cell>
                                                        <Table.Cell ></Table.Cell>
                                                </Table.Row> : 
                                                <>
                                                
                                                <Table.Row>
                                                    <Table.Cell colSpan='2' >{row3.product_name}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{row3.product_code}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(row3.weight_real)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.numberFormat(row3.balance)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.numberFormat(row3.balance_total)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(row3.balance * row3.weight_real)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(row3.balance_total * row3.weight_real)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{branch_name}</Table.Cell>
                                                </Table.Row>
                                                
                                                </>
                                                }
                                                
                                                         
                                                        </>
                                                    )
                                                })}
                                                </>
                                            )
                                        })}
                                        <Table.Row>
                                                            <Table.Cell  colSpan='3' ></Table.Cell>
                                                            <Table.Cell style={textRight}><b>รวม {row.category_name}</b></Table.Cell>
                                                            {/* total_balance_borrow_category */}
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(total_balance_category)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(total_balance_borrow_category)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(total_weight_category)}</b></div></Table.Cell>
                                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(total_weight_borrow_category)}</b></div></Table.Cell>
                                                        <Table.Cell ></Table.Cell>
                                                </Table.Row>
                                        </>
                                        )
                                    }
                                    )}
                                    <Table.Row>
                                    <Table.Cell colSpan='3' style={textRight}></Table.Cell>
                                    <Table.Cell style={textRight}><b>รวมทั้งหมด</b></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(n)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(n_borrow)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_borrow)}</b></div></Table.Cell>
                                    <Table.Cell ></Table.Cell>
                                </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)