/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import {
    Popup,
} from 'semantic-ui-react'
import Utility from '../../../Utility';


class ItemsCell extends Component {
    render() {
        const { rowIndex, field, data, type, ...props } = this.props;

        let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field]) : data[rowIndex][field];
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }
        return (
            <Cell {...props} >
                <Popup
                    position={this.props.position}
                    content={this.props.type == 'f' ? Utility.weightFormat(v) : v}
                    style={Width300}
                    trigger={
                        <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign)}>{this.props.type == 'f' ? Utility.weightFormat(v) : v}</div>}
                />
            </Cell>
        );
    }
}


class TableCategory extends Component {
    render() {

        let items = this.props.items
        return (<Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={30}
            width={this.props.width}
            height={150}>
            <Column
                header={<Cell>%ทอง</Cell>}
                cell={
                    <ItemsCell position='top left' width={100} data={items} field="title" />
                }
                width={100}
                fixed={true}
            />
            <Column
                header={<Cell className='text-right'>น.น.ซื้อ(กรัม)</Cell>}
                cell={
                    <ItemsCell position='top right' width={140} data={items} field="weight" textAlign='text-right' type='f' />
                }
                width={140}
                fixed={true}
            />
            <Column
                header={<Cell className='text-right'>น.น.ซื้อ(บาท)</Cell>}
                cell={
                    <ItemsCell position='top right' width={140} data={items} field="weight_b" textAlign='text-right' type='f' />
                }
                width={140}
                fixed={true}
            />
        </Table>)
    }
}

export default TableCategory 