/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Popup,
} from 'semantic-ui-react';
import { Cell } from 'fixed-data-table';

class ItemsCell extends Component {
    render() {
        const { rowIndex, field, data, textAlign } = this.props;
        let width = (this.props.width * 0.9) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (<Cell onClick={(e) => {
        }} >
            <Popup
                position={this.props.position}
                content={data[rowIndex][field]}
                trigger={
                    <div style={ellipsisStyle} className={textAlign} >{data[rowIndex][field]}</div>}
            />

        </Cell>
        );
    }
}

export default ItemsCell