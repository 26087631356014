/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';
import {
    Form,
    Button,
    Popup,
    Dimmer,
    Loader,
    Icon
} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import IncreaseDecrease from './IncreaseDecrease'
import { connect } from 'react-redux'
import moment from 'moment';

function collect(props) {
    return { positon: props.positon };
}

class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { rowIndex, field, data, ...props } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (

            <Cell {...props}>
                <Popup
                    position={this.props.position}
                    content={data[rowIndex][field]}
                    trigger={
                        <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>{data[rowIndex][field]}</div>}
                />
            </Cell>
        );
    }
}

class ItemsCellIcon extends Component {
    render() {
        const { rowIndex, data } = this.props;
        var recordDate = new Date(data[rowIndex].record_date);
        var todaysDate = new Date();
        return (<Cell onClick={(e) => {
        }} >
            <center style={{ padding: '4px' }}>
                {
                    rowIndex == 0 && recordDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0) ?
                        <a onClick={(e) => { this.props.onClick(e, data[rowIndex]) }} >
                            <Icon name='window close outline' />
                        </a>
                        : ''
                }
            </center>
        </Cell>
        );
    }
}



class Leaseprinciple extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeItem: window.location.pathname,
            table_width: 600,
            table_hiegh: 150,
            lease_product: [],
            loading: false,
            setting: this.props.setting,
            lease_principle: [],
            increase_decrease_open: props.sub_action == 'add_delete'
        }

        this.handelSubmit = this.handelSubmit.bind(this)
        this.handelDeleteItem = this.handelDeleteItem.bind(this)

    }

    componentDidMount() {

        if (this.props.data.id) {
            let lease_principle = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.data.id + '/principle/?is_enabled=1');

            this.setState({
                loading: true
            })
            Promise.all([lease_principle]).then((values) => {
                this.setState({
                    lease_principle: this.setFieldValue(values[0]),
                    loading:false
                })
            });
        }

        setTimeout(() => {

            let elHeight = document.getElementById('content-body2')
            this.setState({ w_content: elHeight.clientWidth });
        }, 100)

    }

    setFieldValue(list) {

        let j = list.length;
        for (let i in list) {
            list[i].no = j--
            list[i].date = Utility.formatDate(list[i].record_date)
            if (list[i].kind == 'IC')
                list[i].increase = Utility.priceFormat(list[i].total)
            else
                list[i].decrease = Utility.priceFormat(list[i].total)
            list[i].balance_display = Utility.priceFormat(list[i].balance)

            if (list[i].ledger)
                list[i].staff_name = list[i].ledger.staff_name
        }

        return list
    }

    handelDeleteItem(e, item) {
        if(this.props.data.status == 3 || this.props.data.status == 4)
            return
        if (window.confirm('ยืนยันลบ!')) {
            Utility.delete(Settings.baseUrl + '/lease/' + this.props.data.id + '/principle/' + item.id + '/', (status, data, code) => {

                if (status) {
                    this.props.onSave();
                    this.componentDidMount();
                } else if (code == 403) {
                    alert('ไม่สามารถลบได้ สิทธิ์ superadmin/admin/manager เท่านั้นที่สามารถลบได้')
                }
            })
        }
    }

    handelSubmit() {
        this.setState({ increase_decrease_open: false })
        this.props.onSave();
        this.componentDidMount();
    }


    render() {

        const items = this.state.lease_principle
        return (<div id="content-body2">
            {this.state.increase_decrease_open && <IncreaseDecrease open={this.state.open} onClose={() => this.setState({ increase_decrease_open: false })} onSave={this.handelSubmit} lease={this.props.data} setting={this.state.setting} />}
            <Form>
                <Form.Group>
                    <Form.Field width={16}>
                        {(this.props.data.status < 3 && (moment().format("YYYY-MM-DD") > this.props.overdue_end_date && this.props.role_overdue_end_date))&&<span style={{color:'red'}}>สินค้าหลุดจำนำ เนื่องจากลูกค้าชำระดอกเบี้ยล่าช้าเกินกว่าวันที่กำหนด {this.props.data.overdue_end_date} วัน</span>}    
                        {this.props.data.id && <Button disabled={this.props.data.status > 2}  disabled={this.props.data.status > 2 || (moment().format("YYYY-MM-DD") > this.props.overdue_end_date && this.props.role_overdue_end_date)} floated='right' primary content='เพิ่ม/ลด' size='mini' type='button' onClick={() => this.setState({ increase_decrease_open: true,open:true })} />}
                    </Form.Field>
                </Form.Group>
            </Form>
            <div id="w_content"  className='relative'>
            <Dimmer active={this.state.loading} inverted>
                <Loader inverted content='Loading' />
            </Dimmer>
            <Table
                rowsCount={items.length}
                rowHeight={30}
                headerHeight={30}
                width={this.state.w_content}
                height={this.state.table_hiegh}>
                <Column
                    header={<Cell></Cell>}
                    cell={
                        <ItemsCellIcon data={items} onClick={this.handelDeleteItem} />
                    }
                    width={50}
                />
                <Column
                    header={<Cell className='text-center'>ครั้งที่</Cell>}
                    cell={
                        <ItemsCell position='top left' width={50} id='count' data={items} field="no" textAlign='text-center' />
                    }
                    width={50}
                />
                <Column
                    header={<Cell>วันที่</Cell>}
                    cell={
                        <ItemsCell position='top left' width={180} data={items} field="date" />
                    }
                    width={180}
                />
                <Column
                    header={<Cell className="text-right">เพิ่ม</Cell>}
                    cell={
                        <ItemsCell position='top right' width={180} data={items} field="increase" textAlign='text-right' />
                    }
                    width={180}
                />
                <Column
                    header={<Cell className="text-right">ลด</Cell>}
                    cell={
                        <ItemsCell position='top right' width={120} data={items} field="decrease" textAlign='text-right' />
                    }
                    width={120}
                />
                <Column
                    header={<Cell className="text-right">คงเหลือ</Cell>}
                    cell={
                        <ItemsCell position='top right' width={120} data={items} field="balance_display" textAlign='text-right' />
                    }
                    width={120}
                />
                <Column
                    header={<Cell>พนักงาน</Cell>}
                    cell={
                        <ItemsCell position='top left' width={120} data={items} field="staff_name" />
                    }
                    width={120}
                />
                <Column
                    header={<Cell>หมายเหตุ</Cell>}
                    cell={
                        <ItemsCell position='top left' width={120} data={items} field="description" />
                    }
                    width={120}
                />

            </Table>
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        lease_modal: state.lease_modal
    })
}

export default connect(mapStateToProps)(Leaseprinciple)
