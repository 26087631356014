/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Checkbox, Divider
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment()
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var ledger_category = Utility.getFetch(Settings.baseReportUrl + "/ledger_category/?is_enabled=1")
        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var bank_cards = Utility.getFetch(Settings.baseReportUrl + '/bank_cards/?is_enabled=1');
        Promise.all([branches, ledger_category, bank_cards]).then((values) => {
            this.branches = values[0];
            this.ledger_category = values[1]
            this.bank_cards = values[2]
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let qrstring = Utility.jsonToQueryString(search)
        this.setState({
            loading: true
        })

        var ledger = Utility.getFetch(Settings.baseReportUrl + '/ledger/?is_enabled=1&' + qrstring);
        var bills = Utility.getFetch(Settings.baseReportUrl + '/bills/?is_enabled=1&' + qrstring);
        Promise.all([ledger, bills]).then((values) => {
            this.bills = values[1]
            this.setState({
                loading: false,
                items: this.setFieldValue(values[0])
            });
        });
    }

    setFieldValue(_items) {
        let items = []
        _items.filter((l)=> ['CD','CC','CDQ','CCQ'].includes(l.payment) && l.kind === 'IN').forEach(item=>{
            let ledger_category = item.ledger_category
            item.object_title = ledger_category.title;
            item.date = Utility.formatDate(item.record_date);
            item.time = Utility.formatTime(item.record_date);
            if (item.card_bank_card) {
                item.card_type = `${item.card_bank_card.kind}`
                item.card_bank = `${item.card_bank_card.bank.name}`
                item.card_period = `${item.card_bank_card.period_display}`
            }
            items.push(item);
        })
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานการชำระเงินแบบเครดิต';
        let filename = 'ledger-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
            'word-break': 'break-word',
        }

        const textLeft = {
            'text-align': 'left',
            'word-break': 'break-word',
        }
        const textCenter = {
            'text-align': 'center',
            'word-break': 'break-word',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let total = 0
        let card_service = 0
        let card = 0
        let items = this.state.items
        let group_card_type = {}
        for (let i in items) {
            let item = (items[i]);
            total += parseFloat(item.card)
            card_service += parseFloat(item.card_service)
            card += parseFloat(item.card) + parseFloat(item.card_service)

            if (group_card_type[item.card_bank_card] == null) {
                group_card_type[item.card_bank_card] = {
                    items: [],
                    total: 0,
                    total_card_service: 0,
                    total_card: 0,
                }
            }
            group_card_type[item.card_bank_card].items.push(item)
            group_card_type[item.card_bank_card].total += parseFloat(item.card)
            group_card_type[item.card_bank_card].total_card_service += parseFloat(item.card_service)
            group_card_type[item.card_bank_card].total_card += parseFloat(item.card) + parseFloat(item.card_service)
        }
        let items2 = []
        for (let g in group_card_type)
            items2.push(group_card_type[g])


        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานการชำระเงินแบบเครดิต</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ : {Utility.formatDate(this.state.search.end_date)} </center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>

                                            <Table.HeaderCell style={textCenter}>เลขที่ใบสำคัญ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่อ้างอิง</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เวลา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ชนิดบัตรเครดิต</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ธนาคาร</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ประเภทชำระ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>%ค่าธรรมเนียม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดสุทธิ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าธรรมเนียม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เครดิต+ค่าธรรมเนียม</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {items2.map((row2, j) => <Table.Body key={j}>
                                        {row2.items.map((row, i) => <Table.Row key={i}>
                                            <Table.Cell style={textCenter}>{row.number}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.object_number}</Table.Cell>
                                            <Table.Cell style={textLeft} width={3}>{row.object_title}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.date}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.time}</Table.Cell>
                                            <Table.Cell style={textLeft} width={3}>{row.customer && row.customer.name}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.card_type}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.card_bank}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.card_period}</Table.Cell>
                                            <Table.Cell style={textRight}>{`${Utility.priceFormat(Math.round(parseFloat(row.card_service) / parseFloat(row.card) * 10000) / 100)}%`}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.card)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(row.card_service))}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(row.card) + parseFloat(row.card_service))}</Table.Cell>
                                        </Table.Row>)}

                                        <Table.Row >
                                            <Table.Cell colSpan='10' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(row2.total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(row2.total_card_service)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(row2.total_card)}</b></div></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>)}
                                    <Table.Footer>
                                        <Table.Row >
                                            <Table.Cell colSpan='10' style={textRight}><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='tota'>{Utility.priceFormat(total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='tota'>{Utility.priceFormat(card_service)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='card'>{Utility.priceFormat(card)}</b></div></Table.Cell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}


const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(PrintPreview)