/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { Table, Column, Cell } from 'fixed-data-table';
import OptionItemsCell from '../OptionItemsCell'
import { Icon, Popup } from 'semantic-ui-react';
import ItemsCell from '../ItemsCell'
// import { ContextMenuProvider } from 'react-contexify';

class IconItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props}>
        {}
        <Icon name={data[rowIndex].status_stock == 'N' ? 'warning circle' : 'check circle'} color={data[rowIndex].status_stock == 'N' ? 'yellow' : 'green'} />
      </Cell>
    );
  }
}

// class ItemsCell extends Component {
//   constructor(props) {
//     super(props);
//   }
//   render() {
//     const { rowIndex, field, className, data, ...props } = this.props;
//     let width = (this.props.width - 10) + 'px'
//     var ellipsisStyle = {
//       whiteSpace: 'nowrap',
//       width: width,
//       overflow: 'hidden',
//       textOverflow: 'ellipsis',
//     }
//     const Width300 = {
//       'word-break': 'break-word'
//     }
//     const cellStyle = {
//       'cursor': 'auto'
//     }
//     return (
//       <Cell {...props} style={cellStyle} className={(data[rowIndex].is_void ? 'cell_red cell-time-click' : 'cell-time-click') + ' ' + (className ? className : '')} title={data[rowIndex].is_void ? 'ถูกยกเลิก โดย' + data[rowIndex].canceler_name : ''}>
//         <ContextMenuProvider
//           data={rowIndex}
//           id="menu_id"
//           className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}
//         >
//           <Popup
//             position={this.props.position}
//             content={
//               data[rowIndex][field]
//             }
//             style={Width300}
//             trigger={
//               <div style={ellipsisStyle}>{data[rowIndex][field]}</div>
//             }
//           />
//         </ContextMenuProvider>
//       </Cell>
//     );
//   }
// }

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class BillTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      table_width: 100
    }
  }

  componentDidMount() {

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    const items = this.props.items

    return (
      <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={387}>
          <Column
            width={40}
            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell delete={false} onClickMenu={this.props.onActionItemCell} />}
          />
          <Column

            header={<Cell></Cell>}
            cell={
              <IconItemsCell data={items} field="status_stock_title" />
            }
            width={30}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='status_stock'
              sortDir={this.props.colSortDirs.status_stock}>
              สถานะปรับปรุงสต็อก
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} data={items} field="status_stock_title" />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              className='text-center'
              onSortChange={this.props.onSortChange}
              field='number'
              sortDir={this.props.colSortDirs.number}>
              เลขที่ Lot
            </SortHeaderCell>}
            cell={
              <ItemsCell width={150} data={items} field="number" textAlign='text-center'/>
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              className='text-center'
              onSortChange={this.props.onSortChange}
              field='lot_date'
              sortDir={this.props.colSortDirs.lot_date}>
              วันที่สร้างรายการ
            </SortHeaderCell>}
            cell={
              <ItemsCell width={120} data={items} field="lot_date" textAlign='text-center'/>
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='amount'
              sortDir={this.props.colSortDirs.amount}>
              จำนวนรายการ
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={130} data={items} field="amount" textAlign='text-right' />
            }
            width={130}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight'
              sortDir={this.props.colSortDirs.weight}>
              น้ำหนักทองเก่ารวม
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={140} data={items} field="weight" textAlign='text-right' />
            }
            width={140}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight_real'
              sortDir={this.props.colSortDirs.weight_real}>
              น้ำหนักชั่งห่อรวม
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={140} data={items} field="weight_real" textAlign='text-right' />
            }
            width={140}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight_out'
              sortDir={this.props.colSortDirs.weight_out}>
              น้ำหนักชั่งส่งหลอม
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={140} data={items} field="weight_out" textAlign='text-right' />
            }
            width={140}
          />
          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={250} data={items} field="description" />
            }
            width={250}
          />
        </Table></div>
    )
  }

}

export default BillTable

