/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import moment from 'moment';
import { connect } from 'react-redux'

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left',
        }

        return (<Table.Row >
            <Table.Cell style={textLeft}>{this.props.item.product.kind.name}</Table.Cell>
            <Table.Cell style={textLeft}>{this.props.item.product.name}</Table.Cell>
            <Table.Cell style={textRight}>{Utility.numberFormat(this.props.item.amount)}</Table.Cell>
            <Table.Cell style={textRight}>{Utility.weightFormat(this.props.item.product.weight * this.props.item.amount)}</Table.Cell>
            <Table.Cell style={textRight}>{Utility.priceFormat(this.props.item.cost)}</Table.Cell>
            <Table.Cell style={textRight}>{Utility.priceFormat(this.props.item.cost * this.props.item.amount)}</Table.Cell>
        </Table.Row>)
    }
}

class PrintPreview extends Component {
    constructor(props) {
        super(props);



        this.state = {
            search: {
                date_lt: moment(),
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var categories = Utility.getFetch(Settings.baseReportUrl + '/categories/?is_enabled=1');
        Promise.all([branches, staffs, categories]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }

            this.categories = values[2];
            let categories = []
            for (let i in this.categories) {
                categories.push({
                    value: this.categories[i].id,
                    key: this.categories[i].id,
                    text: this.categories[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                categories: categories
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                date_lt: moment(),
                branch: this.props.auth.branch.id,
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date_lt = Utility.formatDate2(search.date_lt)

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/stock_product/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let title = 'รายงานปริมาณสินค้าในสต็อก';
        let filename = 'stock_volume';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let category = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (category[item.product.category.id] == null) {

                category[item.product.category.id] = {
                    items: [],
                    category: item.product.category
                }
            }
            category[item.product.category.id].items.push(item)
        }

        let items = []
        for (let i in category) {
            items.push(category[i])
        }
        let amount_all = 0
        let sum_w_all = 0
        let cost_all = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานปริมาณสินค้าในสต็อก</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>

                            <Form.Field>
                                <label>%ทอง</label>
                                <Dropdown placeholder='%ทอง' search selection options={this.state.categories} value={this.state.search.category} name='category' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center id='date'>วันที่ : {Utility.formatDate(moment())}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>เปอร์เซ็นต์ทอง</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.รวม(กรัม)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุน(บาทละ/ชิ้นละ)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>คิดเป็นเงิน</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {items.map((row, i) => {

                                        let kinds = {}
                                        for (let i in row.items) {
                                            let item_ = row.items[i]
                                            if (kinds[item_.product.kind.id] == null) {
                                                kinds[item_.product.kind.id] = {
                                                    title: item_.product.kind.name,
                                                    items: []
                                                }
                                            }
                                            kinds[item_.product.kind.id].items.push(item_)
                                        }
                                        let items_kinds = []
                                        for (let i in kinds) {
                                            for (let j in kinds[i].items)
                                                items_kinds.push(kinds[i].items[j])
                                        }
                                        let amount_kind = 0
                                        let sum_w_kind = 0
                                        let cost_kind = 0
                                        return (<Table.Body>
                                            <Table.Row style={divStyle}>
                                                <Table.Cell style={textLeft} colSpan='6'><b>{row.category.name}</b></Table.Cell>

                                            </Table.Row>
                                            {items_kinds.map((row2, i) => {
                                                amount_kind += row2.amount
                                                sum_w_kind += (row2.product.weight * row2.amount)
                                                cost_kind += (row2.cost * row2.amount)

                                                amount_all += row2.amount
                                                sum_w_all += (row2.product.weight * row2.amount)
                                                cost_all += (row2.cost * row2.amount)
                                                return (<Table.Row >
                                                    <Table.Cell style={textLeft}>{row2.product.kind.name}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row2.product.name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.numberFormat(row2.amount)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(row2.product.weight * row2.amount)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row2.cost)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row2.cost * row2.amount)}</Table.Cell>
                                                </Table.Row>)
                                            })}
                                            <Table.Row >
                                                <Table.Cell style={textRight} colSpan='2'><b><u>{'รวม ' + row.category.name}</u></b></Table.Cell>
                                                <Table.Cell style={textRight}><b><u>{Utility.numberFormat(amount_kind)}</u></b></Table.Cell>
                                                <Table.Cell style={textRight}><b><u>{Utility.weightFormat(sum_w_kind)}</u></b></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}><b><u>{Utility.priceFormat(cost_kind)}</u></b></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell style={textRight} colSpan='2'><b><u>{'รวมทั้งหมด'}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.numberFormat(amount_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.weightFormat(sum_w_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.priceFormat(cost_all)}</u></b></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)