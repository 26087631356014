/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment, Form, Checkbox,Message
} from 'semantic-ui-react';

class CalculateDay extends Component {

    constructor(props) {
        super(props);
        this.handelChange = this.handelChange.bind(this)
        
    }

    handelChange(e,v){
        this.props.onChange(e,v);
    }

    render() {
        return (
            <div>
                <Message attached size='mini' >เศษของวันคิดเป็น</Message>
            <Segment attached className="botton">
                <Form.Group widths='inline'>
                    <Form.Field width={8} id='radio1'>
                        <Checkbox disabled width={8} radio label='คิดเป็นรายวัน' checked={this.props.value == 1} value='1' onChange={this.handelChange} />
                    </Form.Field>
                    <Form.Field width={8} id='radio2'>
                        <Checkbox disabled width={8} radio label='1/4 เดือน(1-7)' checked={this.props.value == 2} value='2' onChange={this.handelChange} />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='inline'>
                    <Form.Field width={8} id='radio3'>
                        <Checkbox disabled width={8} radio label='1/2 เดือน(8-15)' checked={this.props.value == 3} value='3' onChange={this.handelChange} />
                    </Form.Field>
                    <Form.Field width={8} id='radio4'>
                        <Checkbox disabled width={8} radio label='3/4 เดือน(16-22)' checked={this.props.value == 4} value='4' onChange={this.handelChange} />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='inline'>
                    <Form.Field width={8} id='radio5'>
                        <Checkbox disabled width={8} radio label='1 เดือน (23-31)' checked={this.props.value == 5} value='5' onChange={this.handelChange} />
                    </Form.Field>
                    <Form.Field width={8} id='radio6'>
                        <Checkbox disabled width={8} radio label='ไม่คิด' checked={this.props.value == 6} value='6' onChange={this.handelChange} />
                    </Form.Field>
                </Form.Group>
            </Segment>
            </div>
        )
    }
}

export default CalculateDay;