/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';

import {
    Form, Button, Modal, Loader, Dimmer, Pagination,
    Popup,Icon,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

class ItemsCell extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { rowIndex, field, data, ...props } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }
        let v = (field === 'amount_in' || field === 'amount_out') && data[rowIndex][field] == undefined ? 0 : data[rowIndex][field]
        if((field === 'amount_in' || field === 'amount_out') && data[rowIndex][field] == undefined){
        }
        

        return (
            <Cell {...props} >
                <Popup
                    position={this.props.position}
                    content={(field === 'before' || field === 'amount_in' || field === 'amount_out' || field === 'after' || field === 'before_w' || field === 'amount_in_w' || field === 'amount_out_w' || field === 'after_w') ?
                        (v === undefined) ? v
                            : v.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : v
                    }
                    style={Width300}
                    trigger=
                    {(field === 'before' || field === 'amount_in' || field === 'amount_out' || field === 'after' || field === 'before_w' || field === 'amount_in_w' || field === 'amount_out_w' || field === 'after_w') ?
                        (v === undefined) ?
                            <div style={ellipsisStyle} className={this.props.textAlign}><span className={this.props.color}>{v}</span></div>
                            : <div style={ellipsisStyle} className={this.props.textAlign}><span className={this.props.color}>{v.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></div>
                        : <div style={ellipsisStyle} className={this.props.textAlign}><span className={this.props.color}>{v}</span></div>
                    }
                />
            </Cell>
        );
    }
}


class ReportDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            item: [],
            start_date: moment().add('-60', 'days'),
            end_date: moment(),
            page: 1,
            totalPages: 1,
        }

        this.submitSearch = this.submitSearch.bind(this)
    }

    submitSearch(e) {
        e.preventDefault()
        this.componentDidMount()
    }

    componentDidMount() {
        this.searchProduct(1)
    }

    searchProduct(page) {
        this.setState({
            loader_active: true
        });
        let q = Utility.jsonToQueryString({
            start_date: Utility.formatDate2(this.state.start_date),
            end_date: Utility.formatDate2(this.state.end_date)
        })
        Utility.get(Settings.baseUrl + '/stock_product/' + this.props.id + '/items?' + q + '&page=' + page, (status, data) => {

            if (status == true) {
                this.setState({
                    data: this.setValue2(data.results),
                    loader_active: false,
                    totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
                    page: page,
                });
            }
        });
    }

    setValue2(data) {

        // group
        const items = {}
        for (let i = 0; i < data.length; i++) {
            const item = data[i]
            data[i].record_date = Utility.formatDate(data[i].record_date)
            data[i].branch_name = data[i].branch.name
            data[i].product_code = data[i].product.code
            data[i].product_name = data[i].product.name
            data[i].product_kind_name = data[i].product.kind.name

            if (data[i].kind == 'I' || data[i].kind == 'V' || data[i].kind == 'TI' || data[i].kind == 'IO' || data[i].kind == 'VT') {
                data[i].amount_in = data[i].amount
                data[i].amount_in_w = (parseFloat(data[i].product.weight) * parseInt(data[i].amount))
            } else {
                data[i].amount_out_w = (parseFloat(data[i].product.weight) * parseInt(data[i].amount))
                data[i].amount_out = data[i].amount
            }
            const key = item.object_number + item.stock + item.new_product.invoice_item.id + data[i].kind
            if (items[key]) {
                if (data[i].kind == 'I' || data[i].kind == 'V' || data[i].kind == 'TI' || data[i].kind == 'IO' || data[i].kind == 'VT') {
                    items[key].amount_in += data[i].amount_in
                    items[key].amount_in_w += data[i].amount_in_w
                } else {
                    items[key].amount_out_w += data[i].amount_out_w
                    items[key].amount_out += data[i].amount_out
                }
                items[key].amount += data[i].amount
                items[key].before = data[i].before
                items[key].before_w = (parseFloat(data[i].product.weight) * parseInt(data[i].before))
            } else {

                data[i].before_w = (parseFloat(data[i].product.weight) * parseInt(data[i].before))
                data[i].after_w = (parseFloat(data[i].product.weight) * parseInt(data[i].after))
                items[key] = JSON.parse(JSON.stringify(data[i]))
            }
        }

        const _items = []
        for (let k in items) {
            items[k].after_w = Utility.weightFormat(items[k].after_w)
            items[k].before_w = Utility.weightFormat(items[k].before_w)
            items[k].amount_out_w = Utility.weightFormat(items[k].amount_out_w)
            items[k].amount_in_w = Utility.weightFormat(items[k].amount_in_w)
            _items.push(items[k])
        }

        return _items;
    }

    componentWillMount() {
        setTimeout(() => {
            window.addEventListener('resize', this.updateDimensions);
            this.updateDimensions()
        }, 200)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    
      updateDimensions = () => {
        let elHeight = document.getElementById('table_width2')
        this.setState({ table_width: elHeight.clientWidth });
      };

    handlePaginationChange(e, { activePage }) {

        this.setState({ page: activePage, loadder: true })
        this.searchProduct(activePage)
    }

    render() {

        const data = this.state.data;
        return (

            <Modal size='large' open={this.props.open} /*dimmer='blurring'*/>

                <Button id='btnClose' circular icon='close' title='ปิด' basic floated='right' name='' onClick={(e) => {
                    e.preventDefault();
                    this.props.onClose();
                }} />
                <Modal.Header>สต็อกการ์ดทอง - {this.props.name}</Modal.Header>

                <Modal.Content className=''>
                    <Dimmer active={this.state.loader_active} inverted>
                        <Loader />
                    </Dimmer>
                    <Modal.Description>
                        <Form className='fluid ' size='small' onSubmit={this.submitSearch}>
                            <Form.Group>
                                <Form.Field >
                                    <label>จากวันที่</label>
                                    <DatePicker
                                        id='dateStart'
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.start_date}
                                        selected={this.state.start_date}
                                        onChange={(date) => {
                                            date = date?date:moment()
                                            if(this.state.end_date<date){
                                                this.setState({ end_date: date });
                                            }
                                            this.setState({ start_date: date });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>ถึงวันที่</label>
                                    <DatePicker
                                        id='dateEnd'
                                        dateFormat="DD/MM/YYYY"
                                        minDate={this.state.start_date}
                                        value={this.state.end_date}
                                        selected={this.state.end_date}
                                        onChange={(date) => {
                                            date = date?date:moment()
                                            this.setState({ end_date: date });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field width={5}>
                                    <br />
                                    <Button id='btnSearch' ><Icon name='search' />ค้นหา</Button>
                                </Form.Field>
                            </Form.Group>

                        </Form>
                        <div id="table_width2">
                            <Table
                                rowsCount={data.length}
                                rowHeight={35}
                                headerHeight={30}
                                width={this.state.table_width}
                                height={450} >
                                <Column
                                    header={<Cell>วันที่</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={80} data={data} field="record_date" />
                                    }
                                    width={80}
                                />
                                <Column
                                    header={<Cell>สาขา</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={150} data={data} field="branch_name" />
                                    }
                                    width={150}
                                />
                                <Column
                                    header={<Cell>เลขที่บิลอ้างอิง</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={150} id='billID' data={data} field="object_number" />
                                    }
                                    width={150}
                                />
                                <Column
                                    header={<Cell>ประเภท</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={data} field="kind_display" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>รหัสสินค้า</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={data} field="product_code" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>ชื่อสินค้า</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={190} data={data} field="product_name" />
                                    }
                                    width={190}
                                />
                                <Column
                                    header={<Cell className='text-right'>จ.น. เริ่มต้น</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={120} data={data} field="before" textAlign='text-right' color='pink' />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell className='text-right'>จ.น. เข้า</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={120} data={data} field="amount_in" textAlign='text-right' color='blue' />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell className='text-right'>จ.น. ออก</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={120} data={data} field="amount_out" textAlign='text-right' color='red' />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell className='text-right'>จ.น. คงเหลือ</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={120} data={data} field="after" textAlign='text-right' color='green' />
                                    }
                                    width={120}
                                />

                                <Column
                                    header={<Cell className='text-right'>น.น. เริ่มต้น</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={150} data={data} field="before_w" textAlign='text-right' color='pink' />
                                    }
                                    width={150}
                                />
                                <Column
                                    header={<Cell className='text-right'>น.น. เข้า</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={150} data={data} field="amount_in_w" textAlign='text-right' color='blue' />
                                    }
                                    width={150}
                                />
                                <Column
                                    header={<Cell className='text-right'>น.น. ออก</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={150} data={data} field="amount_out_w" textAlign='text-right' color='red' />
                                    }
                                    width={150}
                                />
                                <Column
                                    header={<Cell className='text-right'>น.น. คงเหลือ</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={150} data={data} field="after_w" textAlign='text-right' color='pink' />
                                    }
                                    width={150}
                                />
                            </Table></div>
                    </Modal.Description>

                </Modal.Content>
                {this.state.totalPages > 1 &&
                    <Pagination
                        floated='right'
                        size='tiny'
                        activePage={this.state.page}
                        onPageChange={this.handlePaginationChange.bind(this)}
                        totalPages={this.state.totalPages}
                    />

                }
            </Modal>


        );
    }
}


export default ReportDetail;