const default_state = {
  is_ready: false,
  list: [],
  filter: {},
  count: 0,
  totalPages: 0,
  activePage: 1,
  income: 0,
  expenses: 0,
}
const ledger_list = (state = default_state, action) => {
  switch (action.type) {
    case 'SET_LEDGER_LIST':
      return {
        ...state,
        is_ready: true,
        list: action.list.results ? action.list.results : [],
        count: action.list.count ? action.list.count : 0,
        totalPages: action.list.results ? action.list.next == null && action.list.previous == null ? 0 : Math.ceil(action.list.count / 10) : 0,
        activePage: action.activePage,
        income: action.income,
        expenses: action.expenses,
      }
    case 'LOAD_LEDGER_LIST':
      return {
        ...state,
        is_ready: false,
      }
    default:
      return state
  }
}

export default ledger_list