/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
   Button, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';

class ProductDetailPrintPreview extends Component {
    render() {
        let filename = 'invoice-out-' + this.props.invoice_detail.number;
        let title = 'บิลโอนทองเก่าเข้าสต็อกทองใหม่';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }
        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let vendor_name = ''; 
        let vendor = Utility.getObjectByValue(this.props.vendors,this.props.invoice_detail.vendor)
        
        if(vendor){
            vendor_name = vendor.text;
        }
        return (<div>
            <Modal  open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
            <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>
                
                    <div id='view-print'>
                    <div id='paperA4-portrait'>
                        <Table basic id='table-to-xls' style={divStyle} >
                            <Table.Header>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='15' style={textCenter}>{title}</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='15' style={textCenter}>สาขา: {this.props.branch_name}</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='15' style={textCenter}>เลขที่บิล: {this.props.invoice_detail.number}</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='4' style={textRight}>สถานะปรับปรุงสต๊อก:</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4' style={textLeft}>{Settings.status_stock[this.props.status_stock]}</Table.HeaderCell>

                                    <Table.HeaderCell colSpan='2' style={textRight}>วันที่:</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='2' style={textLeft}>{Utility.formatDate(this.props.invoice_detail.invoice_date)}</Table.HeaderCell>
                                    <Table.HeaderCell style={textLeft}></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>รหัสสินค้า</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>ประเภท</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>กลุ่มสินค้า</Table.HeaderCell>
                                    <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>น้ำหนัก/ชิ้น</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>น้ำหนักรวม</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>ค่าแรง/ชิ้น</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>ค่าแรงรวม</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>ต้นทุน/ชิ้น</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>ต้นทุนรวม</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>ราคาขายต่อชิ้น</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.props.items.map((row, i) => <Table.Row key={i}>
                                    <Table.Cell style={textCenter}>{i + 1}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.product_code}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.kind_title}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.category_title}</Table.Cell>
                                    <Table.Cell style={textLeft}>{row.product_name}</Table.Cell>
                                    <Table.Cell style={textRight}>{Utility.numberFormat(row.amount)}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.weight.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.weight_total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.fee.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Cell>
                                    <Table.Cell style={textRight}>{Utility.priceFormat(Utility.removeCommas(row.fee)*Utility.removeCommas(row.amount))}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.cost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Cell>
                                    <Table.Cell style={textRight}>{(Utility.priceFormat(parseFloat(Utility.removeCommas(row.cost)*Utility.removeCommas(row.amount))+(parseFloat(Utility.removeCommas(row.fee)*Utility.removeCommas(row.amount))))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.product.price_tag.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Cell>
                                </Table.Row>)}

                                <Table.Row >
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell><b>ยอดรวม</b></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(this.props.product_total)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{this.props.weight_total}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{this.props.fee_total}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{this.props.cost_total}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="ui primary button small"
                        table="table-to-xls"
                        filename={filename}
                        sheet={title}
                        buttonText="Download as XLS" >
                    </ReactHTMLTableToExcel>

                    <Button primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(()=>{
                            mywindow.close();
                        },2000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default ProductDetailPrintPreview;