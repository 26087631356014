/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
    Form, Segment, Header,
    Button, Loader,
    Dimmer, Dropdown, Input, Modal, Grid, Table, Icon,
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TableProduct from './TableProduct';
import TableCategory from './TableCategory';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_tab1: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            total_balance_begining: 0,
            total_pay_cash: 0,
            total_pay_check: 0,
            total_pay_transfer: 0,
            total_pay_lease: 0,
            total_receive_cash: 0,
            total_receive_transfer: 0,
            total_receive_card: 0,
            total_receive_interest_cash: 0,
            total_receive_interest_transfer: 0,
            total_receive_interest_card: 0,
            total_receive_interest_check: 0,
            total_receive_interest2_cash: 0,
            total_receive_interest2_transfer: 0,
            total_receive_interest2_card: 0,
            total_receive_interest2_check: 0,
            weight_lease_product: 0,
            n_lease: 0,
            total_redeem: 0,
            total_interest: 0,
            daily_ledger_cash: 0,
            amount_lease_product: 0,
            items_category: [],
            items_products: [],
            total_check_in: 0,
            is_search: false,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }


            this.setState({
                loader_active: false,
                branches: branches,
                table_width: window.innerWidth
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            total_balance_begining: 0,
            total_pay_cash: 0,
            total_pay_check: 0,
            total_pay_transfer: 0,
            total_pay_lease: 0,
            total_receive_cash: 0,
            total_receive_card: 0,
            total_receive_transfer: 0,
            total_receive_interest_cash: 0,
            total_receive_interest_transfer: 0,
            total_receive_interest_card: 0,
            total_receive_interest_check: 0,
            total_receive_interest2_cash: 0,
            total_receive_interest2_transfer: 0,
            total_receive_interest2_card: 0,
            total_receive_interest2_check: 0,
            weight_lease_product: 0,
            n_lease: 0,
            total_redeem: 0,
            total_interest: 0,
            items_category: [],
            items_products: [],

            total_receive_interest_redeem: 0,
            total_buy: 0,
            total_sell_cash: 0,
            total_sell_transfer: 0,
            total_sell_card: 0,
            total_sell_check: 0,
            total_sell: 0,
            total_expenses: 0,
            total_income: 0,
            total_receipt: 0,
            total_expenditure: 0,
            lease_delete: 0,
            lease_delete_credit: 0,
            lease_add: 0,
            total_check: 0,
            total_transfer: 0,
            total_card: 0,
            total_card_service: 0,
            total_cash: 0,
            total_cash_ex: 0,
            total_check_ex: 0,

            weight_se: 0,
            weight_bu: 0,
            items_category: [],
            items_products: [],
            total_weight_se: 0,
            total_weight_bu: 0,
            labor_cost: 0,
            gold_cost: 0,

            n_lease: 0,
            total_lease: 0,
            total_redeem: 0,
            total_pay_lease: 0,

            amount_lease_product: 0,
            weight_lease_product: 0,

            stock_weight: 0,

            stock_category_weight: 0,

            amount_lease: 0,
            amount_redeem: 0,
            daily_ledger_cash: 0,
            is_search: false,
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let date_before = new Date(search.start_date)
        date_before.setDate(date_before.getDate() - 1)
        date_before = Utility.formatDate2(date_before)

        let fromData = Utility.jsonToQueryString(search)

        let lease = 0
        if (search.lease != null)
            lease = search.lease
        let url = Settings.baseReportUrl + "/ledger/?" + fromData
        let url2 = Settings.baseReportUrl + "/daily_ledger_balance/?date=" + date_before + "&branch=" + this.state.search.branch
        Utility.get(url2, (s, d) => {
            if (s == true) {
                let daily_ledger_cash = 0
                if (d.length != 0) {
                    daily_ledger_cash = d[0].cash
                }
                this.setState({
                    daily_ledger_cash: daily_ledger_cash
                })

            }
        })


        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {

                let total_balance_begining = 0 // เงินต้น
                let total_pay_cash = 0
                let total_pay_check = 0
                let total_pay_transfer = 0
                let total_receive_cash = 0
                let total_receive_card = 0
                let total_receive_transfer = 0
                let total_receive_interest_cash = 0
                let total_receive_interest_transfer = 0
                let total_receive_interest_card = 0
                let total_receive_interest_check = 0
                let total_receive_interest2_cash = 0
                let total_receive_interest2_transfer = 0
                let total_receive_interest2_card = 0
                let total_receive_interest2_check = 0
                let total_interest = 0
                let total_receive_interest_redeem = 0
                let total_sell_cash = 0
                let total_sell_card = 0
                let total_sell_transfer = 0
                let total_sell_check = 0
                let total_buy = 0
                let total_sell = 0
                let total_expenses = 0
                let total_income = 0
                let total_receipt = 0
                let total_expenditure = 0
                let total_cash = 0;
                let total_cash_ex = 0;
                let total_check_ex = 0;
                let lease_delete = 0
                let lease_delete_transfer = 0
                let lease_delete_credit = 0
                let lease_add = 0
                let total_transfer = 0;
                let total_card = 0
                let total_card_service = 0

                for (let i in d) {
                    let item = d[i]
                    if (item.payment === 'TF') {
                        continue
                    }
                    let total = Number(item.total)
                    let cash = Number(item.cash)
                    let card = Number(item.card)
                    let card_service = Number(item.card_service)
                    let transfer = Number(item.transfer)

                    if (item.kind == 'EX') {
                        total_expenses += total
                        total_cash_ex += cash
                    }
                    if (item.kind == 'IN') {
                        total_income += total
                        total_card += card
                        total_card_service += card_service
                        total_cash += cash
                        total_transfer += transfer
                        total_receipt += total
                    } else {
                        total_expenditure += total
                    }

                    if (item.ledger_category.id == 12) {
                        total_balance_begining += total
                    }

                    if (item.ledger_category.id > 12 && item.kind == 'EX') {
                        total_pay_cash += cash
                    } else if (item.ledger_category.id > 12 && item.kind == 'IN') {
                        total_receive_cash += cash
                        total_receive_transfer += transfer
                        total_receive_card += card
                    } else if (item.ledger_category.id == 4) {
                        //if(item.status!=3)
                        //#total_pay_lease += Number(item.total)
                    } else if (item.ledger_category.id == 7) {// ต่อดอก
                        total_receive_interest_cash += cash
                        total_receive_interest_card += card
                        total_receive_interest_transfer += transfer
                    } else if (item.ledger_category.id == 10) {//  เงินต้น+ดอกเบี้ยไถ่คืน
                        total_interest += (total - card_service)
                        total_receive_interest_redeem += ((total - card_service) - Number(item.object_lease.amount))
                        total_receive_interest2_cash += cash
                        total_receive_interest2_card += card
                        total_receive_interest2_transfer += transfer
                    } else if (item.ledger_category.id == 2) {
                        total_buy += total
                    } else if (item.ledger_category.id == 1 || item.ledger_category.id == 3) {
                        total_sell += (total - card_service)
                        total_sell_cash += cash
                        total_sell_card += card
                        total_sell_transfer += transfer
                    } else if (item.ledger_category.id == 9) {
                        lease_delete += cash
                        lease_delete_credit += card
                        lease_delete_transfer += transfer
                    } else if (item.ledger_category.id == 8) {
                        lease_add += total
                    }

                }
                this.setState({
                    loading: false,
                    is_search: true,
                    total_balance_begining: total_balance_begining + Number(this.state.daily_ledger_cash),
                    total_pay_cash: total_pay_cash,
                    total_pay_check: total_pay_check,
                    total_pay_transfer: total_pay_transfer,
                    total_receive_cash: total_receive_cash,
                    total_receive_card: total_receive_card,
                    total_receive_transfer: total_receive_transfer,
                    //total_pay_lease: total_pay_lease,
                    total_receive_interest_redeem: total_receive_interest_redeem,
                    total_receive_interest_cash: total_receive_interest_cash,
                    total_receive_interest_card: total_receive_interest_card,
                    total_receive_interest_transfer: total_receive_interest_transfer,
                    total_receive_interest_check: total_receive_interest_check,
                    total_receive_interest2_cash: total_receive_interest2_cash,
                    total_receive_interest2_card: total_receive_interest2_card,
                    total_receive_interest2_transfer: total_receive_interest2_transfer,
                    total_receive_interest2_check: total_receive_interest2_check,
                    total_interest: total_interest,
                    total_buy: total_buy,
                    total_sell_cash: total_sell_cash,
                    total_sell_card: total_sell_card,
                    total_sell_transfer: total_sell_transfer,
                    total_sell_check: total_sell_check,
                    total_sell: total_sell,
                    total_expenses: total_expenses,
                    total_income: Number(total_income) + Number(this.state.daily_ledger_cash),
                    total_receipt: total_receipt,
                    total_expenditure: total_expenditure,
                    lease_delete: lease_delete,
                    lease_delete_transfer: lease_delete_transfer,
                    lease_delete_credit: lease_delete_credit,
                    lease_add: lease_add,
                    total_check: total_pay_check,
                    // total_check: (total_sell_check + total_receive_interest2_check + total_receive_interest_check),
                    total_card: total_card,
                    total_card_service: total_card_service,
                    total_transfer: total_transfer,
                    total_cash: Number(total_cash) + Number(this.state.daily_ledger_cash),
                    total_cash_ex: total_cash_ex,
                    total_check_ex: total_check_ex,
                })
            }
        })

        url = Settings.baseReportUrl + "/bill_items/?status_stock=Y&" + fromData
        Utility.get(url, (s, d) => {
            if (s == true) {
                let weight_se = 0
                let total_weight_bu = 0
                let total_weight_se = 0
                let total_weight_bu_b = 0
                let total_weight_se_b = 0
                let weight_bu = 0
                let n = d.length
                let group_product = {}
                let group_category = {}
                let labor_cost = 0
                let gold_cost = 0
                for (let i in d) {
                    if (d[i].kind == 'SE') {

                        labor_cost += (Number(d[i].fee) - (d[i].sell_bill_item.fee))
                        gold_cost += (Number(d[i].sell) - (Number(d[i].sell_bill_item.cost) + Number(d[i].fee)))
                        total_weight_se += Number(d[i].weight)
                        weight_se += Number(d[i].weight) / Number(d[i].product.category.weight)
                        if (group_product[d[i].product.category.id] == null) {
                            group_product[d[i].product.category.id] = {
                                title: d[i].product.category.name,
                                weight: 0,
                                weight_b: 0
                            }
                        }
                        group_product[d[i].product.category.id].weight += Number(d[i].weight)
                        group_product[d[i].product.category.id].weight_b += (Number(d[i].weight) / Number(d[i].product.category.weight))
                    }
                    if (d[i].kind == 'BU') {
                        total_weight_bu += Number(d[i].weight)
                        weight_bu += Number(d[i].weight) / Number(d[i].category.weight)
                        if (group_category[d[i].category.id] == null) {
                            group_category[d[i].category.id] = {
                                title: d[i].category.name,
                                weight: 0,
                                weight_b: 0
                            }
                        }
                        group_category[d[i].category.id].weight += Number(d[i].weight)
                        group_category[d[i].category.id].weight_b += (Number(d[i].weight) / Number(d[i].category.weight))
                    }

                }

                let items_products = []
                for (let i in group_product) {
                    items_products.push(group_product[i])
                }
                let items_category = []
                for (let i in group_category) {
                    items_category.push(group_category[i])
                }
                this.setState({
                    weight_se: weight_se,
                    weight_bu: weight_bu,
                    items_category: this.setValueProduct(items_category),
                    items_products: this.setValueProduct(items_products),
                    total_weight_se: total_weight_se,
                    total_weight_bu: total_weight_bu,
                    labor_cost: labor_cost,
                    gold_cost: gold_cost,
                });
            }
        });

        // url = Settings.baseUrl + "/lease/0/product_no_join/?" + fromData
        // Utility.get(url, (s, d) => {
        //     if (s == true) {
        //         let weight = 0
        //         let n = d.lease_product.length
        //         for (let i in d.lease_product) {
        //             weight += Number(d.lease_product[i].weight)
        //         }
        //         this.setState({
        //             amount_lease_product: n,
        //             weight_lease_product: weight
        //         });
        //     }
        // });

        url = Settings.baseUrl + "/lease_product_sum/?" + fromData
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    amount_lease_product: d.amount_lease_product,
                    weight_lease_product: d.weight_lease_product,
                });
            }
        });

        url = Settings.baseReportUrl + "/lease_report/?is_enabled=1&" + fromData
        Utility.get(url, (s, d) => {
            if (s == true) {
                let weight = 0
                let n = d.length
                let total_lease = 0
                let total_redeem = 0
                let total_pay_lease = 0;

                for (let i in d) {

                    if (d[i].status == 3) {
                        total_redeem += Number(d[i].amount);
                        total_pay_lease += Number(d[i].amount);
                    }
                    if (d[i].status == 1 || d[i].status == 2) {
                        total_lease += Number(d[i].amount);
                        total_pay_lease += Number(d[i].amount);
                    }

                }
                this.setState({
                    n_lease: n,
                    total_lease: total_lease,
                    total_redeem: total_redeem,
                    total_pay_lease: total_pay_lease
                });
            }
        });

        // url = Settings.baseUrl + "/stock_product/?branch=" + this.state.search.branch
        // Utility.get(url, (s, d) => {
        //     let w = 0
        //     for (let i in d) {
        //         w += Number(d[i].amount * d[i].product.weight)
        //     }
        //     this.setState({
        //         stock_weight: w,
        //     });
        // });
        url = Settings.baseUrl + "/stock_product_sum_weight/?branch=" + this.state.search.branch
        Utility.get(url, (s, d) => {
            this.setState({
                stock_weight: d.stock_weight,
            });
        });
        url = Settings.baseReportUrl + "/stock_old_product/?branch=" + this.state.search.branch
        Utility.get(url, (s, d) => {
            let w = 0
            for (let j in d) {
                // url = Settings.baseUrl + "/stock_old_product/" + d[j].id + "/items"
                // Utility.get(url, (s, d) => {
                //     for (let i in d) {
                //         w += Number(d[i].weight)
                //     }
                //     this.setState({
                //         stock_category_weight: w,
                //     });
                // })
                url = Settings.baseUrl + "/stock_old_product/" + d[j].id + "/items_sum_weight"
                Utility.get(url, (s, d) => {
                    w += Number(d.total_weight)
                    this.setState({
                        stock_category_weight: w,
                    });
                })
            }

        });

        // url = Settings.baseUrl + "/lease/?branch=" + this.state.search.branch
        // Utility.get(url, (s, d) => {
        //     let amount_redeem = 0
        //     let amount_lease = d.length
        //     let total_interest_bath = 0
        //     for (let i in d) {
        //         if (d[i].status == 3)
        //             amount_redeem += 1
        //         if (Number(d[i].total_interest) > 0) {
        //             let weight_b = 0
        //             for (let p in d[i].lease_prodcut) {
        //                 weight_b += Number(d[i].lease_prodcut[p].weight) / Number(d[i].lease_prodcut[p].category.weight)
        //             }
        //             total_interest_bath += (Number(d[i].total_interest) / weight_b)
        //         }
        //     }
        //     this.setState({
        //         amount_lease: amount_lease,
        //         amount_redeem: amount_redeem,
        //         total_interest_bath: total_interest_bath,
        //     });

        // });
        url = Settings.baseUrl + "/lease_sum/?branch=" + this.state.search.branch
        Utility.get(url, (s, d) => {
            this.setState({
                amount_lease: d.amount_lease,
                amount_redeem: d.amount_redeem,
                total_interest_bath: d.total_interest_bath,
            });
        });
    }

    setValueProduct(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            item.weight = Utility.weightFormat(item.weight)
            items.push(item)
        }

        return items
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.customer != null && this.state.search.customer != item.lease.customer.id) {
                continue
            }
            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data

        let title = 'รายงานสรุปประจำวัน';
        let filename = 'lease-history-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let lease_g = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (lease_g[item.lease.id] == null)
                lease_g[item.lease.id] = {
                    items: [],
                    lease: item.lease
                }

            lease_g[item.lease.id].items.push(item)
        }

        let lease_arr = []
        for (let i in lease_g) {
            lease_arr.push(lease_g[i])
        }

        let total_net_balance = (this.state.total_income - this.state.total_expenses)
        let not_search = !this.state.is_search
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสรุปประจำวัน</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login2">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Field width={4}>
                                    <label>สาขา</label>
                                    <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                                </Form.Field>
                                <Form.Field width={4}>
                                    <label>จากวันที่</label>
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.search.start_date}
                                        selected={this.state.search.start_date}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            if (this.state.search.end_date < date) {
                                                this.handlerInput(null, {
                                                    name: 'end_date',
                                                    value: date
                                                });
                                            }
                                            this.handlerInput(null, {
                                                name: 'start_date',
                                                value: date
                                            });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field width={4}>
                                    <label>ถึงวันที่</label>
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.search.end_date}
                                        selected={this.state.search.end_date}
                                        minDate={this.state.search.start_date}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field width={4}>
                                    <br />
                                    <Button floated='right' type='button' onClick={(e) => {
                                        this.handlerSubmit(e)
                                    }}><Icon name='search' />ค้นหา</Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                        <Form size='small'>
                            <Table >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='3' className='td_zone'>รายจ่าย</Table.HeaderCell>
                                        <Table.HeaderCell colSpan='4'>รายรับ</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell colSpan='1'></Table.Cell>
                                        <Table.Cell ><b><center>เงินสด</center></b></Table.Cell>
                                        <Table.Cell className='td_zone'></Table.Cell>
                                        <Table.Cell ></Table.Cell>
                                        <Table.Cell ><b><center>เงินสด</center></b></Table.Cell>
                                        <Table.Cell ><b><center>เงินโอน</center></b></Table.Cell>
                                        <Table.Cell ><b><center>เครดิต</center></b></Table.Cell>
                                        {/* <Table.Cell ><b><center>เช็ค</center></b></Table.Cell> */}
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>ยอดจ่ายเงิน</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_pay_cash)} className='text-right' /></Table.Cell>
                                        <Table.Cell className='td_zone'>
                                            {/* <Input value={Utility.priceFormat(this.state.total_pay_check)} className='text-right' /> */}
                                        </Table.Cell>
                                        <Table.Cell className='text-right'><div className='text-right'>ยอดเงินสด ณ ต้นวัน</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_balance_begining)} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='2'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='3' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>ยอดรับเงิน</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_cash)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_transfer)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_card)} className='text-right' /></Table.Cell>
                                        {/* <Table.Cell ></Table.Cell> */}
                                        {/* <Table.Cell colSpan='2'></Table.Cell> */}
                                    </Table.Row>

                                    <Table.Row >
                                        <Table.Cell colSpan='3' className='td_zone'><b>ขายฝาก</b></Table.Cell>
                                        <Table.Cell colSpan='4'></Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>ยอดขายฝาก</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' :
                                            (isNaN(Number(this.state.total_pay_lease + (this.state.lease_delete + this.state.lease_delete_credit + this.state.lease_delete_transfer) - this.state.lease_add))) ?
                                                Utility.priceFormat(0)
                                                : Utility.priceFormat(this.state.total_pay_lease + (this.state.lease_delete + this.state.lease_delete_credit + this.state.lease_delete_transfer) - this.state.lease_add)
                                        } className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>ดอกเบี้ยต่อดอก</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest_cash)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest_transfer)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest_card)} className='text-right' /></Table.Cell>
                                        {/* <Table.Cell ><Input value={Utility.priceFormat(this.state.total_receive_interest_check)} className='text-right' /></Table.Cell> */}
                                        {/* <Table.Cell colSpan='2'></Table.Cell> */}
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'> <Form.Field inline><span>จ.น.</span><Input width={2} style={{ width: '100px' }} readOnly value={not_search ? '' : this.state.amount_lease_product} className='text-right w2' /><span> ชิ้น</span></Form.Field></div></Table.Cell>
                                        <Table.Cell ><span>น.น.รวม </span><Input style={{ width: '150px' }} readOnly value={not_search ? '' : Utility.priceFormat(this.state.weight_lease_product)} className='text-right' /><span> กรัม</span></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>เงินต้น+ดอกเบี้ยไถ่คืน</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest2_cash)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest2_transfer)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest2_card)} className='text-right' /></Table.Cell>
                                        {/* <Table.Cell colSpan='2'></Table.Cell> */}
                                        {/* <Table.Cell ><Input value={Utility.priceFormat(this.state.total_receive_interest2_check)} className='text-right' /></Table.Cell> */}
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>จำนวนรายการขายฝาก</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.numberFormat(this.state.n_lease)} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>ดอกเบี้ยไถ่คืน</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_receive_interest_redeem)} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='2'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>ยอดขายฝากคงเหลือ</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_pay_lease)} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>ยอดไถ่คืน(เงินต้น)</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' :
                                            (isNaN(Number(this.state.total_interest - this.state.total_receive_interest_redeem))) ?
                                                Utility.priceFormat(0)
                                                : Utility.priceFormat(this.state.total_interest - this.state.total_receive_interest_redeem)
                                        } className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='2'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>เพิ่มเงินต้น</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.lease_add)} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>ลดเงินต้น</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.lease_delete)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.lease_delete_transfer)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.lease_delete_credit)} className='text-right' /></Table.Cell>
                                        {/* <Table.Cell colSpan='2'></Table.Cell> */}
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell colSpan='3' className='td_zone'><b>ซื้อ/ขายทอง</b></Table.Cell>
                                        <Table.Cell colSpan='4' ></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>ยอดซื้อ</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat((this.state.total_buy < 0 ? (-1) * this.state.total_buy : this.state.total_buy))} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'><Link className='link' to='/report/sales'>ยอดขาย</Link></div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_sell_cash)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_sell_transfer)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_sell_card)} className='text-right' /></Table.Cell>
                                        {/* <Table.Cell ><Input value={Utility.priceFormat(this.state.total_sell_check)} className='text-right' /></Table.Cell> */}
                                        {/* <Table.Cell colSpan='2'></Table.Cell> */}
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ><div className='text-right'>ราคาซื้อเฉลี่ย/บาท</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' :
                                            (isNaN(Number((this.state.total_buy < 0 ? (-1) * this.state.total_buy : this.state.total_buy) / this.state.weight_bu))) ?
                                                Utility.priceFormat(0)
                                                : Utility.priceFormat((this.state.total_buy < 0 ? (-1) * this.state.total_buy : this.state.total_buy) / this.state.weight_bu)
                                        } className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'></Table.Cell>
                                        <Table.Cell ><div className='text-right'>ราคาขายเฉลี่ย/บาท</div></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' :
                                            (isNaN(Number(this.state.total_sell / this.state.weight_se))) ?
                                                Utility.priceFormat(0)
                                                : Utility.priceFormat(this.state.total_sell / this.state.weight_se)
                                        } className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='2'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell colSpan='3' className='td_zone'><b>รายจ่ายรวม</b></Table.Cell>
                                        <Table.Cell colSpan='4' ><b>รายรับรวม</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell ></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_cash_ex < 0 ? (-1) * this.state.total_cash_ex : this.state.total_cash_ex)} className='text-right' /></Table.Cell>
                                        <Table.Cell colSpan='1' className='td_zone'>
                                            {/* <Input value={Utility.priceFormat(this.state.total_check_ex < 0 ? (-1) * this.state.total_check_ex : this.state.total_check_ex)} className='text-right' /> */}
                                        </Table.Cell>
                                        <Table.Cell ></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_cash)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_transfer)} className='text-right' /></Table.Cell>
                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_card)} className='text-right' /></Table.Cell>
                                        {/* <Table.Cell ><Input value={Utility.priceFormat(this.state.total_check)} className='text-right' /></Table.Cell> */}
                                        {/* <Table.Cell colSpan='2'></Table.Cell> */}
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            <Grid celled>
                                <Grid.Row>
                                    <Grid.Column width={10}>
                                        <div>
                                            <Button.Group>
                                                <Button onClick={() => { this.setState({ is_tab1: true }) }} type='button' active={this.state.is_tab1}>ซื้อขายทอง</Button>
                                                <Button onClick={() => { this.setState({ is_tab1: false }) }} type='button' active={!this.state.is_tab1}>ทองเก่า/ขายฝาก</Button>
                                            </Button.Group>
                                            <br /><br />
                                            {this.state.is_tab1 ? <Grid divided='vertically'>
                                                <Grid.Row columns={2}>
                                                    <Grid.Column>
                                                        <div id='table_width'>
                                                            <label>รายการซื้อทองรูปพรรณ (ทองเข้า)</label>
                                                            <TableCategory width={this.state.table_width} items={this.state.items_category} />
                                                        </div>
                                                        <Table basic='very'>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='3'><div>น.น.ซื้อรวม</div></Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><Input readOnly value={not_search ? '' : Utility.weightFormat(this.state.total_weight_bu)} className='text-right' style={{ width: '120px' }} /> กรัม</Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' : Utility.weightFormat(this.state.weight_bu)} className='text-right' style={{ width: '120px' }} /> บาท</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>

                                                        <Table basic='very'>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><div className='text-right'>กำไรทอง</div></Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.gold_cost)} className='text-right' /></Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><div className='text-right'>กำไรค่ากำเหน็จ</div></Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.labor_cost)} className='text-right' /></Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><div className='text-right'>กำไรรวม</div></Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.gold_cost + this.state.labor_cost)} className='text-right' /></Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <label>รายการขายทองรูปพรรณ (ทองออก)</label>
                                                        <TableProduct width={this.state.table_width} items={this.state.items_products} />

                                                        <Table basic='very'>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='3'><div>น.น.ขายรวม</div></Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><Input readOnly value={not_search ? '' : Utility.weightFormat(this.state.total_weight_se)} style={{ width: '120px' }} className='text-right' /> กรัม</Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' : Utility.weightFormat(this.state.weight_se)} style={{ width: '120px' }} className='text-right' /> บาท</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                        <Table basic='very'>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><div className='text-right'>น.น.คงเหลือ Stock</div></Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' :
                                                                        (isNaN(Number(this.state.stock_weight / 15.2))) ?
                                                                            Utility.priceFormat(0)
                                                                            : Utility.priceFormat(this.state.stock_weight / 15.2)
                                                                    } className='text-right' />บาท</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell colSpan='2'><div className='text-right'>น.น.คงเหลือ Stock</div></Table.Cell>
                                                                    <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.stock_weight)} className='text-right' />กรัม</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid> : <Table>

                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell ><div className='text-right'>สต็อกทองเก่า</div></Table.Cell>
                                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.weightFormat(this.state.stock_category_weight)} className='text-right' labelPosition='right' label={{ basic: true, content: 'g' }} /></Table.Cell>
                                                        <Table.Cell ><div className='text-right'>ค่าเฉลี่ยต่อบาท</div></Table.Cell>
                                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.stock_category_weight / 15.2)} className='text-right' labelPosition='right' label={{ basic: true, content: 'บาท' }} /></Table.Cell>
                                                        <Table.Cell ></Table.Cell>
                                                        <Table.Cell ></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell ><div className='text-right'>สรุปยอดขายฝาก ไถ่คืน</div></Table.Cell>
                                                        <Table.Cell ><Input placeholder='สรุปยอดขายฝาก ไถ่คืน' readOnly value={not_search ? '' : (this.state.amount_redeem)} className='text-right' labelPosition='right' label={{ basic: true, content: 'ห่อ' }} /> </Table.Cell>
                                                        <Table.Cell ><div className='text-right'>ฝาก</div></Table.Cell>
                                                        <Table.Cell ><Input placeholder='ห่อ' readOnly value={not_search ? '' : (this.state.amount_lease)} className='text-right' labelPosition='right' label={{ basic: true, content: 'ห่อ' }} /></Table.Cell>

                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell ><div className='text-right'>ด/บ</div></Table.Cell>
                                                        <Table.Cell ><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_interest_bath)} className='text-right' labelPosition='right' label={{ basic: true, content: 'บาท' }} /></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>}

                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Table >
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell ><div className='text-right'>รวมรับ</div></Table.Cell>
                                                    <Table.Cell><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_income)} className='text-right' /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell ><div className='text-right'>ยอดคงเหลือสุทธิ</div></Table.Cell>
                                                    <Table.Cell><Input readOnly value={not_search ? '' :
                                                        (isNaN(Number(total_net_balance))) ?
                                                            Utility.priceFormat(0)
                                                            : Utility.priceFormat(total_net_balance)
                                                    } className='text-right' /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell ><div className='text-right'>รวมยอดเงินสด</div></Table.Cell>
                                                    <Table.Cell><Input readOnly value={not_search ? '' :
                                                        (isNaN(Number(this.state.total_cash - this.state.total_cash_ex))) ?
                                                            Utility.priceFormat(0)
                                                            : Utility.priceFormat(this.state.total_cash - this.state.total_cash_ex)
                                                    } className='text-right' /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell ><div className='text-right'>รวมยอดเงินโอน</div></Table.Cell>
                                                    <Table.Cell><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_transfer)} className='text-right' /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell ><div className='text-right'>รวมยอดเครดิต</div></Table.Cell>
                                                    <Table.Cell><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_card)} className='text-right' /></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell ><div className='text-right'>รวมค่าธรรมเนียม</div></Table.Cell>
                                                    <Table.Cell><Input readOnly value={not_search ? '' : Utility.priceFormat(this.state.total_card_service)} className='text-right' /></Table.Cell>
                                                </Table.Row>
                                                {/* <Table.Row>
                                                    <Table.Cell ><div className='text-right'>รวมยอดเช็ค</div></Table.Cell>
                                                    <Table.Cell><Input value={Utility.priceFormat(this.state.total_check_in - this.state.total_check)} className='text-right' /></Table.Cell>
                                                </Table.Row> */}
                                            </Table.Body>
                                        </Table>
                                        <Button type='button' onClick={(e) => {
                                            if (!this.state.is_search) {
                                                alert("ยังไม่ได้ทำการค้นหา")
                                                return;
                                            }
                                            this.setState({
                                                open: true
                                            })
                                        }}>พิมพ์</Button>
                                        <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Form>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center>ตั้งแต่วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Header>


                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter} colSpan='3' className='td_zone'>รายจ่าย</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='6'>รายรับ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell colSpan='1'></Table.Cell>
                                            <Table.Cell style={textRight}><b>เงินสด</b></Table.Cell>
                                            <Table.Cell colSpan='1' className='td_zone' style={textRight}></Table.Cell>
                                            <Table.Cell ></Table.Cell>
                                            <Table.Cell style={textRight}><b>เงินสด</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>เงินโอน</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>เครดิต</b></Table.Cell>
                                            {/* <Table.Cell style={textRight}><b>เช็ค</b></Table.Cell> */}
                                            <Table.Cell colSpan='2'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell ><div className='text-right'>ยอดจ่ายเงิน</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_pay_cash)}</Table.Cell>
                                            <Table.Cell style={textRight} className='td_zone'>
                                                {/* {Utility.priceFormat(this.state.total_pay_check)} */}
                                            </Table.Cell>
                                            <Table.Cell className='text-right' style={textRight}><div className='text-right'>ยอดเงินสด ณ ต้นวัน</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_balance_begining)}</Table.Cell>
                                            <Table.Cell colSpan='5'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell colSpan='3' className='td_zone'></Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>ยอดรับเงิน</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_cash)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_transfer)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_card)}</Table.Cell>
                                            <Table.Cell ></Table.Cell>
                                            <Table.Cell colSpan='2'></Table.Cell>
                                        </Table.Row>

                                        <Table.Row >
                                            <Table.Cell colSpan='3' className='td_zone'><b>ขายฝาก</b></Table.Cell>
                                            <Table.Cell colSpan='6'></Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell ><div className='text-right'>ยอดขายฝาก</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_pay_lease + (this.state.lease_delete + this.state.lease_delete_credit + this.state.lease_delete_transfer) - this.state.lease_add))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat(this.state.total_pay_lease + (this.state.lease_delete + this.state.lease_delete_credit + this.state.lease_delete_transfer) - this.state.lease_add)}
                                            </Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell ><div className='text-right'>ดอกเบี้ยต่อดอก</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest_cash)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest_transfer)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest_card)}</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest_check)}</Table.Cell> */}
                                            <Table.Cell colSpan='3'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'> จ.น.  {this.state.amount_lease_product} ชิ้น</div></Table.Cell>
                                            <Table.Cell style={textRight}>น.น.รวม   {Utility.priceFormat(this.state.weight_lease_product)} กรัม</Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>เงินต้น+ดอกเบี้ยไถ่คืน</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest2_cash)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest2_transfer)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest2_card)}</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_receive_interest2_check)}</Table.Cell> */}
                                            <Table.Cell colSpan='3'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>จำนวนรายการขายฝาก</div></Table.Cell>
                                            <Table.Cell style={textRight} >{Utility.numberFormat(this.state.n_lease)}</Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>ดอกเบี้ยไถ่คืน</div></Table.Cell>
                                            <Table.Cell style={textRight} >{Utility.priceFormat(this.state.total_receive_interest_redeem)}</Table.Cell>
                                            <Table.Cell colSpan='5'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>ยอดขายฝากคงเหลือ</div></Table.Cell>
                                            <Table.Cell style={textRight} >{Utility.priceFormat(this.state.total_pay_lease)}</Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>ยอดไถ่คืน(เงินต้น)</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_interest - this.state.total_receive_interest_redeem))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat(this.state.total_interest - this.state.total_receive_interest_redeem)}
                                            </Table.Cell>
                                            <Table.Cell colSpan='5'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>เพิ่มเงินต้น</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.lease_add)}</Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>ลดเงินต้น</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.lease_delete)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.lease_delete_transfer)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.lease_delete_credit)}</Table.Cell>
                                            <Table.Cell colSpan='4'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell colSpan='3' className='td_zone' ><b>ซื้อ/ขายทอง</b></Table.Cell>
                                            <Table.Cell colSpan='6' ></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>ยอดซื้อ</div></Table.Cell>
                                            <Table.Cell style={textRight} >{Utility.priceFormat((this.state.total_buy < 0 ? (-1) * this.state.total_buy : this.state.total_buy))}</Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell style={textRight} ><div className='text-right'>ยอดขาย</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_sell_cash)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_sell_transfer)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_sell_card)}</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_sell_check)}</Table.Cell> */}
                                            <Table.Cell colSpan='3'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>ราคาซื้อเฉลี่ย/บาท</div></Table.Cell>
                                            <Table.Cell style={textRight} >
                                                {(isNaN(Number((this.state.total_buy < 0 ? (-1) * this.state.total_buy : this.state.total_buy) / this.state.weight_bu))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat((this.state.total_buy < 0 ? (-1) * this.state.total_buy : this.state.total_buy) / this.state.weight_bu)}
                                            </Table.Cell>
                                            <Table.Cell style={textLeft} className='td_zone'></Table.Cell>
                                            <Table.Cell ><div className='text-right' style={textRight}>ราคาขายเฉลี่ย/บาท</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_sell / this.state.weight_se))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat(this.state.total_sell / this.state.weight_se)}
                                            </Table.Cell>
                                            <Table.Cell colSpan='4'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell colSpan='3' style={textLeft} className='td_zone'><b>รายจ่ายรวม</b></Table.Cell>
                                            <Table.Cell colSpan='6' style={textLeft} ><b>รายรับรวม</b></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell ></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_cash_ex < 0 ? (-1) * this.state.total_cash_ex : this.state.total_cash_ex)}</Table.Cell>
                                            <Table.Cell className='td_zone' style={textRight}>
                                                {/* {Utility.priceFormat(this.state.total_check_ex < 0 ? (-1) * this.state.total_check_ex : this.state.total_check_ex)} */}
                                            </Table.Cell>
                                            <Table.Cell ></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_cash)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_transfer)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_card)}</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_check)}</Table.Cell> */}
                                            <Table.Cell colSpan='3'></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Body>
                                        <Table.Row ><Table.Cell colSpan='9'><hr /></Table.Cell></Table.Row>
                                    </Table.Body>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>รวมรับ</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_income)} </Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>ยอดคงเหลือสุทธิ</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(total_net_balance))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat(total_net_balance)}
                                            </Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>รวมยอดเงินสด</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_cash - this.state.total_cash_ex))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat(this.state.total_cash - this.state.total_cash_ex)}
                                            </Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>รวมยอดเงินโอน</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_transfer)} </Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>รวมยอดเครดิต</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_card)} </Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>รวมค่าธรรมเนียม</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_card_service)} </Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                        {/* <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>รวมยอดเช็ค</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_check_in - this.state.total_check)} </Table.Cell>
                                            <Table.Cell colSpan='6'></Table.Cell>
                                        </Table.Row> */}
                                    </Table.Body>
                                    <Table.Body>
                                        <Table.Row ><Table.Cell colSpan='9'><hr /></Table.Cell></Table.Row>
                                    </Table.Body>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>สต็อกทองเก่า</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.stock_category_weight))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.stock_category_weight)} g
                                            </Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>ค่าเฉลี่ยต่อบาท</div></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.stock_category_weight / 15.2))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.priceFormat(this.state.stock_category_weight / 15.2)} บาท
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell colSpan='3'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>สรุปยอดขายฝาก ไถ่คืน</div></Table.Cell>
                                            <Table.Cell style={textRight}>{(this.state.amount_redeem)} ห่อ</Table.Cell>
                                            <Table.Cell style={textRight}><div className='text-right'>ฝาก</div></Table.Cell>
                                            <Table.Cell style={textRight}>{(this.state.amount_lease)} ห่อ</Table.Cell>
                                            <Table.Cell colSpan='5'></Table.Cell>

                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}><div className='text-right'>ด/บ</div></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.total_interest)} บาท</Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Body>
                                        <Table.Row ><Table.Cell colSpan='9'><hr /></Table.Cell></Table.Row>
                                    </Table.Body>

                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell colSpan='4' style={textRight}>รายการซื้อทองรูปพรรณ (ทองเข้า)</Table.Cell>
                                            <Table.Cell colSpan='5' style={textRight}>รายการขายทองรูปพรรณ (ทองออก)</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell style={textRight}>%ทอง</Table.Cell>
                                            <Table.Cell style={textRight}>น.น.ซื้อ(กรัม)</Table.Cell>
                                            <Table.Cell style={textRight}>น.น.ซื้อ(บาท)</Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}>%ทอง</Table.Cell>
                                            <Table.Cell style={textRight}>น.น.ซื้อ(กรัม)</Table.Cell>
                                            <Table.Cell style={textRight}>น.น.ซื้อ(บาท)</Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>
                                        {this.state.items_category.length > this.state.items_products.length ?
                                            this.state.items_category.map((item, i) => {

                                                let title = ''
                                                let weight = ''
                                                let weight_b = ''
                                                if (this.state.items_products.length > i) {
                                                    title = this.state.items_products[i].title
                                                    weight = this.state.items_products[i].weight
                                                    weight_b = this.state.items_products[i].weight_b
                                                }
                                                return (<Table.Row >
                                                    <Table.Cell style={textRight}>{item.title}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.weight)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.weight_b)}</Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                    <Table.Cell style={textRight}>{title}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(weight)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(weight_b)}</Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                </Table.Row>)

                                            }) : this.state.items_products.map((item, i) => {

                                                let title = ''
                                                let weight = ''
                                                let weight_b = ''
                                                if (this.state.items_category.length > i) {
                                                    title = this.state.items_category[i].title
                                                    weight = this.state.items_category[i].weight
                                                    weight_b = this.state.items_category[i].weight_b
                                                }
                                                return (<Table.Row >
                                                    <Table.Cell style={textRight}>{title}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(weight)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(weight_b)}</Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                    <Table.Cell style={textRight}>{item.title}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.weight)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.weight_b)}</Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                </Table.Row>)
                                            })}

                                    </Table.Body>

                                    <Table.Body>
                                        <Table.Row ><Table.Cell colSpan='9'><hr /></Table.Cell></Table.Row>
                                        <Table.Row >
                                            <Table.Cell style={textRight}>น้ำหนักซื้อทองรวม</Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_weight_bu / 15.2))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.total_weight_bu / 15.2)} บาท
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}>น้ำหนักขายทองรวม</Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_weight_se / 15.2))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.total_weight_se / 15.2)} บาท
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_weight_bu))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.total_weight_bu)} กรัม
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.total_weight_se))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.total_weight_se)} กรัม
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell style={textRight}>กำไรทอง</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.gold_cost)} บาท</Table.Cell>
                                            <Table.Cell style={textRight}>น.น.คงเหลือ Stock</Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.stock_weight / 15.2))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.stock_weight / 15.2)} บาท
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}>กำไรค่ากำเหน็จ</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.labor_cost)} บาท</Table.Cell>
                                            <Table.Cell style={textRight}>น.น.คงเหลือ Stock</Table.Cell>
                                            <Table.Cell style={textRight}>
                                                {(isNaN(Number(this.state.stock_weight))) ?
                                                    Utility.priceFormat(0)
                                                    : Utility.weightFormat(this.state.stock_weight)} กรัม
                                            </Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight}>กำไรรวม</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.labor_cost + this.state.gold_cost)} บาท</Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}


const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(PrintPreview)