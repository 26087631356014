/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Button,
  Icon,
  Radio
} from 'semantic-ui-react';
import InputSearchBill from './InputSearchBill'
import InputSearchTax from './InputSearchTax'
import InputSearchCustomer from '../../Customer/InputSearchCustomer'
import moment from 'moment'
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker';

class FormSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      start_date: moment(),
      end_date: moment(),
      kind: '',
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.handlerSearch = this.handlerSearch.bind(this)
  }
  resetForm(event,d) {
    event.preventDefault();
    for (let i in this.state) {
      
      if(!(d.name =='today' && [i]=='kind'))
      this.setState({
        [i]: ''
      });
    }
    this.setState({
      payment: '',
      start_date: moment(),
      end_date: moment()
    })
    if(d.name == 'today'){
    this.props.onSubmitSearch({
      kind:this.state.kind,
      start_date: Utility.formatDate2(moment()),
      end_date: Utility.formatDate2(moment()),
    });
  }
  }
    
    handlerDropdownSearch(event, value) {
  
      this.setState({
        [value.name]: value.value
      });
    }
  
    submitSearch(event) {
      event.preventDefault();
      this.props.onSubmitSearch(Utility.cloneObjectJson(this.state),1);
  
    }
  
    handlerSearch(event) {
      const target = event.target;
      const value = target.type == 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    }
  
    render() {
  
      return (
        <Form className='attached fluid' size='small' onSubmit={this.submitSearch} >
          <Form.Group>
            <InputSearchBill label='เลขที่บิล' placeholder='เลขที่บิล' width={3} onChange={this.handlerDropdownSearch} name="bill_number" value={this.state.bill_number} />
            {/* <InputSearchTax  label='เลขที่ Vat' placeholder='เลขที่ Vat' width={3} onChange={this.handlerDropdownSearch} name="tax_number" value={this.state.tax_number} /> */}
            <InputSearchCustomer  label='ลูกค้า' placeholder='ลูกค้า' width={4} onChange={this.handlerDropdownSearch} name="customer_id" value={this.state.customer_id} />
            <Form.Dropdown id='searchPayment' label='ประเภทชำระ' name="payment" placeholder='ประเภทชำระ' search selection width={3}
              options={Settings.payment_option}
              value={this.state.payment}
              onChange={this.handlerDropdownSearch} />
            <Form.Field id='searchDateStart' width={3}>
              <label>จากวันที่</label>
              <DatePicker
                // maxDate={this.state.end_date}
                dateFormat="DD/MM/YYYY"
                selected={this.state.start_date}
                onChange={(date) => {
                  date = date?date:moment()
                  this.setState({ 
                    start_date: date , 
                    end_date : this.state.end_date ? this.state.end_date : date  
                  });
                  if(this.state.end_date<date){
                    this.setState({ end_date: date });
                  }
                }}
              />
            </Form.Field>
            <Form.Field id='searchDateEnd' width={3}>
              <label>ถึงวันที่</label>
              <DatePicker
                minDate={this.state.start_date}
                dateFormat="DD/MM/YYYY"
                selected={this.state.end_date}
                onChange={(date) => {
                  date = date?date:moment()
                  this.setState({ end_date: date });
                }}
              />
            </Form.Field>
            
          </Form.Group>
          <Form.Group>
  
            <Form.Field width={2}>
              <Radio id='radioAll' label='ทั้งหมด' name='kind' checked={this.state.kind == ''} value="" onChange={this.handlerDropdownSearch} />
            </Form.Field>
            <Form.Field width={2}>
              <Radio id='radioSell' label='รายการขาย' name='kind' checked={this.state.kind == 'SE'} value="SE" onChange={this.handlerDropdownSearch} />
            </Form.Field>
            <Form.Field width={2}>
              <Radio id='radioBuy' label='รายการซื้อ' name='kind' checked={this.state.kind == 'BU'} value="BU" onChange={this.handlerDropdownSearch} />
            </Form.Field>
            <Form.Field width={2}>
              <Radio id='radioChange' label='รายการเปลี่ยน' name='kind' checked={this.state.kind == 'XC'} value="XC" onChange={this.handlerDropdownSearch} />
            </Form.Field>
            <Form.Field width={2}>
              <Radio id='radioDefactive' label='รายการชำรุด' name='kind' checked={this.state.kind == 'DF'} value="DF" onChange={this.handlerDropdownSearch} />
            </Form.Field>
  
            <Form.Field width={8}>
              <Button id='btnClear' floated='right' name='clear' onClick={this.resetForm} > รีเซ็ต</Button>
              <Button id='btnToday' floated='right' name='today' onClick={this.resetForm} ><Icon name='search' />วันนี้</Button>
              <Button id='btnSearch' floated='right' ><Icon name='search' /> ค้นหา</Button>
              
            </Form.Field>
          </Form.Group>
        </Form>
      )
    }
  }

const mapStateToProps = state => {

  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps
)(FormSearch)