/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { Cell } from 'fixed-data-table';
import {
  Form, Header, Button, Loader, Dimmer, Icon, Input,
  Popup,
} from 'semantic-ui-react';
import Utility from '../../Utility';

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={['weight_real', 'weight', 'weight_out'].includes(field) ? Utility.weightFormat(data[rowIndex][field]) : data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{['weight_real', 'weight', 'weight_out'].includes(field) ? Utility.weightFormat(data[rowIndex][field]) : data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}
export default ItemsCell