/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class SellAverage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            bill_type: [
                {
                    value: 0,
                    key: 0,
                    text: 'ขายทอง'
                },
                {
                    value: 1,
                    key: 1,
                    text: 'เปลี่ยนทอง'
                }
            ]
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.resetProduct = this.resetProduct.bind(this)

    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var categories = Utility.getFetch(Settings.baseReportUrl + '/categories/');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/');
        var pattern = Utility.getFetch(Settings.baseReportUrl + '/pattern/');
        Promise.all([branches, categories, product_types, pattern]).then((values) => {
            this.branches = values[0];
            this.product_types = values[2];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }
            let cat = [];
            for (let i in values[1]) {
                cat.push({
                    key: values[1][i].id,
                    value: values[1][i].id,
                    text: values[1][i].name,
                });
            }
            let product_type = [];
            for (let i in values[2]) {
                product_type.push({
                    key: values[2][i].id,
                    value: values[2][i].id,
                    text: values[2][i].name,
                });
            }

            let pattern = [];
            for (let i in values[3]) {
                pattern.push({
                    key: values[3][i].id,
                    value: values[3][i].id,
                    text: values[3][i].name,
                });
            }
            let products = [];
            this.setState({
                loader_active: false,
                branches: branches,
                categories: cat,
                product_type: product_type,
                pattern: pattern,
                products: products,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
        let search = this.state.search
        if (localStorage.getItem("product_kind") != null)
            search['product_type'] = JSON.parse(localStorage.getItem("product_kind"))
        this.setState({
            search: search
        })
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            products: []
        })
        localStorage.setItem('product_kind','[]')
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let search_kind = []
        if (search.bill_type && search.bill_type.length != 0) {
            for (let i in search.bill_type) {
                if (search.bill_type[i] == 0) {
                    search_kind.push("SE")
                } else if (search.bill_type[i] == 1) {
                    search_kind.push("XC")
                }
            }
            search["kind"] = search_kind
            delete search["bill_type"]
        }
        if (search.vendor && search.vendor.length == 0) {
            delete search["vendor"]
        }
        if (search.categories && search.categories.length == 0) {
            delete search["categories"]
        }
        if (search.product_type && search.product_type.length == 0) {
            delete search["product_type"]
        }
        if (search.pattern && search.pattern.length == 0) {
            delete search["pattern"]
        }
        if (search.type_sale && search.type_sale.length == 0) {
            delete search["type_sale"]
        }

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/bill_items/?sort=code&status_stock=Y&kind=SE,XC&" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.branch) {
                if (item.bill.branch.id != this.state.search.branch)
                    continue;
            }
            // if (this.state.search.categories) {
            //     if (item.product.category.id != this.state.search.categories)
            //         continue;
            // }
            // if (this.state.search.product_type) {
            //     if (item.product.kind.id != this.state.search.product_type)
            //         continue;
            // }
            // if (this.state.search.pattern) {
            //     if (item.product.pattern.id != this.state.search.pattern)
            //         continue;
            // }
            // if (this.state.search.products) {
            //     if (item.product.id != this.state.search.products)
            //         continue;
            // }
            // if (this.state.search.type_sale) {
            //     if (item.product.type_sale != this.state.search.type_sale)
            //         continue;
            // }

            items.push(item)
        }
        return items
    }
    resetProduct(text) {
        let search = this.state.search
        if (text != '') {
            this.setState({
                isLoading: true
            });
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                let products = [];
                let s = {}
                if (search.categories != undefined)
                    s.category_id = search.categories
                if (search.type_sale != undefined)
                    s.type_sale = search.type_sale
                if (search.product_type != undefined)
                    s.product_types_id = search.product_type
                if (search.pattern != undefined)
                    s.pattern_id = search.pattern
                s.text = text
                s.limited = 10
                var p = Utility.getFetch(Settings.baseReportUrl + '/products_no_join/?' + Utility.jsonToQueryString(s));
                Promise.all([p]).then((values) => {
                    let p = values[0].product
                    for (let i in p) {
                        products.push({
                            key: p[i].id,
                            value: p[i].id,
                            text: p[i].code,
                        });
                    }
                    this.setState({
                        products: products,
                        isLoading: false,
                    })
                })
            }, 1600)
        } else {
            this.setState({
                products: [],
            })
        }
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        if (v.name == 'product_type')
            localStorage.setItem('product_kind', JSON.stringify(search[v.name]))
        this.setState({
            search: search
        })
        if (v.name == 'categories') {
            this.resetCategory(v.value);
        }
    }

    resetCategory(category_id) {
        let type = [];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_type: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_type: type });
        }
    }
    render() {


        let title = 'รายงานราคาขายเฉลี่ย';
        let filename = 'stock_in';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }
        let titles = 'รายงานราคาขายและเปลี่ยนเฉลี่ย'
        if (this.state.search.bill_type && this.state.search.bill_type.length == 1) {
            if (this.state.search.bill_type[0] == 0) {
                titles = 'รายงานราคาขายเฉลี่ย'
            } else {
                titles = 'รายงานราคาเปลี่ยนเฉลี่ย'
            }
        }

        let date_g = {}
        for (let i in this.state.items) {

            let item = this.state.items[i]
            if (item.kind == "SE") {
                let product_code = item.product.code
                let name = item.product.name
                let kind = item.product.kind.name
                let category = item.product.category.name
                if (date_g[product_code] == null) {
                    date_g[product_code] = {
                        items: [],
                        title: product_code,
                        name: name,
                        kind: kind,
                        category: category
                    }
                }
                const o = date_g[product_code].items.find(k => k.bill.bill_number === item.bill.bill_number)
                if (o === undefined) {
                    date_g[product_code].items.push(item)
                }
                else {
                    const index = date_g[product_code].items.indexOf(o)
                    date_g[product_code].items[index].amount += item.amount
                    date_g[product_code].items[index].weight = Number(date_g[product_code].items[index].weight) + Number(item.weight)
                    date_g[product_code].items[index].fee = Number(date_g[product_code].items[index].fee) + Number(item.fee)

                }
            }
        }

        let data_list = []
        for (let i in date_g) {
            data_list.push(date_g[i])
        }
        let total_amount = 0
        let total_weight = 0
        let total_cost = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{"รายงานราคาขายและเปลี่ยนเฉลี่ย"}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทบิล</label>
                                <Dropdown multiple search selection placeholder='- ทั้งหมด -' options={this.state.bill_type} value={this.state.search.bill_type} name='bill_type' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>กลุ่มสินค้า</label>
                                <Dropdown multiple search selection placeholder='- ทั้งหมด -' options={this.state.categories} value={this.state.search.categories} name='categories' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทสินค้า<small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown multiple search selection
                                    options={this.state.product_type}
                                    value={this.state.search.product_type} placeholder='- ทั้งหมด -' name='product_type' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ลายทอง</label>
                                <Dropdown multiple search selection placeholder='- ทั้งหมด -' options={this.state.pattern} value={this.state.search.pattern} name='pattern' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทงานขาย</label>
                                <Dropdown multiple search selection placeholder='- ทั้งหมด -' options={Settings.type_sale} value={this.state.search.type_sale} name='type_sale' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>รหัสสินค้า</label>
                                <Dropdown
                                    search selection
                                    placeholder='รหัสสินค้า'
                                    options={this.state.products}
                                    value={this.state.search.products}
                                    name='products'
                                    onChange={this.handlerInput}
                                    onSearchChange={(e, v) => {
                                        this.resetProduct(v.searchQuery)
                                    }}
                                    loading={this.state.isLoading}
                                    isFetching={this.state.isLoading}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center>{titles}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='12'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่อ้างอิง</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.(g)/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาขายทอง/บาท</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาขายทอง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่ากำเหน็จ/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาขายรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ขายทั้งสิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {data_list.map((row, i) => {
                                        let amount = 0
                                        let weight = 0
                                        let avg_gold_price = 0
                                        let avg_sell_p = 0
                                        let avg_fee = 0
                                        let total_sell = 0
                                        let sell_p = 0
                                        return (<Table.Body>
                                            <Table.Row >
                                                <Table.HeaderCell style={textLeft}>{row.title}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}>{row.kind}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}>{row.category}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}>{row.name}</Table.HeaderCell>
                                                <Table.HeaderCell style={textLeft}></Table.HeaderCell>
                                            </Table.Row>
                                            {row.items.map((row, i) => {
                                                weight += parseFloat(row.weight)
                                                amount += row.amount
                                                avg_gold_price += parseFloat(row.gold_price) * parseFloat(row.amount)

                                                avg_fee += (parseFloat(row.fee) / parseFloat(row.amount)) * parseFloat(row.amount)
                                                if (row.product.type_sale === 2) {
                                                    sell_p = parseFloat(row.sell) - parseFloat(row.fee)
                                                } else {
                                                    sell_p = Math.ceil((parseFloat(row.weight) / parseFloat(row.amount)) / parseFloat(row.product.category.weight) * parseFloat(row.gold_price))
                                                }
                                                let fee_p = parseFloat(row.fee) / parseFloat(row.amount)
                                                total_sell += (parseFloat(sell_p) + parseFloat(fee_p)) * parseFloat(row.amount)

                                                avg_sell_p += sell_p * parseFloat(row.amount)

                                                total_weight += parseFloat(row.weight)
                                                total_amount += row.amount
                                                total_cost += (parseFloat(sell_p) + parseFloat(fee_p)) * parseFloat(row.amount)
                                                return (<Table.Row key={i}>
                                                    <Table.Cell style={textLeft}>{Utility.formatDate(row.record_date)}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row.bill.bill_number}</Table.Cell>
                                                    <Table.Cell style={textLeft}><div>{row.bill.kind_display + "ทอง"}</div></Table.Cell>
                                                    <Table.Cell style={textRight}>{row.amount}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(parseFloat(row.weight) / parseFloat(row.amount))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(parseFloat(row.weight))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.gold_price)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(sell_p)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(fee_p * 100 / 107)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(sell_p) + parseFloat(fee_p))}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat((parseFloat(sell_p) + parseFloat(fee_p)) * parseFloat(row.amount))}</Table.Cell>
                                                    <Table.Cell style={textRight}></Table.Cell>
                                                </Table.Row>)
                                            })}

                                            <Table.Row>
                                                <Table.Cell style={textLeft}><div style={textU}>รวม</div></Table.Cell>
                                                <Table.Cell colSpan='3' style={textRight}><div style={textU}>{amount}</div></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.weightFormat(weight)}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(avg_gold_price) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(avg_sell_p) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(avg_fee * 100 / 107) / parseFloat(amount))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat((parseFloat(avg_sell_p) / parseFloat(amount)) + (parseFloat(avg_fee) / parseFloat(amount)))}</div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(parseFloat(total_sell))}</div></Table.Cell>
                                                <Table.Cell style={textRight}></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan='12'></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}

                                    <Table.Row>
                                        <Table.Cell style={textLeft}><b><div style={textU}>รวมทั้งหมด</div></b></Table.Cell>
                                        <Table.Cell colSpan='3' style={textRight}><div style={textU}><b id='total_amount'>{total_amount}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                        <Table.Cell style={textRight}><div style={textU} id='total_weight'><b>{Utility.weightFormat(total_weight)}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                        <Table.Cell colSpan='4' style={textRight}><div style={textU}><b id='total_cost'>{Utility.priceFormat(total_cost)}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='12'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={textLeft}><b><div style={textU}>ราคาขายเฉลี่ย</div></b></Table.Cell>
                                        {parseFloat(total_weight) === 0.00 ?
                                            <Table.Cell colSpan='11' style={textRight}><div style={textU}><b id='total_cost'>0.00</b></div></Table.Cell>
                                            :
                                            <Table.Cell colSpan='11' style={textRight}><div style={textU}><b id='total_cost'>{Utility.priceFormat(parseFloat(total_cost) / parseFloat(total_weight))}</b></div></Table.Cell>
                                        }
                                    </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>
                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(SellAverage)
