/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import { connect } from 'react-redux'

import {
  Form, Input, Dropdown, Button, Icon, Label, Modal, Confirm, Dimmer, Loader, Header, Menu,
  Popup,
  Pagination,
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}


class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

  }

  render() {
    const { rowIndex, field, data, auth, ...props } = this.props;
    return (
      <Cell><center style={{ padding: '4px' }}>
        <a id={rowIndex + "_btnEdit"} onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>

        {/* กรณี ห้ามลบ user ตัวเอง และ user id = 1 เพราะเป็นหลักของระบบ */}
        {auth.user.id == data[rowIndex].user.id || data[rowIndex].user.id == 1 ? <a title="ไม่สามารถลบได้" ><Icon disabled name='window close outline' /></a> : <a id={rowIndex + "_btnDelete"} onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a>}
      </center></Cell>
    );
  }
}


var timeout = null;

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      username: '',
      password: '',
      first_name: '',
      is_active: true,
      modal_open: false,
      search: { branch: 0 },
      msg_error: {},
      activeItem: 'Active',
      branch_list: [],
      user_role: [],
      colSortDirs: {
        'id': 'ASC',
      },
      totalPages: 0,
      activePage: 1,
      count: 0,
    }
    this.columnKey = 'id'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitData = this.submitData.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.clearFormSearch = this.clearFormSearch.bind(this)
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.handlerSubmitSearch()

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  checkUser(username) {
    this.setState({ username_loading: true, username_error: false });
    if (username != null && username !== '') {
      Utility.get(Settings.baseUrl + '/users/?username=' + username, (status, data) => {
        this.setState({ username_loading: false });
        let msg_error = this.state.msg_error
        msg_error['username'] = data.length > 0 ? '*ชื่อผู้ใช้ซ้ำ' : null

        this.setState({
          msg_error: msg_error,
          button_class: ''
        });
      });
    }
  }

  resetPass() {
    let msg_error = {}
    if (this.state.password == '' || this.state.password == undefined || this.state.password == null) {
      msg_error['password'] = ['*กรุณาตั้งรหัสผ่าน']
    }
    if (this.state.password.length > 0 && this.state.password.length < 8) {
      msg_error['password'] = ['ความยาวอย่างน้อย 8 ตัวอักษร']
    }
    if (this.state.password2 == '' || this.state.password2 == undefined || this.state.password2 == null) {
      msg_error['password2'] = ['*กรุณายืนยันรหัสผ่าน']
    }
    if (this.state.password2.length > 0 && (this.state.password2 !== this.state.password)) {
      msg_error['password2'] = ['*กรุณายืนยันรหัสผ่านให้ตรงกัน']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error,
      })
      return;
    }

    let is_superuser = this.state.role == 'A' || this.state.role == 'S' ? 1 : 0;
    var user_profile = {
      user: {
        'password': this.state.password,
      }
    };
    user_profile.user['password'] = this.state.password

    this.setState({ button_class: 'loading' })
    const url = Settings.baseUrl + '/userprofiles/' + this.state.object_id + "/";
    Utility.putJson(url, user_profile, (status, data) => {
      if (status) {
        this.setState({
          modal_pass: false,
          button_class: '',
          password: '',
        });
        this.componentDidMount();
        // this.resetForm();
      }
    });
  }

  submitData() {

    let msg_error = {}

    if (this.state.modal_action == 'add') {
      // if (this.state.modal_action == 'add' || this.state.password == '' || this.state.password == undefined || this.state.password == null) {
      if (this.state.password == '' || this.state.password == undefined || this.state.password == null) {
        msg_error['password'] = ['*กรุณาตั้งรหัสผ่าน']
      }
      if (this.state.password.length > 0 && this.state.password.length < 8) {
        msg_error['password'] = ['ความยาวอย่างน้อย 8 ตัวอักษร']
      }
      if (this.state.password2 == '' || this.state.password2 == undefined || this.state.password2 == null) {
        msg_error['password2'] = ['*กรุณายืนยันรหัสผ่าน']
      }
      if (this.state.password2.length > 0 && (this.state.password2 !== this.state.password)) {
        msg_error['password2'] = ['*กรุณายืนยันรหัสผ่านให้ตรงกัน']
      }
      // }
    }

    if ((this.state.branch == null || this.state.branch == '') && this.state.branch_list.length == 0) {
      msg_error['branch'] = ['*กรุณาเลือกสาขา']
    }

    if (this.state.username == null || this.state.username == '') {
      msg_error['username'] = ['*กรุณาตั้งชื่อผู้ใช้งาน']
    }
    msg_error['username'] = this.state.msg_error.username
    var is_superuser = 0;
    if (this.state.role == null || this.state.role == '') {
      msg_error['role'] = ['*กรุณาเลือกระดับผู้ใช้งาน']

    } else {
      is_superuser = this.state.role == 'A' || this.state.role == 'S' ? 1 : 0;
    }

    if (Object.keys(msg_error).length > 0) {
      if (Object.keys(msg_error).length == 1 && msg_error['username'] == null) {
      } else {
        this.setState({
          msg_error: msg_error,
        })
        return;
      }
    }


    var user_profile = {
      user: {
        'username': this.state.username,
        'is_superuser': is_superuser,
        'is_active': this.state.is_active,
      },
      role: this.state.role,
      branch: this.state.branch,
      branch_list: this.state.branch_list,
      staff: this.state.staff == '' || this.state.staff == 0 ? null : this.state.staff
    };

    if (this.state.password != '')
      user_profile.user['password'] = this.state.password

    this.setState({ button_class: 'loading' })

    if (this.state.modal_action == 'add') {

      // create user profile
      const url = Settings.baseUrl + '/userprofiles/';
      Utility.postJson(url, user_profile, (status, data) => {
        if (status) {
          this.setState({
            modal_open: false
          });
        }
        this.componentDidMount();
        this.resetForm();
        alert('บันทึกข้อมูลสำเร็จ!')
      });
    } else {
      // create user profile
      const url = Settings.baseUrl + '/userprofiles/' + this.state.object_id + "/";

      Utility.putJson(url, user_profile, (status, data) => {
        if (status) {
          this.setState({
            modal_open: false
          });
          this.componentDidMount();
          this.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  resetForm() {
    this.setState({
      username: '',
      password: '',
      password2: '',
      branch: '',
      role: '',
      staff: '',
      search: { branch: 0, username: '' },
      msg_error: {},
      button_class: '',
      branch_list: [],
      is_active: true,
    });

    this.componentDidMount()
  }

  clearFormSearch() {
    this.setState({
      username: '',
      password: '',
      password2: '',
      branch: '',
      role: '',
      staff: '',
      search: { branch: 0, username: '' },
      msg_error: {},
      button_class: '',
      branch_list: [],
      is_active: true,
    });
  }

  componentDidMount() {
    const { auth } = this.props
    this.setState({ st: true });
    this.setState({ loader_active: true });
    let status = 1
    if (this.state.activeItem != 'Active') {
      status = 0
    }
    var userprofiles = Utility.getFetch(Settings.baseUrl + '/userprofiles/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&page=1&is_active=' + status);
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
    var branches_a = Utility.getFetch(Settings.baseUrl + '/branches/');
    var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1');
    var combinedData = { "apiRequest1": {}, "apiRequest2": {}, "apiRequest3": {} };
    Promise.all([userprofiles, branches, staffs, branches_a]).then((values) => {

      let branch_option = [{
        key: 0,
        value: 0,
        text: "- ทั้งหมด -",
      }];
      let branch_option2 = [];
      this.staffs = values[2]

      for (let i in values[1]) {
        branch_option.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        })
      }
      for (let i in values[3]) {
        branch_option2.push({
          key: values[3][i].id,
          value: values[3][i].id,
          text: values[3][i].name,
        })
      }
      let role_index = Settings.user_role.findIndex(role => role.value == this.props.auth.role)
      role_index = auth.role == 'M' ? role_index + 1 : role_index
      let user_role = Settings.user_role.slice(role_index)
      this.setState({
        options_branch: branch_option,
        branch_select: branch_option2,
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        activePage: 1,
        count: values[0].count,
        // activeItem: 'Active',
        user_role: user_role,
        st: false
      });
      return combinedData;
    });

  }

  setStaffOption() {
    var options_staff = [{
      key: 0,
      value: 0,
      text: '-ทั้งหมด-',
    }];
    let branches = []
    if (this.state.role == 'M') {
      for (let i in this.state.branch_list) {
        branches.push(this.state.branch_list[i])
      }
    } else {
      branches.push(this.state.branch)
    }
    for (let i in this.staffs) {
      if (this.state.role == 'M' || this.state.role == 'U') {
        if (branches.includes(this.staffs[i].branch.id)) {
          options_staff.push({
            key: this.staffs[i].id,
            value: this.staffs[i].id,
            text: this.staffs[i].name,
          });
        }
      } else {
        options_staff.push({
          key: this.staffs[i].id,
          value: this.staffs[i].id,
          text: this.staffs[i].name,
        });
      }
    }
    console.log(options_staff.length)
    this.setState({
      st: false,
      options_staff: options_staff
    })
  }

  setFieldValue(v) {
    let items = []
    for (let i in v) {
      if (v[i].role == 'M') {
        let name = ''
        if (v[i].user.is_active == true) {
          for (let b in v[i].branch_list) {
            if (v[i].branch_list[b].is_enabled == '1') {
              name = name + ',' + v[i].branch_list[b].name
            }
          }
        } else {
          for (let b in v[i].branch_list) {
            name = name + ',' + v[i].branch_list[b].name
          }
        }
        v[i].branch_name = name.substring(1)
      } else {
        if (v[i].branch.is_enabled == '0' && v[i].role != 'U') {
          v[i].branch_name = this.props.branches[0].name
        } else {
          v[i].branch_name = v[i].branch.name
        }
      }
      v[i].username = v[i].user.username
      v[i].staff_name = v[i].staff != null ? v[i].staff.name : ''
      v[i].role_name = v[i].role == 'A' ? 'Admin' : v[i].role == 'U' ? 'User' : v[i].role == 'C' ? 'Accountant' : v[i].role == 'M' ? 'Manager' : v[i].role == 'S' ? 'Superadmin' : ''
      if (v[i].role == 'M') {
        if (v[i].branch_name != '')
          items.push(v[i])
      } else {
        items.push(v[i])
      }
    }
    return items;
  }

  getBranch(user_id) {
    var obj = {};
    if (this.object_userprofiles[user_id] != null) {
      obj = this.object_userprofiles[user_id].branch;
    }
    return obj;
  }

  getUserProfile(user_id) {

    var obj = {};
    if (this.object_userprofiles[user_id] != null) {
      obj = this.object_userprofiles[user_id];
    }
    return obj;
  }

  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  handlerSearch(event, v) {

    if (v.name) {

      let search = this.state.search;

      search[v.name] = v.value;
      if (v.name == 'username') {
        search[v.name] = v.value.trim();
      }
      this.setState({
        search: search
      });
      return;
    } else {
      const target = event.target;
      const value = target.type == 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let search = this.state.search;
      search[name] = value;
      this.setState({
        search: search
      });
    }
  }

  handleItemClick = (e, { name }) => {
    let status = 1
    if (name === 'Active') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    let search = this.state.search;
    let str = Utility.jsonToQueryString(search);
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/userprofiles/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&page=1&is_active=' + status + '&' + str, (s, data) => {
      let items = [];
      for (let i in data.results) {
        items.push(data.results[i]);
      }
      this.setState({
        loader_active: false,
        items: this.setFieldValue(items),
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        activePage: 1,
        count: data.count,
      });
    })
    this.setState({ activeItem: name })
  }

  handlePaginationChange = (e, { activePage }) => {
    let status = 1
    if (this.state.activeItem === 'Active') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    let search = this.state.search;
    let str = Utility.jsonToQueryString(search);
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/userprofiles/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_active=' + status + '&page=' + activePage + '&' + str, (s, data) => {
      let items = [];
      for (let i in data.results) {
        items.push(data.results[i]);
      }
      this.setState({
        loader_active: false,
        items: this.setFieldValue(items),
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        activePage: activePage,
        count: data.count,
      });
    })
  }

  handlerSubmitSearch(e, data) {
    let page = data == undefined ? this.state.activePage : 1
    let search = this.state.search;
    let username = search['username'] ? search['username'] : ''
    let branch = search['branch']
    let active = this.state.activeItem == 'Active' ? 1 : 0
    if (search['username'] == '') {
      delete search['username']
    }
    if (search['branch'] == 0) {
      delete search['branch']
    }
    this.setState({ search: { branch: branch, username: username } })
    let str = Utility.jsonToQueryString(search);
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/userprofiles/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&page=' + page + '&is_active=' + active + '&' + str, (s, data) => {
      let items = [];
      for (let i in data.results) {
        items.push(data.results[i]);
      }
      this.setState({
        loader_active: false,
        items: this.setFieldValue(items),
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        activePage: page,
        count: data.count,
        // activeItem: 'Active',
      });
    })
  }

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit') {
      let branch_list = []
      row.branch_list.forEach(function (item, index) {
        branch_list.push(item.id)
      });
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        username: row.username,
        password: '',
        password2: '',
        branch: row.branch.id,
        branch_list: branch_list,
        role: row.role,
        object_id: row.id,
        is_active: row.user.is_active,
        staff: row.staff != null ? row.staff.id : ''
      }, () => {
        this.setStaffOption()
      });
    } else {
      this.handleDelete(e, row);
    }
  }

  handleDelete(e, user_profile) {

    if (window.confirm('ยืนยันลบผู้ใช้งานนี้')) {

      let url = Settings.baseUrl + "/userprofiles/" + user_profile.id + "/"
      Utility.delete(url, (s, r, code) => {

        if (s) {
          this.componentDidMount();
        } else {
          alert(r.error)
        }
      })

    }

  }

  render() {
    const items = this.state.items;
    const stateOptions = this.state.options_branch === null ? [] : this.state.options_branch;
    const optionBranch = this.state.branch_select === null ? [] : this.state.branch_select;
    const { auth } = this.props
    return (
      <div>
        <Form>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>ผู้ใช้งาน</Header>
            </Form.Field>
            <Form.Field width={10}>
              <Button id='btnAddUsers' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างผู้ใช้งาน',

                });
              }}
                floated='right' size='small' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>

        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='btnClose'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content className='scrolling'>
            <Modal.Description>
              <Form size='small'>
                {this.state.object_id == 1 ? '' : <Form.Field error={this.state.msg_error.role}>
                  <label>*ระดับ <MsgInput text={this.state.msg_error.role} /></label>
                  <Dropdown id='dropDownPosition' placeholder='ระดับ' defaultValue={this.state.role} search selection options={this.state.user_role} onChange={(e, data) => {
                    if (data.value == 'M') {
                      this.setState({ branch: '', branch_list: [] })
                    }
                    if (this.state.role == 'M' && data.value != 'M') {
                      this.setState({ branch: '', branch_list: [] })
                    }
                    this.setState({ role: data.value, role_error: false }, () => { this.setStaffOption() });
                  }} />
                </Form.Field>}

                <Form.Field error={this.state.msg_error.branch != null}>
                  <label>*สาขา <MsgInput text={this.state.msg_error.branch} /></label>
                  {this.state.role == 'M' ? <Dropdown id='dropDownBranch' ref={(input) => { this.branch_ref = input; }} placeholder='สาขา' search selection multiple options={optionBranch} defaultValue={this.state.branch_list} value={this.state.branch_list} onChange={(e, data) => {
                    this.setState({
                      branch_list: data.value,
                      branch_error: false
                    }, () => {
                      this.setStaffOption()
                    });
                  }} /> : <Dropdown id='dropDownBranch' ref={(input) => { this.branch_ref = input; }} placeholder='สาขา' search selection options={optionBranch} defaultValue={this.state.branch} value={this.state.branch} onChange={(e, data) => {
                    this.setState({
                      branch: data.value,
                      branch_error: false
                    }, () => {
                      this.setStaffOption()
                    })
                  }} />}

                </Form.Field>
                <Form.Field error={this.state.msg_error.username != null}>
                  <label>*ชื่อผู้ใช้ <MsgInput text={this.state.msg_error.username} /></label>
                  <Input id='InputUserName' fluid ref={(input) => { this.username_ref = input; }}
                    onChange={(e) => {
                      var username = e.target.value
                      this.setState({ username: username });
                      clearTimeout(timeout);
                      timeout = setTimeout(() => {
                        this.checkUser(username);
                      }, 1000);
                    }} value={this.state.username}
                    placeholder='ชื่อผู้ใช้'
                    maxLength={150}
                  />

                </Form.Field>
                {this.state.modal_action == 'add' ?
                  <Form.Field error={this.state.msg_error.password != null}>
                    <label>*รหัสผ่าน <MsgInput text={this.state.msg_error.password} /></label>
                    <Input id='InputPassword' ref={(input) => { this.password_ref = input; }} placeholder='รหัสผ่าน' onChange={(e) => {
                      this.setState({ password: e.target.value })
                      let msg_error = this.state.msg_error
                      if (e.target.value.length < 8) {
                        msg_error['password'] = 'ความยาวอย่างน้อย 8 ตัวอักษร'
                      } else {
                        msg_error['password'] = null
                      }
                      this.setState({
                        msg_error: msg_error
                      })
                    }} type="password" value={this.state.password}
                      maxLength={50} />
                  </Form.Field> : ''}
                {this.state.modal_action == 'add' ?
                  <Form.Field error={this.state.msg_error.password2}>
                    <label>*ยืนยันรหัสผ่าน <MsgInput text={this.state.msg_error.password2} /></label>
                    <Input id='InputPasswordAgain' onChange={(e) => {
                      this.setState({ password2: e.target.value })
                      let msg_error = this.state.msg_error
                      if (e.target.value !== this.state.password) {

                        msg_error['password2'] = 'รหัสผ่านไม่ตรงกัน'

                      } else {
                        msg_error['password2'] = null
                      }
                      this.setState({
                        msg_error: msg_error
                      })
                    }} type="password" value={this.state.password2} placeholder='ยืนยันรหัสผ่าน'
                      maxLength={50} />
                  </Form.Field> : ''}
                <Form.Field>
                  <label>พนักงาน</label>
                  <Dropdown id='dropDownStaff' placeholder='พนักงาน' defaultValue={this.state.staff} search selection options={this.state.options_staff} onChange={(e, data) => {
                    this.setState({ staff: data.value });
                  }}
                    loading={this.state.st} />
                </Form.Field>

                <Form.Field error={this.state.msg_error.is_active != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_active} /></label>
                  <Dropdown id='dropDownStatus' selection fluid onChange={(e, v) => this.setState({ is_active: v.value })} value={this.state.is_active} options={Settings.is_active_option} defaultValue={1} />

                </Form.Field>
                {this.state.modal_action == 'edit' ?
                  <Button id='btnResetPass'
                    size='small'
                    type='button'
                    onClick={(e) => {
                      // e.preventDefault();
                      this.setState({
                        modal_pass: true,
                        msg_error: {},
                        password: '',
                        password2: '',
                      });
                    }}>รีเซ็ตรหัสผ่าน</Button>
                  : ''}
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSaveUsers' size='small' primary onClick={(e) => {
              e.preventDefault();
              this.submitData();
            }}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancelUsers' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        {this.state.modal_pass ?
          <Modal open={this.state.modal_pass} size='tiny' /*dimmer='blurring'*/ >
            <Button
              id='btnClose'
              circular
              icon='close'
              basic
              floated='right'
              name=''
              onClick={() => this.setState({ modal_pass: false })} />
            <Modal.Header>{'แก้ไขรหัสผ่าน'}</Modal.Header>
            <Modal.Content className='scrolling'>
              <Modal.Description>
                <Form size='small'>

                  <Form.Field error={this.state.msg_error.password != null}>
                    <label>*รหัสผ่าน <MsgInput text={this.state.msg_error.password} /></label>
                    <Input id='InputPassword' ref={(input) => { this.password_ref = input; }} onChange={(e) => {
                      this.setState({ password: e.target.value })
                      let msg_error = this.state.msg_error
                      if (e.target.value.length < 8) {
                        msg_error['password'] = 'ความยาวอย่างน้อย 8 ตัวอักษร'
                      } else {
                        msg_error['password'] = null
                      }
                      this.setState({
                        msg_error: msg_error
                      })
                    }} type="password" value={this.state.password}
                      maxLength={50} />
                  </Form.Field>
                  <Form.Field error={this.state.msg_error.password2}>
                    <label>*ยืนยันรหัสผ่าน <MsgInput text={this.state.msg_error.password2} /></label>
                    <Input id='InputPasswordAgain' onChange={(e) => {
                      this.setState({ password2: e.target.value })
                      let msg_error = this.state.msg_error
                      if (e.target.value !== this.state.password) {

                        msg_error['password2'] = 'รหัสผ่านไม่ตรงกัน'

                      } else {
                        msg_error['password2'] = null
                      }
                      this.setState({
                        msg_error: msg_error
                      })
                    }} type="password" value={this.state.password2}
                      maxLength={50} />
                  </Form.Field>
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button id='btnSavePass' size='small' primary onClick={(e) => {
                e.preventDefault();
                this.resetPass();
              }}
                className={this.state.button_class}>บันทึก</Button>
              <Button id='btnCancelRepass' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_pass: false }); }}>ยกเลิก</Button>
            </Modal.Actions>
          </Modal>
          : ''
        }
        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <label>ชื่อผู้ใช้</label>
              <Input maxLength={255} id='InputSearchUsers' placeholder='ชื่อผู้ใช้' value={this.state.search.username} onChange={this.handlerSearch} name='username' />
            </Form.Field>
            <Form.Field width={5}>
              <label>สาขา</label>
              <Dropdown id='InputSearchBranch' placeholder='สาขา' search selection defaultValue={this.state.search.branch} value={this.state.search.branch} options={stateOptions} onChange={this.handlerSearch} name='branch' />
            </Form.Field>
            <Form.Field width={6}>
              <label><br /></label>
              <Button id='btnSearch' size='small' onClick={this.handlerSubmitSearch} type='button'><Icon name='search' /> ค้นหา</Button>
              <Button id='btnSearchAll' size='small' onClick={this.resetForm} type='button' >ทั้งหมด</Button>
              <Button id='clearSearch' size='small' onClick={this.clearFormSearch} type='button' >รีเซ็ต</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='Active'
            active={this.state.activeItem === 'Active'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Inactive'
            active={this.state.activeItem === 'Inactive'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} data={items} auth={auth} />}
            />
            <Column
              header={<SortHeaderCell
                className='text-center'
                onSortChange={this.onSortChange}
                field='id'
                sortDir={this.state.colSortDirs.id}>
                #ID
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top center' width={80} id='ID' data={items} field="id" textAlign='text-center' />
              }
              width={80}
            />

            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='user__username'
                sortDir={this.state.colSortDirs.user__username}>
                ชื่อผู้ใช้งาน
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="username" />
              }
              width={200}
            />
            <Column
              header={<Cell>ชื่อพนักงาน</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="staff_name" />
              }
              width={200}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='role'
                sortDir={this.state.colSortDirs.role}>
                ระดับ
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="role_name" />
              }
              width={200}
            />
            <Column
              header={<Cell>สาขา</Cell>}
              // header={<SortHeaderCell
              //   onSortChange={this.onSortChange}
              //   field='branch__name'
              //   sortDir={this.state.colSortDirs.branch__name}>
              //   สาขา
              // </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="branch_name" />
              }
              width={200}
            />
          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input
              id='amounttrans'
              label='จำนวน'
              placeholder=''
              className='text-right'
              width={2}
              value={Utility.numberFormat(this.state.count)} />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>
        </Form>


      </div>
    );
  }
}
const mapStateToProps = state => {

  return ({
    auth: state.auth,
    branches: state.branches,
  })
}
export default connect(
  mapStateToProps
)(Users)
