/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Grid, Table, Checkbox
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        var currentTime = new Date()
        this.state = {
            is_lease: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                month: currentTime.getMonth() + 1,
                year: currentTime.getFullYear(),
            },
            items: [],
            months: this.getMonth(),
            years: this.getYear()
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    getMonth() {
        let m = []
        for (let i = 1; i <= 12; i++) {
            m.push({
                value: i,
                key: i,
                text: i
            })
        }
        return m
    }

    getYear() {
        let d = new Date()
        let m = []
        for (let i = 2016; i <= d.getFullYear(); i++) {
            m.push({
                value: i,
                key: i,
                text: i
            })
        }
        return m
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var leases = Utility.getFetch(Settings.baseReportUrl + '/lease/?is_enabled=1&branch=' + this.state.search.branch);
        Promise.all([branches, leases]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.leases = values[1];
            let leases = []
            for (let i in this.leases) {
                leases.push({
                    value: this.leases[i].id,
                    key: this.leases[i].id,
                    text: this.leases[i].number
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                leases: leases
            });
        });
    }

    resetForm(e) {
        var currentTime = new Date()
        this.setState({
            is_lease: true,
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                month: currentTime.getMonth() + 1,
                year: currentTime.getFullYear(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        if (search.month == null) {
            alert('กรุณาเลือกเดือน')
            return
        }

        if (search.year == null) {
            alert('กรุณาเลือกปี')
            return
        }

        if (this.state.is_lease)
            this.setState({
                open: true
            })
        else
            this.setState({
                open2: true
            })

        search.start_date = search.year + '-' + search.month + '-01'
        let last_date = new Date(search.year, Number(search.month), 0).getDate()
        search.end_date = search.year + '-' + search.month + '-' + last_date.toString().padStart(2,'0')
        delete search.month
        delete search.year
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/lease_report_2/?" + fromData

        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.lease, d.lease_product, d.customer)
                })
            }
        })
    }

    setFieldValue(_items, lease_products, customers) {
        let items = []
        let no = 1
        for (let i in _items) {
            let item = _items[i]
            let lease_product = []
            const customer = customers.find(k => k.id === item.customer)

            for (let j = 0; j < lease_products.length; j++) {
                if (lease_products[j].lease == item.id) {
                    lease_product.push(lease_products[j])
                }
            }

            item['lease_product'] = lease_product
            item['customer'] = customer

            for (let [index, product] of item.lease_product.entries()) {
                if (index === 0) {
                    items.push({
                        'detail': item,
                        'products': product,
                        'head': true,
                        'number': no++,
                    })
                }
                else {
                    items.push({
                        'detail': item,
                        'products': product,
                        'head': false,
                        'number': no++,
                    })
                }
            }
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {

        let title = 'รายงานยอดขายฝาก';
        let filename = 'lease-' + this.state.search.month + '-' + this.state.search.year;

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const divStyle2 = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
            border: '1px solid black',
        };
        const textRight2 = {
            'text-align': 'right',
            border: '1px solid black',
        }

        const textLeft2 = {
            'text-align': 'left',
            border: '1px solid black',
        }
        const textCenter2 = {
            'text-align': 'center',
            border: '1px solid black',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }


        let amount = 0
        let total_interest = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานยอดขายฝาก,ดอกเบี้ย,รายเดือนตามปีที่ระบุ</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>

                            <Grid divided='vertically'>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Checkbox radio label='ยอดขายฝาก' checked={this.state.is_lease} onChange={(e, v) => { this.setState({ is_lease: true }) }} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox radio label='ดอกเบี้ย' checked={!this.state.is_lease} onChange={(e, v) => { this.setState({ is_lease: false }) }} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br />
                            <Form.Field>
                                <label>เดือน</label>
                                <Dropdown search selection options={this.state.months} value={this.state.search.month} name='month' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ปี</label>
                                <Dropdown search selection options={this.state.years} value={this.state.search.year} name='year' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Button type='button' onClick={(e) => {

                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='date'>ประจำเดือน : {this.state.search.month} ปี {this.state.search.year}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันครบกำหนด</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนเงิน</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                            if (item.head === true) {
                                                amount += parseInt(item.detail.amount)
                                            }
                                            if (item.head === true) {
                                                return (<Table.Row>
                                                    <Table.Cell style={textCenter}>{item.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{item.detail.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.start_date)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.end_date)}</Table.Cell>
                                                    <Table.Cell style={textLeft} width={3}>{item.detail.customer.name}</Table.Cell>
                                                    <Table.Cell style={textLeft} width={3}>{item.products.product_name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.amount)}</Table.Cell></Table.Row>)
                                            } else {
                                                return (<Table.Row>
                                                    <Table.Cell colSpan='5' style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}>{item.products.product_name}</Table.Cell>
                                                </Table.Row>)
                                            }
                                        })}

                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='6'><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='amount'>{Utility.priceFormat(amount)}</b></div></Table.Cell></Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.state.open2} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print2'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>รายงานยอดดอกเบี้ย</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>ประจำเดือน : {this.state.search.month} ปี {this.state.search.year}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันครบกำหนด</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนเงิน</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                            if (item.head === true) {
                                                total_interest += parseInt(item.detail.total_interest)
                                            }
                                            if (item.head === true) {
                                                return (<Table.Row>
                                                    <Table.Cell style={textCenter}>{item.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{item.detail.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.start_date)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.end_date)}</Table.Cell>
                                                    <Table.Cell style={textLeft} width={3}>{item.detail.customer.name}</Table.Cell>
                                                    <Table.Cell style={textLeft} width={3}>{item.products.product_name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.total_interest)}</Table.Cell></Table.Row>)
                                            } else {
                                                return (<Table.Row>
                                                    <Table.Cell colSpan='5' style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}>{item.products.product_name}</Table.Cell>
                                                </Table.Row>)
                                            }
                                        })}

                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='6'><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='total_interest'>{Utility.priceFormat(total_interest)}</b></div></Table.Cell></Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print2').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open2: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)