/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button, Modal, Input, Dimmer, Loader, Table, Header, Label, FormGroup, FormField
} from 'semantic-ui-react';
import SelectOption from '../../SelectOption'
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import { hotkeys } from 'react-keyboard-shortcuts'
import { connect } from 'react-redux'

class DefectiveForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type_form: 'all',
      product_code: '',
      product_id: '',
      product: null,
      product_type: '',
      product_type_id: '',
      product_name: '',
      amount: '',
      discount: '',
      sell_weight_real_g: '',
      product_price: '',
      sell_price: '',
      products: [],
      buy_categories_id: '',
      buy_product_id: '',
      buy_weight_real_g: '',
      buy_weight_real_b: '',
      buy_price: '',
      exchange: 0,
      msg_error: '',
      is_loading: false,
      record_date: moment(),
      category_id: '',
      category_name: '',
      // product_options: this.props.formValue.product_options,
      max: 0,
      gold_bar_sell: '',
      gold_ornaments_buy: '',
      QRCODE: '',
      QRCODE2: '',
      detail: '',
      status_stock_display: '',
      is_submit_qrcode: false,
    }
    this.handlerQRCODE = this.handlerQRCODE.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.handlerReset = this.handlerReset.bind(this);
  }

  componentDidMount() {
  }

  handlerQRCODE(e, d) {
    if (this.state.is_submit_qrcode) {
      this.setState({
        category_name: '',
        product_name: '',
        sell_weight_real_g: '',
        detail: '',
        QRCODE: '',
      })
    }
    this.setState({ QRCODE: d.value });
  }

  submitQRcode() {
    let data = []
    data['code'] = this.state.QRCODE.trim()
    data['stock_type'] = 'new'
    data['branch'] = this.props.auth.branch.id

    this.setState({ is_submit_qrcode: true })

    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + '/qrcode?' + qrstring);
    Promise.all([qrcode]).then((values) => {
      if (values[0].detail === undefined) {
        this.setState({ category_id: values[0].stock_product.product.category.id })
        this.setState({ category_name: values[0].stock_product.product.category.name })
        this.setState({ product_type_id: values[0].stock_product.product.kind.id })
        this.setState({ product_type: values[0].stock_product.product.kind.name })
        this.setState({ product_id: values[0].stock_product.product.id })
        this.setState({ product: values[0].stock_product.product })
        this.setState({ product_name: values[0].stock_product.product.name })
        this.setState({ sell_weight_real_g: values[0].stock_product.product.weight })
        this.setState({ QRCODE2: values[0].qrcode })
        this.weightRealGInput.focus()
      } else {
        this.setState({
          category_name: '',
          product_name: '',
          sell_weight_real_g: '',
          detail: '',
          QRCODE: '',
        })
        alert(values[0].detail)
      }

    })
  }

  handlerReset(e, d) {
    e.preventDefault();
    this.setState({
      product_code: '',
      product_id: '',
      product: null,
      product_type: '',
      amount: '',
      discount: '',
      sell_weight_real_g: '',
      product_price: '',
      sell_price: '',
      product_name: '',
      buy_categories_id: '',
      buy_product_id: '',
      buy_weight_real_g: '',
      buy_weight_real_b: '',
      buy_price: '',
      exchange: 0,
      is_submit_qrcode: false
    })
  }

  handlerSubmit(e, d) {
    e.preventDefault();

    if (this.state.QRCODE == '' || this.state.category_name == '' || this.state.sell_weight_real_g == '') {
      alert('กรุณาเพิ่มสินค้า')
      return;
    }

    for (let i = 0; i < this.props.billItems.length; i++) {
      if (this.props.billItems[i].qrcode === this.state.QRCODE.trim()) {
        alert('ได้ทำการเลือกสินค้านี้แล้ว')
        this.setState({
          category_name: '',
          product_name: '',
          sell_weight_real_g: '',
          detail: '',
          QRCODE: '',
        })
        return;
      }
    }

    const code_change = (new Date()).getTime()

    let sell = ''
    let buy = ''
    let defective = ''

    defective = {
      product_code: this.state.product_code,
      product_id: this.state.product_id,
      product: this.state.product,
      product_type: this.state.product_type,
      amount: this.state.amount,
      discount: this.state.discount,
      sell_weight_real_g: this.state.sell_weight_real_g,
      product_price: this.state.product_price,
      sell_price: this.state.sell_price,
      gold_price: this.state.gold_bar_sell,
      exchange: this.state.exchange,
      id_change: code_change,
      qrcode: this.state.QRCODE2,
      detail: this.state.detail
    }

    this.props.onSubmit(e, {
      sell: sell,
      buy: buy,
      defective: defective,
    });

    this.props.onClose()
    this.handlerReset(e);
  }



  render() {
    const { auth } = this.props

    return (
      <Modal open={this.props.open} /*dimmer='blurring'*/>
        <Button id="btnCloseProductSelect" circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header id='headerModalSelectProduct' as='h3'>เลือกรายการสินค้า</Modal.Header>
        <Modal.Content id='contentModalSelectProduct' >
          <Dimmer active={this.state.is_loading} inverted>
            <Loader inverted content='Loading' />
          </Dimmer>
          <Modal.Description>

            <div>
              <div className={this.props.kind == 'BU' ? 'hidden' : ''}>
                <Message id='selectForDefective'
                  color='grey'
                  attached
                  size='tiny'
                  header='ทองชำรุด'
                />
                <Form className='attached fluid segment grey' color='red' size='small' onSubmit={(e) => {
                  this.handlerSubmit(e)
                }}>
                  <FormGroup>
                    <FormField width={8}>
                      <label>* QR Code </label>
                      <Form.Input
                        autoFocus
                        placeholder='Qrcode'
                        value={this.state.QRCODE}
                        onChange={this.handlerQRCODE}
                        onKeyPress={(e) => {

                          if (e.key == 'Enter') {
                            this.submitQRcode()
                            e.preventDefault()
                          }

                        }}
                        className='text-right' />
                    </FormField>
                  </FormGroup>
                  <Form.Group>
                    <Form.Field width={4} >
                      <label>*%ทอง </label>
                      <Input
                        value={this.state.category_name}
                        placeholder='%ทอง'
                        readOnly
                        className='text-right' />

                    </Form.Field>

                    <Form.Field width={4} >
                      <label>*ชื่อสินค้า</label>
                      <Input
                        value={this.state.product_name}
                        placeholder='ชื่อสินค้า'
                        readOnly
                        className='text-right' />

                    </Form.Field>

                    <Form.Field width={6} >
                      <label>*น.น.ชั่ง(ก.)</label>
                      <Input readOnly value={this.state.sell_weight_real_g.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        // type='number'
                        ref={(c) => {
                          this.weightRealGInput = c
                        }}
                        onFocus={(e) => { e.target.select() }}
                        placeholder='น.น.ชั่ง(ก.)'
                        onChange={this.handlerChangeWeightReal}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter') {
                            e.preventDefault()
                            this.handlerSubmit(e)
                          }
                        }}
                        className='text-right' />

                    </Form.Field>

                  </Form.Group>

                  <Form.Group>
                    <Form.TextArea label='รายละเอียดทอง' placeholder='รายละเอียดทอง' width={6} value={this.state.detail} onChange={(e, d) => {
                      this.setState({
                        detail: d.value
                      })
                    }}
                    />
                  </Form.Group>
                  <div className='hidden' >
                    <Button type='submit' />
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button id="submitDeffectiveSelect" type='submit' size='small' primary onClick={this.handlerSubmit} >ลงรายการ</Button>
        </Modal.Actions>
      </Modal>

    );
  }

  hot_keys = {
    'esc': {
      priority: 4,
      handler: this.props.onClose
    },
    'enter': {
      priority: 4,
      handler: (e) => {
        // e.preventDefault()
        // this.handlerSubmit(e)
      }
    }
  }
}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(hotkeys(DefectiveForm))
