/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import {Form, Button, Dropdown, Input, Modal} from 'semantic-ui-react';
import MsgInput from '../../../Error/MsgInput'


class CategoryForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            msg_error: {},
            product_weight: '',
            price_sell: '',
            price_diff: '',
            total_sell: '',
            cost: '',
            total_diff: '',
            average: '',
        }

        this.handleSelectCategory = this
            .handleSelectCategory
            .bind(this);
        this.handleProductWeight = this
            .handleProductWeight
            .bind(this)
        this.handlePriceSell = this.handlePriceSell.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSelectCategory(e, data) {

        let item = Utility.getObject(this.stock_category, data.value);
        let d = parseFloat(item.category.weight)
        let average = item.total == 0
            ? 0
            : Utility.priceFormat(parseFloat(item.total) / (parseFloat(item.weight) / d))
        this.setState({
            stock_category: data.value,
            product_weight: item.weight,
            max: item.weight,
            average: average,
            total_average: parseFloat(average) * (item.weight / d)
        });

        this.productWeightInput.focus()
    }

    resetFormProduct() {
        this.setState({
          stock_category: '',
          product_weight: '',
          average: '',
          total_average: '',
          price_sell: '',
          price_diff: '',
          total_sell: '',
          total_diff: '',
          cost: '',
          product_action_title: 'เพิ่มสินค้า',
          product_action: 'add',
          msg_error: {}
        });
      }

    handleSubmit(e){

        let msg_error = {}

        if (this.state.stock_category == null || this.state.stock_category == '') {
            msg_error['stock'] = ['เลือกเปอร์เซ็นต์ทองเก่า']
        }
        if (this.state.product_weight == null || this.state.product_weight == '') {
            msg_error['weight'] = ['*ต้องไม่เป็นค่าว่าง']
        }

        if (this.state.price_sell == null || this.state.price_sell == '') {
            msg_error['price'] = ['*ต้องไม่เป็นค่าว่าง']
        }
        if (this.state.cost === undefined || this.state.cost === null || this.state.cost ===''){
            msg_error['cost'] = ['*ต้องไม่เป็นค่าว่าง']
        }
        if (this.state.cost < 0){
            msg_error['cost'] = ['*ต้องไม่ติดลบ']
        }
        this.setState({
            msg_error: msg_error
        })
        if (Object.keys(msg_error).length > 0) {
            return false;
        }

        this.props.onSubmit(e,this.state)
    }

    handleProductWeight(e, v) {
        v.value = v.value.toString().replace(/,/g,'')
        if(parseFloat(v.value)>=0){
            if (isNaN(Number(v.value))) {
            } else {
                this.setState({product_weight: v.value})
                if(this.state.price_sell)
                    this.setPrice(parseFloat(this.state.price_sell))
            }
        }
            
    }

    handlePriceSell(e, data) {
        data.value = data.value.toString().replace(/,/g,'')
        if (isNaN(Number(data.value))) {
        } else {
            if (data.value.length === 0) {
                this.setPrice(parseFloat(0))
            } else {
                this.setPrice(parseFloat(data.value))
            }
            
        }
        
    }

    setPrice(price_sell){
        let average = Utility.removeCommas(this.state.average);
        let product_weight = parseFloat(this.state.product_weight);
    
        let item = Utility.getObject(this.stock_category, this.state.stock_category);
        this.setState({price_sell})
        if(item){
            let d = parseFloat(Utility.removeCommas(item.category.weight))
            this.setState({
            price_sell: price_sell,
            price_diff: Utility.priceFormat(price_sell - parseFloat(average)),
            total_sell: Utility.priceFormat(price_sell * (product_weight / d)),
            total_diff: Utility.priceFormat((price_sell - parseFloat(average)) * (product_weight / d)),
            total_average: parseFloat(average) * (product_weight / d)
            });
        }
    }

    componentDidMount() {
        this.setState({ cost: ''})
        var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_category/?is_enabled=1&branch=' + this.props.branch);
        Promise
            .all([stock_category])
            .then((values) => {
                this.stock_category = values[0];

                let stock_category_options = [];
                for (let i in values[0]) {
                    stock_category_options.push({key: values[0][i].id,
                        value: values[0][i].id,
                        text: values[0][i].category.name
                    });
                }
                this.setState({stock_category_options: stock_category_options});
                this.setState({
                    table_width: document
                        .getElementById('table_width')
                        .clientWidth,
                    table_hiegh: window.innerHeight - 400
                });
            });
    }

    render() {
        return (
            <Modal size='mini' open={true} onClose={this.props.onClose} /*dimmer='blurring'*/
>
                <Button
                    id='closeaddproduct'
                    circular
                    icon='close'
                    basic
                    floated='right'
                    name=''
                    onClick={this.props.onClose}/>
                <Modal.Header>
                    {this.props.title}
                </Modal.Header>
                <Modal.Content>
                    <Form className='fluid' size='small'>
                        <Form.Field error={this.state.msg_error.stock != null} width={16}>
                            <label>เปอร์เซ็นต์ทองเก่า
                                <MsgInput text={this.state.msg_error.stock}/></label>
                            <Dropdown
                                id='percent'
                                search
                                selection
                                options={this.state.stock_category_options}
                                defaultValue={this.state.stock_category}
                                value={this.state.stock_category}
                                onChange={this.handleSelectCategory}/>
                        </Form.Field>
                        <Form.Field width={16} error={this.state.msg_error.weight != null}>
                            <lable>น.น.ทองเก่า(กรัม)
                                <MsgInput text={this.state.msg_error.weight}/></lable>
                            <Input
                                id='weight'
                                as='div'
                                basic
                                min={0}
                                max={this.state.max}
                                // type='number'
                                className='text-right'
                                onFocus={(e)=> e.target.select()}
                                ref={(c)=>this.productWeightInput=c}
                                value={this.state.product_weight.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                onChange={this.handleProductWeight}
                                onKeyPress={(e)=> {
                                    if(e.key == 'Enter'){
                                        this.priceInput.focus()
                                    } 
                                }}
                                />
                        </Form.Field>
                        <Form.Field width={16} error={this.state.msg_error.average != null}>
                            <lable>ราคาทองเก่าเฉลี่ยบาทละ
                                <MsgInput text={this.state.msg_error.average}/></lable>
                            <Input id='average' className='text-right' value={this.state.average.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} readOnly></Input>
                        </Form.Field>
                        <Form.Field width={16} error={this.state.msg_error.price != null}>
                            <lable>ราคาที่ตัด/บาท
                                <MsgInput text={this.state.msg_error.price}/></lable>
                            <Input
                                id='price'
                                className='text-right'
                                ref={(c)=>this.priceInput = c}
                                onChange={this.handlePriceSell}
                                value={this.state.price_sell.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                onKeyPress={(e)=> {
                                    if(e.key == 'Enter'){
                                        this.costInput.focus()
                                    } 
                                }} />
                        </Form.Field>
                        <Form.Field width={16}>
                            <lable>ส่วนต่าง/บาท
                            </lable>
                            <Input id='pricediff' value={this.state.price_diff.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right'/>
                        </Form.Field>
                        <Form.Field width={16}>
                            <lable>ราคาที่ตัดรวม
                            </lable>
                            <Input id='totalsell' value={this.state.total_sell.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right'/>
                        </Form.Field>
                        <Form.Field width={16} error={this.state.msg_error.cost != null}>
                            <lable>ค่าหลอม/บาท
                                <MsgInput text={this.state.msg_error.cost}/></lable>
                            <Input
                                id='cost'
                                // type = 'number'
                                className='text-right'
                                value={this.state.cost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                ref={(c)=>this.costInput=c}
                                onKeyPress={(e)=> {
                                    if(e.key == 'Enter'){
                                        this.handleSubmit(e)
                                    } 
                                }}
                                onChange={(e, d) => {
                                    d.value = d.value.toString().replace(/,/g,'')
                                    if (isNaN(Number(d.value))) {
                                    } else {
                                        this.setState({cost: d.value})
                                    }
                                
                                
                            }}/>
                        </Form.Field>
                        <Form.Field width={16}>
                            <lable>ส่วนต่างรวม</lable>
                            <Input id='totaldiff' value={this.state.total_diff.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right'/>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>

                    <Button
                        id='btnClear'
                        size='small'
                        disabled={this.state.invoice_add_product_disabled}
                        className={this.state.button_product_loading}
                        onClick={(e) => {
                        e.preventDefault();
                        this.resetFormProduct();
                    }}>เริ่มใหม่</Button >
                    <Button
                        id='confirmadd'
                        size='small'
                        primary
                        className={this.state.button_product_loading}
                        onClick={this.handleSubmit}>เพิ่ม</Button>
                </Modal.Actions>
            </Modal >
        );
    }
}

export default CategoryForm