/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import OptionItemsCell from '../OptionItemsCell'
import RewardForm from '../RewardForm'
import {
  Form,
  Button,
  Confirm, Dimmer, Loader,
  Header,
  Pagination,
  Image,
  Input,
  Icon,
  Dropdown,
  Label,
  Popup,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}
class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    var v = data[rowIndex][field]
    if (this.props.type && this.props.type == 'number')
      v = Utility.numberFormat(v)
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={v}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{v}</div>}
        />
      </Cell>
    );
  }
}

class StatusItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, field2, data, ...props } = this.props;
    var v = data[rowIndex][field]
    var k = data[rowIndex][field2]
    if (this.props.type && this.props.type == 'number')
      v = Utility.numberFormat(v)
    return (
      <Cell {...props}>
        <div className='text-center'>
          <Label color={k == '1' ? 'blue' : 'grey'} horizontal>
            {v}
          </Label>
        </div>
      </Cell>
    );
  }
}

class ImageItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props}>
        <Image src={data[rowIndex][field].small_square_crop} size='mini' verticalAlign='top' />
      </Cell>
    );
  }
}

class RewardList extends Component {
  constructor(props) {
    super(props);
    this.limit = 10
    this.state = {
      msg_error: {},
      items: [],
      activePage: 1,
      reward: {},
      text: '',
      is_enabled: 2
    }

    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.loadDataList(activePage)
  }

  componentWillMount() {

    this.loadDataList(this.state.activePage)
    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  loadDataList(activePage) {

    activePage = activePage < 1 ? 1 : activePage

    let q = Utility.jsonToQueryString({
      page: activePage,
      page_size: this.limit,
    })

    if (this.state.text != '')
      q = q + '&text=' + this.state.text.trim()
    if (this.state.is_enabled != 2)
      q = q + '&is_enabled=' + this.state.is_enabled
    let url = Settings.baseUrl + '/reward/?' + q
    Utility.get(url, (status, res, code) => {
      if (status)
        this.setState({
          items: res.results,
          totalPages: res.next == null && res.previous == null ? 0 : Math.ceil(res.count / this.limit),
        })
    })
  }


  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.setState({
        modal_open: true,
        modal_title: 'แก้ไข',
        modal_action: 'edit',
        reward: data
      })
    } else {

      if (window.confirm('ยืนยันลบ')) {
        const url = Settings.baseUrl + '/reward/' + data.id + "/";
        Utility.delete(url, (status, data, status_code) => {
          if (status) {
            this.loadDataList(1);
          } else {
            alert(data.error)
          }
        });
      }
    }
  }

  render() {
    const items = this.state.items
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>รายการของรางวัล</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างรายการของรางวัล'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>

        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        {this.state.modal_open && <RewardForm
          reward={this.state.reward}
          onClose={() => this.setState({ modal_open: false, activePage: 1 })}
          action={this.state.modal_action} onSaved={() => {
            this.setState({ modal_open: false, activePage: 1 })
            this.loadDataList(1)
          }} />}

        <Form size='small'>
          <Form.Group>
            <Form.Field width={8}>
              <Input icon='search' iconPosition='left' placeholder='รหัส, ชื่อ, คะแนน' onChange={(e, v) => this.setState({ text: v.value })} value={this.state.text} name='text' />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option_search} defaultValue={1} />
            </Form.Field>
            <Form.Field width={4}>
              <Button size='small' floated='right' onClick={(e) => {
                this.setState({ text: '', is_enabled: 2 })
                this.state.text = ''
                this.state.is_enabled = 2
                this.loadDataList(1)
              }} type='button' >ทั้งหมด</Button>
              <Button size='small' onClick={(e) => {

                this.loadDataList(1)
              }} floated='right' type='submit'><Icon name='search' />ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={60}
          headerHeight={35}
          width={this.state.table_width}
          height={387}>
          <Column
            width={80}

            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell onClickMenu={this.handleClick} />}
          />
          <Column
            header={<Cell>รหัส</Cell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="code" />
            }
            width={80}
          />
          <Column
            header={<Cell>รูปภาพ</Cell>}
            cell={
              <ImageItemsCell data={items} field="image" />
            }
            width={70}
          />
          <Column
            header={<Cell>ชื่อ</Cell>}
            cell={
              <ItemsCell position='top left' width={300} data={items} field="name" />
            }
            width={300}
          />
          <Column
            header={<Cell className='text-right'>คะแนน</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} textAlign='text-right' field="score" type='number' />
            }
            width={120}
          />
          <Column
            header={<Cell className='text-center'>สถานะ</Cell>}
            cell={
              <StatusItemsCell data={items} textAlign='text-center' field="is_enabled_display" field2="is_enabled" />
            }
            width={120}
          />
        </Table></div>
        <br />
        <Pagination
          activePage={this.state.activePage}
          onPageChange={this.handlePaginationChange}
          totalPages={this.state.totalPages}
        />
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/banks/' + this.state.object_id + "/";
            Utility.delete(url, (status, data) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.componentDidMount();
                this.resetForm();
              } else {
                alert(data.error)
              }
            });

          }}
        />
      </div>

    );
  }
}

export default RewardList;
