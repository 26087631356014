/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import {
  Table,
  Column,
  Cell,
} from 'fixed-data-table';

import {
  Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid,
  Popup,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import StockWaitMeltTable from './StockWaitMeltTable'

class ItemsCell extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props} >
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            ['weight'].includes(field) ?
              <div style={{ ...ellipsisStyle, color: data[rowIndex]['kind'] === 'IM' ? 'green' : 'red' }} className={this.props.textAlign}>{data[rowIndex][field]}</div>
              : <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>
          }
        />
      </Cell>
    );
  }
}
class StockGold99Log extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      table_heigh: Settings.table_heigh,
      number: '',
    }
  }


  componentDidMount() {
    this.setState({
      loader: true
    });
    var items = Utility.getFetch(Settings.baseUrl + '/gold_99/stock/' + this.props.id + '/log/');
    Promise.all([items]).then((values) => {
      this.setState({
        number: values[0].stock.number,
        items: this.setFieldValue(values[0].log),
        loader: false,
      })
    })
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth, table_heigh: window.innerHeight - 380 });
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  setFieldValue(items) {

    for (let i = 0; i < items.length; i++) {
      items[i]['kind_display'] = items[i]['kind'] === 'IM' ? 'นำเข้า' : 'นำออก'
      items[i]['weight'] = Utility.weightFormat(items[i]['weight'])
      items[i]['weight_remain'] = Utility.weightFormat(items[i]['weight_remain'])
      items[i]['record_datetime'] = Utility.formatDate(items[i]['record_datetime'])
    }
    return items;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    const items = this.state.items;
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name=''
          onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              <span id='textBillID'>{this.state.number}</span>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div>
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Table
                  rowsCount={items.length}
                  rowHeight={35}
                  headerHeight={35}
                  width={this.state.table_width}
                  height={300}>
                  <Column
                    header={<Cell className='text-left'>
                      เลขที่บิล
                    </Cell>}
                    cell={
                      <ItemsCell position='top left' width={150} id='number' data={items} field="number" />
                    }
                    width={150}
                  />
                  <Column
                    header={<Cell className='text-center'>
                      วันที่เกิดรายการ
                    </Cell>}
                    cell={
                      <ItemsCell position='top center' width={150} data={items} field="record_datetime" textAlign='text-center' />
                    }
                    width={150}
                  />
                  <Column
                    header={<Cell className='text-right'>
                      ประเภท
                    </Cell>}
                    cell={
                      <ItemsCell position='top right' width={150} data={items} field="kind_display" textAlign='text-right' />
                    }
                    width={150}
                  />
                  <Column
                    header={<Cell className='text-right'>
                      น้ำหนักที่ทำรายการ
                    </Cell>}
                    cell={
                      <ItemsCell position='top center' width={150} data={items} field="weight" textAlign='text-right' />
                    }
                    width={150}
                  />
                  <Column
                    header={<Cell className='text-right'>
                      น้ำหนักคงเหลือ
                    </Cell>}
                    cell={
                      <ItemsCell position='top right' width={150} data={items} field="weight_remain" textAlign='text-right' />
                    }
                    width={150}
                  />
                  <Column
                    header={<Cell className='text-center'>
                      ผู้ทำรายการ
                    </Cell>}
                    cell={
                      <ItemsCell position='top center' width={160} data={items} field="username" textAlign='text-center' />
                    }
                    width={160}
                  />
                </Table>
                <br />
                <Form size='small'>
                  <Form.Group>
                    <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.items.length)} />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

        </Modal.Content>
      </Modal>

    );
  }
}


export default StockGold99Log;