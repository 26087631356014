/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
// import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button,
  Modal,
  Input,
  Dimmer,
  Loader,
  Table,
  Header,
  Label,
  FormGroup,
  FormField,
  Icon,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
// import Utility from '../../../Utility';
// import Settings from '../../../Settings';
import moment from "moment";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import InputSearchCustomer from "../Customer/InputSearchCustomer";
import CustomerModal from "../Customer/CustomerModal";
import Utility from "../../Utility";
import Settings from '../../Settings';
import AccountDetail from './AccountDetail'

class OpenAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      citizen_id: ""
    };
    this.handlerCustomer = this.handlerCustomer.bind(this);
    this.handlerAddCustomer = this.handlerAddCustomer.bind(this);
    this.handlerCreateCustomer = this.handlerCreateCustomer.bind(this);
    this.submitData = this.submitData.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }

  componentDidMount() {
    var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1&branch=' + this.props.auth.branch.id);
    Promise.all([staffs]).then((values) => {

      this.staffs = values[0];
      let staffs = []
      for (let i in this.staffs) {
        staffs.push({
          value: this.staffs[i].id,
          key: this.staffs[i].id,
          text: this.staffs[i].name
        });
      }

      this.setState({
        staffs: staffs,
        st: false
      });
    });
  }

  handlerCustomer(e, d) {
    let data = {};
    for (let i in d.options) {
      if (d.options[i].value == d.value) {
        data = d.options[i].data;
        break;
      }
    }
    this.setState({
      customer: data,
      customer_id: data.id,
      name: data.name,
      code: data.code,
      citizen_id: data.citizen_id,
      phone: data.mobile === null ? "" : data.mobile,
    });
  }
  handlerAddCustomer(e) {
    e.preventDefault();
    this.setState({
      modal_open: true,
    });
  }
  handlerCreateCustomer(data) {
    this.setState({
      modal_open: false,
      customer_id: data.id,
      citizen_id: data.citizen_id,
      customer: data.id,
      name: data.name,
      code: data.code,
      phone: data.mobile === null ? "" : data.mobile,
    });
  }
  submitData() {
    if (this.state.customer_id == undefined || this.state.customer_id == null) {
      alert('กรุณาเลือกลูกค้า')
      return
    }
    if (this.state.citizen_id == undefined || this.state.citizen_id == null || this.state.citizen_id == '') {
      alert('กรุณากรอกเลขบัตรประชาชน')
      return
    }
    if (this.state.name == undefined || this.state.name == null || this.state.name == '') {
      alert('กรุณากรอกชื่อบัญชี')
      return
    }
    if (this.state.amount == undefined || this.state.amount == null || this.state.amount == '') {
      alert('กรุณากรอกจำนวนเงินเปิดบัญชี')
      return
    }
    if (this.state.staff == undefined || this.state.staff == null || this.state.staff == '') {
      alert('กรุณาเลือกพนักงาน')
      return
    }
    var formData = {
      customer: this.state.customer_id,
      name: this.state.name,
      staff: this.state.staff,
      citizen_id: this.state.citizen_id.toString(),
      amount: Number(this.state.amount),
      remark: this.state.description,
      branch: this.props.auth.branch.id,
    };
    const url = Settings.baseUrl + '/savings/open/';
    Utility.post(url, formData, (status, data) => {
      if (status) {
        alert('บันทึกข้อมูลสำเร็จ!');
        this.setState({modal_saving:true,saving:data.saving})
      }
      else {
        alert(data.detail)
      }
    })
  }
  handlerInput(e, v) {
    this.setState({ staff: v.value })
  }
  render() {
    const { auth } = this.props;

    return (
      <div>
        {(this.state.modal_saving) && (
              <AccountDetail
                items={this.state.saving}
                onClose={() => {
                  this.setState({ modal_saving: false });
                  this.props.onClose()
                }}
                open={true}
              />
          )}
      <Modal open={this.props.open} size="mini" /*dimmer='blurring'*/>
        <Button
          id="btnCloseProductSelect"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header>เปิดบัญชี</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size="small">
              <Form.Field>
                <Form.Field width={16}>
                  <label>ลูกค้า</label>
                  <div className="input-search-customer">
                    <InputSearchCustomer
                      fluid
                      placeholder="พิมพ์ชื่อลูกค้า"
                      width={13}
                      onChange={this.handlerCustomer}
                      name="customer_id"
                      value={this.state.customer_id}
                    />
                    <Button className='btn-add-customer-saving' id='addCustomerPOS' icon onClick={this.handlerAddCustomer}>
                      <Icon name='add' />
                    </Button>
                  </div>
                </Form.Field>
              </Form.Field>
              <Form.Field >
                <label>
                  *หมายเลขบัตรประชาชน
                </label>
                <Input
                  id="IDstaff"
                  maxLength={13}
                  value={this.state.citizen_id}
                  onChange={(e) => {
                    this.setState({ citizen_id: e.target.value })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  *ชื่อบัญชี
                </label>
                <Input
                  maxLength={100}
                  id="IDstaff"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  *จำนวนเงินเปิดบัญชี
                </label>
                <Input
                  maxLength={9}
                  id="IDstaff"
                  className='text-right'
                  value={
                    this.state.amount == ""
                      ? ""
                      : this.state.focus
                        ? Utility.removeCommas(this.state.amount)
                        : Utility.priceFormat(this.state.amount)
                  }
                  onChange={(e, data) => {
                    data.value = data.value.toString().replace(/,/g, "");
                    let amount = Utility.removeCommas(data.value);
                    if (isNaN(Number(amount)) || Number(amount) < 0) {
                    } else {
                      let str = data.value.split(".");
                      str[0] = str[0]
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                      str[1] = str[1] == undefined ? "" : "." + str[1];
                      if (str.length != 1) {
                        if (str[1].length > 3) {
                          return;
                        }
                      }
                      this.setState({ amount: data.value });
                    }
                  }}
                  onFocus={() => {
                    if (!this.state.focus) {
                      this.setState({
                        focus: true,
                      });
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      if (this.state.focus) {
                        this.setState({
                          focus: false,
                        });
                      }
                    }, 0);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>*พนักงาน</label>
                <Dropdown placeholder='พนักงาน' search selection
                  options={this.state.staffs}
                  // value={this.state.search.staff} 
                  name='staff'
                  onChange={this.handlerInput}
                  loading={this.state.st} />
              </Form.Field>
              <Form.Field>
                <label>
                  หมายเหตุ
                </label>
                <TextArea
                  maxLength={255}
                  id="IDstaff"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
          <CustomerModal
            open={this.state.modal_open}
            onCreate={this.handlerCreateCustomer}
            onClose={() => {
              this.setState({ modal_open: false });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="cancelAddstaff"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.props.onClose();
            }}
          >
            ยกเลิก
          </Button>
          <Button
            id="submitAddstaff"
            size="small"
            primary
            onClick={this.submitData}
            className={this.state.button_loading ? "loading" : ""}
          >
            เปิดบัญชี
          </Button>
        </Modal.Actions>
      </Modal>
      </div>
      
    );
  }

  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        // e.preventDefault()
        // this.handlerSubmit(e)
      },
    },
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(OpenAccount));
