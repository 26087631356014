/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Header
} from 'semantic-ui-react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import { connect } from 'react-redux'
class InputSearchBill extends Component {

    constructor(props) {
        super(props);

        this.modal_open = false;
        this.state = {
            customers: []
        }

        this.handlerSearchCustomer = this.handlerSearchCustomer.bind(this)
        this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this)
    }

    handlerSearchCustomer(e, v) {
        clearTimeout(this.timeout)
        this.setState({
            isLoading: true
        });
        this.timeout = setTimeout(() => { this.loadCustomerPage(v.searchQuery) }, 1600)
    }

    handlerDropdownSearch(e, v) {
        if (this.props.onChange != null)
            this.props.onChange(e, v)
    }

    componentDidMount() {
    }
    loadCustomerPage(text) {

        let q1 = {
            bill_number_contains: text,
            branch: this.props.auth.branch.id,
            for_staff: true,
        };
        let q = Utility.jsonToQueryString(q1)
        Utility.get(Settings.baseUrl + '/bills/?is_enabled=1&' + q, (status, data) => {
            if (status == true) {
                let result = []
                for (let i in data) {
                    result.push({
                        key: data[i].id,
                        value: data[i].bill_number,
                        text: data[i].bill_number,
                        content: <Header as='h4' content={data[i].bill_number} subheader={Utility.formatDate(data[i].bill_date)} />,
                    })
                }
                this.setState({
                    isLoading: false,
                    customers: result,
                })
            }
        });
    }

    render() {


        return (
            <Form.Dropdown
                id='searchBill'
                placeholder={this.props.placeholder} search
                label={this.props.label}
                width={this.props.width}
                selection
                name={this.props.name}
                loading={this.state.isLoading}
                onSearchChange={this.handlerSearchCustomer}
                value={this.props.value}
                options={this.state.customers}
                onChange={this.handlerDropdownSearch} />
        );
    }
}
const mapStateToProps = state => {

    return ({
        auth: state.auth,
    })
}
export default connect(
    mapStateToProps
)(InputSearchBill)
