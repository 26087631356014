/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';

class ImportCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields(),
            is_loading: false,

        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    setFields(branch_options,staff_options){
        return [
            {
                name: 'number',
                title: '*เลขที่บัญชี',
                type: 'string',
                unique: true,
                required: true
            },
            {
                name: 'amount',
                title: '*จำนวนเงิน',
                type: 'string',
                required: true
            },
            {
                name: 'kind',
                title: '*ประเภท',
                type: 'string',
                required: true
            },
            {
                name: 'balance',
                title: '*ยอดคงเหลือ',
                type: 'string',
                required: true
            },
            {
                name: 'created_datetime',
                title: '*วันที่เปิดบัญชี',
                type: 'string',
                required: true
            },
            // {
            //     name: 'staff',
            //     title: '*รหัสพนักงาน',
            //     type: 'string',
            //     options: staff_options,
            //     required: true
            // },
        ]
    }

    async componentDidMount()  {
        
        var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
        var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/');

        Promise.all([branches]).then((values) => {

            let branch_options = [];
            for (let i in values[0]) {
                branch_options.push({
                    key: values[0][i].id,
                    value: values[0][i].id,
                    text: values[0][i].code,
                });
            }

            let staff_options = [];
            for (let i in values[1]) {
                staff_options.push({
                    key: values[1][i].id,
                    value: values[1][i].id,
                    text: values[1][i].code,
                });
            }

            this.setState({
                fields: this.setFields(branch_options,staff_options)
            })
        });

      }

      
    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]
            let url = Settings.baseUrl + '/saving_item/import/'
            Utility.post(url,data,(e,res)=>{
                position++;
                if(position<data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })

        }
        run()
    }

    render() {
        return (
            <div>
                <ImportCSV
                    name='SavingItem'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportCustomer