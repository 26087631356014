/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import {
    Form,
    Input,
    Button,
    Dropdown,
    Modal,
} from 'semantic-ui-react';

import moment from 'moment';
import MsgInput from '../Error/MsgInput'
import PaymentModal from '../Payment/PaymentModal';
import { connect } from 'react-redux'



class IncreaseDecrease extends Component {

    constructor(props) {
        super(props);

        let auto_action_lease = localStorage.getItem('auto_action_lease')
        this.state = {
            action: auto_action_lease,
            open: auto_action_lease == 'add_delete',
            title: 'เพิ่มเงินต้น',
            type: '1',
            start_date: moment(new Date(this.props.lease.start_date)),
            end_date: moment(),
            pay_date: moment(),
            type2: this.props.setting.calculate_date,
            total_pay: 0,
            ledger: {
                payment: 'CS',
                description: '',
                total: 0
            },
            loading: false,
            error: {}
        }

        if (auto_action_lease == 'add_delete')
            localStorage.setItem('auto_action_lease', '')
        this.handelCheckbox = this.handelCheckbox.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.open = this.open.bind(this)
        this.handlerModalInputChange = this.handlerModalInputChange.bind(this)
        this.handelSubmitPrinciple = this.handelSubmitPrinciple.bind(this)
        this.submitLedger = this.submitLedger.bind(this)
        this.submitPay = this.submitPay.bind(this)
    }


    componentDidMount() {
        this.setState({ st: true })
        const { branch } = this.props.auth
        let banks = Utility.getFetch(Settings.baseUrl + '/banks/?is_enabled=1');
        let bank_cards = Utility.getFetch(Settings.baseUrl + '/bank_cards/?is_enabled=1');
        let staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1&branch=' + branch.id);
        Promise.all([banks, bank_cards, staffs]).then((values) => {
            this.banks = values[0];
            let banks = [];
            for (let i in this.banks) {
                banks.push({
                    key: this.banks[i].id,
                    value: this.banks[i].id,
                    text: this.banks[i].name,
                })
            }

            this.bank_cards = values[1];
            let bank_cards = [];
            for (let i in this.bank_cards) {
                bank_cards.push({
                    key: this.bank_cards[i].id,
                    value: this.bank_cards[i].id,
                    text: this.bank_cards[i].kind + " " + this.bank_cards[i].bank.name,
                })
            }


            this.staffs = values[2];
            let staffs = [];
            for (let i in this.staffs) {
                staffs.push({
                    key: this.staffs[i].id,
                    value: this.staffs[i].id,
                    text: this.staffs[i].name,
                })
            }


            this.setState({
                banks: banks,
                bank_cards: bank_cards,
                staffs: staffs,
                st: false
            })
        });
    }

    handlerModalInputChange(e, d) {
        d.value = d.value.toString().replace(/,/g, '')
        if (isNaN(Number(d.value))) {
        } else {
            let ledger = this.state.ledger;
            var value = d.value;
            if (d.name == 'total') {
                value = parseFloat(value + "")
                if (value < 0)
                    value = value * -1
                if (isNaN(parseFloat(value + ""))) {
                    value = 0
                }
            }
            if (this.state.type == '2' && d.name == 'total') {
                if (value > this.props.lease.amount) {
                    value = this.props.lease.amount
                }
            }
            ledger[d.name] = value;
            this.setState({
                ledger: ledger
            })
        }

        if (d.name == 'description') {
            let ledger = this.state.ledger;
            var value = d.value;
            ledger[d.name] = value;
            this.setState({
                ledger: ledger
            })
        }

    }

    open() {

        this.setState({ open: true })
    }
    close = () => this.setState({ open: false })

    handelCheckbox(e, v) {
        this.setState({
            type: v.value,
            title: v.value == '1' ? 'เพิ่ม' : 'ลด',
        })
    }

    handlerInput(e, v) {
        this.setState({
            [v.name]: v.value
        })
    }

    submitprinciple(formData) {

        let url = Settings.baseUrl + "/lease/" + this.props.lease.id + "/principle/"
        Utility.post(url, formData, (s, d) => {
            this.setState({
                loading_lease_cashier: false,
                modal_lease_cashier: false
            })
            if (s) {
                alert('บันทึกข้อมูลสำเร็จ');
            } else {
                alert(d.error);
            }
            this.setState({
                open: false
            })
            this.props.onSave()
        });
    }

    submitPay() {
        const { auth } = this.props
        const { branch, user } = auth

        if (this.state.staff == null || this.state.staff == 0) {

            this.setState({ error: { staff: 'เลือกพนักงาน' } })
            return;
        }
        if (this.state.ledger.total == null || this.state.ledger.total == 0 || isNaN(this.state.ledger.total)) {

            this.setState({ error: { total: 'ระบุยอดจำนวนเงินเพิ่ม/ลด' } })
            return;
        }

        if (this.state.type == '1') { // pay
            this.submitLedger({
                cash: this.state.ledger.total,
                total: this.state.ledger.total,
                object_id: this.props.lease.id,
                object_number: this.props.lease.number,
                change: 0,
                date: moment(),
                ledger_date: Utility.formatDate2(moment()),
                description: this.state.ledger.description,
                ledger_category: 8,
                bank: '',
                card_code: '',
                card_fee: 0,
                bankcard: '',
                card_service: 0,
                card_period: 0,
                card_start: '',
                card_contract_number: 0,
                card_bank_card: '',
                check_code: '',
                check_total: 0,
                card_total: 0,
                payment: 'CS',
                branch: branch.id,
                check_date: Utility.formatDate2(moment()),
                user: user.id
            })
        } else {

            this.setState({
                payment_open: true
            })
        }
    }
    submitLedger(ledger, savings, point) {

        const { auth } = this.props
        const { user } = auth

        let kind = '';
        let balance = 0

        // set customer
        ledger['customer'] = this.props.lease.customer.id

        ledger['staff'] = this.state.staff
        if (this.state.type == 1) { // add
            ledger['kind'] = 'EX'
            kind = 'IC';
            ledger.ledger_category = 8
            balance = parseFloat(this.props.lease.amount) + parseFloat(Utility.removeCommas(this.state.ledger.total))
        } else {
            ledger['kind'] = 'IN'
            ledger.ledger_category = 9
            kind = 'DC';
            balance = parseFloat(this.props.lease.amount) - parseFloat(Utility.removeCommas(this.state.ledger.total))
        }

        let url = Settings.baseUrl + "/ledger/"
        this.setState({
            loading: true
        })
        Utility.post(url, ledger, (s, d) => {
            this.setState({
                loading: false,
                loading_lease_cashier: false,
                modal_lease_cashier: false
            })
            // save Principle
            let formData = {
                lease: this.props.lease.id,
                user: user.id,
                staff: this.state.staff,
                ledger: d.id,
                balance: balance,
                total: Utility.removeCommas(this.state.ledger.total),
                description: this.state.ledger.description,
                kind: kind
            };
            this.handelSubmitPrinciple(formData);
        });
    }

    handelSubmitPrinciple(formData) {

        let url = Settings.baseUrl + "/lease/" + this.props.lease.id + "/principle/"
        Utility.post(url, formData, (s, d) => {
            this.setState({
                loading_lease_cashier: false,
                modal_lease_cashier: false,
                payment_open: false
            })
            if (s) {
                alert('บันทึกข้อมูลสำเร็จ');
            } else {
                alert(d.error);
            }
            this.setState({
                open: false
            })
            this.props.onSave()
        });

    }

    // updateLease(balance){
    //     let url = Settings.baseUrl + "/lease/" + this.props.lease.id + "/"
    //     let formData = this.props.lease

    //     formData.branch = formData.branch.id
    //     formData.customer = formData.customer.id
    //     formData.staff = formData.staff.id

    //     formData.amount = balance
    //     Utility.put(url, formData, (s, d) => {

    //     });
    // }

    render() {

        const a = moment(new Date(this.state.start_date));
        const b = moment(new Date(this.state.end_date));
        const diff = b.diff(a, 'days')
        const d = diff % 30
        const m = parseInt(diff / 30)
        let is_min = false;
        let total = this.state.month ? (parseFloat(this.props.lease.amount) * parseFloat(this.props.lease.principle) / 100) * parseFloat(this.state.month).toFixed(2) : 0
        if (total < this.props.setting.min_principle) {
            total = this.props.setting.min_principle
            is_min = true
        }
        total = Utility.priceFormat(total)
        return (
            <Modal
                id='ICDCModal'
                dimmer={true}
                open={this.props.open || this.state.action != ''}
                size='small'
            >
                <Button id='btnCloseInDe' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
                <Modal.Header>{this.state.title}</Modal.Header>
                <Modal.Content className='scrolling'>
                    <center>
                        {this.state.payment_open && <PaymentModal
                            object_id={this.props.lease.id}
                            object_number={this.props.lease.number}
                            ledger_category={9}
                            staff={this.state.staff}
                            total={this.state.ledger.total}
                            onClose={() => this.setState({ payment_open: false })}
                            onSubmit={this.submitLedger}
                            loading={this.state.loading}
                        />}
                        <Button.Group size='small'>
                            <Button id='btnIncease' onClick={() => { this.setState({ type: '1', title: 'เพิ่มเงินต้น' }) }} color={this.state.type == '1' ? 'green' : ''}>เพิ่ม</Button>
                            <Button.Or />
                            <Button id='btnDecease' onClick={() => { this.setState({ type: '2', title: 'ลดเงินต้น' }) }} color={this.state.type == '2' ? 'red' : ''}>ลด</Button>
                        </Button.Group>
                    </center>
                    <Form>
                        <Form.Field>
                            <label>เลขที่ขายฝาก</label>
                            <Input
                                id='leaseNum'
                                value={this.props.lease.number}
                                readOnly
                                className="text-right" />
                        </Form.Field>
                        <Form.Field>
                            <label>จำนวนเงินต้น</label>
                            <Input
                                id='inputLeaseAmount'
                                value={Utility.priceFormat(this.props.lease.amount)}
                                readOnly
                                className="text-right" />
                        </Form.Field>

                        <Form.Field error={this.state.error.staff != null}>
                            <label>พนักงาน <MsgInput text={this.state.error.staff} /></label>
                            <Dropdown id='inputStaff' search selection options={this.state.staffs} value={this.state.staff}
                                onChange={(e, v) => {
                                    this.setState({ staff: v.value })
                                }}
                                loading={this.state.st} />
                        </Form.Field>
                        <Form.Field error={this.state.error.total != null}>
                            <label>จำนวนเงินเพิ่ม/ลด <MsgInput text={this.state.error.total} /></label>
                            <Input id='inputLedgerTotal' placeholder='จำนวนเงินเพิ่ม/ลด'
                                // type='number' 
                                onBlur={() => {
                                    setTimeout(() => {
                                        if (this.state.focus_cash) {
                                            this.setState({
                                                focus_cash: false,
                                            });
                                        }
                                    }, 0);
                                }}
                                onFocus={(e) => {
                                    e.target.select()
                                    if (!this.state.focus_cash) {
                                        this.setState({
                                            focus_cash: true,
                                        });
                                    }
                                }}
                                min='1' className='text-right' search selection name="total"
                                value={this.state.focus_cash ? this.state.ledger.total.toString().replace(/,/g, '') : this.state.ledger.total.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                // value={this.state.ledger.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                onChange={this.handlerModalInputChange}
                                maxLength={16} />
                        </Form.Field>
                        <Form.TextArea label='หมายเหตุ' placeholder='หมายเหตุ' search selection name="description" value={this.state.ledger.description}
                            onChange={this.handlerModalInputChange}
                            maxLength={255}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button id='btnSaveInDe' primary content='บันทึก' onClick={this.submitPay} loading={this.state.loading} />
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(IncreaseDecrease)