/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component, forwardRef } from 'react';
import {
    Modal, Form, Button, Grid, Icon, Input, Checkbox, Radio
} from 'semantic-ui-react';
import { DropDownBranch, DropDownProduct } from '../Widget/DropDown'
import InputSearchCustomer from '../Customer/InputSearchCustomer'
import DatePicker from "react-datepicker";
import moment from 'moment';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'

class LeaseSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            overdue: '',
            start_price: '',
            end_price: '',
            phone: '',
            radio: 2,
            in_date_start: moment(),
            in_date_end: moment(),
            status_in: '1,2,3,4',
            checked_lease_across_branch: false
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.clearFormSearch = this.clearFormSearch.bind(this)
    }

    handleInputChange(e, { name, value, q }) {

        if (name == 'radio') {

            this.setState({
                error: {},
                overdue: '',
                start_price: '',
                end_price: '',
                is_enabled: 1,
                due_date: undefined,
                start_close_date: undefined,
                end_close_date: undefined,
                start_out_date: undefined,
                end_out_date: undefined,
                start_end_date: undefined,
                end_end_date: undefined,
                status_in: undefined
            })

            let branch = this.state.branch
            this.state = {}
            if (branch)
                this.state = { branch: branch }
            for (let k in q) {
                if (q[k] == undefined) {
                    delete q[k]
                }
            }
            this.setState(q)
        } else if (name == 'overdue') {
            if (isNaN(Number(value))) {
            } else {
                if (value.length === 0) {
                    value = ''
                }
                const overdue = Utility.parseInt(value, this.state.overdue)
                this.setState({
                    due_date: moment().add(-overdue, 'day')
                })
            }
        }
        if (name == 'overdue' || name == 'start_price' || name == 'end_price') {
            value = value.toString().replace(/,/g, '')
            if (isNaN(Number(value))) {
            } else {
                this.setState({ [name]: value })
            }
        }
        else {
            this.setState({ [name]: value })
        }

    }

    componentDidMount() {
        this.handleSubmit()
    }

    handleSubmit(e) {
        const { checked, product } = this.state
        this.setState({
            error: {}
        })
        if (checked && product == '') {
            this.setState({
                error: { product: 'กรุณาเลือกชื่อสินค้า' }
            })
        } else {
            delete this.state['error']
            if (this.state.product == '')
                delete this.state['product']
            const state = {}
            for (let k in this.state)
                if (this.state[k] != undefined)
                    state[k] = this.state[k]
            this.props.onSubmit(state, 1)
        }

    }

    clearFormSearch() {
        this.setState({
            number: '',
            customer: undefined,
            phone: '',
            citizen_id: '',
            in_date_start: moment(),
            in_date_end: moment(),
            due_date: '',
            overdue: '',
            start_price: '',
            end_price: '',
            product: '',
            start_close_date: undefined,
            end_close_date: undefined,
            start_out_date: undefined,
            end_out_date: undefined,
            start_end_date: undefined,
            end_end_date: undefined,
            radio: 2,
            status_in: '1,2,3,4',
            checked_lease_across_branch: false,
        })
    }

    render() {

        const { onClose } = this.props
        const { branch, number, customer, phone, citizen_id, in_date_start, in_date_end, overdue, start_price, end_price, product } = this.state

        return (<div>
            <Form className='attached fluid' size='small' autocomplete="off">
                <Form.Group>
                    <Form.Field width={3}>
                        <label>เลขที่ใบขายฝาก</label>
                        <Input id='inputLeaseNum' fluid onChange={this.handleInputChange} name="number" value={number} placeholder='เลขที่ใบขายฝาก' />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>ชื่อลูกค้า</label>
                        <InputSearchCustomer fluid onChange={this.handleInputChange} name="customer" value={customer} placeholder='ชื่อลูกค้า' />
                    </Form.Field>

                    <Form.Field width={3}>
                        <label>เบอร์โทร</label>
                        <Input
                            maxLength={10}
                            id='inputPhone' fluid onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    this.setState({
                                        phone: e.target.value
                                    })
                                }
                            }} name="phone" value={phone} placeholder='เบอร์โทร' />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>เลขประจำตัวประชาชน</label>
                        <Input id='inputID' maxLength={13} fluid onChange={this.handleInputChange} name="citizen_id" value={citizen_id} placeholder='เลขประจำตัวประชาชน' />
                    </Form.Field>
                    <Form.Field width={4}>
                        <label>รายการเกินกำหนด (วัน)</label>
                        <Input id='inputOverDue' fluid min={0} max={999}
                            maxLength={3}
                            className='text-right'
                            // type='number' 
                            placeholder='รายการเกินกำหนด (วัน)'
                            onChange={this.handleInputChange} name="overdue" value={overdue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} />
                    </Form.Field>

                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <label>จากวันที่นำเข้า</label>
                        <DatePicker
                            dateFormat="DD/MM/YYYY"
                            autocomplete="off"
                            selected={this.state.in_date_start}
                            onChange={(date) => {
                                date = date ? date : moment()
                                if (this.state.in_date_end < date) {
                                    this.setState({
                                        in_date_end: date,
                                    })
                                }
                                this.setState({
                                    in_date_start: date,
                                })
                            }}
                            name="in_date_start" />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>ถึงวันที่นำเข้า</label>
                        <DatePicker
                            dateFormat="DD/MM/YYYY"
                            autocomplete="off"
                            onChange={(date) => {
                                date = date ? date : moment()
                                if (this.state.in_date_start > date) {
                                    this.setState({
                                        in_date_start: date,
                                    })
                                }
                                this.setState({
                                    in_date_end: date,
                                })
                            }}
                            name="in_date_end"
                            selected={this.state.in_date_end} />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>จากจำนวนเงิน</label>
                        <Input id='inputStartPrice' fluid min={0} max={end_price}
                            className='text-right'
                            // type='number' 
                            onChange={this.handleInputChange} name="start_price"
                            placeholder='จากจำนวนเงิน'
                            onBlur={() => {
                                setTimeout(() => {
                                    if (this.state.focus_start_price) {
                                        this.setState({
                                            focus_start_price: false,
                                        });
                                    }
                                }, 0);
                            }}
                            onFocus={(e) => {
                                e.target.select()
                                if (!this.state.focus_start_price) {
                                    this.setState({
                                        focus_start_price: true,
                                    });
                                }
                            }}
                            maxLength={10}
                            value={this.state.focus_start_price ? start_price.toString().replace(/,/g, '') : start_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        // value={start_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <label>ถึงจำนวนเงิน</label>
                        <Input id='inputEndPrice' fluid min={start_price}
                            className='text-right'
                            // type='number' 
                            onChange={this.handleInputChange} name="end_price"
                            placeholder='ถึงจำนวนเงิน'
                            onBlur={() => {
                                setTimeout(() => {
                                    if (this.state.focus_end_price) {
                                        this.setState({
                                            focus_end_price: false,
                                        });
                                    }
                                }, 0);
                            }}
                            onFocus={(e) => {
                                e.target.select()
                                if (!this.state.focus_end_price) {
                                    this.setState({
                                        focus_end_price: true,
                                    });
                                }
                            }}
                            maxLength={10}
                            value={this.state.focus_end_price ? end_price.toString().replace(/,/g, '') : end_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        // value={end_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
                        />
                    </Form.Field>
                    <Form.Field width={4}>
                        <label><Checkbox id='checkProduct' onChange={(e, v) => this.setState({ checked: v.checked, product: '' })} /> รายการสินค้า <MsgInput text={this.state.error && this.state.error.product} /></label>
                        <DropDownProduct placeholder='รายการสินค้า' id='selectProduct' disabled={!this.state.checked} fluid size='small' fluid selection onChange={this.handleInputChange} name="product" value={product} />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการวันนี้'
                                name='radio'
                                value='2'
                                q={{ in_date_start: moment(), in_date_end: moment(), status_in: '1,2,3,4' }}
                                checked={this.state.radio == '2'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p><Radio
                            label='รายการไถ่คืนวันนี้'
                            name='radio'
                            value='12'
                            q={{ start_close_date: moment(), end_close_date: moment(), status_in: '3' }}
                            checked={this.state.radio == '12'}
                            onChange={this.handleInputChange}
                        /></p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการคัดออกวันนี้'
                                name='radio'
                                value='6'
                                q={{ start_out_date: moment(), end_out_date: moment(), status_in: '4' }}
                                checked={this.state.radio == '6'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการยกเลิกบิล'
                                name='radio'
                                value='7'
                                q={{ is_enabled: 0 }}
                                checked={this.state.radio == '7'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                    <Form.Field width={4} className="checkbox-lease-across-branch">
                        <p>
                            <Checkbox label='ต่อดอกข้ามสาขา' checked={this.state.checked_lease_across_branch} onChange={(e, v) => this.setState({ checked_lease_across_branch: v.checked })} />
                        </p>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <p><Radio
                            label='รายการเดือนนี้'
                            name='radio'
                            value='3'
                            q={{ in_date_start: moment().startOf("month"), in_date_end: moment().endOf("month"), status_in: '1,2,3,4' }}
                            checked={this.state.radio == '3'}
                            onChange={this.handleInputChange}
                        /></p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p><Radio
                            label='รายการไถ่คืนเดือนนี้'
                            name='radio'
                            value='8'
                            q={{ start_close_date: moment().startOf("month"), end_close_date: moment().endOf("month"), status_in: '3' }}
                            checked={this.state.radio == '8'}
                            onChange={this.handleInputChange}
                        /></p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการคัดออกเดือนนี้'
                                name='radio'
                                value='13'
                                q={{ start_out_date: moment().startOf("month"), end_out_date: moment().endOf("month"), status_in: '4' }}
                                checked={this.state.radio == '13'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการครบกำหนดวันนี้'
                                name='radio'
                                value='5'
                                q={{ start_end_date: moment(), end_end_date: moment() }}
                                checked={this.state.radio == '5'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <p><Radio
                            label='รายการทั้งหมด'
                            name='radio'
                            value='4'
                            q={{ in_date_start: '', in_date_end: '', status_in: '1,2,3,4' }}
                            checked={this.state.radio == '4'}
                            onChange={this.handleInputChange}
                        />  </p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p><Radio
                            label='รายการไถ่คืนทั้งหมด'
                            name='radio'
                            value='9'
                            q={{ status_in: '3' }}
                            checked={this.state.radio == '9'}
                            onChange={this.handleInputChange}
                        /></p>
                    </Form.Field>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการคัดออกทั้งหมด'
                                name='radio'
                                q={{ status_in: '4' }}
                                value='10'
                                checked={this.state.radio == '10'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                    <Form.Field width={4}>
                        <p>
                            <Radio
                                label='รายการเกินกำหนดทั้งหมด'
                                name='radio'
                                value='11'
                                q={{ due_date: moment(), status_in: '1,2' }}
                                checked={this.state.radio == '11'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>

                    </Form.Field>
                    <Form.Field width={3}>
                        <p>
                            <Radio
                                label='รายการที่ยังไม่ไถ่คืน'
                                name='radio'
                                value='1'
                                q={{ status_in: '1,2' }}
                                checked={this.state.radio == '1'}
                                onChange={this.handleInputChange}
                            />
                        </p>
                    </Form.Field>
                    <Form.Field width={3}>

                    </Form.Field>
                    <Form.Field width={3}>

                    </Form.Field>
                    <Form.Field width={4} className="btn-search-reset">
                        <Button id='clearSearch' floated='right' onClick={this.clearFormSearch}>รีเซ็ต</Button>
                        <Button id='btnSearch' floated='right' type='submit' onClick={this.handleSubmit}><Icon name='search' />ค้นหา</Button>
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>);
    }
}

export default LeaseSearch
