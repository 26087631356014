/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';
import { connect } from 'react-redux'
import {
  Menu,
  Grid,
  Form,
  Segment,
  Header,
  Icon,
  Image,
  Button,
  Input,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import logoAAGold from '../../images/aa-text_1.png';
import Utility from '../../Utility';
import InputSearchCustomerNamePhone from '../Customer/InputSearchCustomerNamePhone';
import Settings from "../../Settings";
import MsgInput from '../Error/MsgInput'
import { clear_redeem_customer } from '../../actions'

class RedeemQRCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data_encode: '',
      timeout: null,
      ref_id: '',
      count:true,
      points:'0',
    }
    this.handleBackHomePage = this.handleBackHomePage.bind(this);
    this.checkQRcode = this.checkQRcode.bind(this);
  }

  async componentDidMount() {
    if(Object.keys(this.props.redeem_customer.data).length == 0) {
        this.props.history.push('/redeem/customer');
    } else {
        var game = 'วงล้อมหาเฮง'
        await Utility.get(Settings.baseUrl + "/redeem_config/?game=" + game, (status, data) => {
          let results = data[0]
           this.setState({
            points: results.point,
          });
            var data = {
                ...this.props.redeem_customer.data,
                'branch_id': 1,
                'use_score': this.props.redeem_customer.data.amount*parseInt(results.point),
                'point_current':results.point,
                'code':this.props.redeem_customer.data.code,
            }
            let url = Settings.baseUrl + '/redeem_qrcode/'
            Utility.post(url, data, (e, res) => {
                this.setState({ 
                  data_encode: res.url_encode,
                  timeout:res.timeout*60,
                  ref_id: res.ref_id});
            })
        })
    }
  }

  async handleBackHomePage() {
    await this.props.handleClearRedeemCustomer();
    this.props.history.push('/redeem/customer')
  }
  async checkQRcode() {
    if(this.state.timeout===0) {
      this.props.history.push('/redeem/customer')
    }else{
      if(this.state.count==true) {
        await this.setState({ 
          count:false,
          });
          await setTimeout(async ()  =>{
                let url = Settings.baseUrl + `/redeem_qrcode_check/?code=${this.state.ref_id}`
                let res =  await Utility.getAsync(url)
                if(res.data ==='สำเร็จ'){
                  this.props.history.push('/redeem/customer')
                }
                await this.setState({ 
                  timeout:this.state.timeout-1,
                  count:true,
                  });
            },1000)
      }
    }
  }
  render() {
    const { auth } = this.props
    const { role, branch } = auth
    const { redeem_customer } = this.props
    const containerImgLogoTextAAGold = {
        'margin-top': '-25px',
        'margin-bottom': '30px',
        'cursor': 'pointer',
    }
    const container = {
        'max-width': '800px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'font-family': 'Kanit',
    }
    const divInfo = {
        'padding-left': '30px',
        'padding-right': '30px',
    }
    const textCustomerInfo = {
        'text-align': 'center',
        'line-height': '1',
        'font-size': '23px',
        'word-wrap':'break-word',
    }
    const textRedeemInfo = {
        'text-align': 'center',
        'color': '#B51515',
        'line-height': '0.8',
        'font-size': '23px',
    }
    const divTextCustomerInfo = {
        'margin-bottom': '30px',
    }
    const btnRedeemQRCode = {
        'height': '100%',
        'font-family': 'Kanit',
        'font-weight': '600',
        'font-size': '23px',
        'display': 'flex',
        'align-items': 'center',
    }
    const columnBtnRedeemQRCode = {
        'padding-left': '6px',
        'padding-right': '6px',
    }
    const containerQRCode = {
        'text-align': 'center',
        'margin-bottom': '20px'
    }
    const imgQRCodeRedeem = {
        'width': '100%',
        'height': '100%',
        'max-width': '360px',
    }
    
    var QRCode = require('qrcode.react');
    this.checkQRcode()
    return (
      <div style={container}>
        <div 
        style={containerImgLogoTextAAGold}
        onClick={() => {
          this.props.history.push('/redeem/customer')
      }}>
          <Image className='imgLogoTextAAGold' src={logoAAGold}/>
        </div>
        <div style={divInfo}>
            <div style={divTextCustomerInfo}>
                <p style={textCustomerInfo}><span>ลูกค้า : <b>{redeem_customer.data.name}</b></span></p>
                <p style={textCustomerInfo}>แต้มสะสม : {Utility.numberFormat(redeem_customer.data.score)} แต้ม</p>
                <p style={textRedeemInfo}>หมุนวงล้อ : {Utility.numberFormat(redeem_customer.data.amount)} ครั้ง</p>
                <p style={textRedeemInfo}>- {Utility.numberFormat(redeem_customer.data.amount*parseInt(this.state.points))} แต้ม</p>
            </div>
            <div style={containerQRCode}>
                <QRCode style={imgQRCodeRedeem} value={Settings.baseMobileUrl+"redeemconfirm?q="+this.state.data_encode} renderAs='svg'></QRCode>
                <p>หมดเวลาภายใน : {this.state.timeout} วินาที</p>
            </div>
            <Grid columns='two'>
                <Grid.Row>
                    <Grid.Column style={columnBtnRedeemQRCode}>
                        <Button
                            floated="right"
                            type="button"
                            size="small"
                            style={btnRedeemQRCode}
                            onClick={() => {
                                this.handleBackHomePage();
                            }}
                        >
                            กลับหน้าหลัก
                        </Button>
                    </Grid.Column>
                    <Grid.Column style={columnBtnRedeemQRCode}>
                        <Button
                            floated="left"
                            type="button"
                            size="small"
                            style={btnRedeemQRCode}
                            as={Link}
                            to='/redeem/customer'
                        >
                            หมุนอีกครั้ง
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches,
    redeem_customer: state.redeem_customer
  })
}
const mapDispatchToProps = dispatch => ({
  handleClearRedeemCustomer: () => {
    dispatch(clear_redeem_customer())
  }
})
export default connect(
  mapStateToProps, mapDispatchToProps
)(RedeemQRCode)

