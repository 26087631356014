/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Button,
  Dimmer,
  Loader,
  Input,
  Icon,
  Popup,
  Pagination,
} from 'semantic-ui-react';
import { connect } from 'react-redux'
import { ContextMenu, Item, ContextMenuProvider } from 'react-contexify';
import moment from 'moment'
import Settings from '../../Settings';
import Utility from '../../Utility';
import FormSerarchBill from './Search/FormSerarchBill'
import BillListPrintPreview from './BillListPrintPreview';
import InvoicePrintPreview from './InvoicePrintPreview'
import OptionItemsCell from './Cell/OptionItemsCell'
import IconItemsCell from './Cell/IconItemCell'
import { hotkeys } from 'react-keyboard-shortcuts'
import { loadBills } from '../../actions'

class MyAwesomeMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ContextMenu id='menu_id'>
        <Item onClick={this.props.onClick} data='view'>
          ดูข้อมูล
        </Item>
        <Item onClick={this.props.onClick} data='remove'>
          ยกเลิกบิล
        </Item>
      </ContextMenu>
    );
  }
}

class IconStockItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (

      <Cell {...props}>
        <Icon name={data[rowIndex].status_stock == 'N' ? 'warning circle' : 'check circle'} color={data[rowIndex].status_stock == 'N' ? 'yellow' : 'green'} />
      </Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, className, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props} className={(data[rowIndex].is_void ? 'cell_red cell-time-click' : 'cell-time-click') + ' ' + (className ? className : '')} title={data[rowIndex].is_void ? 'ถูกยกเลิก โดย' + data[rowIndex].canceler_name : ''} onClick={(e) => {
        this.props.onClick(e, data[rowIndex]);
      }}>
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id"
          className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>
          <Popup
            position={this.props.position}
            content={
              field == 'card_code' ?
                data[rowIndex][field] == undefined ? '' :
                  data[rowIndex][field].toString().replace(/(\d)(?=(\d{4})+(?!\d))/g, '$1 ')
                  : data[rowIndex][field]
            }
            style={Width300}
            trigger={
              field == 'card_code' ?
                data[rowIndex][field] == undefined ? '' :
                  <div style={ellipsisStyle}>{data[rowIndex][field].toString().replace(/(\d)(?=(\d{4})+(?!\d))/g, '$1 ')}</div>
                :
                <div style={ellipsisStyle}>{data[rowIndex][field]}</div>}
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class BillList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      bill: {},
      modal_detail: false,
      billItems: [],
      action: 'sell',
      bill_loader_active: true,
      billSummary: {
        summary: {
          net_price: 0
        }
      },
      table_width: 300,
      table_height: 300,
      start_date: moment(),
      end_date: moment(),
      colSortDirs: {
        'bill_date': 'ASC',
      },
      activePage: 1,
      item_report: []
    }
    this.columnKey = 'bill_date'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitSearch = this.submitSearch.bind(this);
    this.handlerClose = this.handlerClose.bind(this);
    this.handlerVoidBill = this.handlerVoidBill.bind(this)
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this);
    this.handlerClickItem = this.handlerClickItem.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.searchReport = this.searchReport.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.submitSearch(undefined, activePage)
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.submitSearch()

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handlerVoidBill(e, data) {

    if (data.is_void == 1) {
      alert('ถูกลบแล้ว');
      return;
    }

    let url = Settings.baseUrl + "/bill/" + data.id + "/void/"
    Utility.getAuth(url, (e, d) => {

      alert('ยกเลิกบิลสำเร็จ');
      this.componentDidMount();
      this.setState({
        modal_detail: false,
        bill: {}
      })
    });
  }

  submitSearch(data, page) {
    if (data == undefined) {
      data = this.state.data2
    }
    if (page) {
      this.setState({ activePage: page })
    }
    const { branch } = this.props.auth

    this.setState({
      loader_active: true
    });
    data.columnKey = this.columnKey
    data.sortDi = this.sortDi
    data.branch = branch.id
    if (data.start_date == null)
      delete data.start_date
    else
      data.start_date = Utility.formatDate2(data.start_date)

    if (data.end_date == null)
      delete data.end_date
    else
      data.end_date = Utility.formatDate2(data.end_date)

    data['is_enabled'] = 1
    data['branch'] = branch.id
    data['page'] = page ? page : this.state.activePage
    data['for_staff'] = true
    let qrstring = Utility.jsonToQueryString(data);
    this.props.onLoadBillList(qrstring)
    this.setState(data)
    this.setState({ data2: data })
  }

  async searchReport() {
    let data = this.state.data2
    const { branch } = this.props.auth

    this.setState({
      loader_active: true
    });
    data.columnKey = this.columnKey
    data.sortDi = this.sortDi
    data.branch = branch.id
    if (data.start_date == null)
      delete data.start_date
    else
      data.start_date = Utility.formatDate2(data.start_date)

    if (data.end_date == null)
      delete data.end_date
    else
      data.end_date = Utility.formatDate2(data.end_date)

    data['is_enabled'] = 1
    data['branch'] = branch.id
    data['for_staff'] = true
    delete data.page
    let qrstring = Utility.jsonToQueryString(data);
    const res = await Utility.getAsync(Settings.baseUrl + '/bills/?' + qrstring)
    this.setState({
      item_report: res.data
    })
  }

  handlerClose(e) {
    e.preventDefault();
    this.setState({
      modal_detail: false,
      bill: {}
    });
  }

  componentDidMount() {
    const { dispatch, auth } = this.props
    const { branch } = auth
    this.setState({
      loader_active: true
    });
    let querytoday = Utility.jsonToQueryString({
      start_date: Utility.formatDate2(moment()),
      end_date: Utility.formatDate2(moment()),
      branch: branch.id,
      is_enabled: 1,
      columnKey: this.columnKey,
      sortDi: this.sortDi,
      page: 1,
      for_staff: true,
    });
    this.setState({
      data2: {
        start_date: Utility.formatDate2(moment()),
        end_date: Utility.formatDate2(moment()),
        branch: branch.id,
        is_enabled: 1,
        columnKey: this.columnKey,
        sortDi: this.sortDi,
      }
    })

    var products_data = []
    var categories = []
    var userprofiles = Utility.getFetch(Settings.baseUrl + '/userprofiles/');
    var banks = []
    var bankcards = []
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var product_types = Utility.getFetch(Settings.baseUrl + '/product_types/');
    Promise.all([product_types, branches, products_data, categories, userprofiles, banks, bankcards]).then((values) => {

      this.products = values[2];
      this.categories = values[3];
      this.userprofiles = values[4];
      this.banks = values[5];
      this.bankcards = values[6];
      this.branches = values[1];
      this.product_types = values[0];

      let branches = []
      for (let i in this.branches) {
        branches.push({
          value: this.branches[i].id,
          key: this.branches[i].id,
          text: this.branches[i].name
        });
      }

      let product_types = []
      for (let i in this.product_types) {
        product_types.push({
          value: this.product_types[i].id,
          key: this.product_types[i].id,
          text: this.product_types[i].name
        });
      }
      this.setState({
        branches: branches,
        product_types: product_types,
      });

      // load bill
      this.props.onLoadBillList(querytoday)

    });
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  getObjectUserProfile(user_id) {
    for (let i in this.userprofiles) {
      if (this.userprofiles[i].user.id == user_id)
        return this.userprofiles[i];
    }
    return null;
  }

  handlerClickItem(e, data) {
    this.props.onItemClick(data)
    //this.loadBillDetail(data);
  }

  loadBillDetail(data) {
    let action = 'exc';
    if (data.kind == 'BU')
      action = 'buy';
    else if (data.kind == 'SE')
      action = 'sell';
    this.setState({
      modal_detail: true,
      bill_loader_active: true,
      bill: data,
      total_sell_price: Utility.priceFormat(parseFloat(data.sell)),
      otal_sell_weight: 0,
      total_buy_price: Utility.priceFormat(parseFloat(data.buy)),
      net_price: data.total,
      action: action,
      itemCount: data.amount,
      description: data.description
    })

    let url = Settings.baseUrl + "/bill/" + data.id + "/items/";
    Utility.get(url, (e, res) => {

      if (e == true) {
        let billItems = [];
        for (let i in res) {
          let d = res[i]
          if (d.kind == 'SE' || (d.kind == 'XC' && d.sell != 0)) {
            let p = d.product
            billItems.push({
              type_title: 'ขาย',
              type: 'SE',
              'id': i + 1,
              amount: d.amount,
              sell_price: d.sell,
              sell_weight: 0,
              sell_weight_real: d.weight,
              buy_price: 0,
              buy_weight: 0,
              gold_price: d.price_gold,
              product_code: d.product.code,
              product_id: d.product.id,
              product_name: p.name,
              product_type: p.kind.name,
              category_name: p.category.name,
              category_id: p.category.id,
              code_change: d.code_change,
              exchange: d.exchange,
              sales_force: (d.sell - (d.cost * d.amount))
            })
          } else if (d.kind == 'BU' || (d.kind == 'XC' && d.buy != 0)) {
            billItems.push({
              type_title: 'ซื้อ',
              type: 'BU',
              'id': i + 1,
              amount: 0,
              sell_price: 0,
              sell_weight: 0,
              sell_weight_real: 0,
              buy_price: d.buy,
              buy_weight: d.weight,
              gold_price: d.price_gold,
              product_code: '',
              product_name: d.product_name,
              product_type: '',
              category_name: d.category.name,
              category_id: d.category.id,
              code_change: d.code_change,
              exchange: d.exchange,
              sales_force: 0
            })
          }
        }


        this.setState({
          bill_loader_active: false,
          billItems: billItems
        })
      }

    });

    let url2 = Settings.baseUrl + "/bill_staff/?bill_id=" + data.id;
    Utility.get(url2, (e, res) => {
      this.setState({
        billstaff: res
      })
    });
  }

  setFieldValue(items) {
    const { role } = this.props.auth
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.is_void && (role != 'A' && role != 'S' && role != 'M')) {
        items.splice(i, 1);
        i--;
        continue;
      }
      items[i].customer_name = item.customer.name
      items[i].date = Utility.formatDate(item.bill_date)
      items[i].branch_name = item.branch.name
      items[i].time = Utility.formatTime(item.bill_date)
      items[i].total_sell = Utility.priceFormat(item.sell)
      items[i].total_buy = Utility.priceFormat(item.buy)
      items[i].total_net = Utility.priceFormat(item.total)
      items[i].total_exc = Utility.priceFormat((Number(item.total) + Number(item.buy)) - Number(item.sell))
      items[i].total_diff = Utility.priceFormat(item.sell - item.buy)
      items[i].total_discount = Utility.priceFormat(item.discount)

      let ledger = item.ledger
      if (ledger) {
        items[i].card_code = ledger.card_code
        if (ledger.card_bank_card){
          items[i].card_bank_card_display = `${ledger.card_bank_card.kind} - ${ledger.card_bank_card.bank.name}`
          items[i].payment_display = `${items[i].payment_display}${ledger.card_bank_card.period ? ` (${ledger.card_bank_card.period_display})` : ''}`
        }
        items[i].card_service = ledger.card_service
        items[i].transfer_bank_account_name = ledger.transfer_bank_account_name

        let type_pay = '';
        if (ledger.cash > 0)
          type_pay = 'เงินสด';
        else if (ledger.card > 0)
          type_pay += type_pay == '' ? 'บัตร' : '+บัตร';
        else if (ledger.check > 0) {
          type_pay += type_pay == '' ? 'เช็ค' : '+เช็ค';
          items[i].check_date_title = Utility.formatDate(ledger.check_date);
        }
        items[i].type_pay = type_pay
      } else if (item.kind == 'BU') {
        items[i].type_pay = 'เงินสด';
      }

      items[i].status_bill = item.is_void ? 'ยกเลิกบิล' : 'ปกติ';
      // let user = this.getObjectUserProfile(item.canceler);
      // if (user)
      //   items[i].canceler_name = user.user.username
      items[i].canceler_name = item.canceler_display
      items[i].weight_buy_title = Utility.weightFormat(items[i].weight_buy)

    }
    return items;
  }

  handlerDropdownSearch(event, value) {
    this.setState({
      [value.name]: value.value
    });
  }

  handlerSearch(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    //const {items} = this.state
    const { bills_list, auth } = this.props
    const items = this.setFieldValue(bills_list.list)
    return (<div>
      {auth.role == 'A' || auth.role == 'M' || auth.role == 'S' ? <MyAwesomeMenu id="menu_id" onClick={(e, r, data) => {
        let index = r.props.data;
        const bill = items[index];
        this.handlerClickItem(e, bill);
        // if (data == 'view') {
        //   this.handlerClickItem(e, bill);
        // } else {
        //   if (window.confirm('ยืนยันยกเลิกบิลนี้')) {
        //     this.handlerVoidBill(e, bill);
        //   }
        // }
      }} /> : ''}
      <FormSerarchBill value={this} onSubmitSearch={this.submitSearch} />
      {this.state.modal_open_inv ? <InvoicePrintPreview onClose={() => { this.setState({ modal_open_inv: false }) }} bill_id={this.state.bill_id} /> : ''}
      <div className='relative'>
        <Dimmer className={bills_list.is_loading && items.length == 0 ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={30}
            width={this.state.table_width}
            height={402}>

            <Column
              header={<Cell></Cell>}
              cell={
                <OptionItemsCell onClickMenu={(e, action, index) => {

                  if (action == 'edit')
                    this.handlerClickItem(e, items[index])
                  else if (action == 'delete') {
                    if (window.confirm('ยืนยันยกเลิกบิลนี้')) {
                      this.handlerVoidBill(e, items[index]);
                    }
                  }
                }} data={items} />
              }
              width={25}
            />

            <Column
              header={<Cell></Cell>}
              cell={
                <IconItemsCell onClick={this.handlerClickItem} data={items} />
              }
              width={25}
            />

            <Column
              header={<Cell></Cell>}
              cell={
                <IconStockItemsCell onClick={this.handlerClickItem} data={items} />
              }
              width={25}
            />
            <Column
              header={<SortHeaderCell
                className='text-center'
                onSortChange={this.onSortChange}
                field='status_stock'
                sortDir={this.state.colSortDirs.status_stock}>
                สถานะอัพเดทสต็อก
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top center' width={150} className='text-center' item='status' onClick={this.handlerClickItem} data={items} field="status_stock_display" />
              }
              width={150}
            />
            <Column
              header={<Cell>สาขา</Cell>}
              cell={
                <ItemsCell position='top left' width={120} onClick={this.handlerClickItem} data={items} field="branch_name" />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='bill_number'
                sortDir={this.state.colSortDirs.bill_number}>
                เลขที่บิล/เลขที่ Vat
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} onClick={this.handlerClickItem} data={items} field="bill_number" />
              }
              width={120}
            />
            {/* <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='tax_number'
                sortDir={this.state.colSortDirs.tax_number}>
                เลขที่ Vat
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} onClick={this.handlerClickItem} data={items} field="tax_number" />
              }
              width={120}
            /> */}
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='customer__name'
                sortDir={this.state.colSortDirs.customer__name}>
                ชื่อลูกค้า
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} onClick={this.handlerClickItem} data={items} field="customer_name" />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                className='text-center'
                onSortChange={this.onSortChange}
                field='bill_date'
                sortDir={this.state.colSortDirs.bill_date}>
                วันที่
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={80} onClick={this.handlerClickItem} data={items} field="date" textAlign='text-center' />
              }
              width={80}
            />
            <Column
              header={<SortHeaderCell
                className='text-center'
                onSortChange={this.onSortChange}
                field='bill_date'
                sortDir={this.state.colSortDirs.bill_date}>
                เวลา
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top center' width={60} onClick={this.handlerClickItem} data={items} field="time" textAlign='text-center' />
              }
              width={60}
            />

            <Column
              header={<SortHeaderCell
                className='text-right'
                onSortChange={this.onSortChange}
                field='sell'
                sortDir={this.state.colSortDirs.sell}>
                รวมเงินขาย
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={120} id='sumMoney1' onClick={this.handlerClickItem} data={items} field="total_sell" textAlign='text-right' />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                className='text-right'
                onSortChange={this.onSortChange}
                field='buy'
                sortDir={this.state.colSortDirs.buy}>
                รวมเงินซื้อ
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={120} id='sumMoney2' onClick={this.handlerClickItem} data={items} field="total_buy" textAlign='text-right' />
              }
              width={120}
            />
            <Column
              header={<Cell className='text-right'>รวมเงินเปลี่ยน</Cell>}
              cell={
                <ItemsCell position='top right' width={120} id='sumMoney2' onClick={this.handlerClickItem} data={items} field="exchange" textAlign='text-right' />
              }
              width={120}
            />
            <Column
              header={<Cell className='text-right'>รวมเงินที่ได้รับ</Cell>}
              cell={
                <ItemsCell position='top right' width={120} id='sumMoney2' onClick={this.handlerClickItem} data={items} field="total_net" textAlign='text-right' />
              }
              width={120}
            />
            {/* <Column
              header={<Cell className='text-right'>ส่วนลด</Cell>}
              cell={
                <ItemsCell position='top right' width={100} onClick={this.handlerClickItem} data={items} field="total_discount" textAlign='text-right' />
              }
              width={100}
            /> */}
            <Column
              header={<SortHeaderCell
                className='text-right'
                onSortChange={this.onSortChange}
                field='amount'
                sortDir={this.state.colSortDirs.amount}>
                จำนวนรายการ
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={120} onClick={this.handlerClickItem} data={items} field="amount" textAlign='text-right' />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                className='text-left'
                onSortChange={this.onSortChange}
                field='payment'
                sortDir={this.state.colSortDirs.payment}>
                ประเภทชำระ
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={180} onClick={this.handlerClickItem} data={items} field="payment_display" textAlign='text-left' />
              }
              width={180}
            />
            <Column
              header={<SortHeaderCell
                className='text-center'
                onSortChange={this.onSortChange}
                field='is_void'
                sortDir={this.state.colSortDirs.is_void}>
                สถานะ
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top center' width={100} onClick={this.handlerClickItem} data={items} field="status_bill" textAlign='text-center' />
              }
              width={100}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='canceler'
                sortDir={this.state.colSortDirs.canceler}>
                ผู้ยกเลิก
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={100} onClick={this.handlerClickItem} data={items} field="canceler_name" />
              }
              width={100}
            />
            <Column
              header={<Cell>บัญชีธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={180} onClick={this.handlerClickItem} data={items} field="transfer_bank_account_name" />
              }
              width={180}
            />
            <Column
              header={<Cell>ประเภทบัตรเครดิต</Cell>}
              cell={
                <ItemsCell position='top left' width={180} onClick={this.handlerClickItem} data={items} field="card_bank_card_display" />
              }
              width={180}
            />
            <Column
              header={<Cell>รหัสบัตรเครดิต</Cell>}
              cell={
                <ItemsCell position='top left' width={180} onClick={this.handlerClickItem} data={items} field="card_code" />
              }
              width={180}
            />
            <Column
              header={<SortHeaderCell
                className='text-right'
                onSortChange={this.onSortChange}
                field='weight_buy'
                sortDir={this.state.colSortDirs.weight_buy}>
                รวม น.น.ซื้อทอง(ก.)
              </SortHeaderCell>}
              cell={
                <ItemsCell position='top right' width={180} onClick={this.handlerClickItem} data={items} field="weight_buy_title" textAlign='text-right' />
              }
              width={180}
            />
            {/* <Column
              header={<Cell>เลขที่เช็ค</Cell>}
              cell={
                <ItemsCell position='top left' width={180} onClick={this.handlerClickItem} data={items} field="check_code" />
              }
              width={180}
            /> */}
            {/* <Column
              header={<Cell>เช็คลงวันที่</Cell>}
              cell={
                <ItemsCell position='top left' width={180} onClick={this.handlerClickItem} data={items} field="check_date_title" />
              }
              width={180}
            /> */}
            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} onClick={this.handlerClickItem} data={items} field="description" />
              }
              width={200}
            />
          </Table>
        </div>
      </div>
      <br />
      <Form size='small' >
        <Form.Group>
          <Form.Field id='totalRow' width={2} inline>
            <lable>จำนวน</lable>
            <Input readOnly className='text-right' value={Utility.numberFormat(this.props.bills_list.count)} />
          </Form.Field>
          <Form.Field width={14}>
            <br />
            {this.state.open_print ? <BillListPrintPreview
              data={
                {
                  start_date: this.state.start_date,
                  end_date: this.state.end_date
                }
              }
              items={this.setFieldValue(this.state.item_report)}
              onClose={() => {
                this.setState({ open_print: false });
              }}
            /> : ''}

            <Button id='btnPrintReport' content='พิมพ์รายงาน (F8)'
              icon='print' labelPosition='left'
              onClick={() => {
                this.setState({ open_print: true });
                this.searchReport()
              }} floated='right' type='button' primary />
            {this.props.bills_list.totalPages > 0 && <Pagination
              floated='right'
              size='tiny'
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={this.props.bills_list.totalPages}
            />}
          </Form.Field>
        </Form.Group>
      </Form>
    </div>)
  }
  hot_keys = {
    'f8': {
      priority: 1,
      handler: (event) => {
        this.setState({ open_print: true });
        this.searchReport()
        event.preventDefault()
      }
    }
  }
}

const mapStateToProps = state => {
  return ({
    bills_list: state.bills_list,
    auth: state.auth,
  })
}
const mapDispatchToProps = dispatch => ({
  onLoadBillList: qrstring => loadBills(dispatch, qrstring)
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(hotkeys(BillList))