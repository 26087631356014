/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Input, Modal, Grid, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);
        var currentTime = new Date()
        this.state = {
            is_all: true,
            is_sort: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                month: currentTime.getMonth() + 1,
                year: currentTime.getFullYear(),
            },
            months: this.getMonth(),
            years: this.getYear(),
            items: [],
            action: 'day'
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.handlerClick = this.handlerClick.bind(this)
    }

    getMonth() {
        let m = []
        for (let i = 1; i <= 12; i++) {
            m.push({
                value: i,
                key: i,
                text: i
            })
        }
        return m
    }

    getYear() {
        let d = new Date()
        let m = []
        for (let i = 2016; i <= d.getFullYear(); i++) {
            m.push({
                value: i,
                key: i,
                text: i
            })
        }
        return m
    }

    handlerClick(e, v) {
        this.resetForm()
        this.setState({
            action: v.action
        })
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var leases = Utility.getFetch(Settings.baseReportUrl + '/lease_data/?is_enabled=1&branch=' + this.state.search.branch);
        Promise.all([branches, leases]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.leases = values[1].lease;
            let leases = []
            for (let i in this.leases) {
                leases.push({
                    value: this.leases[i].id,
                    key: this.leases[i].id,
                    text: this.leases[i].number
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                leases: leases
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            var currentTime = new Date()
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY'),
                    month: currentTime.getMonth() + 1,
                    year: currentTime.getFullYear(),
                }
            })
        }
    }

    resetForm(e) {
        var currentTime = new Date()
        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                month: currentTime.getMonth() + 1,
                year: currentTime.getFullYear(),
            }
        })
    }

    getQsearch(key_start, key_end) {

        let search = Utility.cloneObjectJson(this.state.search)
        let q = Utility.cloneObjectJson(this.state.search)
        delete q.start_date
        delete q.end_date
        if (this.state.action == 'day') {
            q[key_start] = Utility.formatDate2(search.start_date)
            q[key_end] = Utility.formatDate2(search.end_date)
        } else if (this.state.action == 'month') {

            if (search.month == null) {
                alert('กรุณาเลือกเดือน')
                return
            }

            if (search.year == null) {
                alert('กรุณาเลือกปี')
                return
            }
            q[key_start] = search.year + '-' + search.month + '-01'
            let last_date = new Date(search.year, Number(search.month), 0).getDate()
            q[key_end] = search.year + '-' + search.month + '-' + last_date.toString().padStart(2, '0')
            delete q.month
            delete q.year
        } else if (this.state.action == 'year') {
            q[key_start] = search.year + '-01-01'
            q[key_end] = search.year + '-12-31'
            delete q.year
        } else if (this.state.action == 'range') {
            q[key_start] = Utility.formatDate2(search.start_date)
            q[key_end] = Utility.formatDate2(search.end_date)
        }
        else if (this.state.action == 'all') {
            q = {}
            q['branch'] = search.branch
        }

        return q
    }

    async handlerSubmit(e) {
        e.preventDefault();

        let fromData = Utility.jsonToQueryString(this.getQsearch('start_date', 'end_date'))
        let url = Settings.baseReportUrl + "/lease_report_2/?" + fromData

        fromData = Utility.jsonToQueryString(this.getQsearch('start_close_date', 'end_close_date'))
        let url_close = Settings.baseReportUrl + "/lease_report_2/?" + fromData

        fromData = Utility.jsonToQueryString(this.getQsearch('start_out_date', 'end_out_date'))
        let url_out = Settings.baseReportUrl + "/lease_report_2/?" + fromData

        this.setState({
            loading: true,
            open: true
        })
        const lease_data = await (await Utility.getAsync(url)).data
        let lease_in = lease_data.lease
        lease_in = lease_in.filter(item => item.status == 1 || item.status == 2 || item.status == 3)

        const lease_in_interest = lease_data.lease_interest

        let lease_close = await (await Utility.getAsync(url_close)).data.lease
        lease_close = lease_close.filter(item => item.status == 3)

        let lease_out = await (await Utility.getAsync(url_out)).data.lease
        lease_out = lease_out.filter(item => item.status == 4)

        let amount_1 = 0
        let total_1 = 0
        let total_interest_1 = 0;
        let amount_2 = 0
        let total_2 = 0
        let total_interest_2 = 0;
        let sum_interset = 0;

        let amount_3 = 0
        let total_3 = 0
        let total_interest_3 = 0;

        let amount_all = 0
        let total_all = 0
        for (let i in lease_in) {
            let item = lease_in[i]
            if (item.status == 1 || item.status == 2 || item.status == 3) {
                amount_1 += 1
                total_1 += parseFloat(item.amount)
                amount_all += 1
            }


        }

        for (let i in lease_in_interest) {
            let item = lease_in_interest[i]
            total_interest_1 += parseFloat(item.total_receive)
            total_all += parseFloat(item.total_receive)
            sum_interset += parseFloat(item.total_receive)
        }

        for (let i in lease_close) {
            let item = lease_close[i]

            if (item.status == 3) {
                amount_2 += 1
                total_2 += parseFloat(item.amount)
                for (let j in item.lease_interest_close) {
                    let interest_close = item.lease_interest_close[j]
                    total_interest_2 += parseFloat(interest_close.total_receive)
                    total_all += parseFloat(interest_close.total_receive)
                    sum_interset += parseFloat(interest_close.total_receive)
                }
                if (lease_in.find(l => l.id == item.id) == undefined) {
                    amount_all += 1
                }
                total_all += parseFloat(item.amount)
            }

        }

        for (let i in lease_out) {
            let item = lease_out[i]

            if (item.status == 4) {
                amount_3 += 1
                total_3 += parseFloat(item.amount)
                total_interest_3 += parseFloat(item.total_interest)
                if (lease_in.find(l => l.id == item.id) == undefined) {
                    amount_all += 1
                }
            }

        }

        this.setState({
            open: true,
            loading: false,
            amount_1: amount_1,
            amount_2: amount_2,
            amount_3: amount_3,
            amount_all: amount_all,
            sum_interset: Utility.priceFormat(sum_interset),
            total_1: Utility.priceFormat(total_1),
            total_2: Utility.priceFormat(total_2),
            total_3: Utility.priceFormat(total_3),
            total_all: Utility.priceFormat(total_all),
            total_interest_1: Utility.priceFormat(total_interest_1),
            total_interest_2: Utility.priceFormat(total_interest_2),
            total_interest_3: Utility.priceFormat(total_interest_3),
            //items: this.setFieldValue(d)
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.is_all == false) {
                if (item.status == 3 || item.status == 4)
                    continue
            }
            items.push(item)
        }

        return items
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {

        let title = 'รายงานยอดขายฝาก';
        let filename = 'lease-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const divStyle2 = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
            border: '1px solid black',
        };
        const textRight2 = {
            'text-align': 'right',
            border: '1px solid black',
        }

        const textLeft2 = {
            'text-align': 'left',
            border: '1px solid black',
        }
        const textCenter2 = {
            'text-align': 'center',
            border: '1px solid black',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }



        title = "สรุปรายงานขายฝากทั้งหมด";
        if (this.state.action != 'all' && this.state.action == 'range')
            title = "สรุปรายงานขายฝากวันที่ :" + Utility.formatDate(this.state.search.start_date) + " " + (this.state.search.start_date != this.state.search.end_date ? 'ถึง ' + Utility.formatDate(this.state.search.end_date) : '')
        if (this.state.action != 'all' && this.state.action == 'day')
            title = "สรุปรายงานขายฝากวันที่ :" + Utility.formatDate(this.state.search.start_date)
        if (this.state.action != 'all' && this.state.action == 'month')
            title = "สรุปรายงานขายฝากเดือน  " + this.state.search.month + " ปี " + this.state.search.year
        if (this.state.action != 'all' && this.state.action == 'year')
            title = "สรุปรายงานขายฝากปี  " + this.state.search.year
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>สรุปรายงานขายฝาก</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Grid divided='vertically'>
                    <Grid.Row columns={2}>
                        <Grid.Column>

                            <div className="box-login">
                                {this.state.action == 'day' ? <Segment textAlign='left' >
                                    <Header size='small'>สรุปรายวัน</Header>
                                    <Form size='small' onSubmit={this.handlerSubmit}>
                                        <Form.Field>
                                            <label>สาขา</label>
                                            <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                                        </Form.Field>
                                        <Form.Field >
                                            <label>วันที่</label>
                                            <DatePicker
                                                dateFormat="DD/MM/YYYY"
                                                value={this.state.search.start_date}
                                                selected={this.state.search.start_date}
                                                onChange={(date) => {
                                                    let search = {}
                                                    date = date ? date : moment()
                                                    search.start_date = date
                                                    search.end_date = date
                                                    // search.branch =  1,
                                                    var currentTime = new Date()
                                                    this.setState({
                                                        search: {
                                                            branch: this.state.search.branch,
                                                            start_date: date,
                                                            end_date: date,
                                                            month: currentTime.getMonth() + 1,
                                                            year: currentTime.getFullYear(),
                                                        }
                                                    })
                                                }}
                                            />
                                        </Form.Field>
                                        <Button onClick={this.handlerSubmit}>พิมพ์</Button>
                                    </Form>
                                </Segment> : ''}
                                {this.state.action == 'month' ? <Segment textAlign='left' >

                                    <Header size='small'>สรุปรายเดือน</Header>
                                    <Form size='small' onSubmit={this.handlerSubmit}>
                                        <Form.Field>
                                            <label>สาขา</label>
                                            <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>เดือน</label>
                                            <Dropdown search selection options={this.state.months} value={this.state.search.month} name='month' onChange={this.handlerInput} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>ปี</label>
                                            <Dropdown search selection options={this.state.years} value={this.state.search.year} name='year' onChange={this.handlerInput} />
                                        </Form.Field>
                                        <Button onClick={this.handlerSubmit}>พิมพ์</Button>
                                    </Form>
                                </Segment> : ''}
                                {this.state.action == 'year' ? <Segment textAlign='left' >

                                    <Header size='small'>สรุปรายปี</Header>
                                    <Form size='small' onSubmit={this.handlerSubmit}>
                                        <Form.Field>
                                            <label>สาขา</label>
                                            <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>ปี</label>
                                            <Dropdown search selection options={this.state.years} value={this.state.search.year} name='year' onChange={this.handlerInput} />
                                        </Form.Field>
                                        <Button onClick={this.handlerSubmit}>พิมพ์</Button>
                                    </Form>
                                </Segment> : ''}

                                {this.state.action == 'range' ? <Segment textAlign='left' >

                                    <Header size='small'>สรุปตามช่วงวันที่</Header>
                                    <Form size='small' onSubmit={this.handlerSubmit}>
                                        <Form.Field>
                                            <label>สาขา</label>
                                            <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                                        </Form.Field>
                                        <Form.Field >
                                            <label>จากวันที่</label>
                                            <DatePicker
                                                dateFormat="DD/MM/YYYY"
                                                value={this.state.search.start_date}
                                                selected={this.state.search.start_date}
                                                onChange={(date) => {
                                                    date = date ? date : moment()
                                                    if (this.state.search.end_date < date) {
                                                        this.handlerInput(null, {
                                                            name: 'end_date',
                                                            value: date
                                                        });
                                                    }
                                                    this.handlerInput(null, {
                                                        name: 'start_date',
                                                        value: date
                                                    });
                                                }}
                                            />
                                        </Form.Field>
                                        <Form.Field >
                                            <label>ถึงวันที่</label>
                                            <DatePicker
                                                dateFormat="DD/MM/YYYY"
                                                value={this.state.search.end_date}
                                                selected={this.state.search.end_date}
                                                minDate={this.state.search.start_date}
                                                onChange={(date) => {
                                                    date = date ? date : moment()
                                                    this.handlerInput(null, {
                                                        name: 'end_date',
                                                        value: date
                                                    });
                                                }}
                                            />
                                        </Form.Field>
                                        <Button onClick={this.handlerSubmit}>พิมพ์</Button>
                                    </Form>
                                </Segment> : ''}
                                {this.state.action == 'all' ? <Segment textAlign='left' >
                                    <Header size='small'>สรุปทั้งหมด</Header>
                                    <Form size='small' onSubmit={this.handlerSubmit}>
                                        <Form.Field>
                                            <label>สาขา</label>
                                            <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                                        </Form.Field>
                                        <center>
                                            <Button onClick={this.handlerSubmit}>พิมพ์</Button>
                                        </center>
                                    </Form>
                                </Segment> : ''}
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <br /><br />
                            <Button fluid action='day' onClick={this.handlerClick}>สรุปรายวัน</Button>
                            <br />
                            <Button fluid action='month' onClick={this.handlerClick}>สรุปรายเดือน</Button>
                            <br />
                            <Button fluid action='year' onClick={this.handlerClick}>สรุปรายปี</Button>
                            <br />
                            <Button fluid action='range' onClick={this.handlerClick}>สรุปตามช่วงวันที่</Button>
                            <br />
                            <Button fluid action='all' onClick={this.handlerClick}>สรุปทั้งหมด</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Modal open={this.state.open} onClose={this.close} /*dimmer='blurring'*/>
                    <Modal.Header><center>{title}</center></Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <Table>
                            <Table.Body>

                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนรายการขายฝาก</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='amount_1' value={Utility.numberFormat(this.state.amount_1)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >ยอดเงินขายฝาก</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_1' value={this.state.total_1} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} colSpan='3'>ดอกเบี้ยต่อดอก</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_interest_1' value={this.state.total_interest_1} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนรายการไถ่คืน</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='amount_2' value={Utility.numberFormat(this.state.amount_2)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >ยอดเงินต้นคืน</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_2' value={this.state.total_2} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} colSpan='3'>ดอกเบี้ยไถ่คืน</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_interest_2' value={this.state.total_interest_2} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนรายการคัดออก</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='amount_3' value={Utility.numberFormat(this.state.amount_3)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >ยอดเงินต้นคัดออก</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_3' value={this.state.total_3} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} colSpan='3'>ดอกเบี้ยรับ</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_interest_3' value={this.state.total_interest_3} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนรายการทั้งหมด</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='amount_all' value={Utility.numberFormat(this.state.amount_all)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >ยอดเงินสดรับทั้งหมด</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='total_all' value={this.state.total_all} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} colSpan='3'>ยอดรายได้จากการขายฝากทั้งหมด</Table.Cell>
                                    <Table.Cell style={textRight} ><Input readOnly id='sum_interset' value={this.state.sum_interset} className='text-right' /></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button id='btnPrintmodal' size='small' type='button' onClick={() => { this.setState({ open2: true, open: false }) }} primary>พิมพ์</Button>
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.open2} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>

                                        <Table.Row>
                                            <Table.Cell style={textRight} >จำนวนรายการขายฝาก</Table.Cell>
                                            <Table.Cell id='amount_lease' style={textRight} >{this.state.amount_1}</Table.Cell>
                                            <Table.Cell style={textRight} >ยอดเงินขายฝาก</Table.Cell>
                                            <Table.Cell id='total_lease' style={textRight} >{this.state.total_1}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='3'>ดอกเบี้ยต่อดอก</Table.Cell>
                                            <Table.Cell id='total_interest_lease' style={textRight} >{this.state.total_interest_1}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} >จำนวนรายการไถ่คืน</Table.Cell>
                                            <Table.Cell id='amount_redeem' style={textRight} >{this.state.amount_2}</Table.Cell>
                                            <Table.Cell style={textRight} >ยอดเงินต้นคืน</Table.Cell>
                                            <Table.Cell id='total_redeem' style={textRight} >{this.state.total_2}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='3'>ดอกเบี้ยไถ่คืน</Table.Cell>
                                            <Table.Cell id='total_interest_redeem' style={textRight} >{this.state.total_interest_2}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} >จำนวนรายการคัดออก</Table.Cell>
                                            <Table.Cell id='amount_eject' style={textRight} >{this.state.amount_3}</Table.Cell>
                                            <Table.Cell style={textRight} >ยอดเงินต้นคัดออก</Table.Cell>
                                            <Table.Cell id='total_eject' style={textRight} >{this.state.total_3}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='3'>ดอกเบี้ยรับ</Table.Cell>
                                            <Table.Cell id='total_interest_eject' style={textRight} >{this.state.total_interest_3}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} >จำนวนรายการทั้งหมด</Table.Cell>
                                            <Table.Cell id='amount_all' style={textRight} >{this.state.amount_all}</Table.Cell>
                                            <Table.Cell style={textRight} >ยอดรับทั้งหมด</Table.Cell>
                                            <Table.Cell id='total_all' style={textRight} >{this.state.total_all}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='3'>ยอดรายได้จากการขายฝากทั้งหมด	</Table.Cell>
                                            <Table.Cell id='sum_interset' style={textRight} >{this.state.sum_interset}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open2: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)