/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Header, Button, Loader, Dimmer, Icon, Pagination, Input
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import BillTable from './BillTable'
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import BillModal from './BillModal';
import { connect } from 'react-redux'

class LotMelt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      lot_melt_detail: '',
      start_date: moment(),
      end_date: moment(),
      table_hiegh: Settings.table_hiegh,
      page: 1,
      totalPages: 1,
      qrstring: '',
      colSortDirs: {
        'number': 'ASC',
      },
      counter:0,
      number: '',
      is_clear_stock: false,
    }
    this.columnKey = 'number'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.onClickitem = this.onClickitem.bind(this);
    this.loaddata = this.loaddata.bind(this);
    this.loaddata2 = this.loaddata2.bind(this);
  }

  onSortChange(columnKey, sortDir) {
    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
      page: 1,
    });
  }

  componentDidMount() {
    this.loaddata(1)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loaddata2(activePage)
  }

  loaddata(page) {
    this.setState({
      loader_active: true,
    });
    var s = '';
    if (this.state.end_date != null && this.state.end_date != '') {
      s += (s == '' ? '' : '&') + 'end_date=' + Utility.formatDate2(this.state.end_date);
    } else {
      this.setState({ 'end_date': this.state.start_date })
    }
    if (this.state.start_date != null && this.state.start_date != '') {
      s += (s == '' ? '' : '&') + 'start_date=' + Utility.formatDate2(this.state.start_date);
    }
    if (this.state.is_clear_stock) {
      s += (s == '' ? '' : '&') + 'status_stock=N';
    }
    if (this.state.number) {
      s += (s == '' ? '' : '&') + 'number=' + this.state.number;
    }
    this.setState({ qrstring: s })
    var lot_melt = Utility.getFetch(Settings.baseUrl + '/lot_melt_list/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + s + '&page=' + page);
    Promise.all([lot_melt]).then((values) => {
      var items = this.setFieldValue(values[0].results);
      this.setState({
        items: items,
        loader_active: false,
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter: values[0].count
      });
      let elHeight = document.getElementById('table_width')
      if (elHeight)
        this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 400 });
    });
  }

  loaddata2(page) {
    this.setState({
      loader_active: true,
    });
    var lot_melt = Utility.getFetch(Settings.baseUrl + '/lot_melt_list/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + this.state.qrstring + '&page=' + page);
    Promise.all([lot_melt]).then((values) => {
      var items = this.setFieldValue(values[0].results);
      this.setState({
        items: items,
        loader_active: false,
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter: values[0].count
      });
      let elHeight = document.getElementById('table_width')
      if (elHeight)
        this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 400 });
    });
  }

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]['status_stock_title'] = Settings.status_stock[item[i].status_stock]
      item[i]['lot_date'] = Utility.formatDate(item[i].lot_date)
      item[i]['amount'] = Utility.numberFormat(item[i].amount)
      item[i]['weight_out'] = item[i].weight_out
    }
    return item;
  }

  clearFormSearch() {
    this.setState({
      start_date: moment(),
      end_date: moment(),
      number: "",
      is_clear_stock: false,
    })
  }

  onClickitem(e, d) {
    let data = this.state.items[d.positon];
    e.preventDefault();
    this.setState({
      open_modal_lot_melt: true,
      lot_melt_detail: data,
      modal_action: 'edit'
    });
  }

  render() {
    let items = this.state.items;
    return (
      <div className='invoice-box'>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={8}>
              <Header floated='left' as='h2'>รวมบิลส่งหลอม</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button size='small' content='สร้างใบรวมบิลส่งหลอม' onClick={() => {
                this.setState({ open_modal_lot_melt: true, modal_action: 'add' });
              }} floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <Form className='attached fluid' size='small'>
          <Form.Group>
            <Form.Field width={4}>
              <label>เลขที่ Lot</label>
              <Input id='number' fluid onChange={(e, data) => {
                this.setState({ number: data.value });
              }}
                name="number"
                placeholder='เลขที่ Lot'
                value={this.state.number} />
            </Form.Field>
            <Form.Field width={4}>
              <label>จากวันที่</label>
              <DatePicker
                id='searchstartdate'
                dateFormat="DD/MM/YYYY"
                selected={this.state.start_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.end_date < date) {
                    this.setState({ end_date: date });
                  }
                  this.setState({ start_date: date });
                }}
              />
            </Form.Field>
            <Form.Field width={4} >
              <label>ถึงวันที่</label>
              <DatePicker
                id='searchenddate'
                dateFormat="DD/MM/YYYY"
                minDate={this.state.start_date}
                selected={this.state.end_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  this.setState({ end_date: date });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Checkbox width={3} id='update' label='ยังไม่ปรับปรุงสต็อก' floated='right'
              checked={this.state.is_clear_stock}
              value={this.state.is_clear_stock} onChange={(e, v) => {
                this.setState({
                  is_clear_stock: !this.state.is_clear_stock
                });
              }} 
            />
            <Form.Field width={13}>
              <Button id='clear_search' floated='right' type='button' size='small' onClick={() => {
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
              <Button id='searchtoday' floated='right' type='button' size='small' onClick={() => {
                this.clearFormSearch();
                setTimeout(() => {
                  this.setState({ page: 1 })
                  this.loaddata(1);
                }, 500)
              }}  ><Icon name='search' /> วันนี้</Button>
              <Button id='search' floated='right' type='button' size='small' onClick={() => {
                this.setState({ page: 1 })
                this.loaddata(1);
              }} ><Icon name='search' /> ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer active={this.state.loader_active} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>}
        <div id="table_width">
          <BillTable items={items} table_width={this.state.table_width} onActionItemCell={this.onClickitem} onSortChange={this.onSortChange} colSortDirs={this.state.colSortDirs} />
          <br />
          <Form size='small'>
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
              <br />
              <Form.Field width={14}>
                <br />
                {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        <br />
        <Form size='small' >
          <Form.Group>
            <Form.Field width={16}>
              <br />
              {this.state.open_modal_lot_melt ? <BillModal
                modal_action={this.state.modal_action}
                lot_melt_detail={this.state.lot_melt_detail}
                open={true}
                onClose={() => {
                  this.setState({ open_modal_lot_melt: false });
                  this.loaddata2(this.state.page)
                }}
                onAddInvoice={(d) => {
                  if (d != null) {
                    this.setState({
                      lot_melt_detail: d,
                      modal_action: 'edit'
                    });
                  }
                }}
              /> : ''}
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(LotMelt)