/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { Table, Column, Cell } from 'fixed-data-table';

import Utility from '../../Utility';
import Settings from '../../Settings';
import CalculateDay from './CalculateDay';
import PaymentModal from '../Payment/PaymentModal';
import MsgInput from '../Error/MsgInput'
import {
    Form,
    Input,
    Button,
    TextArea,
    Modal,
    Label,
    Grid,
    Select,
    Dropdown,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { lease_modal_edit } from '../../actions'
import { connect } from 'react-redux'

class PaymentInterest extends Component {

    constructor(props) {
        super(props);

        let auto_action_lease = localStorage.getItem('auto_action_lease')
        this.state = {
            action: auto_action_lease,
            open: auto_action_lease == 'interest' || auto_action_lease == 'redeem',
            title: auto_action_lease == 'redeem'
                ? 'ไถ่คืน'
                : 'ต่อดอก',
            type: auto_action_lease == 'redeem'
                ? '2'
                : '1',
            interest_date: moment(this.props.data.interest_date),
            end_date: moment(this.props.data.end_date),
            start_date: moment(this.props.data.start_date),
            end_date_redeem: moment(Date.now()),
            pay_date: moment(),
            // type2: this.props.setting.calculate_date,
            type2: auto_action_lease == 'redeem'
                ? 1
                : 6,
            total_pay: 0,
            month_option: this.getMontOption(),
            description: '',
            error: [],
        }
        if (auto_action_lease == 'interest' || auto_action_lease == 'redeem')
            localStorage.setItem('auto_action_lease', '')
        this.handelCheckbox = this
            .handelCheckbox
            .bind(this)
        this.handelMonth = this
            .handelMonth
            .bind(this)
        this.handelCheckbox2 = this
            .handelCheckbox2
            .bind(this)
        this.handlerInput = this
            .handlerInput
            .bind(this)
        this.openModalInterest = this
            .openModalInterest
            .bind(this)
        this.open = this
            .open
            .bind(this)
        this.handelCalculat = this
            .handelCalculat
            .bind(this)
        this.handelSubmitBill = this
            .handelSubmitBill
            .bind(this)
        this.handelSubmit = this.handelSubmit.bind(this)
        this.handlerInputTotal = this.handlerInputTotal.bind(this)
        this.GetQueue = this.GetQueue.bind(this)
    }

    getMontOption() {
        let month_option = [];
        for (let i = 1; i <= 12; i++) {
            month_option.push({ key: i, value: i, text: i })
        }
        return month_option;
    }

    open() {
        this.setState({ open: true })
        this.handelCalculat();
    }
    close = () => this.setState({ open: false })

    handelCheckbox(e, v) {
        this.setState({
            type: v.value,
            title: v.value == '1'
                ? 'ต่อดอก'
                : 'ไถ่คืน'
        })
    }

    handelCheckbox2(e, v) {

        this.setState({
            type2: v.value,
        })
        if (this.state.type == 1) {
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date, v.value)
        } else {
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date_redeem, v.value)
        }
    }

    componentDidMount() {
        const { branch } = this.props.auth
        let staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1&branch=' + branch.id);
        Promise.all([staffs]).then((values) => {
            this.staffs = values[0];
            let staffs = [];
            for (let i in this.staffs) {
                staffs.push({
                    key: this.staffs[i].id,
                    value: this.staffs[i].id,
                    text: this.staffs[i].name,
                })
            }

            this.setState({
                st: false,
                staffs: staffs
            })
        });
        if (this.state.type == 1) {
            let interest_date = new Date(this.props.data.interest_date)
            let start_date = (new Date(this.props.data.start_date))
            let month = (interest_date.getMonth() + this.props.data.month) % 12
            let year = interest_date.getFullYear() + parseInt((interest_date.getMonth() + this.props.data.month) / 12)
            let lastday = this.lastDay(year + 1, month)
            let date = start_date.getDate() > lastday ? lastday : start_date.getDate()
            let end = moment(new Date(year, month, date))
            this.setState({
                end_date: end,
                month_select: this.props.data.month
            }, () =>
                this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date, this.state.type2)
            )
        } else {
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date_redeem, this.state.type2)
        }
    }

    lastDay(y, m) {
        return new Date(y - 1, m + 1, 0).getDate()
    }

    handelMonth(e, v) {
        let interest_date = new Date(this.state.interest_date)
        let start_date = (new Date(this.state.start_date))
        let month = (interest_date.getMonth() + v.value) % 12
        let year = interest_date.getFullYear() + parseInt((interest_date.getMonth() + v.value) / 12)
        let lastday = this.lastDay(year + 1, month)
        let date = start_date.getDate() > lastday ? lastday : start_date.getDate()
        let end = moment(new Date(year, month, date))
        // this.setState({
        //     end_date: end,
        //     month_select: v.value
        // })
        if (this.state.type == 1)
            this.setState({
                end_date: end,
                month_select: v.value
            })
        else
            this.setState({
                end_date_redeem: end,
                month_select: v.value
            })

        this.setAmountMonthAndDay(this.state.interest_date, end, this.state.type2)
    }

    setAmountMonthAndDay(start, end, type2, is_submit = false) {
        const a = moment(new Date(start));
        const b = moment(new Date(end));
        const diff = b.diff(a, 'days')
        const d = diff % 30
        let m = parseInt(diff / 30)
        let m_cal = m
        let is_min = false

        if (d > 0) {
            if (type2 == 1) {
                m_cal += (d / 30)
            } else if (type2 == 2) {
                m_cal += (1 / 4)
            } else if (type2 == 3) {
                m_cal += (1 / 2)
            } else if (type2 == 4) {
                m_cal += (3 / 4)
            } else if (type2 == 5) {
                m_cal += 1
            } else if (type2 == 6) {
                m_cal = Math.round(diff / 30)
            }
        }

        let total = m_cal
            ? (parseFloat(this.props.data.amount) * parseFloat(this.props.data.interest) / 100) * parseFloat(m_cal)
            : 0
        if (total < this.props.setting.min_interest) {
            total = this.props.setting.min_interest
            is_min = true
        }

        let net_price = Utility.removeCommas(this.state.total_pay)
        if (this.state.type == 2) {
            net_price = (parseFloat(Utility.removeCommas(this.props.data.amount)) + parseFloat(net_price))
        }

        const total_pay = this.state.total_pay
        this.setState({
            day: d,
            month: m,
            month_cal: m_cal,
            is_min: is_min,
            net_price: net_price,
            total: parseFloat(total).toFixed(0),
            total_pay: is_submit ? total_pay : parseFloat(total).toFixed(0)
        })
    }

    handlerInput(e, v) {
        // if(v.name == 'description' && v.value.length > 255){
        //     return
        // }
        this.setState({
            [v.name]: v.value
        })
    }

    handelSubmit(e) {
        let error = {}
        if (this.state.staff_id == null || this.state.staff_id == 0) {
            error['staff_id'] = 'กรุณาเลือกพนักงาน'
        }
        if (this.state.total_pay == null || this.state.total_pay == '' || this.state.total_pay == undefined) {
            error['total_pay'] = 'กรุณากรอกดอกเบี้ยรับ'
        } else {
            if (Number(this.state.total_pay) < Number(this.props.setting.min_interest))
                error['total_pay'] = `ไม่สามารถกรอกดอกเบี้ยรับต่ำกว่าดอกเบี้ยขายฝากขั้นต่ำ (${this.props.setting.min_interest}) ได้`
        }
        this.setState({ error: error })
        if (Object.keys(error).length > 0) {
            return
        }
        if (this.state.type == 1)
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date, this.state.type2, true)
        else
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date_redeem, this.state.type2, true)
        this.totalPayInput.focus()
        this.setState({ submit_loading: true })
        if (this.state.description.length > 255) {
            alert('ความยาวหมายเหตุต้องไม่เกิน 255 ตัวอักษร')
            this.setState({ submit_loading: false })
        } else {
            setTimeout(() => {
                this.setState({ submit_loading: false })
                this.setState({
                    modal_lease_cashier: true
                })
            }, 800)
        }
    }

    handelCalculat(e) {
        if (this.state.type == 1) {
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date, this.state.type2)
        } else {
            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date_redeem, this.state.type2)
        }
        this.totalPayInput.focus()
    }

    openModalInterest() { }

    async handelSubmitBill(ledger, data_savings, point) {

        ledger['ledger_category'] = this.state.type == '1' ? 7 : 10

        if (ledger['ledger_category'] == 10) {
            const url = Settings.baseUrl + "/lease/" + this.props.data.id + "/interest/"
            const res = await Utility.getAsync(url)

            let count = 0
            res.data.map((i, item) => {
                if (item.status == 3)
                    count++
            })
            if (count > 0) {
                alert('ไม่สามารถ ไถ่คืนซ้ำได้')
                return;

            }
        }
        // if (data_savings.savings) {
        //     this.submitsaving(data_savings, ledger);
        // }
        // else
        //     this.submitLedger(ledger);

        this.submitinterestv3(ledger, data_savings, point)
    }



    submitinterestv3(ledger, savings, point) {
        this.setState({ loading_lease_cashier: true })
        let total = this.state.month_cal
            ? (parseFloat(this.props.data.amount) * parseFloat(this.props.data.interest) / 100) * parseFloat(this.state.month_cal)
            : 0
        if (total < this.props.setting.min_interest) {
            total = this.props.setting.min_interest
        }
        let status = this.state.type == '1'
            ? 2
            : 3;
        let interest = {
            lease: this.props.data.id,
            // ledger: d.id,
            pay_date: Utility.formatDate2(this.state.pay_date),
            interest_date: this.state.type == '1' ? Utility.formatDate2(this.state.end_date) : Utility.formatDate2(this.state.end_date_redeem),
            total_receive: Utility.removeCommas(this.state.total_pay),
            total: parseFloat(total).toFixed(0),
            description: this.state.description,
            status: status,
            point: point,
        };

        if (interest.description == '' || interest.description == null)
            delete interest.description
        if (ledger.card_period == '' || ledger.card_period == null)
            delete ledger.card_period
        if (ledger.card_start == '' || ledger.card_start == null)
            delete ledger.card_start
        if (ledger.check_bank == '' || ledger.check_bank == null)
            delete ledger.check_bank
        if (ledger.check_date == '' || ledger.check_date == null)
            delete ledger.check_date
        if (ledger.card_service == '' || ledger.card_service == null)
            delete ledger.card_service

        savings['kind'] = this.state.type == '1' ? 3 : 4
        savings['object_id'] = this.props.data.id
        savings['object_number'] = this.props.data.number
        savings['branch'] = this.props.data.branch.id
        savings['staff'] = this.state.staff_id
        savings['remark'] = ''

        let data = {
            ledger: ledger,
            interest: interest,
            savings: savings
        }

        let url = Settings.baseUrlV3 + "/lease/" + this.props.data.id + "/interest/"
        Utility.postJson(url, data, (s, d) => {
            this.GetQueue(d.qid)
        });
    }

    GetQueue(id) {
        setTimeout(() => {
            let url = Settings.baseUrlV3 + "/queue/" + id + "/"
            Utility.get(url, (e, res) => {
                if (res.result.status == 0) {
                    console.log('Interval Get Lease Create - - > ', 'RUN');
                    this.GetQueue(id)
                } else {
                    if (res.result.status == 1) {
                        alert('บันทึกข้อมูลสำเร็จ!');
                        if (res.result.saving_item != null) {
                            this.setState({ detail: true })
                        }
                        this.setState({ loading_lease_cashier: false, modal_lease_cashier: false, open: false })
                        if (res.result.saving_obj) {
                            this.props.onSave(res.result.saving_obj)
                        } else {
                            this.props.onSave()
                        }
                        lease_modal_edit(this.props.dispatch, this.props.data.id)
                        console.log('Interval Get Lease Create - - >', 'SUC');
                    } else {
                        console.log('Interval Get Lease Create - - >', 'ERR');
                        alert(res.result.message)
                        this.setState({ open: false })
                        this.props.onSave()
                        lease_modal_edit(this.props.dispatch, this.props.data.id)
                        return;
                    }

                }
            })

        }, 1000);
    }

    submitsaving(data, ledger) {
        let dt = {
            kind: this.state.type == '1' ? 3 : 4,
            object_id: this.props.data.id,
            object_number: this.props.data.number,
            branch: this.props.data.branch.id,
            staff: this.state.staff_id,
            amount: Number(data.amount),
            remark: ''
        }
        let url = Settings.baseUrl + "/saving/" + data.savings_id + "/payment/";
        Utility.post(url, dt, (s, d) => {
            if (s) {
                this.submitLedger(ledger, d.saving_item, d.savings);
            }
            else
                alert('ชำระเงิน ผิดพลาดกรุณาลองอีกครั้ง')
        });
    }

    cancelSaving(saving, saving_item) {
        let formData = {
            'saving_item': saving_item,
            'remark': 'ยกเลิกเนื่องจากต่อดอกไม่สำเร็จ',
        }
        let url = Settings.baseUrl + "/saving/" + saving + "/cancel/"
        Utility.post(url, formData, (s, d, code) => { });
    }

    cancelLedger(ledger) {
        let formData = {
            'status': 0,
            'description': 'ยกเลิกเนื่องจากต่อดอกไม่สำเร็จ',
        }
        let url = Settings.baseUrl + "/ledger/" + ledger + "/"
        Utility.patch(url, formData, (s, d, code) => { });
    }

    submitLedger(formData2, saving_item, saving) {
        // save ledger
        this.setState({ loading_lease_cashier: true })

        let total = this.state.month_cal
            ? (parseFloat(this.props.data.amount) * parseFloat(this.props.data.interest) / 100) * parseFloat(this.state.month_cal)
            : 0
        if (total < this.props.setting.min_interest) {
            total = this.props.setting.min_interest
        }

        let url = Settings.baseUrl + "/ledger/"
        formData2['customer'] = this.props.data.customer.id
        Utility.post(url, formData2, (s, d, code) => {

            if (!s) {
                if (saving) {
                    this.cancelSaving(saving.id, saving_item)
                }
                alert(d.error)
                this.setState({ open: false })
                this.props.onSave()
                lease_modal_edit(this.props.dispatch, this.props.data.id)
                return;
            }
            // this.setState({ loading_lease_cashier: false, modal_lease_cashier: false })

            // save interest
            let status = this.state.type == '1'
                ? 2
                : 3;
            let formData = {
                lease: this.props.data.id,
                ledger: d.id,
                pay_date: Utility.formatDate2(this.state.pay_date),
                interest_date: this.state.type == '1' ? Utility.formatDate2(this.state.end_date) : Utility.formatDate2(this.state.end_date_redeem),
                total_receive: Utility.removeCommas(this.state.total_pay),
                total: parseFloat(total).toFixed(0),
                description: this.state.description,
                status: status
            };
            this.submitInterest(formData, saving_item, saving);
        });
    }
    async submitsavinglog(data) {

        let url = Settings.baseUrl + "/saving_payment/";
        Utility.post(url, data, (s, d) => {
            if (s) {
                alert("บันทึกบิลและชำระเงินสำเร็จ");
            } else alert("ชำระเงิน ผิดพลาดกรุณาลองอีกครั้ง");
        });
    }
    submitInterest(formData, saving_item, saving) {

        let url = Settings.baseUrl + "/lease/" + this.props.data.id + "/interest/"
        Utility.post(url, formData, (s, d) => {
            this.setState({ loading_lease_cashier: false, modal_lease_cashier: false })
            if (s) {
                if (saving_item != undefined) {
                    let dt = {
                        savings_item: saving_item,
                        object_id: d.id,
                        kind: this.state.type == '1' ? 3 : 4,
                    }
                    this.submitsavinglog(dt)
                } else {
                    alert('บันทึกข้อมูลสำเร็จ');
                }
                if (saving_item != undefined) {
                    this.setState({ detail: true })
                }
                this.setState({ open: false })
                this.props.onSave(saving)
                lease_modal_edit(this.props.dispatch, this.props.data.id)
            } else {
                if (saving) {
                    this.cancelSaving(saving.id, saving_item)
                }
                this.cancelLedger(formData.ledger)
                alert(d.error)
            }
        });
    }

    handlerInputTotal(e, v) {
        if (isNaN(Number(v.value))) {
        } else {
            if (v.value.toString().split(".")[1] != undefined)
                if (v.value.toString().split(".")[1].length > 2)
                    return
            if (v.value < 0) {
                v.value = 0
            }
            this.setState({
                total_pay: v.value,
                net_price: this.state.type === 2 ? (parseFloat(Utility.removeCommas(this.props.data.amount)) + v.value) : v.value
            })
        }
    }

    render() {
        return (
            <Modal id='modalInterest' dimmer={true} open={this.props.open || this.state.action != ''} size='small'>
                <Button
                    id='btnCloseInterest'
                    circular
                    icon='close'
                    basic
                    floated='right'
                    name=''
                    onClick={this.props.onClose} />
                <Modal.Header>
                    {this.state.type == '1'
                        ? 'ต่อดอก'
                        : 'ไถ่คืน'}
                </Modal.Header>
                <Modal.Content className='scrolling'>
                    {this.props.data.branch.id == this.props.auth.branch.id ?
                        <>
                            <center>
                                <Button.Group size='small'>
                                    <Button id='btninterest'
                                        onClick={() => {
                                            this.setState({ type: '1', type2: 6, month_select: this.props.data.month, })
                                            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date, 6)
                                        }}
                                        color={this.state.type == '1'
                                            ? 'green'
                                            : ''}>ต่อดอก</Button>
                                    <Button.Or />
                                    <Button id='redeem'
                                        onClick={() => {
                                            this.setState({
                                                end_date: moment(new Date(this.props.data.end_date)),
                                                type: '2',
                                                month_select: null,
                                                type2: 1,
                                            })
                                            this.setAmountMonthAndDay(this.state.interest_date, this.state.end_date_redeem, 1)
                                        }}
                                        color={this.state.type == '2'
                                            ? 'green'
                                            : ''}>ไถ่คืน</Button>
                                </Button.Group>
                            </center>
                            <br />
                        </>
                        : null}
                    {this.state.modal_lease_cashier && <PaymentModal
                        object_number={this.props.data.number}
                        object_id={this.props.data.id}
                        total={this.state.net_price}
                        customer={this.props.data.customer.id}
                        onClose={() => {
                            this.setState({ modal_lease_cashier: false })
                        }}
                        staff={this.state.staff_id}
                        loading={this.state.loading_lease_cashier}
                        loading_message='กำลังดำเนินการต่อดอก / ไถ่คืน'
                        giving_point={true}
                        onSubmit={this.handelSubmitBill} />}
                    <Form unstackable size='small'>
                        <table>
                            {this.state.type == '1' ?
                                <tr>
                                    <td
                                        style={{
                                            width: '20%',
                                            textAlign: 'right'
                                        }}>
                                        <label>จำนวนเดือน</label>
                                    </td>
                                    <td
                                        style={{
                                            width: '30%'
                                        }}>
                                        <Select
                                            readOnly={this.state.type == '2'}
                                            id='selectMonth'
                                            fluid
                                            onChange={this.handelMonth}
                                            options={this.state.month_option}
                                            value={this.state.month_select} /></td>
                                    <td
                                        style={{
                                            width: '15%'
                                        }}></td>
                                    <td
                                        style={{
                                            width: '30%'
                                        }}></td>
                                    <td
                                        style={{
                                            width: '5%'
                                        }}></td>
                                </tr>
                                : ''}
                            <tr>
                                <td
                                    style={{
                                        width: '20%',
                                        textAlign: 'right'
                                    }}>
                                    <label>ตั้งแต่วันที่</label>
                                </td>
                                <td
                                    style={{
                                        width: '30%'
                                    }}><DatePicker
                                        readOnly={true}
                                        selected={this.state.interest_date}
                                        selectsStart
                                        dateFormat="DD/MM/YYYY"
                                        startDate={this.state.interest_date}
                                        endDate={this.state.end_date}
                                        onChange={(d) => {
                                            d = d ? d : moment()
                                            if (this.state.end_date < d) {
                                                this.setState({ end_date: d });
                                            }
                                            if (this.state.end_date_redeem < d) {
                                                this.setState({ end_date_redeem: d });
                                            }
                                            this.setState({ interest_date: d })
                                            if (this.state.type == 1)
                                                this.setAmountMonthAndDay(d, this.state.end_date, this.state.type2)
                                            else
                                                this.setAmountMonthAndDay(d, this.state.end_date_redeem, this.state.type2)
                                        }} /></td>
                                <td
                                    style={{
                                        width: '20%',
                                        textAlign: 'right'
                                    }}>
                                    <label>ถึงวันที่</label>
                                </td>
                                <td
                                    style={{
                                        width: '30%'
                                    }}>
                                    {this.state.type == '1' ? <DatePicker
                                        selected={this.state.end_date}
                                        selectsEnd
                                        dateFormat="DD/MM/YYYY"
                                        startDate={this.state.interest_date}
                                        minDate={this.state.interest_date}
                                        readOnly={true}
                                        endDate={this.state.end_date}
                                        onChange={(d) => {
                                            d = d ? d : moment()
                                            this.setState({ end_date: d })
                                            this.setAmountMonthAndDay(this.state.interest_date, d, this.state.type2)
                                        }} /> : <DatePicker
                                        readOnly={this.state.type == '2'}
                                        selected={this.state.end_date_redeem}
                                        selectsEnd
                                        dateFormat="DD/MM/YYYY"
                                        minDate={this.state.interest_date}
                                        startDate={this.state.interest_date}
                                        endDate={this.state.end_date_redeem}
                                        onChange={(d) => {
                                            d = d ? d : moment()
                                            this.setState({ end_date_redeem: d })
                                            this.setAmountMonthAndDay(this.state.interest_date, d, this.state.type2)
                                        }} />}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '20%',
                                        textAlign: 'right'
                                    }}>
                                    <label>คิดเป็นเวลา</label>
                                </td>
                                <td
                                    style={{
                                        width: '30%'
                                    }}><Input
                                        id='inputMonth'
                                        fluid
                                        value={Math.floor(this.state.month)}
                                        placeholder='เดือน'
                                        name='month'
                                        readOnly /></td>
                                <td
                                    style={{
                                        width: '20%'
                                    }}>
                                    <label>เดือน</label>
                                </td>
                                <td
                                    style={{
                                        width: '30%'
                                    }}><Input
                                        id='inputDate'
                                        fluid
                                        value={this.state.day}
                                        placeholder='day'
                                        name='day'
                                        readOnly /></td>
                                <td
                                    style={{
                                        width: '5%'
                                    }}>วัน</td>
                            </tr>
                        </table>
                        <br />
                        <CalculateDay onChange={this.handelCheckbox2} value={this.state.type2} />
                        <br />
                        <div className="w50">
                            <Form.Field widths={16}>
                                <label>จำนวนเงิน</label>
                                <Input
                                    id='Amountm'
                                    className="text-right"
                                    value={Utility.priceFormat(this.props.data.amount)}
                                    placeholder='จำนวนเงิน'
                                    name='amount'
                                    readOnly
                                    onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field widths={16}>
                                <label>อัตราดอกเบี้ย (%)</label>
                                <Input
                                    id='InterestRatio'
                                    className="text-right"
                                    value={this.props.data.interest}
                                    placeholder='อัตราดอกเบี้ย'
                                    name='month'
                                    readOnly
                                    onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field widths={16}>
                                <label>วันครบกำหนด</label>
                                <DatePicker
                                    id='inputEndDate'
                                    readOnly={this.state.type == '2'}
                                    value={Utility.formatDate(moment(new Date(this.state.end_date)))}
                                    selected={this.state.end_date}
                                    dateFormat="DD/MM/YYYY"
                                    placeholder='วันครบกำหนด'
                                    name='end_date'
                                    onChange={(d) => {
                                        this.setState({ end_date: d })

                                    }} />
                            </Form.Field>
                            <Form.Field >
                                <label>วันที่จ่าย</label>
                                <Input
                                    id='inputPayDate'
                                    value={Utility.formatDate(moment(new Date(this.state.pay_date)))}
                                    readOnly={true}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ระยะเวลาที่คิดดอกเบี้ย (เดือน)</label>
                                <Input
                                    id='inputInterestMonth'
                                    className="text-right"
                                    value={Utility.priceFormat(this.state.month_cal)}
                                    placeholder='ระยะเวลาที่คิดดอกเบี้ย'
                                    name='month'
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ดอกเบี้ยคำนวณ {this.state.is_min
                                    ? <Label as='span' color='red' pointing='left'>**ดอกเบี้ยขั้นต่ำ</Label>
                                    : ''}</label>
                                <Input
                                    id='InterestTotal'
                                    className="text-right"
                                    value={Utility.priceFormat(this.state.total)}
                                    placeholder='ดอกเบี้ยคำนวณ'
                                    name='total'
                                    readOnly />
                            </Form.Field>
                            <Form.Field error={this.state.error.total_pay != null}>
                                <label>ดอกเบี้ยรับ <MsgInput text={this.state.error.total_pay} /></label>
                                <Input
                                    id='inputInterestPay'
                                    ref={(c) => this.totalPayInput = c}
                                    className="text-right"
                                    onBlur={() => {
                                        setTimeout(() => {
                                            if (this.state.focus_cash) {
                                                this.setState({
                                                    focus_cash: false,
                                                });
                                            }
                                        }, 0);
                                    }}
                                    onFocus={(e) => {
                                        e.target.select()
                                        if (!this.state.focus_cash) {
                                            this.setState({
                                                focus_cash: true,
                                            });
                                        }
                                    }}
                                    value={this.state.focus_cash ? this.state.total_pay.toString().replace(/,/g, '') : this.state.total_pay.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    // value={this.state.total_pay.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                    placeholder='ดอกเบี้ยรับ'
                                    name='total_pay'
                                    onChange={this.handlerInputTotal}
                                    maxLength={16}
                                />
                            </Form.Field>
                            <Form.Field error={this.state.error.staff_id != null}>
                                <label>พนักงาน <MsgInput text={this.state.error.staff_id} /></label>
                                <Dropdown id='dropDownEmp' search selection options={this.state.staffs} autoFocus value={this.state.staff_id}
                                    onChange={(e, v) => {
                                        this.setState({
                                            staff_id: v.value,
                                            error: {
                                                staff_id: null,
                                            },
                                        })
                                    }}
                                    loading={this.state.st} />
                            </Form.Field>
                            <Form.Field >
                                <label>หมายเหตุ</label>
                                <TextArea
                                    value={this.state.description}
                                    placeholder='หมายเหตุ'
                                    name='description'
                                    onChange={this.handlerInput}
                                    maxLength={255}
                                />
                            </Form.Field>
                        </div>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button id='btnCal' primary content='คำนวณ' onClick={this.handelCalculat} />
                    <Button
                        id='btnSaveInterest'
                        primary
                        loading={this.state.submit_loading}
                        content='บันทึก'
                        onClick={this.handelSubmit} />
                </Modal.Actions>
            </Modal>
        )
    }
}
const mapStateToProps = state => {
    return ({
        lease_modal: state.lease_modal,
        auth: state.auth,
    })
}

export default connect(mapStateToProps)(PaymentInterest)