/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,
    Input,
    Button,
    Modal
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import SelectOption from '../../SelectOption'
import { open_modal_lease_product } from '../../../actions'
import { connect } from 'react-redux'

class AddProductModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // products: [],
            weight: '',
            amount: '',
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)

    }

    componentDidMount() {

        // Utility.get(Settings.baseUrl + '/product_name/', (s, d) => {
        //     this.setState({ products: d })
        // })
        // var producs = Utility.getFetch(Settings.baseUrl + '/product_name/');
        var categories = Utility.getFetch(Settings.baseUrl + '/categories/?is_enabled=1');
        let cate_buy_gold = []
        let system_setting = JSON.parse(localStorage.getItem('system_setting'))
        system_setting = system_setting.find(setting => setting.setting == 'BUYGOLD')
        if (system_setting) {
            cate_buy_gold = JSON.parse(system_setting.value)
        }
        
        Promise.all([categories]).then((values) => {
            this.categories = values[0]
            if(cate_buy_gold.length > 0) {
                this.categories = this.categories.filter(cate => cate_buy_gold.includes(cate.code))
            }
            let categories = []
            for (let i in this.categories) {
                categories.push({
                    key: this.categories[i].id,
                    value: this.categories[i].id,
                    text: this.categories[i].name
                })
            }
            this.setState({
                categories: categories,
                loading: false,
                // products: values[1]
            })
        });


    }

    // addProductName(name) {
    //     if (name.length > 0) {
    //         Utility.get(Settings.baseUrl + '/product_name/?name=' + name, (s, d) => {
    //             if (d.length == 0) {
    //                 let product_name = {
    //                     name: name
    //                 };
    //                 Utility.post(Settings.baseUrl + '/product_name/', product_name, (s, d) => {

    //                 });
    //             }
    //         })
    //     }
    // }

    handlerInput(e, v) {
        v.value = v.value.toString().replace(/,/g, '')
        if (v.name === 'weight') {
            v.value = v.value.replace(/\s+/g, '');
        }
        if (isNaN(Number(v.value))) {
        } else {
            let value = 0
            if (v.value.split('.')[0].length > 12) {
                return;
            }
            if (v.value.split('.').length != 1)
                if (v.value.split('.')[1].length > 3) {
                    return;
                }

            if (v.name == 'amount')
                value = Utility.parseInt(v.value, this.state[v.name])
            if (v.name == 'weight')
                value = v.value
            this.setState({
                [v.name]: value
            })
        }

    }


    onSubmit(e) {
        e.preventDefault()
        const { branch, user } = this.props.auth

        // if (this.state.name) {
        //     this.addProductName(this.state.name);
        // }

        if (this.state.category_id == null) {
            alert('กรุณาเลือก %ทอง');

            return;
        }

        if (this.state.name == null) {
            alert('กรุณากรอกชื่อสินค้า');

            return;
        }
        if (this.state.weight == '') {
            alert('กรุณากรอกน้ำหนัก');

            return;
        }
        // if (this.state.amount == '') {
        //     alert('กรุณากรอกจำนวน');

        //     return;
        // }

        let c = Utility.getObject(this.categories, this.state.category_id)

        let formData = {
            product_name: this.state.name,
            branch: branch.id,
            category: c,
            category_name: c.text,
            weight: this.state.weight,
            amount: 1,
            lease: this.props.lease_id,
            user: user.id
        };

        this.props.onSubmit(formData)
        this.props.dispatch(open_modal_lease_product(false))
    }

    render() {
        if (this.props.open)
            return (<Modal id='ModalAddProduct' size='small' open={true} onClose={this.close} /*dimmer='blurring'*/>
                <Button id='btnCloseAddProduct' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
                <Modal.Header id='headerModalAddProduct' >เลือกรายการสินค้า </Modal.Header>
                <Modal.Content id='contentModalAddProduct' >
                    <Form size='small' onSubmit={this.onSubmit} >
                        <Form.Field width={16} >
                            <label>*% ทอง</label>
                            <SelectOption
                                id='inputPercent'
                                onRef={(c) => { this.categoryInput = c }}
                                placeholder='% ทอง'
                                search selection
                                value={this.state.category_id}
                                options={this.state.categories}
                                onChange={(e, d) => {
                                    this.setState({ category_id: d.value })
                                    this.inputName.focus()
                                }} />
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>*ชื่อสินค้า</label>
                            <Input
                                id='inputNameProduct'
                                ref={(c) => this.inputName = c}
                                fluid 
                                list='products'
                                value={this.state.name}
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        this.inputW.focus()
                                    }
                                }}
                                onChange={(e, v) => {
                                    this.setState({ name: v.value })
                                }}
                                maxLength={255}
                            />
                            {/* <datalist id='products'>
                                {this.state.products.map((v, i) => <option value={v.name} key={i} />)}
                            </datalist> */}
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>*น้ำหนัก ชั่ง/ชิ้น</label>
                            <Input
                                id='inputWeight'
                                fluid
                                ref={(c) => this.inputW = c}
                                placeholder='น้ำหนัก'
                                name='weight'
                                maxLength={24}
                                onBlur={() => {
                                    setTimeout(() => {
                                        if (this.state.focus_cash) {
                                            this.setState({
                                                focus_cash: false,
                                            });
                                        }
                                    }, 0);
                                }}
                                onFocus={(e) => {
                                    e.target.select()
                                    if (!this.state.focus_cash) {
                                        this.setState({
                                            focus_cash: true,
                                        });
                                    }
                                }}
                                value={this.state.focus_cash ? this.state.weight.toString().replace(/,/g, '') : this.state.weight.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                // value={this.state.weight.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                // className="text-right"
                                // type='number'
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        this.onSubmit(e)
                                    }
                                }}
                                onChange={this.handlerInput} />

                        </Form.Field>
                        {/* <Form.Field width={16}>
                        <label>จำนวน(ชิ้น)</label>
                        <Input
                            id='inputNumber' 
                            ref={(c)=>this.inputAmount = c}
                            fluid
                            min={0}
                            // type='number'
                            placeholder='จำนวน'
                            name='amount'
                            value={this.state.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            className="text-right"
                            onKeyPress={(e)=> {
                                if(e.key == 'Enter'){
                                    this.onSubmit(e)
                                } 
                            }}
                            onChange={this.handlerInput} />
                    </Form.Field> */}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button id='btnAdd' primary content='เพิ่ม' onClick={this.onSubmit} loading={this.state.loading_button} />
                </Modal.Actions>
            </Modal>)
        else return null
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(mapStateToProps)(AddProductModal)