/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from "react";
import {
  Form,
  Segment,
  Header,
  Button,
  Loader,
  Dimmer,
  Input,
  Sidebar,
  Icon,
} from "semantic-ui-react";
import Settings from "../../Settings";
import Utility from "../../Utility";

import {activations_branch} from "../../actions";
import {connect} from "react-redux";
import {lease_modal_edit, lease_modal_add_open, lease_modal_close} from "../../actions";
import AccountDetail from './AccountDetail'

class SearchByNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: "",
      action: "edit",
    };
    this.handleInput = this.handleInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault();
    const {auth} = this.props;
    this.setState({
      loading: true,
      number: '',
      itm:[],
      open_modal:false
    })
    if(this.state.number.trim().length == 0){
      this.setState({loading:false})
      alert("กรุณากรอกเลขที่บัญชี")
      return;
    }
    var res = Utility.getFetch(
        Settings.baseUrl + "/savings/?full_number=" + this.state.number.trim()
      );
      this.setState({loading:false})
      Promise.all([res]).then((values) => {
        if(values[0].length != 1){
            alert('ไม่พบเลขที่บัญชีนี้ในระบบ')
        } else{
          if(values[0][0].status != 'O'){
            alert('บัญชีนี้ได้ปิดการใช้งานแล้ว')
          } else
            this.setState({itm:values[0][0],open_modal:true,})
        }
      });
  }

  componentDidMount() {
    const { branch } = this.props
    if (branch)
      Utility.get(Settings.baseUrl + "/branch_setting/?branch=" + branch.id, (e, d) => {
        this.setState({
          setting: d[0]
        })
      });
  }

  handleInput(e, v) {
    this.setState({
      number: v.value,
    });
  }

  render() {
    return (
      <div>
        <Form>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated="left" as="h2">
                {this.props.title}
              </Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Sidebar.Pushable>
            <Segment textAlign="left">
              <Sidebar
                animation="overlay"
                direction="top"
                visible={this.state.visible}
                inverted
              >
                <div className="message-alert">บันทึกข้อมูลสำเร็จ</div>
              </Sidebar>
              <Dimmer active={this.state.loader_active} inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
              <Form textAlign="left" onSubmit={this.onSubmit} size="small">
                <Form.Field>
                  <label>เลขที่บัญชี</label>
                  <Input
                    id="inputBillNum"
                    placeholder="เลขที่บัญชี"
                    value={this.state.number}
                    name="number"
                    onChange={(e,data) => {
                      var regex = /^([0-9-])*$/;
                      if (!regex.test(data.value)){
                      } else{
                        this.handleInput(e,data)
                      }
                    }}
                  />
                </Form.Field>
                <center>
                  <br />
                  <Button
                    id="btnSearch"
                    loading={this.state.loading}
                    type="submit"
                    primary
                  >
                    <Icon name='search' />ค้นหา
                  </Button>
                </center>
              </Form>
            </Segment>
          </Sidebar.Pushable>

          {this.state.open_modal &&(
          <AccountDetail
            items={this.state.itm}
            onClose={() => {
              this.setState({open_modal: false});
            }}
            open={true}
            action={this.props.action}
          />
        )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lease_modal: state.lease_modal,
    auth: state.auth,
    branches: state.branches,
  };
};

export default connect(mapStateToProps,)(SearchByNumber);
