/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
    Form, Segment, Header, Button, Dimmer, Loader,
    Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import Settings from '../../Settings';
import Utility from '../../Utility';
import GoldPrice from './GoldPrice';
import ModalGoldPrice from '../GoldPrice/ModalGoldPrice'

import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';


class ItemsCell extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { rowIndex, field, data, ...props } = this.props;
        let width = (this.props.width * 0.9) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }
        return (
            <Cell {...props} >
                <Popup
                    position={this.props.position}
                    content={data[rowIndex][field]}
                    style={Width300}
                    trigger={
                        <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
                />
            </Cell>
        );
    }
}

var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
    return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
    constructor(props) {
        super(props);

        this._onSortChange = this._onSortChange.bind(this);
    }

    render() {
        var { onSortChange, sortDir, children, ...props } = this.props;
        return (
            <Cell {...props}
                className={this.props.className ? this.props.className : ''}>
                <a onClick={this._onSortChange}>
                    {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
                </a>
            </Cell>
        );
    }

    _onSortChange(e) {
        e.preventDefault();
        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.field,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    }
}

class StockCategory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            colSortDirs: {
                'category__name': 'ASC',
            },
        }
        this.columnKey = 'category__name'
        this.sortDi = 'ASC'
        this.onSortChange = this.onSortChange.bind(this)
    }

    onSortChange(columnKey, sortDir) {

        this.columnKey = columnKey
        this.sortDi = sortDir
        this.componentDidMount()

        this.setState({
            colSortDirs: {
                [columnKey]: sortDir,
            },
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({
            visible: true
        })
        localStorage.setItem("goldpric", JSON.stringify({
            gold_bar_sell: this.state.gold_bar_sell,
            gold_bar_buy: this.state.gold_bar_buy,
            gold_ornaments_buy: this.state.gold_ornaments_buy,
            time: moment()
        }));

        setTimeout(() => {
            this.setState({
                visible: false
            })
        }, 1000)
        if (this.props.onSubmit) {
            this.props.onSubmit()
        }
    }

    componentDidMount() {
        const { branch } = this.props.auth
        this.setState({
            loader_active: true
        });
        if (branch != undefined) {
            var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_old_product/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=1&branch=' + branch.id);
            Promise.all([stock_category]).then((values) => {
                this.stock_category = values[0];

                this.setState({
                    items: this.setFieldValue(values[0]),
                    is_ready: true
                });
            });
        }

        this.setState({
            table_width: (window.innerWidth * 0.475) - 56
        });
    }

    setFieldValue(items) {

        for (let i = 0; i < items.length; i++) {
            items[i]['in_date_title'] = Utility.formatDate(items[i].in_date)
            items[i]['out_date_title'] = Utility.formatDate(items[i].out_date)

            let d = parseFloat(items[i].category.weight)
            items[i]['category'] = items[i].category.name
            items[i]['branch'] = items[i].branch.name
            items[i]['average'] = Utility.priceFormat(items[i].cost_avg_per_bath)
            items[i]['weight'] = Utility.weightFormat(items[i].weight_total)
        }

        return items;
    }


    render() {

        const items = this.state.items
        return (

            <Segment >
                <Header as='h4'>สต็อกทองเก่า</Header>

                <Dimmer active={!this.state.is_ready} inverted>
                    <Loader inverted>Loading..</Loader>
                </Dimmer>
                <div id='root-table'>
                    <Table
                        rowsCount={items.length}
                        rowHeight={35}
                        headerHeight={35}
                        width={this.state.table_width}
                        height={Settings.table_hiegh}>
                        <Column
                            header={<SortHeaderCell
                                onSortChange={this.onSortChange}
                                field='category__name'
                                sortDir={this.state.colSortDirs.category__name}>
                                กลุ่มสินค้า
                              </SortHeaderCell>}
                            cell={
                                <ItemsCell position='top left' width={250} data={items} field="category" />
                            }
                            width={250}
                        />
                        <Column
                            header={<Cell className='text-right'>น.น.รวม(g.)</Cell>}
                            cell={
                                <ItemsCell position='top right' width={150} data={items} field="weight" textAlign='text-right' />
                            }
                            width={150}
                        />

                        <Column
                            header={<Cell className='text-right'>ราคาทองเฉลี่ย</Cell>}
                            cell={
                                <ItemsCell position='top right' width={160} data={items} field="average" textAlign='text-right' />
                            }
                            width={160}
                        />

                    </Table></div>
            </Segment>
        );
    }
}

const mapStateToProps = state => {

    return ({
        branches: state.branches,
        auth: state.auth
    })
}
export default connect(
    mapStateToProps
)(StockCategory)
