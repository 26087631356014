/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';

import Utility from '../../Utility';
import Settings from '../../Settings';

import logo_aagold from '../../images/logo_aagold.png'
import bg_lease from '../../images/lease.jpg'
import aagold_line_qrcode from '../../images/aagold_line_qrcode.png'



class ProductDetailPrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {

        if (this.props.id) {
            let lease = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.id + '/');
            let product = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.id + '/product/?is_enabled=1');
            this.setState({
                loading: true
            })

            Promise.all([lease,product]).then((values) => {
                let total_interest = 0;

                this.setState({
                    loading: false,
                    lease: values[0],
                    product: values[1]
                })
            });
        }

    }
    render() {
        let title = 'รายการขายฝาก';
        let filename = 'lese';
        const textRight = {
            'text-align': 'right'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        

        var QRCode = require('qrcode.react');
        const loop = [0,1,2,3]
        return (<div>
            <Modal id='ModalLeasePreview' open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>
                    {this.state.loading?<Segment>
                        <Dimmer active inverted>
                            <Loader inverted content='Loading' />
                        </Dimmer>
                    </Segment>:
                    <div id='view-print' style={{  width: '100%', 'min-height': 'auto',  }}>
                        {loop.map((item,i)=>{
                            let total_amount = 0;
                            let total_weight = 0
                            return(<div>
                        <div id='paperA5-portrait' style={{
                                overflow: 'hidden',
                                'font-family': 'monospace','font-size': '4mm', display:'flex',
                                padding: '20px', flexDirection:'column',width: '148mm', 'height': '210mm', 'font-size': '4mm', 'page-break-before': 'always',
                                //'backgroundImage': `url(${bg_lease})`,
                                'background-size': '100% auto' ,
                                'background-repeat': 'no-repeat'}} >
                            <div style={{padding:'5px','height': '3cm','font-size': '5mm'}}>
                                <div style={{display:'flex',position:'relative'}}>
                                    <div style={{width:'100%'}}>
                                        <div style={{ marginLeft: '16px', marginRight:'5px' ,marginTop: '60px'}}>
                                            <div>{item==0?'ต้นฉบับ':'สำเนา'}</div>
                                            <div>ID: {this.state.lease.citizen_id}</div>
                                        </div>
                                    </div>
                                    <div style={{    'text-align': 'center','margin': '5px'}}>
                                        <QRCode size='65' value={this.state.lease.number} renderAs='svg'></QRCode>
                                        {this.state.lease.number}
                                    </div>
                                </div>
                            </div>
                            <div style={{height:'15cm'}}>
                                <div style={{ marginLeft: '27px', marginRight:'5px' ,marginTop: '22px',display:'grid',gridTemplateColumns:'50% 50%'}}>
  
                                    <div style={{height:'25px','font-size':'16px',borderRadius:'5px',paddingLeft:'5px'}}>
                                        <div style={{padding:'0px'}}>{this.state.lease.customer.name}</div>
                                    </div>
                   
                                    <div style={{height:'25px','font-size':'16px',borderRadius:'5px',paddingLeft:'5px'}}>
                                        <div style={{padding:'1px 0px'}}>{this.state.lease.phone}</div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: '10px', marginRight:'10px' ,marginTop: '5.0mm', 'font-size': '4.0mm' }}>
                                    <div style={{padding:'21px 20px',height:'9cm',borderRadius:'5px'}}>
                                        <table>
                                        {this.state.product.map((item,i)=>{

                                            total_weight += parseFloat(item.weight);
                                            total_amount += parseInt(item.amount);

                                            return (
                                            <tr >
                                                <td style={{ float: 'left', marginLeft: '0mm', width: '64mm' }}><div style={{paddingTop:'7px'}}>{item.product_name}</div></td>
                                                <td style={{ float: 'left', textAlign: 'right', width: '15mm' }}><div style={{paddingTop:'7px'}}>{item.amount}</div></td> 
                                                <td style={{ float: 'left', textAlign: 'right', width: '20mm' }}><div style={{paddingTop:'7px'}}>{Utility.weightFormat(item.weight)}</div></td> 
                                                <td style={{ float: 'left', textAlign: 'right', width: '20mm' }}><div style={{paddingTop:'7px'}}><QRCode size='40' value={item.qrcode} renderAs='svg'></QRCode></div></td> 
                                            </tr>
                                        )
                                        })}
                                            <tr style={{paddingTop:'5px'}}>
                                                <td style={{ float: 'left', marginLeft: '0mm', width: '64mm' }}>	&nbsp;</td>
                                                <td style={{ float: 'left', textAlign: 'right', width: '15mm', 'text-decoration': 'underline' }}><div style={{paddingTop:'12px'}}>{total_amount}</div></td>
                                                <td style={{ float: 'left', textAlign: 'right', width: '20mm', 'text-decoration': 'underline' }}><div style={{paddingTop:'12px'}}>{Utility.weightFormat(total_weight)}</div></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div style={{'font-size': '3.0mm',paddingLeft:'20px',paddingRight:'10px',marginTop:'4.1mm'}}>
                                    
                                    <div style={{ marginLeft: '10px', marginRight:'5px' ,marginTop: '12px',display:'grid',gridTemplateColumns:'50% 50%'}}>
  
                                        <div style={{height:'25px','font-size': '4.0mm',borderRadius:'5px',paddingLeft:'5px',textAlign:'left'}}>
                                            <div style={{padding:'0px'}}>{Utility.dateThai(this.state.lease.start_date)}</div>
                                        </div>
              
                                        <div style={{height:'25px','font-size': '4.0mm',borderRadius:'5px',paddingLeft:'15px',textAlign:'left'}}>
                                            <div style={{padding:'0px'}}>{Utility.dateThai(this.state.lease.end_date)}</div>
                                        </div>
                                    </div>

                                    <div style={{ marginLeft: '10px', marginRight:'5px' ,marginTop: '27px',display:'grid',gridTemplateColumns:'50% 50%'}}>
                        
                                        <div style={{height:'25px','font-size': '4.0mm',borderRadius:'5px',paddingLeft:'5px',textAlign:'left'}}>
                                            <div style={{padding:'0px'}}>{Utility.priceFormat(this.state.lease.amount)}</div>
                                        </div>
                    
                                        <div style={{height:'25px','font-size': '3.0mm',borderRadius:'5px',paddingLeft:'15px',textAlign:'left'}}>
                                            <div style={{padding:'0px'}}>{Utility.arabicNumberToText(this.state.lease.amount)}</div>
                                        </div>
                                    </div>

                
                                </div>
                            </div>
                            
                        </div>
                    </div>)
                        })}
                    </div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        id='printLease'
                        primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 6mm;font-family: monospace;">');
                        mywindow.document.write('<style>');
                        mywindow.document.write('@page {size: A6; margin: 0mm;}');
                        mywindow.document.write('</style>');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(()=>{
                            mywindow.close();
                        },2000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnCloseLeasePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default ProductDetailPrintPreview;