/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import OptionItemsCell from './OptionItemsCell'
import MsgInput from '../Error/MsgInput'
import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader, Dropdown, Header,
  Popup,
  TextArea,
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class ItemsCellOption extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props} className='cell-time-click'>
        <Button.Group basic size='mini'>
          <Button onClick={(e) => {
            this.props.onClickEdit(e, data[rowIndex]);
          }}><Icon color='green' name='edit' /></Button>
          <Button onClick={(e) => {
            this.props.onClickDelete(e, data[rowIndex]);
          }}><Icon color='red' name='delete' /></Button>
        </Button.Group>
      </Cell>
    );
  }
}

class BankAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      number: '',
      banks_id: '',
      description: '',
      branch: [],
      modal_open: false,
      msg_error: {}
    }
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  submitData() {

    var msg_error = {}

    if (this.state.name == null || this.state.name == '') {
      msg_error['name'] = ['*ต้องไม่เป็นค่าว่าง']
    }

    if (this.state.number == null || this.state.number == '') {
      msg_error['number'] = ['*ต้องไม่เป็นค่าว่าง']
    }
    if (this.state.banks_id == null || this.state.banks_id == '') {
      msg_error['bank'] = ['*เลือกธนาคาร']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error,
        button_class: ''
      })
      return;
    }

    var formData = {
      bank: this.state.banks_id,
      number: this.state.number,
      name: this.state.name,
      description: this.state.description,
      branch: this.state.branch,
    };

    this.setState({ button_class: 'loading' })
    const self = this;
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/bank_account/';
      Utility.postJson(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        } else { // error
          if (data.length !== 0)
            this.setState({
              button_class: '',
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/bank_account/' + this.state.object_id + "/";
      Utility.putJson(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        } else { // error
          if (data.length !== 0)
            this.setState({
              button_class: '',
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      number: '',
      banks_id: '',
      description: '',
      branch: [],
      button_class: '',
      msg_error: {}
    });
  }


  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/bank_account/?is_enabled=1', (status, data) => {
      this.setState({
        items: this.setFieldValue(data),
        loader_active: false
      });
    });

    Utility.get(Settings.baseUrl + '/branches/?is_enabled=1', (status, data) => {
      let branchOption = []
      data.forEach(b => {
        branchOption.push({
          key: b.id,
          value: b.id,
          text: b.name
        });
      });
      this.setState({
        branchOption: branchOption,
      });
    });

    Utility.get(Settings.baseUrl + '/banks/?is_enabled=1', (status, data) => {
      let banks = []
      for (let i in data) {
        banks.push({
          value: data[i].id,
          text: data[i].name
        });
      }
      this.setState({
        banks: banks
      });
    });
  }

  setFieldValue(v) {

    for (let i in v) {
      v[i].bank_name = v[i].bank.name
    }

    return v;
  }

  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: data.name,
        number: data.number,
        banks_id: data.bank.id,
        description: data.description || '',
        branch: data.branch,
        object_id: data.id
      });
    } else {
      this.setState({ confirm_open: true, object_id: data.id, object_bank_id: data.bank.id, object: data });
    }
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>บัญชีธนาคาร</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button id='btnAddAccount' size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างบัญชีธนาคาร'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='btnCloseAccount'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.name != null}>
                  <label>*ชื่อบัญชี <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='inputAccountName' onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.number != null}>
                  <label>*เลขที่บัญชี <MsgInput text={this.state.msg_error.number} /></label>
                  <Input id='inputAccountID' type="text" maxLength="14" onChange={(e) => this.setState({ number: e.target.value })} value={this.state.number} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.bank != null}>
                  <label>*ธนาคาร <MsgInput text={this.state.msg_error.bank} /></label>
                  <Dropdown id='dorpDownBankName' search selection width={14} options={this.state.banks}
                    onChange={(e, v) => {
                      this.setState({ banks_id: v.value });
                    }} defaultValue={this.state.banks_id} value={this.state.banks_id} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.branch != null}>
                  <label>สาขา <MsgInput text={this.state.msg_error.branch} /></label>
                  <Dropdown id='dropDownBranch'
                    placeholder='สาขา'
                    selection
                    search
                    multiple
                    options={this.state.branchOption}
                    value={this.state.branch}
                    defaultValue={this.state.branch}
                    onChange={(e, data) => {
                      this.setState({
                        branch: data.value,
                      });
                    }} />
                </Form.Field>
                <Form.Field>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea
                    placeholder='หมายเหตุ'
                    onChange={(e) => this.setState({ description: e.target.value })}
                    value={this.state.description}
                    maxLength={255}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSaveAccount' size='small' primary onClick={this.submitData}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancelAccount' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />
            <Column

              header={<Cell>ชื่อบัญชี</Cell>}
              cell={
                <ItemsCell position='top left' width={200} id='AccountName' data={items} field="name" />
              }
              width={200}
            />
            <Column
              header={<Cell>เลขที่บัญชี</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="number" />
              }
              width={200}
            />
            <Column
              header={<Cell>ชื่อธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="bank_name" />
              }
              width={200}
            />
            <Column
              header={<Cell>สาขา</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="branches" />
              }
              width={200}
            />
            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="description" />
              }
              width={200}
            />
          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
          </Form.Group>
        </Form>
        <Confirm
          id='modalConfirmDeleteAccount'
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/bank_account/' + this.state.object_id + "/"
            Utility.delete(url, (status, data, code) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.componentDidMount();
                this.resetForm();
              } else if (data.error && code == 400) {
                alert(data.error)
              } else if (code == 404) {
                alert('ถูกลบไปแล้ว')
              }
            });

          }}
        />
      </div>
    );
  }
}
export default BankAccount;
