/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import productHelper from '../../Helper/ProductHelper';

class ImportProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields([], []),
            is_loading: false,
            data: [],
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list) {
        this.setState({
            is_loading: true,
            loading_title: '0/' + (data_list.length)
        })
        var position = 0
        let run = () => {
            let data = data_list[position]
            let price_tag = data['price_tag'].replace(",", "");
            data['price_tag'] = price_tag==""?0:parseFloat(price_tag)
            data['weight'] = Number(data['weight'])?parseFloat(data['weight']):0
            var category = Utility.getObject(this.categories,data.category)
            var product_type = Utility.getObject(this.product_types,data.kind)
            var type_weight = parseInt(data.type_weight) 
            var weight = data.weight/category.weight
            var weight_g = data.weight
            var type_sale = parseInt(data.type_sale)
            var _price_tag = data.price_tag
            var pattern = Utility.getObject(this.patterns,data.pattern)
            var dataGen = productHelper.getCode(category,product_type,pattern,type_weight,weight,weight_g,type_sale,_price_tag)

            data['name'] = dataGen.name
            let url = Settings.baseUrl + '/products/'
            Utility.post(url, data, (e, res) => {
                position++;
                if (position < data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })
        }
        run()
    }

    setData(data) {

    }

    setFields(kind_options, category_options,pattern_options) {
        return [
            {
                name: 'category',
                title: '*กลุ่มสินค้า',
                type: 'string',
                unique: true,
                required: true,
                options: category_options,
                max_length: 255,
            },
            {
                name: 'kind',
                title: '*ประเภทสินค้า',
                type: 'string',
                unique: true,
                required: true,
                options: kind_options
            },
            {
                name: 'code',
                title: '*รหัสสินค้า',
                type: 'string',
                unique: true,
                required: true,
            },
            // {
            //     name: 'name',
            //     title: '*ชื่อสินค้า',
            //     type: 'string',
            //     unique: true,
            //     required: true,
            // },
            {
                name: 'pattern',
                title: '*รหัสลายทอง',
                type: 'string',
                unique: true,
                required: true,
                options: pattern_options,
            },
            {
                name: 'weight',
                title: '*น.น.(ก.)',
                type: 'string',
            },
            {
                name: 'type_weight',
                title: '*ประเภทน้ำหนัก',
                type: 'string',
                required: true,
                options: Settings.type_weight
            },
            {
                name: 'type_sale',
                title: '*ประเภทงานขาย',
                required: true,
                type: 'string',
                options: Settings.type_sale
            },
            {
                name: 'price_tag',
                title: 'ราคาป้าย',
                type: 'string',
            },
            // {
            //     name: 'cost',
            //     title: 'ต้นทุน',
            //     type: 'string',
            // },
            {
                name: 'description',
                title: 'หมายเหตุ',
                type: 'string',
            }
        ]
    }

    componentDidMount() {

        var categories = Utility.getFetch(Settings.baseUrl + '/categories/');
        var product_types = Utility.getFetch(Settings.baseUrl + '/product_types/');
        var products = Utility.getFetch(Settings.baseUrl + '/products/?is_enabled=1');
        var patterns = Utility.getFetch(Settings.baseUrl + '/pattern/?is_enabled=1');

        this.setState({
            loading_title: "Loading...",
            is_loading: true
        })

        Promise.all([categories, product_types, products,patterns]).then((values) => {
            this.categories = values[0];
            this.product_types = values[1];
            this.patterns = values[3];

            let cat = [];
            for (let i in values[0]) {
                cat.push({
                    key: values[0][i].id,
                    value: values[0][i].id,
                    text: values[0][i].name,
                });
            }
            let type = [];
            for (let i in values[1]) {
                type.push({
                    key: values[1][i].id,
                    value: values[1][i].id,
                    text: values[1][i].name,
                });
            }

            let pat = [];
            for (let i in values[3]) {
                pat.push({
                    key: values[3][i].id,
                    value: values[3][i].id,
                    text: values[3][i].code,
                });
            }
            
            
            this.setState({pattern:pat})
            this.setState({
                fields: this.setFields(type, cat, pat),
                is_loading: false
            })
        });
        for (var i in this.props.data) {
            this.state.data.push(this.props.data[i].code)
        }
        
    }

    render() {
        return (
            <div>
                <ImportCSV
                    name='Product'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                    data={this.state.data}
                />
            </div>
        )
    }
}

export default ImportProduct