/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Radio, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, List
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DatePicker from 'react-datepicker';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { report_email_list } from '../../../actions'

class StockOldGoldPrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            options:[],
            emailValues:[],
            
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
    }

    resetForm(e) {

        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            emailValues:[],
        })

    }

    async handlerSubmit(e) {
        e.preventDefault();
        if(this.state.emailValues.length > 0 ) {
            let search = Utility.cloneObjectJson(this.state.search)
            search.start_date = Utility.formatDate2(search.start_date)
            search.end_date = Utility.formatDate2(search.end_date)

            let mode = ''
            if (search.start_date==search.end_date){
                mode = 'stock_old_gold'
            }else {
                mode = 'stock_old_gold_range'
            }

            let formData = {
                mode: mode,
                start_date: search.start_date,
                end_date: search.end_date,
                email_list:this.state.emailValues,
            };

            let url = Settings.baseUrl + '/report/summary/'
            Utility.postJson(url, formData, (status, data) => {
                if (status) {
                  Swal.fire({
                    icon: 'success',
                    title: 'ส่งอีเมลสำเร็จ',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.setState({emailValues:[]})
                }
            });
        } else {
            alert('กรุณากรอกอีเมล')
        }
       
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    handleAddition = (e, { value }) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(value).toLowerCase())) {
            this.props.options.push({ text: value, value })
            this.props.handleEmailOptionChange(this.props.options)
            // this.setState((prevState) => ({
            // options: [{ text: value, value }, ...prevState.options],
            // })) 
        } else {
            alert('กรุณากรอกอีเมลให้ถูกต้อง')
            this.state.emailValues.pop()
        }
      }
    
    handleChange = (e, { value }) => { 
    this.setState({ emailValues: value })
    }

    render() {
        const { emailValues } = this.state

        let title = 'รายงานทองเก่า';
        let filename = 'exchange-' + Utility.formatDate3(this.state.search.start_date) + '-to-' + Utility.formatDate3(this.state.search.end_date);
        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานทองเก่า</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                        
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>อีเมล</label>
                                <Dropdown
                                    options={this.props.options}
                                    placeholder='อีเมล'
                                    search
                                    selection
                                    fluid
                                    multiple
                                    allowAdditions
                                    value={emailValues}
                                    onAddItem={this.handleAddition}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Button>ยืนยัน</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
            </Segment>
        </div >)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches,
        options: state.report_email_list.list,
    })
}
const mapDispatchToProps = dispatch => ({
    handleEmailOptionChange: (email_list) => {
        dispatch(report_email_list(email_list))
    }
})
export default connect(
    mapStateToProps,mapDispatchToProps
)(StockOldGoldPrintPreview)