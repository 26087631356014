/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import BillItemTable from './BillItemTable'
import BillPrintPreview from './BillPrintPreview'
import SelectOldProduct from './SelectOldProduct'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Dropdown,
  Input,
  Modal,
  Grid,
  TextArea,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class BillModal extends Component {

  constructor(props) {
    super(props);
    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      branch: '',
      status: 'N',
      item_invoice: [],
      loader: false,
      button_save_title: 'สร้าง',
      description: '',
      button_print_disabled: true,
      msg_error: {},
      code: '',
      focus: '',
      weight_real: '',
    }

    this.onDeleteProduct = this.onDeleteProduct.bind(this);

    this.handelActionItemCell = this.handelActionItemCell.bind(this)
    this.handelSubmitProduct = this.handelSubmitProduct.bind(this)
    this.submitQRcode = this.submitQRcode.bind(this);
  }

  async submitQRcode() {
    let data = []
    data['code'] = this.state.code.trim()
    data['stock_type'] = 'old'
    data['branch'] = this.props.invoice_detail.branch

    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = await Utility.getAsync(Settings.baseUrl + '/qrcode/?' + qrstring);

    Promise.all([qrcode]).then((values) => {
      if (values[0].status_code == 200) {
        this.handelSubmitProduct({ product_old: values[0].data.id })
      } else {
        if (values[0].data.detail === '' || values[0].data.detail === undefined) {
          this.handelSubmitProduct({ product_old: values[0].data.id })
        } else {
          alert(values[0].data.detail)
        }
      }

      this.setState({ code: '' })
    })
  }

  handelSubmitSaveInvoice() {
    const { branch } = this.props.auth
    if (this.state.branch == '') {
      alert('กรุณาเลือกสาขา');
      return;
    }

    var formData = {
      branch: this.state.branch,
      bill_date: Utility.formatDate2(this.state.invoice_date),
      description: this.state.description,
      weight_real: this.state.weight_real.toString().replace(/,/g, '')
    };
    this.setState({ button_save_loading: true })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/bag_old/';
      Utility.post(url, formData, (status, data) => {
        this.setState({
          button_save_loading: false,
          invoice_disabled: true,
          button_save_title: 'บันทึก'
        })
        this.setState({ focus: 'edit' })
        this.props.onAddInvoice(data);
        this.loadInvoiceItem()
        alert('บันทึกข้อมูลสำเร็จ!')
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        description: this.state.description,
        weight_real: this.state.weight_real.toString().replace(/,/g, '')
      };

      const url = Settings.baseUrl + '/bag_old/' + this.props.invoice_detail.id + "/";
      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  handelSubmitProduct(formData) {
    const url = Settings.baseUrl + '/bag_old/' + this.props.invoice_detail.id + '/items/';
    Utility.postJson(url, formData, (status, data) => {
      if (status) {
        this.loadInvoiceItem(true);
      }
      else { // error
        alert(data.detail)
      }
    });
  }

  loadInvoiceItem(reload) {
    var invoices = Utility.getFetch(Settings.baseUrl + '/bag_old/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/bag_old/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise.all([invoices, items]).then((values) => {
      let item = values[0];
      let branch_option = Utility.getOptions(this.props.branches)
      for (let i in branch_option) {
        if (branch_option[i].value === item.branch) {
          this.setState({ branch_name: branch_option[i].text })
        }
      }
      let description = this.state.description
      let weight_real = this.state.weight_real
      this.setState({
        bill_number: item.number,
        description: item.description,
        invoice_date: moment(item.bill_date),
        branch: item.branch,
        status: item.status,
        invoice_disabled: true,
        item_invoice: this.setFieldValue(values[1]),
        button_save_title: 'บันทึก',
        loader: false,
        button_print_disabled: false,
        weight_real: item.weight_real,
        weight_real_disabled: item.status_stock == 'Y' ? true : false
      });
      if (reload == true) {
        this.setState({
          weight_real: weight_real,
          description: description,
        })
      }
    });
  }

  loadInvoiceItem_2(reload) {

    var items = Utility.getFetch(Settings.baseUrl + '/bag_old/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise.all([items]).then((values) => {
      let description = this.state.description
      let weight_real = this.state.weight_real
      this.setState({
        invoice_disabled: true,
        item_invoice: this.setFieldValue(values[0]),
        button_save_title: 'บันทึก',
        loader: false,
        button_print_disabled: false,
      });
      if (reload == true) {
        this.setState({
          weight_real: weight_real,
          description: description,
        })
      }
    });
  }

  setFieldValue(item) {

    let invoice_weight_total = 0;
    let cost_total = 0;
    for (let i = 0; i < item.length; i++) {
      invoice_weight_total += parseFloat(item[i].weight);
      cost_total += parseFloat(item[i].product_old.cost);
      item[i]['category_title'] = item[i].product_old.stock_old_product.category.name
      item[i]['ref_number'] = item[i].product_old.object_number
      item[i]['qrcode'] = item[i].product_old.qrcode
      item[i]['product_name'] = item[i].product_old.name
      item[i]['weight'] = Utility.weightFormat(item[i].weight)
      item[i]['cost'] = Utility.priceFormat(item[i].product_old.cost)
    }
    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      total: Utility.numberFormat(item.length),
      cost_total: Utility.priceFormat(cost_total),
    });
    return item;
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      btn_stock: true
    });
    this.setState({ focus: this.props.modal_action })

    if (this.props.modal_action == 'edit') {
      this.loadInvoiceItem()
    }
  }

  onDeleteProduct(e, d) {
    let data = this.state.item_invoice[d.positon];
    const url = Settings.baseUrl + '/bag_old/' + this.props.invoice_detail.id + '/items/' + data.id + '/';
    Utility.delete(url, (status, data) => {
      this.loadInvoiceItem_2();
    });
  }

  handelActionItemCell(e, d) {

    if (d.action == 'delete') {
      if (window.confirm('ยืนยันลบรายการนี้'))
        this.onDeleteProduct(e, d)
    } else {
    }

  }

  render() {
    const { branch } = this.props.auth
    let items = this.state.item_invoice;
    const branch_option = Utility.getOptions(this.props.branches)
    return (

      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.bill_number ? <span id='textBillID'>เลขที่ห่อ {this.state.bill_number}</span> : 'เพิ่มห่อทอง'}
              {
                this.state.bill_number &&
                <Header.Subheader>
                  <span><Icon name={'folder open'} color={this.state.status == 'O' ? 'green' : 'gray'} />{Settings.status_bag[this.state.status]}</span>
                </Header.Subheader>
              }
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form className=' fluid' size='small' >
                        <Form.Field error={this.state.msg_error.branch != null} width={16} >
                          <label>*สาขา <MsgInput text={this.state.msg_error.branch} /></label>
                          <Dropdown id='branch' search selection width={16} options={branch_option} placeholder='สาขา' value={this.state.branch} onChange={(e, data) => {
                            this.setState({ branch: data.value });
                          }} disabled={this.state.invoice_disabled} />
                        </Form.Field>
                        <Form.Field width={16}>
                          <label>วันที่</label>
                          <DatePicker
                            id='date'
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.invoice_date}
                            readOnly
                            onChange={(date) => {
                              date = date ? date : moment()
                              this.setState({ invoice_date: date });
                            }}
                            disabled={this.state.invoice_disabled}
                          />
                        </Form.Field>

                        <Form.Field width={16}>
                          <label>น้ำหนักชั่งห่อ กรัม</label>
                          <Input
                            id='weight_real'
                            placeholder='0.000'
                            className='text-right'
                            onChange={(e) => {
                              if (isNaN(Number(e.target.value))) {
                              } else {
                                if (e.target.value.split('.')[0].length > 6) {
                                  return;
                                }
                                if (e.target.value.toString().split(".")[1] != undefined)
                                  if (e.target.value.toString().split(".")[1].length > 3)
                                    return
                                this.setState({ weight_real: e.target.value })
                              }
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_w) {
                                  this.setState({
                                    focus_w: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={(e) => {
                              e.target.select()
                              if (!this.state.focus_w) {
                                this.setState({
                                  focus_w: true,
                                });
                              }
                            }}
                            value={this.state.focus_w ? this.state.weight_real.toString().replace(/,/g, '') : Utility.weightFormat(this.state.weight_real)}
                            disabled={this.state.weight_real_disabled || this.state.status == 'C'}
                            maxLength={10}
                          />
                        </Form.Field>
                        <Form.Field width={16}>
                          <label>หมายเหตุ</label>
                          <TextArea
                            placeholder='หมายเหตุ'
                            maxLength={255}
                            value={this.state.description}
                            onChange={(e, v) => {
                              this.setState({ description: v.value })
                            }}
                            disabled={this.state.status == 'C'}
                          />
                        </Form.Field>
                        <small> *กรอกหมายเหตุเมื่อน้ำหนักชั่งห่อกรัม ไม่เท่ากับ น้ำหนักรวม</small>
                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={8}>
                            <Header floated='left' as='h3'>รายการสินค้า</Header>
                          </Form.Field>
                          <Form.Field width={5}>
                            <label>QR Code</label>
                            <Input
                              className='text-right'
                              placeholder='Qr code'
                              autoFocus={1}
                              onKeyPress={(e) => {
                                if (e.key == 'Enter') {
                                  this.submitQRcode()
                                }
                              }}
                              onChange={(e) => this.setState({ code: e.target.value })}
                              value={this.state.code}
                              disabled={!(this.state.focus === 'edit' && this.state.status === 'O')}
                            />
                          </Form.Field>
                          <Form.Field width={3}>
                            <label><br /></label>
                            <Button
                              primary
                              size='small'
                              icon='add'
                              fluid
                              disabled={this.state.focus !== 'edit' || this.state.status !== 'O'}
                              onClick={(e) => {
                                this.setState({ open_select: true })
                              }}
                              type='button'
                              content='เลือกสินค้า' />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <BillItemTable
                        status={this.state.status}
                        items={items}
                        table_width={this.state.table_width}
                        onActionItemCell={this.handelActionItemCell}
                      />
                      <br />
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input label='จำนวนชิ้น' placeholder='' className='text-right' width={4} value={this.state.total} readOnly />
                          <Form.Input label='น้ำหนักรวม' placeholder='' className='text-right' width={4} value={this.state.invoice_weight_total} readOnly />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button
            id='btnPrint'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled}
            onClick={(e) => {
              this.setState({ open_print: true })
            }}
            content='พิมพ์' />
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.handelSubmitSaveInvoice();
            }}
            disabled={this.state.status == 'C'}
            className={this.state.button_save_loading ? 'loading' : ''}
            content={this.state.button_save_title}
          />

        </Modal.Actions>
        {this.state.open_print
          ? <BillPrintPreview
            branch_name={this.state.branch_name}
            invoice_detail={this.props.invoice_detail}
            status={this.state.status}
            items={items}
            cost_total={this.state.cost_total}
            weight_total={this.state.invoice_weight_total}
            onClose={() => {
              this.setState({ open_print: false })
            }} />
          : ''}
        {this.state.open_select
          ? <SelectOldProduct
            addItems={this.handelSubmitProduct}
            branch={this.state.branch}
            onClose={() => {
              this.setState({ open_select: false })
            }} />
          : ''}
      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}
export default connect(
  mapStateToProps,
)(BillModal)