/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import GoldMeltPrintPreview from './GoldMeltPrintPreview'
import GoldMeltItemTable from './GoldMeltItemTable'
import {
  Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
var dateFormat = require('dateformat');

class BillModal extends Component {

  constructor(props) {
    super(props);
    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      bill_date: moment(),
      vendor: '',
      status_stock: 'N',
      modal_open: this.props.open,
      product_action_title: 'เพิ่มสินค้า',
      product_action: 'add',
      item_invoice: [],
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการทอง 99.99 เข้าสต็อก',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      description: '',
      button_print_disabled: true,
      msg_error: {},
      QRCODE: '',
      is_disable_qrcode: true,
      focus1: '',
      focus2: '',
      weight_out: '',
      weight_total: '',
      weight_price: '',
      remark: '',
      vendors_dropdown_search: [],
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.loadGoldPrice = this.loadGoldPrice.bind(this)
    this.getItemvendor = this.getItemvendor.bind(this)
  }

  handelSubmitSave() {
    if (this.state.vendor == '') {
      alert('กรุณาเลือกโรงงาน/ร้านส่ง');
      return;
    } else {
      this.submitUpdateStock();
    }
  }
  getItemvendor() {
    var vendorsQ = Utility.getFetch(Settings.baseUrl + '/lot_melt_get_vendors/' + this.props.invoice_detail.id + '/');
    Promise.all([vendorsQ]).then((values) => {
      let vendors = [];
      for (let i in values[0]) {
        vendors.push({
          key: values[0][i].id,
          value: values[0][i].id,
          text: values[0][i].name,
        });
      }
      this.setState({
        vendors_dropdown_search: vendors,
        vendor: values[0][0].id
      });
    })
  }
  loadGoldPrice() {
    let d = new Date();
    let q = dateFormat(d, "yyyy-mm-dd HH:MM")
    let url = Settings.baseUrl + "/gold_price/?datetime=" + q;
    this.setState({ is_loading: true })
    let gold_price_period = []
    Utility.get(url, (s, res) => {
      let text = ''

      for (let i in res) {
        text = Utility.priceFormat(res[i].gold_99_buy) + ' (' + 'ปัจจุบัน ' + Utility.formatTimeSecond(res[i].record_date) + ' น.' + ') '
        gold_price_period.push({ key: res[i].id, value: res[i].id, text: text })
        let gold_g = parseFloat(res[i].gold_99_buy) / 15.2
        this.setState({
          weight_price: gold_g.toFixed(2)
        })
      }

      if (s && res.length > 0) {
        this.setState({
          gold_price: res[0],
          gold_price_period: gold_price_period,
          gold_price_value: gold_price_period.length != 0 ? gold_price_period[0].value : 0,
          is_loading: false,
        })
      }
    });
  }
  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      btn_stock: true
    });
    this.setState({ focus1: this.props.modal_action, focus2: this.props.modal_action })
    this.loadGoldPrice()
    this.getItemvendor()
  }
  submitUpdateStock() {
    if (window.confirm('ยืนยันอนุมัติและอัพเดทสต็อก')) {
      if (this.state.weight_price == '' || this.state.weight_price == null || this.state.weight_price == 0) {
        alert('น้ำหนักทองคำ ต้องไม่เป็น 0')
        this.componentDidMount();
        return;
      }
      if (this.state.weight_total == '' || this.state.weight_total == null || this.state.weight_total == 0) {
        alert('ราคาทองคำ( ต้องไม่เป็น 0')
        this.componentDidMount();
        return;
      }

      var formData = {
        vendor: this.state.vendor,
        bill_date: Utility.formatDate2(this.state.bill_date),
        weight: this.state.weight_total,
        gold_price: this.state.gold_price_value,
        description: this.state.description,
        cost: Math.round(Number(this.props.invoice_detail.cost.toString().replace(/,/g, '')) / Number(this.state.weight_total) / 0.0656 * 100) / 100,
      };

      this.setState({ button_save_loading: true })


      const url = Settings.baseUrl + '/gold_99_create/' + this.props.invoice_detail.id + "/";
      Utility.post(url, formData, (status, data) => {

        if (data.status == '200') {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.props.onClose()
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }
  submitDelStock() {
    this.setState({ button_save_loading: true })
    const url = Settings.baseUrl + '/gold_99_delete/' + this.props.invoice_detail.id + "/";
    Utility.delete(url, (status, data) => {
      if (data.status == '200') {
        this.setState({ button_save_loading: false })
        this.props.onAddInvoice(data);
        this.props.onClose()
        alert('บันทึกข้อมูลสำเร็จ!')
      }
    });
  }
  render() {
    let items = this.state.item_invoice;
    return (

      <Modal size={this.props.modal_action === 'add' ? "large" : 'mini'} open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />

        {this.props.modal_action === 'add' ?
          <>
            <Modal.Header>
              <Header as='h4'>
                <Header.Content>
                  {this.state.invoice_title} {this.props.invoice_detail.number && <span id='textBillID'>[{this.props.invoice_detail.number}]</span>}
                  <Header.Subheader>
                    <span><Icon name={this.state.status_stock == 'N' ? 'warning circle' : 'check circle'} color={this.state.status_stock == 'N' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Modal.Header>
            <Modal.Content>
              <div >
                <div className='relative'>
                  {this.state.loader && <Dimmer active={this.state.loader} inverted>
                    <Loader inverted content='Loading' />
                  </Dimmer>}
                  <div>
                    <Grid columns={3}>
                      <Grid.Row>

                        <Grid.Column width={16} >
                          <Form className=' fluid' size='small' >
                            <Form.Group>
                              <Form.Field width={16}>
                                <label>วันที่</label>
                                <DatePicker
                                  id='date'
                                  dateFormat="DD/MM/YYYY"
                                  selected={this.state.bill_date}
                                  readOnly
                                  onChange={(date) => {
                                    date = date ? date : moment()
                                    this.setState({ bill_date: date });
                                  }}
                                  disabled={this.state.invoice_disabled}
                                />
                              </Form.Field  >
                              <Form.Dropdown width={16}
                                id='vendor'
                                label='*ชื่อโรงงาน/ร้านส่ง'
                                search
                                selection
                                options={this.state.vendors_dropdown_search}
                                value={this.state.vendor}
                                placeholder='ชื่อโรงงาน/ร้านส่ง'
                                defaultValue={this.state.vendor} onChange={(e, data) => {
                                  this.setState({ vendor: data.value });
                                }} />
                              <Form.Field width={16} autoComplete="off">
                                <label>มูลค่าต้นทุนทองคำ 96.5</label>
                                <Input
                                  autocomplete="off"
                                  placeholder='0.00'
                                  className='text-right'
                                  readOnly
                                  value={this.props.invoice_detail.cost}
                                  maxLength={10}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form>
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Form className=' fluid' size='small' >
                            <Form.Group>
                              <Form.Field width={16} autoComplete="off">
                                <label>*น้ำหนักทองคำ 99.99 (กรัม)</label>
                                <Input
                                  autoComplete="off"
                                  placeholder='0.000'
                                  className='text-right'
                                  onChange={(e) => {
                                    if (isNaN(Number(e.target.value))) {
                                    } else {
                                      if (e.target.value.split('.')[0].length > 6) {
                                        return;
                                      }
                                      if (e.target.value.toString().split(".")[1] != undefined)
                                        if (e.target.value.toString().split(".")[1].length > 3)
                                          return
                                      this.setState({ weight_total: e.target.value })
                                    }
                                  }}
                                  onBlur={() => {
                                    setTimeout(() => {
                                      if (this.state.focus1) {
                                        this.setState({
                                          focus1: false,
                                        });
                                      }
                                    }, 0);
                                  }}
                                  onFocus={(e) => {
                                    e.target.select()
                                    if (!this.state.focus1) {
                                      this.setState({
                                        focus1: true,
                                      });
                                    }
                                  }}
                                  value={this.state.focus1 ? this.state.weight_total.toString().replace(/,/g, '') : Utility.weightFormat(this.state.weight_total)}
                                  // disabled={this.state.weight_out_disabled}
                                  maxLength={10}
                                />
                              </Form.Field>
                              <Form.Field width={16} autoComplete="off">
                                <label>ราคาต้นทุนทองคำ 99.99 (ต้นทุน/กรัม)</label>
                                <Input
                                  autocomplete="off"
                                  placeholder='0.00'
                                  className='text-right'
                                  readOnly
                                  value={Utility.priceFormat(this.state.weight_total ? Number(this.props.invoice_detail.cost.toString().replace(/,/g, '')) / Number(this.state.weight_total) : 0)}
                                  maxLength={10}
                                />
                              </Form.Field>
                              <Form.Field width={16} autoComplete="off">
                                <label>ราคาต้นทุนทองคำ 99.99 (ต้นทุน/บาท)</label>
                                <Input
                                  autocomplete="off"
                                  placeholder='0.00'
                                  className='text-right'
                                  readOnly
                                  value={Utility.priceFormat(this.state.weight_total ? Math.round(Number(this.props.invoice_detail.cost.toString().replace(/,/g, '')) / Number(this.state.weight_total) * 100 / 0.0656) / 100 : 0)}
                                  maxLength={10}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form>
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Form className=' fluid' size='small' >
                            <Form.Group>
                              <Form.Field width={16} autoComplete="off">
                                <label>หมายเหตุ</label>
                                <Input
                                  autoComplete="off"
                                  placeholder='หมายเหตุ'
                                  className=''
                                  onChange={(e) => {

                                    this.setState({ description: e.target.value })
                                  }}
                                  value={this.state.description}
                                  // disabled={this.state.weight_out_disabled}
                                  maxLength={150}
                                />
                              </Form.Field>
                              <Form.Field width={16} autoComplete="off"></Form.Field>
                              <Form.Field width={16} autoComplete="off"></Form.Field>
                            </Form.Group>
                          </Form>
                        </Grid.Column>
                        <Grid.Column>
                          <Form>
                            <Form.Dropdown
                              // loading={this.props.loading}
                              label='ราคาทองคำ 99.99 (บาท)' fluid selection width={16}
                              options={this.state.gold_price_period}
                              value={this.state.gold_price_value}
                              // onChange={this.goldPriceChangeHandler} 
                              disabled={true}
                              style={{ 'word-break': 'break-word' }}
                              openOnFocus={false}
                            />
                          </Form>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              </div>

            </Modal.Content>
            <Modal.Actions>
              <Button
                id='btnUpdate'
                size='small'
                icon='lightning'
                color='red'
                onClick={(e) => {
                  this.handelSubmitSave();
                }
                }
                className={this.state.button_update_stoick_loading ? 'loading' : ''}
                disabled={this.state.button_update_stoick_loading || (this.state.weight_price == '' || this.state.weight_price == null || this.state.weight_price == 0) || (this.state.weight_total == '' || this.state.weight_total == null || this.state.weight_total == 0) || this.state.vendor == ''}
                content='อนุมัติและอัพเดทสต๊อก' />

            </Modal.Actions>
            {this.state.open_print
              ? <GoldMeltPrintPreview
                invoice_detail={this.props.invoice_detail}
                vendors={this.props.vendors}
                items={items}
                onClose={() => {
                  this.setState({ open_print: false })
                }} />
              : ''}
          </> : <>
            <Modal.Header>
              <Header as='h4' className="text-center">
                <Header.Content >
                  ยกเลิกรายการ
                </Header.Content>
              </Header>
            </Modal.Header>
            <Modal.Content >
              <label className="text-center">
                คุณต้องการยกเลิก {this.props.invoice_detail.number && <span id='textBillID'>[{this.props.invoice_detail.number}]</span>}
              </label>
              <Form className=' fluid' size='small' >
                <Form.Field width={16} autoComplete="off">
                  <label>หมายเหตุ</label>
                  <Input
                    autoComplete="off"
                    placeholder='หมายเหตุ'
                    className=''
                    onChange={(e) => {

                      this.setState({ remark: e.target.value })
                    }}
                    value={this.state.remark}
                    // disabled={this.state.weight_out_disabled}
                    maxLength={150}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                id='btnUpdate'
                size='small'
                color='red'
                onClick={(e) => {
                  this.submitDelStock()
                }
                }
                className={this.state.button_update_stoick_loading ? 'loading' : ''}
                content='ยืนยัน' />

            </Modal.Actions>
          </>}

      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(BillModal)