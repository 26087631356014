/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                date_lt: moment(),
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([branches, staffs, product_types]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }


            this.product_types = values[2];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                product_types: product_types
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_date = url.searchParams.get("date_lt");
        if (temp_date !== null) {
            this.setState({
                search: {
                    date_lt: moment(temp_date, 'DD/MM/YYYY'),
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                date_lt: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        let date = Utility.formatDate2(search.date_lt)
        delete search.date_lt
        search.start_date = date
        search.end_date = date
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/stock_new_product_lot/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.stock_lot, d.invoice_item, d.product, d.kind, d.category)
                })
            }
        })
    }

    setFieldValue(_items, invoice_items, products, kinds, categories) {
        let items = []
        let product = {}
        for (let i in _items) {
            let item = _items[i]
            const invoice_item = invoice_items.find(k => k.id === item.invoice_item)
            const product_list = products.find(k => k.id === invoice_item.product)
            const kind = kinds.find(k => k.id === product_list.kind)
            const category = categories.find(k => k.id === product_list.category)

            item['product_code'] = product_list.code
            item['product_name'] = product_list.name
            item['product_kind'] = kind.name
            item['product_category'] = category.name

            if (product[item.product_code] == null)
                product[item.product_code] = {
                    items: [],
                    name: item.product_name,
                    code: item.product_code,
                    weight: invoice_item.weight,
                    category: item.product_category,
                    kind: item.product_kind,
                }
            product[item.product_code].items.push(item)
        }
        for (let i in product) {
            let stock = 0
            let inc = 0
            let dec = 0
            let total = 0
            for (let j in product[i].items) {
                if (product[i].items[j].kind == 'I' || product[i].items[j].kind == 'IO' || product[i].items[j].kind == 'TI' || product[i].items[j].kind == 'VT' || product[i].items[j].kind == 'V') {
                    inc += parseFloat(product[i].items[j].amount)
                } else if (product[i].items[j].kind == 'O' || product[i].items[j].kind == 'TO' || product[i].items[j].kind == 'XC' || product[i].items[j].kind == 'DF' || product[i].items[j].kind == 'CU') {
                    dec += parseFloat(product[i].items[j].amount)
                } else if (product[i].items[j].kind == 'ยกไป') {
                    total += parseFloat(product[i].items[j].after)
                } else if (product[i].items[j].kind == 'ยกมา') {
                    stock += parseFloat(product[i].items[j].after)
                }
            }
            items.push({
                name: product[i].name,
                code: product[i].code,
                weight: product[i].weight,
                category: product[i].category,
                kind: product[i].kind,
                stock: stock,
                inc: inc,
                dec: dec,
                total: total,
            })
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data

        let title = 'รายงานสต็อกทองคงเหลือตามวันที่';
        let filename = 'sell_type_amount';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
            border: '1px solid black',
            'border-collapse': 'collapse'
        };
        const textRight = {
            'text-align': 'right',
            border: '1px solid black',
        }

        const textLeft = {
            'text-align': 'left',
            border: '1px solid black',
        }
        const textCenter = {
            'text-align': 'center',
            border: '1px solid black',
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let amount = 0
        //let weight = 0
        let weight_b = 0
        let sell = 0
        let net = 0

        let category = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (category[item.category] == null)
                category[item.category] = {
                    items: [],
                    title: item.category,
                    // id: item.product.category.id
                }

            category[item.category].items.push(item)
        }
        let items2 = []
        for (let i in category) {
            items2.push(category[i])
        }

        let sum_total = 0;
        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }
        let stock_n_all = 0
        let stock_w_all = 0
        let inc_n_all = 0
        let inc_w_all = 0
        let dec_n_all = 0
        let dec_w_all = 0
        let total_n_all = 0
        let total_w_all = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสต็อกทองคงเหลือตามวันที่</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.date_lt}
                                    selected={this.state.search.date_lt}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'date_lt',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='11'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='11'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='11'><center id='date'>ประจำวันที่ : {Utility.formatDate(this.state.search.date_lt)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter} rowspan={2}>ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} rowspan="2">รหัสสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} rowspan="2">น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='2'>สต็อก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='2'>เพิ่ม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='2'>ลด</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textCenter} >นับ</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textCenter} colSpan='2'>เหลือ</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter} >น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >จำนวน</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textCenter} >-</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textCenter} >น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >จำนวน</Table.HeaderCell>

                                        </Table.Row>
                                    </Table.Header>

                                    {items2.map((row, i) => {

                                        let kind = {}
                                        for (let i in row.items) {
                                            let itemm = row.items[i]
                                            if (kind[itemm.kind] == null) {
                                                kind[itemm.kind] = {
                                                    items: [],
                                                }
                                            }
                                            kind[itemm.kind].items.push(itemm)
                                        }
                                        let itemss = []
                                        let product = {}
                                        for (let i in kind) {
                                            for (let j in kind[i].items) {
                                                let o = kind[i].items[j]
                                                if (product[o.code] == null) {
                                                    product[o.code] = o
                                                }
                                            }
                                        }
                                        for (let i in product) {
                                            itemss.push(product[i])
                                        }
                                        let stcok_n = 0;
                                        let stock_w = 0;
                                        let inc_n = 0;
                                        let inc_w = 0;
                                        let dec_n = 0;
                                        let dec_w = 0;
                                        let total_n = 0;
                                        let total_w = 0
                                        return (<Table.Body>
                                            <Table.Row >
                                                <Table.Cell style={textLeft} colSpan='14'>%ทอง {row.title}</Table.Cell>
                                            </Table.Row>
                                            {itemss.map((row, i) => {
                                                stock_w_all += (parseFloat(row.weight) * row.stock)
                                                stock_n_all += row.stock
                                                inc_w_all += (parseFloat(row.weight) * row.inc)
                                                inc_n_all += row.inc
                                                dec_w_all += (parseFloat(row.weight) * row.dec)
                                                dec_n_all += row.dec
                                                total_w_all += (parseFloat(row.weight) * row.total)
                                                total_n_all += row.total

                                                stock_w += (parseFloat(row.weight) * row.stock)
                                                stcok_n += row.stock
                                                inc_w += (parseFloat(row.weight) * row.inc)
                                                inc_n += row.inc
                                                dec_w += (parseFloat(row.weight) * row.dec)
                                                dec_n += row.dec
                                                total_w += (parseFloat(row.weight) * row.total)
                                                total_n += row.total

                                                return (<Table.Row id={row.code}>
                                                    <Table.Cell style={textCenter} >{row.kind}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{row.code}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.weightFormat(parseFloat(row.weight))}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.weightFormat(parseFloat(row.weight) * row.stock)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.numberFormat(row.stock)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.weightFormat(parseFloat(row.weight) * row.inc)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.numberFormat(row.inc)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.weightFormat(parseFloat(row.weight) * row.dec)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.numberFormat(row.dec)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.weightFormat(parseFloat(row.weight) * row.total)}</Table.Cell>
                                                    <Table.Cell style={textCenter} >{Utility.numberFormat(row.total)}</Table.Cell>
                                                </Table.Row>)
                                            })}
                                            <Table.Row >
                                                <Table.Cell style={textRight} colSpan='3'><b><u>รวม%ทอง {row.title}</u></b></Table.Cell>
                                                <Table.Cell id={'stock_w_' + row.id} style={textCenter} ><b><u>{Utility.weightFormat(stock_w)}</u></b></Table.Cell>
                                                <Table.Cell id={'stock_n_' + row.id} style={textCenter} ><b><u>{Utility.numberFormat(stcok_n)}</u></b></Table.Cell>
                                                <Table.Cell id={'inc_w_' + row.id} style={textCenter} ><b><u>{Utility.weightFormat(inc_w)}</u></b></Table.Cell>
                                                <Table.Cell id={'inc_n_' + row.id} style={textCenter} ><b><u>{Utility.numberFormat(inc_n)}</u></b></Table.Cell>
                                                <Table.Cell id={'dec_w_' + row.id} style={textCenter} ><b><u>{Utility.weightFormat(dec_w)}</u></b></Table.Cell>
                                                <Table.Cell id={'dec_n_' + row.id} style={textCenter} ><b><u>{Utility.numberFormat(dec_n)}</u></b></Table.Cell>
                                                <Table.Cell id={'total_w_' + row.id} style={textCenter} ><b><u>{Utility.weightFormat(total_w)}</u></b></Table.Cell>
                                                <Table.Cell id={'total_n_' + row.id} style={textCenter} ><b><u>{Utility.numberFormat(total_n)}</u></b></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell style={textRight} colSpan='3'><b><u>รวมทั้งหมด</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.weightFormat(stock_w_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.numberFormat(stock_n_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.weightFormat(inc_w_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.numberFormat(inc_n_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.weightFormat(dec_w_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.numberFormat(dec_n_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.weightFormat(total_w_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textCenter} ><b><u>{Utility.numberFormat(total_n_all)}</u></b></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)