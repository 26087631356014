/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment,Loader,Dimmer, Label
} from 'semantic-ui-react';
import { connect } from 'react-redux'
import Utility from '../../Utility';
import Settings from '../../Settings';
import moment from 'moment';
import CanvasJSChart from '../../canvasjs.react'
var dataPoints =[];
class SellYear extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                start_date: Utility.formatYear(moment()) + '-01-01',
                end_date: Utility.formatYear(moment()) + '-12-31',
                branch: props.auth.branch == undefined ?'':props.auth.branch.id
            },
            items: []
        }
    }

    componentDidMount() {
        var bills = Utility.getFetch(Settings.baseUrl + '/bills/?kind=SE&is_enabled=1&'+Utility.jsonToQueryString(this.state.search));
        Promise.all([bills]).then((values) => {
            this.setState({
                loading: false,
                items: values[0],
                is_ready: true
            });

            let label = []
             for(let y = 0 ;y<12;y++){
            let s = (y+1).toString()
            if(s.length == 1){
                label.push('0'+s+'/'+new Date().getFullYear())
            } else{
                label.push(s+'/'+new Date().getFullYear())
            }
        }
        this.setState({label})


            let items2 = values[0]
            let group_month = {}
            let labels = []
            let data = []
            for (let i in items2) {
                let item = items2[i];
                let k = Utility.formatMonthYear(item.bill_date)
                if (group_month[k] == null) {
                    group_month[k] = {
                        items: [],
                        date: k
                    }
                    labels.push(k)
                }
                group_month[k].items.push(item);
            }

            let items = []
            dataPoints = []
            let totals = [0,0,0,0,0,0,0,0,0,0,0,0]
            for (let i in group_month) {
                items.push(group_month[i])
                let total = 0
                for (let j in group_month[i].items) {
                    total += parseFloat(group_month[i].items[j].total)
                }
                totals[label.indexOf(group_month[i].date)] = total
                // dataPoints.push({
                //     y: total,
                //     label: group_month[i].date
                // })
                
            }
            for(let r in label){
                dataPoints.push({
                    y: totals[r],
                    label: label[r]
                })
            }

            this.setState({dataPoints})

            if(this.chart)
                this.chart.render();
        });
        

    }
    render() {
        const {dataPoints} = this.state
        const options = {
			theme: "light2",
			title: {
				text: "สรุปยอดขายทองปี "+Utility.formatYear(moment())
			},
			axisY: {
				title: "ยอดขาย",
				prefix: "฿",
				includeZero: false
			},
			data: [{
				type: "line",
				// xValueFormatString: "MMM YYYY",
				yValueFormatString: "#,##0.00",
                dataPoints: dataPoints
			}]
        }

        return (<div>
            <Segment >

            <Dimmer active={!this.state.is_ready} inverted>
                    <Loader inverted>Loading..</Loader>
                </Dimmer> 
                <CanvasJSChart options = {options} onRef={ref => this.chart = ref} />
             </Segment>
        </div>)
    }
}

const mapStateToProps = state =>{
    
    return ({
      branches: state.branches,
      auth: state.auth
    })
  }
  export default connect(
    mapStateToProps
  )(SellYear)
