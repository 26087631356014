if (process.env.REACT_APP_MODE === 'production') {
    module.exports = require('./prod.config');
}else if (process.env.REACT_APP_MODE === 'staging') {
    module.exports = require('./stg.config');
}else if (process.env.REACT_APP_MODE === 'test') {
    module.exports = require('./test.config');
}else if (process.env.REACT_APP_MODE === 'dev') {
    module.exports = require('./dev.config');
}else {
    module.exports = require('./local.config');
}