/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import { Table, Column, Cell } from 'fixed-data-table';
import BillPrintPreview from './BillPrintPreview'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Dropdown,
  Modal,
  Grid,
  Label,
  Input,
  Popup,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
class ItemsIconCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    return (
      <Cell {...props}>
        <div className='text-center'>{!data[rowIndex][field] ? <Icon name='minus circle' color='yellow' /> : <Icon color='green' name='check circle outline' />}</div>
      </Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}

class InvoiceTransferInModal extends Component {

  constructor(props) {
    super(props);

    this.modal_status = false;
    props.branches.shift();
    this.state = {
      branches: props.branches,
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      ref_transfer: '',
      branch: '',
      branch_to: '',
      status_stock: 'N',
      modal_open: this.props.open,
      product_action_title: 'เพิ่มสินค้า',
      product_action: 'add',
      invoice_item: [],
      button_print_disabled: true,
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการโอนเข้าทองใหม่ระหว่างสาขา',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      description: '',
      msg_error: {},
      items_in: [],
      items_out: [],
      focus: '',
      modal_reject: false,
      description_reject: '',
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.invoice_transfer = []

    this.onDeleteProduct = this
      .onDeleteProduct
      .bind(this);
  }

  submitSaveInvoice() {

    let msg_error = {}
    if (this.state.ref_transfer == '') {
      msg_error['ref_transfer'] = ['กรุณาเลือกเลขที่ใบโอนสาขาต้นทาง']
    }
    if (this.state.branch_to == '' || this.state.branch_to == 0) {
      msg_error['branch_to'] = ['กรุณาเลือกสาขา']
    }

    if (this.state.branch_to == this.state.branch) {
      msg_error['branch_to'] = ['เลือกสาขาไม่ถูกต้อง']
    }
    this.setState({ msg_error: msg_error })
    if (Object.keys(msg_error).length > 0) {

      return;
    }

    const ref_invoice_transfer = this.invoice_transfer.find((element) => {
      return element.id === this.state.ref_transfer;
    })

    var formData = {
      branch_from: ref_invoice_transfer.branch_from,
      branch_to: ref_invoice_transfer.branch_to,
      ref_transfer: this.state.ref_transfer,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      kind: 'TI',
      description: this.state.description
    };

    this.setState({ button_save_loading: true })
    if (this.props.action == 'add') {
      formData.status_stock = 'N'
      const url = Settings.baseUrl + '/invoices_transfer/';
      Utility.post(url, formData, (status, data) => {

        this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
        this
          .props
          .onAddInvoice(data);
        this.loadInvoiceItem();
        alert('บันทึกข้อมูลสำเร็จ!')
        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.description
      };

      const url = Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.id + "/";

      Utility.patch(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem();
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  submitSaveInvoice_no_alert() {

    let msg_error = {}
    if (this.state.ref_transfer == '') {
      msg_error['ref_transfer'] = ['กรุณาเลือกเลขที่ใบโอนสาขาต้นทาง']
    }
    if (this.state.branch_to == '' || this.state.branch_to == 0) {
      msg_error['branch_to'] = ['กรุณาเลือกสาขา']
    }

    if (this.state.branch_to == this.state.branch) {
      msg_error['branch_to'] = ['เลือกสาขาไม่ถูกต้อง']
    }
    this.setState({ msg_error: msg_error })
    if (Object.keys(msg_error).length > 0) {

      return;
    }

    const ref_invoice_transfer = this.invoice_transfer.find((element) => {
      return element.id === this.state.ref_transfer;
    })

    var formData = {
      branch_from: ref_invoice_transfer.branch_from,
      branch_to: ref_invoice_transfer.branch_to,
      ref_transfer: this.state.ref_transfer,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      kind: 'TI',
      description: this.state.description
    };

    this.setState({ button_save_loading: true })
    if (this.props.action == 'add') {
      formData.status_stock = 'N'
      const url = Settings.baseUrl + '/invoices_transfer/';
      Utility.post(url, formData, (status, data) => {

        this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
        this
          .props
          .onAddInvoice(data);
        this.loadInvoiceItem();
        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.description
      };

      const url = Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.id + "/";

      Utility.patch(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem();
        }
      });
    }
  }

  supmitProduct() {

    let formData = {
      invoice: this.props.invoice_detail.id,
      product: this.state.product_id,
      status_stock: 'N',
      amount: this.state.product_amount,
      weight: this.state.product_weight,
      weight_total: this.state.product_weight_total,
      weight_real: this.state.product_weight_real
    };

    let msg_error = {}
    if (this.state.product_id == null || this.state.product_id == '') {
      msg_error['product'] = ['กรุณาเลือกสินค้า']
    }
    if (this.state.product_amount == null || this.state.product_amount == '') {
      msg_error['amount'] = ['กรุณาระบุจำนวน']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error })
      return;
    }

    this.setState({ button_product_loading: true })
    if (this.state.product_action == 'add') {
      const url = Settings.baseUrl + '/invoice_item/';
      Utility.post(url, formData, (status, data) => {
        if (status) {
          this.setState({ button_product_loading: false })
          this.loadInvoiceItem();
        } else { // error
          if (data.length !== 0)
            this.setState({ msg_error: data })
        }
      });
    } else {
      const url = Settings.baseUrl + '/invoice_item/' + this.state.item_id + '/';
      Utility.put(url, formData, (status, data) => {
        if (status) {
          this.setState({ button_product_loading: false })
          this.loadInvoiceItem();
        } else { // error
          if (data.length !== 0)
            this.setState({ msg_error: data })
        }
      });
    }

  }

  async loadInvoiceItem(reload) {
    this.setState({ loader: true });
    var invoices = await Utility.getAsync(Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.id + "/");
    var invoices_out = await Utility.getAsync(Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.ref_transfer + "/");
    var items_out = await Utility.getAsync(Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.ref_transfer + '/items/');
    var items_in = await Utility.getAsync(Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.id + '/items/');


    invoices = invoices.data
    invoices_out = invoices_out.data
    items_in = items_in.data
    items_out = items_out.data

    if (invoices_out.is_void) {
      alert('ใบโอนออกถูกยกเลิกแล้ว')
    } else if(invoices_out.is_reject){
      alert('ใบโอนออกถูก Reject แล้ว')
    }

    this.loadInvoiceTransfer(invoices.branch_to, invoices.is_transfer ? 1 : 0, true)
    let description = this.state.description
    let status_bill = this.state.status_bill
    this.setState({
      items_out: items_out,
      items_in: items_in,
      number: invoices.number,
      description: invoices.description,
      branch_to: invoices.branch_to,
      ref_transfer: invoices.ref_transfer,
      status_bill: invoices.status_bill,
      is_void: invoices.is_void,
      is_void_to: invoices_out.is_void,
      is_reject_to: invoices_out.is_reject,
      is_transfer: invoices.is_transfer,
      invoice_date: moment(invoices.invoice_date),
      invoice_disabled: true,
      button_save_title: 'บันทึก',
      invoice_item: this.setFieldValue(items_in, items_out),
      button_print_disabled: false,
      status_stock: invoices.status_stock,
      button_update_stoick_disabled: items_in.length == 0 || invoices.status_stock == 'Y',
      invoice_add_product_disabled: invoices.status_stock == 'Y',
      invoice_title: <span >{< span > แก้ไขรายการโอนเข้าทองใหม่ระหว่างสาขา </span>} </span>,
      loader: false
    });
    if (reload == true) {
      this.setState({
        description: description,
        status_bill: status_bill,
      })
    }
  }

  setFieldValue(item_in, item_out) {

    let invoice_weight_total = 0;
    let invoice_profit_total = 0;
    const items = []
    const _items = {}
    for (let i = 0; i < item_out.length; i++) {
      const item = item_out[i]
      const product = item.new_product.stock_product.product
      const cost = item.new_product.invoice_item.cost
      const fee = item.new_product.invoice_item.fee
      item['status_stock_title'] = Settings.status_stock[item.status_stock]
      item['product_code'] = product.code
      item['product_name'] = product.name
      item['category_title'] = product.category.name
      item['kind_title'] = product.kind.name
      item['weight'] = parseFloat(product.weight)
      item['fee'] = fee
      item['cost'] = cost;

      const _item_in = item_in.find((el) => {
        return el.new_product.qrcode == item.new_product.qrcode
      })

      if (_item_in) {
        item['in'] = 1
        item['out'] = 1
      } else {
        item['in'] = 1
        item['out'] = 0
      }

      if (product.type_sale == 2) {

        item['price_total'] = Utility.priceFormat(parseFloat(product.cost) * parseFloat(item.amount));
        item['type_sale_title'] = 'งานชิ้น';
        item['price_tag'] = Utility.priceFormat(parseFloat(product.price_tag));
      } else {
        item['price_total'] = Utility.priceFormat(0.00);
        item['type_sale_title'] = 'งานชั่ง';
        item['price_tag'] = Utility.priceFormat(0.00);
      }

      invoice_weight_total += item.weight
      const key = product.code + item.new_product.invoice_item.id
      if (_items[key] == null) {
        item.weight_total = item.weight
        _items[key] = item
        _items[key].amount_diff = `${_items[key].out}/${_items[key].in}`
        _items[key].status_icon = _items[key].out == _items[key].in
      } else {

        _items[key].in += item['in']
        _items[key].out += item['out']
        _items[key].weight_total += item.weight
        _items[key].amount_diff = `${_items[key].out}/${_items[key].in}`
        _items[key].status_icon = _items[key].out == _items[key].in
      }
    }

    for (let k in _items)
      items.push(_items[k])

    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      invoice_product_total: Utility.numberFormat(item_in.length),
      invoice_itme_total: Utility.numberFormat(items.length),
      invoice_profit_total: Utility.priceFormat(invoice_profit_total)
    });
    for (let j = 0; j < items.length; j++) {
      items[j]['weight'] = Utility.weightFormat(items[j].weight)
      items[j]['amount_diff'] = Utility.numberFormat(items[j].amount_diff.toString())
      items[j]['weight_total'] = Utility.weightFormat(items[j].weight_total)
      items[j]['fee'] = Utility.priceFormat(items[j].fee)
      items[j]['cost'] = Utility.priceFormat(items[j].cost)
      items[j]['price_tag'] = Utility.priceFormat(items[j].price_tag)
    }
    return items;
  }

  onDeleteProduct(e, d) {

    if (!window.confirm('ยืนยันลบ'))
      return

    let data = this.state.invoice_item[d.positon];
    if (data.status_stock == 'N') {
      const url = Settings.baseUrl + '/invoice_item/' + data.id + '/';
      Utility.delete(url, (status, data) => {

        this.setState({ button_product_loading: false })
        this.loadInvoiceItem();
      });
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  submitUpdateStock() {
    this.setState({ qrcode: '' })
    this.setState({ button_update_stoick_loading: true })
    const url = Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.id + '/stock_update/';
    Utility.get(url, (status, data) => {

      if (status) {
        this.props.onAddInvoice();
        this.loadInvoiceItem();
        alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
        this.setState({ focus: 'update' })
      } else {
        alert(data.error)
      }

      this.setState({ button_update_stoick_loading: false })
    });
  }

  componentDidMount() {
    this.setState({ loader_active: true, btn_stock: true });
    this.setState({ focus: this.props.action })

    if (this.props.action == 'edit')
      this.loadInvoiceItem();
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width2')
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.addItemByQrcode(this.state.qrcode)
    }
  }

  async addItemByQrcode(qrcode) {
    qrcode = qrcode.trim()
    const url = Settings.baseUrl + `/invoices_transfer/${this.props.invoice_detail.id}/qrcode/`
    const req = await Utility.postAsync(url, { qrcode })

    if (req.status_code == 400) {
      alert(req.data.detail)
    } else if (req.status_code == 404) {
      alert('ไม่พบ qrcode ในระบบ')
    } else if (req.status_code == '201') {
      this.loadInvoiceItem(true)

    } else {
      alert(req.data.detail)
    }
    this.setState({ qrcode: '' })

  }

  handleQrchangeChange(e, { value, name }) {
    this.setState({
      [name]: value
    })
  }

  async loadInvoiceTransfer(branch_to, is_transfer = 0, all = false) {
    let url = Settings.baseUrl + `/invoices_transfer/?${Utility.jsonToQueryString({ branch_to, is_transfer, kind: 'TO', 'status_stock': 'Y', 'is_void': 0, 'is_reject': 0, })}`
    if (all) {
      url = Settings.baseUrl + `/invoices_transfer/?${Utility.jsonToQueryString({ branch_to, is_transfer, kind: 'TO', })}`
    }
    const req = await Utility.getAsync(url)

    this.invoice_transfer = req.data
    const invoice_transfer_options = []
    this.invoice_transfer.map((item, i) => {
      invoice_transfer_options.push({
        value: item.id,
        key: item.id,
        text: item.number
      })
    })
    this.setState({
      invoice_transfer_options: invoice_transfer_options
    })

  }

  render() {
    let items = this.state.invoice_item;
    return (
      <div>
        <Modal size="large" open={true} /*dimmer='blurring'*/ >
          < Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
          <Modal.Header>
            <Header as='h4'>
              <Header.Content>
                {this.state.invoice_title}
                {this.state.number && <span id='textBillID'>[บิลเลขที่ {this.state.number}]</span>}
                <Header.Subheader>
                  <span id='statusBill'><Icon
                    name={this.state.status_stock == 'N'
                      ? 'warning circle'
                      : 'check circle'}
                    color={this.state.status_stock == 'N'
                      ? 'yellow'
                      : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
                  {
                    this.state.is_transfer ? <Label as='span' size={'tiny'} circular color={'green'}>โอนเข้าแล้ว</Label>
                      : this.state.is_void ? <Label as='span' size={'tiny'} circular color={'grey'}>ยกเลิกแล้ว</Label>
                        : ''
                  }
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Modal.Header> < Modal.Content > <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid >
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form className='fluid' size='small'>

                        <Form.Field width={16} error={this.state.msg_error.branch_to != null}>
                          <label>*โอนเข้าสาขา
                      <MsgInput text={this.state.msg_error.branch_to} /></label>
                          <Dropdown
                            id='dropDownFromBranchForAdd'
                            placeholder='โอนเข้าสาขา'
                            search
                            selection
                            width={16}
                            options={this.props.branches}
                            defaultValue={this.state.branch_to}
                            value={this.state.branch_to}
                            onChange={(e, data) => {
                              this.setState({ branch_to: data.value })
                              this.loadInvoiceTransfer(data.value)
                            }}
                            disabled={this.state.invoice_disabled} />
                        </Form.Field>
                        <Form.Group >

                          <Form.Field width={16} error={this.state.msg_error.ref_transfer != null}>
                            <label>*เลขที่ใบโอนสาขาต้นทาง
                        <MsgInput text={this.state.msg_error.ref_transfer} /></label>
                            <Dropdown
                              id='dropDownToBranchForAdd'
                              placeholder='เลขที่ใบโอนสาขาต้นทาง'
                              disabled={this.state.invoice_disabled}
                              search
                              selection
                              width={16}
                              options={this.state.invoice_transfer_options}
                              value={this.state.ref_transfer}
                              onChange={(e, data) => {
                                this.setState({ ref_transfer: data.value });
                              }} />
                          </Form.Field>
                        </Form.Group >

                        <Form.Group >
                          <Form.Field width={16}>
                            <label>วันที่</label>
                            <DatePicker
                              id='dateStartForAdd'
                              dateFormat="DD/MM/YYYY"
                              selected={this.state.invoice_date}
                              readOnly
                              onChange={(date) => {
                                if (date === null || date === '') {
                                  this.setState({ invoice_date: moment() });
                                } else {
                                  this.setState({ invoice_date: date });
                                }
                              }}
                              disabled={this.state.invoice_disabled} />
                          </Form.Field>
                        </Form.Group >
                        <Form.Group inline>
                          <Form.Checkbox
                            id='clearBill'
                            label='เคลียร์บิล'
                            width={16}
                            disabled={this.state.is_void || this.state.is_reject_to || this.state.is_void_to}
                            checked={this.state.status_bill == 'Y'}
                            onChange={(e, v) => {
                              this.setState({
                                status_bill: v.checked
                                  ? 'Y'
                                  : 'N'
                              });
                            }} />
                        </Form.Group>

                        <Form.Group >
                          <Form.TextArea
                            label='หมายเหตุ'
                            maxLength={255}
                            placeholder='หมายเหตุ'
                            width={16}
                            disabled={this.state.is_void || this.state.is_reject_to || this.state.is_void_to}
                            value={this.state.description}
                            onChange={(e, v) => {
                              this.setState({ description: v.value })
                            }} />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={10}>
                            <Header.Content floated='left' as='h3'>
                              รายการสินค้า
                  <Header.Subheader><small> <Label as='span' basic> <Icon color='green' name='check circle outline' /> {this.state.items_in.length}</Label> <Label as='span' basic><Icon name='minus circle' color='yellow' />  {this.state.items_out.length - this.state.items_in.length}</Label></small></Header.Subheader>
                            </Header.Content>
                          </Form.Field>
                          <Form.Field width={6}>
                            <label>QR Code</label>
                            {this.state.focus === 'edit' && this.state.status_stock === 'N' ?
                              <Input
                                className='text-right'
                                autoFocus={1}
                                placeholder='QR Code'
                                name='qrcode'
                                value={this.state.qrcode}
                                floated='right'
                                autoFocus
                                ref={(c) => {
                                  this.qrcodee = c
                                }}
                                disabled={this.state.is_void || this.state.is_reject_to || this.state.is_void_to}
                                // onKeyPress={(e)=>{
                                //   if (e.key == 'Enter') {
                                //     this.submitQRcode()
                                //   }
                                // }}
                                onChange={this.handleQrchangeChange.bind(this)}
                                onKeyDown={this.handleKeyDown.bind(this)} />
                              :
                              <Input
                                disabled={1}
                                placeholder='QR Code'
                                />
                            }
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <div id="table_width2">
                        <Table
                          rowsCount={items.length}
                          rowHeight={35}
                          headerHeight={35}
                          width={this.state.table_width}
                          height={400}>
                          <Column
                            header={< Cell className='text-center' > สถานะ </Cell>}
                            cell={< ItemsIconCell data={
                              items
                            }
                              field="status_icon" />}
                            width={50} />
                          <Column
                            header={< Cell className='text-center'> จำนวน </Cell>}
                            cell={< ItemsCell position='top center' width={150} className='text-center' data={
                              items
                            }
                              field="amount_diff" />}
                            width={150} />
                          <Column
                            header={< Cell > รหัสสินค้า </Cell>}
                            cell={< ItemsCell position='top left' width={100} data={
                              items
                            }
                              field="product_code" />}
                            width={100} />
                          <Column
                            header={< Cell > กลุ่มสินค้า </Cell>}
                            cell={< ItemsCell position='top left' width={120} data={
                              items
                            }
                              field="category_title" />}
                            width={120} />
                          <Column
                            header={< Cell > ประเภท </Cell>}
                            cell={< ItemsCell position='top left' width={120} data={
                              items
                            }
                              field="kind_title" />}
                            width={120} />
                          <Column
                            header={< Cell > ชื่อสินค้า </Cell>}
                            cell={< ItemsCell position='top left' width={150} id='ProductName' data={
                              items
                            }
                              field="product_name" />}
                            width={150} />
                          <Column
                            header={< Cell className='text-right' > น.น./ชิ้น</Cell >}
                            cell={< ItemsCell position='top right' width={100} data={
                              items
                            }
                              field="weight" textAlign='text-right' />}
                            width={100} />
                          <Column
                            header={< Cell className='text-right' > น.น.รวม </Cell>}
                            cell={< ItemsCell position='top right' width={100} data={
                              items
                            }
                              field="weight_total" textAlign='text-right' />}
                            width={100} />

                          <Column
                            header={< Cell className='text-right' > ต้นทุนชิ้นละ </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="cost" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > ค่าแรงชิ้นละ </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="fee" textAlign='text-right' />}
                            width={100} />
                          <Column
                            header={< Cell className='text-right' > ราคาป้ายชิ้นละ </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="price_tag" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-center' > ประเภทการขาย </Cell>}
                            cell={< ItemsCell position='top center' width={120} data={
                              items
                            }
                              field="type_sale_title" textAlign='text-center' />}
                            width={120} />
                        </Table>
                      </div>
                      <br />
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input
                            id='invoice_itme_total'
                            label='จำนวนรายการ'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_itme_total}
                            readOnly />

                          <Form.Input
                            id='invoice_weight_total'
                            label='น้ำหนักรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_weight_total}
                            readOnly />
                          <Form.Input
                            id='invoice_product_total'
                            label='จำนวนรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_product_total}
                            readOnly />
                        </Form.Group>
                      </Form>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>

            </div> </div>
            {this.state.open_print
              ? <BillPrintPreview
                branches={this.state.branches}
                invoice_detail={this.props.invoice_detail}
                items={items}
                product_total={this.state.invoice_product_total}
                weight_total={this.state.invoice_weight_total}
                status_stock={this.state.status_stock}
                onClose={() => {
                  this.setState({ open_print: false })
                }} />
              : ''}
          </Modal.Content > <Modal.Actions>
            {this.props.action == 'edit' ?
              <Button
                color='red'
                size='small'
                icon='cancel'
                floated='left'
                disabled={this.state.is_void || this.state.status_stock == 'Y' || this.state.is_reject_to || this.state.is_void_to}
                labelPosition='left'
                id='cancel-this'
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ modal_reject: true })
                }}
                content='Reject' />
              : null}
            <Button
              id='btnPrint'
              primary
              size='small'
              icon='print'
              disabled={this.state.button_print_disabled || this.state.is_void || this.state.is_reject_to || this.state.is_void_to}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ open_print: true })
              }}
              content='พิมพ์' />
            <Button
              id='btnSave'
              size='small'
              primary
              icon='save'
              labelPosition='left'
              onClick={(e) => {
                e.preventDefault();
                this.submitSaveInvoice();
              }}
              disabled={this.state.is_void || this.state.is_reject_to || this.state.is_void_to}
              className={this.state.button_save_loading
                ? 'loading'
                : ''}
              content={this.state.button_save_title} />
            <Button
              id='btnSaveAndUpdate'
              size='small'
              icon='lightning'
              color='red'
              onClick={(e) => {
                e.preventDefault();
                if (!window.confirm('ยืนยันอนุมัติและอัพเดทสต๊อก'))
                  return;
                this.submitSaveInvoice_no_alert();
                setTimeout(() => {
                  this.submitUpdateStock();
                }, 400)
              }}
              className={this.state.button_update_stoick_loading
                ? 'loading'
                : ''}
              disabled={this.state.items_in.length != this.state.items_out.length
                || this.state.items_in.length == 0
                || this.state.button_update_stoick_disabled
                || this.state.is_void
                || this.state.is_reject_to
                || this.state.is_void_to
              }
              content='อนุมัติและอัพเดทสต๊อก' />

          </Modal.Actions> </Modal>
        <Modal open={this.state.modal_reject} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='btnClose'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_reject: false })} />
          <Modal.Header>{'แจ้งเตือน'}</Modal.Header>
          <Modal.Content className='scrolling'>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.description_reject != null}>
                  <label>หมายเหตุ*<MsgInput text={this.state.msg_error.description_reject} /></label>
                  <Input id='Input'
                    placeholder='หมายเหตุ'
                    onChange={(e) => {
                      this.setState({ description_reject: e.target.value })
                    }}
                    value={this.state.description_reject}
                    maxLength={255} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSavePass' size='small' primary
              onClick={(e) => {
                const url = Settings.baseUrl + '/invoices_transfer/' + this.props.invoice_detail.id + '/reject/';
                if (this.state.description_reject == '') {
                  this.setState({ msg_error: { description_reject: "ต้องระบุหมายเหตุ" } })
                } else {
                  var formData = {
                    description: this.state.description_reject
                  }
                  Utility.post(url, formData, (status, data) => {
                    if (data.status != true) {
                      this.setState({ description_reject: '', msg_error: {} })
                      alert(data.detail)
                    } else {
                      this.setState({ modal_reject: false, description_reject: '', msg_error: {} })
                      this.loadInvoiceItem();
                    }
                  });
                }
              }}
            >ยืนยัน</Button>
          </Modal.Actions>
        </Modal>
      </div >
    )
  }

}

export default InvoiceTransferInModal;