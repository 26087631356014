import registerServiceWorker from './registerServiceWorker';
import beforeunloadService from './beforeunloadService';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React from 'react';
import {render} from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers'
import App from './App';
// css
import 'fixed-data-table/dist/fixed-data-table.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-contexify/dist/ReactContexify.min.css' 
import './react-contextmenu.css';
import './App.css';
import './index.css';
 
const store = createStore(rootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

function Root() {
    return ( <App />);
  }

render(<Provider store={store}><Root /></Provider>, document.getElementById('root'));
beforeunloadService();
//registerServiceWorker()
