/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader, Dropdown,
  Header,TextArea,Menu,
  Popup
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e,v){
  }

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell><center>
        <a onClick={(e)=>{this.props.onClickMenu(e,{ action: 'edit',positon: rowIndex })}}><Icon  name='edit'  /></a>
        <a onClick={(e)=>{this.props.onClickMenu(e,{ action: 'delete',positon: rowIndex })}} ><Icon name='window close outline'  /></a>
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class ProductType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      description: '',
      code: '',
      modal_open: false,
      categories_id: 0,
      msg_error: {},
      is_enabled: 1,
      activeItem:'แสดง'
    }
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  submitData(e) {
    e.preventDefault()
    var formData = {
      name: this.state.name,
      code: this.state.code,
      category_id: this.state.categories_id,
      description: this.state.description,
      is_enabled: this.state.is_enabled,
    };

    const self = this;
    var msg_error = {}
    this.setState({ button_class: 'loading' ,msg_error: {}})
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/product_types/';
      Utility.post(url, formData, (status, data)=> {
        msg_error = data
        this.setState({ button_class: '' ,msg_error: {}})
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        }else { // error
          if (data.code !== undefined){
            if(data.code[0] === "Ensure this field has no more than 5 characters."){
            msg_error.code = ["*ต้องไม่มากกว่า5ตัวอักษร"]
          }
            if(data.code.length > 1){
              msg_error.code = data.code[0]
            }
            if(data.code[0] === "product type และ code มีอยู่แล้ว"){
              msg_error.code = ["*มีรหัสประเภทสินค้านี้อยู่ในระบบแล้ว"]
            }
          }
          if (data.name !== undefined){
            if(data.name[0] === "product type และ name มีอยู่แล้ว"){
              msg_error.name = ["*ชื่อประเภทสินค้านี้มีอยู่ในระบบแล้ว"]
            }
          }
          this.setState({
            msg_error: msg_error
          })
        }
      });
    } else {
      const url = Settings.baseUrl + '/product_types/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data)=> {
        msg_error = data
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        }else { // error
          if (data.code !== undefined){
            if(data.code[0] === "Ensure this field has no more than 5 characters."){
              msg_error.code = ["*ต้องไม่มากกว่า5ตัวอักษร"]
            }
            
            if(data.code.length > 1){
              msg_error.code = data.code[0]
            }
            if(data.code[0] === "product type และ code มีอยู่แล้ว"){
              msg_error.code = ["*มีรหัสประเภทสินค้านี้อยู่ในระบบแล้ว"]
            }
          }
          if (data.name !== undefined){
            if(data.name[0] === "product type และ name มีอยู่แล้ว"){
              msg_error.name = ["*ชื่อประเภทสินค้านี้มีอยู่ในระบบแล้ว"]
            }
          }
          this.setState({
            msg_error: msg_error
          })
        }
      });
    }
    this.setState({button_class:false})
  }

  resetForm() {
    this.setState({
      name: '',
      code: '',
      description: '',
      categories_id: 0,
      button_class: false,
      is_enabled: 1,
    });
  }

  componentDidMount() {
    this.setState({
      loader_active: true
    });
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }
    var categories = Utility.getFetch(Settings.baseUrl + '/categories/?is_enabled=1');
    var product_types = Utility.getFetch(Settings.baseUrl + '/product_types/?is_enabled='+status);
    Promise.all([categories, product_types]).then((values) => {

      this.categories = values[0];
      let cat = [{
        key: 0,
        value: 0,
        text: 'ทั้งหมด',
      }];
      for (let i in values[0]) {
        cat.push({
          key: values[0][i].id,
          value: values[0][i].id,
          text: values[0][i].name,
        });
      }
      this.setState({
        categories: cat,
        items: this.setFieldValue(values[1]),
        loader_active: false,
        // activeItem: 'แสดง',
      });
    });
  }

  setFieldValue(v) {

    for (let k in v) {
      let cat = Utility.getObject(this.categories, v[k].category_id);
      if (cat)
        v[k].category_name = cat.name;
      else
        v[k].category_name = 'ทั้งหมด';
    }

    return v;
  }
  componentWillMount() {
    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };
  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit') {
      // this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: row.name,
        code: row.code,
        is_enabled: row.is_enabled,
        description: row.description,
        object_id: row.id,
        msg_error:{},
        categories_id:row.category_id
      });
    } else {
      this.setState({ confirm_open: true, object_id: row.id });
    }

  }

  handleItemClick = (e, { name }) => {
    let status = 1
    if(name==='แสดง'){
      status=1 //แสดง
    }else{
      status=0 //ซ่อน
    }
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/product_types/?is_enabled='+status, (status, data) => {
      if(status)
        this.setState({
          items: this.setFieldValue(data),
          loader_active: false
        });
    });
    this.setState({ activeItem: name })
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form size='small'>
          <Form.Group>
          <Form.Field width={6}>
            <Header floated='left' as='h2'>ประเภทสินค้า</Header>
          </Form.Field>
            <Form.Field width={16}>

              <Button id='addProductType' size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  msg_error: {},
                  modal_action: 'add',
                  modal_title: 'สร้างประเภทสินค้า'
                });
              }}
                floated='right' icon='plus' labelPosition='left' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} /*dimmer='blurring'*/ size='tiny' >
        <Button
            id='closeProductType'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={()=>this.setState({ modal_open: false })}/>
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field error={this.state.msg_error.code}>
                  <label>*รหัส <MsgInput text={this.state.msg_error.code} /></label>
                  <Input id='productTypeID' onChange={(e) => this.setState({ code: e.target.value })} placeholder='รหัส' value={this.state.code} />
                  <i>*เป็นตัวเลขหรือตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ 5 ตัวอักษรเท่านั้น </i>
                </Form.Field>
                <Form.Field error={this.state.msg_error.name}>
                  <label>*ชื่อ <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='productTypename' placeholder='ชื่อ' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={100}/>
                  
                </Form.Field>

                <Form.Field>
                  <label>เฉพาะกลุ่มสินค้า</label>
                  <Dropdown id='productType' placeholder='กลุ่มสินค้า' search selection defaultValue={0} value={this.state.categories_id} options={this.state.categories} onChange={(e, data) => {
                    this.setState({ categories_id: data.value });
                  }} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.description!=null}>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea placeholder='หมายเหตุ' onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} maxLength={255}/>
                </Form.Field>
                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown id='productTypestatus' selection fluid onChange={(e,v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />
                  
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='confirmProductType' size='small' primary onClick={this.submitData}
              className={this.state.button_class ? 'loading' : ''}>บันทึก</Button>
            <Button id='cancelProductType' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={387}>
          <Column
          width={80}
          header={
            <Cell ></Cell>
          }
          cell={<OptionItemsCell onClickMenu={this.handleClick} />}
        />
          <Column
            header={<Cell>รหัส</Cell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="code" />
            }
            width={80}
          />
          <Column
            header={<Cell>ชื่อ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="name" />
            }
            width={200}
          />

          <Column
            header={<Cell>เฉพาะกลุ่มสินค้า</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="category_name" />
            }
            width={200}
          />

          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="description" />
            }
            width={200}
          />
        </Table></div>
        <Form size='small'>
              <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
              </Form.Group>
            </Form>
        <Confirm
          id='deleteproducttype'
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/product_types/' + this.state.object_id + "/";
            var self = this;
            Utility.delete(url, (status, data)=> {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              }else{
                alert(data.error)
              }
            });

          }}
        />
      </div>
    );
  }
}
export default ProductType;
