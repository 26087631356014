/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import Utility from '../../../../Utility';
import { connect } from 'react-redux'
import MsgInput from '../../../Error/MsgInput'
import {
  Form,
  Input,
  Table,
  Icon
} from 'semantic-ui-react';
import { invoice_item_remove, invoice_item_set_value } from '../../../../reducers/invoice_item_modal';
import Settings from '../../../../Settings';

class TableRowInput extends Component{

    constructor(props){
        super(props)
        this.state = {
        }

    }

    async componentDidMount(){
      let items_option = []
      const url = Settings.baseUrl + '/products/';
      const rep = await Utility.getAsync(url)

      for (let i=0; i<rep.data.length; i++) {
        let item = rep.data[i]
        let duplicate = this.props.items_all.find(k => k.product == item.id)
        if (duplicate != undefined){
          continue;
        }
        items_option.push({key: item.id,
          value: item.id,
          text: item.code + " " +item.name
        });
      }    

      this.setState({items_option:items_option})
    }

    render(){
        const {index,products,items_option,invoice} = this.props
        const {product,amount,weight,weight_real,cost,fee} = this.props.itme
        const product_item = Utility.getObject(products.items, product);

        return(<Table.Row>
            <Table.Cell>
                <a onClick={(e)=>{this.props.onDelete(index)}}><Icon  name='window close outline'  /></a>
            </Table.Cell>
            <Table.Cell valign="bottom">
              <div><MsgInput text={this.props.itme.product_error} /></div>
              <Form.Dropdown
                error={this.props.itme.product_error!=''}
                id='productimport'
                size='samll'
                search
                selection
                options={this.props.items_option}
                placeholder='รหัสสินค้า,ชื่อสินค้า'
                value={product}
                onChange={(e, data) => {
                  let item = Utility.getObject(products.items, data.value);
                  this.props.onInputChange(index,'amount','')
                  this.props.onInputChange(index,'cost',0)
                  this.props.onInputChange(index,'product',data.value)
                  this.props.onInputChange(index,'weight',item.weight)
              }}/>
              
            </Table.Cell>
            <Table.Cell valign="bottom">
              <div><MsgInput text={this.props.itme.amount_error} /></div>
              <Input
                error={this.props.itme.amount_error!=''}
                size='samll'
                id='amountimport'
                min={0}
                ref={(c)=>this.productAmountInput = c}
                placeholder='จำนวน(ชิ้น)'
                className='text-right'
                maxLength={3}
              onBlur={() => {
                  setTimeout(() => {
                      if (this.state.focus_amount) {
                          this.setState({
                              focus_amount: false,
                          });
                      }
                  }, 0);
              }}
              onFocus={() => {
                if (!this.state.focus_amount) {
                    this.setState({
                        focus_amount: true,
                    });
                }
            }}
                onChange={(e,v)=>{
                    v.value = v.value.toString().replace(/,/g,'')
                    if(v.value.length != 0){
                      v.value = parseInt(v.value)
                    }
                    if (isNaN(Number(v.value))) {
                    } else {
                      this.props.onInputChange(index,'amount',v.value)
                      const item = Utility.getObject(products.items, product);
            
                      if(item && item.type_sale==1 && (!isNaN(Number(weight)) && Number(weight)>0)){
                          const _weight_real = Number(weight*v.value)
                          var _cost = Number(_weight_real)/Number(v.value)/Number(item.category.weight) * Number(Utility.removeCommas(invoice.cost) )

                          this.props.onInputChange(index,'cost',_cost)
                      }
                    }
                }}
                value={this.state.focus_amount ? amount.toString().replace(/,/g,'') : amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}/>
                
            </Table.Cell>
            <Table.Cell valign="bottom">
              <Input
                size='samll'
              id='weightimport'
              placeholder='น.น.(กรัม)'
              value={weight}
              readOnly
              className='text-right'/>
            </Table.Cell>
            <Table.Cell valign="bottom">
                
              <Input
                size='samll'
                id='weighttotalimport'
                placeholder=''
                readOnly
                value={Utility.weightFormat((weight*amount))}
                className='text-right'/>
            </Table.Cell>
            {/* <Table.Cell valign="bottom">
              <div><MsgInput text={this.props.itme.weight_real_error} /></div>
              <Input
                error={this.props.itme.weight_real_error!=''}
                size='samll'
                id='weightrealimport'
                placeholder=''
                maxLength={20}
                onBlur={() => {
                  setTimeout(() => {
                      if (this.state.focus_weight_real) {
                          this.setState({
                            focus_weight_real: false,
                          });
                      }
                  }, 0);
              }}
              onFocus={(e) => {
                e.target.select()
                if (!this.state.focus_weight_real) {
                    this.setState({
                      focus_weight_real: true,
                    });
                }
            }}
            value={this.state.focus_weight_real ? weight_real.toString().replace(/,/g,'') :weight_real==''||weight_real==undefined?'' : Utility.weightFormat(weight_real)}
                ref={(c)=>this.productWeightInputReal = c}
                onChange={(e,v)=>{
                    v.value = v.value.toString().replace(/,/g,'')
                    if (isNaN(Number(v.value))) {
                    } else {
                      this.props.onInputChange(index,'weight_real',v.value)
                    }
                    const item = Utility.getObject(products.items, product);
                    
                    if(item && item.type_sale==1 && (!isNaN(Number(v.value)) && Number(v.value)>0)){
                        const _weight_real = Number(v.value)
                        var _cost = Number(_weight_real)/Number(amount)/Number(item.category.weight) * Number(Utility.removeCommas(invoice.cost) )

                        this.props.onInputChange(index,'cost',_cost)
                    }
                }}
                className='text-right'/>
                
            </Table.Cell> */}
            <Table.Cell valign="bottom">
            <div><MsgInput text={this.props.itme.cost_error} /></div>
            <Input
                size='samll'
                error={this.props.itme.cost_error!=''}
                id='weightrealimport'
                placeholder='ราคาต้นทุน/ชิ้น'
                maxLength={20}
                readOnly={product_item && product_item.type_sale==1}
                onBlur={() => {
                  setTimeout(() => {
                      if (this.state.focus_cost) {
                          this.setState({
                            focus_cost: false,
                          });
                      }
                  }, 0);
              }}
              onFocus={(e) => {
                e.target.select()
                if (!this.state.focus_cost) {
                    this.setState({
                      focus_cost: true,
                    });
                }
            }}
            value={product_item && product_item.type_sale==1?Utility.priceFormat(cost):this.state.focus_cost ? cost.toString().replace(/,/g,'') : cost==''||cost==undefined?'' :Utility.priceFormat(cost)}
                // onFocus={(e)=>{e.target.select()}}
                // value={product_item && product_item.type_sale==1?Utility.priceFormat(cost):
                //   cost==''||cost==undefined?'':
                //   cost.split('.')[1]==undefined?
                //   cost.split('.')[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                //   :cost.split('.')[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+cost.split('.')[1]
                // }
                ref={(c)=>this.productWeightInputReal = c}
                onChange={(e,v)=>{
                    v.value = v.value.toString().replace(/,/g,'')
                    if(isNaN(Number(v.value)))
                        return
                    this.props.onInputChange(index,'cost',v.value)
                }}
                className='text-right'/>
                 
            </Table.Cell>
            <Table.Cell valign="bottom">
              <div><MsgInput text={this.props.itme.fee_error} /></div>
              <Input
                size='samll'
                error={this.props.itme.fee_error!=''}
                id='weightrealimport'
                placeholder='ค่าแรง/ชิ้น'
                maxLength={20}
                // type='number'
                onBlur={() => {
                  setTimeout(() => {
                      if (this.state.focus_fee) {
                          this.setState({
                            focus_fee: false,
                          });
                      }
                  }, 0);
              }}
              onFocus={(e) => {
                e.target.select()
                if (!this.state.focus_fee) {
                    this.setState({
                      focus_fee: true,
                    });
                }
            }}
            value={this.state.focus_fee ? fee.toString().replace(/,/g,'') :fee==''||fee==undefined?'' : Utility.priceFormat(fee)}
                onChange={(e,v)=>{

                  v.value = v.value.toString().replace(/,/g,'')
                  if (isNaN(Number(v.value))) {
                  } else {
                    this.props.onInputChange(index,'fee',v.value)
                  }
                }}
                className='text-right'/>
                
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>)
    }

}



const mapStateToProps = (state,ownProps) =>{
    return ({
      auth: state.auth,
      branches: state.branches,
      itme: state.invoice_item_modal.items[ownProps.index],
      items_all: state.invoice_item_modal.items,
      invoice: state.invoice_item_modal.invoice,
      products: state.products,
      items_option: state.products.items_option
    })
  }
  const mapDispatchToProps = dispatch => {
    return {
        onDelete: (index)=>{
            dispatch(invoice_item_remove(index))
        },
        onInputChange: (index,key,value)=>{
            dispatch(invoice_item_set_value(index,key,value))
        }
    };
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableRowInput)