/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import {
  Form,
  Header,
  Button,
  Grid,
  Modal,
  Table,
  Dimmer,
  Loader,
  Input,
  TextArea,
  Popup,
  Pagination,
  Checkbox,
  Select,
} from "semantic-ui-react";
import { hotkeys } from "react-keyboard-shortcuts";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import Settings from "../../Settings";
import Utility from "../../Utility";
import { connect } from "react-redux";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import CloseAccount from "./CloseAccount";
import AccountItems from "./AccountItems";
import { ContextMenu, Item, ContextMenuProvider } from "react-contexify";
import PrintConfirm from "./PrintConfirm";

var dateFormat = require("dateformat");

class SelectItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      items: [],
      selected: null,
      selected_item: null,
      lineOption: [],
    };
  }

  handelLine(e, v) {
    this.setState({
      line: v.value
    })
  }

  async componentDidMount() {

    await this.loadItem(1)
    if (this.props.mode == 'repair') {
      const url = Settings.baseUrl + "/saving/" + this.props.savings.id + "/print/";
      const savings_print = await Utility.getAsync(url)
      const { savings_max_line } = savings_print.data
      let option = []
      for (let i = 1; i <= savings_max_line; i++) {
        option.push({ key: i, value: i, text: 'บรรทัดที่ ' + i })
      }
      this.setState({
        lineOption: option
      })
    }
  }

  async loadItem(page) {
    const { mode } = this.props
    this.setState({ loader_active: true });
    let mode_q = mode == 'slip' ? '&is_today=1' : ''
    let url = Settings.baseUrl + "/saving/" + this.props.savings.id + "/item/?columnKey=id&sortDi=ASC&page=" + page + (mode_q)
    const request_item = await Utility.getAsync(url)
    this.setState({ loader_active: false });

    if (request_item.status_code == 200) {
      this.setState({
        items: request_item.data.results,
        totalPages: request_item.data.next == null && request_item.data.previous == null ? 0 : Math.ceil(request_item.data.count / 10),
        counter: request_item.data.count,
      })
    }
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage });
    this.loadItem(activePage);
  }

  handleChangeSelected(e, { value, item }) {
    this.setState({
      selected: value,
      selected_item: item
    })
  }

  async submitData() {

    const { selected, selected_item } = this.state;
    const item_ignore = []
    if (this.props.mode == 'slip') { // mode Print Slip
      this.props.onPrintSlip(selected_item)

    } else { // mode Print Book
      // load all items
      const url = Settings.baseUrl + "/saving/" + this.props.savings.id + "/print/";
      const savings_print = await Utility.getAsync(url)

      const { items } = savings_print.data
      if (this.props.mode == 'repair') {
        let repair = []
        items.map((item, i) => {
          if (item.id == parseInt(selected)) {
            repair.push(item)
          }
        })
        if (this.state.line == undefined) {
          alert('กรุณาเลือกบรรทัด')
          return;
        } else {
          this.props.onPrintRepair(repair,this.state.line)
        }
      }
      items.map((item, i) => {
        if (item.id < parseInt(selected)) {
          item_ignore.push(item.id)
        }
      })

      this.setState({ loader_active: true });

      // submit print start line
      const url_submit = Settings.baseUrl + "/saving/" + this.props.savings.id + "/print/";
      const data = {
        page: 1,
        line: 0,
        items: item_ignore
      }
      Utility.postJson(url, data, (status, data, status_code) => {
        if (status_code == 200) {
          this.setState({ loader_active: false });
          this.props.onPrintBook()
        }
      })
    }

  }

  render() {
    const { role } = this.props.auth;
    const { items, page } = this.state;
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button
          id="btnClose"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header id="headerModalPOS">
          <Header as="h4">
            <span>เลือกรายการพิมพ์</span>
          </Header>
        </Modal.Header>
        <Modal.Content id="contentModalPOS">
          <Dimmer active={this.state.loader_active} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>เลือก</Table.HeaderCell>
                <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
                <Table.HeaderCell>รายการ</Table.HeaderCell>
                <Table.HeaderCell>วันที่</Table.HeaderCell>
                <Table.HeaderCell>เวลา</Table.HeaderCell>
                <Table.HeaderCell>ยอดเงิน</Table.HeaderCell>
              </Table.Row>

            </Table.Header>
            <Table.Body>
              {items.map((item, i) => {
                return (<Table.Row key={i}>
                  <Table.Cell>
                    <Checkbox radio value={item.id}
                      item={item}
                      checked={this.state.selected === item.id}
                      onChange={this.handleChangeSelected.bind(this)} />
                  </Table.Cell>
                  <Table.Cell>{((page - 1) * 10) + i + 1}</Table.Cell>
                  <Table.Cell>{item.kind_display}</Table.Cell>
                  <Table.Cell>{Utility.formatDate(item.record_date)}</Table.Cell>
                  <Table.Cell>{Utility.formatTime(item.record_date)}</Table.Cell>
                  <Table.Cell>{Utility.priceFormat(item.amount)}</Table.Cell>
                </Table.Row>)
              })}
            </Table.Body>
          </Table>
          {this.state.totalPages > 1 && (
            <Pagination
              floated="right"
              size="tiny"
              activePage={this.state.page}
              onPageChange={this.handlePaginationChange.bind(this)}
              totalPages={this.state.totalPages}
            />
          )}
          <br /><br />
        </Modal.Content>
        <Modal.Actions>
          {this.props.mode == 'repair' &&
            <Select
              placeholder='เลือกบรรทัด'
              options={this.state.lineOption}
              onChange={this.handelLine.bind(this)}
              value={this.state.line}
            />}
          <Button
            id="btnPrintVat"
            size="small"
            disabled={!this.state.selected}
            primary
            icon="print"
            labelPosition="left"
            onClick={this.submitData.bind(this)}
            content="พิมพ์"
          />
        </Modal.Actions>
      </Modal>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(SelectItem);
