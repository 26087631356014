/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class MotionStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.resetProduct = this.resetProduct.bind(this)

    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var categories = Utility.getFetch(Settings.baseReportUrl + '/categories/');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/');
        var pattern = Utility.getFetch(Settings.baseReportUrl + '/pattern/');
        Promise.all([branches, categories, product_types, pattern]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.categories = values[1];
            let cat = [];
            for (let i in this.categories) {
                cat.push({
                    key: this.categories[i].id,
                    value: this.categories[i].id,
                    text: this.categories[i].name,
                });
            }

            this.product_types = values[2];
            let type = [];
            for (let i in this.product_types) {
                type.push({
                    key: this.product_types[i].id,
                    value: this.product_types[i].id,
                    text: this.product_types[i].name,
                });
            }

            this.patterns = values[3];
            let pattern = [];
            for (let i in this.patterns) {
                pattern.push({
                    key: this.patterns[i].id,
                    value: this.patterns[i].id,
                    text: this.patterns[i].name,
                });
            }

            let products_code = [];

            this.setState({
                loader_active: false,
                branches: branches,
                categories: cat,
                product_types: type,
                pattern: pattern,
                products_code: products_code,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
        let search = this.state.search
        if (localStorage.getItem("product_kind") != null)
            search['kind'] = JSON.parse(localStorage.getItem("product_kind"))
        this.setState({
            search: search
        })
    }

    resetCategory(category_id) {
        let type = [];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_types: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_types: type });
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            products_code: []
        })
        localStorage.setItem('product_kind','[]')

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)


        if (search.category && search.category.length == 0) {
            delete search["category"]
        }
        if (search.kind && search.kind.length == 0) {
            delete search["kind"]
        }
        if (search.pattern && search.pattern.length == 0) {
            delete search["pattern"]
        }
        if (search.type_sale && search.type_sale.length == 0) {
            delete search["type_sale"]
        }

        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/stock_new_product_lot/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.stock_lot, d.invoice_item, d.product, d.kind, d.category)
                })
            }
        })
    }

    resetProduct(text) {
        let search = this.state.search
        if (text != '') {
            this.setState({
                isLoading: true
            });
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                let products = [];
                let s = {}
                if (search.category != undefined)
                    s.category_id = search.category
                if (search.type_sale != undefined)
                    s.type_sale = search.type_sale
                if (search.kind != undefined)
                    s.product_types_id = search.kind
                if (search.pattern != undefined)
                    s.pattern_id = search.pattern
                s.text = text
                s.limited = 10
                var p = Utility.getFetch(Settings.baseReportUrl + '/products_no_join/?' + Utility.jsonToQueryString(s));
                Promise.all([p]).then((values) => {
                    let p = values[0].product
                    for (let i in p) {
                        products.push({
                            key: p[i].id,
                            value: p[i].id,
                            text: p[i].code,
                        });
                    }
                    this.setState({
                        products_code: products,
                        isLoading: false,
                    })
                })
            }, 1600)
        } else {
            this.setState({
                products_code: [],
            })
        }
    }

    setFieldValue(_items, invoice_items, products, kinds, categories) {
        let items = []

        for (let i in _items) {
            let item = _items[i]

            const invoice_item = invoice_items.find(k => k.id === item.invoice_item)
            const product = products.find(k => k.id === invoice_item.product)
            const kind = kinds.find(k => k.id === product.kind)
            const category = categories.find(k => k.id === product.category)

            item['product_code'] = product.code
            item['product_name'] = product.name
            item['product_kind'] = kind.name
            item['product_category'] = category.name
            item['date_title'] = Utility.formatDate(item.date)
            item['ref_number'] = item.object_number
            item['ref'] = item.object_number
            item['list_detail'] = item.kind_display

            if (item.kind == 'ยกมา' || item.kind == 'ยกไป') {
                item['ref_number'] = ''
                item['amount'] = item.after
                item['description'] = ''
            }

            if (item.kind == 'I' || item.kind == 'IO' || item.kind == 'TI' || item.kind == 'ยกมา' || item.kind == 'VT' || item.kind == 'V') {
                item['amount_in'] = Utility.numberFormat(item.amount)
                item['weight_per_piece'] = Utility.weightFormat(invoice_item.weight)
                item['weight_total'] = Utility.weightFormat(item.amount * invoice_item.weight)
                item['cost_per_piece'] = Utility.priceFormat(invoice_item.cost)
                item['fee_per_piece'] = Utility.priceFormat(invoice_item.fee)
                item['cost_total_per_piece'] = Utility.priceFormat(parseFloat(invoice_item.cost) + parseFloat(invoice_item.fee))
                item['cost_total'] = Utility.priceFormat((parseFloat(invoice_item.cost) + parseFloat(invoice_item.fee)) * item.amount)

                item['amount_out'] = Utility.numberFormat(0)
                item['sell_per_piece'] = Utility.priceFormat(0)
                item['sell_fee_per_piece'] = Utility.priceFormat(0)
                item['sell_total_per_piece'] = Utility.priceFormat(0)
                item['sell_total'] = Utility.priceFormat(0)
            } else if (item.kind == 'O' || item.kind == 'TO' || item.kind == 'DF' || item.kind == 'XC' || item.kind == 'CU') {
                item['amount_in'] = Utility.numberFormat(0)
                item['weight_per_piece'] = Utility.weightFormat(0)
                item['weight_total'] = Utility.weightFormat(0)
                item['cost_per_piece'] = Utility.priceFormat(0)
                item['fee_per_piece'] = Utility.priceFormat(0)
                item['cost_total_per_piece'] = Utility.priceFormat(0)
                item['cost_total'] = Utility.priceFormat(0)

                item['amount_out'] = Utility.numberFormat(item.amount)
                item['sell_per_piece'] = Utility.priceFormat(parseFloat(item.sell) - parseFloat(item.fee))
                item['sell_fee_per_piece'] = Utility.priceFormat(item.fee)
                item['sell_total_per_piece'] = Utility.priceFormat(parseFloat(item.sell))
                item['sell_total'] = Utility.priceFormat((parseFloat(item.sell)) * parseFloat(item.amount))
            } else if (item.kind == 'ยกไป') {
                item['amount_in'] = ''
                item['weight_per_piece'] = ''
                item['weight_total'] = ''
                item['cost_per_piece'] = ''
                item['fee_per_piece'] = ''
                item['cost_total_per_piece'] = ''
                item['cost_total'] = ''

                item['amount_out'] = ''
                item['sell_per_piece'] = ''
                item['sell_fee_per_piece'] = ''
                item['sell_total_per_piece'] = ''
                item['sell_total'] = ''
            }

            item['amount_remain'] = Utility.numberFormat(item.after)
            item['cost_per_unit'] = Utility.priceFormat(invoice_item.cost)
            item['fee_per_unit'] = Utility.priceFormat(invoice_item.fee)
            item['value_remaining_per_price'] = Utility.
                priceFormat(parseFloat(invoice_item.cost) + parseFloat(invoice_item.fee))
            item['value_remaining'] = Utility.priceFormat((parseFloat(invoice_item.cost) + parseFloat(invoice_item.fee)) * item.after)

            items.push(JSON.parse(JSON.stringify(item)))
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        if (v.name == 'kind')
        localStorage.setItem('product_kind', JSON.stringify(search[v.name]))
        this.setState({
            search: search
        })
        if (v.name == 'category') {
            this.resetCategory(v.value);
        }
    }
    render() {


        let title = 'รายงานสต็อกทองใหม่คงเหลือ (แบบเคลื่อนไหว)';
        let filename = 'stock_motion';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const bgWhite = {
            'background-color': '#fff'
        }

        const lineTop = {
            'border-top': '1px solid black'
        }

        const descriptionWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }

        const bgGray = {
            'background-color': '#eee'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }
        let list = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            let code = item.product_code
            let name = item.product_name
            let kind = item.product_kind
            let category = item.product_category
            if (list[code] == null) {
                list[code] = {
                    items: [],
                    code: code,
                    name: name,
                    kind: kind,
                    category: category
                }
            }
            if (item.kind == 'ยกมา' || item.kind == 'ยกไป') {
                const o2 = list[code].items.find(k => k.kind === item.kind)
                if (o2 === undefined) {
                    list[code].items.push(JSON.parse(JSON.stringify(item)))
                } else {
                    item['date_title'] = ''
                    item['ref_number'] = ''
                    item['list_detail'] = ''
                    list[code].items.push(JSON.parse(JSON.stringify(item)))
                }
            }
            else {
                const o = list[code].items.find(k => k.ref === item.ref && k.kind === item.kind && k.invoice_item === item.invoice_item)
                if (o === undefined) {
                    if (!(item.amount_remain == 0 && item.amount_in == 0 && item.amount_out == 0)) {
                        const o2 = list[code].items.find(k => k.date_title === item.date_title && k.ref_number === item.ref_number && k.kind == item.kind)

                        if (o2 === undefined) {
                            list[code].items.push(JSON.parse(JSON.stringify(item)))
                        } else {
                            item['date_title'] = ''
                            item['ref_number'] = ''
                            item['list_detail'] = ''
                            list[code].items.push(JSON.parse(JSON.stringify(item)))
                        }
                    }
                }
                else {
                    const index = list[code].items.indexOf(o)
                    list[code].items[index].amount_in = Utility.numberFormat(parseInt(Utility.removeCommas(list[code].items[index].amount_in)) + parseInt(Utility.removeCommas(item.amount_in)))
                    list[code].items[index].cost_total = Utility.priceFormat(parseFloat(Utility.removeCommas(list[code].items[index].cost_total)) + parseFloat(Utility.removeCommas(item.cost_total)))

                    list[code].items[index].amount_out = Utility.numberFormat(parseInt(Utility.removeCommas(list[code].items[index].amount_out)) + parseInt(Utility.removeCommas(item.amount_out)))
                    list[code].items[index].sell_total = Utility.priceFormat(parseFloat(Utility.removeCommas(list[code].items[index].sell_total)) + parseFloat(Utility.removeCommas(item.sell_total)))

                    list[code].items[index].amount_remain = item.amount_remain
                    list[code].items[index].value_remaining = item.value_remaining
                    if (item.amount > 0) {
                        list[code].items[index].sell_fee_per_piece = Utility.priceFormat((parseFloat(Utility.removeCommas(item.sell_fee_per_piece)) + parseFloat(Utility.removeCommas(list[code].items[index].sell_fee_per_piece))))
                        list[code].items[index].sell_per_piece = Utility.priceFormat((parseFloat(Utility.removeCommas(item.sell_per_piece)) + parseFloat(Utility.removeCommas(list[code].items[index].sell_per_piece))))
                        list[code].items[index].sell_total_per_piece = Utility.priceFormat((parseFloat(Utility.removeCommas(item.sell_total_per_piece)) + parseFloat(Utility.removeCommas(list[code].items[index].sell_total_per_piece))))
                    }

                }
            }

        }

        let data_list = []
        for (let i in list) {
            for (let j in list[i].items) {
                let avg_divider = parseInt(Utility.removeCommas(list[i].items[j].amount_in)) + parseInt(Utility.removeCommas(list[i].items[j].amount_out))
                list[i].items[j].sell_fee_per_piece = Utility.priceFormat(parseFloat(Utility.removeCommas(list[i].items[j].sell_fee_per_piece)) / avg_divider)
                list[i].items[j].sell_per_piece = Utility.priceFormat(parseFloat(Utility.removeCommas(list[i].items[j].sell_per_piece)) / avg_divider)
                list[i].items[j].sell_total_per_piece = Utility.priceFormat(parseFloat(Utility.removeCommas(list[i].items[j].sell_total_per_piece)) / avg_divider)
            }
            data_list.push(list[i])
        }

        function sortByProperty(property) {
            return function (a, b) {
                if (a[property] > b[property])
                    return 1;
                else if (a[property] < b[property])
                    return -1;

                return 0;
            }
        }

        data_list.sort(sortByProperty("code"));

        //  items.sort(sortByProperty(pId));

        data_list.sort()

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสินค้าคงเหลือ(แบบเคลื่อนไหว)</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>กลุ่มสินค้า</label>
                                <Dropdown multiple id='searchproductgroup' placeholder='- ทั้งหมด -' search selection defaultValue={this.state.search.category} value={this.state.search.category} options={this.state.categories} onChange={this.handlerInput} name='category' />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทสินค้า <small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown multiple id='searchproducttype' placeholder='- ทั้งหมด -' search selection defaultValue={this.state.search.kind} value={this.state.search.kind}
                                    options={
                                        this.state.product_types
                                    }
                                    onChange={this.handlerInput} name='kind' />
                            </Form.Field>
                            <Form.Field>
                                <label>ลายทอง</label>
                                <Dropdown multiple id='searchpattern' placeholder='- ทั้งหมด -' search selection defaultValue={this.state.search.pattern} value={this.state.search.pattern} options={this.state.pattern} onChange={this.handlerInput} name='pattern' />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทงานขาย</label>
                                <Dropdown multiple id='searchproductsell' placeholder='- ทั้งหมด -' search selection defaultValue={this.state.search.type_sale} value={this.state.search.type_sale} options={Settings.type_sale} onChange={this.handlerInput} name='type_sale' />
                            </Form.Field>
                            <Form.Field>
                                <label>รหัสสินค้า</label>
                                <Dropdown
                                    id='searchproductid'
                                    placeholder='รหัสสินค้า'
                                    search selection
                                    defaultValue={this.state.search.product}
                                    value={this.state.search.product}
                                    options={this.state.products_code}
                                    onChange={this.handlerInput}
                                    name='product'
                                    onSearchChange={(e, v) => {
                                        this.resetProduct(v.searchQuery)
                                    }}
                                    loading={this.state.isLoading}
                                    isFetching={this.state.isLoading}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='extra-wide'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='21'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='21'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='21'><center id='date'>ช่วงเวลา: ตั้งแต่ {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3' style={textCenter}></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='7' style={textCenter}>ทองเข้า</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='5' style={textCenter}>ทองออก</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='5' style={textCenter}>คงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='1' style={textCenter}></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่อ้างอิง</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการ</Table.HeaderCell>

                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.(g)/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุน/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่าแรง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนทั้งสิ้น</Table.HeaderCell>

                                            <Table.HeaderCell style={textCenter}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ราคาขายทอง/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่ากำเหน็จ/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ราคาขายรวม/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ขายทั้งสิ้น</Table.HeaderCell>

                                            <Table.HeaderCell style={textCenter}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ราคา/หน่วย</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่าแรง/หน่วย</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>มูลค่าคงเหลือ/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>มูลค่าคงเหลือ</Table.HeaderCell>

                                            <Table.HeaderCell style={textCenter}>หมายเหตุ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {data_list.map((row, i) => {
                                        let first = false
                                        return (<Table.Body>
                                            <Table.Row >
                                                <Table.HeaderCell style={textCenter}>{row.code}</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>{row.kind}</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>{row.category}</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>{row.name}</Table.HeaderCell>
                                            </Table.Row>
                                            {row.items.map((row, i) => {
                                                let tableStyleRight = textRight
                                                let tableStyleLeft = textLeft
                                                let tableStyleCenter = textCenter
                                                let tableStyleDescription = textLeft
                                                if (row.date_title != '') {
                                                    first = !first
                                                    tableStyleRight = (lineTop, textRight)
                                                    tableStyleLeft = (lineTop, textLeft)
                                                    tableStyleCenter = (lineTop, textCenter)
                                                    tableStyleDescription = (lineTop, textLeft, descriptionWidth)
                                                } else {
                                                    row.description = ''
                                                }
                                                return (
                                                    <Table.Row id={row.product_code} key={i} style={first ? (bgGray) : (bgWhite)}>
                                                        <Table.Cell style={tableStyleCenter}>{row.date_title}</Table.Cell>
                                                        <Table.Cell style={tableStyleLeft}>{row.ref_number}</Table.Cell>
                                                        <Table.Cell style={tableStyleLeft}>{row.list_detail}</Table.Cell>

                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.amount_in}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.weight_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.weight_total}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.cost_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.fee_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.cost_total_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.cost_total}</Table.Cell>

                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.amount_out}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.sell_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.sell_fee_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.sell_total_per_piece}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.amount_in == 0 && row.amount_out == 0 ? '' : row.sell_total}</Table.Cell>

                                                        <Table.Cell style={tableStyleRight}>{row.amount_remain}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.cost_per_unit}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.fee_per_unit}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.value_remaining_per_price}</Table.Cell>
                                                        <Table.Cell style={tableStyleRight}>{row.value_remaining}</Table.Cell>

                                                        <Table.Cell style={tableStyleDescription}>{row.kind == 'ยกมา' || row.kind == 'ยกไป' ? '' : row.description}</Table.Cell>

                                                    </Table.Row>)
                                            })}
                                            <Table.Row >
                                                <Table.Cell colSpan='21'></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    {/* <Table.Row>
                                    <Table.Cell style={textLeft}><b><div style={textU}>รวมทั้งหมด</div></b></Table.Cell>
                                    <Table.Cell colSpan='3' style={textRight}><div style={textU}><b id='total_amount'>{total_amount}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU} id='total_weight'><b>{Utility.weightFormat(total_weight)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                    <Table.Cell colSpan='4' style={textRight}><div style={textU}><b id='total_cost'>{Utility.priceFormat(total_cost)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textLeft}><b><div style={textU}>ต้นทุนทองถัวเฉลี่ย</div></b></Table.Cell>
                                    <Table.Cell colSpan='10' style={textRight}><div style={textU}><b id='total_cost'>{Utility.priceFormat(parseFloat(total_cost)/parseFloat(total_weight))}</b></div></Table.Cell>
                                </Table.Row> */}
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(MotionStock)
