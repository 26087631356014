/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { connect } from 'react-redux'
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import OptionItemsCell from '../../OptionItemsCell'
import { Table, Column, Cell } from 'fixed-data-table';
import BillItemModal from './BillItemModal'
import BillPrintPreview from './BillPrintPreview'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Dropdown,
  Modal,
  Grid,
  Input,
  Popup,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';


class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}>
        {/* { (field === 'amount' || field === 'weight' || field === 'weight_total' || field === 'weight_real' || field === 'cost' || field === 'price_tag') ? 
            <div className={this.props.textAlign}>{data[rowIndex][field].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
          :  */}
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
        {/* } */}
      </Cell>
    );
  }
}

class BillModal extends Component {

  constructor(props) {
    super(props);

    this.modal_status = false;
    props.branches.shift();
    this.state = {
      branches: props.branches,
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      branch: '',
      status_stock: 'N',
      modal_open: this.props.open,
      product_action_title: 'เพิ่มสินค้า',
      product_action: 'add',
      invoice_item: [],
      button_print_disabled: true,
      button_update_stoick_disabled: true,
      qrcode_input_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการนำออกทองใหม่',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      description: '',
      is_void: false,
      msg_error: {},
      code: '',
      focus: '',
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];

    this.onDeleteProduct = this
      .onDeleteProduct
      .bind(this);
  }

  submitSaveInvoice() {
    let { auth } = this.props
    let msg_error = {}
    if (this.state.branch == '') {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }
    if (this.state.description == '') {
      msg_error['description'] = ['กรุณาระบุหมายเหตุ']
    }
    if (this.state.description.length > 255) {
      msg_error['description'] = ['ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร']
    }

    this.setState({ msg_error: msg_error })
    if (Object.keys(msg_error).length > 0) {

      return;
    }
    if (auth.role != 'S' && auth.role != 'A') {
      alert('คุณไม่มีสิทธิ์สร้าง/บันทึกใบนำออกทองใหม่')
      return
    }

    var formData = {
      branch: this.state.branch,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      kind: 'CU',
      description: this.state.description,
    };

    this.setState({ button_save_loading: true })
    if (this.props.action == 'add') {
      formData.status_stock = 'N'

      const url = Settings.baseUrl + '/invoices_export/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
        this
          .props
          .onAddInvoice(data);
        this.loadInvoiceItem();
        alert('บันทึกข้อมูลสำเร็จ!')

        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        ref_number: this.state.ref_number,
        invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.description,
      };

      const url = Settings.baseUrl + '/invoices_export/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem();
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  submitSaveInvoice_no_alert() {
    let { auth } = this.props
    let msg_error = {}
    if (this.state.branch == '') {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }
    if (this.state.description == '') {
      msg_error['description'] = ['กรุณาระบุหมายเหตุ']
    }
    if (this.state.description.length > 255) {
      msg_error['description'] = ['ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร']
    }

    this.setState({ msg_error: msg_error })
    if (Object.keys(msg_error).length > 0) {

      return;
    }
    if (auth.role != 'S' && auth.role != 'A') {
      alert('คุณไม่มีสิทธิ์สร้าง/บันทึกใบนำออกทองใหม่')
      return
    }

    var formData = {
      branch: this.state.branch,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      kind: 'CU',
      description: this.state.description,
    };

    this.setState({ button_save_loading: true })
    if (this.props.action == 'add') {
      formData.status_stock = 'N'

      const url = Settings.baseUrl + '/invoices_export/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
        this
          .props
          .onAddInvoice(data);
        this.loadInvoiceItem();

        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        ref_number: this.state.ref_number,
        invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.description,
      };

      const url = Settings.baseUrl + '/invoices_export/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem();
        }
      });
    }
  }

  supmitProduct() {

    let formData = {
      invoice: this.props.invoice_detail.id,
      product: this.state.product_id,
      status_stock: 'N',
      amount: this.state.product_amount,
      weight: this.state.product_weight,
      weight_total: this.state.product_weight_total,
      weight_real: this.state.product_weight_real
    };

    let msg_error = {}
    if (this.state.product_id == null || this.state.product_id == '') {
      msg_error['product'] = ['กรุณาเลือกสินค้า']
    }
    if (this.state.product_amount == null || this.state.product_amount == '') {
      msg_error['amount'] = ['กรุณาระบุจำนวน']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error })
      return;
    }

    this.setState({ button_product_loading: true })
    if (this.state.product_action == 'add') {
      const url = Settings.baseUrl + '/invoice_item/';
      Utility.post(url, formData, (status, data) => {
        if (status) {
          this.setState({ button_product_loading: false, modal_product: false })
          this.loadInvoiceItem();
        } else { // error
          if (data.length !== 0)
            this.setState({ msg_error: data })
        }
      });
    } else {
      const url = Settings.baseUrl + '/invoice_item/' + this.state.item_id + '/';
      Utility.put(url, formData, (status, data) => {
        if (status) {
          this.setState({ button_product_loading: false, modal_product: false })
          this.loadInvoiceItem();
        } else { // error
          if (data.length !== 0)
            this.setState({ msg_error: data })
        }
      });
    }

  }

  loadInvoiceItem(reload) {
    const { auth } = this.props
    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_export/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/invoices_export/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise
      .all([invoices, items])
      .then((values) => {
        // set invoice detail
        let item = values[0];
        //this.setState(item)
        let description = this.state.description
        let status_bill = this.state.status_bill
        this.setState({
          number: item.number,
          description: item.description,
          is_void: item.is_void,
          branch: item.branch,
          status_bill: item.status_bill,
          invoice_date: moment(item.invoice_date),
          invoice_disabled: true,
          button_save_title: 'บันทึก',
          invoice_item: this.setFieldValue(values[1]),
          button_print_disabled: false,
          qrcode_input_disabled: item.status_stock == 'Y' || item.is_void,
          status_stock: item.status_stock,
          button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y' || item.is_void || auth.role != 'S',
          invoice_add_product_disabled: item.status_stock == 'Y',
          invoice_title: <span >{< span > แก้ไขรายการนำออกทองใหม่</span>} </span>,
          loader: false
        });
        if(reload==true){
          this.setState({
            description: description,
            status_bill: status_bill,
          })
        }
      });
  }

  setFieldValue(item) {

    let invoice_weight_total = 0;
    let invoice_product_total = 0;
    // let invoice_profit_total = 0;
    let items = []
    for (let i = 0; i < item.length; i++) {

      let status_stock_title = Settings.status_stock[item[i].invoice_export.status_stock]
      let product_code = item[i].new_product.stock_product.product.code
      let product_name = item[i].new_product.stock_product.product.name
      let category_title = item[i].new_product.stock_product.product.category.name
      let kind_title = item[i].new_product.stock_product.product.kind.name
      let weight = item[i].new_product.product.weight
      let type_sale_title
      let fee = item[i].new_product.invoice_item.fee
      let cost = Utility.priceFormat(parseFloat(item[i].new_product.invoice_item.cost));
      let price_tag
      let code = item[i].new_product.qrcode
      let status_stock = item[i].invoice_export.status_stock
      if (item[i].new_product.stock_product.product.type_sale == 2) {
        type_sale_title = 'งานชิ้น';
        price_tag = Utility.priceFormat(parseFloat(item[i].new_product.stock_product.product.price_tag));
      } else {
        type_sale_title = 'งานชั่ง';
        price_tag = Utility.priceFormat(0.00);
      }
      const lot_id = item[i].new_product.invoice_item.id
      const o = items.find(k => k.product_code === product_code && k.new_product.invoice_item.id === lot_id)
      if (o === undefined) {
        items.push({
          new_product: item[i].new_product,
          status_stock_title: status_stock_title,
          product_code: product_code,
          product_name: product_name,
          category_title: category_title,
          kind_title: kind_title,
          type_sale_title: type_sale_title,
          cost: cost,
          price_tag: price_tag,
          weight: weight,
          amount: 1,
          fee: fee,
          weight_total: weight,
          qrcode: [code],
          ids: [item[i].id],
          status_stock: status_stock,
        })
      }
      else {
        const index = items.indexOf(o)
        items[index].weight_total = parseFloat(weight) + parseFloat(items[index].weight_total)
        items[index].amount += 1
        items[index].qrcode.push(code)
        items[index].ids.push(item[i].id)
      }
      invoice_weight_total += parseFloat(weight);
      invoice_product_total += parseInt(1);
    }
    let invoice_itme_total = items.length;
    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      invoice_product_total: Utility.numberFormat(invoice_product_total),
      invoice_itme_total: Utility.numberFormat(invoice_itme_total),
      // invoice_profit_total: Utility.priceFormat(invoice_profit_total)
    });
    for (let j = 0; j < items.length; j++) {
      items[j]['weight'] = Utility.weightFormat(items[j].weight)
      items[j]['amount'] = Utility.numberFormat(items[j].amount.toString())
      items[j]['weight_total'] = Utility.weightFormat(items[j].weight_total)
      items[j]['fee'] = Utility.priceFormat(parseFloat(items[j].fee.toString().replace(/,/g,'')))
      items[j]['cost'] = Utility.priceFormat(parseFloat(items[j].cost.toString().replace(/,/g,'')))
      items[j]['price_tag'] = Utility.priceFormat(parseFloat(items[j].price_tag.toString().replace(/,/g,'')))
    }

    return items;
  }

  submitQRcode() {
    let { auth } = this.props
    let data = []
    data['code'] = this.state.code.trim()
    data['stock_type'] = 'new'
    data['branch'] = this.state.branch
    if (auth.role != 'S' && auth.role != 'A') {
      alert('คุณไม่มีสิทธิ์ค้นหา Qrcode')
      return
    }

    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + '/qrcode?' + qrstring);
    Promise.all([qrcode]).then((values) => {
      if (values[0].detail === undefined) {
        const o = this.state.invoice_item.find(k => k.product_code === values[0].product.code)
        if (o === undefined || this.state.invoice_item[this.state.invoice_item.indexOf(o)].qrcode.indexOf(data.code) == -1) {
          var formData = {
            new_product: values[0].id,
            invoice_export: this.props.invoice_detail.id,
            status_stock: 'N',
          }
          const url = Settings.baseUrl + '/invoice_export_item/';
          Utility.post(url, formData, (status, data) => {
            this.loadInvoiceItem(true);
            // this.setState({button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก'})
          });
        }
        else {
          alert('ได้ทำการเลือกสินค้านี้แล้ว')
        }
      } else {
        alert(values[0].detail)
      }

    })
    this.setState({ code: '' })
  }

  onDeleteProduct(e, d) {
    let { auth } = this.props
    if (auth.role != 'S' && auth.role != 'A') {
      alert('คุณไม่มีสิทธิ์ลบรายการสินค้า')
      return
    }
    if (!window.confirm('ยืนยันลบ'))
      return
    if (this.state.is_void) {
      alert('ไม่สามารถลบรายการนี้ได้');
      return
    }
    let data = this.state.invoice_item[d.positon];

    if (data.status_stock == 'N') {

      for (const i in data.ids) {
        const url = Settings.baseUrl + '/invoice_export_item/' + data.ids[i] + '/';
        Utility.delete(url, (status, data) => {
          this.setState({ button_product_loading: false })
          this.loadInvoiceItem();
        });
      }
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  submitUpdateStock() {
    let { auth } = this.props
    if (auth.role != 'S') {
      alert('คุณไม่มีสิทธิ์บันทึกและอัพเดทสต๊อก')
      return
    }
    if (this.state.description == '') {
      let msg_error = {}
      msg_error['description'] = ['กรุณาระบุหมายเหตุ']
      this.setState({ msg_error: msg_error })
      return
    }
    this.setState({ button_update_stoick_loading: true })
    const url = Settings.baseUrl + '/invoices_export/' + this.props.invoice_detail.id + '/stock_update/';
    Utility.get(url, (status, data) => {

      if (data.status) {
        this.props.onAddInvoice();
        this.loadInvoiceItem();
        alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
        this.setState({ focus: 'update' })
      } else {
        alert(data.error)
      }

      this.setState({ button_update_stoick_loading: false })
    });
  }

  componentDidMount() {
    this.setState({ loader_active: true, btn_stock: true });
    this.setState({ focus: this.props.action })

    if (this.props.action == 'edit')
      this.loadInvoiceItem();
    setTimeout(() => {
      let table_width = document.getElementById('table_width2')
      this.setState({ table_width: table_width.clientWidth });
      window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()
      }, 400)
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width2')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    let items = this.state.invoice_item;
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/ >
        < Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.invoice_title}
              {this.state.number && <span id='textBillID'>[บิลเลขที่ {this.state.number}]</span>}
              <Header.Subheader>
                <span id='statusBill'><Icon
                  name={this.state.status_stock == 'N'
                    ? 'warning circle'
                    : 'check circle'}
                  color={this.state.status_stock == 'N'
                    ? 'yellow'
                    : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header> < Modal.Content > <div >
          <div className='relative'>
            {this.state.loader && <Dimmer active={this.state.loader} inverted>
              <Loader inverted content='Loading' />
            </Dimmer>}
            <div>
              {this.state.modal_product && <BillItemModal onSuccess={() => {
                this.setState({ modal_product: false })
                this.loadInvoiceItem()
              }} invoice={this.state.invoice_item} onClose={() => this.setState({ modal_product: false })} invoice={this.props.invoice_detail} />}
              <Grid >
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form className='fluid' size='small'>
                      <Form.Group >
                        <Form.Field width={16} error={this.state.msg_error.branch != null}>
                          <label>*สาขา
                      <MsgInput text={this.state.msg_error.branch} /></label>
                          <Dropdown
                            id='dropDownBranchForAdd'
                            placeholder='สาขา'
                            search
                            selection
                            width={16}
                            options={this.props.branches}
                            defaultValue={this.state.branch}
                            value={this.state.branch}
                            onChange={(e, data) => {
                              let branches = []
                              for (let i in this.props.branches) {
                                if (this.props.branches[i].key != data.value) {
                                  branches.push(this.props.branches[i])
                                }
                              }
                              this.setState({ branch: data.value, branches: branches });
                            }}
                            disabled={this.state.invoice_disabled} />
                        </Form.Field>

                      </Form.Group >

                      <Form.Group >
                        <Form.Field width={16}>
                          <label>วันที่</label>
                          <DatePicker
                            id='dateStartForAdd'
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.invoice_date}
                            readOnly
                            onChange={(date) => {
                              if (date === null || date === '') {
                                this.setState({ invoice_date: moment() });
                              } else {
                                this.setState({ invoice_date: date });
                              }
                            }}
                            disabled={this.state.invoice_disabled} />
                        </Form.Field>
                      </Form.Group >
                      <Form.Group inline>
                        <Form.Checkbox
                          id='clearBill'
                          label='เคลียร์บิล'
                          width={16}
                          checked={this.state.status_bill == 'Y'}
                          disabled={this.state.is_void}
                          onChange={(e, v) => {
                            this.setState({
                              status_bill: v.checked
                                ? 'Y'
                                : 'N'
                            });
                          }} />
                      </Form.Group>

                      <Form.Group >
                        <Form.TextArea
                          error={this.state.msg_error.description != null}
                          label={this.state.msg_error.description != null ? <label>*หมายเหตุ<MsgInput text={this.state.msg_error.description} /></label> : '*หมายเหตุ'}
                          disabled={this.state.is_void}
                          placeholder='หมายเหตุ'
                          width={16}
                          value={this.state.description}
                          onChange={(e, v) => {
                            this.setState({ description: v.value })
                          }} />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Form size='small'>
                      <Form.Group>
                        <Form.Field width={10}>
                          <Header floated='left' as='h3'>รายการสินค้า</Header>
                        </Form.Field>
                        <Form.Field width={6} disabled={this.state.qrcode_input_disabled}>
                          {/* <Button
                        id='btnAdd'
                        disabled={this.state.invoice_add_product_disabled}
                        size='small'
                        content='เพิ่มรายการสินค้า'
                        onClick={() =>this.setState({modal_product: true})}
                        floated='right'
                        icon='plus'
                        labelPosition='left'
                        type='button'
                        color='green'/> */}
                          <label>QR Code</label>
                          {this.state.focus === 'edit' && this.state.status_stock === 'N' ? <Input
                            className='text-right'
                            placeholder='Qr code'
                            autoFocus={1}
                            ref={(c) => {
                              this.qrcodee = c
                            }}
                            onKeyPress={(e) => {
                              if (e.key == 'Enter') {
                                this.submitQRcode()
                              }
                            }}
                            onChange={(e) => this.setState({ code: e.target.value })}
                            value={this.state.code}
                          /> :
                            <Input
                              placeholder='Qr code'
                              className='text-right'
                              disabled={1}
                            />
                          }
                        </Form.Field>
                      </Form.Group>
                    </Form>
                    <div id="table_width2">
                      <Table
                        disabled={this.state.is_void}
                        rowsCount={items.length}
                        rowHeight={35}
                        headerHeight={35}
                        width={this.state.table_width}
                        height={400}>
                        <Column
                          width={50}
                          header={< Cell > </Cell>}
                          cell={< OptionItemsCell edit={false} onClickMenu={
                            (e, data) => {
                              if (data.action == 'delete')
                                this.onDeleteProduct(e, data)
                            }
                          } />} />
                        <Column
                          header={< Cell > สถานะปรับปรุงสต็อก </Cell>}
                          cell={< ItemsCell position='top left' width={150} data={
                            items
                          }
                            field="status_stock_title" />}
                          width={150} />
                        <Column
                          header={< Cell > รหัสสินค้า </Cell>}
                          cell={< ItemsCell position='top left' width={120} data={
                            items
                          }
                            field="product_code" />}
                          width={120} />
                        <Column
                          header={< Cell > กลุ่มสินค้า </Cell>}
                          cell={< ItemsCell position='top left' width={120} data={
                            items
                          }
                            field="category_title" />}
                          width={120} />
                        <Column
                          header={< Cell > ประเภท </Cell>}
                          cell={< ItemsCell position='top left' width={120} data={
                            items
                          }
                            field="kind_title" />}
                          width={120} />
                        <Column
                          header={< Cell > ชื่อสินค้า </Cell>}
                          cell={< ItemsCell position='top left' width={200} id='ProductName' data={
                            items
                          }
                            field="product_name" />}
                          width={200} />
                        <Column
                          header={< Cell className='text-right' > น.น./ชิ้น</Cell >}
                          cell={< ItemsCell position='top right' width={100} data={
                            items
                          }
                            field="weight" textAlign='text-right' />}
                          width={100} />
                        <Column
                          header={< Cell className='text-right' > จำนวน </Cell>}
                          cell={< ItemsCell position='top right' width={100} data={
                            items
                          }
                            field="amount" textAlign='text-right' />}
                          width={100} />
                        <Column
                          header={< Cell className='text-right' > น.น.รวม </Cell>}
                          cell={< ItemsCell position='top right' width={100} data={
                            items
                          }
                            field="weight_total" textAlign='text-right' />}
                          width={100} />
                        {/* <Column
                      header={< Cell className = 'text-right' > น.น.ชั่งจริง </Cell>}
                      cell={< ItemsCell position='top right' width={100} data = {
                      items
                    }
                    field = "weight_real" textAlign = 'text-right' />}
                      width={100}/> */}
                        <Column
                          header={< Cell className='text-right' > ค่าแรงชิ้นละ </Cell>}
                          cell={< ItemsCell position='top right' width={100} data={
                            items
                          }
                            field="fee" textAlign='text-right' />}
                          width={100} />
                        <Column
                          header={< Cell className='text-right' > ต้นทุนชิ้นละ </Cell>}
                          cell={< ItemsCell position='top right' width={120} data={
                            items
                          }
                            field="cost" textAlign='text-right' />}
                          width={120} />
                        <Column
                          header={< Cell className='text-right' > ราคาป้ายชิ้นละ </Cell>}
                          cell={< ItemsCell position='top right' width={120} data={
                            items
                          }
                            field="price_tag" textAlign='text-right' />}
                          width={120} />
                        <Column
                          header={< Cell className='text-center' > ประเภทการขาย </Cell>}
                          cell={< ItemsCell position='top center' width={120} data={
                            items
                          }
                            field="type_sale_title" textAlign='text-center' />}
                          width={120} />
                      </Table>
                    </div>
                    <br />
                    <Form className='fluid' size='small'>
                      <Form.Group >
                        <Form.Input
                          id='invoice_itme_total'
                          label='จำนวนรายการ'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={this.state.invoice_itme_total}
                          readOnly />
                        {/* <Form.Input
                      id='invoice_profit_total'
                      label='ค่าแรงขายปลีกรวม'
                      placeholder=''
                      className='text-right'
                      width={4}
                      value={this.state.invoice_profit_total}
                      readOnly/> */}
                        <Form.Input
                          id='invoice_weight_total'
                          label='น้ำหนักรวม'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={this.state.invoice_weight_total}
                          readOnly />
                        <Form.Input
                          id='invoice_product_total'
                          label='จำนวนรวม'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={this.state.invoice_product_total}
                          readOnly />
                      </Form.Group>
                    </Form>

                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

          </div> </div>
          {this.state.open_print
            ? <BillPrintPreview
              branches={this.props.branches}
              invoice_detail={this.props.invoice_detail}
              items={items}
              product_total={this.state.invoice_product_total}
              weight_total={this.state.invoice_weight_total}
              onClose={() => {
                this.setState({ open_print: false })
              }} />
            : ''}
        </Modal.Content > <Modal.Actions>
          {/* <Button
            color='red'
            size='small'
            icon='cancel'
            floated='left'
            disabled={this.state.is_void || this.state.status_stock=='N'}
            labelPosition='left'
            id='cancel-this'
            onClick={(e) => {
              this.handlerVoidBill(this.props.bill)
            }}
            content='ยกเลิกรายการนี้' /> */}
          {/* <Button
            id='btnPrint'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled}
            onClick={(e) => {
            e.preventDefault();
            this.setState({open_print: true})
          }}
            content='พิมพ์'/> */}
          <Button
            id='btnSave'
            size='small'
            primary
            icon='save'
            labelPosition='left'
            disabled={this.state.is_void}
            onClick={(e) => {
              e.preventDefault();
              this.submitSaveInvoice();
            }}
            className={this.state.button_save_loading
              ? 'loading'
              : ''}
            content={this.state.button_save_title} />
          <Button
            id='btnSaveAndUpdate'
            size='small'
            icon='lightning'
            color='red'
            onClick={(e) => {
              e.preventDefault();
              if (window.confirm('ยืนยันอนุมัติและอัพเดทสต๊อก')){
                this.submitSaveInvoice_no_alert();
                setTimeout(() => {
                    this.setState({code:''})
                    this.submitUpdateStock(); 
                }, 400)}   
            }}
            className={this.state.button_update_stoick_loading
              ? 'loading'
              : ''}
            disabled={this.state.button_update_stoick_disabled}
            content='อนุมัติและอัพเดทสต๊อก' />
          <br /><br />
          <small>*การอนุมัติต้องเป็นสิทธิ์ Super Admin เท่านั้น</small>
        </Modal.Actions> </Modal>
    )
  }

}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}

export default connect(
  mapStateToProps,
)(BillModal)