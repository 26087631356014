/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';

import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader, Dropdown,
  Header,
  Checkbox,
  Popup
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class Categories extends Component {

  constructor(props) {
    super(props);

    this.gender_options = [
      {
        key: 'MA',
        value: 'MA',
        text: 'ชาย',
      }, {
        key: 'FE',
        value: 'FE',
        text: 'หญิง',
      }, {
        key: 'NA',
        value: 'NA',
        text: 'ไม่ระบุ',
      }
    ]

    this.state = {
      items: [],
      name: '',
      code: '',
      phone: '',
      description: '',
      citizen_id: '',
      modal_open: false,
      search: {
        customer_id: 0
      },
      setting: [
        {
          key: 'nick_name',
          label: 'ชื่อเล่น',
          check: true
        },
        {
          key: 'address',
          label: 'ที่อยู่',
          check: true
        },
        {
          key: 'city',
          label: 'อำเภอ',
          check: true
        },
        {
          key: 'province',
          label: 'จังหวัด',
          check: false
        },
        {
          key: 'postal_code',
          label: 'รหัสไปรษณีย์',
          check: false
        },
        {
          key: 'phone',
          label: 'เบอร์มือถือ',
          check: false
        },
        {
          key: 'email',
          label: 'อีเมล',
          check: false
        },
        {
          key: 'birth_date',
          label: 'วันเกิด',
          check: false
        },
        {
          key: 'gender',
          label: 'เพศ',
          check: false,
          options: this.gender_options
        },
        {
          key: 'nationality',
          label: 'สัญชาติ',
          check: false
        },
        {
          key: 'ethnicity',
          label: 'เชื้อชาติ',
          check: false
        },
        {
          key: 'point',
          label: 'คะแนน',
          check: false,
          className: 'text-right'
        }
      ]
    }
    this.submitData = this.submitData.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handleSeting = this.handleSeting.bind(this)
    this.submitSetting = this.submitSetting.bind(this)
  }

  submitData() {
    var formData = {
      name: this.state.name,
      code: this.state.code,
      phone: this.state.phone,
      description: this.state.description,
      citizen_id: this.state.citizen_id,
      birth_date: '2017-01-01'
    };
    let setting = this.state.setting
    for (let i in setting) {
      const o = setting[i]
      if (this.state[o.key])
        formData[o.key] = this.state[o.key]
    }

    const self = this;
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/customers/';
      Utility.post(url, formData, function (status, data) {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        }
      });
    } else {
      const url = Settings.baseUrl + '/customers/' + this.state.object_id + "/";
      Utility.put(url, formData, function (status, data) {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        }
      });
    }
  }

  genCode() {
    Utility.getCounter('customers', (s, d) => {
      let a = d.last_id == null ? 1 : d.last_id + 1;
      this.setState({
        code: 'C' + Utility.getNumberLeng(a, 6)
      });
    });
  }

  resetForm() {
    this.setState({
      name: '',
      code: '',
      description: '',
      phone: '',
      citizen_id: '',
      button_class: '',
      search: {}
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    let system_setting = JSON.parse(localStorage.getItem('system_setting'))
    system_setting = system_setting.filter(setting => setting.setting == 'CUSF')
    if (system_setting.length == 0) {
      Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
        for (let i in data) {
          if (data[i].setting == 'CUSF') {
            this.sys_setting_id = data[i].id
            this.setState({
              setting: JSON.parse(data[i].value)
            })
            break
          }
        }
      });
    } else {
      this.sys_setting_id = system_setting[0].id
      this.setState({
        setting: JSON.parse(system_setting[0].value)
      })
    }

    Utility.get(Settings.baseUrl + '/customers/?is_enabled=1', (status, data) => {

      this.customers = data;
      let customers = [];
      let customers_code = [];
      let customers_phone = [];
      let customers_citizen_id = [];
      for (let i in data) {
        customers.push({
          key: data[i].id,
          value: data[i].id,
          text: data[i].name
        });
        customers_code.push({
          key: data[i].id,
          value: data[i].id,
          text: data[i].code
        });
        customers_phone.push({
          key: data[i].id,
          value: data[i].id,
          text: data[i].phone
        });
        customers_citizen_id.push({
          key: data[i].id,
          value: data[i].id,
          text: data[i].citizen_id
        });
      }

      self.setState({
        items: data,
        customers: customers,
        customers_code: customers_code,
        customers_citizen_id: customers_citizen_id,
        customers_phone,
        loader_active: false
      });
    });
  }


  handlerSearch(event, v) {

    if (v.name) {
      let search = this.state.search;
      search[v.name] = v.value;
      this.setState({
        search: search
      });
      return;
    } else {
      const target = event.target;
      const value = target.type == 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let search = this.state.search;
      search[name] = value;
      this.setState({
        search: search
      });
    }
  }

  handlerSubmitSearch(e) {
    let search = this.state.search;
    let str = Utility.jsonToQueryString(search);
    Utility.get(Settings.baseUrl + '/customers/?is_enabled=1&' + str, (s, data) => {

      this.setState({
        loader_active: false,
        items: data
      });
    })
  }
  componentWillMount() {
    this.setState({
      table_width: this.props.clientWidth,
      table_hiegh: window.innerHeight - 250
    });
  }

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: row.name,
        code: row.code,
        citizen_id: row.citizen_id,
        phone: row.phone,
        description: row.description,
        object_id: row.id
      });
    } else {
      this.setState({ confirm_open: true, object_id: row.id });
    }
  }

  handleSeting(e, v) {

    let setting = this.state.setting
    setting[v.name].check = v.checked
    this.setState({
      setting: setting
    })
  }

  setFieldValue(items) {
    let _items = []
    for (let i in items) {
      const item = Utility.cloneObjectJson(items[i])
      const op = Utility.getObjectByValue(this.gender_options, item.gender)
      if (op)
        item.gender = op.text
      item.birth_date = Utility.formatDate(item.birth_date)
      _items.push(item)
    }
    return _items
  }

  submitSetting(e) {
    e.preventDefault()


    const formData = {
      setting: 'CUSF',
      value: JSON.stringify(this.state.setting)
    }
    if (this.sys_setting_id) {
      Utility.put(Settings.baseUrl + "/system_setting/" + this.sys_setting_id + "/", formData, (s, d) => {
        this.setState({ open_setting: false });
        Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
          if (status) {
            localStorage.setItem("system_setting", JSON.stringify(data));
          }
        });
      })
    } else {
      Utility.post(Settings.baseUrl + "/system_setting/", formData, (s, d) => {
        this.setState({ open_setting: false });
        Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
          if (status) {
            localStorage.setItem("system_setting", JSON.stringify(data));
          }
        });
      })
    }
  }

  render() {
    const items = this.setFieldValue(this.state.items);
    let setting = []
    for (let i in this.state.setting) {
      if (this.state.setting[i].check)
        setting.push(this.state.setting[i])
    }

    return (
      <div>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/>
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content className='scrolling'>
            <Modal.Description>
              <Form size='small'>
                <Form.Field>
                  <label>รหัสลูกค้า</label>
                  <Input onChange={(e) => this.setState({ code: e.target.value })}
                    value={this.state.code}
                    action={
                      <Button onClick={(e) => { e.preventDefault(); this.genCode(); }} icon='angle double left' />
                    } />
                </Form.Field>
                <Form.Field>
                  <label>ชื่อ</label>
                  <Input onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={100} />
                </Form.Field>
                <Form.Field>
                  <label>เบอร์โทร</label>
                  <Input onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      this.setState({
                        phone: e.target.value
                      })
                    }
                  }} value={this.state.phone} maxLength={20} />
                </Form.Field>
                <Form.Field>
                  <label>เลขประจำตัวประชาชน</label>
                  <Input onChange={(e) => this.setState({ citizen_id: e.target.value })} value={this.state.citizen_id} maxLength={20} />
                </Form.Field>
                {setting.map((row, i) =>
                  <Form.Field key={i}>
                    <label>{row.label}</label>
                    {row.options ? <Dropdown options={row.options} search selection name={row.key} onChange={(e, v) => { this.setState({ [v.name]: v.value }) }} value={this.state[row.key]} /> : <Input onChange={(e) => this.setState({ [row.key]: e.target.value })} value={this.state[row.key]} />}
                  </Form.Field>)}
                <Form.Field>
                  <label>หมายเหตุ</label>
                  <Input onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} maxLength={255} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button size='small' primary onClick={(e) => {
              e.preventDefault();
              this.submitData();
              this.setState({ button_class: 'loading' })
            }}
              className={this.state.button_class}>บันทึก</Button>
            <Button size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Form size='small'>
          <Form.Group>
            <Form.Field>
              <label>รหัสลูกค้า</label>
              <Dropdown placeholder='รหัสลูกค้า' search selection defaultValue={this.state.search.customer_id} value={this.state.search.customer_id} options={this.state.customers_code} onChange={this.handlerSearch} name='customer_id' />
            </Form.Field>
            <Form.Field>
              <label>ชื่อลูกค้า</label>
              <Dropdown placeholder='ชื่อลูกค้า' search selection defaultValue={this.state.search.customer_id} value={this.state.search.customer_id} options={this.state.customers} onChange={this.handlerSearch} name='customer_id' />
            </Form.Field>
            <Form.Field>
              <label>เบอร์โทร</label>
              <Dropdown placeholder='เบอร์โทร' search selection defaultValue={this.state.search.customer_id} value={this.state.search.customer_id} options={this.state.customers_phone} onChange={this.handlerSearch} name='customer_id' />
            </Form.Field>
            <Form.Field>
              <label>เลขประจำตัวประชาชน</label>
              <Dropdown placeholder='เลขประจำตัวประชาชน' search selection defaultValue={this.state.search.customer_id} value={this.state.search.customer_id} options={this.state.customers_citizen_id} onChange={this.handlerSearch} name='customer_id' />
            </Form.Field>

            <Form.Field width={16}>
              <br />
              <Button size='small' onClick={this.handlerSubmitSearch} type='button'><Icon name='search' />ค้นหา</Button>
              <Button size='small' onClick={(e) => {
                this.resetForm(e);
                this.componentDidMount();
              }} type='button' >ทั้งหมด</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={this.state.table_hiegh}>
          <Column
            header={<Cell>รหัสลูกค้า</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="code" />
            }
            width={120}
          />
          <Column
            header={<Cell>ชื่อ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="name" />
            }
            width={200}
          />
          <Column
            header={<Cell>เบอร์โทร</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="phone" />
            }
            width={200}
          />
          <Column
            header={<Cell>เลขประจำตัวประชาชน</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="citizen_id" />
            }
            width={200}
          />
          {setting.map((row, i) =>
            <Column
              header={<Cell>{row.label}</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field={row.key} className={row.className} />
              }
              width={200}
            />
          )}
          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="description" />
            }
            width={200}
          />
        </Table>
        <br />
        <Form size='small'>
          <Form.Group>

            <Form.Field width={16}>

              <Button size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างลูกค้า'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
              <Button size='small' content='ตั้งค่าข้อมูล' onClick={(e) => {
                e.preventDefault();
                this.setState({
                  open_setting: true
                })
              }}
                floated='right' icon='setting' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <Modal size='mini' open={this.state.open_setting} onClose={this.close} /*dimmer='blurring'*/>
          <Modal.Header>
            ตั้งค่าข้อมูล
          </Modal.Header>
          <Modal.Content>
            <Form.Field>
              <Checkbox label='รหัสลูกค้า' checked={true} />
            </Form.Field>
            <Form.Field>
              <Checkbox label='ชื่อ' checked={true} />
            </Form.Field>
            <Form.Field>
              <Checkbox label='เบอร์โทร' checked={true} />
            </Form.Field>
            <Form.Field>
              <Checkbox label='เลขประจำตัวประชาชน' checked={true} />
            </Form.Field>
            <Form.Field>
              <Checkbox label='หมายเหตุ' checked={true} />
            </Form.Field>
            {this.state.setting.map((row, i) =>
              <Form.Field key={i}>
                <Checkbox label={row.label} name={i} checked={row.check} key={i} onChange={this.handleSeting} />
              </Form.Field>)}
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.submitSetting}>
              บันทึก
            </Button>
            <Button onClick={() => {
              this.setState({ open_setting: false });
            }}>
              ปิด
            </Button>
          </Modal.Actions>
        </Modal>
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/customers/' + this.state.object_id + "/";
            var formData = {
              is_enabled: 0
            };
            var self = this;
            Utility.put(url, formData, function (status, data) {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              }
            });
          }}
        />
      </div>
    );
  }
}
export default Categories;
