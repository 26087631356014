/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Header
} from 'semantic-ui-react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';


class InputSearchBill extends Component {

    constructor(props) {
        super(props);

        this.modal_open = false;
        this.state = {
            options: [],
            searchQuery: '',
            is_reset: false,
        }

        this.timeout = null;

        this.handlerSearchCustomer = this.handlerSearchCustomer.bind(this)
        this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this)
        this.handleClickFocus = this.handleClickFocus.bind(this)
    }

    handlerSearchCustomer(e, v) {
        clearTimeout(this.timeout)
        this.setState({
            isLoading: true,
            searchQuery: v.searchQuery.trim()
        }, () => this.timeout = setTimeout(() => { this.loadCustomerPage(this.state.searchQuery) }, 1600));
    }

    handlerDropdownSearch(e, v) {
        if (this.props.onChange != null) {
            this.props.onChange(e, v)
            this.setState({
                searchQuery: v.value
            })
        }

    }

    componentDidMount() {
    }
    loadCustomerPage(text) {
        if (text.length === 0) {
            this.setState({
                isLoading: false,
                options: [],
            })
        } else {
            let q1 = {
                branch: this.props.branch,
                object_number: text
            };
            let q = Utility.jsonToQueryString(q1)
            Utility.get(Settings.baseUrl + `/ledger-search/?${q}`, (status, data) => {
                this.setState({
                    isLoading: false,
                    options: status === true ? data : [],
                })
            });
        }

    }

    handleClickFocus() {
        this.setState({
            searchQuery: ''
        })
    }

    render() {
        if (this.props.value == '' && !this.state.is_reset) {
            this.state.searchQuery = ''
            this.setState({ is_reset: true })
        }
        this.state.is_reset = false

        return (
            <Form.Dropdown placeholder={this.props.placeholder} search
                label={this.props.label}
                width={this.props.width}
                selection
                name={this.props.name}
                loading={this.state.isLoading}
                onSearchChange={this.handlerSearchCustomer}
                searchQuery={this.state.searchQuery}
                onFocus={this.handleClickFocus}
                value={this.props.value}
                options={this.state.options}
                onChange={this.handlerDropdownSearch} />
        );
    }
}

export default InputSearchBill;
