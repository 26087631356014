/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import DropDownBase from './DropDownBase';
import { connect } from 'react-redux'
import { loadBranch } from '../../../actions'

class DropdownBranch extends Component {
    componentDidMount() {
        //loadBranch(this.props.dispatch)
    }

    render() {
        const { branches, filter, auth } = this.props;

        const _options = []
        const _options2 = []

        for (let i in branches) {

            // filter
            if (auth.branch != undefined) {
                if (auth.role == 'U' && auth.branch.id != branches[i].id)
                    continue
                _options.push({
                    key: i,
                    text: branches[i].name,
                    value: branches[i].id,
                })
                _options2.push({
                    key: i,
                    text: branches[i].code+' - '+branches[i].name,
                    value: branches[i].id,
                })
            }
        }

        return (<DropDownBase options={this.props.showCode ? _options2 : _options} {...this.props} />
        );
    }
}

const mapStateToProps = state => {

    return ({
        auth: state.auth,
        branches: state.branches,
    })
}
export default connect(
    mapStateToProps,
)(DropdownBranch)