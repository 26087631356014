/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';

class ImportCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields(),
            is_loading: false,

        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    setFields(options_career,options_workplace){
        return [
            {
                name: 'name',
                title: '*ชื่อ',
                type: 'string',
                unique: true,
                required: true
            },

            {
                name: 'citizen_id',
                title: '*เลขประจำตัวประชาชน',
                type: 'string',
                unique: true,
            },
            {
                name: 'phone',
                title: 'เบอร์โทร',
                type: 'string',
            },
            {
                name: 'mobile',
                title: 'เบอร์มือถือ',
                type: 'string',
            },
            {
                name: 'address',
                title: 'ที่อยู่',
                type: 'string',
            },
            {
                name: 'district',
                title: 'ตำบล',
                type: 'string',
            },
            {
                name: 'city',
                title: 'อำเภอ',
                type: 'string',
            },
            {
                name: 'province',
                title: 'จังหวัด',
                type: 'string',
            },
            {
                name: 'postal_code',
                title: 'รหัสไปรษณีย์',
                type: 'string',
            },
            {
                name: 'email',
                title: 'อีเมล',
                type: 'string',
            },
            {
                name: 'birth_date',
                title: 'วันเกิด',
                type: 'string',
            },
            {
                name: 'gender',
                title: 'เพศ',
                type: 'string',
                options: [
                    {
                        key: 'MA',
                        value: 'MA',
                        text: 'ชาย',
                      },
                      {
                        key: 'FE',
                        value: 'FE',
                        text: 'หญิง',
                      }
                ]
            },
            {
                name: 'career',
                title: 'อาชีพ',
                type: 'string',
                options: options_career
            },
            {
                name: 'workplace',
                title: 'สถานที่ทำงาน',
                type: 'string',
                options: options_workplace
            },
            {
                name: 'description',
                title: 'หมายเหตุ',
                type: 'string',
            },
        ]
    }

    async componentDidMount()  {
        
        var requese_career = await Utility.getAsync(Settings.baseUrl + '/career/?is_enabled=1')
        var requese_workplace = await Utility.getAsync(Settings.baseUrl + '/workplace/?is_enabled=1')

        const options_career = []
        for (var i in requese_career.data) {
            options_career.push({
              key: requese_career.data[i].id,
              value: requese_career.data[i].id,
              text: requese_career.data[i].name,
            });
          }
        const options_workplace = []
        for (var i in requese_workplace.data) {
            options_workplace.push({
            key: requese_workplace.data[i].id,
            value: requese_workplace.data[i].id,
            text: requese_workplace.data[i].name,
            });
        }
        this.setState({
            fields: this.setFields(options_career,options_workplace)
        })
      }

      
    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]
            let url = Settings.baseUrl + '/customers/import/'
            Utility.post(url,data,(e,res)=>{
                position++;
                if(position<data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })

        }
        run()
    }

    render() {

        return (
            <div>
                <ImportCSV
                    name='Customer'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportCustomer