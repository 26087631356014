/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint no-self-assign: "off"*/
import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Icon, Dimmer, Loader,
  Pagination, Menu,
  Popup,
} from 'semantic-ui-react';

import 'react-dates/lib/css/_datepicker.css';
import { ContextMenuProvider } from 'react-contexify';
import Settings from '../../Settings';
import Utility from '../../Utility';

class ItemsCell extends Component {

  render() {
    const { rowIndex, field, type, data, ...props } = this.props;
    let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
      : type == 'price' ? data[rowIndex][field]
        : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
          : data[rowIndex][field];
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props} className={(data[rowIndex].bill.is_void ? 'cell_red' : '')}>
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id"
          className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>
          <Popup
            position={this.props.position}
            content={v}
            style={Width300}
            trigger={
              <div style={ellipsisStyle}>{v}</div>
            }
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props} className={(data[rowIndex].bill.is_void ? 'cell_red cell-time-click' : 'cell-time-click')} onClick={(e) => {
        this.props.onClick(e, data[rowIndex]);
      }}>
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id"
          className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>
          <div ><Icon name={data[rowIndex].bill.is_void ? 'remove circle' : 'check circle'} color={data[rowIndex].bill.is_void ? 'red' : 'green'} /></div>
        </ContextMenuProvider>
      </Cell>
    );
  }
}

class BillList extends Component {

  constructor(props) {
    super(props);
    this.limit = 30

    this.state = {
      billItems: [],
      items: [],
      activeItem: 'sell'
    }

    this.handleItemClick = this.handleItemClick.bind(this)
    this.handlerClickItem = this.handlerClickItem.bind(this)
    this.handlerClose = this.handlerClose.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount() {
    this.setTable('sell')
  }

  setFieldValue(items) {

    for (let i in items) {
      if (items[i].kind == 'SE')
        items[i].product_name = items[i].product.name
      else {
        items[i].product_name = items[i].product_name
      }
      if (items[i].kind == 'SE')
        items[i].product_code = items[i].product.code

      items[i].branch_name = items[i].bill.branch.name
      items[i].bill_number = items[i].bill.bill_number
      items[i].date = Utility.formatDate(items[i].bill.record_date)
      items[i].time = Utility.formatTime(items[i].bill.record_date)
      items[i].total = Utility.priceFormat(items[i].kind == 'SE' ? items[i].sell : items[i].buy)
    }

    return items
  }

  handlerClose(e) {
    e.preventDefault();
    this.setState({
      modal_detail: false,
      bill: {}
    });
  }

  handleItemClick(e, v) {
    this.setTable(v.name)
  }

  setTable(tab_name, activePage) {

    this.setState({
      activeItem: tab_name,
      loader_active: true
    })

    let q = {}
    if (tab_name == 'sell') {
      q = {
        kind: 'SE',
        category_not: 3
      }
    } else if (tab_name == 'buy') {
      q = {
        kind: 'BU',
        category_not: 3
      }
    } else if (tab_name == 'sell_gold') {
      q = {
        kind: 'SE',
        category: 3
      }
    } else if (tab_name == 'buy_gold') {
      q = {
        kind: 'BU',
        category: 3
      }
    }
    q['activePage'] = activePage
    q['page_size'] = this.limit
    let querytoday = Utility.jsonToQueryString(q);
    Utility.get(Settings.baseUrl + '/customers/' + this.props.customer_id + '/bill_item/?is_enabled=1&' + querytoday, (s, res, c) => {

      let items = res.results ? this.setFieldValue(res.results) : []
      this.setState({
        loader_active: false,
        items: items,
        table_width: window.innerWidth,
        totalPages: res.next == null && res.previous == null ? 0 : Math.ceil(res.count / this.limit),
      })

    });
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.setTable(this.state.activeItem, activePage)
  }

  handlerClickItem(e, data) {
    this.loadBillDetail(data.bill);
  }

  loadBillDetail(data) {
    let action = 'exc';
    if (data.kind == 'BU')
      action = 'buy';
    else if (data.kind == 'SE')
      action = 'sell';
    this.setState({
      modal_detail: true,
      bill_loader_active: true,
      bill: data,
      total_sell_price: Utility.priceFormat(parseFloat(data.sell)),
      otal_sell_weight: 0,
      total_buy_price: Utility.priceFormat(parseFloat(data.buy)),
      net_price: data.total,
      action: action,
      itemCount: data.amount,
      description: data.description
    })

    let url = Settings.baseUrl + "/bill/" + data.id + "/items/";
    Utility.get(url, (e, res) => {

      if (e == true) {
        let billItems = [];
        for (let i in res) {
          let d = res[i]
          if (d.kind == 'SE') {
            let p = d.product
            billItems.push({
              type_title: 'ขาย',
              type: 'SE',
              'id': i + 1,
              amount: d.amount,
              sell_price: d.sell,
              sell_weight: 0,
              sell_weight_real: d.weight,
              buy_price: 0,
              buy_weight: 0,
              gold_price: d.price_gold,
              product_code: d.product.code,
              product_id: d.product.id,
              product_name: p.name,
              product_type: p.kind.name,
              category_name: p.category.name,
              category_id: p.category.id,
              code_change: d.code_change,
              exchange: d.exchange,
              sales_force: (d.sell - (d.cost * d.amount))
            })
          } else if (d.kind == 'BU') {
            billItems.push({
              type_title: 'ซื้อ',
              type: 'BU',
              'id': i + 1,
              amount: 0,
              sell_price: 0,
              sell_weight: 0,
              sell_weight_real: 0,
              buy_price: d.buy,
              buy_weight: d.weight,
              gold_price: d.price_gold,
              product_code: '',
              product_name: d.product_name,
              product_type: '',
              category_name: d.category.name,
              category_id: d.category.id,
              code_change: d.code_change,
              exchange: d.exchange,
              sales_force: 0
            })
          }
        }
        this.setState({
          bill_loader_active: false,
          billItems: billItems
        })
      }

    });

    let url2 = Settings.baseUrl + "/bill_staff/?bill_id=" + data.id;
    Utility.get(url2, (e, res) => {
      this.setState({
        billstaff: res
      })
    });
  }

  componentWillMount() { this.setState({ table_width: this.props.clientWidth ? this.props.clientWidth : window.innerWidth - 70, table_height: window.innerHeight - 250 }); }
  render() {
    let items = this.state.items
    const activeItem = this.state.activeItem
    return (<div>

      <div className='relative' id='table_width'>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Menu>
          <Menu.Item id='buyGold1'
            name='sell'
            active={activeItem == 'sell'}
            content='ซื้อทองรูปพรรณ'
            onClick={this.handleItemClick}
          />
          <Menu.Item id='sellGold1'
            name='buy'
            active={activeItem == 'buy'}
            content='ขายทองรูปพรรณ'
            onClick={this.handleItemClick}
          />
          <Menu.Item id='buyGold2'
            name='sell_gold'
            active={activeItem == 'sell_gold'}
            content='ซื้อทองแท่ง'
            onClick={this.handleItemClick}
          />
          <Menu.Item id='sellGold2'
            name='buy_gold'
            active={activeItem == 'buy_gold'}
            content='ขายทองแท่ง'
            onClick={this.handleItemClick}
          />
        </Menu>


        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={this.state.table_height}>

          <Column
            header={<Cell>เลขที่บิล</Cell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="bill_number" />
            }
            width={80}
          />
          <Column
            header={<Cell>วันที่</Cell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="date" />
            }
            width={80}
          />
          <Column
            header={<Cell>เวลา</Cell>}
            cell={
              <ItemsCell position='top left' width={60} data={items} field="time" />
            }
            width={60}
          />
          <Column
            header={<Cell>สาขา</Cell>}
            cell={
              <ItemsCell position='top left' width={140} data={items} field="branch_name" />
            }
            width={140}
          />
          <Column
            header={<Cell>รหัสสินค้า</Cell>}
            cell={
              <ItemsCell position='top left' width={120} id='productID' data={items} field="product_code" />
            }
            width={120}
          />
          <Column
            header={<Cell>ชื่อสินค้า</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="product_name" />
            }
            width={200}
          />
          {(activeItem == 'sell' || activeItem == 'sell_gold') && <Column
            header={<Cell className='text-right'>จำนวน</Cell>}
            cell={
              <ItemsCell position='top right' width={50}  data={items} field="amount" textAlign='text-right' type='number' />
            }
            width={50}
          />}
          {(activeItem == 'buy' || activeItem == 'buy_gold') && <Column
            header={<Cell className='text-right'>น้ำหนัก ก.</Cell>}
            cell={
              <ItemsCell position='top right' width={140}  data={items} field="weight" textAlign='text-right' type='weight' />
            }
            width={140}
          />}
          <Column
            header={<Cell className='text-right'>ราคา</Cell>}
            cell={
              <ItemsCell position='top right' width={120}  data={items} field="total" textAlign='text-right' type='price' />
            }
            width={120}
          />
        </Table>

        {this.state.totalPages > 0 && <Pagination
          activePage={this.state.activePage}
          onPageChange={this.handlePaginationChange}
          totalPages={this.state.totalPages}
        />}
      </div>
    </div>)
  }
}


export default BillList;
