const state_default = {
  is_open: false,
  modal_action: 'add',
  data: {}
}
const lease_modal = (state = state_default, action) => {
    switch (action.type) {
      case 'LEASE_MODAL_EDIT_OPEN':
        return {
          is_open: true,
          modal_action: 'edit',
          data: action.data
        }
      case 'LEASE_MODAL_CLOSE':
          return {
            is_open: false,
            modal_action: 'add',
            data: {}
          }
          case 'LEASE_MODAL_ADD_OPEN':
              return {
                is_open: true,
                modal_action: 'add',
                data: {}
              }
    default:
        return state
    }
  }
  
  export default lease_modal