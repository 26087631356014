/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,Header
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';

class InputSearchLease extends Component {

    constructor(props) {
        super(props);

        this.modal_open = false;
        this.state = {
            leases: []
        }

        this.handlerSearchLease = this.handlerSearchLease.bind(this)
        this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this)
    }

    handlerSearchLease(e, v) {
        clearTimeout(this.timeout)
        this.setState({
            isLoading: true
        });
        this.timeout = setTimeout(()=>{this.loadLeasePage(v.searchQuery)},1600)
    }

    handlerDropdownSearch(e,v){
        if(this.props.onChange!=null)
            this.props.onChange(e,v)
    }

    componentDidMount() {
    }
    loadLeasePage(text) {

        let q1 = {
            contains_number: text
        };
        let q = Utility.jsonToQueryString(q1)
        Utility.get(Settings.baseUrl + '/lease/?' + q, (status, data) => {
            if (status == true) {
                let result = []
                for (let i in data) {
                    result.push({
                        key: data[i].id,
                        value: data[i].id,
                        text: data[i].number,
                    })
                }
                this.setState({
                    isLoading: false,
                    leases: result,
                })
            }
        });
    }

    render() {


        return (
            <Form.Dropdown 
                id='searchLease'
                placeholder={this.props.placeholder} search
                label={this.props.label}
                width={this.props.width}
                selection 
                name={this.props.name}
                loading={this.state.isLoading}
                onSearchChange={this.handlerSearchLease}
                value={this.props.value}
                options={this.state.leases}
                onChange={this.handlerDropdownSearch} />
        );
    }
}

export default InputSearchLease;
