/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import InputSearchCustomer from '../../Customer/InputSearchCustomer';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        user_type: "",
        user_id: "",
        user_name: "",
        start_date: moment(),
        end_date: moment(),
      },
      items: [],
      user_type: [
        {
          value: "",
          key: 0,
          text: 'ทั้งหมด'
        },
        {
          value: "True",
          key: 1,
          text: 'พนักงาน'
        },
        {
          value: "False",
          key: 2,
          text: 'ลูกค้า'
        }
      ]
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }

  componentDidMount() {
    this.setState({ st: true })
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        search: {
          start_date: moment(temp_start, 'DD/MM/YYYY'),
          end_date: moment(temp_end, 'DD/MM/YYYY')
        }
      })
    }
  }

  resetForm(e) {
    this.setState({
      search: {
        user_type: "",
        user_name: "",
        user_id: "",
        start_date: moment(),
        end_date: moment(),
      }
    })
  }

  handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)
    // search.start_date = Utility.formatDate2(search.start_date)
    // search.end_date = Utility.formatDate2(search.end_date)
    let fromData = Utility.jsonToQueryString(search)
    var search_cus_code = this.state.search.customer_id
    var search_text = ""
    if (search_cus_code) {
      search_text = "&cus_code=" + search_cus_code
    } else { 
      var search_cus_code = ''
    }
    console.log("search_text, ", search_text)
    var url = Settings.baseReportUrl + "/customers/mobile_list/?user_type=" + search.user_type + "&user_name=" + search.user_name +search_text
    if(search.user_type==""){ 
      var url = Settings.baseReportUrl + "/customers/mobile_list/?user_name=" + search.user_name + search_text
    }
    this.setState({
      open: true,
      // loading: true
    })
    Utility.get(url, (s, d) => {
      if (s == true) {
        this.setState({
          loading: false,
          items: d
        })
      }
    })

    var url = Settings.baseReportUrl + "/customers/"
    Utility.get(url, (s, d) => {
      if (s == true){
        this.setState({
          all_customer: d.count
        })
      }
    })
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search
    })
  }
  render() {
    let data = this.props.data

    let title = 'รายงานสรุปจำนวนลูกค้าลงทะเบียนใช้ Mobile Application';
    let filename = 'mobile-register';

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }

    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center',
      'width': '220px'
    }
    const staffWidth = {
      'width': '300px'
    }
    const textU = {
      'text-decoration': 'underline'
    }
    let amount = 0
    let weight = 0
    let weight_b = 0
    let sell = 0
    let wage = 0
    let net = 0
    for (let i in this.state.items) {
      amount += parseFloat(this.state.items[i].amount)
      weight += parseFloat(this.state.items[i].weight)
      weight_b += parseFloat(this.state.items[i].weight_b)
      sell += parseFloat(this.state.items[i].sell)
      wage += (parseFloat(this.state.items[i].wage))
      net += (parseFloat(this.state.items[i].net))
    }

    let branch_name = ''
    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>รายงานจำนวนลูกค้าลงทะเบียนใช้ Mobile Application</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >
            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
              <Form.Field>
                <label>ลูกค้า</label>
                <InputSearchCustomer placeholder='ลูกค้า' width={16} onChange={this.handlerInput} name="customer_id" value={this.state.search.customer_id} />
              </Form.Field>
              {/* <Form.Field>
                <label>ชื่อลูกค้า</label>
                <Input placeholder="ชื่อลูกค้า" value={this.state.search.user_name} name="user_name" onChange={this.handlerInput} />
              </Form.Field>
              <Form.Field>
                <label>รหัสลูกค้า</label>
                <Input placeholder="รหัสลูกค้า" value={this.state.search.user_id} name="user_id" onChange={this.handlerInput} />
              </Form.Field> */}
              <Form.Field>
                <label>ประเภทลูกค้า</label>
                <Dropdown placeholder='- ทั้งหมด -' search selection options={this.state.user_type} value={this.state.search.user_type} name='user_type' onChange={this.handlerInput} />
              </Form.Field>
              <Button>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='paperA4-portrait'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan='6'><center>รายงานจำนวนลูกค้าลงทะเบียนใช้ Mobile Application</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan='1' style={textCenter}>ลำดับ</Table.HeaderCell>
                      <Table.HeaderCell colSpan='1' style={textCenter}>รหัสลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan='2' style={textCenter}>ชื่อ-สกุลลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan='1' style={textCenter}>ประเภทลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan='1' style={textRight}>วันที่ลงทะเบียน<span style={{paddingRight: "60px"}}></span></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.items.map((row, i) =>
                      <Table.Row key={i}>
                        <Table.Cell style={textCenter}>{i+1}</Table.Cell>
                        <Table.Cell style={textCenter}>{row.cus_code}</Table.Cell>
                        <Table.Cell colSpan='2' style={{paddingLeft: "150px"}}>{row.user_name}</Table.Cell>
                        <Table.Cell style={{textAlign: "left", paddingLeft: "80px"}}>{row.user_type=="True"? "พนักงาน" : "ลูกค้า" }</Table.Cell>
                        <Table.Cell style={textRight}>{Utility.formatDate(row.created_datetime)}<span style={{paddingRight: "62px"}}></span></Table.Cell>
                      </Table.Row>)
                    }

                    <Table.Row >
                      <Table.Cell colSpan='6' style={{textAlign: "right"}}><b>มีลูกค้าลงทะเบียนใช้ Mobile Application ทั้งหมด {Utility.numberFormat(this.state.items.length)} คน  จากลูกค้าทั้งหมด {Utility.numberFormat(this.state.all_customer)} คน</b><span style={{paddingRight: "59px"}}></span></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(()=>{
                mywindow.close();
              },2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)