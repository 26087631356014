import Utility from '../Utility'
import Settings from '../Settings'
import update from 'immutability-helper';

const default_state = {
  items: [],
  items_option: []
}
export const loadProduct = async (dispatch) => {

  const url = Settings.baseUrl + '/products/';
  const rep = await Utility.getAsync(url)

  dispatch( {
    type: "SET_PRODUCTS_LIST",
    data: {
      list: rep.data
    }
  })
};
const products = (state = default_state, action) => {
    switch (action.type) {
      case 'SET_PRODUCTS_LIST':
        const items_option = []
        action.data.list.map((o,i)=>{
          items_option.push({key: o.id,
            value: o.id,
            text: o.code + " " +o.name
          });
        })
        return update(state, {items: {$set: action.data.list},items_option: {$set: items_option}});
    default:
        return state
    }
  }
  
  export default products