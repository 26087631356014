/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            total_weight: 0,
            total_sell: 0,
            total_fee: 0,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
    }

    resetForm(e) {
        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/report/overview-sale-change/?" + fromData

        this.setState({
            open: true,
            loading: true,
            items: [],
            total_weight: 0,
            total_sell: 0,
            total_fee: 0,
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setFieldValue(d)
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        let total_weight = 0
        let total_sell = 0
        let total_fee = 0
        for (let i in _items) {
            let item = _items[i]
            total_weight += item.total.weight
            total_sell += item.total.sell
            total_fee += item.total.fee
            items.push(item)
        }

        this.setState({
            loading: false,
            items: items,
            total_weight: total_weight,
            total_sell: total_sell,
            total_fee: total_fee,
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานภาพรวมขาย-เปลี่ยน';
        let filename = 'overview-sale-change-' + Utility.formatDate3(this.state.search.start_date) + '-to-' + Utility.formatDate3(this.state.search.end_date);


        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    search: {
                                        start_date: moment(),
                                        end_date: moment(),
                                    }
                                }, () => {
                                    this.handlerSubmit(e)
                                })
                            }}>วันนี้</Button>
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    search: {
                                        start_date: moment().startOf("month"),
                                        end_date: moment(),
                                    }
                                }, () => {
                                    this.handlerSubmit(e)
                                })
                            }}>เดือนนี้</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle} celled>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ค่ากำเหน็จ/ค่าเปลี่ยน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ราคาทอง</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รวม</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {this.state.items.map((row, i) => {
                                        return (<>
                                            <Table.Row key={`${i}-sell`}>
                                                <Table.Cell style={textCenter} rowspan='2'>{row.branch}</Table.Cell>
                                                <Table.Cell style={textLeft} >ขาย</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(row.sell.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.sell.fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.sell.sell)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.sell.fee + row.sell.sell)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={`${i}-xc`}>
                                                <Table.Cell style={{ ...textLeft, borderLeft: '1px solid rgba(34,36,38,.1)' }}>เปลี่ยน</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(row.xc.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.xc.fee + row.xc.xc)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.xc.sell)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.xc.fee + row.xc.sell + row.xc.xc)}</Table.Cell>
                                            </Table.Row>
                                        </>)
                                    })}
                                    <Table.Row >
                                        <Table.Cell style={textCenter}><b>รวม</b></Table.Cell>
                                        <Table.Cell style={textCenter}></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.weightFormat(this.state.total_weight)}</div></b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.numberFormat(this.state.total_fee)}</div></b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.numberFormat(this.state.total_sell)}</div></b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.numberFormat(this.state.total_sell + this.state.total_fee)}</div></b></Table.Cell>
                                    </Table.Row>

                                    <Table.Row >
                                        <Table.Cell colSpan='6'></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='6' style={{ ...textLeft, fontSize: '20px' }}>รวม ขาย+เปลี่ยน</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={{ ...textCenter, borderTop: '1px solid rgba(34,36,38,.1)' }}>สาขา</Table.HeaderCell>
                                        <Table.HeaderCell style={{ ...textCenter, borderTop: '1px solid rgba(34,36,38,.1)' }}>รายการ</Table.HeaderCell>
                                        <Table.HeaderCell style={{ ...textCenter, borderTop: '1px solid rgba(34,36,38,.1)' }}>น้ำหนัก</Table.HeaderCell>
                                        <Table.HeaderCell style={{ ...textCenter, borderTop: '1px solid rgba(34,36,38,.1)' }}>ค่ากำเหน็จ/ค่าเปลี่ยน</Table.HeaderCell>
                                        <Table.HeaderCell style={{ ...textCenter, borderTop: '1px solid rgba(34,36,38,.1)' }}>ราคาทอง</Table.HeaderCell>
                                        <Table.HeaderCell style={{ ...textCenter, borderTop: '1px solid rgba(34,36,38,.1)' }}>รวม</Table.HeaderCell>
                                    </Table.Row>
                                    {this.state.items.map((row, i) => {
                                        return (
                                            <Table.Row key={`${i}-sum`}>
                                                <Table.Cell style={textCenter}>{row.branch}</Table.Cell>
                                                <Table.Cell style={textLeft} >ขาย + เปลี่ยน</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(row.total.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.total.fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.total.sell)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.numberFormat(row.total.fee + row.total.sell)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                    <Table.Row >
                                        <Table.Cell style={textCenter}><b>รวม</b></Table.Cell>
                                        <Table.Cell style={textCenter}></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.weightFormat(this.state.total_weight)}</div></b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.numberFormat(this.state.total_fee)}</div></b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.numberFormat(this.state.total_sell)}</div></b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.numberFormat(this.state.total_sell + this.state.total_fee)}</div></b></Table.Cell>
                                    </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)