/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from "react";
// import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button,
  Modal,
  Input,
  Dimmer,
  Loader,
  Table,
  Header,
  Label,
  FormGroup,
  FormField,
  Icon,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
// import Utility from '../../../Utility';
// import Settings from '../../../Settings';
import moment from "moment";
import {hotkeys} from "react-keyboard-shortcuts";
import {connect} from "react-redux";
import InputSearchCustomer from "../Customer/InputSearchCustomer";
import CustomerModal from "../Customer/CustomerModal";
import Utility from "../../Utility";
import Settings from '../../Settings';
import BookPrintPreview from "./BookPrintPreview";

class PrintNextPageConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        savings_data: {}
    };
    this.submitPrint = this.submitPrint.bind(this)
  }

  async componentDidMount() {

    const {savings} = this.props
    this.setState({loading: true})
    const url = Settings.baseUrl + "/saving/" + savings.id + "/print/";
    const savings_print = await Utility.getAsync(url)

    if(savings_print.status_code == 200){
        const savings_data = savings_print.data
        this.setState({loading: false,savings_data})
    }
  }

  async submitPrint(){
    const {savings_data} = this.state
    const {auth,savings} = this.props;
    
    this.setState({loading: true})
    const url = Settings.baseUrl + "/saving/" + savings.id + "/nextpage/";
    const savings_print = await Utility.postAsync(url,{})
    this.setState({loading: false})
    this.props.onSubmit();
  }

  render() {
    const {auth,savings} = this.props;
    const {loading,savings_data,open_preview,print_data} = this.state
    return (
      <Modal open={true} size="mini" /*dimmer='blurring'*/>
        <Button
          id="btnCloseProductSelect"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header>แจ้งเตือน</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {!loading && <Header>ยืนยันขึ้นหน้าสมุดใหม่</Header>}
          <Dimmer active={loading} inverted>
            <Loader inverted>Loading</Loader>
        </Dimmer>
            {open_preview && <BookPrintPreview
                title='พิมพ์สมุด'
                data={print_data}
                onClose={()=>{
                  this.setState({open_preview: false})
                  this.props.onClose();
                }}
            />}
          </Modal.Description>
          <CustomerModal
            open={this.state.modal_open}
            onCreate={this.handlerCreateCustomer}
            onClose={() => {
              this.setState({modal_open: false});
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="cancelAddstaff"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.props.onClose();
            }}
          >
            ยกเลิก
          </Button>
          <Button
            id="submitAddstaff"
            size="small"
            primary
            onClick={this.submitPrint}
            className={this.state.button_loading ? "loading" : ""}
          >
            ยืนยันพิมพ์
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        // e.preventDefault()
        // this.handlerSubmit(e)
      },
    },
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(PrintNextPageConfirm));
