/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import InputSearchLease from '../../Lease/InputSearchLease'
import InputSearchCustomer from '../../Customer/InputSearchCustomer';

function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)
        let lease = 0
        if (search.lease != null)
            lease = search.lease
        let url = Settings.baseReportUrl + "/lease/" + lease + "/interest/?" + fromData

        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.customer != null && this.state.search.customer != item.lease.customer.id) {
                continue
            }
            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data

        let title = 'รายงานประวัติการต่อดอก';
        let filename = 'lease-history-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const textU = {
            'text-decoration': 'underline'
        }
        const descriptionWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let lease_g = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (lease_g[item.lease.id] == null) {
                lease_g[item.lease.id] = {
                    items: [],
                    lease: item.lease,
                    lease_number: item.lease.number
                }
            }
            lease_g[item.lease.id].items.push(item)
        }
        let lease_arr = []
        for (let i in lease_g) {
            lease_arr.push(lease_g[i])
        }
        lease_arr.sort(sortByProperty("lease_number"));
        let interest_total_all = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานประวัติการต่อดอก</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>เลขที่ขายฝาก</label>
                                <InputSearchLease placeholder='เลขที่ขายฝาก' search selection options={this.state.leases} value={this.state.search.lease} loading={this.state.loading} name='lease' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>ชื่อลูกค้า</label>
                                <InputSearchCustomer placeholder='ชื่อลูกค้า' search selection options={this.state.customers} value={this.state.search.customer} name='customer' onChange={this.handlerInput} />
                            </Form.Field>
                            <br />
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center>ตั้งแต่วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่ต่อดอก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='2'>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ดอกเบี้ย</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='2'>หมายเหตุ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {lease_arr.map((item, i) => {
                                        let lease = item.lease
                                        let interest_total = 0
                                        return (<Table.Body id={lease.branch.name + "_" + i}>
                                            <Table.Row>
                                                <Table.Cell style={textCenter}>{i + 1}</Table.Cell>
                                                <Table.Cell style={textCenter}>{lease.number}</Table.Cell>
                                                <Table.Cell style={textCenter}>{lease.branch.name}</Table.Cell>
                                                <Table.Cell style={textCenter}>{ }</Table.Cell>
                                                <Table.Cell style={textCenter} colSpan='2'>{lease.customer.name}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(lease.weight)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(lease.total_interest)}</Table.Cell>
                                                <Table.Cell style={textCenter, descriptionWidth} colSpan='2'>{lease.description}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell style={textCenter} colSpan='2'>ครั้งที่</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>วันที่จ่าย</Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}>ดอกเบี้ยรับ</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>วันครบกำหนด</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>ต่อดอกถึงวันที่</Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}>จำนวนเดือน</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>สาขาชำระ</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>ช่องทางชำระ</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>หมายเหตุ</Table.HeaderCell>
                                            </Table.Row>
                                            {item.items.map((item2, i) => {
                                                interest_total += Number(item2.total_receive)
                                                interest_total_all += Number(item2.total_receive)
                                                return (<Table.Row>
                                                    <Table.Cell style={textCenter} colSpan='2'>{i + 1}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item2.pay_date)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item2.total_receive)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item2.lease.end_date)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item2.interest_date)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{item2.lease.month}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{item2.ledger.branch.name}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{item2.payment}</Table.Cell>
                                                    <Table.Cell style={textLeft, descriptionWidth}>{item2.description}</Table.Cell>
                                                </Table.Row>)
                                            })}
                                            <Table.Row>
                                                <Table.Cell style={textRight} colSpan='3'><b><u>รวม</u></b></Table.Cell>
                                                <Table.Cell style={textRight}><b><u>{Utility.priceFormat(interest_total)}</u></b></Table.Cell>
                                                <Table.Cell style={textRight} colSpan='5'></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='3'><b><u>รวมทั้งหมด</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.priceFormat(interest_total_all)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight} colSpan='5'></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)