/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Button,
    Modal,
    Table,
    Dimmer,
    Loader,
    Pagination,
    Form,
    Dropdown,
    Input,
    Icon,
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import QrcodePrintPreview from '../../QrcodePrintPreview'

class PrintQrcodeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadder: false,
            items: [{
                product_name: '',
                product_code: '',
                qrcode: '',
            }],
            print_qrcode: false,
            print_qrcode_items: [],
            page: 1,
            totalPages: 1,
            limit: 10,
            row: 10,
            count: 0,
            search: {
                branch_id: 0,
                category_id: 0,
                product_types_id: 0,
                product_id: 0,
                type_sale: 0,
                weight_b: 0,
                weight: '',
            },
            s: '',
        }

        this.onClickPrintItem = this.onClickPrintItem.bind(this)
        this.onClickPrintAll = this.onClickPrintAll.bind(this)
        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    }

    componentDidMount() {
        var self = this;
        this.setState({
            loader: true
        });
        var categories = Utility.getFetch(Settings.baseUrl + '/categories/');
        var product_types = Utility.getFetch(Settings.baseUrl + '/product_types/');
        var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
        Promise.all([categories, product_types, branches]).then((values) => {
            this.categories = values[0];
            this.product_types = values[1];
            let cat = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }];
            for (let i in values[0]) {
                cat.push({
                    key: values[0][i].id,
                    value: values[0][i].id,
                    text: values[0][i].name,
                });
            }
            let type = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }];
            for (let i in values[1]) {
                type.push({
                    key: values[1][i].id,
                    value: values[1][i].id,
                    text: values[1][i].name,
                });
            }

            let products_code = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }];

            let products = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }];

            let type_sales = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }]
            type_sales = type_sales.concat(Settings.type_sale)
            let weights = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }]
            weights = weights.concat(Settings.weight)

            this.branches = values[2];
            let branches = [{
                value: 0,
                key: 0,
                text: '- ทั้งหมด -'
            }]
            for (let i in this.branches) {
                if (this.branches[i].is_enabled == 1)
                    branches.push({
                        value: this.branches[i].id,
                        key: this.branches[i].id,
                        text: this.branches[i].name
                    });
            }

            this.setState({
                categories: cat,
                product_types: type,
                loader_active: false,
                products: products,
                products_code: products_code,
                type_sales: type_sales,
                weights: weights,
                branches: branches,
            });
        });
    }

    onClickPrintAll(e) {

        const { items, loadder } = this.state
        const print_qrcode_items = []

        items.map((item, i) => {
            print_qrcode_items.push({
                qrcode: item.qrcode,
                text: item.product_name,
                weight: item.weight
            })
        })

        this.setState({
            print_qrcode: true,
            print_qrcode_items
        })
    }
    handlerSearch(event, v) {
        if (v.name) {
            if (v.name == 'weight') {
                v.value = v.value.toString().replace(/,/g, '')
                if (isNaN(Number(v.value)))
                    return;
                if (v.value.split('.')[0].length > 6)
                    return;
                if (v.value.toString().split(".")[1] != undefined)
                    if (v.value.toString().split(".")[1].length > 3)
                        return
            }
            let search = this.state.search;
            if (v.name == 'weight') {
                search.weight_b = 0
            }
            search[v.name] = v.value;
            this.setState({
                search: search
            });
            // this.resetProduct(search)
            if (v.name == 'category_id') {
                this.resetCategory(v.value);
            }
            return;
        }
    }

    handlerSubmitSearch(e) {
        this.setState({
            loader: true
        });
        let search = this.state.search;
        for (let i in search) {
            if (search[i] == 0) {
                if (i != 'weight')
                    delete search[i]
            }
        }
        if (search['weight_b'] == 0) {
            if (search['weight'] != 0)
                delete search['weight']
        }
        if (search['weight'] == '')
            delete search['weight']
        let search2 = {
            branch_id: search['branch_id'] ? search['branch_id'] : 0,
            category_id: search['category_id'] ? search['category_id'] : 0,
            product_types_id: search['product_types_id'] ? search['product_types_id'] : 0,
            pattern_id: search['pattern_id'] ? search['pattern_id'] : 0,
            product_id: search['product_id'] ? search['product_id'] : 0,
            type_sale: search['type_sale'] ? search['type_sale'] : 0,
            weight_b: search['weight_b'] ? search['weight_b'] : 0,
            weight: search['weight'] ? search['weight'] : '',
        }
        let str = Utility.jsonToQueryString(search);
        this.setState({ search: search2, s: str })
        this.loadQrcode(1, this.state.row, str)
    }

    resetProduct(text) {
        let search = this.state.search
        if (text != '') {
            this.setState({
                isLoading: true
            });
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                let products = [{
                    key: 0,
                    value: 0,
                    text: '-ทั้งหมด-',
                }];
                let products_code = [{
                    key: 0,
                    value: 0,
                    text: '-ทั้งหมด-',
                }];
                let s = {}
                if (search.category_id != 0)
                    s.category_id = search.category_id
                if (search.type_sale != 0)
                    s.type_sale = search.type_sale
                if (search.product_types_id != 0)
                    s.product_types_id = search.product_types_id
                if (search.weight != '')
                    s.weight = search.weight
                s.text = text
                s.limited = 10
                var p = Utility.getFetch(Settings.baseUrl + '/products_no_join/?' + Utility.jsonToQueryString(s));
                Promise.all([p]).then((values) => {
                    let p = values[0].product
                    for (let i in p) {
                        products_code.push({
                            key: p[i].id,
                            value: p[i].id,
                            text: p[i].code,
                        });
                        products.push({
                            key: p[i].id,
                            value: p[i].id,
                            text: p[i].name,
                        });
                    }
                    if (p.filter(p => p.id == this.state.search.product_id).length == 0) {
                        let ss = this.state.search
                        ss.product_id = 0
                        this.setState({
                            search: ss
                        })
                    }
                    this.setState({
                        products: products,
                        products_code: products_code,
                        isLoading: false,
                    })
                })
            }, 1600)
        } else {
            let products = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }];
            let products_code = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }];
            this.setState({
                products: products,
                products_code: products_code,
            })
        }
    }

    resetCategory(category_id) {
        let type = [{
            key: 0,
            value: 0,
            text: '-ทั้งหมด-',
        }];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_types: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_types: type });
        }
    }

    resetForm() {
        let search = {
            branch_id: 0,
            category_id: 0,
            product_types_id: 0,
            product_id: 0,
            type_sale: 0,
            weight_b: 0,
            weight: '',
        }
        this.setState({
            search: search,
        })
        // this.resetProduct(search)
    }

    onClickPrintItem(e, { item }) {

        const print_qrcode_items = []
        print_qrcode_items.push({
            qrcode: item.qrcode,
            text: item.product_name,
            weight: item.weight
        })
        this.setState({
            print_qrcode: true,
            print_qrcode_items
        })
    }

    async loadQrcode(page, limit, str) {
        this.setState({ loadder: true })
        str = str != undefined ? str : this.state.s
        const url = Settings.baseUrl + `/qrcode/search/?page=` + page + `&page_size=` + limit + `&` + str
        const req = await Utility.getAsync(url)
        this.setState({
            items: req.data.count == 0 ?
                [{
                    product_name: '',
                    product_code: '',
                    qrcode: '',
                }]
                : req.data.results,
            count: req.data.count,
            loadder: false,
            totalPages: req.data.next == null && req.data.previous == null ? 0 : Math.ceil(req.data.count / limit),
        })
    }

    handlePaginationChange(e, { activePage }) {

        this.setState({ page: activePage, loadder: true })
        this.loadQrcode(activePage, this.state.row)
        // this.componentDidMount()
    }

    render() {
        var stateOptions = [{ key: '10', value: 10, text: '10' }, { key: '20', value: 20, text: '20' }, { key: '50', value: 50, text: '50' }, { key: '100', value: 100, text: '100' }]
        const { items, loadder } = this.state
        return (<div>
            <Modal open={true} onClose={this.close}  /*dimmer='blurring'*/>
                <Button
                    id='btnCloseModalImEx'
                    circular
                    icon='close'
                    basic
                    floated='right'
                    name=''
                    onClick={this.props.onClose} />
                <Modal.Header>ค้นหา QRCode</Modal.Header>
                <Modal.Content className='scrolling ' style={{ 'min-height': '400px' }}>
                    <Form size='small'>
                        <Form.Group>
                            <Form.Field width={4}>
                                <label>สาขา</label>
                                <Dropdown
                                    id='searchproductgroup'
                                    placeholder='สาขา'
                                    search selection
                                    defaultValue={this.state.search.branch_id}
                                    value={this.state.search.branch_id}
                                    options={this.state.branches}
                                    onChange={this.handlerSearch}
                                    name='branch_id'
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>กลุ่มสินค้า</label>
                                <Dropdown
                                    id='searchproductgroup'
                                    placeholder='กลุ่มสินค้า'
                                    search selection
                                    defaultValue={this.state.search.category_id}
                                    value={this.state.search.category_id}
                                    options={this.state.categories}
                                    onChange={this.handlerSearch}
                                    name='category_id'
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>ประเภทสินค้า</label>
                                <Dropdown
                                    id='searchproducttype'
                                    placeholder='ประเภทสินค้า'
                                    search selection
                                    defaultValue={this.state.search.product_types_id}
                                    value={this.state.search.product_types_id}
                                    options={this.state.product_types}
                                    onChange={this.handlerSearch}
                                    name='product_types_id'
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>ประเภทงานขาย</label>
                                <Dropdown
                                    id='searchproductsell'
                                    placeholder='ประเภทงานขาย'
                                    search selection
                                    defaultValue={this.state.search.type_sale}
                                    value={this.state.search.type_sale}
                                    options={this.state.type_sales}
                                    onChange={this.handlerSearch}
                                    name='type_sale'
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group >
                            <Form.Field width={4}>
                                <label>น้ำหนัก บาท</label>
                                <Dropdown id='searchproductweightb'
                                    placeholder='น้ำหนัก บาท'
                                    search selection
                                    defaultValue={this.state.search.weight_b}
                                    value={this.state.search.weight_b}
                                    options={this.state.weights}
                                    onChange={(e, v) => {
                                        let search = this.state.search;
                                        search.weight = (parseFloat(v.value) * 15.2).toFixed(3);
                                        search.weight_b = v.value
                                        if (v.value == 0) {
                                            search.weight = ''
                                        }
                                        // this.resetProduct(search)
                                        this.setState({
                                            search: search
                                        })
                                    }}
                                    name='weight_b'
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>น้ำหนัก กรัม</label>
                                <Input
                                    id='searchproductweightg'
                                    placeholder='น้ำหนัก กรัม'
                                    search selection
                                    value={this.state.search.weight ? this.state.search.weight : ''}
                                    onChange={this.handlerSearch}
                                    name='weight'
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>รหัสสินค้า</label>
                                <Dropdown
                                    id='searchproductid'
                                    placeholder='รหัสสินค้า'
                                    search selection
                                    defaultValue={this.state.search.product_id}
                                    value={this.state.search.product_id}
                                    options={this.state.products_code}
                                    onChange={this.handlerSearch}
                                    name='product_id'
                                    onSearchChange={(e, v) => {
                                        this.resetProduct(v.searchQuery)
                                    }}
                                    loading={this.state.isLoading}
                                    isFetching={this.state.isLoading}
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>ชื่อสินค้า</label>
                                <Dropdown
                                    id='searchproductname'
                                    placeholder='ชื่อสินค้า'
                                    search selection
                                    defaultValue={this.state.search.product_id}
                                    value={this.state.search.product_id}
                                    options={this.state.products}
                                    onChange={this.handlerSearch}
                                    name='product_id'
                                    onSearchChange={(e, v) => {
                                        this.resetProduct(v.searchQuery)
                                    }}
                                    loading={this.state.isLoading}
                                    isFetching={this.state.isLoading}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group className='text-right'>
                            <Form.Field width={16}>
                                <Button id='searchproduct' size='small' onClick={this.handlerSubmitSearch} type='button' disabled={this.state.loadder} loading={this.state.loadder}><Icon name='search' />  ค้นหา</Button>
                                <Button id='searchallproduct' size='small' onClick={(e) => {
                                    this.resetForm(e);
                                }} type='button' >รีเซ็ต</Button>
                            </Form.Field>
                        </Form.Group>

                    </Form>
                    <div className='segment'>
                        <Dimmer active={loadder} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <p>ผลการค้นหา {this.state.count} รายการ</p>
                        <Table size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>รหัสสินค้า</Table.HeaderCell>
                                    <Table.HeaderCell>ชื่อสินค้า</Table.HeaderCell>
                                    <Table.HeaderCell>QR Code</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {items.map((item, i) => <Table.Row>
                                    <Table.Cell>{item.product_code}</Table.Cell>
                                    <Table.Cell>{item.product_name}</Table.Cell>
                                    <Table.Cell>
                                        <p>{item.qrcode}</p></Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {item.qrcode != '' ?
                                            <Button item={item} primary icon='print' size='small' labelPosition='right' content='พิมพ์' onClick={this.onClickPrintItem} />
                                            : ''}
                                    </Table.Cell>
                                </Table.Row>)}
                            </Table.Body>
                        </Table>

                    </div>
                    {this.state.print_qrcode && <QrcodePrintPreview items={this.state.print_qrcode_items} onClose={(e) => this.setState({ print_qrcode: false })} />}
                </Modal.Content>
                <Modal.Actions>
                    <Form size='small'>

                        <Form.Field width={16} >
                            <b>แสดง </b>
                            <Dropdown
                                size='mini'
                                style={{ padding: '8px 13px', background: '#fff', border: 'solid 2px #D3D3D3', borderRadius: '5px' }}
                                floated='right'
                                value={this.state.row}
                                options={stateOptions}
                                onChange={(e, data) => {
                                    this.setState({ row: data.value, loadder: true });
                                    this.loadQrcode(1, data.value)
                                    this.setState({ activePage: 1 })
                                }}
                            />
                            <b> แถว </b>
                            {this.state.totalPages > 1 &&
                                <Pagination
                                    floated='left'
                                    size='mini'
                                    activePage={this.state.page}
                                    onPageChange={this.handlePaginationChange.bind(this)}
                                    totalPages={this.state.totalPages}
                                />

                            }

                            <Button floated='right' id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ยกเลิก</Button>
                            <Button floated='right' primary icon='print' size='small' labelPosition='right' disabled={this.state.count == 0} content='พิมพ์ทั้งหมด' onClick={this.onClickPrintAll} />


                        </Form.Field>

                    </Form>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default PrintQrcodeModal;