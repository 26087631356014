/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';


class ImportProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields([],[]),
            is_loading: false
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let id = []
        let run = ()=>{
            let data = data_list[position]
            let url_search = Settings.baseUrl + '/lease/?number='+data.lease
            Utility.get(url_search,(e,res)=>{
                if(res[0].status == 4 && id.includes(res[0].id) == false)
                    id.push(res[0].id)
                if(res.length>0){
                    let url = Settings.baseUrl + '/lease/'+res[0].id+'/product/'
                    data['lease'] = res[0].id
                    Utility.post(url,data,(e,res)=>{
                        position++;
                        if(position<data_list.length){
                            run()
                        }
                        else {
                            for(let i in id){
                                const url = Settings.baseUrl + "/lease/" + id[i] + "/stock/?action=in"
                                Utility.getAuth(url, () => {
                                })
                            }
                            this.props.onUpdate()
                        }
                    })
                }else{
                    position++;
                        if(position<data_list.length){
                            run()
                        }
                        else{
                            this.props.onUpdate()
                        }
                }
        })
        }
        run()
    }

    setData(data){

    }

    setFields(branch_option,category_option){
        return [
            {
                name: 'lease',
                title: '*เลขที่ขายฝาก',
                type: 'string',
                required: true,
            },
            {
                name: 'category',
                title: '*หมวดสินค้า',
                type: 'string',
                required: true,
                options: category_option
            },
            {
                name: 'product_name',
                title: '*ชื่อสินค้า',
                type: 'string',
                required: true,
            },
            {
                name: 'weight',
                title: '*น้ำหนักรวม(ก.)',
                required: true,
                type: Number
            },
            // {
            //     name: 'amount',
            //     title: '*จำนวน',
            //     required: true,
            //     type: Number
            // }
        ]
    }

  componentDidMount() {

    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var categories = Utility.getFetch(Settings.baseUrl + '/categories/');
    Promise.all([branches, categories]).then((values) => {

      let branch_options = [];
      for (let i in values[0]) {
        branch_options.push({
          key: values[0][i].id,
          value: values[0][i].id,
          text: values[0][i].name,
        });
      }
      let category_options = [];
      for (let i in values[1]) {
        category_options.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        });
      }
      

      this.setState({
          fields: this.setFields(branch_options,category_options)
      })
    });
  }

    render() {

        return (
            <div>
                <ImportCSV
                    name='LeaseProduct'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportProduct