/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Input, Modal, Table, Checkbox
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            is_all: true,
            is_sort: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                day: 45
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');

        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []

            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
    }

    resetForm(e) {

        this.setState({
            is_all: true,
            is_sort: true,
            search: {
                branch: this.props.auth.branch.id,
                day: 45
            },
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.status_in = '1,2'
        if (search.day == '')
            search.due_date = Utility.formatDate2(moment())
        else
            search.due_date = Utility.formatDate2(moment().add(-search.day, 'day'))

        if (!this.state.is_sort)
            search.sort = 'number'
        else
            search.sort = 'in_date'

        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/lease_report_2/?" + fromData

        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {
                // this.setFieldValue(d)

                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.lease, d.lease_product, d.customer)
                })
            }
        })
    }

    setFieldValue(_items, lease_products, customers) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            let lease_product = []
            for (let j = 0; j < lease_products.length; j++) {
                if (lease_products[j].lease == item.id) {
                    lease_product.push(lease_products[j])
                }
            }
            const customer = customers.find(k => k.id === item.customer)

            item['customer'] = customer
            item['lease_product'] = lease_product

            for (let [index, product] of item.lease_product.entries()) {
                if (index === 0) {
                    items.push({
                        'detail': item,
                        'products': product,
                        'head': true,
                        'number': Number(i) + 1,
                    })
                }
                else {
                    items.push({
                        'detail': item,
                        'products': product,
                        'head': false,
                        'number': Number(i) + 1,
                    })
                }
            }
        }

        return items
    }
    handlerInput(e, v) {
        if (isNaN(Number(v.value))) {
        } else {
            v.value = parseInt(v.value)
            if (isNaN(parseInt(v.value))) {
                v.value = 1
            }
            if (v.value < 1) {
                v.value = 1
            }
            let search = this.state.search
            search[v.name] = v.value
            this.setState({
                search: search
            })
        }
    }
    render() {
        let data = this.props.data

        let title = 'รายงานเกินกำหนด';
        let filename = 'lease-overdue';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const textU = {
            'text-decoration': 'underline'
        }

        const descriptionWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }


        let weight = 0
        let amount = 0
        let total_interest = 0
        let today = new Date()
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานเกินกำหนด</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field>
                                <label>เกินกำหนดมากกว่า (วัน)</label>
                                <Input
                                    // type='number' 
                                    className='text-right'
                                    value={this.state.search.day} name='day' onChange={this.handlerInput} maxLength={4} />
                                <small style={{ 'color': 'red' }}>*มากกว่า 0</small>
                            </Form.Field>
                            <br />
                            <Checkbox radio label='เรียงตามเลขที่ใบขายฝาก' checked={!this.state.is_sort} onChange={(e, v) => { this.setState({ is_sort: false }) }} />
                            <br />
                            <Checkbox radio label='เรียงตามวันที่' checked={this.state.is_sort} onChange={(e, v) => { this.setState({ is_sort: true }) }} />


                            <br />
                            <br />
                            <Button type='submit' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='date'>ณ วันที่ : {Utility.formatDate(moment())}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันครบกำหนด</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รายการสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนเงิน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เกินกำหนด (วัน)</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>หมายเหตุ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                            if (item.head === true) {
                                                amount += parseInt(item.detail.amount)
                                                total_interest += parseFloat(item.detail.total_interest)
                                                weight += parseFloat(item.detail.weight)
                                            }
                                            let day = new Date(item.detail.end_date)
                                            day = new Date(day.getFullYear(), day.getMonth(), day.getDate())
                                            let diff_day = (today.getTime() - day.getTime()) / (1000 * 3600 * 24)

                                            if (item.head === true) {
                                                return (<Table.Row>
                                                    <Table.Cell style={textCenter}>{item.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{item.detail.number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.start_date)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(item.detail.end_date)}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{item.detail.customer.name}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{item.products.product_name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.products.weight)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(item.detail.amount)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{diff_day}</Table.Cell>
                                                    <Table.Cell style={textLeft, descriptionWidth}>{item.detail.description}</Table.Cell>
                                                </Table.Row>)
                                            }
                                            else {
                                                return (<Table.Row>
                                                    <Table.Cell colSpan='5' style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}>{item.products.product_name}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.weightFormat(item.products.weight)}</Table.Cell>
                                                </Table.Row>)
                                            }
                                        })}

                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='6'><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='weight'>{Utility.weightFormat(weight)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='amount'>{Utility.priceFormat(amount)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                        </Table.Row>

                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)