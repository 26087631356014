/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class RewardSummaryPrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            summary: {},
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/report/reward/?" + fromData

        this.setState({
            loading: true,
            items: [],
            summary: {},
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                if (d.status) {
                    this.setState({
                        loading: false,
                        items: d.list,
                        summary: d.summary,
                        open: true,
                    })
                } else {
                    this.setState({
                        loading: false,
                    })
                    alert(d.message)
                }

            }
        })

        this.setState({
            // open: true,
            loading: true
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }


    render() {


        let title = 'รายงานสรุปแลกของรางวัล';
        let filename = 'reward-summary';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    disabled={this.state.loading}
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    disabled={this.state.loading}
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button disabled={this.state.loading}>{this.state.loading ? 'กำลังโหลด...' : 'พิมพ์'}</Button>
                            <Button disabled={this.state.loading} type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>No.</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนชิ้นที่แลก (ชิ้น)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาสินค้า (บาท)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าจัดส่งรวม (บาท)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาสินค้า+ค่าจัดส่ง (บาท)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนครั้งที่แลก (ครั้ง)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {this.state.items.map((row, i) => {
                                        return (
                                            <Table.Row >
                                                <Table.Cell style={textCenter}>{Utility.numberFormat(i + 1)}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.product_name}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.total_item}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.total_price}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.total_shipping_fee}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.total_price_shipping_fee}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.total_order}</Table.Cell>
                                            </Table.Row>

                                        );
                                    }
                                    )}
                                    <Table.Row >
                                        <Table.Cell style={textCenter} colSpan='2'><b>รวม</b></Table.Cell>
                                        <Table.Cell style={textRight}><b>{this.state.summary.total_item || 0}</b></Table.Cell>
                                        <Table.Cell style={textRight}><b>{this.state.summary.total_price || 0}</b></Table.Cell>
                                        <Table.Cell style={textRight}><b>{this.state.summary.total_shipping_fee || 0}</b></Table.Cell>
                                        <Table.Cell style={textRight}><b>{this.state.summary.total_price_shipping_fee || 0}</b></Table.Cell>
                                        <Table.Cell style={textRight}><b>{this.state.summary.total_order || 0}</b></Table.Cell>
                                    </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>
                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(RewardSummaryPrintPreview)
