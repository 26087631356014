/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Header, Button, Loader, Dimmer, Icon, Pagination, Input, Dropdown,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import GoldMeltTable from './GoldMeltTable'
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import GoldMeltModal from './GoldMeltModal';
import GoldMeltModalItem from './GoldMeltModal/GoldMeltItemTable';
import StockGold99Log from '../StockGold99Log';
import { connect } from 'react-redux'

class ImportGoldMelt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_detail: '',
      start_date: moment(),
      end_date: moment(),
      is_clear_stock: false,
      is_clear_bill: false,
      table_hiegh: Settings.table_hiegh,
      page: 1,
      totalPages: 1,
      qrstring: '',
      colSortDirs: {
        'number': 'ASC',
      },
      import_bill: false,
      import_log: false,
      counter: 0,
      lot_search: '',
      import_bill_search: '',
      factory_search: 0,
      factory_dropdown_search: [],


    }
    this.columnKey = 'number'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.onClickitem = this.onClickitem.bind(this);
    this.onLogCell = this.onLogCell.bind(this);
    this.loaddata = this.loaddata.bind(this);
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  componentDidMount() {
    this.loaddata(1)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loaddata(activePage)
    // this.componentDidMount()
  }

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]['weight_out'] = item[i].weight_out
      item[i]['weight'] = Utility.weightFormat(item[i].weight)
      item[i]['weight_real'] = Utility.weightFormat(item[i].weight_real)
      item[i]['gold_price'] = Utility.priceFormat(item[i].gold_price)
      item[i]['cost'] = Utility.priceFormat(item[i].cost)
      if (item[i]['bill_import']) {
        item[i]['bill_import']['gold_price'] = Utility.priceFormat(item[i].bill_import.gold_price)
        item[i]['bill_import']['cost_bath'] = Utility.priceFormat(Number(item[i].bill_import.cost))
        item[i]['bill_import']['cost'] = Utility.priceFormat(item[i].bill_import.cost * 0.0656)
        item[i]['bill_import']['weight'] = Utility.weightFormat(item[i].bill_import.weight)
        item[i]['bill_import']['weight_remain'] = Utility.weightFormat(item[i].bill_import.weight_remain)
      }
    }
    return item;
  }

  loaddata(page) {
    this.setState({
      loader_active: true,
    });

    var s = '';
    if (this.state.end_date != null && this.state.end_date != '') {
      s += (s == '' ? '' : '&') + 'end_date=' + Utility.formatDate2(this.state.end_date);
    } else {
      this.setState({ 'end_date': this.state.start_date })
    }
    if (this.state.start_date != null && this.state.start_date != '') {
      s += (s == '' ? '' : '&') + 'start_date=' + Utility.formatDate2(this.state.start_date);
    }

    if (this.state.is_clear_stock) {
      s += (s == '' ? '' : '&') + 'status_stock=N';
    }

    if (this.state.is_clear_bill) {
      s += (s == '' ? '' : '&') + 'status_bill=N';
    }

    this.setState({ qrstring: s })

    var vendors = Utility.getFetch(Settings.baseUrl + '/vendors/?is_enabled=1');
    var invoices = Utility.getFetch(Settings.baseUrl + '/gold_99_list/?page=' + page + '&page_size=' + 10 + '&vendor=' + this.state.factory_search + '&bill=' + this.state.import_bill_search + '&lot=' + this.state.lot_search + '&start_date=' + moment(this.state.start_date).format('YYYY-MM-DD') + '&end_date=' + moment(this.state.end_date).format('YYYY-MM-DD') + '&is_clear_stock=' + this.state.is_clear_stock + '&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi);
    Promise.all([invoices, vendors]).then((values) => {
      this.vendors = values[1];
      let vendors = [];
      let factory_dropdown_search = [];
      vendors.push({
        key: 0,
        value: 0,
        text: '- ทั้งหมด -',
      });
      for (let i in values[1]) {
        vendors.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        });
        factory_dropdown_search.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        });
      }
      var items = this.setFieldValue(values[0].results)
      this.setState({
        vendors: vendors,
        factory_dropdown_search: factory_dropdown_search,
        items: items,
        loader_active: false,
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter: values[0].count
      });
      let elHeight = document.getElementById('table_width')
      if (elHeight)
        this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 400 });
    });
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        start_date: moment(temp_start, 'DD/MM/YYYY'),
        end_date: moment(temp_end, 'DD/MM/YYYY')
      })
    }
  }

  clearFormSearch() {
    this.setState({
      start_date: moment(),
      end_date: moment(),
      is_clear_stock: false,
      is_clear_bill: false,
      lot_search: '',
      import_bill_search: '',
      factory_search: 0,
    })
  }

  onClickitem(e, d, a) {
    let data = this.state.items[d.positon ? d.positon : d];
    e.preventDefault();
    if (['add', 'del'].includes(a)) {
      this.setState({
        import_bill: a == 'add' ? true : false,
        invoice_detail: data,
        bill_number: data,
        modal_action: a == 'add' ? 'add' : 'del'
      });
      if (a == 'del') {
        let statas = false
        do {
          statas = this.promptDel(data)
        }
        while (!statas);
      }
    } else if (['view'].includes(a)) {
      this.setState({
        open_detail: true,
        id: data.bill_import.id,
      })
    }
  }
  promptDel(data) {
    let description = prompt("กรุณาระบุหมายเหตุการยกเลิกบิลนำเข้าทอง 99.99");
    if (description !== '' && description !== null) {
      if (description.length <= 150) {
        if (window.confirm('ยืนยันลบ!')) {
          const url = Settings.baseUrl + '/gold_99_delete/' + data.id + "/?description=" + description;
          Utility.delete(url, (status, data) => {
            if (data.status == '200') {
              alert('บันทึกข้อมูลสำเร็จ!')
              this.loaddata(1)
            }
          });
          return true
        }
      } else if (description.length > 150) {
        alert('กรอกตัวอักษรได้ไม่เกิน150ตัวอักษร')
        return false
      }
    } else if (description == '') {
      alert('กรุณาระบุหมายเหตุการยกเลิกบิลนำเข้าทอง 99.99!!')
      return false
    } else {
      return true
    }
  }
  onLogCell(e, d, a) {
    let data = this.state.items[d.positon ? d.positon : d];
    e.preventDefault();
    this.setState({
      import_log: true,
      invoice_detail: data,
      bill_number: data,
    });
  }

  resetForm() {

  }

  render() {
    let items = this.state.items;
    return (

      <div className='invoice-box'>

        <Form size='small'>
          <Form.Group>
            <Form.Field width={8}>
              <Header floated='left' as='h2'>นำเข้า</Header>
            </Form.Field>
          </Form.Group>
        </Form>

        <Form className='attached fluid' size='small'>
          <Form.Group>
            <Form.Field width={4}>
              <label>เลขที่บิลนำเข้า</label>
              <Input
                id='import_bill_search'
                placeholder='เลขที่บิลนำเข้า'
                onChange={(e) => { this.setState({ import_bill_search: e.target.value }) }}
                value={this.state.import_bill_search}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>ชื่อโรงงาน</label>
              <Dropdown
                id='factory_dropdown_search'
                search
                selection
                options={this.state.vendors}
                defaultValue={this.state.factory_search}
                value={this.state.factory_search}
                onChange={(e, value) => {
                  this.setState({ factory_search: value.value })
                }}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>เลขที่ Lot </label>
              <Input
                id='lot_search'
                placeholder='เลขที่ Lot'
                onChange={(e) => { this.setState({ lot_search: e.target.value }) }}
                value={this.state.lot_search}
              />
            </Form.Field>
            <Form.Field width={3}>
              <label>จากวันที่</label>
              <DatePicker
                id='searchstartdate'
                dateFormat="DD/MM/YYYY"
                selected={this.state.start_date}
                maxDate={this.state.end_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.end_date < date) {
                    this.setState({ end_date: date });
                  }
                  this.setState({ start_date: date });
                }}
              />
            </Form.Field>
            <Form.Field width={3} >
              <label>ถึงวันที่</label>
              <DatePicker
                id='searchenddate'
                dateFormat="DD/MM/YYYY"
                maxDate={moment()}
                minDate={this.state.start_date}
                selected={this.state.end_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  this.setState({ end_date: date });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Checkbox width={3} id='update' label='ยังไม่ปรับปรุงสต็อก' floated='right'
              checked={this.state.is_clear_stock}
              value={this.state.is_clear_stock} onChange={(e, v) => {

                this.setState({
                  is_clear_stock: !this.state.is_clear_stock
                });
              }} />
            {/* <Form.Checkbox width={3} id='clear' label='ยังไม่เคลียร์บิล' checked={this.state.is_clear_bill} value={this.state.is_clear_bill} onChange={(e, v) => {
              this.setState({
                is_clear_bill: !this.state.is_clear_bill
              });
            }} /> */}
            <Form.Field width={16}>
              <Button id='clear_search' floated='right' type='button' size='small' onClick={() => {
                // this.componentDidMount();
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
              <Button id='searchtoday' floated='right' type='button' size='small' onClick={() => {
                this.clearFormSearch();
                setTimeout(() => {
                  this.setState({ page: 1 })
                  this.loaddata(1);
                }, 500)
              }}  ><Icon name='search' /> วันนี้</Button>
              <Button id='search' floated='right' type='button' size='small' onClick={() => {
                this.setState({ page: 1 })
                this.loaddata(1);
              }} ><Icon name='search' /> ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer active={this.state.loader_active} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>}
        <div id="table_width">
          <GoldMeltTable items={items} table_width={this.state.table_width} onActionItemCell={this.onClickitem} onLogCell={this.onLogCell} onSortChange={this.onSortChange} colSortDirs={this.state.colSortDirs} />
          <br />
          <Form size='small'>
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
              <br />
              <Form.Field width={14}>
                <br />
                {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
          {/* {this.state.totalPages>1 && <Pagination
                    activePage={this.state.page}
                    onPageChange={this.handlePaginationChange.bind(this)}
                    totalPages={this.state.totalPages}
                  />} */}
        </div>
        <br />
        <Form size='small' >
          <Form.Group>
            <Form.Field width={16}>
              <br />
              {this.state.import_bill ? <GoldMeltModal
                modal_action={this.state.modal_action}
                vendors={this.state.factory_dropdown_search}
                bill_number={this.state.bill_number}
                invoice_detail={this.state.invoice_detail}
                open={true}
                onClose={() => {
                  this.setState({ import_bill: false });
                  this.loaddata(this.state.page)
                  // this.componentDidMount();
                }}
                onAddInvoice={(d) => {
                  if (d != null) {
                    this.setState({
                      invoice_detail: d,
                      modal_action: 'edit'
                    });
                  }
                }}
              /> : ''}
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.import_log ?
          <GoldMeltModalItem
            onClose={() => {
              this.setState({ import_log: false });
              this.loaddata(this.state.page)
              // this.componentDidMount();
            }}
            table_width={this.state.table_width}
            items={this.state.invoice_detail}></GoldMeltModalItem> : ''}
        {this.state.open_detail ? <StockGold99Log
          id={this.state.id}
          open={true}
          onClose={() => {
            this.setState({ open_detail: false });
          }}
        /> : ''}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(ImportGoldMelt)