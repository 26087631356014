/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import { Dimmer, Loader } from 'semantic-ui-react'
import Utility from '../../Utility';
import Settings from '../../Settings';

class ImportInvoiceItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [
                {
                    name: 'code',
                    title: '*รหัสสินค้า',
                    type: 'string',
                    unique: true,
                    required: true,
                },
                {
                    name: 'amount',
                    title: '*จำนวน',
                    type: Number,
                    unique: true,
                    required: true,
                },
                // {
                //     name: 'weight',
                //     title: '*น.น.ชั่ง(กรัม)',
                //     type: 'string',
                //     unique: true,
                //     required: true,
                // },
                {
                    name: 'cost',
                    title: '*ราคาต้นทุน/ชิ้น',
                    type: 'string',
                    unique: true,
                    required: true,
                },
                {
                    name: 'fee',
                    title: '*ค่าแรง/ชิ้น',
                    type: 'string',
                    unique: true,
                    required: true,
                },
            ],
            is_loading: false,
            is_ready: false
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }
    setFields(product) {
        return [
            {
                name: 'code',
                title: '*รหัสสินค้า',
                type: 'string',
                unique: true,
                required: true,
                options: product
            },
            {
                name: 'amount',
                title: '*จำนวน',
                type: Number,
                unique: true,
                required: true,
            },
            // {
            //     name: 'weight',
            //     title: '*น.น.ชั่ง(กรัม)',
            //     type: Number,
            //     unique: true,
            //     required: true,
            // },
            {
                name: 'cost',
                title: '*ราคาต้นทุน/ชิ้น',
                type: Number,
                unique: true,
                required: true,
            },
            {
                name: 'fee',
                title: '*ค่าแรง/ชิ้น',
                type: Number,
                unique: true,
                required: true,
            },
        ]
    }
    componentDidMount(){

        this.setState({
            loading_title: "Loading...",
            is_loading: true
        })
        var products = Utility.getFetch(Settings.baseUrl + '/products/?is_enabled=1');
        Promise.all([products]).then((values) => {

            let pd = [];
            for (let i in values[0]) {
                pd.push({
                    key: values[0][i].id,
                    value: values[0][i].id,
                    text: values[0][i].code,
                });
            }
            
            
            this.setState({prod:values[0]})
            this.setState({
                fields: this.setFields(pd),
                is_loading: false
            })
        });
    }

    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]
            const prod = Utility.getObject(this.state.prod, data .code);
            const formData = {
              weight: (parseFloat(prod.weight)).toFixed(3),
              weight_total: (parseFloat(prod.weight) * parseFloat(data .amount)).toFixed(3),
              weight_real:(parseFloat(prod.weight) * parseFloat(data .amount)).toFixed(3),
              amount: data .amount,
              cost: parseFloat(data .cost).toFixed(2),
              fee: parseFloat(data .fee).toFixed(2),
              invoice: this.props.id,
              product: data .code,
              old_product:''
            }
            let url = Settings.baseUrl + '/invoice_item/'
            Utility.post(url,formData,(e,res)=>{
                position++;
                if(position<data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })
        }
        run()

    }

    render() {

        return (
            <div>
              
                <ImportCSV
                    name='InvoiceItem'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                    show={0}
                 />
            </div>
        )
    }
}

export default ImportInvoiceItem