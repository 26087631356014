/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { Table, Column, Cell } from 'fixed-data-table';
import ItemsCell from '../ItemsCell'
import OptionItemsCell from '../OptionItemsCell'

import { Icon } from 'semantic-ui-react';

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class IconItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props}>
        {}
        <Icon name={'folder open'} color={data[rowIndex].status == 'O' ? 'green' : 'gray'} />
      </Cell>
    );
  }
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class BillTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      table_width: 100
    }
  }

  componentDidMount() {

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };
  render() {
    const items = this.props.items

    return (
      <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={402}>
          <Column
            width={40}
            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell delete={false} onClickMenu={this.props.onActionItemCell} />}
          />

          <Column

            header={<Cell></Cell>}
            cell={
              <IconItemsCell position='top left' width={30} data={items} field="status_bag" />
            }
            width={30}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='status'
              sortDir={this.props.colSortDirs.status}>
              สถานะ
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} data={items} field="status_bag" />
            }
            width={50}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='branch__name'
              sortDir={this.props.colSortDirs.branch__name}>
              สาขา
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} data={items} field="branche_title" />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='number'
              sortDir={this.props.colSortDirs.number}>
              เลขที่ห่อ
          </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="number" />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='record_date'
              sortDir={this.props.colSortDirs.record_date}>
              วันที่
              </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="record_date_title" />
            }
            width={100}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='record_date'
              sortDir={this.props.colSortDirs.record_date}>
              เวลา
              </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={80} data={items} field="record_time" />
            }
            width={80}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight'
              sortDir={this.props.colSortDirs.weight}>
              น้ำหนักรวม
          </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="weight" textAlign='text-right' />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight_real'
              sortDir={this.props.colSortDirs.weight_real}>
              น้ำหนักชั่งรวม
          </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="weight_real" textAlign='text-right' />
            }
            width={120}
          />
          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={250} data={items} field="description" />
            }
            width={250}
          />
        </Table></div>
    )
  }

}

export default BillTable

