/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Popup,
  Modal,
  Input,
  Grid,
  Radio,
  Pagination,
  Icon,
} from "semantic-ui-react";
import moment from "moment";
import { Table, Column, Cell } from "fixed-data-table";
import Settings from "../../Settings";
import Utility from "../../Utility";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import { lease_modal_edit, lease_modal_add_open, lease_modal_close } from "../../actions";
import MsgInput from "../Error/MsgInput";
import DatePicker from "react-datepicker";
import OptionItemsCellRedeemPoint from "./OptionItemsCellRedeemPoint";
import InputSearchCustomer from '../Customer/InputSearchCustomer';
function collect(props) {
  return { positon: props.positon };
}
class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = this.props.width - 10 + "px";
    var ellipsisStyle = {
      whiteSpace: "nowrap",
      width: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    return (
      <ContextMenuTrigger
        id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}
      >
        <Cell {...this.props} onClick={() => { }}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            trigger={
              <div
                style={ellipsisStyle}
                className={
                  (this.props.textAlign == null ? "" : this.props.textAlign) +
                  " text-cell"
                }
              >
                {field=='point'?Utility.numberFormat(data[rowIndex][field]):data[rowIndex][field]}
              </div>
            }
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class RewardRedeemPoint extends Component {
  constructor(props) {
    super(props);
    this.data_search = null;
    this.state = {
      items: [],
      total_item: 0,
      open: false,
      in_date_start: moment(),
      in_date_end: moment(),
      totalPages: 1,
      page: 1,
      open_modal_cancel: false,
      open_modal_view: false,
      remark: '',
      select_id: '',
      button_class: '',
      detail_cancel: {},
      msg_error: {},
      customers: undefined,
    };
    this.loaddata2 = this.loaddata2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onClickCancelRedeemPoint = this.onClickCancelRedeemPoint.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  loaddata2(page) {
    this.setState({ loader_active: true });
    var redeem_customer_list = Utility.getFetch(Settings.baseUrl + "/redeem_customer_list/?"+this.state.qr+"&page="+page)
    Promise.all([redeem_customer_list]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
    });
  }

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]["created_at"] =
        item[i].created_at == null ? "" : Utility.formatDateTime(item[i].created_at);
      item[i]["updated_at"] =
        item[i].updated_at == null ? "" : Utility.formatDate(item[i].updated_at);
    }
    return item;
  }

  componentDidMount() {
    this.handleSubmit();
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById("client_width");
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleSubmit(e) {
    this.setState({ loader_active: true });
    const { branch } = this.props.auth
    var s = "";
    if (this.state.in_date_end != null && this.state.in_date_end != "") {
      s +=
        (s == "" ? "" : "&") + "end_date=" + Utility.formatDate2(this.state.in_date_end);
    }
    if (this.state.in_date_start != null && this.state.in_date_start != "") {
      s +=
        (s == "" ? "" : "&") +
        "start_date=" +
        Utility.formatDate2(this.state.in_date_start);
    }
    if (branch) {
      s += (s == "" ? "" : "&") + "branch=" + branch.id;
    }
    if (this.state.customers !== undefined && this.state.customers != "") {
      s +=
      (s == "" ? "" : "&") + "customers=" + this.state.customers;
    }
    this.setState({ qr: s });
    var redeem_customer_list = Utility.getFetch(Settings.baseUrl + "/redeem_customer_list/?"+s+"&page=1")
    Promise.all([redeem_customer_list]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
    });

  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage });
    this.loaddata2(activePage);
  }

  clearFormSearch() {
    this.setState({
      in_date_start: moment(),
      in_date_end: moment(),
    });
  }

  resetFormCancel() {
    this.setState({
      remark: '',
      button_class: '',
      msg_error: {},
    });
  }

  async handleClick(e, data) {
    if (data.action == 'cancel') {
      this.setState({ open_modal_cancel: true, select_id: this.state.items[data.positon].id });
    } else if (data.action == 'view') {
      var data_cancel = {
        'updated_at': this.state.items[data.positon].updated_at,
        'remark': this.state.items[data.positon].remark,
        'username': this.state.items[data.positon].username,
      }
      await this.setState({ detail_cancel: data_cancel });
      this.setState({ open_modal_view: true });
    }
  }
  handleInputChange(event, value) {
    setTimeout(() => {
      this.setState({ customers: value.value });
    }, 100);
  }
  onClickCancelRedeemPoint() {
    if (this.state.remark) {
      this.setState({ button_class: 'loading' })
      var data = {
        'remark': this.state.remark
      }
      var cancel_redeem_point = Utility.patchAsync(Settings.baseUrl + "/cancel_redeem_customer/"+this.state.select_id+"/", data)
      Promise.all([cancel_redeem_point]).then((values) => {
        this.setState({ button_class: '' })
        alert('บันทึกข้อมูลสำเร็จ!')
        this.resetFormCancel();
        this.setState({ open_modal_cancel: false });
        this.loaddata2(this.state.page);
      });
    } else {
      this.setState(prevState => ({
        msg_error: {
          ...prevState.msg_error,
          remark: '*กรุณากรอกหมายเหตุ'
        }
      }))
    }
  }

  render() {
    const items = this.state.items;
    const { branch, role } = this.props.auth;
    const { in_date_start, in_date_end, customers } = this.state;
    return (
      <div>
        <Form>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated="left" as="h3">
                Redeem Point
              </Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <Form
          className="attached fluid"
          size="small"
          onSubmit={this.handleSubmit}
          autocomplete="off"
        >
          <Form.Group widths='equal'>
            <Form.Field width={16}>
              <label>ชื่อลูกค้า</label>
              <InputSearchCustomer fluid onChange={this.handleInputChange} name="customer" value={customers} placeholder='ชื่อลูกค้า' />
            </Form.Field>
            <Form.Field width={6}>
              <label>จากวันที่</label>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                autocomplete="off"
                selected={this.state.in_date_start}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.in_date_end < date) {
                    this.setState({
                      in_date_end: date,
                    })
                  }
                  this.setState({
                    in_date_start: date,
                  })
                }}
                name="in_date_start"
              />
            </Form.Field>
            <Form.Field width={6}>
              <label>ถึงวันที่</label>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                autocomplete="off"
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.in_date_start > date) {
                    this.setState({
                      in_date_start: date,
                    })
                  }
                  this.setState({
                    in_date_end: date,
                  })
                }}
                name="in_date_end"
                selected={this.state.in_date_end}
              />
            </Form.Field>
            <Form.Field>
              <label><br /></label>
              <Button
                id="search"
                floated="left"
                type="button"
                size="small"
                onClick={() => {
                  this.handleSubmit();
                  this.setState({ page: 1 });
                }}
              >
                {" "}
                <Icon name="search" /> ค้นหา
              </Button>
              <Button
                id="search"
                floated="left"
                type="button"
                size="small"
                onClick={() => {
                  this.clearFormSearch();
                  setTimeout(() => {
                    this.setState({ page: 1 });
                    this.handleSubmit();
                  }, 500);
                }}
              >
                {" "}
                <Icon name="search" /> วันนี้
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="relative">
          <div id="client_width">
            <div className="relative">
              <Dimmer active={this.state.loader_active} inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
              <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={30}
                width={this.state.table_width}
                height={402}
              >
                <Column
                  header={<Cell></Cell>}
                  cell={
                    <OptionItemsCellRedeemPoint
                      action={"edit"}
                      data={items}
                      onClickMenu={this.handleClick}
                      edit_only={true}
                    />
                  }
                  width={70}
                />
                <Column
                  header={<Cell className="text-center">สาขา</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="branch_name"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">วันเวลา</Cell>}
                  cell={
                    <ItemsCell
                      position="top right"
                      width={120}
                      data={items}
                      field="created_at"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">รหัสลูกค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="customer_code"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">ชื่อลูกค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="customer_name"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">เบอร์มือถือ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="mobile"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">เกมที่ร่วมสนุก</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="redeem_display"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-right">แต้มที่ใช้</Cell>}
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="point"
                      textAlign="text-right"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">สถานะ</Cell>}
                  cell={
                    <ItemsCell
                      position="top right"
                      width={80}
                      data={items}
                      field="status_display"
                      textAlign="text-center"
                    />
                  }
                  width={80}
                />
                <Column
                  header={<Cell className="text-center">ยืนยันจากลูกค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="confirm"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">พนักงานที่สร้างรายการ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="creator"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
              </Table>
            </div>
          </div>
        </div>
        <br />
        <Form size="small">
          <Form.Group>
            <Form.Input
              id="amounttrans"
              label="จำนวน"
              placeholder=""
              className="text-right"
              width={2}
              value={Utility.numberFormat(this.state.counter)}
            />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && (
                <Pagination
                  floated="right"
                  size="tiny"
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />
              )}
            </Form.Field>
          </Form.Group>
        </Form>
        <Modal open={this.state.open_modal_cancel} size="mini">
          <Button
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={(e) => {
              this.setState({ open_modal_cancel: false });
              this.resetFormCancel()
            }}
          />
          <Modal.Header>ยกเลิก Redeem Point</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field error={this.state.msg_error.remark != null}>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.remark} /></label>
                  <Input
                    maxLength={100}
                    fluid
                    onChange={(e) => {
                      this.setState({
                        remark: e.target.value
                      })
                    }}
                    name="remark"
                    placeholder='หมายเหตุ'
                    value={this.state.remark}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id="AddConfirm"
              size='small'
              primary
              onClick={this.onClickCancelRedeemPoint}
              className={this.state.button_class}
            >
              บันทึก
            </Button>
            <Button
              id="AddCancel"
              size='small'
              onClick={(e) => {
                this.setState({ open_modal_cancel: false });
                this.resetFormCancel()
              }}
            >
              ยกเลิก
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.open_modal_view} size="mini">
          <Button
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={(e) => {
              this.setState({ open_modal_view: false });
            }}
          />
          <Modal.Header>รายละเอียดการยกเลิก</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ wordBreak: 'break-all' }}>
              <p>วันที่ยกเลิกรายการ : {this.state.detail_cancel.updated_at}</p>
              <p>สาเหตุ : {this.state.detail_cancel.remark}</p>
              <p>ชื่อพนักงานที่ทำรายการ : {this.state.detail_cancel.username}</p>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    branches: state.branches,
  };
};

export default connect(mapStateToProps)(RewardRedeemPoint);
