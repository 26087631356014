/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Button, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../Utility';

class ProductDetailPrintPreview extends Component {

    render() {
        let data = this.props.data
        let daily_ledger_cash = this.props.daily_ledger.list.length == 0 ? Utility.parseFloat(0) : Utility.parseFloat(this.props.daily_ledger.list[0].cash)
        let title = 'รายงานรับ-จ่าย';
        let filename = 'ledger-' + Utility.formatDate3(data.start_date) + '-to-' + Utility.formatDate3(data.end_date);
        let date_before = new Date(data.start_date)
        date_before.setDate(date_before.getDate() - 1)
        date_before = Utility.formatDate(date_before)
        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let income = 0
        let expenses = 0

        for (let i in this.props.items) {
            let item = this.props.items[i];
            income += parseFloat(Utility.removeCommas(item.income))
            expenses += parseFloat(Utility.removeCommas(item.expenses))
        }
        income = income + daily_ledger_cash
        return (<div>
            <Modal open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Modal.Header id='headerModalLedgerPreview' >Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    <div id='view-print'>
                        <div id='paperA4-portrait'>
                            <Table basic id='table-to-xls' style={divStyle}>
                                <Table.Header>
                                    <Table.Row >
                                        <Table.HeaderCell colSpan='11'><center>{title}</center></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.HeaderCell colSpan='11'><center>ตั้งแต่วันที่ {Utility.formatDate(data.start_date)} ถึง {Utility.formatDate(data.end_date)}</center></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>เวลา</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>เลขที่อ้างอิง</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>เลชที่ใบสำคัญ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>รายการ</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>ชื่อลูกค้า</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>จำนวนรับเงิน</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>จำนวนจ่ายเงิน</Table.HeaderCell>
                                        <Table.HeaderCell style={textCenter}>สถานะ</Table.HeaderCell>
                                        <Table.HeaderCell style={textLeft}>หมายเหตุ</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body id='leadger-preview' >
                                    <Table.Row >
                                        <Table.Cell style={textCenter}>1</Table.Cell>
                                        <Table.Cell style={textCenter}>{date_before}</Table.Cell>
                                        <Table.Cell style={textCenter}>{'00:00'}</Table.Cell>
                                        <Table.Cell style={textCenter}></Table.Cell>
                                        <Table.Cell style={textCenter}></Table.Cell>
                                        <Table.Cell style={textCenter} width={3}>ยกยอดเงินสด</Table.Cell>
                                        <Table.Cell style={textCenter} width={3}></Table.Cell>
                                        <Table.Cell style={textRight}>{Utility.priceFormat(this.props.daily_ledger.list.length == 0 ? 0 : this.props.daily_ledger.list[0].cash)}</Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                        <Table.Cell style={textRight}></Table.Cell>
                                        <Table.Cell style={textLeft, { 'word-break': 'break-word' }} width={3}>{this.props.daily_ledger.list.length == 0 ? '' : this.props.daily_ledger.list[0].description}</Table.Cell>
                                    </Table.Row>
                                    {this.props.items.map((row, i) => <Table.Row key={i}>
                                        <Table.Cell style={textCenter}>{i + 2}</Table.Cell>
                                        <Table.Cell style={textCenter}>{row.date}</Table.Cell>
                                        <Table.Cell style={textCenter}>{row.time}</Table.Cell>
                                        <Table.Cell style={textCenter}>{row.object_number}</Table.Cell>
                                        <Table.Cell style={textCenter}>{row.number}</Table.Cell>
                                        <Table.Cell style={textCenter} width={3}>{row.object_title}</Table.Cell>
                                        <Table.Cell style={textCenter} width={3}>{row.customer_name}</Table.Cell>
                                        <Table.Cell style={textRight}>{row.income}</Table.Cell>
                                        <Table.Cell style={textRight}>{row.expenses}</Table.Cell>
                                        <Table.Cell style={textRight}>{row.bill_status_title}</Table.Cell>
                                        <Table.Cell style={textLeft, { 'word-break': 'break-word' }} width={3}>{row.description}</Table.Cell>
                                    </Table.Row>)}

                                    <Table.Row >
                                        <Table.Cell colSpan='7' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(income)}</b></div></Table.Cell>
                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(expenses)}</b></div></Table.Cell>
                                        <Table.Cell colSpan='2' style={textRight}></Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell colSpan='7' style={textRight}><div style={textU}><b>ยอดคงเหลือ</b></div></Table.Cell>
                                        <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(income - expenses)}</b></div></Table.Cell>
                                        <Table.Cell colSpan='3' style={textRight}></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions id='actionsModalLedgerPreview'>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="ui primary button small"
                        table="table-to-xls"
                        filename={filename}
                        sheet={title}
                        buttonText="Download as XLS" >
                    </ReactHTMLTableToExcel>

                    <Button primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(()=>{
                            mywindow.close();
                        },2000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnPrint' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default ProductDetailPrintPreview;