/*eslint eqeqeq: "off"*/
import Utility from '../Utility'
import Settings from '../Settings'


export const open_modal_lease_product = (is_open) => ({
  type: 'OPEN_MODAL_LEASE_PRODUCT',
  data: {
    name: 'lease_product',
    is_open: is_open
  }
})
export const data_list = (name, data) => ({
  type: 'DATA_LIST',
  data: {
    name: name,
    data: data
  }
})

export const loadBranch = async (dispatch) => {

  const branches = await Utility.getAsync(Settings.baseUrl + '/branches/?is_enabled=1')
  dispatch({
    type: 'BRANCHES',
    data: branches.data
  })
}

export const activations_branch = async (dispatch, data) => {
  const url = Settings.baseUrl + '/notification_system/?branch=' + data.id;
  const rep = await Utility.getAsync(url)
  dispatch({
    type: 'SET_NOTI',
    list: rep.data
  })
  dispatch({
    type: 'ACTIVATIONS_BRANCH',
    data
  })
}

export const get_noti = async (dispatch, branch) => {
  const url = Settings.baseUrl + '/notification_system/?branch=' + branch.id;
  const rep = await Utility.getAsync(url)
  dispatch({
    type: 'SET_NOTI',
    list: rep.data
  })
}

export const auth = async (dispatch) => {
  const r = await Utility.getAsync(Settings.baseUrl + '/user_profile/')
  let branch = ''
  if (r.data.role == 'M') {
    branch = r.data.branch_list[0]
  } else {
    branch = r.data.branch
  }
  if (r.data.role == 'M' || r.data.role == 'A' || r.data.role == 'S') {
    if (branch != undefined) {
      const branches = await Utility.getAsync(Settings.baseUrl + '/branches/?is_enabled=1')
      if (branch['is_enabled'] == 0) {
        branch = branches.data[0]
      }
    }
    let b = JSON.parse(localStorage.getItem("branch")) || branch
    const url = Settings.baseUrl + '/notification_system/?branch=' + b.id;
    const rep = await Utility.getAsync(url)
    dispatch({
      type: 'SET_NOTI',
      list: rep.data
    })
  }
  if (r.data.role == 'U') {
    if (branch['is_enabled'] == 0)
      branch = undefined
  }
  Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
    if(status){
      localStorage.setItem("system_setting", JSON.stringify(data));
    }
  });
  dispatch({
    type: 'SET_AUTH',
    data: {
      is_ready: true,
      is_login: r.status_code == 200,
      staff: r.data.staff,
      branch: JSON.parse(localStorage.getItem("branch")) || branch,
      user: r.data.user,
      role: r.data.role,
      branch_list: r.data.branch_list
    }
  })
}

export const logout = async (dispatch) => {

  const r = await Utility.getAsync(Settings.baseUrl + '/user_profile/')
  dispatch({
    type: 'SET_AUTH',
    data: {
      is_ready: true,
      is_login: r.status_code == 200,
      staff: r.data.staff,
      branch: r.data.branch,
      user: r.data.user,
      role: r.data.role
    }
  })
}

export const loadProductName = (cb) => {

  Utility.get(Settings.baseUrl + '/product_name/?is_enabled=1', (s, r, c) => {
    cb({
      type: 'PRODUCTS_NAME',
      data: r
    })
  });
}

export const loadBills = async (dispatch, querystring) => {
  dispatch({
    type: 'SET_BILL_LIST_LOADING',
    is_loading: true
  })
  const res = await Utility.getAsync(Settings.baseUrl + '/bills/?' + querystring)
  dispatch({
    type: 'SET_BILL_LIST',
    list: res.data,
    filter: querystring
  })
}

export const loadLedger = async (dispatch, querystring, page) => {

  dispatch({
    type: 'LOAD_LEDGER_LIST',
  })
  const res = await Utility.getAsync(Settings.baseUrl + '/ledger/?page=' + page + '&' + querystring)
  const res2 = await Utility.getAsync(Settings.baseUrl + '/ledger_sum/?' + querystring)
  dispatch({
    type: 'SET_LEDGER_LIST',
    list: res.data,
    income: res2.data.income,
    expenses: res2.data.expenses,
    activePage: page,
  })
}

export const loadDailyLedger = async (dispatch, date, branch_id) => {
  let date_before = new Date(date)
  date_before.setDate(date_before.getDate() - 1)
  date_before = Utility.formatDate2(date_before)
  const res = await Utility.getAsync(Settings.baseUrl + "/daily_ledger_balance/?date=" + date_before + "&branch=" + branch_id)
  dispatch({
    type: 'SET_DAILY_LEDGER_LIST',
    list: res.data
  })
}

export const loadLedgerCategory = async (dispatch, querystring) => {
  const res = await Utility.getAsync(Settings.baseUrl + '/ledger_category/?is_enabled=1')
  dispatch({
    type: 'SET_LEDGER_CATEGORY_LIST',
    list: res.data
  })
}

export const lease_modal_edit = async (dispatch, lease_id) => {
  const res = await Utility.getAsync(Settings.baseUrl + `/lease/${lease_id}/`)
  dispatch({
    type: 'LEASE_MODAL_EDIT_OPEN',
    data: res.data
  })
}

export const lease_modal_close = () => {

  return {
    type: 'LEASE_MODAL_CLOSE'
  }
}

export const lease_modal_add_open = () => {

  return {
    type: 'LEASE_MODAL_ADD_OPEN'
  }
}

export const report_email_list = (email_list) => ({
  type: 'SET_REPORT_EMAIL_LIST',
  list: email_list,
})

export const redeem_customer = (data) => ({
  type: 'SET_REDEEM_CUSTOMER',
  data: data,
})

export const clear_redeem_customer = () => {
  return {
    type: 'CLEAR_REDEEM_CUSTOMER'
  }
}