const default_state = {
  list: [],
  link: {
    1: '/stock/bag_old',
    2: '/stock/transfer_stock',
  },
  icon: {
    1: 'box',
    2: 'sign in alternate',
  },
}
const noti_system = (state = default_state, action) => {
  switch (action.type) {
    case 'SET_NOTI':
      if (action.list.length == 0) {
        localStorage.removeItem('noti')
      } else {
        localStorage.setItem('noti', true)
      }
      return {
        ...state,
        list: action.list,
      }
    default:
      return state
  }
}

export default noti_system