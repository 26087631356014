/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';


class ImportProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields([], []),
            is_loading: false
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list) {
        this.setState({
            is_loading: true,
            loading_title: '0/' + (data_list.length)
        })
        var position = 0
        let run = () => {
            let data = data_list[position]
            let url = Settings.baseUrl + '/lease/'
            data['status_stock'] = 'N'
            data['number'] = data['number'].trim()
            data.customer = 1
            Utility.post(url, data, (e, res) => {
                position++;
                if (position < data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })

        }
        run()

    }

    setFields(branch_option, staff_option) {
        return [
            {
                name: 'number',
                title: '*เลขที่ขายฝาก',
                type: 'string',
                required: true,
                length_require:7,
            },
            {
                name: 'bill_date',
                title: '*วันที่ (ดด/วว/ปปปป)',
                type: 'date',
                required: true
            },
            {
                name: 'branch',
                title: '*รหัสสาขา',
                type: 'string',
                required: true,
                options: branch_option,
                fill: (num) => {
                    num = num + ""
                    var s = "000" + num;
                    return s.substr(num.length);
                }
            },
            {
                name: 'staff',
                title: 'รหัสพนักงาน',
                type: 'string',
                options: staff_option
            },
            {
                name: 'citizen_id',
                title: 'เลขประจำตัวประชาชนลูกค้า',
                type: 'string',
            },
            {
                name: 'phone',
                title: 'เบอร์โทรลูกค้า',
                type: 'string',
            },
            {
                name: 'amount',
                title: '*ยอดเงินต้น',
                required: true,
                type: Number
            },
            {
                name: 'weight',
                title: '*น้ำหนักรวม(ก.)',
                required: true,
                type: Number
            },
            {
                name: 'month',
                title: '*จำนวนเดือน',
                required: true,
                type: Number
            },
            {
                name: 'interest',
                title: '*อัตราดอกเบี้ย (%)',
                required: true,
                type: Number
            },
            {
                name: 'total_interest',
                title: '*รวมดอกเบี้ยรับ',
                required: true,
                type: Number
            },
            {
                name: 'start_date',
                title: '*วันที่นำเข้า (ดด/วว/ปปปป)',
                required: true,
                type: 'date',
            },
            {
                name: 'end_date',
                title: '*วันที่ครบกำหนด (ดด/วว/ปปปป)',
                required: true,
                type: 'date',
            },
            {
                name: 'interest_date',
                title: '*วันที่ตั้งต้นคิดดอกเบี้ย (ดด/วว/ปปปป)',
                required: true,
                type: 'date',
            },
            {
                name: 'close_date',
                title: 'วันที่ไถ่คืน (ดด/วว/ปปปป)',
                required: false,
                type: 'date',
            },
            {
                name: 'status',
                title: '*สถานะ',
                required: true,
                type: 'string',
                options: Settings.status_lease_options
            }
        ]
    }

    componentDidMount() {
        var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
        var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/');
        // var lease = Utility.getFetch(Settings.baseUrl + '/lease/');
        Promise.all([branches, staffs]).then((values) => {

            let branch_options = [];
            for (let i in values[0]) {
                branch_options.push({
                    key: values[0][i].id,
                    value: values[0][i].id,
                    text: values[0][i].code,
                });
            }
            let staff_options = [];
            for (let i in values[1]) {
                staff_options.push({
                    key: values[1][i].id,
                    value: values[1][i].id,
                    text: values[1][i].code,
                });
            }
            // let data = []
            // for (let i in values[2]) {
            //     data.push(values[2][i].number);
            // }
            // this.setState({data:data})

            this.setState({
                fields: this.setFields(branch_options, staff_options)
            })
        });
    }

    render() {

        return (
            <div>
                <ImportCSV
                    name='Lease'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                />
            </div>
        )
    }
}

export default ImportProduct