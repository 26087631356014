import {
  combineReducers
} from 'redux'
import modal_open from './modal_open'
import branches from './branches'
import products_name from './products_name'
import auth from './auth'
import bills_list from './bills_list'
import ledger_list from './ledger_list'
import ledger_category from './ledger_category'
import lease_modal from './lease_modal'
import invoice_item_modal from './invoice_item_modal'
import products from './products'
import daily_ledger from './daily_ledger'
import noti_system from './noti_system'
import report_email_list from './report_email_list'
import redeem_customer from './redeem_customer'
export default combineReducers({
  modal_open,
  branches,
  products_name,
  auth,
  bills_list,
  ledger_list,
  ledger_category,
  lease_modal,
  invoice_item_modal,
  products,
  daily_ledger,
  noti_system,
  report_email_list,
  redeem_customer
})