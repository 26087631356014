/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import BillItemModal from './BillItemModal'
import { connect } from 'react-redux'
import { Table, Column, Cell } from 'fixed-data-table';
import { invoice_item_open, invoice_item_set_value, invoice_item_close, invoice_item_add, invoice_item_remove } from '../../../../reducers/invoice_item_modal'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Dropdown,
  Input,
  Modal,
  Grid,
  Popup,
  TextArea,
  Checkbox
} from 'semantic-ui-react';
import BillPrintPreview from './BillPrintPreview'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OptionItemsCell from '../../OptionItemsCell'
import BillQrcodeModal from './BillQrcodeModal'
import ImportInvoiceItem from "../../../Import/ImportInvoiceItem";

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}

class InvoiceDetail extends Component {

  constructor(props) {
    super(props);

    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      vendor: '',
      status_stock: 'N',
      modal_open: this.props.open,
      action_title: 'เพิ่มสินค้า',
      action: 'add',
      invoice_item: [],
      button_print_disabled: true,
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: this.props.action == 'import'
        ? 'เพิ่มรายการทองใหม่เข้าสต๊อก'
        : 'เพิ่มรายการทองใหม่ออกจากสต๊อก',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      invoice_description: '',
      msg_error: {},
      cost: ''
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];

    this.onOpenModal = this
      .onOpenModal
      .bind(this);
    this.onDeleteProduct = this
      .onDeleteProduct
      .bind(this);
  }

  submitSaveInvoice() {
    let msg_error = {}
    if (this.state.branch_id === undefined) {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }

    if ((this.state.vendor === '' || this.state.vendor === 0) && this.props.action == 'import') {
      msg_error['vendor'] = ['กรุณาเลือกโรงงาน/ร้านส่ง']
    }

    if (this.state.cost === '') {
      msg_error['cost'] = ['กรุณาระบุราคาทอง']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    var formData = {
      branch: this.state.branch_id,
      vendor: this.state.vendor,
      cost: this.state.cost.replace(/,/g, ''),
      ref_number: this.state.ref_number,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: this.state.status_bill,
      status_stock: this.state.status_stock,
      kind: this.props.action == 'import'
        ? 'IM'
        : 'EX',
      description: this.state.invoice_description
    };

    this.setState({ button_save_loading: true, msg_error: {} })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoices/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    } else {
      var formData2 = {
        branch: this.state.branch_id,
        vendor: this.state.vendor,
        ref_number: this.state.ref_number,
        // invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.invoice_description
      };

      const url = Settings.baseUrl + '/invoices/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    }
  }

  submitSaveInvoice_no_alert() {
    let msg_error = {}
    if (this.state.branch_id === undefined) {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }

    if ((this.state.vendor === '' || this.state.vendor === 0) && this.props.action == 'import') {
      msg_error['vendor'] = ['กรุณาเลือกโรงงาน/ร้านส่ง']
    }

    if (this.state.cost === '') {
      msg_error['cost'] = ['กรุณาระบุราคาทอง']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    var formData = {
      branch: this.state.branch_id,
      vendor: this.state.vendor,
      cost: this.state.cost.replace(/,/g, ''),
      ref_number: this.state.ref_number,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: this.state.status_bill,
      status_stock: this.state.status_stock,
      kind: this.props.action == 'import'
        ? 'IM'
        : 'EX',
      description: this.state.invoice_description
    };

    this.setState({ button_save_loading: true, msg_error: {} })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoices/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    } else {
      var formData2 = {
        branch: this.state.branch_id,
        vendor: this.state.vendor,
        ref_number: this.state.ref_number,
        // invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.invoice_description
      };

      const url = Settings.baseUrl + '/invoices/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    }
  }


  loadInvoiceItem(reload) {

    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/invoices/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise
      .all([invoices, items])
      .then((values) => {
        // set invoice detail
        let item = values[0];
        let branch_option = Utility.getOptions(this.props.branches)
        for (let i in branch_option) {
          if (branch_option[i].value === item.branch) {
            this.setState({ branch_name: branch_option[i].text })
          }
        }
        let status_bill = this.state.status_bill
        let invoice_description = this.state.invoice_description
        this.setState({
          number: item.number,
          branch_id: item.branch,
          vendor: item.vendor,
          invoice_date: moment(item.invoice_date),
          cost: Utility.priceFormat(item.cost),
          status_stock: item.status_stock,
          status_bill: item.status_bill,
          ref_number: item.ref_number,
          invoice_description: item.description,
          invoice_disabled: true,
          invoice_item: this.setFieldValue(values[1]),
          button_print_disabled: false,
          button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y' || item.status_stock == 'D',
          button_save_title: 'บันทึก',
          invoice_add_product_disabled: item.status_stock == 'Y' || item.status_stock == 'D',
          invoice_title: <span>{this.props.action == 'import'
            ? <span>แก้ไขรายการทองใหม่เข้าสต๊อก
                </span>
            : <span>แก้ไขรายการทองใหม่ออกจากสต๊อก</span>
          } </span>,
          loader: false,
          button_update_stoick_loading: item.status_stock == 'D' ? true : false,
        });
        if (reload == true) {
          this.setState({
            status_bill: status_bill,
            invoice_description: invoice_description,
          });
        }
        if (item.status_stock == 'D') {
          setTimeout(() => { this.loadInvoiceItem(true) }, 5000)
        }
      });
  }

  setFieldValue(item) {
    let invoice_weight_total = 0;
    let invoice_product_total = 0;
    let invoice_itme_total = item.length;
    // let invoice_profit_total = 0;
    let invoice_fee_total = 0
    let invoice_cost_total = 0
    for (let i = 0; i < item.length; i++) {
      item[i]['status_stock_title'] = Settings.status_stock[item[i].status_stock]
      item[i]['product_code'] = item[i].product.code
      item[i]['product_name'] = item[i].product.name
      item[i]['category_title'] = item[i].product.category.name
      item[i]['kind_title'] = item[i].product.kind.name
      item[i]['weight'] = Utility.weightFormat(item[i].product.weight)
      item[i]['amount'] = Utility.numberFormat(item[i].amount.toString())
      item[i]['weight_total'] = Utility.weightFormat(item[i].weight_total)
      item[i]['weight_real'] = Utility.weightFormat(item[i].weight_real)
      item[i]['fee'] = Utility.priceFormat(item[i].fee)

      if (item[i].product.type_sale == 2) {
        // invoice_profit_total += parseFloat(item[i].product.cost) * parseFloat(item[i].amount);
        item[i]['type_sale_title'] = 'งานชิ้น';
        item[i]['cost'] = Utility.priceFormat(item[i].cost);
        item[i]['price_tag'] = Utility.priceFormat(parseFloat(item[i].product.price_tag));
      } else {
        item[i]['type_sale_title'] = 'งานชั่ง';
        item[i]['cost'] = Utility.priceFormat(item[i].cost);
        item[i]['price_tag'] = Utility.priceFormat(0.00);
      }

      invoice_weight_total += parseFloat(Utility.removeCommas(item[i].weight_total));
      invoice_product_total += parseInt(Utility.removeCommas(item[i].amount));

      invoice_fee_total += parseFloat((Utility.removeCommas(item[i].fee)) * Utility.removeCommas(item[i].amount))
      invoice_cost_total += (parseFloat(Utility.removeCommas(item[i].cost) * Utility.removeCommas(item[i].amount))) + (parseFloat(Utility.removeCommas(item[i].fee) * Utility.removeCommas(item[i].amount)))
    }
    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      invoice_product_total: Utility.numberFormat(invoice_product_total),
      invoice_itme_total: Utility.numberFormat(invoice_itme_total),
      // invoice_profit_total: Utility.priceFormat(invoice_profit_total),
      invoice_fee_total: Utility.priceFormat(invoice_fee_total),
      invoice_cost_total: Utility.priceFormat(invoice_cost_total)
    });
    return item;
  }

  resetFormProduct() {
    this.setState({
      product_id: '',
      product_name: '',
      product_weight: '',
      product_weight_total: '',
      product_weight_real: '',
      product_amount: '',
      action_title: 'เพิ่มสินค้า',
      action: 'add',
      msg_error: {}
    });
  }


  onOpenModal() {
    if (this.props.modal_action == "edit") {
      this.loadInvoiceItem();
    } else {
      this.setState({ button_save_disabled: false })
    }
  }

  onClosenModal() {
    this.setState({
      number: '',
      branch_id: '',
      vendor: '',
      status_stock: 'N',
      ref_number: '',
      invoice_disabled: false,
      invoice_item: [],
      button_update_stoick_disabled: true,
      button_save_title: 'สร้าง',
      button_save_disabled: true,
      invoice_add_product_disabled: true,
      product_id: '',
      product_name: '',
      product_weight: '',
      product_weight_total: '',
      product_weight_real: '',
      product_amount: '',
      button_update_stoick_loading: false
    });
  }

  onStatusModal() {
    if (this.modal_status != this.props.open) {
      if (this.props.open)
        this.onOpenModal()
      else
        this.onClosenModal()
    }
    this.modal_status = this.props.open;
  }

  onDeleteProduct(e, d) {
    let data = this.state.invoice_item[d.positon];
    if (data.status_stock == 'N') {
      if (!window.confirm('ยืนยันลบ'))
        return
      const url = Settings.baseUrl + '/invoice_item/' + data.id + '/';
      Utility.delete(url, (status, data) => {

        this.setState({ button_product_loading: false })
        this.resetFormProduct();
        this.loadInvoiceItem();
      });
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  submitUpdateStock() {
    const { auth } = this.props
    if (!(auth.role == 'A' || auth.role == 'S')) {
      alert('คุณไม่มีสิทธิ์อัพเดทสต๊อกได้')
      return
    }
    this.setState({ button_update_stoick_loading: true })
    const url = Settings.baseUrl + '/invoices/' + this.props.invoice_detail.id + '/stock/';
    Utility.get(url, (status, data) => {

      if (status) {
        this
          .props
          .onAddInvoice();
        this.loadInvoiceItem();
        alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
      } else {
        alert(data.error)
      }

      this.setState({
        button_update_stoick_loading: false
      })
    });
  }


  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)
    this.setState({ loader_active: true, btn_stock: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_w')
    this.setState({ table_width: elHeight.clientWidth });
  };


  async handleSubmitItem() {

    const { items, invoice } = this.props.invoice_item_modal
    const { products, auth } = this.props

    if (items.length == 0) {
      alert('กรุณาลงรายการสินค้า')
      return
    }
    if (!(auth.role == 'A' || auth.role == 'S' || auth.role == 'M')) {
      alert('คุณไม่มีสิทธิ์บันทึกรายการ')
      return
    }
    var is_error = false
    items.map((item, i) => {
      this.props.onInputError(i, 'product_error', '')
      this.props.onInputError(i, 'amount_error', '')
      // this.props.onInputError(i, 'weight_real_error', '')
      this.props.onInputError(i, 'cost_error', '')
      this.props.onInputError(i, 'fee_error', '')


      if (item.product == '') {
        is_error = true
        this.props.onInputError(i, 'product_error', 'กรุณาเลือกสินค้า')

      } else {
        if (item.amount == '') {
          is_error = true
          this.props.onInputError(i, 'amount_error', 'กรุณาระบุจำนวน')
        }
        if (item.amount > 2147483647) {
          is_error = true
          this.props.onInputError(i, 'amount_error', 'ไม่สามรถกรอกค่ามากกว่า 2,147,483,647 ได้')
        }
        // if (item.weight_real == '') {
        //   is_error = true
        //   this.props.onInputError(i, 'weight_real_error', 'กรุณาระบุน้ำหนักชั่ง')
        // }
        if (item.cost == '') {
          is_error = true
          this.props.onInputError(i, 'cost_error', 'กรุณาระบุต้นทุน')
        }
        if (item.fee == '') {
          is_error = true
          this.props.onInputError(i, 'fee_error', 'กรุณาระบุค่าแรง')
        }
        // if (item.weight_real.toString().split('.')[0].length > 20) {
        //   is_error = true
        //   this.props.onInputError(i, 'weight_real_error', 'ไม่สามารถกรอกตัวเลขมากกว่า 20 หลักได้')
        // }
        if (item.cost.toString().split('.')[0].length > 20) {
          is_error = true
          this.props.onInputError(i, 'cost_error', 'ไม่สามารถกรอกตัวเลขมากกว่า 20 หลักได้')
        }
        if (item.fee.toString().split('.')[0].length > 20) {
          is_error = true
          this.props.onInputError(i, 'fee_error', 'ไม่สามารถกรอกตัวเลขมากกว่า 20 หลักได้')
        }
      }
    })

    if (is_error) return
    let list = []
    for (let i in items) {
      const o = list.find(k => k.product == items[i].product && k.cost == items[i].cost && k.fee == items[i].fee)
      if (o === undefined) {
        list.push(items[i])
      } else {
        const index = list.indexOf(o)
        list[index].amount=parseInt(list[index].amount) +parseInt(items[i].amount)
        list[index].weight_real=parseFloat(list[index].weight_real)+parseFloat(items[i].weight_real)
      }
    }
    await list.map(async (item, i) => {

      const url = Settings.baseUrl + `/invoice_item/`

      const prod = Utility.getObject(products.items, item.product);
      const formData = {
        weight: (parseFloat(prod.weight)).toFixed(3),
        weight_total: (parseFloat(prod.weight) * parseFloat(item.amount)).toFixed(3),
        weight_real:(parseFloat(prod.weight) * parseFloat(item.amount)).toFixed(3),
        amount: item.amount,
        cost: parseFloat(item.cost).toFixed(2),
        fee: parseFloat(item.fee).toFixed(2),
        invoice: this.props.invoice_detail.id,
        product: item.product,
        old_product: ''
      }
      await Utility.postAsync(url, formData)
      this.loadInvoiceItem(true)
    })

    this.props.onBillItemModalClose()

  }


  render() {
    this.onStatusModal()
    let branch_option = Utility.getOptions(this.props.branches)
    let items = this.state.invoice_item;
    const { is_open } = this.props.invoice_item_modal
    const { auth } = this.props
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button
          id='btnCloseModalImEx'
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={
            this.props.onClose
          } />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.invoice_title}
              {this.state.number && <span>[บิลเลขที่ {this.state.number}]</span>}
              <Header.Subheader>
                <span><Icon
                  id='iconimport'
                  name={this.state.status_stock == 'N' || this.state.status_stock == 'D' ? 'warning circle' : 'check circle'}
                  color={this.state.status_stock == 'N' || this.state.status_stock == 'D' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          {this.state.import_branch_open ? (
            <ImportInvoiceItem
              onClose={() => {
                this.setState({
                  import_branch_open: false,
                });
              }}
              id={this.props.invoice_detail.id}
              onUpdate={(data) => {
                this.setState({
                  import_branch_open: false,
                });
                this.loadInvoiceItem(true)
              }}
            />
          ) : (
              ""
            )}
          {is_open && <BillItemModal handleSubmit={this.handleSubmitItem.bind(this)} />}
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div >
                <Grid >
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={16} error={this.state.msg_error.branch != null}>
                            <label>*สาขา
                              <MsgInput text={this.state.msg_error.branch} /></label>
                            <Dropdown
                              id='branchimport'
                              placeholder='สาขา'
                              width={16}
                              className={this.state.branch_id_error
                                ? 'error'
                                : ''}
                              search
                              selection
                              options={branch_option}
                              value={this.state.branch_id}
                              onChange={(e, data) => {
                                this.setState({ branch_id: data.value });
                              }}
                              disabled={this.state.invoice_disabled} />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={16} error={this.state.msg_error.vendor != null}>
                            <label>{this.state.invoice_disabled || this.props.action == 'export'
                              ? ''
                              : '*'}ชื่อโรงงาน/ร้านส่ง
                              <MsgInput text={this.state.msg_error.vendor} /></label>
                            <Dropdown
                              id='vendorimport'
                              width={16}
                              label=''
                              placeholder='ชื่อโรงงาน/ร้านส่ง'
                              search
                              disabled={this.state.invoice_disabled}
                              selection
                              options={this.props.vendors}
                              value={this.state.vendor}
                              onChange={(e, data) => {
                                this.setState({ vendor: data.value });
                              }} />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          {(this.props.action != 'export' && this.props.action == 'import')
                            && <Form.Field width={16} error={this.state.msg_error.ref_number != null}>
                              <label>เลขที่บิลร้านส่ง
                                <MsgInput text={this.state.msg_error.ref_number} /></label>
                              <Input
                                id='ref_number'
                                disabled={this.state.invoice_disabled}
                                placeholder='เลขที่บิลร้านส่ง'
                                value={this.state.ref_number}
                                maxLength={15}
                                onChange={(e, data) => {
                                  this.setState({ ref_number: data.value });
                                }} /></Form.Field>}
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={16} error={this.state.msg_error.cost != null}>
                            <label>*ราคาทองคำ(ต้นทุน)
                                <MsgInput text={this.state.msg_error.cost} /></label>
                            <Input
                              readOnly={this.state.invoice_disabled}
                              id='cost'
                              placeholder='0.00'
                              className='text-right'
                              value={this.state.cost}
                              disabled={this.state.invoice_disabled}
                              maxLength={10}
                              onChange={(e, data) => {
                                data.value = data.value.toString().replace(/,/g, '')
                                let cost = Utility.removeCommas(data.value)
                                if (isNaN(Number(cost)) || Number(cost) < 0) {
                                } else {
                                  let str = data.value.split('.')
                                  str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                  str[1] = str[1] == undefined ? '' : '.' + str[1]
                                  if (str.length != 1){
                                    if(str[1].length > 3){
                                      return;
                                    }
                                  }
                                  // data.value = str[0] + str[1]
                                  this.setState({ cost: data.value });
                                }
                              }} 
                              onFocus={() => {
                                if (!this.state.focus) {
                                    this.setState({
                                        focus: true,
                                        cost : this.state.cost.toString().replace(/,/g,'')
                                    });
                                }
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    if (this.state.focus) {
                                        this.setState({
                                            focus: false,
                                            cost : Utility.priceFormat(this.state.cost)
                                        });
                                    }
                                }, 0);
                            }}
                              /></Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={16}>
                            <label>วันที่</label>
                            <DatePicker
                              id='date'
                              readOnly
                              dateFormat="DD/MM/YYYY"
                              selected={this.state.invoice_date}
                              onChange={(date) => {
                                if (date === null || date === '') {
                                  this.setState({ invoice_date: moment() });
                                } else {
                                  this.setState({ invoice_date: date });
                                }
                              }}
                              disabled={this.state.invoice_disabled} />
                          </Form.Field>
                        </Form.Group>

                        <Form.Group inline>
                          <Form.Field >

                            <Checkbox
                              id='clearbillimport'
                              checked={this.state.status_bill == 'Y'}
                              onChange={(e, v) => {
                                this.setState({
                                  status_bill: v.checked
                                    ? 'Y'
                                    : 'N'
                                });
                              }} />
                            <label>เคลียร์บิล</label>
                          </Form.Field>
                        </Form.Group>

                        <Form.Group >
                          <Form.Field width={16} error={this.state.msg_error.description != null}>
                            <label>หมายเหตุ
                              <MsgInput text={this.state.msg_error.description} /></label>
                            <TextArea
                              placeholder='หมายเหตุ'
                              width={16}
                              value={this.state.invoice_description}
                              onChange={(e, v) => {
                                this.setState({ invoice_description: v.value })
                              }} />
                          </Form.Field>
                        </Form.Group>

                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>

                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={6}>
                            <Header floated='left' as='h3'>รายการสินค้า</Header>
                          </Form.Field>
                          <Form.Field width={10}>
                            <Button
                              id='add'
                              disabled={this.state.invoice_add_product_disabled}
                              size='small'
                              content='เพิ่มรายการสินค้า'
                              onClick={(e) => {
                                this.props.onBillItemModalOpen(this.state)
                              }}
                              floated='right'
                              icon='plus'
                              labelPosition='left'
                              type='button'
                              color='green' />
                             { auth.role == 'A' || auth.role == 'S' || auth.role == 'M'?
                            <Button
                              disabled={this.state.invoice_add_product_disabled}
                              id="importBranch"
                              size="small"
                              content="Import"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  import_branch_open: true,
                                });
                              }}
                              floated="right"
                              icon="file alternate outline"
                              labelPosition="left"
                              type="button"
                              primary
                            />
                            :''}
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <div id='table_w'>
                        <Table
                          rowsCount={items.length}
                          rowHeight={35}
                          headerHeight={30}
                          width={this.state.table_width}
                          height={350}>
                          <Column
                            width={50}
                            header={< Cell > </Cell>}
                            cell={< OptionItemsCell edit={false} onClickMenu={
                              (e, data) => {
                                this.onDeleteProduct(e, data)
                              }
                            } />} />

                          <Column
                            header={< Cell > รหัสสินค้า </Cell>}
                            cell={< ItemsCell position='top left' width={120} data={
                              items
                            }
                              field="product_code" />}
                            width={120} />
                          <Column
                            header={< Cell > กลุ่มสินค้า </Cell>}
                            cell={< ItemsCell position='top left' width={120} data={
                              items
                            }
                              field="category_title" />}
                            width={120} />
                          <Column
                            header={< Cell > ประเภท </Cell>}
                            cell={< ItemsCell position='top left' width={120} data={
                              items
                            }
                              field="kind_title" />}
                            width={120} />
                          <Column
                            header={< Cell > ชื่อสินค้า </Cell>}
                            cell={< ItemsCell position='top left' width={200} data={
                              items
                            }
                              field="product_name" />}
                            width={200} />
                          <Column
                            header={< Cell className='text-right' > น.น./ชิ้น</Cell >}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="weight" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > จำนวน </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="amount" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > น.น.รวม </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="weight_total" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > น.น.ชั่งจริง </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="weight_real" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > ค่าแรงชิ้นละ </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={items} field="fee" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > ต้นทุนชิ้นละ </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={items} field="cost" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > ราคาป้ายชิ้นละ </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="price_tag" textAlign='text-right' />}
                            width={120} />
                          <Column
                            header={< Cell className='text-center' > ประเภทการขาย </Cell>}
                            cell={< ItemsCell position='top center' width={120} data={
                              items
                            }
                              field="type_sale_title" textAlign='text-center' />}
                            width={120} />
                        </Table>
                      </div>
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input
                            id='invoiceitem'
                            label='จำนวนรายการ'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_itme_total}
                            readOnly />
                          {/* <Form.Input
                            id='invoiceprofit'
                            label='ค่าแรงขายปลีกรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_profit_total}
                            readOnly/> */}
                          <Form.Input
                            id='weighttotal'
                            label='น้ำหนักรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_weight_total}
                            readOnly />
                          <Form.Input
                            label='ต้นทุนค่าแรง'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_fee_total}
                            readOnly />
                          <Form.Input
                            id='producttotal'
                            label='จำนวนรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_product_total}
                            readOnly />

                        </Form.Group>

                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <br />
              <br />

            </div>

            {this.state.open_print
              ? <BillPrintPreview
                invoice_detail={this.props.invoice_detail}
                items={items}
                vendors={this.props.vendors}
                product_total={this.state.invoice_product_total}
                weight_total={this.state.invoice_weight_total}
                fee_total={this.state.invoice_fee_total}
                cost_total={this.state.invoice_cost_total}
                branch_name={this.state.branch_name}
                status_stock={this.state.status_stock}
                onClose={() => {
                  this.setState({ open_print: false })
                }} />
              : ''}
            {this.state.open_print_qr
              ? <BillQrcodeModal
                invoice_detail={this.props.invoice_detail}
                items={items}
                vendors={this.props.vendors}
                product_total={this.state.invoice_product_total}
                weight_total={this.state.invoice_weight_total}
                fee_total={this.state.invoice_fee_total}
                cost_total={this.state.invoice_cost_total}
                branch_name={this.state.branch_name}
                onClose={() => {
                  this.setState({ open_print_qr: false })
                }} />
              : ''}
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button
            id='qrcode'
            primary
            size='small'
            icon='qrcode'
            disabled={this.state.status_stock == 'N' || this.state.status_stock == 'D'}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ open_print_qr: true })
            }}
            content='พิมพ์ qrcode' />
          <Button
            id='print'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled || this.state.status_stock == 'D'}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ open_print: true })
            }}
            content='พิมพ์' />
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            disabled={this.state.status_stock == 'D'}
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.submitSaveInvoice();
            }}
            className={this.state.button_save_loading
              ? 'loading'
              : ''}
            content={this.state.button_save_title} />
          <Button
            id='updateimport'
            size='small'
            icon='lightning'
            color='red'
            onClick={(e) => {
              e.preventDefault();
              if (window.confirm('ยืนยันอนุมัติและอัพเดทสต๊อก')){
                this.submitSaveInvoice_no_alert();
              setTimeout(() => {
                this.submitUpdateStock();
              }, 400)}
            }}
            className={this.state.button_update_stoick_loading
              ? 'loading'
              : ''}
            disabled={this.props.auth.role != 'M' ? this.state.button_update_stoick_disabled : true}
            content='อนุมัติและอัพเดทสต๊อก' />
          <br /><br />
          <small>*การอนุมัติต้องเป็นสิทธิ์ Super Admin และ Admin เท่านั้น</small>
        </Modal.Actions>
      </Modal>
    )
  }

}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
    products: state.products,
    branches: state.branches,
    invoice_item_modal: state.invoice_item_modal
  })
}
const mapDispatchToProps = dispatch => {
  return {
    onBillItemModalOpen: (invoice) => {
      dispatch(invoice_item_open(invoice))
    },
    onInputError: (index, key, value) => {
      dispatch(invoice_item_set_value(index, key, value))
    },
    onBillItemModalClose: () => {
      dispatch(invoice_item_close())
    },
    addItem: (item = null) => {
      dispatch(invoice_item_add(item));
    },
    onDelete: (index) => {
      dispatch(invoice_item_remove(index))
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetail)
