/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import MsgInput from '../Error/MsgInput'
import {
    Route,
    NavLink
} from 'react-router-dom';
import {
    Form,
    Input,
    Dropdown,
    Button,Modal
} from 'semantic-ui-react';

import 'react-dates/lib/css/_datepicker.css';
import Utility from '../../Utility';
import Settings from '../../Settings';
import { connect } from 'react-redux'


class LeasePay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            error: {},
            amount: Utility.priceFormat(parseFloat(this.props.amount))
        }

        this.submitBill = this.submitBill.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
        this.setState({st:true})
        const {branch} = this.props.auth
        let staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1&branch='+ branch.id);
        Promise.all([staffs]).then((values) => {
            this.staffs = values[0];
            let staffs = [];
            for (let i in this.staffs) {
                staffs.push({
                    key: this.staffs[i].id,
                    value: this.staffs[i].id,
                    text: this.staffs[i].name,
                })
            }

            this.setState({
                st:false,
                staffs: staffs
            })
        });
    }

    submitBill(){
        if(this.state.staff_id==null || this.state.staff_id==0){
            this.setState({
                error: {
                    staff_id: 'กรุณาเลือกพนักงาน'
                }
            })
            return;
        }
        
        this.props.onSubmit({
            staff_id : this.state.staff_id,
            amount : this.state.amount
        });
    }

    closeModal(){
        this.props.onClose()
    }

    render() {

        return (
            <div>
                <Modal id='modalPayLease' open={this.props.open} dimmer={false} size='mini' /*dimmer='blurring'*/ >
                    <Button id='btnClosePayLease' circular icon='close'  basic floated='right' name='' onClick={this.closeModal}/>
                    <Modal.Header>จ่ายเงิน</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <Form>
                                <Form.Field error={this.state.error.staff_id!=null}>
                                    <label>*พนักงาน <MsgInput text={this.state.error.staff_id} /></label>
                                    <Dropdown id='dropDownEmp' search selection options={this.state.staffs} autoFocus value={this.state.staff_id}
                                    onChange={(e,v)=>{
                                        this.setState({
                                            staff_id:v.value
                                        })
                                        this.amountInput.focus()
                                    }}
                                    loading={this.state.st}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>*จำนวนเงิน</label>
                                    <Input id='inputAmountMoney' 
                                    readOnly
                                    value={this.state.amount}
                                    ref={(c)=>this.amountInput=c}
                                    onKeyPress={(e)=>{
                                        if (e.key == 'Enter') {
                                            this.submitBill(e)
                                        }
                                    }}
                                    className="text-right" />
                                </Form.Field>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button id='btnSavePayLease' size='small' primary onClick={this.submitBill}
                            className={this.state.button_class ? 'loading' : ''}>บันทึก</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state =>{
    return ({
      auth: state.auth,
      branches: state.branches
    })
  }

  export default connect(
    mapStateToProps,
  )(LeasePay)
