/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Radio, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DatePicker from 'react-datepicker';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import { connect } from 'react-redux'

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const staffWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }
        const data = this.props.items
        let arr = data.items

        return (<Table.Body>
            <Table.Row>
                <Table.Cell style={textLeft}><b>{this.props.type}</b></Table.Cell>
                <Table.Cell style={textLeft}><b>{data.title}</b></Table.Cell>
                <Table.Cell colSpan='8'></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell style={textCenter}><b>เลขที่บัญชี</b></Table.Cell>
                <Table.Cell style={textLeft}><b>สาขา</b></Table.Cell>
                <Table.Cell style={textLeft}><b>ชื่อบัญชี</b></Table.Cell>
                <Table.Cell style={textCenter}><b>วันที่</b></Table.Cell>
                <Table.Cell style={textCenter}><b>เวลา</b></Table.Cell>
                <Table.Cell style={textRight}><b>ยอดฝาก</b></Table.Cell>
                <Table.Cell style={textRight}><b>ยอดขาย</b></Table.Cell>
                <Table.Cell style={textRight}><b>ยอดเงินคงเหลือ</b></Table.Cell>
                <Table.Cell style={textLeft}><b>ผู้ทำรายการ</b></Table.Cell>
                <Table.Cell style={textLeft}><b>หมายเหตุ</b></Table.Cell>
            </Table.Row>
            {arr.map((row, i) => {
                return (<Table.Row>
                    <Table.Cell style={textCenter}>{row.number}</Table.Cell>
                    <Table.Cell style={textLeft}>{row.branch_name}</Table.Cell>
                    <Table.Cell style={textLeft}>{row.name}</Table.Cell>
                    <Table.Cell style={textCenter}>{row.date}</Table.Cell>
                    <Table.Cell style={textCenter}>{row.time}</Table.Cell>
                    <Table.Cell style={textRight}>{Utility.priceFormat(row.deposit)}</Table.Cell>
                    <Table.Cell style={textRight}>{Utility.priceFormat(row.withdraw)}</Table.Cell>
                    <Table.Cell style={textRight}>{Utility.priceFormat(row.balance)}</Table.Cell>
                    <Table.Cell style={textLeft, staffWidth}>{row.staff_name}</Table.Cell>
                    <Table.Cell style={textLeft, staffWidth}>{row.remark}</Table.Cell>
                </Table.Row>)
            })}
            <Table.Row>
                <Table.Cell colSpan='5' style={textRight}><div style={textU}><b></b>ยอดรวม</div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(data.total_deposit)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(data.total_withdraw)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(Number(data.total_deposit) - Number(data.total_withdraw))}</b></div></Table.Cell>
                <Table.Cell colSpan='2'></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell colSpan='9'></Table.Cell>
            </Table.Row>
        </Table.Body>)
    }
}

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: 0,
                start_date: moment(),
                end_date: moment(),
            },
            group: '1',
            items: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches,]).then((values) => {
            this.branches = values[0];
            let branches = [{
                value: 0,
                key: 0,
                text: 'ทุกสาขา'
            }]
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: 0,
                start_date: moment(),
                end_date: moment(),
            },
            group: '1',
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if (this.state.search.branch == 0)
            delete search['branch']
        let q = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/saving/0/item/?columnKey=record_date&sortDi=ASC&" + q
        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            } else {
                this.setState({
                    loading: false,
                    items: []
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            item.branch_name = item.branch.name
            item.gender_dispaly = item.saving.customer.gender == 'NA' ? 'ไม่ระบุ'
                : item.saving.customer.gender == 'MA' ? 'ชาย'
                    : item.saving.customer.gender == 'FE' ? 'หญิง' : ''
            item.staff_name = item.staff.name
            item.time = Utility.formatTime(item.record_date)
            item.date = Utility.formatDate(item.record_date)
            item.number = item.saving.number
            item.deposit = item.kind == 'O' || item.kind == 'D' ? item.amount : 0
            item.withdraw = item.kind == 'W' || item.kind == 'C' ? item.amount : 0
            item.name = item.saving.name
            item.last_balance = item.saving.balance
            items.push(item)
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let title = 'รายงานฝากถอนประจำวัน';
        let filename = 'savings-deposit-withdraw-' + Utility.formatDate3(this.state.search.start_date) + '-to-' + Utility.formatDate3(this.state.search.end_date);
        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let date_group = {}
        let branch_grouup = {}
        let gender_group = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (this.state.group == '1') {
                if (date_group[item.date] == null)
                    date_group[item.date] = {
                        items: [],
                        title: item.date,
                        total: 0,
                        total_deposit: 0,
                        total_withdraw: 0,
                    }
                date_group[item.date].total_deposit += Number(item.deposit)
                date_group[item.date].total_withdraw += Number(item.withdraw)
                date_group[item.date].items.push(item)
                date_group[item.date].total += Number(item.amount)
            }
            if (this.state.group == '2') {
                if (branch_grouup[item.branch_name] == null)
                    branch_grouup[item.branch_name] = {
                        items: [],
                        title: item.branch_name,
                        total: 0,
                        total_deposit: 0,
                        total_withdraw: 0,
                    }
                branch_grouup[item.branch_name].total_deposit += Number(item.deposit)
                branch_grouup[item.branch_name].total_withdraw += Number(item.withdraw)
                branch_grouup[item.branch_name].items.push(item)
                branch_grouup[item.branch_name].total += Number(item.amount)
            }
            if (this.state.group == '3') {
                if (gender_group[item.gender_dispaly] == null)
                    gender_group[item.gender_dispaly] = {
                        items: [],
                        title: item.gender_dispaly,
                        total: 0,
                        total_deposit: 0,
                        total_withdraw: 0,
                    }
                gender_group[item.gender_dispaly].total_deposit += Number(item.deposit)
                gender_group[item.gender_dispaly].total_withdraw += Number(item.withdraw)
                gender_group[item.gender_dispaly].items.push(item)
                gender_group[item.gender_dispaly].total += Number(item.amount)
            }
        }

        let items_all = []
        if (this.state.group == '1') {
            for (let i in date_group) {
                items_all.push(date_group[i])
            }
        } else if (this.state.group == '2') {
            for (let i in branch_grouup) {
                items_all.push(branch_grouup[i])
            }
        } else if (this.state.group == '3') {
            for (let i in gender_group) {
                items_all.push(gender_group[i])
            }
        }

        let branch_name = 'ทุกสาขา'
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานฝากถอนประจำวัน</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Dropdown
                                id='branch'
                                value={this.state.search.branch}
                                defaultValue={0}
                                label='สาขา'
                                fluid
                                size='small'
                                search
                                name='branch'
                                selection
                                options={this.state.branches}
                                onChange={this.handlerInput}
                            />
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label='แบ่งตามวันที่'
                                value='1'
                                checked={this.state.group === '1'}
                                onChange={(e, data) => {
                                    this.setState({ group: data.value });
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label='แบ่งตามสาขา'
                                value='2'
                                checked={this.state.group === '2'}
                                onChange={(e, data) => {
                                    this.setState({ group: data.value });
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label='แบ่งตามเพศ'
                                value='3'
                                checked={this.state.group === '3'}
                                onChange={(e, data) => {
                                    this.setState({ group: data.value });
                                }}
                            />
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='9'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell colSpan='8' style={textLeft}>
                                                <b>{this.state.group == '1' ? 'แบ่งตามวันที่' :
                                                    this.state.group == '2' ? 'แบ่งตามสาขา' : 'แบ่งตามเพศ'}</b>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    {items_all.map((row, i) => <GroupRow key={i} items={row}
                                        type={this.state.group == '1' ? 'วัน/เดือน/ปี' : this.state.group == '2' ? 'สาขา' : 'เพศ'} />
                                    )}
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div >)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)