import React, { Component } from 'react';
import {
   Button, Modal, Table,Dimmer,Loader
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import QrcodePrintPreview from '../../QrcodePrintPreview'

var QRCode = require('qrcode.react');
class QrcodeModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            loadder: true,
            items: []
        }
        this.onClickPrintItem = this.onClickPrintItem.bind(this)
        this.onClickPrintAll = this.onClickPrintAll.bind(this)
    }

    componentDidMount(){
        this.loadQrcode()
    }

    onClickPrintAll(e){

        const {items,loadder} = this.state
        const print_qrcode_items = []

        items.map((item,i)=>{
            if (item.kind == 'BU'){
                print_qrcode_items.push({
                qrcode: item.qrcode,
                text: item.product_name,
                weight: item.weight
            })
            }
        })
        
        this.setState({
            print_qrcode: true,
            print_qrcode_items
        })
    }

    onClickPrintItem(e,{item}){
        const print_qrcode_items = []
        print_qrcode_items.push({
            qrcode: item.qrcode,
            text: item.product_name,
            weight: item.weight
        })
        this.setState({
            print_qrcode: true,
            print_qrcode_items
        })
    }

    async loadQrcode(){
        const url = Settings.baseUrl + `/bill/${this.props.bill_id}/items/`
        const req = await Utility.getAsync(url)
        this.setState({
            items: req.data,
            loadder: false
        })
    }

    render() {
        const {items,loadder} = this.state
        return (<div>
            <Modal  open={true} onClose={this.close}  /*dimmer='blurring'*/>
            <Button
          id='btnCloseModalImEx'
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={this.props.onClose}/>
            <Modal.Header>QR Code</Modal.Header>
                <Modal.Content className='scrolling '>
                    <div className='segment'>
                    <Dimmer inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Table >
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>กลุ่มสินค้า</Table.HeaderCell>
                            <Table.HeaderCell>ชื่อทองเก่า</Table.HeaderCell>
                            <Table.HeaderCell>QR Code</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {items.map((item,i)=>
                            item.kind === 'BU' || item.kind == 'DF'?
                            <Table.Row>
                                <Table.Cell>{item.category.name}</Table.Cell>
                                <Table.Cell>{item.kind === 'BU' ? item.product_name : item.product.name}</Table.Cell>
                                <Table.Cell>
                                    <p>{item.qrcode}</p></Table.Cell>
                                <Table.Cell textAlign='right'><Button primary icon='print' size='small' labelPosition='right' content='พิมพ์' onClick={this.onClickPrintItem} item={item} /></Table.Cell>
                            </Table.Row>:''
                            )}
                        </Table.Body>
                    </Table>
                    </div>
                    {this.state.print_qrcode && <QrcodePrintPreview items={this.state.print_qrcode_items} onClose={(e)=>this.setState({print_qrcode: false})} />}
                </Modal.Content>
                <Modal.Actions>

                    <Button primary icon='print' size='small' labelPosition='right' content='พิมพ์ทั้งหมด' onClick={this.onClickPrintAll} />

                    <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ยกเลิก</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default QrcodeModal;