/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import OptionItemsCell from './OptionItemsCell'
import MsgInput from '../Error/MsgInput'
import ImportBranch from '../Import/ImportBranch'
import {
  Table,
  Column,
  Cell,
} from 'fixed-data-table';
import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Menu,
  Confirm, Dimmer, Loader, Header, TextArea, Dropdown,
  Popup,
} from 'semantic-ui-react';
import { connect } from 'react-redux'
import { loadBranch, auth } from '../../actions'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}
class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


class Branch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      code: '',
      remark: '',
      modal_open: false,
      msg_error: {},
      activeItem: 'แสดง',
      colSortDirs: {
        'code': 'ASC',
      },
    }
    this.columnKey = 'code'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.handleItemClick()

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handleItemClick = (e, d) => {
    let name = d == undefined ? this.state.activeItem : d.name
    let status = 1
    if (name === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/branches/?is_enabled=' + status + '&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi, (status, data) => {
      if (status)
        this.setState({
          items: data,
          loader_active: false
        });
    });
    this.setState({ activeItem: name })
  }

  submitData() {

    var formData = {
      name: this.state.name,
      code: this.state.code,
      description: this.state.description,
      address: this.state.address,
      is_enabled: this.state.is_enabled,
      telephone: this.state.telephone,
      line_id: this.state.line_id,
      gg_map: this.state.gg_map,
    };

    const self = this;
    this.setState({ button_class: 'loading', msg_error: {} })
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/branches/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.componentDidMount();
          self.resetForm();
          self.setState({
            modal_open: false
          });
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });

    } else {

      const url = Settings.baseUrl + '/branches/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          if (data.id == this.props.auth.branch.id, data.is_enabled == 0) {
            localStorage.removeItem('branch')
            const { dispatch } = this.props
            auth(dispatch)
          }
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    }
  }

  genCode() {
    Utility.getCounter('branches', (s, d) => {
      this.setState({
        code: this.pad(d.number)
      });
    });
  }

  pad(num, size) {
    num = num + ""
    var s = "000" + num;
    return s.substr(num.length);
  }

  resetForm() {
    this.setState({
      name: '',
      code: '',
      description: '',
      address: '',
      button_class: '',
      msg_error: {},
      is_enabled: 1,
    });
  }

  async componentDidMount() {
    this.setState({
      loader_active: true
    });
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
    Utility.get(Settings.baseUrl + '/branches/?is_enabled=' + status + '&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi, (status, data) => {
      if (status)
        this.setState({
          items: data,
          loader_active: false,
          // activeItem: 'แสดง',
        });
    });
    const { dispatch } = this.props
    await loadBranch(dispatch)


  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);

  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleDelete(e, branch) {

    if (window.confirm('ยืนยันลบ')) {

      let url = Settings.baseUrl + "/branches/" + branch.id + "/"
      Utility.delete(url, (s, r, code) => {

        if (s) {
          this.componentDidMount();
        } else {
          alert(r.error)
        }
      })

    }

  }

  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_title: 'แก้ไข',
        modal_action: 'edit',
        name: data.name,
        is_enabled: data.is_enabled,
        code: data.code,
        telephone: data.telephone,
        gg_map: data.gg_map,
        line_id:data.line_id,
        description: data.description,
        address: data.address,
        object_id: data.id,
        object: data
      })
    } else {
      this.handleDelete(e, data)
    }
  }

  render() {
    const items = this.state.items;
    const { auth } = this.props
    return (
      <div>
        {this.state.import_branch_open ?
          <ImportBranch
            onClose={() => {
              this.setState({
                import_branch_open: false,
              });
            }}
            onUpdate={() => {
              this.setState({
                import_branch_open: false,
              });
              this.componentDidMount()
            }} /> : ''}
        <Form >
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>สาขา</Header>
            </Form.Field>
            <Form.Field width={10}>
              {auth.role == 'A' || auth.role == 'S' ?
                <Button
                  id='importBranch'
                  size='small' content='Import'
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      import_branch_open: true,
                    });
                  }}
                  floated='right' icon='file alternate outline' labelPosition='left' type='button' primary />
                : ''}
              <Button
                id='addBranch'
                size='small' content='เพิ่ม'
                onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  this.setState({
                    modal_open: true,
                    modal_action: 'add',
                    modal_title: 'สร้างสาขา'
                  });
                }}
                floated='right' icon='plus' labelPosition='left' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal id='modalCreateBranch' open={this.state.modal_open} size='mini' /*dimmer='blurring'*/ >
          <Button
            id='btnClose'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small' >
                <Form.Field error={this.state.msg_error.code != null} >
                  <label>*รหัสสาขา <MsgInput text={this.state.msg_error.code} /></label>
                  <Input id='inputBranchID'
                    onChange={(e) => this.setState({ code: e.target.value })}
                    placeholder='รหัสสาขา'
                    ref='code'
                    readOnly
                    value={this.state.code}
                    action={
                      <Button id='btnGenID' onClick={(e) => { e.preventDefault(); this.genCode(); }} disabled={this.state.modal_action == 'edit'} icon='angle double left' />
                    } />
                  <i>{"กดปุ่ม << เพื่อสร้างรหัสสาขา"}</i>

                </Form.Field>
                <Form.Field error={this.state.msg_error.name != null}>
                  <label>*ชื่อสาขา <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='inputBranchName' placeholder='ชื่อสาขา' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={100} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.telephone != null}>
                  <label>เบอร์โทรศัพท์<MsgInput text={this.state.msg_error.telephone} /></label>
                  <Input placeholder='เบอร์โทรศัพท์' onChange={(e) => this.setState({ telephone: e.target.value })} value={this.state.telephone} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.line_id != null}>
                  <label>Line ID<MsgInput text={this.state.msg_error.line_id} /></label>
                  <Input placeholder='Line ID' onChange={(e) => this.setState({ line_id: e.target.value })} value={this.state.line_id} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.gg_map != null}>
                  <label>Google Map<MsgInput text={this.state.msg_error.gg_map} /></label>
                  <Input placeholder='Google Map' onChange={(e) => this.setState({ gg_map: e.target.value })} value={this.state.gg_map} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown id='inputBranchStatus' selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} />

                </Form.Field>
                <Form.Field error={this.state.msg_error.address != null}>
                  <label>ที่อยู่ <MsgInput text={this.state.msg_error.address} /></label>
                  <TextArea placeholder='หมายเหตุ' onChange={(e) => this.setState({ address: e.target.value })} value={this.state.address} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.description != null}>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea placeholder='หมายเหตุ' onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} maxLength={255} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSave' size='small' primary onClick={this.submitData}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancel' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />
            <Column

              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='code'
                sortDir={this.state.colSortDirs.code}>
                รหัสสาขา
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={120} id='branchID' data={items} field="code" />
              }
              width={120}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='name'
                sortDir={this.state.colSortDirs.name}>
                ชื่อสาขา
        </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="name" />
              }
              width={200}
            />
            <Column
              header={<Cell>ที่อยู่</Cell>}
              cell={
                <ItemsCell position='top left' width={500} data={items} field="address" />
              }
              width={500}
            />
            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="description" />
              }
              width={200}
            />

          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
          </Form.Group>
        </Form>

        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/branches/' + this.state.object_id + "/";
            var formData = this.state.object;
            formData['is_enabled'] = 0;
            this.setState({ confirm_open: false, loader_active: true });
            Utility.put(url, formData, (status, data) => {
              if (status) {
                this.componentDidMount();
                this.resetForm();
              }
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(mapStateToProps)(Branch);
