/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import { connect } from 'react-redux'
import { Table, Column, Cell } from 'fixed-data-table';
import { invoice_item_open, invoice_item_set_value, invoice_item_close, invoice_item_add, invoice_item_remove } from '../../../../reducers/invoice_item_modal'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Dropdown,
  Input,
  Modal,
  Grid,
  Popup,
  TextArea,
  Checkbox
} from 'semantic-ui-react';
import BillPrintPreview from './BillPrintPreview'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OptionItemsCell from '../../OptionItemsCell'

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}

class InvoiceDetail extends Component {

  constructor(props) {
    super(props);

    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      vendor: '',
      status_stock: 'N',
      modal_open: this.props.open,
      action_title: 'เพิ่มสินค้า',
      action: 'add',
      invoice_item: [],
      button_print_disabled: true,
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการนำออกสต็อกทองยืม',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      invoice_description: '',
      msg_error: {},
      cost: '',
      code: '',
      focus: '',
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];

    this.onOpenModal = this
      .onOpenModal
      .bind(this);
    this.onDeleteProduct = this
      .onDeleteProduct
      .bind(this);
  }

  submitSaveInvoice() {
    let msg_error = {}
    if (this.state.branch_id === undefined) {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    var formData = {
      branch: this.state.branch_id,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: this.state.status_bill,
      status_stock: this.state.status_stock,
      description: this.state.invoice_description
    };

    this.setState({ button_save_loading: true, msg_error: {} })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoiceborrow_export/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
          this.setState({ focus: 'edit' })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
          this.qrcodee.focus()
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    } else {
      var formData2 = {
        branch: this.state.branch_id,
        status_bill: this.state.status_bill,
        description: this.state.invoice_description
      };

      const url = Settings.baseUrl + '/invoiceborrow_export/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    }
  }

  submitSaveInvoice_no_alert() {
    let msg_error = {}
    if (this.state.branch_id === undefined) {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    var formData = {
      branch: this.state.branch_id,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: this.state.status_bill,
      status_stock: this.state.status_stock,
      description: this.state.invoice_description
    };

    this.setState({ button_save_loading: true, msg_error: {} })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoiceborrow_export/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          this.setState({ focus: 'edit' })
          this.qrcodee.focus()
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    } else {
      var formData2 = {
        branch: this.state.branch_id,
        status_bill: this.state.status_bill,
        description: this.state.invoice_description
      };

      const url = Settings.baseUrl + '/invoiceborrow_export/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
        } else { // error
          msg_error = data
          if (data.description !== undefined){
            if(data.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({ msg_error: msg_error })
        }
      });
    }
  }


  loadInvoiceItem(reload) {

    var invoices = Utility.getFetch(Settings.baseUrl + '/invoiceborrow_export/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/invoiceborrow_export/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise
      .all([invoices, items])
      .then((values) => {
        // set invoice detail
        let item = values[0];
        let branch_option = Utility.getOptions(this.props.branches)
        for (let i in branch_option) {
          if (branch_option[i].value === item.branch) {
            this.setState({ branch_name: branch_option[i].text })
          }
        }
        let status_bill = this.state.status_bill
        let invoice_description = this.state.invoice_description
        this.setState({
          number: item.number,
          branch_id: item.branch,
          // vendor: item.vendor,
          invoice_date: moment(item.invoice_date),
          // cost: Utility.priceFormat(item.cost),
          status_stock: item.status_stock,
          status_bill: item.status_bill,
          ref_number: item.ref_number,
          invoice_description: item.description,
          invoice_disabled: true,
          invoice_item: this.setFieldValue(values[1]),
          button_print_disabled: false,
          button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y' || item.status_stock == 'D',
          button_save_title: 'บันทึก',
          invoice_add_product_disabled: item.status_stock == 'Y' || item.status_stock == 'D',
          invoice_title: <span>
            <span>แก้ไขรายการนำออกสต็อกทองยืม</span>
          </span>,
          loader: false,
          button_update_stoick_loading: item.status_stock == 'D' ? true : false,
        });
        if (reload == true) {
          this.setState({
            status_bill: status_bill,
            invoice_description: invoice_description,
          });
        }
        if (item.status_stock == 'D') {
          setTimeout(() => { this.loadInvoiceItem(true) }, 5000)
        }
      });
  }

  setFieldValue(item) {
    let invoice_weight_total = 0;
    let invoice_product_total = 0;
    let invoice_itme_total = item.length;
    for (let i = 0; i < item.length; i++) {
      item[i]['status_stock_title'] = Settings.status_stock[item[i].status_stock]
      item[i]['number_invoice'] = item[i].invoice_export.number
      item[i]['weight'] = Utility.weightFormat(item[i].weight)
      item[i]['amount'] = Utility.numberFormat(item[i].amount)

      invoice_weight_total += parseFloat(Utility.removeCommas(item[i].weight));
      invoice_product_total += parseInt(Utility.removeCommas(item[i].amount));

    }
    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      invoice_product_total: Utility.numberFormat(invoice_product_total),
      invoice_itme_total: Utility.numberFormat(invoice_itme_total),
    });
    return item;
  }

  resetFormProduct() {
    this.setState({
      product_id: '',
      product_name: '',
      product_weight: '',
      product_weight_total: '',
      product_weight_real: '',
      product_amount: '',
      action_title: 'เพิ่มสินค้า',
      action: 'add',
      msg_error: {}
    });
  }


  onOpenModal() {
    if (this.props.modal_action == "edit") {
      this.loadInvoiceItem();
    } else {
      this.setState({ button_save_disabled: false })
    }
  }

  onClosenModal() {
    this.setState({
      number: '',
      branch_id: '',
      // vendor: '',
      status_stock: 'N',
      ref_number: '',
      invoice_disabled: false,
      invoice_item: [],
      button_update_stoick_disabled: true,
      button_save_title: 'สร้าง',
      button_save_disabled: true,
      invoice_add_product_disabled: true,
      product_id: '',
      product_name: '',
      product_weight: '',
      product_weight_total: '',
      product_weight_real: '',
      product_amount: '',
      button_update_stoick_loading: false
    });
  }

  onStatusModal() {
    if (this.modal_status != this.props.open) {
      if (this.props.open)
        this.onOpenModal()
      else
        this.onClosenModal()
    }
    this.modal_status = this.props.open;
  }

  onDeleteProduct(e, d) {
    let data = this.state.invoice_item[d.positon];
    
    if (data.invoice_borrow_export.status_stock == 'N') {
      if (!window.confirm('ยืนยันลบ'))
        return
      const url = Settings.baseUrl + '/invoiceborrow_export_item/' + data.id + '/';
      Utility.delete(url, (status, data) => {

        this.setState({ button_product_loading: false })
        this.resetFormProduct();
        this.loadInvoiceItem();
      });
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  submitUpdateStock() {
    this.setState({ button_update_stoick_loading: true })
    const url = Settings.baseUrl + '/invoiceborrow_export/' + this.props.invoice_detail.id + '/stock/';
    Utility.get(url, (status, data) => {
      if (data.status) {
        this
          .props
          .onAddInvoice();
        this.loadInvoiceItem();
        alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
        this.setState({ focus: 'update' })
      } else {
        alert(data.error)
      }

      this.setState({
        button_update_stoick_loading: false
      })
    });
  }


  componentDidMount() {
    this.setState({ focus: this.props.modal_action })
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)
    this.setState({ loader_active: true, btn_stock: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_w')
    this.setState({ table_width: elHeight.clientWidth });
  };

  submitQRcode() {
    let { auth } = this.props

    if(this.state.code == '') {
      alert('กรุณากรอกเลขที่ใบนำออก')
      return
    }

    let data = []
    data['number'] = this.state.code.trim()
    data['branch'] = this.state.branch_id
    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + '/invoices_export?' + qrstring);
    
    Promise.all([qrcode]).then((values) => {
      if (values[0].length != 0) {
          var formData = {
            invoice_export: values[0][0].id,
            invoice_borrow_export: this.props.invoice_detail.id,
          }
          const url = Settings.baseUrl + '/invoiceborrow_export_item/';
          Utility.post(url, formData, (status, data) => {
            if(data.detail == undefined) {
              this.loadInvoiceItem(true);
            }
            else {
              alert(data.detail)
            }
          });
        
      } else {
        alert('ไม่พบเลขที่ใบนำออก')
      }

    })
    this.setState({ code: '' })
  }

  render() {
    this.onStatusModal()
    let branch_option = Utility.getOptions(this.props.branches)
    let items = this.state.invoice_item;
    const { is_open } = this.props.invoice_item_modal
    const { auth } = this.props
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button
          id='btnCloseModalImEx'
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={
            this.props.onClose
          } />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.invoice_title}
              {this.state.number && <span>[บิลเลขที่ {this.state.number}]</span>}
              <Header.Subheader>
                <span><Icon
                  id='iconimport'
                  name={this.state.status_stock == 'N' || this.state.status_stock == 'D' ? 'warning circle' : 'check circle'}
                  color={this.state.status_stock == 'N' || this.state.status_stock == 'D' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          
          {/* {is_open && <BillItemModal handleSubmit={this.handleSubmitItem.bind(this)} />} */}
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div >
                <Grid >
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={16} error={this.state.msg_error.branch != null}>
                            <label>*สาขา
                              <MsgInput text={this.state.msg_error.branch} /></label>
                            <Dropdown
                              id='branchimport'
                              placeholder='สาขา'
                              width={16}
                              className={this.state.branch_id_error
                                ? 'error'
                                : ''}
                              search
                              selection
                              options={branch_option}
                              value={this.state.branch_id}
                              onChange={(e, data) => {
                                this.setState({ branch_id: data.value });
                              }}
                              disabled={this.state.invoice_disabled} />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={16}>
                            <label>วันที่</label>
                            <DatePicker
                              id='date'
                              readOnly
                              dateFormat="DD/MM/YYYY"
                              selected={this.state.invoice_date}
                              onChange={(date) => {
                                if (date === null || date === '') {
                                  this.setState({ invoice_date: moment() });
                                } else {
                                  this.setState({ invoice_date: date });
                                }
                              }}
                              disabled={this.state.invoice_disabled} />
                          </Form.Field>
                        </Form.Group>

                        <Form.Group inline>
                          <Form.Field >

                            <Checkbox
                              id='clearbillimport'
                              checked={this.state.status_bill == 'Y'}
                              onChange={(e, v) => {
                                this.setState({
                                  status_bill: v.checked
                                    ? 'Y'
                                    : 'N'
                                });
                              }} />
                            <label>เคลียร์บิล</label>
                          </Form.Field>
                        </Form.Group>

                        <Form.Group >
                          <Form.Field width={16} error={this.state.msg_error.description != null}>
                            <label>หมายเหตุ
                              <MsgInput text={this.state.msg_error.description} /></label>
                            <TextArea
                              placeholder='หมายเหตุ'
                              width={16}
                              value={this.state.invoice_description}
                              onChange={(e, v) => {
                                this.setState({ invoice_description: v.value })
                              }} />
                          </Form.Field>
                        </Form.Group>

                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>

                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={6}>
                            <Header floated='left' as='h3'>รายการใบนำออก</Header>
                          </Form.Field>
                          <Form.Field width={10}>
                            <label>เลขที่ใบนำออก</label>
                          {this.state.focus === 'edit' && this.state.status_stock === 'N' ? <Input
                            className='text-right'
                            placeholder='เลขที่ใบนำออก'
                            autoFocus={1}
                            ref={(c) => {
                              this.qrcodee = c
                            }}
                            onKeyPress={(e) => {
                              if (e.key == 'Enter') {
                                this.submitQRcode()
                              }
                            }}
                            onChange={(e) => this.setState({ code: e.target.value })}
                            value={this.state.code}
                          /> :
                            <Input
                              placeholder='เลขที่ใบนำออก'
                              className='text-right'
                              disabled={1}
                            />
                          }
                           
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <div id='table_w'>
                        <Table
                          rowsCount={items.length}
                          rowHeight={35}
                          headerHeight={30}
                          width={this.state.table_width}
                          height={350}>
                          <Column
                            width={50}
                            header={< Cell > </Cell>}
                            cell={< OptionItemsCell edit={false} onClickMenu={
                              (e, data) => {
                                this.onDeleteProduct(e, data)
                              }
                            } />} />

                          <Column
                            header={< Cell >เลขที่ใบนำออก</Cell>}
                            cell={< ItemsCell position='top left' width={120} data={
                              items
                            }
                              field="number_invoice" />}
                            width={120} />
                          <Column
                            header={< Cell className='text-right' > น.น.รวม </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="weight" textAlign='text-right' />}
                            width={120} />            
                          <Column
                            header={< Cell className='text-right' > จำนวน </Cell>}
                            cell={< ItemsCell position='top right' width={120} data={
                              items
                            }
                              field="amount" textAlign='text-right' />}
                            width={120} />

                        </Table>
                      </div>
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input
                            id='invoiceitem'
                            label='จำนวนรายการ'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_itme_total}
                            readOnly />
                          <Form.Input
                            id='weighttotal'
                            label='น้ำหนักรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_weight_total}
                            readOnly />
                          <Form.Input
                            id='producttotal'
                            label='จำนวนรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_product_total}
                            readOnly />

                        </Form.Group>

                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <br />
              <br />
            </div>

            {this.state.open_print
              ? <BillPrintPreview
                invoice_detail={this.props.invoice_detail}
                items={items}
                // vendors={this.props.vendors}
                product_total={this.state.invoice_product_total}
                weight_total={this.state.invoice_weight_total}
                // fee_total={this.state.invoice_fee_total}
                // cost_total={this.state.invoice_cost_total}
                branch_name={this.state.branch_name}
                status_stock={this.state.status_stock}
                onClose={() => {
                  this.setState({ open_print: false })
                }} />
              : ''}
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button
            id='print'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled || this.state.status_stock == 'D'}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ open_print: true })
            }}
            content='พิมพ์' />
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            disabled={this.state.status_stock == 'D'}
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.submitSaveInvoice();
            }}
            className={this.state.button_save_loading
              ? 'loading'
              : ''}
            content={this.state.button_save_title} />
          <Button
            id='updateimport'
            size='small'
            icon='lightning'
            color='red'
            onClick={(e) => {
              e.preventDefault();
              if (window.confirm('ยืนยันอนุมัติและอัพเดทสต๊อก')){
                this.submitSaveInvoice_no_alert();
              setTimeout(() => {
                this.submitUpdateStock();
              }, 400)}
            }}
            className={this.state.button_update_stoick_loading
              ? 'loading'
              : ''}
            disabled={this.state.button_update_stoick_disabled}
            content='อนุมัติและอัพเดทสต๊อก' />
          <br /><br />
         
        </Modal.Actions>
      </Modal>
    )
  }

}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
    products: state.products,
    branches: state.branches,
    invoice_item_modal: state.invoice_item_modal
  })
}
const mapDispatchToProps = dispatch => {
  return {
    onBillItemModalOpen: (invoice) => {
      dispatch(invoice_item_open(invoice))
    },
    onInputError: (index, key, value) => {
      dispatch(invoice_item_set_value(index, key, value))
    },
    onBillItemModalClose: () => {
      dispatch(invoice_item_close())
    },
    addItem: (item = null) => {
      dispatch(invoice_item_add(item));
    },
    onDelete: (index) => {
      dispatch(invoice_item_remove(index))
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetail)
