/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import StockProdcutDetail from './StockProdcutDetail';
import StockProductPrintPreview from './StockProductPrintPreview'
import ImportStockProduct from '../../Import/ImportStockProduct';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Input,
  Button,
  Header,
  Dimmer,
  Loader,
  Dropdown,
  Pagination,
  Popup,
  Icon,
} from 'semantic-ui-react';
import moment from 'moment';

class ItemsCell extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props} onClick={() => {
        this.props.onContextMenu(data[rowIndex].id, data[rowIndex].product_name);
      }} className="cell-time-click">
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class FormSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      s_date: moment(),
      e_date: moment(),
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      branch: 0,
      category: 0,
      product: 0,
      kind: 0,
      type_sale: 0,
      weight_b: 0,
      weight: ''
    }

    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.clearFormSearch = this.clearFormSearch.bind(this)
  }

  resetForm(event) {
    event.preventDefault();
    this.props.resetCategory(0)
    this.props.resetProduct(0, 0, 0, '', '')
    for (let i in this.state) {
      this.setState({
        [i]: 0
      });
    }
    this.setState({
      weight: '',
      weight_b: 0,
      s_date: moment(),
      e_date: moment(),
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      branch: 0,
      category: 0,
      product: 0,
      kind: 0,
      type_sale: 0,
    })

    delete this.state.s_date;
    delete this.state.e_date;
    this.props.onSubmitSearch({
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
    });
  }

  clearFormSearch(event) {
    event.preventDefault();
    this.props.resetCategory(0)
    this.props.resetProduct(0, 0, 0, '', '')
    for (let i in this.state) {
      this.setState({
        [i]: 0
      });
    }
    this.setState({
      weight: '',
      weight_b: 0,
      s_date: moment(),
      e_date: moment(),
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      branch: 0,
      category: 0,
      product: 0,
      kind: 0,
      type_sale: 0,
    })

  }
  handlerDropdownSearch(event, value) {
    this.setState({
      [value.name]: value.value
    }
      // , () => {
      //   this.props.resetProduct(this.state.category, this.state.kind, this.state.type_sale, this.state.weight)
      // }
    );
    if (value.name == 'category') {
      this.props.resetCategory(value.value)
    }
  }

  submitSearch(event) {
    event.preventDefault();

    delete this.state.s_date;
    delete this.state.e_date;

    let v = this.state;
    for (let i in v) {
      if (v[i] == '' || v[i].value == 0)
        delete v[i]
    }

    this.props.onSubmitSearch(v);
    this.setState({
      weight: v.weight == undefined ? '' : v.weight,
      weight_b: v.weight_b == undefined ? 0 : v.weight_b,
      branch: v.branch == undefined ? 0 : v.branch,
      category: v.category == undefined ? 0 : v.category,
      product: v.product == undefined ? 0 : v.product,
      kind: v.kind == undefined ? 0 : v.kind,
      type_sale: v.type_sale == undefined ? 0 : v.type_sale,
    })
    // this.props.loaddata()
  }

  handlerSearch(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    // this.loaddata(1)
  }

  render() {
    return (
      <Form className='attached fluid' size='small' onSubmit={this.submitSearch}>
        <Form.Group>
          <Form.Dropdown id='dropDownBranch' label='สาขา' placeholder='สาขา' search selection width={4} name='branch' value={this.state.branch}
            options={this.props.value.state.branches} onChange={this.handlerDropdownSearch} />
          <Form.Dropdown id='dropDownGropProduct' label='กลุ่มสินค้า' placeholder='กลุ่มสินค้า' search selection width={4} name='category' value={this.state.category}
            options={this.props.value.state.categories} onChange={this.handlerDropdownSearch} />

          <Form.Dropdown id='dropDownProductType' label='ประเภทสินค้า' placeholder='ประเภทสินค้า' search selection width={4} name="kind" value={this.state.kind}
            options={
              this.props.value.state.product_type
            }
            onChange={this.handlerDropdownSearch} />
          <Form.Field width={4}>
            <label>ประเภทงานขาย</label>
            <Dropdown id='dropDownSaleType' placeholder='ประเภทงานขาย' search selection defaultValue={this.props.value.state.type_sale}
              value={this.state.type_sale} options={this.props.value.state.type_sales}
              onChange={this.handlerDropdownSearch} name='type_sale' />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={4}>
            <label>น้ำหนัก บาท</label>
            <Dropdown id='dropDownWeightB' placeholder='น้ำหนัก บาท' search selection defaultValue={this.props.value.state.weight_b} value={this.state.weight_b} options={Settings.weight} onChange={(e, v) => {
              this.setState({
                weight: (parseFloat(v.value) * 15.2).toFixed(3),
                weight_b: v.value
              }, () => { this.props.resetProduct(this.state.category, this.state.kind, this.state.type_sale, this.state.weight) })
            }} name='weight' />
          </Form.Field>
          <Form.Field width={4}>
            <label>น้ำหนัก กรัม</label>
            <Input id='inputWeightF' placeholder='น้ำหนัก กรัม' search selection value={this.state.weight}
              onChange={(e, v) => {
                if (isNaN(Number(v.value))) {
                } else {
                  if (v.value.split('.').length != 1) {
                    if (v.value.split('.')[1].length > 3) {
                      return;
                    }
                  }
                  this.setState({
                    weight: v.value,
                    weight_b: 0,
                  }, () => { this.props.resetProduct(this.state.category, this.state.kind, this.state.type_sale, this.state.weight) })
                }
              }}
              name='weight' />
          </Form.Field>
          <Form.Dropdown
            id='dropDownProductID'
            label='รหัสสินค้า'
            placeholder='รหัสสินค้า'
            search selection width={4}
            name='product'
            value={this.state.product}
            options={this.props.value.state.products_code}
            onChange={this.handlerDropdownSearch}

            onSearchChange={(e, v) => {
              this.props.resetProduct(this.state.category, this.state.kind, this.state.type_sale, this.state.weight, v.searchQuery)
            }}
            loading={this.props.value.state.isLoading}
            isFetching={this.props.value.state.isLoading}
          />
          <Form.Dropdown
            id='dropDownProductName'
            label='ชื่อสินค้า'
            placeholder='ชื่อสินค้า'
            search selection
            width={4}
            name='product'
            value={this.state.product}
            options={this.props.value.state.products_name}
            onChange={this.handlerDropdownSearch}
            onSearchChange={(e, v) => {
              this.props.resetProduct(this.state.category, this.state.kind, this.state.type_sale, this.state.weight, v.searchQuery)
            }}
            loading={this.props.value.state.isLoading}
            isFetching={this.props.value.state.isLoading}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <Button id='btnAll' floated='right' onClick={this.clearFormSearch} >รีเซ็ต</Button>
            <Button id='btnAll' floated='right' onClick={this.resetForm} >ทั้งหมด</Button>
            <Button id='btnSearch' floated='right' ><Icon name='search' />ค้นหา</Button>
          </Form.Field>
        </Form.Group>
      </Form>)
  }
}


class StockProduct extends Component {

  constructor(props) {
    super(props);
    this.limit = 10
    this.state = {
      items: [],
      data: [],
      table_hiegh: Settings.table_hiegh,
      totalPages: 1,
      page: 1,
      items_print: [],
      sum_weight: 0,
      sum_amount: 0,
      query_string: '',
      colSortDirs: {
        'product__category__name': 'ASC',
      },
      counter: 0,
      branch_select: ''
    }
    this.columnKey = 'product__category__name'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitData = this.submitData.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.loaddata = this.loaddata.bind(this)
    this.resetCategory = this.resetCategory.bind(this)
    this.resetProduct = this.resetProduct.bind(this)
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  onContextMenu(id, name) {

    this.setState({
      id: id,
      name: name
    })

    this.setState({
      open_report: true
    })
  }

  setValue2(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].record_date = Utility.formatDate(data[i].record_date)
      data[i].branch_name = data[i].branch.name
      data[i].product_code = data[i].product.code
      data[i].product_name = data[i].product.name
      data[i].product_kind_name = data[i].product.kind.name

      data[i].after_w = Utility.weightFormat(parseFloat(data[i].product.weight) * parseInt(data[i].after))
      data[i].before_w = Utility.weightFormat(parseFloat(data[i].product.weight) * parseInt(data[i].before))
      if (data[i].kind == 'I') {
        data[i].amount_in = data[i].amount
        data[i].amount_in_w = Utility.weightFormat(parseFloat(data[i].product.weight) * parseInt(data[i].amount))
      } else {
        data[i].amount_out_w = Utility.weightFormat(parseFloat(data[i].product.weight) * parseInt(data[i].amount))
        data[i].amount_out = data[i].amount
      }
    }
    return data;
  }


  submitData() {

  }

  handlerSearch(data) {
    //onsubmitsearch
    this.search = data;
    let qrstring = Utility.jsonToQueryString(data);
    var stock_product = Utility.getFetch(Settings.baseUrl + '/stock_product/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=1&' + qrstring + '&page=' + 1 + '&page_size=' + this.limit);
    Promise.all([stock_product]).then((values) => {
      this.setState({
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / this.limit),
        query_string: qrstring,
        data: data,
        counter: values[0].count
      });
    });
  }
  loaddata(page) {
    var self = this;
    this.setState({
      loader_active: true
    });

    var stock_product = Utility.getFetch(Settings.baseUrl + '/stock_product/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=1&' + this.state.query_string + '&page=' + page + '&page_size=' + this.limit);
    Promise.all([stock_product,]).then((values) => {
      this.setState({
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / this.limit),
        counter: values[0].count,
        data: {},
      });
      let elHeight = document.getElementById('table_width')
      this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 380 });
    });
  }

  resetProduct(category, kind, type_sale, weight, text) {
    let search = this.state.search
    if (text != '') {
      this.setState({
        isLoading: true
      });
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let products = [{
          key: 0,
          value: 0,
          text: '-ทั้งหมด-',
        }];
        let products_code = [{
          key: 0,
          value: 0,
          text: '-ทั้งหมด-',
        }];
        let s = {}
        if (category != 0)
          s.category_id = category
        if (type_sale != 0)
          s.type_sale = type_sale
        if (kind != 0)
          s.product_types_id = kind
        if (weight != '')
          s.weight = weight
        s.text = text
        s.limited = 10
        var p = Utility.getFetch(Settings.baseUrl + '/products_no_join/?' + Utility.jsonToQueryString(s));
        Promise.all([p]).then((values) => {
          let p = values[0].product
          for (let i in p) {
            products_code.push({
              key: p[i].id,
              value: p[i].id,
              text: p[i].code,
            });
            products.push({
              key: p[i].id,
              value: p[i].id,
              text: p[i].name,
            });
          }
          this.setState({
            products_name: products,
            products_code: products_code,
            isLoading: false,
          })
        })
      }, 1600)
    } else {
      let products = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      let products_code = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }];
      this.setState({
        products_name: products,
        products_code: products_code,
      })
    }
  }

  resetCategory(category_id) {
    let type = [{
      key: 0,
      value: 0,
      text: '-ทั้งหมด-',
    }];
    for (let i in this.product_type) {
      if (this.product_type[i].category_id == category_id || this.product_type[i].category_id == 0 || category_id == 0) {
        type.push({ key: this.product_type[i].id, value: this.product_type[i].id, text: this.product_type[i].name });
      }
    }

    if (type.length > 0)
      this.setState({ product_type: type });
    else {
      for (let i in this.product_type) {
        type.push({ key: this.product_type[i].id, value: this.product_type[i].id, text: this.product_type[i].name });
      }
      this.setState({ product_type: type });
    }
  }

  componentDidMount() {
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var categories = Utility.getFetch(Settings.baseUrl + '/categories/');
    var product_type = Utility.getFetch(Settings.baseUrl + '/product_types/')
    // var products = Utility.getFetch(Settings.baseUrl + '/products_no_join/')
    Promise.all([branches, categories, product_type]).then((values) => {
      this.branches = values[0];
      let branches = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      for (let i in this.branches) {
        if (this.branches[i].is_enabled == 1)
          branches.push({
            value: this.branches[i].id,
            key: this.branches[i].id,
            text: this.branches[i].name
          });
      }

      this.categories = values[1];
      let categories = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      for (let i in this.categories) {
        categories.push({
          value: this.categories[i].id,
          key: this.categories[i].id,
          text: this.categories[i].name
        });
      }

      this.product_type = values[2];
      let product_type = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      for (let i in this.product_type) {
        product_type.push({
          value: this.product_type[i].id,
          key: this.product_type[i].id,
          text: this.product_type[i].name
        });
      }
      let prodcuts_code = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      let prodcuts_name = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      let type_sales = [{
        key: 0,
        value: 0,
        text: '-ทั้งหมด-',
      }]
      type_sales = type_sales.concat(Settings.type_sale)

      this.setState({
        loader_active: false,
        branches: branches,
        categories: categories,
        product_type: product_type,
        products_code: prodcuts_code,
        products_name: prodcuts_name,
        type_sales: type_sales,
      });
      let elHeight = document.getElementById('table_width')
      this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 380 });
    });
    var s = '';
    if (this.state.search_weight != null && this.state.search_weight != '') {
      s += (s == '' ? '' : '&') + 'weight=' + (this.state.search_weight * 15.2).toFixed(3);
    }
    this.setState({ query_string: s })
    this.loaddata(this.state.page)
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  getTypeSale(key) {
    let type = Settings.type_sale.filter((o) => { return o.value == key });
    return type[0];
  }

  setFieldValue(items) {

    for (let i = 0; i < items.length; i++) {
      items[i]['in_date_title'] = Utility.formatDate(items[i].in_date)
      items[i]['out_date_title'] = Utility.formatDate(items[i].out_date)
      items[i]['product_name'] = items[i].product.name
      items[i]['product_code'] = items[i].product.code
      items[i]['branch_name'] = items[i].branch.name
      items[i]['product_kind'] = items[i].product.kind.name
      items[i]['product_weight'] = Utility.weightFormat(items[i].product.weight)
      items[i]['product_category'] = items[i].product.category.name;
      items[i]['amount_title'] = Utility.numberFormat(parseInt(items[i].amount))
      items[i]['weight_total_title'] = Utility.weightFormat(parseInt(items[i].amount) * parseFloat(items[i].product.weight))
      items[i]['product_type_sale'] = this.getTypeSale(items[i].product.type_sale).text
      items[i]['product_price_tag'] = items[i].product.price_tag
    }

    return items;
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };
  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loaddata(activePage)
  }
  render() {
    const items = this.state.items;
    let branch = 'ทุกสาขา'
    if (this.state.data.branch != undefined) {
      for (let i in this.state.branches) {
        if (this.state.branches[i].value == this.state.data.branch) {
          branch = this.state.branches[i].text
        }
      }
    }
    return (
      <div id="table_width">
        {this.state.import_open ? <ImportStockProduct
          onClose={() => {
            this.setState({
              import_open: false,
            });
          }}
          onUpdate={() => {
            this.setState({
              import_open: false,
            });
            this.componentDidMount()
          }} /> : ''}
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>สต็อกทองใหม่</Header>
            </Form.Field>
          </Form.Group>
        </Form>

        {this.state.loader_active && <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>}
        <FormSearch value={this} onSubmitSearch={this.handlerSearch} loaddata={this.loaddata} resetCategory={this.resetCategory} resetProduct={this.resetProduct} />
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={402}>
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='branch__name'
              sortDir={this.state.colSortDirs.branch__name}>
              สาขา
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} id='rowBranch' data={items} field="branch_name" onContextMenu={this.onContextMenu} />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='product__category__name'
              sortDir={this.state.colSortDirs.product__category__name}>
              กลุ่มสินค้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="product_category" onContextMenu={this.onContextMenu} />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='product__kind__name'
              sortDir={this.state.colSortDirs.product__kind__name}>
              ประเภทสินค้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="product_kind" onContextMenu={this.onContextMenu} />
            }
            width={120}
          />

          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='product__code'
              sortDir={this.state.colSortDirs.product__code}>
              รหัสสินค้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="product_code" onContextMenu={this.onContextMenu} />
            }
            width={120}
          />

          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='product__name'
              sortDir={this.state.colSortDirs.product__name}>
              ชื่อสินค้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={200} id='productName' data={items} field="product_name" onContextMenu={this.onContextMenu} />
            }
            width={200}
          />
          <Column
            header={<SortHeaderCell
              className="text-right"
              onSortChange={this.onSortChange}
              field='amount'
              sortDir={this.state.colSortDirs.amount}>
              จำนวน
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={80} id={items + '_rowAmount'} data={items} field="amount_title" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={80}
          />
          <Column
            header={<SortHeaderCell
              className="text-right"
              onSortChange={this.onSortChange}
              field='product__weight'
              sortDir={this.state.colSortDirs.product__weight}>
              น.น./ชิ้น(g.)
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="product_weight" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={120}
          />
          <Column
            // header={<SortHeaderCell
            //   className="text-right"
            //   onSortChange={this.onSortChange}
            //   field='status_stock'
            //   sortDir={this.state.colSortDirs.status_stock}>
            //   น.น.รวม(g.)
            // </SortHeaderCell>}
            header={<Cell className="text-right">น.น.รวม(g.)</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="weight_total_title" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={120}
          />

          <Column
            header={<Cell>
              วันที่ซื้อ
            </Cell>}
            cell={
              <ItemsCell position='top left' width={100} data={items} field="in_date_title" onContextMenu={this.onContextMenu} />
            }
            width={100}
          />
          <Column
            header={<Cell>
              ขายครั้งสุดท้าย
            </Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="out_date_title" onContextMenu={this.onContextMenu} />
            }
            width={120}
          />

          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='product__type_sale'
              sortDir={this.state.colSortDirs.product__type_sale}>
              ประเภทงานขาย
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={130} data={items} field="product_type_sale" onContextMenu={this.onContextMenu} />
            }
            width={130}
          />
          <Column
            header={<SortHeaderCell
              className="text-right"
              onSortChange={this.onSortChange}
              field='product__price_tag'
              sortDir={this.state.colSortDirs.product__price_tag}>
              ราคาป้ายวันนี้
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={200} data={items} field="product_price_tag" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={200}
          />
          <Column
            header={<Cell className="text-right"></Cell>}
            cell={
              <ItemsCell position='top right' width={25} data={items} field='ee' />
            }
            width={25}
          />
        </Table>
        <br />
        {/* {this.state.totalPages > 1 && <Pagination
          activePage={this.state.page}
          onPageChange={this.handlePaginationChange.bind(this)}
          totalPages={this.state.totalPages}
        />} */}
        {this.state.open_print ? <StockProductPrintPreview

          onClose={() => {
            this.setState({ open_print: false });
          }}
          items={this.state.items_print}
          sum_amount={this.state.sum_amount}
          sum_weight={this.state.sum_weight}
          branch_name={branch}
        /> : ''}
        {this.state.open_report ? <StockProdcutDetail
          id={this.state.id}
          name={this.state.name}
          open={true}
          onClose={() => {
            this.setState({ open_report: false });
            //this.componentDidMount();
          }}
        /> : ''}
        <Form size='small' >
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
            <br />
            <Form.Field width={16}>
              <br />
              <Button id='btnPrint' content='พิมพ์' onClick={() => {
                this.search = this.state.data;
                var stock_product = Utility.getFetch(Settings.baseUrl + '/stock_product/export/?is_enabled=1&' + this.state.query_string);
                this.setState({
                  loader_active: true
                });
                Promise.all([stock_product]).then((values) => {
                  this.setState({
                    open_print: true,
                    items_print: values[0].result,
                    sum_amount: values[0].sum_amount,
                    sum_weight: values[0].sum_weight,
                    loader_active: false,
                  });
                });


              }} floated='right' icon='print' labelPosition='right' className='ml-1' type='button' primary />
              {this.state.totalPages > 1 && <Pagination
                size='tiny'
                floated='right'
                activePage={this.state.page}
                onPageChange={this.handlePaginationChange.bind(this)}
                totalPages={this.state.totalPages}
              />}

            </Form.Field>
          </Form.Group>
        </Form>
      </div>

    );
  }
}
export default StockProduct;
