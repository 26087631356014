/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';

class ImportPattern extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [
                {
                    name: 'code',
                    title: '*รหัสลายทอง',
                    type: 'string',
                    unique: true,
                    required: true,
                    length_require: 3,
                    pattern: /^[A-Z0-9]*$/,
                },
                {
                    name: 'name',
                    title: '*ชื่อลายทอง',
                    type: 'string',
                    unique: true,
                    required: true
                },
                {
                    name: 'description',
                    title: 'หมายเหตุ',
                    type: 'string',
                },
            ],
            is_loading: false
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]
            let url = Settings.baseUrl + '/pattern/'
            Utility.post(url,data,(e,res)=>{
                position++;
                if(position<data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })
        }
        run()
    }

    render() {
        return (
            <div>
                <ImportCSV
                    name='Pattern'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportPattern