/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';

class SelectOption extends Component {

    constructor(props){
        super(props)

    
        this.handleChange = this.handleChange.bind(this)
        this.onRef = this.onRef.bind(this)

    }

    handleChange(event){
        this.props.onChange(event,{name: this.props.name,value: event.target.value});
    }

    onRef(input){
        if(this.props.onRef)
            this.props.onRef(input)
    }

    render() {
        let options = this.props.options==null?[]:this.props.options.slice()
        if( options ==null)
            options = []
        options.unshift({
            value: 0,
            text: '-- เลือก --'
        })
        return (<select className='ui select input' autoFocus={this.props.autoFocus} ref={this.onRef} onChange={this.handleChange} name={this.props.name}>
            {options.map((item,index)=>{
            return (
            <option key={index} value={item.value} selected={item.value==Number(this.props.value)}>{item.text}</option>);
            }
            )}
        </select>)
    }
}

export default SelectOption