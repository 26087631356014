/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Checkbox, Divider
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

var LineChart = require("react-chartjs").Line;

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment()
            },
            items: [],
            label: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                start_date: new Date().getFullYear() + '-01-01',
                end_date: new Date().getFullYear() + '-12-31',
                year: new Date().getFullYear()
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
            },
            start_date: new Date().getFullYear() + '-01-01',
            end_date: new Date().getFullYear() + '-12-31',
            year: new Date().getFullYear()
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(this.state.start_date)
        search.end_date = Utility.formatDate2(this.state.end_date)
        let qrstring = Utility.jsonToQueryString(search)
        this.setState({
            loading: true
        })

        var bills = Utility.getFetch(Settings.baseReportUrl + '/bills/?kind=SE&is_enabled=1&' + qrstring);
        Promise.all([bills]).then((values) => {
            this.setState({
                loading: false,
                items: values[0]
            });
        });
        let label = []
        for (let y = 0; y < 12; y++) {
            let s = (y + 1).toString()
            if (s.length == 1) {
                label.push('0' + s + '/' + this.state.year)
            } else {
                label.push(s + '/' + this.state.year)
            }
        }
        this.setState({ label })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    getYearOption() {
        let y = new Date().getFullYear();
        let years = []
        for (let i = 2016; i <= y; i++) {
            years.push({
                value: i,
                key: i,
                text: i
            });
        }
        return years
    }

    render() {
        let title = 'สรุปยอดขายทองรวม';
        let filename = 'ledger-' + this.state.year

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }


        let items2 = this.state.items
        let group_month = {}
        let labels = []
        let data = []
        for (let i in items2) {
            let item = items2[i];
            let k = Utility.formatMonthYear(item.bill_date)
            if (group_month[k] == null) {
                group_month[k] = {
                    items: [],
                    title: k
                }
                labels.push(k)
            }
            group_month[k].items.push(item);
        }


        let items = []
        for (let i in group_month) {
            items.push(group_month[i])
            let total = 0
            for (let j in group_month[i].items) {
                total += parseFloat(group_month[i].items[j].total)
            }
            data.push((total))
        }
        let d = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        for (let r in items) {
            let total = 0
            for (let t in items[r].items) {
                total += parseFloat(items[r].items[t].total)
            }
            d[this.state.label.indexOf(items[r].title)] = total
        }
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>สรุปยอดขายทองรวม</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field>
                                <label>ปี</label>
                                <Dropdown search selection options={this.getYearOption()} value={this.state.year} name='year' onChange={(e, v) => {

                                    this.setState({
                                        start_date: v.value + '-01-01',
                                        end_date: v.value + '-12-31',
                                        year: v.value
                                    })

                                }} />
                            </Form.Field>
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell colSpan='4' style={textCenter}>
                                                <LineChart data={{
                                                    type: 'line',
                                                    labels: this.state.label,
                                                    datasets: [{
                                                        label: '# of Votes',
                                                        data: d,
                                                        backgroundColor: [
                                                            '#FFF',
                                                            '#FFF',
                                                            '#FFF',
                                                            '#FFF',
                                                            '#FFF',
                                                            '#FFF'
                                                        ],
                                                        borderColor: [
                                                            '#111',
                                                            '#111',
                                                            '#111',
                                                            '#111',
                                                            '#111',
                                                            '#111',
                                                        ],
                                                        borderWidth: 1
                                                    }]
                                                }} width="900" height="250" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>

                                            <Table.HeaderCell style={textCenter}></Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่บิลขาย</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่ขาย</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคารวม</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {items.map((row, i) => {
                                        let total = 0;
                                        return (<Table.Body key={i}>
                                            <Table.Row >
                                                <Table.Cell colSpan='4'>{row.title}</Table.Cell>
                                            </Table.Row>
                                            {row.items.map((row, i) => {
                                                total += parseFloat(row.total)
                                                return (<Table.Row id={row.bill_number}>
                                                    <Table.Cell ></Table.Cell>
                                                    <Table.Cell style={textCenter}>{row.bill_number}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{Utility.formatDate(row.bill_date)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.total)}</Table.Cell>
                                                </Table.Row>)
                                            })}
                                            <Table.Row >
                                                <Table.Cell colSpan='2'></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                                <Table.Cell style={textRight}><div style={textU}><b id={'total_' + row.title}>{Utility.priceFormat(total)}</b></div></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}

                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)