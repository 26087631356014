/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import { connect } from 'react-redux'
import {
  Form,
  Button,
  Icon,
  Grid,
} from 'semantic-ui-react';
import DropdownBranch from '../Widget/DropDown/DropDownBranch'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InputSearchCustomer from '../Customer/InputSearchCustomer';
import InputSearchBill from './Search/InputSearchBill'
import InputSearchLedger from './Search/InputSearchLedger'
import { loadLedgerCategory } from '../../actions'

class FormSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      s_date: moment(),
      e_date: moment(),
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
      default_dropdown: 0,
    }

    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.clearFormSearch = this.clearFormSearch.bind(this);

  }

  componentDidCatch() {
    this.setState({
      branch: this.props.auth.branch == undefined ? '' : this.props.auth.branch.id
    })
  }

  resetForm(event) {
    event.preventDefault();
    for (let i in this.state) {
      this.setState({
        [i]: ''
      });
    }
    this.componentDidMount()
    this.setState({
      s_date: moment(),
      e_date: moment(),
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      kind: 0,
      ledger_category: 0
    })

    this.props.onSubmitSearch({
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
    });
    this.props.onSubmitSearchSum({
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
    });
  }

  clearFormSearch() {
    let ledger_category = []

    ledger_category.push({
      value: 0,
      key: 0,
      text: 'ทั้งหมด'
    })

    for (let i in this.state) {
      this.setState({
        [i]: ''
      });
    }
    this.setState({
      s_date: moment(),
      e_date: moment(),
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      kind: 0,
      ledger_category: 0,
      ledger_categorys: ledger_category
    })
  }

  componentDidMount() {
    this.props.handleLedgerCategoryChanges()
    let ledger_category = []
    ledger_category.push({
      value: 0,
      key: 0,
      text: 'ทั้งหมด'
    })
    this.setState({
      ledger_categorys: ledger_category
    });
  }
  handlerDropdownSearch(event, value) {

    if (value.name == 'kind') {
      this.props.handleLedgerCategoryChanges()
      setTimeout(() => {
        let ledger_category = []
        if (this.props.ledger_category.list != '')
          ledger_category.push({
            value: 0,
            key: 0,
            text: 'ทั้งหมด'
          })
        for (let i in this.props.ledger_category.list) {
          if (value.value == this.props.ledger_category.list[i].kind) {
            ledger_category.push({
              value: this.props.ledger_category.list[i].id,
              key: this.props.ledger_category.list[i].id,
              text: this.props.ledger_category.list[i].title
            });
          }
        }
        this.setState({
          ledger_categorys: ledger_category,
          ledger_category: '',
        });
      }, 100)
    }
    this.setState({
      [value.name]: value.value,
    });
  }

  submitSearch(event) {
    event.preventDefault();

    let v = this.state;
    for (let i in v) {
      if (v[i] == '')
        delete v[i]
    }

    this.props.onSubmitSearch(v);
    this.props.onSubmitSearchSum(v);
  }

  handlerSearch(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { branch, role } = this.props.auth
    let ledger_kind_option = [{
      key: '',
      value: 0,
      text: 'ทั้งหมด',
      content: <div  ><Icon name='sticky note' />ทั้งหมด</div>
    }]

    for (let k in Settings.ledger_kind_option)
      ledger_kind_option.push(Settings.ledger_kind_option[k])

    return (
      <Form className='attached fluid' size='small' >
        <Form.Group>
          <InputSearchLedger
            width={4}
            label='เลขที่ใบสำคัญ'
            placeholder='เลขที่ใบสำคัญ'
            onChange={this.handlerDropdownSearch}
            name="number"
            value={this.state.number}
            branch={this.state.branch}
          />
          <InputSearchBill
            width={4}
            id='inputSearchLedgerObject'
            label='เลขที่อ้างอิง'
            placeholder='เลขที่อ้างอิง'
            onChange={this.handlerDropdownSearch}
            name="ledger_object"
            value={this.state.ledger_object}
            branch={this.state.branch}
          />
          <Form.Dropdown
            width={4}
            id='inputSearchKind'
            label='ประเภท'
            placeholder='ประเภท'
            search selection
            name="kind"
            value={this.state.kind}
            options={ledger_kind_option}
            onChange={this.handlerDropdownSearch}
            defaultValue={this.state.kind}
          />
          <Form.Dropdown
            width={4}
            id='inputSearchLedgerCategory'
            label='รายการ'
            placeholder='รายการ'
            search selection
            name='ledger_category'
            value={this.state.ledger_category}
            options={this.state.ledger_categorys}
            onChange={this.handlerDropdownSearch}
            defaultValue={this.state.ledger_category}
          />
        </Form.Group>
        <Form.Group>
          <InputSearchCustomer
            label='ลูกค้า'
            width={4}
            placeholder='พิมพ์ ชื่อ หรือ รหัสลูกค้า'
            onChange={this.handlerDropdownSearch}
            name="customer_id"
            value={this.state.customer_id}
          />
          <Form.Field width={3}>
            <label>จากวันที่</label>
            <DatePicker
              // maxDate={this.state.e_date}
              dateFormat="DD/MM/YYYY"
              value={this.state.start_date}
              selected={this.state.s_date}
              onChange={(date) => {
                date = date ? date : moment()
                this.setState({
                  s_date: date,
                  start_date: Utility.formatDate(date),
                  e_date: this.state.e_date ? this.state.e_date : date
                });
                if (this.state.e_date < date) {
                  this.setState({
                    e_date: date,
                    end_date: Utility.formatDate(date)
                  });
                }
              }}
            />
          </Form.Field>
          <Form.Field width={3}>
            <label>ถึงวันที่</label>
            <DatePicker
              minDate={this.state.s_date}
              dateFormat="DD/MM/YYYY"
              value={this.state.end_date}
              selected={this.state.e_date}
              onChange={(date) => {
                date = date ? date : moment()
                this.setState({
                  e_date: date,
                  end_date: Utility.formatDate(date)
                });
              }}
            />
          </Form.Field>
          <Form.Field >
            <label><br /></label>
            <Button id='btnSearch' onClick={this.submitSearch}><Icon name='search' />ค้นหา</Button>
            <Button id='btnAll' onClick={this.resetForm} ><Icon name='search' />วันนี้</Button>
            <Button id='clearSearch' onClick={this.clearFormSearch} ><Icon name='search' />รีเซ็ต</Button>
          </Form.Field>
        </Form.Group>
      </Form>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches,
    ledger_category: state.ledger_category
  })
}

const mapDispatchToProps = dispatch => ({
  handleLedgerCategoryChanges: async () => {
    loadLedgerCategory(dispatch)
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(FormSearch)