export default function unload() {
    window.addEventListener('beforeunload', (e) => {
        var isNoti = localStorage.getItem("noti")
        if (isNoti) {
            var link = localStorage.getItem("link")
            if (link) {
                localStorage.removeItem('link')
            } else {
                var confirmationMessage = "\o/";
                (e || window.event).returnValue = confirmationMessage;
                return confirmationMessage;
            }
        }
    });
}