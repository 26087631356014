/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import AddProductModal from './AddProductModal'
import BillPrintPreview from './BillPrintPreview'
import BillItemTable from './BillItemTable'
import {
  Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
var dateFormat = require('dateformat');

class BillModal extends Component {

  constructor(props) {
    super(props);
    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      bill_date: moment(),
      vendor: '',
      status_stock: 'N',
      modal_open: this.props.open,
      product_action_title: 'เพิ่มสินค้า',
      product_action: 'add',
      item_invoice: [],
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการนำออกทองเก่าไปหลอม',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      description: '',
      button_print_disabled: true,
      msg_error: {},
      QRCODE: '',
      is_disable_qrcode: true,
      focus: '',
      focus_input: '',
      weight_out: '',
      weight_real_total:0,
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.onEditProduct = this.onEditProduct.bind(this);
    this.onDeleteProduct = this.onDeleteProduct.bind(this);

    this.handelActionItemCell = this.handelActionItemCell.bind(this)
    this.handelSubmitProduct = this.handelSubmitProduct.bind(this)
    this.handlerQRCODE = this.handlerQRCODE.bind(this)
    this.submitQRcode = this.submitQRcode.bind(this)
    this.loadGoldPrice = this.loadGoldPrice.bind(this)
  }

  handelSubmitSaveInvoice() {
    if (this.state.vendor == '') {
      alert('กรุณาเลือกโรงงาน/ร้านส่ง');
      return;
    }

    var formData = {
      vendor: this.state.vendor,
      bill_date: Utility.formatDate2(this.state.bill_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      description: this.state.description,
      weight_out: this.state.weight_out,
    };

    this.setState({ button_save_loading: true })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoices_melt/';
      Utility.post(url, formData, (status, data) => {
        this.setState({
          button_save_loading: false,
          invoice_add_product_disabled: false,
          invoice_disabled: true,
          button_save_title: 'บันทึก'
        })
        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
        this.props.onAddInvoice(data);
        this.loadInvoiceItem()
        alert('บันทึกข้อมูลสำเร็จ!')

      });
    } else {
      var formData2 = {
        vendor: this.state.vendor,
        bill_date: Utility.formatDate2(this.state.bill_date),
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight_out: this.state.weight_out,
      };

      const url = Settings.baseUrl + '/invoices_melt/' + this.props.invoice_detail.id + "/";
      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  handelSubmitSaveInvoice_no_alert() {
    if (this.state.vendor == '') {
      alert('กรุณาเลือกโรงงาน/ร้านส่ง');
      return;
    }


    var formData = {
      vendor: this.state.vendor,
      bill_date: Utility.formatDate2(this.state.bill_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      description: this.state.description,
      weight_out: this.state.weight_out,
    };

    this.setState({ button_save_loading: true })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoices_melt/';
      Utility.post(url, formData, (status, data) => {
        this.setState({
          button_save_loading: false,
          invoice_add_product_disabled: false,
          invoice_disabled: true,
          button_save_title: 'บันทึก'
        })
        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
        this.props.onAddInvoice(data);
        this.loadInvoiceItem()

      });
    } else {
      var formData2 = {
        vendor: this.state.vendor,
        bill_date: Utility.formatDate2(this.state.bill_date),
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight_out: this.state.weight_out,
      };

      const url = Settings.baseUrl + '/invoices_melt/' + this.props.invoice_detail.id + "/";
      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
        }
      });
    }
  }

  handelSubmitProduct(e, data) {

    let formData = {
      invoice_melt: this.props.invoice_detail.id,
      status_stock: 'N',
    };

    this.setState({ button_product_loading: true })
    if (this.state.product_action == 'add') {
      const url = Settings.baseUrl + '/invoice_melt_item/';
      Utility.post(url, formData, (status, data) => {
        if (status) {
          this.setState({ button_product_loading: false, modal_product: false })
          this.setState(data)
          this.loadInvoiceItem();
        } else { // error
          if (data.error) {
            alert(data.error)
          }
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/invoice_melt_item/' + this.state.item_id + '/';
      Utility.put(url, formData, (status, data) => {
        if (status) {
          this.setState({ button_product_loading: false, modal_product: false })
          this.loadInvoiceItem();
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    }
  }

  loadGoldPrice() {
    let d = new Date();
    let q = dateFormat(d, "yyyy-mm-dd HH:MM")
    let url = Settings.baseUrl + "/gold_price/?datetime=" + q ;
    this.setState({ is_loading: true })
    let gold_price_period = []
    Utility.get(url, (s, res) => {
      let text = ''

      for (let i in res){
        text = Utility.priceFormat(res[i].gold_99_buy) + ' (' + 'ปัจจุบัน ' + Utility.formatTimeSecond(res[i].record_date) + ' น.' +') '
        gold_price_period.push({key:res[i].id,value:res[i].id,text:text})
      }

      if (s && res.length > 0) {
        this.setState({
          gold_price: res[0],
          gold_price_period: gold_price_period,
          gold_price_value: gold_price_period.length != 0 ? gold_price_period[0].value:0,
          is_loading: false,
        })
      }
    });
  }

  loadInvoiceItem(reload) {
    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_melt/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/invoices_melt/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    this.loadGoldPrice()
    Promise.all([invoices, items]).then((values) => {
      // set invoice detail
      let item = values[0];
      let description = this.state.description
      let status_bill = this.state.status_bill
      this.setState({
        number: item.number,
        description: item.description,
        status_bill: item.status_bill,
        bill_date: moment(item.bill_date),
        vendor: item.vendor,
        invoice_disabled: true,
        item_invoice: this.setFieldValue(values[1]),
        button_save_title: 'บันทึก',
        status_stock: item.status_stock,
        button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y',
        invoice_add_product_disabled: item.status_stock == 'Y',
        invoice_title: <span>{<span>แก้ไขรายการนำออกทองเก่าไปหลอม</span>} </span>,
        loader: false,
        button_print_disabled: false,
        is_disable_qrcode: item.status_stock == 'Y',
        invoice_melt_id: item.id,
        weight_out: item.weight_out,
        weight_out_disabled: item.status_stock == 'Y' ? true : false
      });
      if (reload == true) {
        this.setState({
          description: description,
          status_bill: status_bill,
        })
      }
    });
  }

  setFieldValue(item) {
    let weight_real_total = 0
    for (let i = 0; i < item.length; i++) {
      item[i]['weight'] = item[i].weight
      item[i]['weight_real'] = item[i].invoice_old.weight_real
      weight_real_total += parseFloat(item[i].invoice_old.weight_real)
    }

    this.setState({weight_real_total:Utility.weightFormat(weight_real_total)})
    return item;
  }


  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      btn_stock: true
    });
    this.setState({ focus: this.props.modal_action })

    if (this.props.modal_action == 'edit') {
      this.loadInvoiceItem()
    }
    this.loadGoldPrice()
  }

  onEditProduct(e, d) {
    let data = this.state.item_invoice[d.positon];
    this.setState({
      item_id: data.id,
      stock_category: data.stock.id,
      product_weight: data.weight,
      average: data.average,
      price_sell: Utility.removeCommas(data.price),
      total_sell: Utility.removeCommas(data.total),
      cost: Utility.removeCommas(data.cost),
      total_diff: data.total_diff,
      price_diff: parseFloat(Utility.removeCommas(data.price)) - parseFloat(Utility.removeCommas(data.average)),
      product_action: 'edit',
      product_action_title: 'แก้ไข'
    });
  }

  onDeleteProduct(e, d) {
    let data = this.state.item_invoice[d.positon];
    if (data.status_stock == 'N') {
      const url = Settings.baseUrl + '/invoice_melt_item/' + data.id + '/';
      Utility.delete(url, (status, data) => {

        this.setState({ button_product_loading: false })
        this.loadInvoiceItem();
      });
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  handelActionItemCell(e, d) {

    if (d.action == 'delete') {
      if (window.confirm('ยืนยันลบรายการนี้'))
        this.onDeleteProduct(e, d)
    } else {
      this.onEditProduct(e, d)
    }

  }

  submitUpdateStock() {
    this.setState({ QRCODE: '' })
    if (window.confirm('ยืนยันอนุมัติและอัพเดทสต็อก')) {
      if (this.state.weight_out == '' || this.state.weight_out == null || this.state.weight_out == 0) {
        alert('น้ำหนักชั่งห่อส่งหลอม ต้องไม่เป็น 0')
        this.componentDidMount();
        return;
      }

      if (Utility.weightFormat(this.state.weight_out) != this.state.weight_real_total) {
        if (this.props.auth.role != 'S') {
          alert('คุณไม่มีสิทธิ์อนุมัติและอัพเดทสต๊อกเนื่องจาก น้ำหนักชั่งส่งหลอม ไม่เท่ากับ น้ำหนักชั่งห่อรวม โปรดติดต่อ superadmin')
          this.componentDidMount();
          return;
        } else {
          if (this.state.description == '') {
            alert('กรุณากรอกหมายเหตุ กรณี น้ำหนักชั่งส่งหลอม ไม่เท่ากับ น้ำหนักชั่งห่อรวม')
            this.componentDidMount();
            return;
          }
  
          let msg = this.state.weight_out > 0 ? 'กรุณายืนยันการอัพเดทสต๊อก เนื่องจาก น้ำหนักชั่งส่งหลอม ไม่เท่ากับ น้ำหนักชั่งห่อรวม' : 'กรุณายืนยันการอัพเดทสต๊อก เนื่องจาก น้ำหนักชั่งห่อ เป็น 0.000 กรัม'
          let r = window.confirm(msg);
          if (!r) {
            return;
          }
        }
      }

      this.setState({
        button_update_stoick_loading: true,
      })
      const url = Settings.baseUrl + '/invoices_melt/' + this.props.invoice_detail.id + '/stock_update/';
      Utility.get(url, (status, data) => {
        this.props.onAddInvoice();
        this.loadInvoiceItem();
        this.setState({ focus: 'update' })
        this.setState({
          button_update_stoick_loading: false
        })
        alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
        this.handelSubmitSaveInvoice_no_alert();
      });
    }
  }

  handlerQRCODE(e, d) {
    this.setState({ QRCODE: d.value });
  }

  submitQRcode() {
    let data = []
    data['number'] = this.state.QRCODE.trim()
    data['status_stock'] = 'Y'
    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + '/invoices_old?' + qrstring);
    Promise.all([qrcode]).then((values) => {
      let item = values[0]
      if (item.length == 0) {
        alert('ไม่พบเลขที่ห่อที่เลือก')
      } else {
        if (this.state.bill_date < moment(item[0].bill_date)) {
          alert('เลขที่ห่อที่เลือกยังไม่ถูกสร้าง')
        }
        else {
          let formData = {
            invoice_old: item[0].id,
            invoice_melt: this.state.invoice_melt_id
          }
          const url = Settings.baseUrl + '/invoice_melt_item/';
          Utility.post(url, formData, (status, data) => {
            this.setState({
              button_save_loading: false,
              invoice_add_product_disabled: false,
              invoice_disabled: true,
              button_save_title: 'บันทึก'
            })

            if (!status) {
              alert(data.detail)
              return
            }
            this.loadInvoiceItem(true)
          });
        }
      }

      this.setState({
        QRCODE: ''
      })

    })
  }


  render() {
    let items = this.state.item_invoice;
    return (

      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.invoice_title} {this.state.number && <span id='textBillID'>[บิลเลขที่ {this.state.number}]</span>}
              <Header.Subheader>
                <span><Icon name={this.state.status_stock == 'N' ? 'warning circle' : 'check circle'} color={this.state.status_stock == 'N' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form className=' fluid' size='small' >
                        <Form.Dropdown id='vendor' label='*ชื่อโรงงาน/ร้านส่ง' search selection width={16} options={this.props.vendors} value={this.state.vendor} placeholder='ชื่อโรงงาน/ร้านส่ง' defaultValue={this.state.vendor} onChange={(e, data) => {
                          this.setState({ vendor: data.value });
                        }} disabled={this.state.invoice_disabled || this.props.action == 'export'} />

                        <Form.Field width={16}>
                          <label>วันที่</label>
                          <DatePicker
                            id='date'
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.bill_date}
                            readOnly
                            onChange={(date) => {
                              date = date ? date : moment()
                              this.setState({ bill_date: date });
                            }}
                            disabled={this.state.invoice_disabled}
                          />
                        </Form.Field>

                        <Form.Field width={16}>
                          <label>น้ำหนักชั่งส่งหลอม</label>
                          <Input
                            id='weight_out'
                            placeholder='0.000'
                            className='text-right'
                            onChange={(e) => {
                              if (isNaN(Number(e.target.value))) {
                              } else {
                                if (e.target.value.split('.')[0].length > 6) {
                                  return;
                                }
                                if (e.target.value.toString().split(".")[1] != undefined)
                                  if (e.target.value.toString().split(".")[1].length > 3)
                                    return
                                this.setState({ weight_out: e.target.value })
                              }
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_input) {
                                  this.setState({
                                    focus_input: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={(e) => {
                              e.target.select()
                              if (!this.state.focus_input) {
                                this.setState({
                                  focus_input: true,
                                });
                              }
                            }}
                            value={this.state.focus_input ? this.state.weight_out.toString().replace(/,/g, '') : Utility.weightFormat(this.state.weight_out)}
                            disabled={this.state.weight_out_disabled}
                            maxLength={10}
                          />
                        </Form.Field>

                        <Form.Checkbox id='btnClearBill' label='เคลียร์บิล' width={16} checked={this.state.status_bill == 'Y'} onChange={(e, v) => {


                          this.setState({
                            status_bill: v.checked ? 'Y' : 'N'
                          });
                        }} />
                        <Form.TextArea label='หมายเหตุ' placeholder='หมายเหตุ' width={16} value={this.state.description} onChange={(e, v) => {
                          this.setState({ description: v.value })
                        }}
                          maxLength={255} />
                        <Form.Dropdown 
                          // loading={this.props.loading}
                          label='ราคาทอง' fluid selection width={16}
                          options={this.state.gold_price_period}
                          value={this.state.gold_price_value}
                          // onChange={this.goldPriceChangeHandler} 
                          disabled={true}
                          style={{'word-break':'break-word'}}
                          openOnFocus={false}
                        />
                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={8}>
                            <Header floated='left' as='h3'>รายการสินค้า</Header>
                          </Form.Field>

                          <Form.Field width={8} floated='right'>
                            <label>เลขที่ห่อ</label>
                            {(this.state.focus === 'edit' && this.state.status_stock === 'N') ?
                              <Input
                                ref={(c) => {
                                  this.qrcodee = c
                                }}
                                autoFocus={1}
                                placeholder='เลขที่ห่อ'
                                value={this.state.QRCODE}
                                onChange={this.handlerQRCODE}

                                onKeyPress={(e) => {

                                  if (e.key == 'Enter') {
                                    this.submitQRcode()
                                    // this.weightRealGInput.focus()
                                    // e.preventDefault()
                                  }
                                }}
                                className='text-right' />
                              :
                              <Input
                                disabled={1}
                                placeholder='เลขที่ห่อ'
                                className='text-right' />
                            }
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <BillItemTable items={items} table_width={this.state.table_width} onActionItemCell={this.handelActionItemCell}
                       />
                       <br/>
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input id='weight_real_total' label='น้ำหนักชั่งห่อรวม' placeholder='' className='text-right' width={4} value={this.state.weight_real_total} readOnly />
                        </Form.Group>
                        <small>*ถ้าน้ำหนักชั่งห่อรวม ไม่เท่ากับ น้ำหนักชั่งส่งหลอม สิทธิ์ในการอนุมัติเเละอัพเดทสต็อกจะเป็นของ Superadmin</small>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button
            id='btnPrint'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ open_print: true })
            }}
            content='พิมพ์' />
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.handelSubmitSaveInvoice();
            }}
            className={this.state.button_save_loading ? 'loading' : ''}
            content={this.state.button_save_title} />
          <Button
            id='btnUpdate'
            size='small'
            icon='lightning'
            color='red'
            onClick={(e) => {
              this.handelSubmitSaveInvoice();
              setTimeout(() => {
                this.submitUpdateStock();
              }, 400)
            }
            }
            className={this.state.button_update_stoick_loading ? 'loading' : ''}
            disabled={this.state.button_update_stoick_disabled}
            content='อนุมัติและอัพเดทสต๊อก' />

        </Modal.Actions>
        {this.state.open_print
          ? <BillPrintPreview
            invoice_detail={this.props.invoice_detail}
            vendors={this.props.vendors}
            items={items}
            onClose={() => {
              this.setState({ open_print: false })
            }} />
          : ''}
      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(BillModal)