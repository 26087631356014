/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
// import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button,
  Modal,
  Input,
  Dimmer,
  Loader,
  Table,
  Header,
  Label,
  FormGroup,
  FormField,
  Icon,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
// import Utility from '../../../Utility';
// import Settings from '../../../Settings';
import moment from "moment";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import InputSearchCustomer from "../Customer/InputSearchCustomer";
import CustomerModal from "../Customer/CustomerModal";
import Utility from "../../Utility";
import Settings from '../../Settings';

class CloseAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      remark: "",
      fee: 0,
      total: 0,
    };
    this.handlerCustomer = this.handlerCustomer.bind(this);
    this.handlerAddCustomer = this.handlerAddCustomer.bind(this);
    this.handlerCreateCustomer = this.handlerCreateCustomer.bind(this);
    this.submitData = this.submitData.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }

  componentDidMount() {
    var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1&branch=' + this.props.auth.branch.id);
    var settings = Utility.getFetch(Settings.baseUrl + '/branch_setting/?is_enabled=1&branch=' + this.props.auth.branch.id);
    Promise.all([staffs, settings]).then((values) => {
      this.staffs = values[0];
      let staffs = []
      for (let i in this.staffs) {
        staffs.push({
          value: this.staffs[i].id,
          key: this.staffs[i].id,
          text: this.staffs[i].name
        });
      }
      var fee = Utility.parseFloat(Utility.removeCommas(this.props.balance))*Utility.parseFloat(values[1][0].savings_fee)/Utility.parseFloat(100)
      fee = fee < 100 ? 100: fee > 500 ? 500 : fee
      this.setState({
          staffs: staffs,
          st: false,
          number:this.props.items.number,
          name:this.props.items.name,
          balance:Utility.priceFormat(Utility.removeCommas(this.props.balance)),
          fee:parseFloat(fee).toFixed(2),
          total:(parseFloat(Utility.removeCommas(this.props.balance)) - parseFloat(fee)).toFixed(2),
          savings_fee:values[1][0].savings_fee
      });
    });
  }

  handlerCustomer(e, d) {
    let data = {};
    for (let i in d.options) {
      if (d.options[i].value == d.value) {
        data = d.options[i].data;
        break;
      }
    }
    this.setState({
      customer: data,
      customer_id: data.id,
      name: data.name,
      code: data.code,
      citizen_id: data.citizen_id,
      phone: data.mobile === null ? "" : data.mobile,
    });
  }
  handlerAddCustomer(e) {
    e.preventDefault();
    this.setState({
      modal_open: true,
    });
  }
  handlerCreateCustomer(data) {
    this.setState({
      modal_open: false,
      customer_id: data.id,
      citizen_id: data.citizen_id,
      customer: data.id,
      name: data.name,
      code: data.code,
      phone: data.mobile === null ? "" : data.mobile,
    });
  }
  submitData() {
    if (this.state.staff == undefined || this.state.staff == null || this.state.staff == '') {
      alert('กรุณาเลือกพนักงาน')
      return
    }
    if (!window.confirm('ยืนยันการปิดบัญชี เลขที่บัญชี ' + this.state.number))
      return
    var formData = {
      staff: this.state.staff,
      branch: this.props.auth.branch.id,
      remark: this.state.remark,
    };
    let url = Settings.baseUrl + '/saving/' + this.props.items.id + '/close/';
    Utility.post(url, formData, (status, data) => {
      if (status) {
        alert('บันทึกข้อมูลสำเร็จ!');
        this.props.onSubmit()
      }
      else {
        alert(data.detail)
      }
    })
  }
  handlerInput(e, v) {
    this.setState({ staff: v.value })
  }
  render() {
    const { auth } = this.props;

    return (
      <Modal open={this.props.open} size="mini" /*dimmer='blurring'*/>
        <Button
          id="btnCloseProductSelect"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header>ปิดบัญชี</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size="small">
              <Form.Field >
                <label>
                  เลขที่บัญชีออมทอง
                </label>
                <Input
                  value={this.state.number}
                  readOnly
                />
              </Form.Field>
              <Form.Field >
                <label>
                  ชื่อบัญชี
                </label>
                <Input
                  value={this.state.name}
                  readOnly
                />
              </Form.Field>
              <Form.Field >
                <label>
                  ยอดเงินคงเหลือ
                </label>
                <Input
                  className='text-right'
                  value={this.state.balance}
                  readOnly
                />
              </Form.Field>
              <Form.Field >
                <label>
                ค่าธรรมเนียม {this.state.savings_fee} %
                </label>
                <Input
                  className='text-right'
                  value={Utility.priceFormat(this.state.fee)}
                  readOnly
                />
              </Form.Field>
              <Form.Field >
                <label>
                  ยอดถอนคงเหลือ
                </label>
                <Input
                  className='text-right'
                  value={Utility.priceFormat(this.state.total)}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>*พนักงาน</label>
                <Dropdown placeholder='พนักงาน' search selection
                  options={this.state.staffs}
                  // value={this.state.search.staff} 
                  name='staff'
                  onChange={this.handlerInput}
                  loading={this.state.st} />
              </Form.Field>
              <Form.Field>
                <label>
                  หมายเหตุ
                </label>
                <TextArea
                  id="IDstaff"
                  maxLength={255}
                  value={this.state.remark}
                  onChange={(e) => this.setState({ remark: e.target.value })}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
          <CustomerModal
            open={this.state.modal_open}
            onCreate={this.handlerCreateCustomer}
            onClose={() => {
              this.setState({ modal_open: false });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="cancelAddstaff"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.props.onClose();
            }}
          >
            ยกเลิก
          </Button>
          <Button
            id="submitAddstaff"
            size="small"
            primary
            onClick={this.submitData}
            className={this.state.button_loading ? "loading" : ""}
          >
            บันทึก
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        // e.preventDefault()
        // this.handlerSubmit(e)
      },
    },
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(CloseAccount));
