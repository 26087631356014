/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import BillPrintPreview from './BillPrintPreview'
import BillItemTable from './BillItemTable'
import {
  Form, Header, Button, Loader, Dimmer, Icon, Input, Modal, Grid
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
var dateFormat = require('dateformat');

class BillModal extends Component {

  constructor(props) {
    super(props);
    this.modal_status = false;
    this.state = {
      items: [],
      bill_date: moment(),
      status_stock: 'N',
      item_invoice: [],
      item_invoice_id: [],
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      bill_title: 'เพิ่มรายการรวมบิลส่งหลอม',
      loader: false,
      button_save_title: 'สร้าง',
      description: '',
      button_print_disabled: true,
      QRCODE: '',
      is_disable_qrcode: true,
      weight_out_total:0,
      item_lot_melt: null,
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.onDeleteProduct = this.onDeleteProduct.bind(this);
    this.handelActionItemCell = this.handelActionItemCell.bind(this)
    this.handlerQRCODE = this.handlerQRCODE.bind(this)
    this.submitQRcode = this.submitQRcode.bind(this)
    this.loadGoldPrice = this.loadGoldPrice.bind(this)
  }

  handelSubmitSaveBill() {
    this.setState({ button_save_loading: true })
    if (this.props.modal_action == 'add') {
      var formData = {
        bill_date: Utility.formatDate2(this.state.bill_date),
        status_stock: this.state.status_stock,
        description: this.state.description,
        item_invoice_id: this.state.item_invoice_id.join(),
      };
      const url = Settings.baseUrl + '/lot_melt/';
      Utility.post(url, formData, (status, data) => {
        this.setState({
          button_save_loading: false,
        })
        if (status) {
          this.qrcodee.focus();
          this.props.onAddInvoice(data);
          this.setState({ button_save_title: 'บันทึก' });
          this.loadInvoiceItem();
          alert('บันทึกข้อมูลสำเร็จ!')
        }
        else {
          alert(data.detail)
          return
        }
      });
    } else {
      var formData2 = {
        description: this.state.description,
      };
      const url = Settings.baseUrl + '/lot_melt_update/' + this.props.lot_melt_detail.id + "/";
      Utility.patch(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  loadGoldPrice() {
    let d = new Date();
    let q = dateFormat(d, "yyyy-mm-dd HH:MM")
    let url = Settings.baseUrl + "/gold_price/?datetime=" + q ;
    this.setState({ is_loading: true })
    let gold_price_period = []
    Utility.get(url, (s, res) => {
      let text = ''

      for (let i in res){
        text = Utility.priceFormat(res[i].gold_99_buy) + ' (' + 'ปัจจุบัน ' + Utility.formatTimeSecond(res[i].record_date) + ' น.' +') '
        gold_price_period.push({key:res[i].id,value:res[i].id,text:text})
      }

      if (s && res.length > 0) {
        this.setState({
          gold_price: res[0],
          gold_price_period: gold_price_period,
          gold_price_value: gold_price_period.length != 0 ? gold_price_period[0].value:0,
          is_loading: false,
        })
      }
    });
  }

  loadInvoiceItem(reload) {
    var lot_melt = Utility.getFetch(Settings.baseUrl + '/lot_melt/' + this.props.lot_melt_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/lot_melt_item_list/?lot_melt_id=' + this.props.lot_melt_detail.id);
    this.setState({ loader: true });
    this.loadGoldPrice()
    Promise.all([lot_melt, items]).then((values) => {
      // set invoice detail
      let item = values[0];
      let description = this.state.description
      let lot_melt_item = values[1];
      let data_item_invoice = [];
      for (var i=0; i<lot_melt_item.length; i++) {
        data_item_invoice.push(lot_melt_item[i].invoice_melt);
      }
      this.setState({
        number: item.number,
        description: item.description,
        bill_date: moment(item.lot_date),
        item_invoice: this.setFieldValue(data_item_invoice),
        button_save_title: 'บันทึก',
        status_stock: item.status_stock,
        button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y',
        bill_title: <span>{<span>แก้ไขรายการรวมบิลส่งหลอม</span>} </span>,
        loader: false,
        button_print_disabled: false,
        is_disable_qrcode: item.status_stock == 'Y',
        lot_melt_id: item.id,
        item_lot_melt: item,
      });
      if (reload == true) {
        this.setState({
          description: description,
        })
      }
    });
  }

  setFieldValue(item) {
    let weight_out_total = 0;
    for (let i = 0; i < item.length; i++) {
      var item_weight_out = item[i].weight_out;
      var check_format_date = moment(item[i].bill_date, 'DD/MM/YYYY').isValid();
      item[i]['weight'] = item[i].weight
      item[i]['weight_real'] = item[i].weight_real
      item[i]['weight_out'] = item[i].weight_out
      item[i]['gold_price'] = Utility.numberFormat(item[i].gold_price)
      if (!check_format_date) {
        item[i]['bill_date'] = Utility.formatDate(item[i].bill_date)
      }
      weight_out_total += parseFloat(item_weight_out);
    }
    this.setState({weight_out_total:weight_out_total});
    return item;
  }


  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      btn_stock: true
    });

    if (this.props.modal_action == 'edit') {
      this.loadInvoiceItem()
    }
    this.loadGoldPrice()
  }

  onDeleteProduct(e, d) {
    let data = this.state.item_invoice[d.positon];
    if (this.props.modal_action == 'add') {
      const item_invoice = this.state.item_invoice;
      const index = item_invoice.indexOf(data);
      item_invoice.splice(index, 1);
      const item_invoice_id = this.state.item_invoice_id;
      const index_invoice_id = item_invoice_id.indexOf(data.id);
      item_invoice_id.splice(index_invoice_id, 1);
      this.setState({ item_invoice: this.setFieldValue(item_invoice), item_invoice_id });
    } else {
      if (this.state.status_stock == 'N') {
        const url = Settings.baseUrl + '/lot_melt_item_del/' + data.id + '/';
        Utility.delete(url, (status, data) => {
          this.setState({ button_product_loading: false })
          this.loadInvoiceItem(true)
          if (!status) {
            alert(data.detail)
            return
          }
        });
      } else {
        alert('ไม่สามารถลบได้ เนื่องจากอัพเดทสต๊อกแล้ว');
      }
    }
  }

  handelActionItemCell(e, d) {
    if (d.action == 'delete') {
      if (window.confirm('ยืนยันลบรายการนี้'))
        this.onDeleteProduct(e, d)
    }
  }

  submitUpdateStock() {
    this.setState({ QRCODE: '' })
    if (window.confirm('ยืนยันอนุมัติและอัพเดทสต็อก')) {
      this.setState({
        button_update_stoick_loading: true,
        button_save_loading: true,
      })
      var formDataLotMeltUpdate = {
        description: this.state.description,
      };
      const url_lot_melt_update = Settings.baseUrl + '/lot_melt_update/' + this.props.lot_melt_detail.id + "/";
      Utility.patch(url_lot_melt_update, formDataLotMeltUpdate, (status, data) => {
        var formDataStockUpdate = {
          
        };
        const url = Settings.baseUrl + '/lot_melt/' + this.props.lot_melt_detail.id + '/stock_update/';
        Utility.patch(url, formDataStockUpdate, (status, data) => {
          this.loadInvoiceItem();
          this.setState({
            button_update_stoick_loading: false,
            button_save_loading: false,
          })
          alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
        });
      });
    }
  }

  handlerQRCODE(e, d) {
    this.setState({ QRCODE: d.value });
  }

  submitQRcode() {
    let data = []
    data['number'] = this.state.QRCODE.trim()
    data['status_stock'] = 'Y'
    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + '/invoices_melt/?' + qrstring);
    Promise.all([qrcode]).then((values) => {
      let item = values[0]
      if (item.length == 0) {
        alert('ไม่พบเลขที่บิลที่เลือก')
      } else {
        if (this.state.bill_date < moment(item[0].bill_date)) {
          alert('เลขที่บิลที่เลือกยังไม่ถูกสร้าง')
        }
        else {
          if (this.props.modal_action == 'add') {
            var data_lot_melt_item = [];
            data_lot_melt_item['invoice_melt'] = item[0].id;
            data_lot_melt_item['is_enabled'] = 1;
            let qrstring = Utility.jsonToQueryString(data_lot_melt_item);
            var lot_melt_item_list = Utility.getFetch(Settings.baseUrl + '/lot_melt_item_list/?' + qrstring);
            Promise.all([lot_melt_item_list]).then((values) => {
              let lot_melt_item = values[0];
              if (lot_melt_item.length > 0) {
                alert('ไม่สามารถเพิ่มสินค้าได้อีกครั้ง เนื่องจากถูกลงรายการไว้ที่เลขที่ Lot : '+lot_melt_item[0].lot_melt.number)
              } else {
                var data_invoice_melt = this.state.item_invoice;
                var data_invoice_melt_id = this.state.item_invoice_id;
                if (data_invoice_melt.some(k=>k.id == item[0].id)) {
                  alert("ไม่สามารถเพิ่มสินค้าซ้ำได้");
                } else {
                  data_invoice_melt.push(item[0]);
                  data_invoice_melt_id.push(item[0].id);
                  this.setState({ item_invoice: this.setFieldValue(data_invoice_melt), item_invoice_id: data_invoice_melt_id });
                }
              }
            })
          } else {
            let formData = {
              invoice_id: item[0].id,
              lot_melt_id: this.state.lot_melt_id
            }
            const url = Settings.baseUrl + '/lot_melt_item/';
            Utility.post(url, formData, (status, data) => {
              this.setState({
                button_save_loading: false,
                button_save_title: 'บันทึก'
              })

              if (!status) {
                alert(data.detail)
                return
              }
              this.loadInvoiceItem(true)
            });
          }
        }
      }
      this.setState({
        QRCODE: ''
      })
    })
  }

  // async handlerVoidBill() {
  //   this.setState({
  //     button_update_stoick_loading: true,
  //     button_save_loading: true,
  //   })
  //   let description = prompt("กรุณาระบุหมายเหตุ");
  //   if (description == null || description == "") {
  //     return;
  //   }
  //   const data_ = { description: description };

  //   if (description.length > 255) {
  //     alert("ความยาวหมายเหตุต้องไม่เกิน 255 ตัวอักษร");
  //     return;
  //   }
  //   if (!window.confirm('ยืนยันยกเลิกบิล!'))
  //     return
  //   if (this.state.description != "") {
  //     description = this.state.description + " " + description;
  //   }
  //   const _data = { description: description };
  //   const url = Settings.baseUrl + '/lot_melt/' + this.props.lot_melt_detail.id + '/void/';
  //   Utility.patch(url, _data, (status, data) => {
  //     this.loadInvoiceItem();
  //     this.setState({
  //       button_update_stoick_loading: false,
  //       button_save_loading: false,
  //     })
  //     if (status) {
  //       alert('ยกเลิกรายการสำเร็จ');
  //     } else {
  //       alert(data.detail);
  //     }
  //   });
  // }

  render() {
    let items = this.state.item_invoice;
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.bill_title} {this.state.number && <span id='textBillID'>[เลขที่ Lot {this.state.number}]</span>}
              <Header.Subheader>
                <span><Icon name={this.state.status_stock == 'N' ? 'warning circle' : 'check circle'} color={this.state.status_stock == 'N' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form className=' fluid' size='small' >
                        <Form.Field width={16}>
                          <label>วันที่</label>
                          <DatePicker
                            id='date'
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.bill_date}
                            readOnly
                            onChange={(date) => {
                              date = date ? date : moment()
                              this.setState({ bill_date: date });
                            }}
                            disabled={true}
                          />
                        </Form.Field>
                        <Form.TextArea 
                          label='หมายเหตุ' 
                          placeholder='หมายเหตุ'
                          width={16} 
                          value={this.state.description} 
                          onChange={(e, v) => {
                          this.setState({ description: v.value })
                          }}
                          maxLength={255} 
                        />
                        <Form.Dropdown 
                          label='ราคาทอง' fluid selection width={16}
                          options={this.state.gold_price_period}
                          value={this.state.gold_price_value}
                          disabled={true}
                          style={{'word-break':'break-word'}}
                          openOnFocus={false}
                        />
                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={8}>
                            <Header floated='left' as='h3'>รายการสินค้า</Header>
                          </Form.Field>

                          <Form.Field width={8} floated='right'>
                            <label>เลขที่บิล</label>
                            {this.state.status_stock === 'N' ?
                              <Input
                                ref={(c) => {
                                  this.qrcodee = c
                                }}
                                autoFocus={1}
                                placeholder='เลขที่บิล'
                                value={this.state.QRCODE}
                                onChange={this.handlerQRCODE}

                                onKeyPress={(e) => {

                                  if (e.key == 'Enter') {
                                    this.submitQRcode()
                                  }
                                }}
                                className='text-right' />
                              :
                              <Input
                                disabled={1}
                                placeholder='เลขที่บิล'
                                className='text-right' />
                            }
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <BillItemTable items={items} table_width={this.state.table_width} onActionItemCell={this.handelActionItemCell}
                       />
                       <br/>
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input id='weight_out_total' label='น้ำหนักชั่งส่งหลอม' placeholder='' className='text-right' width={4} value={Utility.weightFormat(this.state.weight_out_total)} readOnly />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>

        </Modal.Content>
        <Modal.Actions>
          {/* {this.props.modal_action == "edit" && this.state.status_stock === 'Y' &&
            <Button
              color="red"
              size="small"
              icon="cancel"
              floated="left"
              // disabled={this.state.is_void == 1}
              labelPosition="left"
              id="cancel-this"
              onClick={(e) => {
                this.handlerVoidBill();
              }}
              content="ยกเลิกรายการนี้"
            />
          } */}
          <Button
            id='btnPrint'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ open_print: true })
            }}
            content='พิมพ์' />
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.handelSubmitSaveBill();
            }}
            className={this.state.button_save_loading ? 'loading' : ''}
            content={this.state.button_save_title} />
          <Button
            id='btnUpdate'
            size='small'
            icon='lightning'
            color='red'
            onClick={(e) => {
              this.submitUpdateStock();
            }
            }
            className={this.state.button_update_stoick_loading ? 'loading' : ''}
            disabled={this.state.button_update_stoick_disabled}
            content='อนุมัติและอัพเดทสต๊อก' />

        </Modal.Actions>
        {this.state.open_print
          ? <BillPrintPreview
            item_lot_melt={this.state.item_lot_melt}
            items={items}
            onClose={() => {
              this.setState({ open_print: false })
            }} />
          : ''}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(BillModal)