import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Loader, Dimmer, Modal, Table
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      search: {
        start_date: moment(),
        end_date: moment(),
      },
      items: [],
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }


  componentDidMount() {
    this.setState({ st: true })
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        search: {
          start_date: moment(temp_start, 'DD/MM/YYYY'),
          end_date: moment(temp_end, 'DD/MM/YYYY')
        }
      })
    }
  }

  resetForm(e) {
    this.setState({
      status: 'all',
      search: {
        start_date: moment(),
        end_date: moment(),
      },
      group: true,
    })
  }

  async handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)
    search.start_date = Utility.formatDate2(search.start_date)
    search.end_date = Utility.formatDate2(search.end_date)
    search.start_date = Utility.formatDate2(search.start_date)
    search.end_date = Utility.formatDate2(search.end_date)
    let qrstring = Utility.jsonToQueryString(search)
    this.setState({
      loading: true
    })

    var ledger = Utility.getFetch(Settings.baseReportUrl + '/report/ledger-transfer-sum/?' + qrstring);
    Promise.all([ledger]).then((values) => {
      this.setState({
        loading: false,
        items: values[0]
      });
    });
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search,
      items: []
    })
  }
  render() {
    let title = 'รายงานสรุปเงินโอน';
    let filename = `summary-ledger-transfer-${moment().format('DDMMYYYY')}`;

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }

    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center',
      'width': '200px'

    }
    const borderRight = {
      'border-right': '1px'
    }
    const textU = {
      'text-decoration': 'underline'
    }
    let summary_total = 0
    let summary_amount = 0
    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>{title}</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >
            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
              <>
                <Form.Field>
                  <label>จากวันที่</label>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    value={this.state.search.start_date}
                    selected={this.state.search.start_date}
                    onChange={(date) => {
                      date = date ? date : moment()
                      if (this.state.search.end_date < date) {
                        this.handlerInput(null, {
                          name: 'end_date',
                          value: date
                        });
                      }
                      this.handlerInput(null, {
                        name: 'start_date',
                        value: date
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field >
                  <label>ถึงวันที่</label>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    value={this.state.search.end_date}
                    selected={this.state.search.end_date}
                    minDate={this.state.search.start_date}
                    onChange={(date) => {
                      date = date ? date : moment()
                      this.handlerInput(null, {
                        name: 'end_date',
                        value: date
                      });
                    }}
                  />
                </Form.Field>
              </>
              <Button type='button' onClick={(e) => {
                this.setState({
                  open: true
                })
                this.handlerSubmit(e)
              }}>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='paperA4-portrait'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan={4} ><center>{title}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell colSpan={4} ><center>วันที่ {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                    </Table.Row>

                    <Table.Row>
                      <Table.HeaderCell style={textLeft}>บัญชีธนาคาร</Table.HeaderCell>
                      <Table.HeaderCell style={textLeft}>สาขา</Table.HeaderCell>
                      <Table.HeaderCell style={textRight}>จำนวนรายการ</Table.HeaderCell>
                      <Table.HeaderCell style={textRight}>ยอดเงินรวม</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {
                      this.state.items.map((row, i) => {
                        let total = 0
                        let amount = 0
                        return (
                          <>
                            <Table.Row>
                              <Table.Cell style={textLeft} colSpan='4'>{row.account}</Table.Cell>
                            </Table.Row>
                            {
                              row.branch.map((row2, i) => {
                                total += row2.total
                                summary_total += row2.total
                                amount += row2.amount
                                summary_amount += row2.amount
                                return (<Table.Row key={i}>
                                  <Table.Cell></Table.Cell>
                                  <Table.Cell style={textLeft}>{row2.name}</Table.Cell>
                                  <Table.Cell style={textRight}>{Utility.numberFormat(row2.amount)}</Table.Cell>
                                  <Table.Cell style={textRight}>{Utility.priceFormat(row2.total)}</Table.Cell>
                                </Table.Row>)
                              }
                              )
                            }
                            <Table.Row >
                              <Table.Cell style={textRight} colSpan='2'><b>รวม</b></Table.Cell>
                              <Table.Cell style={textRight}><b>{Utility.numberFormat(amount)}</b></Table.Cell>
                              <Table.Cell style={textRight}><b>{Utility.priceFormat(total)}</b></Table.Cell>
                            </Table.Row>
                          </>
                        )
                      }
                      )
                    }
                    <Table.Row >
                      <Table.Cell style={textRight} colSpan='2'><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                      <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_amount)}</b></div></Table.Cell>
                      <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_total)}</b></div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(() => {
                mywindow.close();
              }, 2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)