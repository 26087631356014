/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                date: moment(),
            },
            items: [],
            total_weight: 0,
            total_value: 0,
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
    }

    resetForm(e) {
        this.setState({
            search: {
                date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date = Utility.formatDate2(search.date)
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/gold_99/stock/?&columnKey=stock__number&sortDi=ASC&" + fromData

        this.setState({
            open: true,
            loading: true,
            items: [],
            total_value: 0,
            total_weight: 0,
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setFieldValue(d)
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        let total_weight = 0
        let total_value = 0
        for (let i in _items) {
            let item = _items[i]
            item['vendor_name'] = item.stock.vendor
            item['weight_remain'] = Number(item.weight_remain)
            item['stock_number'] = item.stock.number
            item['stock_cost'] = Math.round(Number(item.stock.cost) * 0.0656 * 100) / 100
            item['stock_cost_bath'] = Math.round(Number(item.stock.cost) * 100) / 100
            item['cost_value'] = Math.round(((Math.round(Number(item.stock.cost) * 100) / 100) * 0.0656 * item.weight_remain) * 100) / 100

            total_weight += item.weight_remain
            total_value += item.cost_value
            items.push(item)
        }

        this.setState({
            loading: false,
            total_weight: total_weight,
            total_value: total_value,
            items: items,
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = 'รายงานสต็อกทอง 99.99';
        let filename = 'stock_gold_99';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let s_in = 0
        let s_out = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.date}
                                    selected={this.state.search.date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center id='date'>ณ วันที่ {Utility.formatDate(this.state.search.date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>เลขที่บิลนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>โรงงาน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>น้ำหนักคงเหลือ(กรัม)</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนทอง(กรัม)</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ต้นทุนทอง(บาท)</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>มูลค่าต้นทุน</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {this.state.items.map((row, i) => {
                                        return (<Table.Row key={i}>
                                            <Table.Cell style={textCenter}>{row.stock_number}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.vendor_name}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_remain)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.stock_cost)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.stock_cost_bath)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_value)}</Table.Cell>
                                        </Table.Row>)
                                    })}
                                    <Table.Row >
                                        <Table.Cell colSpan='2' style={textRight}><b>รวม</b></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.weightFormat(this.state.total_weight)}</div></b></Table.Cell>
                                        <Table.Cell ></Table.Cell>
                                        <Table.Cell ></Table.Cell>
                                        <Table.Cell style={textRight}><b><div style={textU}>{Utility.priceFormat(this.state.total_value)}</div></b></Table.Cell>
                                    </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)