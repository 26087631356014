/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import image from '../../images/image.png'
import { connect } from 'react-redux';
import {
    Form,
    Button,
    Confirm, Dimmer, Loader,
    Header,
    Pagination,
    Image,
    Input,
    Icon,
    Dropdown,
    Label,
    Popup,
    Checkbox,
    Accordion,
    Grid,
    Modal,
} from 'semantic-ui-react';

class RewardList extends Component {
    constructor(props) {
        super(props);
        this.limit = 10
        this.state = {
            items: [],
            text: '',
            status: 'A',
            select: [],
            item_edit: null,
            total: 0,
            action: '',
            loading: false,
            products: [],
            products_select: [],
            q: '',
            amount: '',
            loading_search: false,
            select: '',
            last_page: 1,
        }

        this.setFieldValue = this.setFieldValue.bind(this)
        this.loadMenu = this.loadMenu.bind(this)
        this.submitData = this.submitData.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.saveImage = this.saveImage.bind(this)
        this.loadProduct = this.loadProduct.bind(this)
        this.reorder = this.reorder.bind(this)
        this.getProductSelect = this.getProductSelect.bind(this)

    }

    componentDidMount() {
        this.loadMenu()
    }

    loadProduct(page = 1, search = '') {
        this.setState({
            loader_active: true,
        });
        let filter = search == '' ? '' : '&q=' + search
        var product = Utility.getFetch(Settings.baseUrl + '/reward/product/?instock=1&perPage=100&page=' + page + '&status=O&' + this.state.item_edit + filter + '&not_in=' + this.state.select);
        Promise.all([product]).then((values) => {
            if (values[0].status) {
                this.setState({
                    loader_active: false,
                    products: values[0].products.data,
                    current_page: values[0].products.meta.current_page,
                    last_page: values[0].products.meta.last_page,
                    amount: values[0].products.meta.total,
                });
            }
        });
    }

    saveImage() {
        this.setState({ loading: true })
        let dashboard_icon_path = this.uploadImage(this.state.icon_image, 'dico')
        Promise.all([dashboard_icon_path]).then((values) => {
            let formData = {}
            formData['query'] = this.state.item_edit
            formData['name'] = this.state.name
            formData['subname'] = this.state.subname
            formData['perPage'] = this.state.perPage
            if (values[0]) {
                formData['icon'] = values[0]
            }
            this.submitData(formData)
        });
    }

    submitData(data) {
        let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/dashboard/', data)
        Promise.all([r]).then((values) => {
            this.setState({ item_edit: null, loading: false })
            this.loadMenu()
        })
    }

    getProductSelect() {
        let select = []
        for (let i in this.state.products_select) {
            select.push(this.state.products_select[i].id)
        }
        this.setState({
            select: JSON.stringify(select)
        }, () => {
            this.loadProduct()
        })
    }

    async uploadImage(image, kind) {
        if (image) {
            var formData = new FormData();
            formData.append('image', image);
            formData.append('kind', kind);
            const url = Settings.baseUrl + '/upload_reward_image/';
            const res = await Utility.postUploadAsync(url, formData)
            if (res.status) {
                let ret = res.data.image
                return ret
            } else {
                return null
            }
        } else {
            return null
        }
    }

    loadMenu() {
        this.setState({
            loader_active: true
        });
        var query = Utility.getFetch(Settings.baseUrl + '/reward/dashboard/');
        Promise.all([query]).then((values) => {
            this.query = values[0].DASHBOARD_QUERY_DATA
            if (values[0].status) {
                this.setState({
                    loader_active: false,
                });
            }
            this.setFieldValue()
        })
    }

    reorder(data, direction) {
        let products_select = []
        let temp = null
        this.state.products_select.map((row, i) => {
            if (row.id == data.id) {
                if (direction == 'up') {
                    if (products_select.length != 0) {
                        temp = products_select.pop()
                        products_select.push(row)
                        products_select.push(temp)
                        temp = null
                    } else {
                        products_select.push(row)
                    }
                } else if (direction == 'down') {
                    temp = row
                } else {
                    products_select.push(row)
                }
            } else {
                products_select.push(row)
                if (temp != null) {
                    products_select.push(temp)
                    temp = null
                }
            }
        })
        this.setState({
            products_select: products_select
        }, () => {
            let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/dashboard_products/'
                , { 'query': this.state.item_edit, 'products': JSON.stringify(this.state.products_select) }
            )
        })
    }

    setFieldValue() {
        let query = this.query
        this.setState({ items: query, item_show: query.filter(q => q.show == true && !q.disabled ) })
    }

    render() {
        const items = this.state.items
        return (
            <div>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Header floated='left' as='h2'>จัดการสินค้าแนะนำ</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                    <Loader content='Loading' inverted />
                </Dimmer>
                <Accordion styled fluid>
                    {this.state.items.map((row, i) => <div key={i}>
                        <Accordion.Title
                            active={true}
                            index={i}
                        >
                            <Grid verticalAlign='middle' divided='vertically'
                                style={{
                                    color: row.disabled ? 'grey'
                                        :row.query.includes('category_id') ? 'red'
                                            : row.query.includes('type') ? 'blue'
                                                : row.query.includes('tag') ? 'green' : 'black'
                                }}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox toggle checked={row.show} onChange={(e, v) => {
                                            this.submitData({
                                                'query': row.query,
                                                'show': v.checked,
                                            })
                                        }} disabled={row.disabled}/>
                                    </Grid.Column>
                                    <Grid.Column width={6}

                                    >
                                        {this.props.auth.role == 'S' ?
                                            <a onClick={(e) => {
                                                this.setState({
                                                    item_edit: row.query,
                                                    icon_preview: row.icon,
                                                    name: row.name,
                                                    icon_image: null,
                                                    subname: row.subname,
                                                    perPage: row.perPage,
                                                    title: row.name,
                                                    q: '',
                                                    products_select: row.products ? row.products : [],
                                                    products: [],
                                                    amount: '',
                                                }, () => {
                                                    this.getProductSelect()
                                                })
                                            }}
                                            ><Icon name='edit' /></a>
                                            : ''}
                                        {row.name} ({row.total})
                                    </Grid.Column>
                                    {/* <Grid.Column width={2}>
                                        ชื่อรอง: {row.subname}
                                    </Grid.Column> */}
                                    <Grid.Column width={3}>
                                        ประเภท: {row.query.toString().split('=')[0]}
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        แสดง {row.perPage} รายการ
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image src={row.icon == null ? image : Settings.mediaUrl + row.icon} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {row.show && i != 0 && !row.disabled ?
                                            <Button icon
                                                size='mini'
                                                style={{ 'margin': '2px' }}
                                                onClick={(e, v) => {
                                                    this.submitData({
                                                        'query': row.query,
                                                        'position': 'up',
                                                    })
                                                }}
                                            >
                                                <Icon name='angle up' />
                                            </Button>
                                            : ''}
                                        {row.show && i + 1 != this.state.item_show.length && !row.disabled ?
                                            <Button icon
                                                size='mini'
                                                style={{ 'margin': '2px' }}
                                                onClick={(e, v) => {
                                                    this.submitData({
                                                        'query': row.query,
                                                        'position': 'down',
                                                    })
                                                }}
                                            >
                                                <Icon name='angle down' />
                                            </Button>
                                            : ''}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Accordion.Title>
                    </div>
                    )}
                </Accordion>
                {
                    this.state.item_edit != null &&
                    <Modal id='modalCreateBranch' open={true} size='fullscreen' /*dimmer='blurring'*/>
                        <Button
                            id='btnClose'
                            circular
                            icon='close'
                            basic
                            floated='right'
                            name=''
                            disabled={this.state.loading}
                            onClick={() => { this.setState({ item_edit: null }); this.loadMenu(); }} />
                        <Modal.Header>แก้ไข: {this.state.title}</Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Grid columns={2} divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form size='small' >
                                                <Form.Field>
                                                    <label>ชื่อหมวดหมู่</label>
                                                    <Input
                                                        onChange={(e) => {
                                                            this.setState({ name: e.target.value })
                                                        }}
                                                        value={this.state.name}
                                                        maxLength={30}
                                                    />
                                                </Form.Field>
                                            </Form>
                                            <br />
                                            {/* <Form size='small' >
                                    <Form.Field>
                                        <label>ชื่อรอง</label>
                                        <Input
                                            onChange={(e) => {
                                                this.setState({ subname: e.target.value })
                                            }}
                                            value={this.state.subname}
                                            maxLength={100}
                                        />
                                    </Form.Field>
                                </Form>
                                <br /> */}
                                            <Form size='small' >
                                                <Form.Field>
                                                    <label>จำนวนแสดง</label>
                                                    <Input
                                                        onChange={(e) => {
                                                            this.setState({ perPage: e.target.value })
                                                        }}
                                                        value={this.state.perPage}
                                                        maxLength={100}
                                                    />
                                                </Form.Field>
                                            </Form>
                                            <br />
                                            <Form size='small' >
                                                <Form.Field>
                                                    <label>Icon (50x50)</label>
                                                    <Input onChange={(e) => this.setState({ icon_image: e.target.files[0], icon_preview: URL.createObjectURL(e.target.files[0]) })} type="file" accept="image/*" />
                                                    <Image src={this.state.icon_image ? this.state.icon_preview : this.state.icon_preview ? Settings.mediaUrl + this.state.icon_preview : this.state.icon_preview} size='small' bordered />
                                                </Form.Field>
                                            </Form>
                                            {this.state.products_select.length != 0 &&
                                                <Accordion styled fluid>
                                                    {this.state.products_select.map((row, i) => <div key={i}>
                                                        <Accordion.Title
                                                            active={true}
                                                            index={i}
                                                        >
                                                            <Grid verticalAlign='middle' divided='vertically'>
                                                                <Grid.Row>
                                                                    <Grid.Column width={2}>
                                                                        <Checkbox toggle checked={this.state.products_select.filter(p => p.id == row.id).length != 0}
                                                                            onChange={(e, v) => {
                                                                                if (v.checked) {
                                                                                    let products_select = this.state.products_select
                                                                                    products_select.push(row)
                                                                                    this.setState({
                                                                                        products_select: products_select
                                                                                    }, () => {
                                                                                        let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/dashboard_products/'
                                                                                            , { 'query': this.state.item_edit, 'products': JSON.stringify(this.state.products_select) }
                                                                                        )
                                                                                        this.getProductSelect()
                                                                                    })
                                                                                } else {
                                                                                    let products_select = this.state.products_select.filter(p => p.id != row.id)
                                                                                    this.setState({
                                                                                        products_select: products_select
                                                                                    }, () => {
                                                                                        let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/dashboard_products/'
                                                                                            , { 'query': this.state.item_edit, 'products': JSON.stringify(this.state.products_select) }
                                                                                        )
                                                                                        this.getProductSelect()
                                                                                    })
                                                                                }
                                                                            }} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={2}>
                                                                        <Image src={row.main_image} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={10}>
                                                                        {row.name}  <br/><span style={{'color':'red'}}>{Utility.priceFormat(row.point)}</span>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={2} style={{'display':'flex','align-items':'center'}}>
                                                                        {i != 0 ?
                                                                            <Button icon
                                                                                size='mini'
                                                                                style={{ 'margin': '2px' }}
                                                                                onClick={(e, v) => {
                                                                                    this.reorder(row, 'up')
                                                                                }}
                                                                            >
                                                                                <Icon name='angle up' />
                                                                            </Button>
                                                                            : ''}
                                                                        {i + 1 != this.state.products_select.length ?
                                                                            <Button icon
                                                                                size='mini'
                                                                                style={{ 'margin': '2px' }}
                                                                                onClick={(e, v) => {
                                                                                    this.reorder(row, 'down')
                                                                                }}
                                                                            >
                                                                                <Icon name='angle down' />
                                                                            </Button>
                                                                            : ''}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Accordion.Title>
                                                    </div>
                                                    )}
                                                </Accordion>
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Input
                                                fluid
                                                style={{ 'margin-bottom': '2%', 'margin-top': '2%' }}
                                                icon placeholder='ค้นหา...'
                                                onChange={(e) => {
                                                    this.setState({ q: e.target.value, loading_search: true }, () => {
                                                        clearTimeout(this.timeout)
                                                        this.timeout = setTimeout(() => {
                                                            this.loadProduct(1, this.state.q)
                                                            this.setState({ loading_search: false })
                                                        }, 1000)
                                                    })
                                                }}
                                                loading={this.state.loading_search}
                                            >
                                                <input />
                                                <Icon name='search' />
                                            </Input>
                                            ผลการค้นหา {this.state.amount} รายการ
                                            <Accordion styled fluid>
                                                {this.state.products.map((row, i) => <div key={i}>
                                                    <Accordion.Title
                                                        active={true}
                                                        index={i}
                                                    >
                                                        <Grid verticalAlign='middle' divided='vertically'>
                                                            <Grid.Row>
                                                                <Grid.Column width={2}>
                                                                    <Checkbox toggle checked={this.state.products_select.filter(p => p.id == row.id).length != 0}
                                                                        onChange={(e, v) => {
                                                                            if (v.checked) {
                                                                                let products_select = this.state.products_select
                                                                                products_select.push(row)
                                                                                this.setState({
                                                                                    products_select: products_select
                                                                                }, () => {
                                                                                    let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/dashboard_products/'
                                                                                        , { 'query': this.state.item_edit, 'products': JSON.stringify(this.state.products_select) }
                                                                                    )
                                                                                    this.getProductSelect()
                                                                                })
                                                                            } else {
                                                                                let products_select = this.state.products_select.filter(p => p.id != row.id)
                                                                                this.setState({
                                                                                    products_select: products_select
                                                                                }, () => {
                                                                                    let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/dashboard_products/'
                                                                                        , { 'query': this.state.item_edit, 'products': JSON.stringify(this.state.products_select) }
                                                                                    )
                                                                                    this.getProductSelect()
                                                                                })
                                                                            }
                                                                        }} />
                                                                </Grid.Column>
                                                                <Grid.Column width={2}>
                                                                    <Image src={row.main_image} />
                                                                </Grid.Column>
                                                                <Grid.Column width={12}>
                                                                    {row.name} <br/><span style={{'color':'red'}}>{Utility.priceFormat(row.point)}</span>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Accordion.Title>
                                                </div>
                                                )}
                                                {this.state.last_page != 1 &&
                                                    <div>
                                                        <Accordion.Title
                                                            active={true}
                                                        >
                                                            <Pagination
                                                                activePage={this.state.current_page}
                                                                totalPages={this.state.last_page}
                                                                onPageChange={(e, { activePage }) => {
                                                                    this.loadProduct(activePage, this.state.q)
                                                                }}
                                                                ellipsisItem={null}
                                                            />
                                                        </Accordion.Title>
                                                    </div>
                                                }
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <br />
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button id='btnSave' size='small' primary
                                onClick={this.saveImage}
                                loading={this.state.loading}
                                disabled={this.state.loading}
                                className={this.state.button_class}>บันทึก</Button>
                            <Button id='btnCancel' size='small' disabled={this.state.loading} onClick={(e) => { e.preventDefault(); this.setState({ item_edit: null }); this.loadMenu(); }}>ยกเลิก</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps)(RewardList)