/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';

class ImportStaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields([]),
            is_loading: false,
            data: [],
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]
            let url = Settings.baseUrl + '/staffs/'
            Utility.post(url,data,(e,res)=>{
                position++;
                if(position<data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })

        }
        run()
    }

    setData(data){

    }

    setFields(branch_options){
        return [
            {
                name: 'code',
                title: '*รหัสพนักงาน',
                type: 'string',
                unique: true,
                required: true
            },
            {
                name: 'branch',
                title: '*สาขา',
                type: 'string',
                unique: true,
                required: true,
                options: branch_options
            },
            {
                name: 'name',
                title: '*ชื่อพนักงาน',
                type: 'string',
                unique: true,
                required: true,
            },
            {
                name: 'address',
                title: 'ที่อยู่',
                type: 'string'
            },
            {
                name: 'phone',
                title: 'เบอร์โทร',
                type: Number,
            }
        ]
    }

  componentDidMount() {
    
    Utility.get(Settings.baseUrl + '/branches/?is_enabled=1', (status, data) => {
        var options_branch = [];
        for (var i in data) {
          options_branch.push({
            key: data[i].id,
            value: data[i].id,
            text: data[i].name,
          });
        }

        

      this.setState({
          fields: this.setFields(options_branch)
      })
    });
    for (var i in this.props.data) {
        this.state.data.push(this.props.data[i].code)
    }
  }

    render() {
        return (
            <div>
                <ImportCSV
                    name='Staff'
                    data={this.state.data}
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportStaff