/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Checkbox, Divider
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InputSearchLease from '../../Lease/InputSearchLease'
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            is_all: true,
            is_sort: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment()
            },
            lease_to: '',
            lease_from: '',
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }


            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
                lease_from: '',
                lease_to: '',
            },
            is_all: true,
            is_sort: true,
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if (search.lease_from) {
            search.lease_from = search.lease_from.trim()
            if (search.lease_from == '') {
                delete search['lease_from']
            }
        }
        if (search.lease_to) {
            search.lease_to = search.lease_to.trim()
            if (search.lease_to == '') {
                delete search['lease_to']
            }
        }
        if (!this.state.is_sort)
            search.sort = 'number'
        else
            search.sort = 'in_date'
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/lease_data/?" + fromData

        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {

            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.lease, d.lease_product, d.customer)
                })
            }
        })
    }

    setFieldValue(_items, lease_products, customers) {
        let items = []
        let no = 1
        for (let i in _items) {
            let item = _items[i]
            let lease_product = []
            const customer = customers.find(k => k.id === item.customer)

            for (let j = 0; j < lease_products.length; j++) {
                if (lease_products[j].lease == item.id) {
                    lease_product.push(lease_products[j])
                }
            }

            item['lease_product'] = lease_product
            item['customer'] = customer

            if (this.state.is_all == false) {
                if (item.status == 3 || item.status == 4)
                    continue
            }
            items.push(item)
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data

        let title = 'รายงานสต็อกขายฝากรายบิล';
        let filename = 'lease-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const divStyle2 = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
            border: '1px solid black',
        };
        const textRight2 = {
            'text-align': 'right',
            border: '1px solid black',
        }

        const textLeft2 = {
            'text-align': 'left',
            border: '1px solid black',
        }
        const textCenter2 = {
            'text-align': 'center',
            border: '1px solid black',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }


        let weight = 0
        let amount = 0
        let stock = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสต็อกขายฝากรายบิล</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>เลขที่บิลจาก</label>
                                <Input width={16} placeholder='เลขที่บิลจาก' name="lease_from" value={this.state.search.lease_from} onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field>
                                <label>เลขที่บิลถึง</label>
                                <Input width={16} placeholder='เลขที่บิลจาก' name="lease_to" value={this.state.search.lease_to} onChange={this.handlerInput} />
                            </Form.Field>
                            <Checkbox radio label='แสดงทั้งหมด' checked={this.state.is_all} onChange={(e, v) => { this.setState({ is_all: true }) }} />
                            <br />
                            <Checkbox radio label='แสดงเฉพาะรายการที่ไม่ได้คัดออกหรือไม่ได้ไถ่คืน' checked={!this.state.is_all} onChange={(e, v) => { this.setState({ is_all: false }) }} />
                            <Divider />
                            <Checkbox radio label='เรียงตามวันที่นำเข้า' checked={this.state.is_sort} onChange={(e, v) => { this.setState({ is_sort: true }) }} />
                            <br />
                            <Checkbox radio label='เรียงตามเลขที่ใบขายฝาก' checked={!this.state.is_sort} onChange={(e, v) => { this.setState({ is_sort: false }) }} />
                            <br />
                            <br />
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open2: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์รายงาน</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='8'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='8'><center>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='8'><center>ตั้งแต่วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันครบกำหนด</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนเงิน</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                                weight += parseFloat(item.weight)
                                                amount += parseInt(item.amount)
                                                stock += parseInt(item.lease_product.length)
                                            return (<Table.Row key={i}>
                                                        <Table.Cell style={textCenter}>{i+1}</Table.Cell>
                                                        <Table.Cell style={textCenter}>{item.number}</Table.Cell>
                                                        <Table.Cell style={textCenter}>{Utility.formatDate(item.start_date)}</Table.Cell>
                                                        <Table.Cell style={textCenter}>{Utility.formatDate(item.end_date)}</Table.Cell>
                                                        <Table.Cell style={textLeft} width={3}>{item.customer.name}</Table.Cell>
                                                        <Table.Cell style={textRight} width={3}>{item.lease_product.length}</Table.Cell>
                                                        <Table.Cell style={textRight}>{Utility.weightFormat(item.weight)}</Table.Cell> 
                                                        <Table.Cell style={textRight}>{Utility.priceFormat(item.amount)}</Table.Cell>
                                                    </Table.Row>
                                                )
                                        })}

                                        <Table.Row>
                                            <Table.Cell style={textRight} colSpan='5'><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='stock'>{Utility.numberFormat(stock)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='weight'>{Utility.weightFormat(weight)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b id='amount'>{Utility.priceFormat(amount)}</b></div></Table.Cell></Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.state.open2} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print2'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle2}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='10'><center id='date'>ตั้งแต่วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter2}>เลขที่ขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft2}>ชื่อ-สกุล</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight2}>ยอดฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight2}>น้ำหนัก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight2}>จำนวนสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter2}>เบอร์โทร</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter2} colSpan='4'>รายการต่อดอก</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textCenter} ></Table.HeaderCell> */}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((item, i) => {
                                            // if (item.head === true) {
                                                weight += parseFloat(item.weight)
                                                amount += parseInt(item.amount)
                                            // }
                                            return (<Table.Row key={i}>
                                                <Table.Cell style={textCenter2}>{i+1}</Table.Cell>
                                                <Table.Cell style={textLeft2}>{item.customer.name}</Table.Cell>
                                                <Table.Cell style={textRight2}>{Utility.priceFormat(item.amount)}</Table.Cell>
                                                <Table.Cell style={textRight2}>{Utility.weightFormat(item.weight)}</Table.Cell>
                                                <Table.Cell style={textRight2}>{item.lease_product.length}</Table.Cell>
                                                <Table.Cell style={textCenter2}>{item.phone}</Table.Cell>
                                                <Table.Cell style={textLeft2}></Table.Cell>
                                                <Table.Cell style={textLeft2}></Table.Cell>
                                                <Table.Cell style={textLeft2}></Table.Cell>
                                                <Table.Cell style={textLeft2}></Table.Cell> 
                                            </Table.Row>)
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print2').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open2: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)