const default_state = {
  is_ready: false,
  is_loading: true,
  list: [],
  filter: {},
  count: 0,
  totalPages: 0,
}
const bills_list = (state = default_state, action) => {
  switch (action.type) {
    case 'SET_BILL_LIST':
      return {
        ...state,
        is_ready: true,
        is_loading: false,
        list: action.list.results ? action.list.results : [],
        filter: action.filter,
        count: action.list.count ? action.list.count : 0,
        totalPages: action.list.results ? action.list.next == null && action.list.previous == null ? 0 : Math.ceil(action.list.count / 10) : 0
      }
    case 'SET_BILL_LIST_LOADING':
      return {
        ...state,
        is_loading: action.is_loading
      }
    case 'SEARCH_BILL_LIST':
      return {
        ...state,
        filter: action.filter,
      }
    default:
      return state
  }
}

export default bills_list