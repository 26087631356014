/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Radio
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      search: {
        user_type: "",
        user_id: "",
        user_name: "",
        start_date: moment(),
        end_date: moment(),
      },
      items: [],
      cal: [],
      new_data: [],
      user_type: [
        {
          value: "",
          key: 0,
          text: 'ทั้งหมด'
        },
        {
          value: "True",
          key: 1,
          text: 'พนักงาน'
        },
        {
          value: "False",
          key: 2,
          text: 'ลูกค้า'
        }
      ],
      choicedropdown:[
        {
            key: '1',
            text: 'ทั้งหมด',
            value: 'all',
          },{
            key: '2',
            text: 'สำเร็จ',
            value: 'success',
      },{
            key: '3',
            text: 'ยกเลิก',
            value: 'cancel',
      }],
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
    this.handlerDropdown = this.handlerDropdown.bind(this)
  }


  componentDidMount() {
    this.setState({ st: true })
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        search: {
          start_date: moment(temp_start, 'DD/MM/YYYY'),
          end_date: moment(temp_end, 'DD/MM/YYYY')
        }
      })
    }
  }
  handlerDropdown(event, value){
    this.setState({
            status: value.value,
        });
  }

  resetForm(e) {
    this.setState({
      status: 'all',
      search: {
        user_type: "",
        user_name: "",
        user_id: "",
        start_date: moment(),
        end_date: moment(),
      },
    })
  }

  async handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)
    search.start_date = Utility.formatDate2(search.start_date)
    search.end_date = Utility.formatDate2(search.end_date)
    let fromData = Utility.jsonToQueryString(search)
    var url = Settings.baseReportUrl + '/redeem_customer_list/?'
      var url = url + `start_date=${search.start_date}&end_date=${search.end_date}&`
      var url = url + `status=${this.state.status}`
    
    // let search_categories = []
    this.setState({
      loading: true
    })
    await Utility.get(url, (s, d) => {
      if (s == true) {
        this.setState({
          items: d,
        })
        this.setState({
          loading:false,
          open: true,
        })
      }
    })
    var url = Settings.baseReportUrl + "/customers/"
    Utility.get(url, (s, d) => {
      if (s == true){
        this.setState({
          all_customer: d.count
        })
      }
    })
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search,
      items:[]
    })
  }
  render() {
    let data = this.props.data
    let title = 'รายงานRedeemPoint';
    let filename = 'redeempoint';

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }

    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center',
      'width': '200px'

    }
    const borderRight = {
      'border-right': '1px'
    }
    const textU = {
      'text-decoration': 'underline'
    }
    let amount = 0
    let weight = 0
    let weight_b = 0
    let sell = 0
    let wage = 0
    let net = 0
    for (let i in this.state.items) {
      amount += parseFloat(this.state.items[i].amount)
      weight += parseFloat(this.state.items[i].weight)
      weight_b += parseFloat(this.state.items[i].weight_b)
      sell += parseFloat(this.state.items[i].sell)
      wage += (parseFloat(this.state.items[i].wage))
      net += (parseFloat(this.state.items[i].net))
    }
    let branch_name = ''
    let totalredeem = 0
    let totalpoints =0

    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>รายงาน Redeem Point</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >
            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
            <Form.Field>
              <Form.Dropdown
                    id='searchStatus'
                    placeholder={'เลือกดู'} fluid
                    label={'สถานะ'}
                    width={this.props.width}
                    placeholder={'เลือกดู'}
                    selection
                    name={this.props.name}
                    loading={this.state.isLoading}
                    isFetching={this.state.isLoading}
                    value={this.state.status}
                    options={this.state.choicedropdown}
                    disabled={this.state.isLoading}
                    onChange={this.handlerDropdown} />
              </Form.Field>
              <>
              <Form.Field>
                  <label>จากวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.start_date}
                      selected={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          if (this.state.search.end_date < date) {
                              this.handlerInput(null, {
                                  name: 'end_date',
                                  value: date
                              });
                          }
                          this.handlerInput(null, {
                              name: 'start_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              <Form.Field >
                  <label>ถึงวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.end_date}
                      selected={this.state.search.end_date}
                      minDate={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          this.handlerInput(null, {
                              name: 'end_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              </>
              {/* <Form.Field>
                <label>ชื่อลูกค้า</label>
                <Input placeholder="ชื่อลูกค้า" value={this.state.search.user_name} name="user_name" onChange={this.handlerInput} />
              </Form.Field>
              <Form.Field>
                <label>รหัสลูกค้า</label>
                <Input placeholder="รหัสลูกค้า" value={this.state.search.user_id} name="user_id" onChange={this.handlerInput} />
              </Form.Field>
              <Form.Field>
                <label>ประเภทลูกค้า</label>
                <Dropdown placeholder='- ทั้งหมด -' search selection options={this.state.user_type} value={this.state.search.user_type} name='user_type' onChange={this.handlerInput} />
              </Form.Field> */}
              <Button>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='paperA4-portrait'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan='10' ><center>รายงาน Redeem Point</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell colSpan='10' ><center>วันที่ {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan = '2' style={{textAlign: "left"}}>สถานะ : {this.state.status=='all'?'ทั้งหมด':this.state.status=='success'?'สำเร็จ':'ยกเลิก'}</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '8' ></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '5%'}}>No.</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '10%'}}>รหัสลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "left",width: '13%',minWidth: '13%',maxWidth: '300px'}}>ชื่อลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "left",width: '10%',minWidth: '10%',maxWidth: '250px'}}>พนักงานที่สร้างรายการ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '12%'}}>วันที่ทำรายการ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '12%'}}>เกมที่ร่วมสนุก</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '5%'}}>สถานะ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>จำนวน</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '5%'}}>แต้มที่ใช้</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '13%',minWidth: '13%',maxWidth: '300px'}}>หมายเหตุ</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {
                    this.state.items.map((row, i) =>{
                    totalredeem = totalredeem + parseInt(row.amount!== null?row.amount:'0') 
                    totalpoints = totalpoints + parseInt(row.status=='cancel'?'0':row.point)
                      return(<Table.Row key={i}>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '5%'}}>{i+1}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '10%'}}>{row.customer_code}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "left",minWidth: '13%',maxWidth: '300px',overflowWrap: 'break-word'}}>{row.customer_name}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "left",minWidth: '10%',maxWidth: '250px',overflowWrap: 'break-word'}}>{row.creator}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '12%'}}>{(Utility.formatDate(row.created_at))}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '12%'}}>{row.redeem_display}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '5%'}}>{row.status_display}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",maxWidth: '5%'}}>{parseInt(row.amount)}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",maxWidth: '5%'}}>{row.status=='cancel'?Utility.priceFormat('0'):Utility.priceFormat(row.point)}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "left",width:'13%',maxWidth: '300px',overflowWrap: 'break-word'}}>{row.remark}</Table.Cell>
                      </Table.Row>)
                      }
                      )
                    }
                    <Table.Row >
                      <Table.Cell colSpan = '6'> </Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "left"}}><b><u>รวมทั้งหมด</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{totalredeem}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.priceFormat(totalpoints)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' ></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(()=>{
                mywindow.close();
              },2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)