import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Pagination
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class StockNewGoldReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                date_lt: moment(),
            
            },
            item: [],
            total_item: 0,
            page_size: 5,
            activePage: 1,
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.loadDataInterval = setInterval(() => {
        this.loadReportStockNewGold(this.state.activePage)
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.loadDataInterval)
    }

    componentDidMount() {
        this.loadReportStockNewGold(this.state.activePage);
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ activePage: activePage })
        this.loadReportStockNewGold(activePage);
    }

    loadReportStockNewGold(page) {
        let url = Settings.baseReportUrl + `/stock_new_gold_list/?page=${page}&page_size=${this.state.page_size}`
        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    total_item: d.count,
                    item: d.results,
                    total_page: d.next == null && d.previous == null ? 0 : Math.ceil(d.count / this.state.page_size),
                })
            }
        })
    }

    resetForm(e) {
        this.setState({
            search: {
                date_lt: moment(),
            }
        })
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date_lt = Utility.formatDate2(search.date_lt)
        let url = Settings.baseUrl + "/stock_new_gold/" 
        this.setState({
            loading: true
        })
        Utility.post(url, search, (s, d) => {
            this.loadReportStockNewGold(1);
            this.setState({
                loading: false,
                activePage: 1,
            })
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    delReportStockNewGold(id) {
        if (window.confirm('ยืนยันลบ')) {
            let url = Settings.baseReportUrl + `/stock_new_gold_delete/${id}/`
            this.setState({
                loading: true
            })
            Utility.delete(url, (s, d) => {
                if (s == true) {
                    var tmppage =  this.state.item.length==1?this.state.activePage==1?1:this.state.activePage-1:this.state.activePage
                    this.loadReportStockNewGold(tmppage);
                    this.setState({
                        loading: false,
                        activePage: tmppage,
                    })
                }
            })
        }
    }
    

    render() {
        const tableStyle = {
            color: '#111',
            width: '100%',
            lineHeight: '20px',
        };
        const headerCellStyle = {
            textAlign: 'center',
            color: '#fff',
        }
        const tableHeaderStyle = {
            backgroundColor: '#db2828',
        }
        const textCenter = {
            textAlign: 'center'
        }
        return (
            <div>
                <Segment color='black' style={{
                    height: '90vh',
                    overflow: 'auto'
                }}>
                    <Form >
                        <Form.Group>
                            <Form.Field>
                                <Header floated='left' as='h4'>รายงานสรุปสต็อกทองใหม่รวมทุกสาขา</Header>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <div className="box-login">
                        <Segment textAlign='left' >
                            <Header size='small'>เงื่อนไขค้นหา</Header>
                            <Form size='small' onSubmit={this.handlerSubmit}>
                                <Form.Field >
                                    <label>วันที่</label>
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.search.date_lt}
                                        selected={this.state.search.date_lt}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            this.handlerInput(null, {
                                                name: 'date_lt',
                                                value: date
                                            });
                                        }}
                                        />
                                </Form.Field>
                                <Button>พิมพ์</Button>
                                <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                            </Form>
                        </Segment>
                    </div>
                    {this.state.total_item > 0 &&
                        <>
                            <Table basic style={tableStyle}>
                                <Table.Header style={tableHeaderStyle}>
                                    <Table.Row>
                                        <Table.HeaderCell style={headerCellStyle} width={6}>ชื่อรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>วันที่ออกรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>สถานะรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={4}>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.state.item.map((item) => (
                                        <Table.Row >
                                            <Table.Cell>{item.filename}</Table.Cell>
                                            <Table.Cell style={textCenter}>{`${Utility.formatDate(item.created_at)} ${Utility.formatTimeSecond(item.created_at)}`}</Table.Cell>
                                            <Table.Cell style={textCenter}>{item.status_display}</Table.Cell>
                                            <Table.Cell style={textCenter}>
                                                <Button onClick={()=>this.delReportStockNewGold(item.id)} color='red'><Icon name="trash"/>ลบ</Button>
                                                <Button target="__blank" href={`${Settings.mediaUrl}${item.subpath}`} color='blue' disabled={item.status!==1}><Icon name="cloud download"/>ดาวน์โหลด</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            <Form size='small'>
                                <Form.Group>
                                    <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.total_item)} />
                                    <Form.Field width={16}>
                                        <br />
                                        {this.state.total_page > 1 &&
                                            <Pagination
                                                floated='right'
                                                size='tiny'
                                                activePage={this.state.activePage}
                                                onPageChange={this.handlePaginationChange.bind(this)}
                                                totalPages={this.state.total_page}
                                            />
                                        }
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </>
                    }
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(StockNewGoldReport)