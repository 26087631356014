const default_state = {
    data: {},
  }
  const redeem_customer = (state = default_state, action) => {
    switch (action.type) {
      case 'SET_REDEEM_CUSTOMER':
        return {
          ...state,
          data: action.data,
        }
      case 'CLEAR_REDEEM_CUSTOMER':
        return {
          ...state,
          data: {},
        }
      default:
        return state
    }
  }
  
  export default redeem_customer