/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Button, Modal
} from 'semantic-ui-react';
import {hotkeys} from 'react-keyboard-shortcuts'
import Utility from '../../../Utility';

class LineEmpty extends Component{
    render(){
        return (<tr style={{'height': '18px'}}>
        <td colSpan="6"></td>
    </tr>)
    }
}

class LineEND extends Component{
    render(){
        return (<tr style={{'height': '18px'}}>
        <td colSpan="6">--- End ---</td>
    </tr>)
    }
}

class LineText extends Component{
    render(){

        const {item} = this.props
        return (<tr style={{'height': '18px'}} >
        <td style={{'width': '1.9cm','text-align': 'center'}}>{Utility.formatDate(item.record_date)}</td>
        <td style={{'width': '1cm','text-align': 'center'}}>{item.code}</td>
        <td style={{'width': '3.3cm','text-align': 'right'}}>{item.withdraw==0?'':`***${Utility.priceFormat(item.withdraw)}**`}&nbsp;</td>
        <td style={{'width': '3.3cm','text-align': 'right'}}>{item.deposit==0?'':`***${Utility.priceFormat(item.deposit)}**`}</td>
        <td style={{'width': '3.5cm','text-align': 'right'}}>***{Utility.priceFormat(item.balance)}**</td>
        <td style={{'width': '1cm','text-align': 'center'}}>{item.id}</td>
    </tr>)
    }
}

class PrintPreviewModal extends Component {

    constructor(props){
        super(props)

        this.handlerPrint = this.handlerPrint.bind(this)

    }

    componentDidMount(){
        setTimeout(()=>{
            this.handlerPrint()
        },800)
        setTimeout(()=>{
            this.props.onClose()
        },1800)
    }

    setItem(items){
        let _items = []

        for(let i in items){

            var item = items[i]
            if(item.kind == 'D' || item.kind == 'O'){
                item.deposit = item.amount
                item.withdraw = 0
            }else if(item.kind == 'W' || item.kind == 'C'){
                item.deposit = 0
                item.withdraw = item.amount
            }

            _items.push(item)
        }

        return _items
    }

    handlerPrint(){
        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
        mywindow.document.write('<style>@page {size: A5;margin: 0mm;}@media only print { body { font-family: monospace; }}</style>');
        mywindow.document.write('<html><head><title>' +this.props.title + '</title>');
        mywindow.document.write('</head><body style="font-size: 11px;font-family: monospace;">');
        mywindow.document.write(document.getElementById('view-print').innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        setTimeout(()=>{
            mywindow.close();
        },2000)
    }

    render() {
        const {items,start_line} = this.props.data
        const {is_end_page} = this.props
        const _items = this.setItem(items)
        const emptyLineList = []
        const textLineList = []
        let line_num = 0
        for(let i=0;i< start_line-1;i++){
            emptyLineList.push(<LineEmpty/>)
            line_num++
            if(line_num==11){
                emptyLineList.push(<LineEmpty/>)
                emptyLineList.push(<LineEmpty/>)
                line_num+=2
            }
        }

        {_items.map((item,i)=>{
            line_num ++
            textLineList.push(<LineText key={i} item={item} line={line_num} />)
            if(line_num==11){
                textLineList.push(<LineEmpty/>)
                textLineList.push(<LineEmpty/>)
                line_num+=2
            }
        })}

        return (<div>
            <Modal  open={true} /*dimmer='blurring'*/>
            <Button
            id='closePreview'
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={this.props.onClose}/>
            <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    <div id='view-print'>
                        <div  id='paperbook' style={{ 'page-break-before': 'always',marginLeft:'0.2cm'}}>
                                <table style={{'font-size': '10px','font-family': 'monospace', 'line-height': '16px',marginTop:'3.6cm' }} >
                                    <tbody >
                                        {emptyLineList}
                                        {textLineList}
                                        {is_end_page && <LineEND/>}
                                    </tbody>
                                </table>
                            </div>
                    </div>
                    
                </Modal.Content>
    
                <Modal.Actions>
                    <Button primary icon='print' size='small' onClick={this.handlerPrint} labelPosition='right' content='พิมพ์ (F8)' />
                </Modal.Actions>
            </Modal>
        </div>)
    }

    hot_keys = {
        'f8': {
          priority: 1,
          handler: (event) => {
            this.handlerPrint()
            event.preventDefault()
          }
        },
        'esc': {
            priority: 1,
            handler: (event) => {
                this.props.onClose()
              event.preventDefault()
            }
          }
      }
}

export default hotkeys(PrintPreviewModal)