/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import Redeem from '../Redeem';
import RedeemQRCode from '../Redeem/RedeemQRCode';
import NavBar from '../NavBar'

import {BrowserRouter as Router, Route, Link,Redirect} from 'react-router-dom'
import {Dimmer, Loader, Label, Image} from 'semantic-ui-react'
import Utility from '../../Utility';
import Settings from '../../Settings';
import {loadBranch,loadLedgerCategory} from '../../actions'
import { connect } from 'react-redux'
var config = require( '../../config' )

class MainRedeem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    async componentDidMount() {
        const {dispatch} = this.props

        await loadLedgerCategory(dispatch)
        await loadBranch(dispatch)
        this.setState({
            is_ready: true
        })

    }

    render() {

        const {auth} = this.props
        const {role} = auth
        const {is_ready} = this.state
        return (
            <div>
                {!is_ready &&
                <Dimmer active inverted>
                    <Loader inverted>Loading..</Loader>
                </Dimmer>
                }
                {is_ready && <Router>
                    <div className='menu-top'>
                        <NavBar onLogout={this.props.onLogout} />
                        <div className='segment' id='root-content-redeem' heigth={500}>
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/redeem/customer" component={Redeem}/> : ''}
                            {role == 'A' || role == 'M' || role == 'S' || role == 'U' ? <Route path="/redeem/qrcode" component={RedeemQRCode}/> : ''}
                        </div>
                    </div>
                </Router>}
            </div>
        );
    }
}

const mapStateToProps = ({auth}) =>{
    return ({auth})
  }
  export default connect(
    mapStateToProps
  )(MainRedeem)