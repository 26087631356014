/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Input, Modal, Table,
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import { connect } from 'react-redux'
import moment from 'moment';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

var dateFormat = require('dateformat');

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            is_lease: true,
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                amount_from: 0
            },
            amount: 0.00,
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var leases = Utility.getFetch(Settings.baseReportUrl + '/lease_data/?is_enabled=1&branch=' + this.state.search.branch);
        Promise.all([branches, leases]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.leases = values[1].lease;
            let leases = []
            for (let i in this.leases) {
                leases.push({
                    value: this.leases[i].id,
                    key: this.leases[i].id,
                    text: this.leases[i].number
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                leases: leases
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,

                amount_from: 0
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)

        if (this.state.is_lease)
            this.setState({
                open: true
            })
        else
            this.setState({
                open2: true
            })

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/lease_report_2/?is_enabled=1&" + fromData

        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.lease)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            items.push(item)
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {

        let title = 'รายงานยอดขายฝาก';
        let filename = 'lease-group-mont';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }

        const divStyle2 = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
            border: '1px solid black',
        };
        const textRight2 = {
            'text-align': 'right',
            border: '1px solid black',
        }

        const textLeft2 = {
            'text-align': 'left',
            border: '1px solid black',
        }
        const textCenter2 = {
            'text-align': 'center',
            border: '1px solid black',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let year = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            let d = new Date(item.record_date);
            let y = dateFormat(d, "yyyy");

            if (year[y] == null)
                year[y] = {
                    items: [],
                    title: 'ปี ' + y
                }
            year[y].items.push(item)

        }

        let year_list = []
        for (let i in year)
            year_list.push(year[i])



        let total_m3 = 0
        let total_interest_m3 = 0
        let total_amount = parseFloat(this.state.amount)
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานยอดขายฝาก,ดอกเบี้ย,รายเดือน</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field>
                                <label>ยอดตั้งต้น(บาท)</label>
                                <Input name='amount' className='text-right'
                                    // type='number' 
                                    maxLength={15}
                                    onBlur={() => {
                                        setTimeout(() => {
                                            if (this.state.focus_exchange) {
                                                this.setState({
                                                    focus_exchange: false,
                                                });
                                            }
                                        }, 0);
                                    }}
                                    onFocus={(e) => {
                                        e.target.select()
                                        if (!this.state.focus_exchange) {
                                            this.setState({
                                                focus_exchange: true,
                                            });
                                        }
                                    }}
                                    // value={this.state.exchange.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
                                    value={this.state.focus_exchange ? this.state.search.amount_from.toString().replace(/,/g, '') : this.state.search.amount_from.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}

                                    // value={this.state.search.amount_from.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
                                    onChange={(e, v) => {
                                        v.value = v.value.toString().replace(/,/g, '')
                                        if (isNaN(Number(v.value))) {
                                        } else {
                                            let search = this.state.search
                                            search.amount_from = Number(v.value)
                                            this.setState({ search })
                                        }

                                    }} />
                            </Form.Field>
                            <Button type='button' onClick={(e) => {

                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='5'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='5'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft} colSpan='2'></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดเงินขายฝาก</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดดอกเบี้ยรับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ยอดเงินคงฝาก</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {year_list.map((item, i) => {

                                        let m = {}
                                        for (let i in item.items) {
                                            let item = this.state.items[i]
                                            let d = new Date(item.record_date);
                                            let mm = dateFormat(d, "yyyy-mm");
                                            if (m[mm] == null)
                                                m[mm] = {
                                                    items: [],
                                                    title: 'เดือน ' + dateFormat(d, "mm")
                                                }
                                            m[mm].items.push(item)
                                        }
                                        let m_list = []
                                        for (let i in m) {
                                            m_list.push(m[i])
                                        }
                                        let total_m2 = 0
                                        let total_interest_m2 = 0
                                        return (<Table.Body>
                                            <Table.Row >
                                                <Table.HeaderCell style={textLeft} colSpan='5'>{item.title}</Table.HeaderCell>
                                            </Table.Row>
                                            {m_list.map((item2, i) => {
                                                let total_m = 0
                                                let total_interest_m = 0
                                                for (let j in item2.items) {
                                                    total_interest_m += parseFloat(item2.items[j].total_interest)
                                                    total_m += parseFloat(item2.items[j].amount)
                                                }

                                                total_m2 += total_m
                                                total_interest_m2 += total_interest_m
                                                total_m3 += total_m
                                                total_interest_m3 += total_interest_m
                                                total_amount += total_m
                                                return (<Table.Row>
                                                    <Table.Cell style={textLeft} ></Table.Cell>
                                                    <Table.Cell style={textLeft} >{item2.title}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(total_m)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(total_interest_m)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(total_amount)}</Table.Cell>
                                                </Table.Row>)
                                            })}
                                            <Table.Row >
                                                <Table.Cell style={textRight} colSpan='2'><div style={textU}>รวม{item.title}</div></Table.Cell>
                                                <Table.Cell style={textRight} ><div style={textU}>{Utility.priceFormat(total_m2)}</div></Table.Cell>
                                                <Table.Cell style={textRight} ><div style={textU}>{Utility.priceFormat(total_interest_m2)}</div></Table.Cell>
                                                <Table.Cell style={textRight} ></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    <Table.Body>

                                        <Table.Row >
                                            <Table.Cell style={textRight} colSpan='2'><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                                            <Table.Cell style={textRight} ><div id='total' style={textU}><b>{Utility.priceFormat(total_m3)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight} ><div id='total_interest' style={textU}><b>{Utility.priceFormat(total_interest_m3)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight} ></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = ({ auth, branches }) => {
    return ({ auth, branches })
}
export default connect(
    mapStateToProps,
)(PrintPreview)