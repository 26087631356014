import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Checkbox, Popup, Label, Table, Radio
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      search: {
        user_type: "",
        user_id: "",
        user_name: "",
        start_date: moment(),
        end_date: moment(),
        branch:props.auth.branch == undefined ? 1 : props.auth.branch.id,
      },
      branchesAll:[],
      group:true,
      items: [],
      cal: [],
      new_data: [],
      user_type: [
        {
          value: "",
          key: 0,
          text: 'ทั้งหมด'
        },
        {
          value: "True",
          key: 1,
          text: 'พนักงาน'
        },
        {
          value: "False",
          key: 2,
          text: 'ลูกค้า'
        }
      ],
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }


  componentDidMount() {
    this.setState({ st: true })
    var url_string = window.location.toString();
    var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
    Promise.all([branches]).then((values) => {
    this.setState({branchesAll:values})
    });
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        search: {
          start_date: moment(temp_start, 'DD/MM/YYYY'),
          end_date: moment(temp_end, 'DD/MM/YYYY')
        }
      })
    }
  }

  resetForm(e) {
    this.setState({
      status: 'all',
      search: {
        user_type: "",
        user_name: "",
        user_id: "",
        branch:this.props.auth.branch == undefined ? 1 : this.props.auth.branch.id,
        start_date: moment(),
        end_date: moment(),
      },
      group:true,
    })
  }

  async handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)
    search.start_date = Utility.formatDate2(search.start_date)
    search.end_date = Utility.formatDate2(search.end_date)
    let fromData = Utility.jsonToQueryString(search)
    var url = Settings.baseReportUrl + '/summarylease/?'
       url = url + `start_date=${search.start_date}&end_date=${search.end_date}&`
    // let search_categories = []
    this.setState({
      loading: true
    })
    await Utility.get(url, (s, d) => {
      if (s == true) {
        this.setState({
          items: d,
        })
        this.setState({
          loading:false,
          open: true,
        })
      }
    })
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search,
      items:[]
    })
  }
  render() {
    let data = this.props.data
    let title = 'รายงานสรุปยอดดอกเบี้ย(ต่อดอก)รายสาขา';
    let filename = `summary_lease_${moment().format('DDMMYYYY')}`;

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }

    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center',
      'width': '200px'

    }
    const borderRight = {
      'border-right': '1px'
    }
    const textU = {
      'text-decoration': 'underline'
    }
    let col = 6
    let totalPOS = 0
    let countPOS =0
    let totalMOBILE =0
    let countMOBILE =0
    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>รายงานสรุปยอดดอกเบี้ย(ต่อดอก)รายสาขา</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >
            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
              <>
              <Form.Field>
                  <label>จากวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.start_date}
                      selected={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          if (this.state.search.end_date < date) {
                              this.handlerInput(null, {
                                  name: 'end_date',
                                  value: date
                              });
                          }
                          this.handlerInput(null, {
                              name: 'start_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              <Form.Field >
                  <label>ถึงวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.end_date}
                      selected={this.state.search.end_date}
                      minDate={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          this.handlerInput(null, {
                              name: 'end_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              </>
              <Button>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='paperA4-portrait'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan={col} ><center>รายงานสรุปยอดดอกเบี้ย(ต่อดอก)รายสาขา</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell colSpan={col} ><center>วันที่ {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "center",width: '10%'}}>No.</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "left",width: '40%'}}>สาขา</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '10%'}}>จำนวนรายการ(POS)</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '10%'}}>ดอกเบี้ย(POS)</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '10%'}}>จำนวนรายการ(MOBILE)</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right",width: '10%'}}>ดอกเบี้ย(MOBILE)</Table.HeaderCell>           
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {
                    this.state.items.map((row, i) =>{
                        totalPOS =totalPOS+row.totalleasePOS 
                        countPOS =countPOS+row.countPOS
                        totalMOBILE =totalMOBILE+row.totalleaseMOBILE
                        countMOBILE =countMOBILE+row.countMOBILE
                      return(<Table.Row key={i}>
                        <Table.Cell colSpan = '1' style={{textAlign: "center",maxWidth: '10%'}}>{i+1}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "left",maxWidth: '40%'}}>{row.branch}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",minWidth: '10%'}}>{Utility.numberFormat(row.countPOS)}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",minWidth: '10%'}}>{Utility.priceFormat(row.totalleasePOS)}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",maxWidth: '10%'}}>{Utility.numberFormat(row.countMOBILE)}</Table.Cell>
                        <Table.Cell colSpan = '1' style={{textAlign: "right",maxWidth: '10%'}}>{Utility.priceFormat(row.totalleaseMOBILE)}</Table.Cell>
                      </Table.Row>)
                      }
                      )
                    }
                     <Table.Row >
                      <Table.Cell colSpan = '1' style={{textAlign: "center"}}></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>ยอดรวม</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.numberFormat(countPOS)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.priceFormat(totalPOS)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.numberFormat(countMOBILE)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.priceFormat(totalMOBILE)}</u></b></Table.Cell>
                    </Table.Row>
                    <Table.Row >
                      <Table.Cell colSpan = '1' style={{textAlign: "center"}}></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>ยอดรวมทั้งหมด</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.numberFormat(countPOS+countMOBILE)}</u></b></Table.Cell>
                      <Table.Cell colSpan = '1' style={{textAlign: "right"}}><b><u>{Utility.priceFormat(totalPOS+totalMOBILE)}</u></b></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(()=>{
                mywindow.close();
              },2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)