/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import StockRewardForm from '../StockRewardForm'
import StockRewardItemModal from './StockRewardItemModal'
import {
  Form,
  Button,
  Confirm, Dimmer, Loader,
  Header,
  Pagination,
  Image,
  Icon,
  Input,
  Dropdown,
  Popup,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';


class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

    //this.props.onClickMenu(e);
  }

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell><center style={{ padding: '4px' }}>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'view_log', positon: rowIndex }) }}><Icon name='clock outline' /></a>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }}><Icon name='window close outline' /></a>
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    const type = this.props.dataType | 'string'
    let value = data[rowIndex][field]
    if (type == 'number')
      value = Utility.numberFormat(value)
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={value}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.className}>{value}</div>}
        />
      </Cell>
    );
  }
}

class ImageItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props}>
        <Image src={data[rowIndex][field].small_square_crop} size='mini' verticalAlign='top' />
      </Cell>
    );
  }
}

class StockRewardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg_error: {},
      items: [],
      activePage: 1,
      text: ''
    }

    this.page_size = 10

    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.loadDataList(activePage)
  }

  componentWillMount() {

    this.loadDataList(this.state.activePage)
    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  loadDataList(activePage) {

    activePage = activePage < 1 ? 1 : activePage
    let q = {
      page: activePage,
      page_size: this.page_size
    }

    // check search
    if (this.state.text != '')
      q['keyword'] = this.state.text.trim()


    let url = Settings.baseUrl + '/stock_reward/?' + Utility.jsonToQueryString(q)

    this.setState({ loader_active: true })
    Utility.get(url, (status, res, code) => {

      this.setState({
        loader_active: false,
        items: res.results,
        totalPages: res.next == null && res.previous == null ? 0 : Math.ceil(res.count / this.page_size),
      })
    })
  }



  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.setState({
        modal_open: true,
        modal_title: 'แก้ไข',
        modal_action: 'edit',
        stock_reward: data
      })
    } else if (d.action == 'view_log') {
      this.setState({
        modal_log_open: true,
        modal_title: 'ประวัติ',
        modal_action: 'view',
        stock_reward: data
      })
    } else if (d.action == 'delete') {
      if (window.confirm('ยืนยันลบรายการนี้')) {

        let url = Settings.baseUrl + '/stock_reward/' + data.id + "/"
        Utility.delete(url, (s, r, c) => {

          this.loadDataList(this.state.activePage)

        })

      }
    }
  }

  handlerSubmitSearch() {

    this.setState({ activePage: 1 })
    this.loadDataList(1)

  }

  resetForm() {

    this.setState({
      text: '',
      activePage: 1
    })

    this.state.text = ''

    this.loadDataList(1)

  }

  setFieldValue(items) {

    for (let i in items) {
      items[i]['reward_name'] = items[i].reward.name
      items[i]['reward_code'] = items[i].reward.code
      items[i]['reward_image'] = items[i].reward.image
      items[i]['reward_score'] = items[i].reward.score
      items[i]['updated_datetime_display'] = Utility.formatDate(items[i].updated_datetime) + Utility.formatTime(items[i].updated_datetime)
    }
    return items
  }

  render() {
    const items = this.setFieldValue(this.state.items)
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>สต็อกของรางวัล</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างรายการของรางวัล'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>

        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>

        {this.state.modal_log_open && <StockRewardItemModal stock_reward={this.state.stock_reward} clientWidth={this.props.clientWidth} onClose={() => this.setState({ modal_log_open: false })} />}

        {this.state.modal_open && <StockRewardForm onSaved={() => {
          this.setState({ modal_open: false, activePage: 1 })
          this.loadDataList(1)
        }} stock_reward={this.state.stock_reward} action={this.state.modal_action} title={this.state.modal_title} onClose={() => this.setState({ modal_open: false })} />}

        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <Input placeholder='รหัสของรางวัล,ชื่อของรางวัล' value={this.state.text} onChange={(e, v) => this.setState({ 'text': v.value })} name='text' />
            </Form.Field>


            <Form.Field width={6}>
              <Button onClick={this.handlerSubmitSearch} size='small' type='button'><Icon name='search' /> ค้นหา</Button>
              <Button onClick={this.resetForm} size='small' type='button' >ทั้งหมด</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={60}
          headerHeight={35}
          width={this.state.table_width}
          height={387}>
          <Column
            width={80}

            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell onClickMenu={this.handleClick} />}
          />
          <Column
            header={<Cell>รหัส</Cell>}
            cell={
              <ItemsCell data={items} field="reward_code" />
            }
            width={80}
          />
          <Column
            header={<Cell>รูปภาพ</Cell>}
            cell={
              <ImageItemsCell data={items} field="reward_image" />
            }
            width={70}
          />
          <Column
            header={<Cell>ชื่อ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="reward_name" />
            }
            width={200}
          />
          <Column
            header={<Cell className="text-right">คะแนน</Cell>}
            cell={
              <ItemsCell position='top right' width={80} data={items} className='text-right' dataType='number' field="reward_score" />
            }
            width={80}
          />
          <Column
            header={<Cell className="text-right">จำนวนคงเหลือ</Cell>}
            cell={
              <ItemsCell position='top right' width={200} data={items} className='text-right' dataType='number' field="amount" />
            }
            width={200}
          />
          <Column
            header={<Cell>อัพเดทเมื่อ</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="updated_datetime_display" />
            }
            width={120}
          />
        </Table></div>
        <br />
        {this.state.totalPages > 0 && <Pagination
          activePage={this.state.activePage}
          onPageChange={this.handlePaginationChange}
          totalPages={this.state.totalPages}
        />}
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/banks/' + this.state.object_id + "/";
            Utility.delete(url, (status, data) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.componentDidMount();
                this.resetForm();
              } else {
                alert(data.error)
              }
            });

          }}
        />
      </div>

    );
  }
}

export default StockRewardList;
