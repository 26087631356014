/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import AddProductModal from './AddProductModal'
import PaymentModal from '../../../Payment/PaymentModal'
import BillItemTable from './BillItemTable'
import BillPrintPreview from './BillPrintPreview'
import {
  Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class BillModal extends Component {

  constructor(props) {
    super(props);
    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      branch: '',
      vendor: '',
      status_stock: 'N',
      modal_open: this.props.open,
      product_action_title: 'เพิ่มสินค้า',
      product_action: 'add',
      item_invoice: [],
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการนำออกทองเก่า',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      description: '',
      button_print_disabled: true,
      msg_error: {},
      code: '',
      focus: '',
      focus_input: '',
      weight_real: '',
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.onEditProduct = this.onEditProduct.bind(this);
    this.onDeleteProduct = this.onDeleteProduct.bind(this);

    this.handelActionItemCell = this.handelActionItemCell.bind(this)
    this.submitBillHandler = this.submitBillHandler.bind(this)
    this.handelSubmitProduct = this.handelSubmitProduct.bind(this)
    this.handelBagOld = this.handelBagOld.bind(this)
    this.submitQRcode = this.submitQRcode.bind(this);
    this.submitStockUpdate = this.submitStockUpdate.bind(this);
  }

  submitQRcode() {
    let data = []
    let c = this.state.code.trim()
    if (/^BO-/.test(c)) {
      this.handelBagOld(c)
    }
    else {
      data['code'] = this.state.code.trim()
      data['stock_type'] = 'old'
      data['branch'] = this.props.invoice_detail.branch
      let qrstring = Utility.jsonToQueryString(data);
      var qrcode = Utility.getAsync(Settings.baseUrl + '/qrcode/?' + qrstring);
      Promise.all([qrcode]).then((values) => {
        if (values[0].status_code == 200){
          this.handelSubmitProduct(values[0].data)
        }else {
          if (values[0].data.detail === '' || values[0].data.detail === undefined) {
            this.handelSubmitProduct(values[0].data)
          } else {
            alert(values[0].data.detail)
          }
        }
        
        this.setState({ code: '' })
      })
    }
  }

  handelSubmitSaveInvoice() {
    const { branch } = this.props.auth
    if (this.state.branch == '') {
      alert('กรุณาเลือกสาขา');
      return;
    }

    var formData = {
      branch: this.state.branch,
      bill_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      description: this.state.description,
      weight_real: this.state.weight_real.toString().replace(/,/g, '')
    };
    this.setState({ button_save_loading: true })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoices_old/';
      Utility.post(url, formData, (status, data) => {
        this.setState({
          button_save_loading: false,
          invoice_add_product_disabled: false,
          invoice_disabled: true,
          button_save_title: 'บันทึก'
        })
        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
        this.props.onAddInvoice(data);
        this.loadInvoiceItem()
        alert('บันทึกข้อมูลสำเร็จ!')
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight_real: this.state.weight_real.toString().replace(/,/g, '')
      };

      const url = Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + "/";
      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        }
      });
    }
  }

  handelSubmitSaveInvoice_no_alert() {
    const { branch } = this.props.auth
    if (this.state.branch == '') {
      alert('กรุณาเลือกสาขา');
      return;
    }

    var formData = {
      branch: this.state.branch,
      bill_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: 'N',
      status_stock: this.state.status_stock,
      description: this.state.description,
      weight_real: this.state.weight_real.toString().replace(/,/g, '')
    };
    this.setState({ button_save_loading: true })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoices_old/';
      Utility.post(url, formData, (status, data) => {
        this.setState({
          button_save_loading: false,
          invoice_add_product_disabled: false,
          invoice_disabled: true,
          button_save_title: 'บันทึก'
        })
        this.setState({ focus: 'edit' })
        this.qrcodee.focus()
        this.props.onAddInvoice(data);
        this.loadInvoiceItem()
      });
    } else {
      var formData2 = {
        branch: this.state.branch,
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight_real: this.state.weight_real.toString().replace(/,/g, '')
      };

      const url = Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + "/";
      Utility.put(url, formData2, (status, data) => {
        if (status) {
          this.setState({ button_save_loading: false })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
        }
      });
    }
  }

  handelBagOld(code) {
    let formData = {
      qrcode: code,
    };
    const url = Settings.baseUrl + '/invoice_old/' + this.props.invoice_detail.id + '/bagold/';
    Utility.post(url, formData, (status, data) => {
      if (status) {
        this.setState({ button_product_loading: false, modal_product: false })
        this.setState(data)
        this.loadInvoiceItem(true);
      }
      else { // error
        alert(data.detail)
      }
    });
    this.setState({ button_product_loading: true, code: '' })
  }

  handelSubmitProduct(data) {
    let formData = {
      invoice_old: this.props.invoice_detail.id,
      product_old: data.id,
      qrcode: data.qrcode
    };
    const url = Settings.baseUrl + '/invoice_old_item/';
    Utility.post(url, formData, (status, data) => {
      if (status) {
        this.setState({ button_product_loading: false, modal_product: false })
        this.setState(data)
        this.loadInvoiceItem(true);
      }
      else { // error
        alert(data.detail)
      }
    });

    this.setState({ button_product_loading: true })
  }

  loadInvoiceItem(reload) {

    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise.all([invoices, items]).then((values) => {
      // set invoice detail
      let item = values[0];
      let branch_option = Utility.getOptions(this.props.branches)
      for (let i in branch_option) {
        if (branch_option[i].value === item.branch) {
          this.setState({ branch_name: branch_option[i].text })
        }
      }
      let description = this.state.description
      let status_bill = this.state.status_bill
      let weight_real = this.state.weight_real
      this.setState({
        bill_number: item.bill_number,
        description: item.description,
        status_bill: item.status_bill,
        invoice_date: moment(item.bill_date),
        branch: item.branch,
        vendor: item.vendor,
        invoice_disabled: true,
        item_invoice: this.setFieldValue(values[1]),
        button_save_title: 'บันทึก',
        status_stock: item.status_stock,
        button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y',
        invoice_add_product_disabled: item.status_stock == 'Y',
        invoice_title: <span>{<span>แก้ไขรายการนำออกทองเก่า</span>} </span>,
        loader: false,
        button_print_disabled: false,
        weight_real: item.weight_real,
        weight_real_disabled: item.status_stock == 'Y' ? true : false
      });
      if (reload == true) {
        this.setState({
          weight_real: weight_real,
          description: description,
          status_bill: status_bill,
        })
      }
    });
  }

  loadInvoiceItem_2(reload) {

    var items = Utility.getFetch(Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise.all([items]).then((values) => {
      // set invoice detail

      let description = this.state.description
      let status_bill = this.state.status_bill
      let weight_real = this.state.weight_real
      this.setState({
        invoice_disabled: true,
        item_invoice: this.setFieldValue(values[0]),
        button_save_title: 'บันทึก',
        button_update_stoick_disabled: values[0].length == 0,
        invoice_title: <span>{<span>แก้ไขรายการนำออกทองเก่า</span>} </span>,
        loader: false,
        button_print_disabled: false,
      });
      if (reload == true) {
        this.setState({
          weight_real: weight_real,
          description: description,
          status_bill: status_bill,
        })
      }
    });
  }

  setFieldValue(item) {

    let invoice_weight_total = 0;
    let invoice_cost_total = 0;
    for (let i = 0; i < item.length; i++) {
      invoice_weight_total += parseFloat(item[i].weight);
      invoice_cost_total += parseFloat(item[i].product_old.cost);
      item[i]['product_name'] = item[i].product_old.name
      item[i]['weight'] = Utility.weightFormat(item[i].weight)
      item[i]['cost'] = Utility.priceFormat(item[i].product_old.cost)
    }
    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      invoice_cost_total: Utility.priceFormat(invoice_cost_total),
    });
    return item;
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      btn_stock: true
    });

    this.setState({ focus: this.props.modal_action })

    if (this.props.modal_action == 'edit') {
      this.loadInvoiceItem()
    }
  }

  onEditProduct(e, d) {
    let data = this.state.item_invoice[d.positon];
    this.setState({
      item_id: data.id,
      stock_category: data.stock.id,
      product_weight: data.weight,
      average: data.average,
      price_sell: Utility.removeCommas(data.price),
      total_sell: Utility.removeCommas(data.total),
      cost: Utility.removeCommas(data.cost),
      // total_diff: data.total_diff,
      price_diff: parseFloat(Utility.removeCommas(data.price)) - parseFloat(Utility.removeCommas(data.average)),
      product_action: 'edit',
      product_action_title: 'แก้ไข'
    });
  }

  onDeleteProduct(e, d) {
    let data = this.state.item_invoice[d.positon];
    if (data.status_stock == 'N') {
      const url = Settings.baseUrl + '/invoice_old_item/' + data.id + '/';
      Utility.delete(url, (status, data) => {
        this.setState({ button_product_loading: false })
        this.loadInvoiceItem_2();
      });
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  handelActionItemCell(e, d) {

    if (d.action == 'delete') {
      if (window.confirm('ยืนยันลบรายการนี้'))
        this.onDeleteProduct(e, d)
    } else {
      this.onEditProduct(e, d)
    }

  }

  submitStockUpdate() {
    this.setState({ code: '' })
    const url = Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + '/stock_update/';
    if (this.state.weight_real == '' || this.state.weight_real == null) {
      alert('น้ำหนักชั่งห่อ กรัม ต้องไม่เป็น 0')
      return;
    }
    if (Utility.weightFormat(this.state.weight_real) != this.state.invoice_weight_total) {
      if (this.props.auth.role != 'S') {
        alert('คุณไม่มีสิทธิ์อนุมัติและอัพเดทสต๊อกเนื่องจาก น้ำหนักชั่งห่อ กรัม ไม่เท่ากับ น้ำหนักรวม โปรดติดต่อ superadmin')
        return;
      } else {
        if (this.state.description == '') {
          alert('กรุณากรอกหมายเหตุ กรณี น้ำหนักชั่งห่อ กรัม ไม่เท่ากับ น้ำหนักรวม ')
          return;
        }

        let msg = this.state.weight_real > 0 ? 'กรุณายืนยันการอัพเดทสต๊อก เนื่องจาก น้ำหนักชั่งห่อ ไม่เท่ากับ น้ำหนักรวม' : 'กรุณายืนยันการอัพเดทสต๊อก เนื่องจาก น้ำหนักชั่งห่อ เป็น 0.00 กรัม'
        let r = window.confirm(msg);
        if (!r) {
          return;
        }
      }
    }

    this.handelSubmitSaveInvoice_no_alert();

    var formData = {
      branch: this.state.branch,
      status_bill: this.state.status_bill,
      description: this.state.description,
      weight_real: this.state.weight_real.toString().replace(/,/g, '')
    };

    const url2 = Settings.baseUrl + '/invoices_old/' + this.props.invoice_detail.id + "/";
    Utility.put(url2, formData, (status, data) => {
      if (status) {
        this.setState({ button_save_loading: false })
        Utility.get(url, (status, data) => {
          // this.submitledger(ledger)
          if (data.status == false) {
            alert(data.error);
          } else {
            this.props.onAddInvoice();
            this.loadInvoiceItem();
            alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
            this.setState({ focus: 'update' })
          }
          // this.setState({
          //   button_update_stoick_loading: false
          // })
        });
      }
    });

  }

  submitUpdateStock(ledger) {

    this.setState({
      button_update_stoick_loading: true,
      payment_open: false
    })
    const url = Settings.baseUrl + '/bill_category/' + this.props.invoice_detail.id + '/stock/';
    Utility.get(url, (status, data) => {
      this.submitledger(ledger)

      this.props.onAddInvoice();
      this.loadInvoiceItem();

      this.setState({
        button_update_stoick_loading: false
      })
    });
  }

  submitBillHandler(ledger, saving, point) {

    if (window.confirm('ยืนยันอนุมัติและอัพเดทสต็อก')) {
      this.submitUpdateStock(ledger)
    }

  }

  submitledger(ledger) {

    ledger['object_number'] = this.props.invoice_detail.bill_number
    ledger['object_id'] = this.props.invoice_detail.id
    ledger['ledger_category'] = 5

    let url = Settings.baseUrl + "/ledger/";
    Utility.post(url, ledger, (s, d) => {

    });
  }
  render() {
    const { branch } = this.props.auth
    let items = this.state.item_invoice;
    const branch_option = Utility.getOptions(this.props.branches)
    return (

      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.invoice_title} {this.state.bill_number && <span id='textBillID'>[บิลเลขที่ {this.state.bill_number}]</span>}
              <Header.Subheader>
                <span><Icon name={this.state.status_stock == 'N' ? 'warning circle' : 'check circle'} color={this.state.status_stock == 'N' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          {this.state.modal_product && <AddProductModal onClose={() => this.setState({ modal_product: false })} title={this.state.product_action_title} branch={this.props.invoice_detail.branch} onSubmit={this.handelSubmitProduct} />}
          <div >
            {this.state.payment_open && <PaymentModal
              ledger_category={5}
              total={this.state.invoice_net}
              onClose={() => this.setState({ payment_open: false })}
              onSubmit={this.submitBillHandler}
            />}
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form className=' fluid' size='small' >
                        <Form.Field error={this.state.msg_error.branch != null} width={16} >
                          <label>*สาขา <MsgInput text={this.state.msg_error.branch} /></label>
                          <Dropdown id='branch' search selection width={16} options={branch_option} placeholder='สาขา' value={this.state.branch} onChange={(e, data) => {
                            this.setState({ branch: data.value });
                          }} disabled={this.state.invoice_disabled} />
                        </Form.Field>
                        {/* <Form.Dropdown id='vendor' label='ชื่อโรงงาน/ร้านส่ง' search selection width={16} options={this.props.vendors} value={this.state.vendor} defaultValue={this.state.vendor} onChange={(e, data) => {
                          this.setState({ vendor: data.value });
                        }} disabled={this.state.invoice_disabled || this.props.action == 'export'} /><br /> */}

                        <Form.Field width={16}>
                          <label>วันที่</label>
                          <DatePicker
                            id='date'
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.invoice_date}
                            readOnly
                            onChange={(date) => {
                              date = date ? date : moment()
                              this.setState({ invoice_date: date });
                            }}
                            disabled={this.state.invoice_disabled}
                          />
                        </Form.Field>

                        <Form.Field width={16}>
                          <label>น้ำหนักชั่งห่อ กรัม</label>
                          <Input
                            id='weight_real'
                            placeholder='0.000'
                            className='text-right'
                            onChange={(e) => {
                              if (isNaN(Number(e.target.value))) {
                              } else {
                                if (e.target.value.split('.')[0].length > 6) {
                                  return;
                                }
                                if (e.target.value.toString().split(".")[1] != undefined)
                                  if (e.target.value.toString().split(".")[1].length > 3)
                                    return
                                this.setState({ weight_real: e.target.value })
                              }
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_input) {
                                  this.setState({
                                    focus_input: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={(e) => {
                              e.target.select()
                              if (!this.state.focus_input) {
                                this.setState({
                                  focus_input: true,
                                });
                              }
                            }}
                            value={this.state.focus_input ? this.state.weight_real.toString().replace(/,/g, '') : Utility.weightFormat(this.state.weight_real)}
                            disabled={this.state.weight_real_disabled}
                            maxLength={10}
                          />
                        </Form.Field>

                        <Form.Checkbox id='btnClearBill' label='เคลียร์บิล' width={16} checked={this.state.status_bill == 'Y'} onChange={(e, v) => {


                          this.setState({
                            status_bill: v.checked ? 'Y' : 'N'
                          });
                        }} />
                        <Form.TextArea label='หมายเหตุ' placeholder='หมายเหตุ' maxLength={255} width={16} value={this.state.description} onChange={(e, v) => {
                          this.setState({ description: v.value })
                        }} />
                        <small> *กรอกหมายเหตุเมื่อน้ำหนักชั่งห่อกรัม ไม่เท่ากับ น้ำหนักรวม</small>
                      </Form>

                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form size='small'>
                        <Form.Group>
                          <Form.Field width={6}>
                            <Header floated='left' as='h3'>รายการสินค้า</Header>
                          </Form.Field>
                          <Form.Field width={10}>
                            <label>QR Code</label>
                            {this.state.focus === 'edit' && this.state.status_stock === 'N' ?
                              <Input
                                className='text-right'
                                placeholder='Qr code'
                                autoFocus={1}
                                // this.weightRealGInput.focus()
                                // disabled={this.state.invoice_add_product_disabled}
                                ref={(c) => {
                                  this.qrcodee = c
                                }}
                                onKeyPress={(e) => {
                                  if (e.key == 'Enter') {
                                    this.submitQRcode()
                                  }
                                }}
                                onChange={(e) => this.setState({ code: e.target.value })}
                                value={this.state.code}
                              /> :
                              <Input
                                className='text-right'
                                placeholder='Qr code'
                                disabled={1}

                              />
                            }
                            {/* <Button id='add' 
                            disabled={this.state.invoice_add_product_disabled} size='small' content='เพิ่มรายการสินค้า' 
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ modal_product: true })
                            }}
                              floated='right' icon='plus' labelPosition='left' type='button' color='green' /> */}
                          </Form.Field>
                        </Form.Group>
                      </Form>
                      <BillItemTable items={items} table_width={this.state.table_width} update_stock={this.state.button_update_stoick_disabled} onActionItemCell={this.handelActionItemCell} />
                      <br />
                      <Form className='fluid' size='small'>
                        <Form.Group >
                          <Form.Input id='weighttotal' label='น้ำหนักรวม' placeholder='' className='text-right' width={4} value={this.state.invoice_weight_total} readOnly />
                          <Form.Input id='costtotal' label='ต้นทุนรวม' placeholder='' className='text-right' width={4} value={this.state.invoice_cost_total} readOnly />
                        </Form.Group>
                        <small>*ถ้าน้ำหนักชั่งห่อกรัม ไม่เท่ากับ น้ำหนักรวม สิทธิ์ในการอนุมัติเเละอัพเดทสต็อกจะเป็นของ Superadmin</small>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button
            id='btnPrint'
            primary
            size='small'
            icon='print'
            disabled={this.state.button_print_disabled}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ open_print: true })
            }}
            content='พิมพ์' />
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.handelSubmitSaveInvoice();
            }}
            className={this.state.button_save_loading ? 'loading' : ''}
            content={this.state.button_save_title} />
          <Button
            id='btnUpdate'
            size='small'
            icon='lightning'
            color='red'
            onClick={this.submitStockUpdate}
            // onClick={(e) => this.setState({payment_open:true})}
            className={this.state.button_update_stoick_loading ? 'loading' : ''}
            disabled={this.state.button_update_stoick_disabled}
            content='อนุมัติและอัพเดทสต๊อก' />

        </Modal.Actions>
        {this.state.open_print
          ? <BillPrintPreview
            branch_name={this.state.branch_name}
            invoice_detail={this.props.invoice_detail}
            status_stock={this.state.status_stock}
            items={items}
            cost_total={this.state.invoice_cost_total}
            weight_total={this.state.invoice_weight_total}
            onClose={() => {
              this.setState({ open_print: false })
            }} />
          : ''}
      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}
export default connect(
  mapStateToProps,
)(BillModal)