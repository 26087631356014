/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Login from './components/Layout/Login'
import Main from './components/Layout/Main'
import MainRedeem from './components/Layout/MainRedeem'
import { connect } from 'react-redux'
import { auth, logout } from './actions'
import Settings from './Settings';
import Utility from './Utility';

import {
    Container, Dimmer, Loader
} from 'semantic-ui-react';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }
        this.onAccess = this
            .onAccess
            .bind(this);
        this.onLogout = this
            .onLogout
            .bind(this);
        this.onLogout2 = this
            .onLogout2
            .bind(this);
    }

    async componentDidMount() {
        await auth(this.props.dispatch)
        this.checkLogin();
        let timerId = setInterval(() => {
            if (this.props.auth) {
                if (this.props.auth.is_login) {
                    const r = Utility.getAsync(Settings.baseUrl + '/user_profile/')
                    Promise.all([r]).then((v) => {
                        if (v[0].data.user.is_active != true) {
                            auth(this.props.dispatch);
                            this.checkLogin();
                        }
                    });
                }
            }
        }, 60000);
        var url_redeem = ['/redeem/login', '/redeem/customer', '/redeem/qrcode'];
        const token = JSON.parse(localStorage.getItem('token'));
        const is_redeem = localStorage.getItem('is_redeem')
        if (window.location.pathname.startsWith('/redeem')) {
            if (!url_redeem.includes(window.location.pathname)) {
                if (token !== null && Object.keys(token).length > 0 && is_redeem) {
                    window.location.replace(window.location.origin+'/redeem/customer')
                } else {
                    window.location.replace(window.location.origin+'/redeem/login')
                }
            } else if (!window.location.pathname.includes('/redeem/login') && (token === null || token === undefined || Object.keys(token).length === 0)) {
                window.location.replace(window.location.origin+'/redeem/login')
            } else if (window.location.pathname.includes('/redeem/login') && token !== null && Object.keys(token).length > 0) {
                if (is_redeem) {
                    window.location.replace(window.location.origin+'/redeem/customer')
                } else {
                    localStorage.removeItem('token')
                    localStorage.removeItem('noti')
                    window.location.replace(window.location.origin+'/redeem/login')
                }
            } else if (window.location.pathname.includes('/redeem/customer') && token !== null && Object.keys(token).length > 0 && !is_redeem) {
                localStorage.removeItem('token')
                localStorage.removeItem('noti')
                window.location.replace(window.location.origin+'/redeem/login')
            } else if (window.location.pathname.includes('/redeem/qrcode') && token !== null && Object.keys(token).length > 0 && !is_redeem) {
                localStorage.removeItem('token')
                localStorage.removeItem('noti')
                window.location.replace(window.location.origin+'/redeem/login')
            }
        } else {
            if(is_redeem){
                localStorage.removeItem('token')
                localStorage.removeItem('noti')  
                localStorage.removeItem('is_redeem')  
                window.location.replace(window.location.origin)
            }
        }
    }

    onLogout() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete("token");
        const newUrl = window.location.pathname + "?" + urlSearchParams.toString();
        window.history.replaceState({}, "", newUrl);
        auth(this.props.dispatch)
        const is_redeem = localStorage.getItem('is_redeem')
        if(is_redeem) {
            localStorage.removeItem('is_redeem')
            window.location.replace(window.location.origin+'/redeem/login')
        } else {
            window.location.replace(window.location.origin)
        }
    }

    onLogout2() {
        const url = Settings.revokeTokenUrl;
        const token = JSON.parse(localStorage.getItem('token'))
        const formData = {
            token: token.access_token,
            grant_type: 'password',
            client_id: Settings.client_id,
            client_secret: Settings.client_secret
        };
        Utility.postBasic(url, formData, (status, data, code) => {
            if (code == 200) {
                localStorage.setItem("token", JSON.stringify({}));
                localStorage.removeItem('branch')
                this.onLogout()
            }
        })
    }

    async onAccess() {
        await auth(this.props.dispatch)
        if(window.location.pathname == '/redeem/login' || window.location.pathname == '/redeem') {
            localStorage.setItem("is_redeem", true);
            const { auth } = this.props
            if (auth.branch == undefined && auth.is_login == true) {
                alert('สาขาถูกปิดการใช้งาน')
                this.onLogout2()
            } else if (auth.user) {
                if (auth.user.is_active == false) {
                    alert('ผู้ใช้ถูกปิดการใช้งาน')
                    this.onLogout2()
                } else if (auth.role == 'U' && auth.branch.id != 1) {
                    alert('ยังไม่เปิดให้ใช้บริการในสาขานี้')
                    this.onLogout2()
                } else if (auth.role == 'M') {
                    let check_branch_list = auth.branch_list.filter(k => k.id == 1);
                    if (check_branch_list.length == 0) {
                        alert('ยังไม่เปิดให้ใช้บริการในสาขานี้')
                        this.onLogout2()
                    } else {
                        window.location.replace(window.location.origin+'/redeem/customer')
                    }
                } else if (auth.role == 'C') {
                    alert('ผู้ใช้ไม่สามารถใช้บริการนี้ได้')
                    this.onLogout2()
                } else {
                    window.location.replace(window.location.origin+'/redeem/customer')
                }
            }
        } else {
            const { auth } = this.props
            if (auth.branch == undefined && auth.is_login == true) {
                alert('สาขาถูกปิดการใช้งาน')
                this.onLogout2()
            } else if (auth.user) {
                if (auth.user.is_active == false) {
                    alert('ผู้ใช้ถูกปิดการใช้งาน')
                    this.onLogout2()
                } else {
                    window.location.replace(window.location.origin)
                }
            }
        }
    }

    checkLogin() {
        const { auth } = this.props
        const is_redeem = localStorage.getItem('is_redeem')
        if (auth.branch == undefined && auth.is_login == true) {
            alert('สาขาถูกปิดการใช้งาน')
            this.onLogout2()
        } else if (auth.user) {
            if (auth.user.is_active == false) {
                alert('ผู้ใช้ถูกปิดการใช้งาน')
                this.onLogout2()
            } else if (is_redeem) {
                if (auth.role == 'U' && auth.branch.id != 1) {
                    alert('ยังไม่เปิดให้ใช้บริการในสาขานี้')
                    this.onLogout2()
                    window.location.replace(window.location.origin+'/redeem/login');
                } else if (auth.role == 'M') {
                    var check_branch_list = auth.branch_list.filter(k => k.id == 1);
                    if (check_branch_list.length == 0) {
                        alert('ยังไม่เปิดให้ใช้บริการในสาขานี้')
                        this.onLogout2()
                        window.location.replace(window.location.origin+'/redeem/login');
                    }
                } else if (auth.role == 'C') {
                    alert('ผู้ใช้ไม่สามารถใช้บริการนี้ได้')
                    this.onLogout2()
                    window.location.replace(window.location.origin+'/redeem/login');
                }
            }
        }
    }

    checkExpiredRedeem() {
        const { auth } = this.props
        const is_redeem = localStorage.getItem('is_redeem')
        if(auth.is_ready && !auth.is_login && is_redeem) {
            localStorage.removeItem('is_redeem')
            window.location.replace(window.location.origin+'/redeem/login');
        }
    }

    render() {
        const { auth } = this.props
        let active = true
        if (auth.user)
            if (auth.user.is_active == false) {
                active = false
            }
        // this.checkLogin()
        const is_redeem = localStorage.getItem('is_redeem')
        this.checkExpiredRedeem()

        return (<div>
            {!auth.is_ready ?
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                :
                <Container fluid>
                    {(auth.is_login && auth.branch != undefined) || active == false ? 
                        is_redeem ?
                            <MainRedeem onLogout={this.onLogout} /> 
                        :
                            <Main onLogout={this.onLogout} /> 
                    : 
                        <Login onAccess={this.onAccess} />
                    }
                </Container>}</div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return ({ auth })
}
export default connect(
    mapStateToProps
)(App)