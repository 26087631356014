/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import Settings from "../../Settings";
import Utility from "../../Utility";
import MsgInput from "../Error/MsgInput";
//import OptionItemsCell from './OptionItemsCell'
import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm,
  Dimmer,
  Loader,
  Header,
  Dropdown,
  Popup,
  Pagination,
} from "semantic-ui-react";
import { Table, Column, Cell } from "fixed-data-table";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { connect } from "react-redux";
import { loadLedgerCategory } from "../../actions";
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = this.props.width * 0.9 + "px";
    var ellipsisStyle = {
      whiteSpace: "nowrap",
      width: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    const Width300 = {
      // 'width':'1000px',
      "word-break": "break-word",
    };
    return (
      <ContextMenuTrigger
        id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}
      >
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>
                {data[rowIndex][field]}
              </div>
            }
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {
    //this.props.onClickMenu(e);
  }

  render() {
    const { rowIndex, data, ...props } = this.props;
    return (
      <Cell>
        {data[rowIndex].is_standard ? (
          <center style={{ padding: "4px" }}>
            <a
              title="ไม่สามารถแก้ไขได้"
              style={{ "text-decoration": "none", color: "black" }}
            >
              <Icon disabled name="edit" />
            </a>
            <a title="ไม่สามารถลบได้" style={{ "text-decoration": "none", color: "black" }}>
              <Icon disabled name="window close outline" />
            </a>
          </center>
        ) : (
            <center style={{ padding: "4px" }}>
              <a
                onClick={(e) => {
                  this.props.onClickMenu(e, { action: "edit", positon: rowIndex });
                }}
              >
                <Icon name="edit" />
              </a>
              <a
                onClick={(e) => {
                  this.props.onClickMenu(e, { action: "delete", positon: rowIndex });
                }}
              >
                <Icon name="window close outline" />
              </a>
            </center>
          )}
      </Cell>
    );
  }
}

class LedgerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      title: "",
      modal_open: false,
      msg_error: {},
      totalPages: 0,
      activePage: 1,
      count: 0,
    };
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  submitData() {
    var msg_error = {};

    if (this.state.title == null || this.state.title == "") {
      msg_error["title"] = ["*ต้องไม่เป็นค่าว่าง"];
    }

    if (
      this.state.ledger_category_kind == null ||
      this.state.ledger_category_kind == ""
    ) {
      msg_error["kind"] = ["*ต้องไม่เป็นค่าว่าง"];
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error,
      });
      return;
    }

    var formData = {
      title: this.state.title,
      kind: this.state.ledger_category_kind,
    };

    this.setState({ button_class: "loading" });
    const self = this;
    if (this.state.modal_action == "add") {
      const url = Settings.baseUrl + "/ledger_category/";
      Utility.post(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false,
          });
          this.props.handleLedgerCategoryChanges();
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else {
          // error
          if (data.length !== 0)
            this.setState({
              msg_error: data,
              button_class: "",
            });
        }
      });
    } else {
      const url = Settings.baseUrl + "/ledger_category/" + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false,
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else {
          // error
          if (data.length !== 0)
            this.setState({
              msg_error: data,
              button_class: "",
            });
        }
      });
    }
  }

  resetForm() {
    this.setState({
      title: "",
      button_class: "",
      msg_error: {},
    });
  }

  handlePaginationChange = (e, { activePage }) => {
    var self = this;
    this.setState({
      loader_active: true,
    });
    Utility.get(Settings.baseUrl + "/ledger_category/?is_enabled=1&page=" + activePage, (status, data) => {
      let results = data.results
      for (let i in results) {
        results[i].kind_title = Utility.getObjectByValue(
          Settings.ledger_kind_option,
          results[i].kind
        ).text;
      }

      self.setState({
        items: results,
        loader_active: false,
        activePage: activePage,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        count: data.count
      });
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
    });
    Utility.get(Settings.baseUrl + "/ledger_category/?is_enabled=1&page=1", (status, data) => {
      let results = data.results
      for (let i in results) {
        results[i].kind_title = Utility.getObjectByValue(
          Settings.ledger_kind_option,
          results[i].kind
        ).text;
      }

      self.setState({
        items: results,
        loader_active: false,
        activePage: 1,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        count: data.count
      });
    });
  }

  componentWillMount() {
    setTimeout(() => {
      window.addEventListener("resize", this.updateDimensions);
      this.updateDimensions();
    }, 400);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById("table_width");
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == "edit") {
      if (data.is_standard == 1) {
        alert("ไม่สามารถแก้ไขได้");
        return;
      }
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_title: "แก้ไข",
        modal_action: "edit",
        title: data.title,
        ledger_category_kind: data.kind,
        object_id: data.id,
      });
    } else {
      this.setState({ confirm_open: true, object_id: data.id });
    }
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form size="small">
          <Form.Group>
            <Form.Field width={6}>
              <Header floated="left" as="h2">
                รายการรับ-จ่าย
              </Header>
            </Form.Field>
            <Form.Field width={10}>
              <Button
                id="addinout"
                content="เพิ่ม"
                onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  this.setState({
                    modal_open: true,
                    modal_action: "add",
                    modal_title: "สร้างรายการรับ-จ่าย",
                  });
                }}
                floated="right"
                icon="plus"
                labelPosition="right"
                type="button"
                primary
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem data={{ action: "edit" }} onClick={this.handleClick}>
            แก้ไข
          </MenuItem>
          <MenuItem data={{ action: "delete" }} onClick={this.handleClick}>
            ลบ
          </MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? "active" : ""} inverted>
          <Loader content="Loading" inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size="tiny" /*dimmer='blurring'*/>
          <Button
            id="closeaddinout"
            circular
            icon="close"
            basic
            floated="right"
            name=""
            onClick={() => this.setState({ modal_open: false })}
          />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size="small">
                <Form.Field error={this.state.msg_error.title != null}>
                  <label>
                    *รายการ <MsgInput text={this.state.msg_error.title} />
                  </label>
                  <Input
                    id="menuinout"
                    placeholder="รายการ"
                    onChange={(e) => this.setState({ title: e.target.value })}
                    value={this.state.title}
                    maxLength={255}
                  />
                </Form.Field>
                <Form.Field error={this.state.msg_error.kind != null}>
                  <label>
                    *ประเภท <MsgInput text={this.state.msg_error.kind} />
                  </label>
                  <Dropdown
                    id="typeinout"
                    label="*ประเภท"
                    placeholder="ประเภท"
                    search
                    selection
                    name="kind"
                    value={this.state.ledger_category_kind}
                    options={Settings.ledger_kind_option}
                    onChange={(e, d) => {
                      this.setState({ ledger_category_kind: d.value });
                    }}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id="confirminout"
              size="small"
              primary
              onClick={this.submitData}
              className={this.state.button_class}
            >
              บันทึก
            </Button>
            <Button
              id="cancelinout"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ modal_open: false });
              }}
            >
              ยกเลิก
            </Button>
          </Modal.Actions>
        </Modal>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}
          >
            <Column
              width={80}
              header={<Cell></Cell>}
              cell={<OptionItemsCell data={items} onClickMenu={this.handleClick} />}
            />
            <Column
              header={<Cell>รหัส</Cell>}
              cell={
                <ItemsCell position="top left" width={80} data={items} field="code" />
              }
              width={80}
            />
            <Column
              header={<Cell>รายการ</Cell>}
              cell={
                <ItemsCell position="top left" width={200} data={items} field="title" />
              }
              width={200}
            />
            <Column
              header={<Cell>เมนุ</Cell>}
              cell={
                <ItemsCell position="top left" width={200} data={items} field="menu" />
              }
              width={200}
            />
            <Column
              header={<Cell>ประเภท</Cell>}
              cell={
                <ItemsCell
                  position="top left"
                  width={200}
                  data={items}
                  field="kind_title"
                />
              }
              width={200}
            />
          </Table>
        </div>
        <br />
        <Form size="small">
          <Form.Group>
            <Form.Input
              id="amounttrans"
              label="จำนวน"
              placeholder=""
              className="text-right"
              width={2}
              value={Utility.numberFormat(this.state.count)}
            />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>
        </Form>
        <Confirm
          id="deleteinout"
          content="ยืนยันลบรายการ"
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url =
              Settings.baseUrl + "/ledger_category/" + this.state.object_id + "/";
            Utility.delete(url, (status, data) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.props.handleLedgerCategoryChanges();
                this.componentDidMount();
                this.resetForm();
              } else alert(data.error);
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    branches: state.branches,
    ledger_category: state.ledger_category,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleLedgerCategoryChanges: async () => {
    loadLedgerCategory(dispatch);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LedgerCategory);
// export default LedgerCategory;
