/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import LedgerForm from './LedgerForm'
import LedgerFormSearch from './LedgerFormSearch'
import LedgerBalanceModal from './LedgerBalanceModal'
import LedgerCardSwipeModal from './LedgerCardSwipeModal'
import { connect } from 'react-redux'
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Button,
  Icon,
  Dimmer, Loader, Segment,
  Label,
  Header,
  Popup,
  Input,
  Modal,
  Pagination,
} from 'semantic-ui-react';
import MsgInput from '../Error/MsgInput'
import moment from 'moment';
import { ContextMenuProvider } from 'react-contexify';
import LedgerPrintPreview from './LedgerPrintPreview'
import { activations_branch, loadLedger, loadDailyLedger } from '../../actions'

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

  }

  render() {
    const { rowIndex, field, data, disabled, ...props } = this.props;
    return (
      <Cell><center style={{ padding: '4px' }}>
        {!disabled && <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a>}
        {disabled && <Icon title='admin เท่านั้น' style={{ opacity: '0.4' }} name='window close outline' />}
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;

    let color = '';
    if (data[rowIndex].kind == 'IN' && field == 'income') color = 'green'
    else if (data[rowIndex].kind == 'EX' && field == 'expenses') color = 'red'
    if (['cash', 'card', 'transfer', 'online'].includes(field) && data[rowIndex][field] !== '0.00') {
      color = data[rowIndex].kind == 'IN' ? 'green' : 'red'
    }
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <Cell {...props} >
        <ContextMenuProvider
          data={rowIndex}
          id="menu_id">
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}><span className={color}>{data[rowIndex][field]}</span></div>}
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}

class IconItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props} >
        <div className={this.props.textAlign}><span>{data[rowIndex].kind == 'IN' ? <Icon name='sticky note' color='green' /> : <Icon name='sticky note' color='red' />} {data[rowIndex].kind_title}</span></div>
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class Ledger extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      bill: {},
      billItems: [],
      ledger: {
        customer_id: 0,
        ledger_date: moment(),
        kind: 'IN',
        card_bank_card: ''
      },
      start_date: moment(),
      end_date: moment(),
      msg_error: {},
      daily_ledger_cash: 0,
      modal_ledger_balance: false,
      date_balance: Utility.formatDate2(moment()),
      modal_delete: false,
      modal_card_swipe: false,
      colSortDirs: {
        'record_date': 'ASC',
      },
      item_report: [],
      expenses: 0,
      income: 0,
    }
    this.columnKey = 'record_date'
    this.sortDi = 'ASC'
    this.submitData = this.submitData.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this)
    this.handlerVoidBill = this.handlerVoidBill.bind(this)
    this.handlerModalInputChange = this.handlerModalInputChange.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)

    this.addledger = this.addledger.bind(this)
    this.addledgerList = this.addledgerList.bind(this)
    this.handlerSearchSum = this.handlerSearchSum.bind(this)

    this.searchReport = this.searchReport.bind(this)
  }

  submitData() {

  }

  handlePaginationChange = (e, { activePage }) => {
    this.handlerSearch(undefined, activePage)
  }

  resetForm() {
    let ledger = {
      ledger_date: moment()
    }
    this.setState({
      modal_ledger: false,
      modal_delete: false,
      description_reject: '',
      ledger: ledger,
      ledger_action: 'ADD',
      msg_error: {},
      delete_id: ''
    })
  }


  addledger(e) {
    e.preventDefault()
    let ledger = {
      customer_id: 0,
      ledger_date: moment(),
      kind: 'IN'
    }
    this.setState({
      modal_ledger: true,
      ledger: ledger,
      ledger_action: 'ADD'
    })

  }

  handlerSearch(data, page) {
    let data2 = Object.assign({}, data);
    if (data == undefined) {
      data2 = this.state.data2
    }
    if (page == undefined) {
      page = 1
    }
    this.setState({
      start_date: data == undefined ? data2.start_date : Utility.formatDateViewToDate(data2.start_date),
      end_date: data == undefined ? data2.end_date : Utility.formatDateViewToDate(data2.end_date),
    })
    const { branch } = this.props.auth
    delete data2.e_date
    delete data2.s_date
    delete data2.ledger_categorys
    data2.columnKey = this.columnKey
    data2.sortDi = this.sortDi
    data2.is_enabled = 1
    data2.branch = branch.id
    data2.start_date = data == undefined ? data2.start_date : Utility.formatDateViewToDate(data2.start_date);
    data2.end_date = data == undefined ? data2.end_date : Utility.formatDateViewToDate(data2.end_date);
    if (data != undefined)
      this.props.loadDailyLedger(data2.start_date, branch.id)
    this.props.intData(data2, page)
    this.setState({
      data2: data2,
    })
  }

  async handlerSearchSum(data) {
    let data2 = Object.assign({}, data);
    const { branch } = this.props.auth
    delete data2.e_date
    delete data2.s_date
    delete data2.ledger_categorys
    data2.columnKey = this.columnKey
    data2.sortDi = this.sortDi
    data2.is_enabled = 1
    data2.branch = branch.id
    data2.start_date = Utility.formatDateViewToDate(data2.start_date);
    data2.end_date = Utility.formatDateViewToDate(data2.end_date);
    const res = await Utility.getAsync(Settings.baseUrl + '/ledger_sum/?' + Utility.jsonToQueryString(data2))
    this.setState({
      income: res.data.income,
      expenses: res.data.expenses,
    })
  }

  async searchReport() {
    let data2 = this.state.data2
    const { branch } = this.props.auth
    data2.branch = branch.id
    delete data2.page
    let querystring = Utility.jsonToQueryString(data2)
    const res = await Utility.getAsync(Settings.baseUrl + '/ledger/?' + querystring)
    this.setState({
      item_report: res.data
    })
  }

  async componentDidMount() {

    const { branch } = this.props.auth
    this.setState({
      loader_active: true
    });
    const q = {
      start_date: Utility.formatDate2(moment()),
      end_date: Utility.formatDate2(moment()),
      branch: branch.id,
      is_enabled: 1,
      columnKey: this.columnKey,
      sortDi: this.sortDi,
    }
    this.setState({
      data2: {
        start_date: Utility.formatDate2(moment()),
        end_date: Utility.formatDate2(moment()),
        branch: branch.id,
        is_enabled: 1,
        columnKey: this.columnKey,
        sortDi: this.sortDi,
      }
    })
    this.props.intData(q, 1)
    this.handlerSearchSum({
      start_date: Utility.formatDate(moment()),
      end_date: Utility.formatDate(moment()),
      branch: branch.id,
      is_enabled: 1,
      columnKey: this.columnKey,
      sortDi: this.sortDi,
    })
    this.props.loadDailyLedger(moment(), branch.id)
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  getTypeSale(key) {
    let type = Settings.type_sale.filter((o) => { return o.value == key });
    return type[0];
  }

  setFieldValue(items) {
    const { ledger_category } = this.props

    let amount = items.length;
    let expenses = 0;
    let income = 0;
    for (let i = 0; i < items.length; i++) {
      const ledger_category = items[i].ledger_category
      items[i].object_title = ledger_category.title;
      items[i].is_void = 1
      items[i].branch_name = items[i].branch.name;
      items[i].date = Utility.formatDate(items[i].record_date);
      items[i].time = Utility.formatTime(items[i].record_date);
      items[i].kind_title = items[i].kind == 'EX' ? 'รายจ่าย' : 'รายรับ'

      items[i].income = Utility.priceFormat(items[i].kind == 'IN' ? parseFloat(items[i].total) : 0)
      items[i].expenses = Utility.priceFormat(items[i].kind != 'IN' ? items[i].total : 0)
      items[i].card_service = Utility.priceFormat(items[i].card_service)


      if (items[i].ledger_category.id <= 3) {
        let item = items[i].object_bill;
        if (item) {
          items[i].bill_status_title = item.is_void ? 'ยกเลิกบิลแล้ว' : ''
          items[i].is_void = item.is_void;
        }
      }

      items[i].type_pay = Utility.getObjectByValue(Settings.payment_option, items[i].payment).text
      if (items[i].card_bank_card) {
        items[i].type_pay = `${items[i].type_pay} ${items[i].card_bank_card.period ? ` (${items[i].card_bank_card.period_display})` : ''}`
        items[i].card_bank_card_display = `${items[i].card_bank_card.kind} - ${items[i].card_bank_card.bank.name}`
      }
      items[i].cash = Utility.priceFormat(Utility.removeCommas(items[i].cash));
      items[i].card = Utility.priceFormat(Utility.removeCommas(items[i].card));
      items[i].online = Utility.priceFormat(Utility.removeCommas(items[i].payment === 'TF' ? items[i].transfer : 0));
      items[i].transfer = Utility.priceFormat(Utility.removeCommas(items[i].payment !== 'TF' ? items[i].transfer : 0));
      items[i].card_code = items[i].card_code ? items[i].card_code.toString().replace(/(\d)(?=(\d{4})+(?!\d))/g, '$1 ') : ''
    }

    return items;
  }

  loadBillDetail(data) {

    let action = 'exc';
    if (data.kind == 'BU')
      action = 'buy';
    else if (data.kind == 'SE')
      action = 'sell';

    this.setState({
      modal_detail: true,
      bill_loader_active: true,
      bill: data,
      total_sell_price: Utility.priceFormat(parseFloat(data.sell)),
      otal_sell_weight: 0,
      total_buy_price: Utility.priceFormat(parseFloat(data.buy)),
      net_price: data.total,
      action: action,
      itemCount: data.amount,
      description: data.description
    })

    let url = Settings.baseUrl + "/bill/" + data.id + "/items/";
    Utility.get(url, (e, res) => {
      let billItems = [];
      for (let i in res) {
        let d = res[i]
        if (d.kind == 'SE') {
          let p = Utility.getObject(this.products, d.product_id)
          billItems.push({
            type_title: 'ขาย',
            type: 'SE',
            id: i + 1,
            amount: d.amount,
            sell_price: d.sell,
            sell_weight: 0,
            sell_weight_real: d.weight,
            buy_price: 0,
            buy_weight: 0,
            gold_price: d.price_gold,
            product_code: p.code,
            product_id: p.id,
            product_name: p.name,
            product_type: p.kind.name,
            category_name: p.category.name,
            category_id: p.category.id,
            code_change: d.code_change,
            exchange: d.exchange,
          })
        } else {
          let c = Utility.getObject(this.categories, d.category)
          billItems.push({
            type_title: 'ซื้อ',
            type: 'BU',
            'id': i + 1,
            amount: 0,
            sell_price: 0,
            sell_weight: 0,
            sell_weight_real: 0,
            buy_price: d.buy,
            buy_weight: d.weight,
            gold_price: d.price_gold,
            product_code: '',
            product_name: d.product_name,
            product_type: '',
            category_name: c.name,
            category_id: d.catory,
            code_change: d.code_change,
            exchange: d.exchange,
          })
        }
      }


      this.setState({
        bill_loader_active: false,
        billItems: billItems
      })

    });

    let url2 = Settings.baseUrl + "/bill_staff/?bill_id=" + data.id;
    Utility.get(url2, (e, res) => {
      this.setState({
        billstaff: res
      })
    });
  }

  loadBillInfo(bill_id) {

    let url2 = Settings.baseUrl + "/bills/" + bill_id + "/";
    Utility.get(url2, (e, res) => {
      this.loadBillDetail(res);
    });
  }

  handlerVoidBill(e, data) {

    if (data.is_void == 1) {
      alert('ถูกลบแล้ว');
      return;
    }

    let url = Settings.baseUrl + "/bill/" + data.id + "/void/"
    Utility.getAuth(url, (e, d) => {

      alert('ยกเลิกบิลสำเร็จ');
      this.componentDidMount();
      this.setState({
        modal_detail: false,
        bill: {}
      })
    });
  }

  loadledger(object) {
    this.setState({
      modal_ledger: true,

    })
    const _o = JSON.parse(JSON.stringify(object))
    _o.customer = object.customer.id
    this.setState({
      ledger: _o,
      ledger_action: 'EDIT'
    })
  }

  onSortChange(columnKey, sortDir) {
    this.columnKey = columnKey
    this.sortDi = sortDir
    this.handlerSearch(undefined, this.props.ledger_list.activePage)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handlerModalInputChange(e, d) {

    const { ledger_category } = this.props

    let ledger = this.state.ledger;
    ledger[d.name] = d.value;

    if (d.name == 'kind') {
      let _ledger_category = []
      for (let i in ledger_category) {
        if (_ledger_category[i].kind == d.value)
          _ledger_category.push({
            value: ledger_category[i].id,
            key: ledger_category[i].id,
            text: ledger_category[i].title
          });
      }
      this.setState({
        ledger_category: _ledger_category
      })
    }


    this.setState({
      ledger: ledger
    })
  }

  addledgerList(e) {
    e.preventDefault()
    this.setState({
      modal_ledger: false,
      modal_ledger_list: true
    })

  }

  handlerDropdownSearch(e, { value }) {
    const { branches } = this.props
    const branch = branches.find((item) => item.id == value)
    this.props.handleBranchChange(branch)
  }

  deleteledger(description) {
    let url = Settings.baseUrl + "/ledger/" + this.state.delete_id + "/";
    let data = {
      status: 0,
      description: description,
    }
    Utility.patch(url, data, (s, d, c) => {

      if (s) {
        alert('ลบข้อมูลสำเร็จ')
        this.resetForm()
        this.componentDidMount();

      } else {
        alert(d.error)
      }

      this.setState({
        loadingSubmitledger: false
      })

    });
  }

  render() {

    const { ledger_list, daily_ledger } = this.props
    const { branch, role } = this.props.auth
    const items = this.setFieldValue(ledger_list.list);

    const amount = items.length;
    let expenses = 0
    let income = 0;
    let daily_ledger_cash = daily_ledger.list.length == 0 ? Utility.parseFloat(0) : Utility.parseFloat(daily_ledger.list[0].cash)

    items.map((item, i) => {
      income += parseFloat(item.kind == 'IN' ? parseFloat(item.total) : 0)
      expenses += parseFloat(item.kind != 'IN' ? item.total : 0)

    });

    let remain = (income + daily_ledger_cash) - expenses

    return (
      <div>
        {this.state.modal_ledger &&
          <LedgerForm
            action={this.state.ledger_action}
            ledger={this.state.ledger}
            onClose={() => this.setState({ modal_ledger: false })}
            ledger_category_list={this.state.ledger_category_list}
            onSave={() => {
              alert('บันทึกข้อมูลสำเร็จ!')
              this.componentDidMount()
              this.setState({ modal_ledger: false })
            }} />}
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h3'>
                <div style={{ padding: '7px' }} className="pull-left"> <Icon name='book' /> รายรับ-รายจ่าย </div>
              </Header>

            </Form.Field>
            <Form.Field width={10}>
              <Button id='addIn-Out' size='small' primary floated='right' onClick={this.addledger} labelPosition='left' icon='plus' content='เพิ่มรายการ' />
              <Button size='small' primary floated='right'
                onClick={() => {
                  this.setState({
                    modal_card_swipe: true,
                  });
                }}
                labelPosition='left' icon='credit card' content='รูดเงินสด' />
              <Button size='small' primary floated='right'
                onClick={() => {
                  this.setState({
                    modal_ledger_balance: true,
                    date_balance: Utility.formatDate2(moment()),
                    balance_action: 'update',
                  });
                }}
                labelPosition='left' icon='send' content='ส่งยอดเงินสด' />
            </Form.Field>
          </Form.Group>
        </Form>
        <Segment color='black' >
          <Dimmer className={ledger_list.is_ready ? '' : 'active'} inverted>
            <Loader content='Loading' inverted />
          </Dimmer>
          <LedgerFormSearch value={this} onSubmitSearch={this.handlerSearch} onSubmitSearchSum={this.handlerSearchSum} />
          <div id='table_width'>
            <Table
              rowsCount={items.length}
              rowHeight={35}
              headerHeight={35}
              width={this.state.table_width}
              height={402}>

              <Column
                header={<Cell></Cell>}
                cell={
                  <OptionItemsCell disabled={role == 'U'} data={items} field="kind_title" onClickMenu={(e, data) => {
                    let index = data.positon;
                    let object = items[index];
                    if (data.action == 'view') {
                      if (object.ledger_category <= 1)
                        this.loadledger(object);
                      else {
                        this.loadledger(object);
                      }
                    } else if (data.action == 'edit') {
                      this.loadledger(object);
                    } else if (data.action == 'delete') {
                      if (object.ledger_category.id == 14 || object.ledger_category.id == 15 || object.ledger_category.id == 16
                        || object.ledger_category.id == 17 || object.ledger_category.id == 18 || object.ledger_category.id == 19
                        || object.ledger_category.id == 20 || object.ledger_category.id == 21 || object.ledger_category.id == 22 || object.ledger_category.id == 23) {
                        alert('ไม่สามารถลบรายการได้');
                      }
                      else if (object.is_void && object.ledger_category.menu != 'ขายฝาก') {
                        if (window.confirm('ยืนยันลบรายการนี้')) {
                          this.setState({ 'modal_delete': true, 'delete_id': object.id })
                        }
                      }
                      else
                        alert('กรุณายกเลิกบิลก่อน');
                    }
                  }} />
                }
                width={80}
              />

              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='kind'
                    sortDir={this.state.colSortDirs.kind}>
                    รับ-จ่าย
                  </SortHeaderCell>}
                cell={
                  <IconItemsCell data={items} field="kind_title" />
                }
                width={80}
              />
              <Column
                header={
                  <Cell>
                    สาขา
                  </Cell>}
                cell={
                  <ItemsCell position='top left' width={150} data={items} field="branch_name" />
                }
                width={150}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='number'
                    sortDir={this.state.colSortDirs.number}>
                    เลขที่ใบสำคัญ
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={150} data={items} field="number" />
                }
                width={150}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='object_number'
                    sortDir={this.state.colSortDirs.object_number}>
                    เลขที่อ้างอิง
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={150} data={items} field="object_number" />
                }
                width={150}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='record_date'
                    sortDir={this.state.colSortDirs.record_date}>
                    วันที่
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={100} data={items} field="date" />
                }
                width={100}
              />

              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='record_date'
                    sortDir={this.state.colSortDirs.record_date}>
                    เวลา
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={80} data={items} field="time" />
                }
                width={80}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='ledger_category__title'
                    sortDir={this.state.colSortDirs.ledger_category__title}>
                    รายการ
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={180} data={items} field="object_title" />
                }
                width={180}
              />

              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='income'
                    className='text-right'
                    sortDir={this.state.colSortDirs.income}>
                    จำนวนรับ
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top right' width={120} data={items} field="income" textAlign='text-right' color='green' />
                }
                width={120}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='expenses'
                    className='text-right'
                    sortDir={this.state.colSortDirs.expenses}>
                    จำนวนจ่าย
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top right' width={180} data={items} field="expenses" textAlign='text-right' color='red' />
                }
                width={180}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='payment'
                    sortDir={this.state.colSortDirs.payment}>
                    ประเภทชำระ
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={180} data={items} field="type_pay" />
                }
                width={180}
              />
              <Column
                header={<Cell>บัญชีธนาคาร</Cell>}
                cell={
                  <ItemsCell position='top left' width={180} data={items} field="transfer_bank_account_name" />
                }
                width={180}
              />
              <Column
                header={<Cell>ประเภทบัตรเครดิต</Cell>}
                cell={
                  <ItemsCell position='top left' width={180} data={items} field="card_bank_card_display" />
                }
                width={180}
              />
              <Column
                header={<Cell>รหัสบัตรเครดิต</Cell>}
                cell={
                  <ItemsCell position='top left' width={180} data={items} field="card_code" />
                }
                width={180}
              />

              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='customer__name'
                    sortDir={this.state.colSortDirs.customer__name}>
                    ชื่อลูกค้า
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={150} data={items} field="customer_name" />
                }
                width={150}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='staff__name'
                    sortDir={this.state.colSortDirs.staff__name}>
                    พนักงาน
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top left' width={150} data={items} field="staff_name" />
                }
                width={150}
              />
              <Column
                header={
                  <SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='card_service'
                    className='text-right'
                    sortDir={this.state.colSortDirs.card_service}>
                    ค่าธรรมเนียม
                  </SortHeaderCell>}
                cell={
                  <ItemsCell position='top right' width={100} data={items} field="card_service" textAlign='text-right' />
                }
                width={100}
              />
              {/* <Column
                header={<Cell className='text-right'>สถานะยกเลิกบิล</Cell>}
                cell={
                  <ItemsCell position='top right' width={120} data={items} field="bill_status_title" textAlign='text-right' />
                }
                width={120}
              /> */}

              <Column
                header={<Cell className='text-right'>ยอดเงินสด</Cell>}
                cell={
                  <ItemsCell position='top right' width={200} data={items} field="cash" textAlign='text-right' />
                }
                width={100}
              />
              <Column
                header={<Cell className='text-right'>ยอดเงินโอน</Cell>}
                cell={
                  <ItemsCell position='top right' width={200} data={items} field="transfer" textAlign='text-right' />
                }
                width={100}
              />
              <Column
                header={<Cell className='text-right'>ยอดบัตร</Cell>}
                cell={
                  <ItemsCell position='top right' width={200} data={items} field="card" textAlign='text-right' />
                }
                width={100}
              />
              <Column
                header={<Cell className='text-right'>ยอดต่อดอกออนไลน์</Cell>}
                cell={
                  <ItemsCell position='top right' width={200} data={items} field="online" textAlign='text-right' />
                }
                width={120}
              />
              <Column
                header={<Cell >หมายเหตุ</Cell>}
                cell={
                  <ItemsCell position='top left' width={300} data={items} field="description" />
                }
                width={300}
              />
            </Table>
          </div>
          {this.state.open_print ? <LedgerPrintPreview
            data={{
              start_date: this.state.start_date,
              end_date: this.state.end_date
            }}
            onClose={() => {
              this.setState({ open_print: false });
            }}
            items={this.setFieldValue(this.state.item_report)}
            daily_ledger={daily_ledger}
          /> : ''}
          <br />
          <Form className='attached fluid' size='small' >
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={ledger_list.count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} />
              <Form.Input disabled tabIndex={-1} width={4} transparent={true} />
              <Form.Field width={4}>
                <label>
                  ยกยอดเงินสด
                  <a style={{
                    color: 'blue',
                    paddingLeft: '10px'
                  }}
                    onClick={() => {
                      let date = this.state.start_date
                      if (typeof this.state.start_date != 'string') {
                        date = Utility.formatDate2(moment())
                      }
                      let date_before = new Date(date)
                      date_before.setDate(date_before.getDate() - 1)
                      date_before = Utility.formatDate2(date_before)
                      this.setState({
                        modal_ledger_balance: true,
                        date_balance: date_before,
                        balance_action: 'check',
                      });
                    }}
                  >ดูรายละเอียด</a>
                </label>
                <Input id='daily_balance' placeholder='' className='text-right grey' width={4} value={Utility.priceFormat(daily_ledger_cash)} color='grey' readOnly />
              </Form.Field>
              <Form.Input id='amountIncomes' label='รับ' placeholder='' className='text-right green' width={4} value={Utility.priceFormat(this.props.ledger_list.income)} color='green' readOnly />
              <Form.Input id='amountOutcomes' label='จ่าย' placeholder='' className='text-right red' width={4} value={Utility.priceFormat(this.props.ledger_list.expenses)} color='red' readOnly />
              <Form.Input id='amountTotals' label='คงเหลือ' placeholder='' className='text-right yellow' width={4} value={Utility.priceFormat(Number(daily_ledger_cash) + (Number(this.props.ledger_list.income) - Number(this.props.ledger_list.expenses)))} color='yellow' readOnly />
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>

                <Button size='small' primary floated='right' labelPosition='left' icon='print' content='พิมพ์' onClick={() => {
                  this.searchReport()
                  this.setState({
                    open_print: true
                  })
                }} />
                {this.props.ledger_list.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
                  activePage={this.props.ledger_list.activePage}
                  onPageChange={this.handlePaginationChange}
                  totalPages={this.props.ledger_list.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
        <Modal open={this.state.modal_delete} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='btnClose'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_delete: false, delete_id: '', description_reject: '', msg_error: {} })} />
          <Modal.Header>{'แจ้งเตือน'}</Modal.Header>
          <Modal.Content className='scrolling'>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.description_reject != null}>
                  <label>หมายเหตุ*<MsgInput text={this.state.msg_error.description_reject} /></label>
                  <Input id='Input'
                    onChange={(e) => {
                      this.setState({ description_reject: e.target.value })
                    }}
                    value={this.state.description_reject}
                    maxLength={255} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSavePass' size='small' primary
              onClick={(e) => {
                if (this.state.description_reject == '' || this.state.description_reject == undefined) {
                  this.setState({ msg_error: { description_reject: "ต้องระบุหมายเหตุ" } })
                } else {
                  this.deleteledger(this.state.description_reject)
                }
              }}
            >ยืนยัน</Button>
          </Modal.Actions>
        </Modal>
        {this.state.modal_ledger_balance ?
          <LedgerBalanceModal
            onClose={() => {
              this.setState({
                modal_ledger_balance: false,
              });
            }}
            date={this.state.date_balance}
            action={this.state.balance_action}
          />
          : null}
        {this.state.modal_card_swipe ?
          <LedgerCardSwipeModal
            action={this.state.ledger_action}
            ledger={this.state.ledger}
            onClose={() => this.setState({ modal_card_swipe: false })}
            ledger_category_list={this.state.ledger_category_list}
            onSave={() => {
              alert('บันทึกข้อมูลสำเร็จ!')
              this.componentDidMount()
              this.setState({ modal_card_swipe: false })
            }} />
          : null}
      </div>
    );
  }
}

const mapStateToProps = ({ ledger_category, ledger_list, branches, auth, daily_ledger }) => {
  return ({
    auth,
    branches,
    ledger_list,
    ledger_category,
    daily_ledger,
  })
}


const mapDispatchToProps = dispatch => ({
  intData: (q, page) => {
    loadLedger(dispatch, Utility.jsonToQueryString(q), page)
  },
  handleBranchChange: (branch) => {
    dispatch(activations_branch(dispatch, branch))

    const q = {
      start_date: Utility.formatDate2(moment()),
      end_date: Utility.formatDate2(moment()),
      branch: branch.id,
      is_enabled: 1
    }
    loadLedger(dispatch, Utility.jsonToQueryString(q), 1)
    loadDailyLedger(dispatch, moment(), branch.id)
  },
  loadDailyLedger: (date, branch_id) => {
    loadDailyLedger(dispatch, date, branch_id)
  }

})
export default connect(
  mapStateToProps, mapDispatchToProps
)(Ledger)
