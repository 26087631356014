/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
   Segment, Button, Loader, Dimmer, Modal, Table, Image, Checkbox
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../Utility';
import Settings from '../../Settings';
import logo from '../../logo.png';
import signature from '../../signature.png'
import moment from 'moment';
import { connect } from 'react-redux'

class DisplayPrint extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const {branch} =this.props
        const divStyle = {
            color: '#111',
            'font-size': '12px',
            'line-height': '20px',
            'font-family': 'monospace',
            'border-collapse': 'collapse',
            border: '1px solid black'
        };
        const textRight = {
            'text-align': 'right'
        }
        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let invoice_info = Settings.invoice_info
        let tax_number = this.props.tax_number;
        let total_cost = 0
        let total_weight = 0
        let total_unit = 0

        const bill_info = this.props.bill_info
        const bill_items = this.props.bill_items
        console.log(bill_info)
        let sell_cost = bill_info.gold_price.gold_ornaments_buy / 15.2
        

        return (<div >
            <div id='paperA4-portrait' style={{ 'page-break-before': 'always' }}>
                <Table basic className='nobarder' id='table-to-xls' style={divStyle}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                            <Table.Cell style={{ width: '10%' }}></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan='3' style={textCenter}>
                                <Image src={logo} width={70} height={70} />
                            </Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}><b>{invoice_info.name}</b></Table.Cell>
                            <Table.Cell colSpan='3'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}> <b>ใบรับซื้อทองเก่า/ใบสำคัญจ่าย</b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='3' style={textLeft}>{invoice_info.address1}</Table.Cell>
                            <Table.Cell colSpan='3'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}> <b></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='3' style={textLeft}>{invoice_info.address2}</Table.Cell>
                            <Table.Cell colSpan='3'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='7'></Table.Cell>
                            <Table.Cell colSpan='3' style={textCenter}> เลขประจำตัวผู้เสียภาษีอากร {invoice_info.citizen_id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='5' style={textLeft}><div style={textU}>สาขาที่ออกใบกำกับภาษี สาขาที่ {this.props.tax_code}</div></Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}> วันที่ </Table.Cell>
                            <Table.Cell style={textCenter}>  </Table.Cell>
                            <Table.Cell colSpan='2' style={textLeft}> {Utility.formatDate(bill_info.bill_date)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='5' style={textLeft}></Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}> เลขที่ </Table.Cell>
                            <Table.Cell style={textCenter}>  </Table.Cell>
                            <Table.Cell colSpan='2' style={textLeft}> {tax_number}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell colSpan='1' style={textLeft}>&nbsp;&nbsp;ชื่อผู้ขาย</Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.name}</Table.Cell>
                            <Table.Cell colSpan='3' style={textRight}>ทองคำแท่งซื้อเข้า บาทละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{(Utility.priceFormat(bill_info.gold_price.gold_bar_buy))}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='1' style={textLeft}>&nbsp;&nbsp;ที่อยู่</Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.address}</Table.Cell>
                            <Table.Cell colSpan='3' style={textRight}>ทองคำแท่งขายออก บาทละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>{(Utility.priceFormat(bill_info.gold_price.gold_bar_sell))}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='1' style={textRight}></Table.Cell>
                            <Table.Cell colSpan='3' style={textLeft}>{bill_info.customer.district} {bill_info.customer.city} {bill_info.customer.province} {bill_info.customer.postal_code}</Table.Cell>
                            <Table.Cell colSpan='3' style={textRight}>ทองรูปพรรณรับชื้อคืน บาทละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>  {(Utility.priceFormat(bill_info.gold_price.gold_ornaments_buy))}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='2' style={textRight}></Table.Cell>
                            <Table.Cell colSpan='2' style={textLeft}></Table.Cell>
                            <Table.Cell colSpan='3' style={textRight}>ทองรูปพรรณรับชื้อคืน กรัมละ</Table.Cell>
                            <Table.Cell colSpan='2' style={textRight}>  {Utility.priceFormat(sell_cost)}</Table.Cell>
                            <Table.Cell style={textLeft}>บาท</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>ลำดับ</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>รายละเอียด</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>น้ำหนัก</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>หน่วย</Table.Cell>
                            <Table.Cell colSpan='2' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>จำนวนเงิน</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Item</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Descripttion</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>Amount</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        {bill_items.map((item, i) => {
                            total_cost += parseFloat(item.buy)
                            total_weight += parseFloat(item.weight)
                            total_unit += parseFloat(item.amount)
                            return (<Table.Row key={i}>
                                <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>{i + 1}</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'left' }}>{item.product_name}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{Utility.weightFormat(item.weight)}</Table.Cell>
                                <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>กรัม</Table.Cell>
                            <Table.Cell colSpan='2' style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>{Utility.priceFormat(item.buy)}&nbsp;&nbsp;</Table.Cell>
                            </Table.Row>)
                        })}
                        {bill_items.length < 3 ? <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                        </Table.Row> : ''}
                        <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                        </Table.Row>
                        {/* <Table.Row>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}>&nbsp;</Table.Cell>
                            <Table.Cell colSpan='5' style={{ 'border-right': '1px solid black', 'text-align': 'left' }}>{Utility.arabicNumberToText(bill_info.kind == "SE" ? bill_info.total:bill_info.sell)}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'center' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={textCenter}></Table.Cell>
                        </Table.Row> */}

                    </Table.Body>
                    <Table.Body >
                        <Table.Row>
                            <Table.Cell colSpan='6' style={{ 'border-right': '1px solid black', 'text-align': 'right' }}>&nbsp;&nbsp;รวม</Table.Cell>
                    <Table.Cell style={{ 'border-right': '1px solid black','border-bottom': '1px solid black', 'text-align': 'right' }}>&nbsp;&nbsp;{Utility.weightFormat(total_weight)}</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black','border-bottom': '1px solid black', 'text-align': 'right' }}>&nbsp;&nbsp;กรัม</Table.Cell>
                    <Table.Cell colSpan='2' style={{ 'border-right': '1px solid black','border-bottom': '1px solid black', 'text-align': 'right' }}>&nbsp;&nbsp;{Utility.priceFormat(total_cost)}&nbsp;&nbsp;</Table.Cell>
                        </Table.Row>
                        {/* <Table.Row>
                            <Table.Cell colSpan='6' style={{'text-align': 'right' }}></Table.Cell>
                            <Table.Cell style={{ 'border-bottom': '1px solid black', 'text-align': 'right' }}></Table.Cell>
                            <Table.Cell style={{ 'border-bottom': '1px solid black', 'text-align': 'right' }}></Table.Cell>
                            <Table.Cell colSpan='2' style={{ 'border-bottom': '1px solid black', 'text-align': 'right' }}></Table.Cell>
                        </Table.Row> */}
                    </Table.Body>
                    
                    <Table.Body >
                        <Table.Row>
                            <Table.Cell style={textLeft}>&nbsp;&nbsp;หมายเหตุ</Table.Cell>
                            <Table.Cell colSpan='8' style={textLeft}>แนบสำเนาบัตรประชาชน</Table.Cell>
                            <Table.Cell style={{ 'border-right': '1px solid black', 'text-align': 'left' }}></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textLeft}>&nbsp;&nbsp;ชำระโดย</Table.Cell>
                            <Table.Cell style={textRight}><Checkbox checked={bill_info.payment == 'CS' || bill_info.payment == 'CC'} /></Table.Cell>
                            <Table.Cell style={textLeft}>เงินสด</Table.Cell>
                            <Table.Cell style={textRight}><Checkbox checked={bill_info.payment == 'CD' || bill_info.payment == 'CC'} /></Table.Cell>
                            <Table.Cell colSpan='5' style={textLeft}>บัตรเครดิต</Table.Cell>
                            <Table.Cell style={textLeft} style={{ 'border-right': '1px solid black', 'text-align': 'left' }}></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell style={textLeft} style={{background: '#eee'}} colSpan='2'>&nbsp;&nbsp;จำนวนเงินสุทธิ</Table.Cell>
                            <Table.Cell style={textLeft} style={{ background: '#eee','border-right': '1px solid black', 'text-align': 'left' }}></Table.Cell>
                            <Table.Cell style={textLeft} colSpan='7'>{Utility.arabicNumberToText(bill_info.kind == "BU" ? bill_info.buy:bill_info.buy)}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body style={{ border: '1px solid black' }}>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='10'>ข้าพเจ้าได้รับเงินไปถูกต้องแล้วและขอรับรองว่าของที่นำมาขายเป็นของข้าพเจ้าโดยแท้จริง ไม่ใช่เป็นของที่ได้มาโดยการกระทำผิดแต่อย่างใด จึงขอลงชื่อไว้เป็นหลักฐาน</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='5'><br />.................................</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='5'><br />.................................</Table.Cell>
                            
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='5'>ผู้ขาย</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='5'>ผู้รับเงิน</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={textCenter} colSpan='5'>วันที่/Date……/……/…….</Table.Cell>
                            <Table.Cell style={textCenter} colSpan='5'>วันที่/Date……/……/…….</Table.Cell>
                        </Table.Row>
                    </Table.Body>

                </Table>
            </div>
        </div>)
    }
}

class InvoiceOldPrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            bill_info: {},
            bill_items: []
        }
    }

    componentDidMount() {


        Utility.get(Settings.baseUrl + "/branch_setting/?is_enabled=1&branch=" + this.props.auth.branch.id, (e, resJson) => {
            this.b = resJson;
            this.setState({ tax_code: resJson[0].tax_code })
          });

        var bills = Utility.getFetch(Settings.baseUrl + "/bills/" + this.props.bill_id + "/");
        var bill_items = Utility.getFetch(Settings.baseUrl + "/bill/" + this.props.bill_id + "/items/");
        // var tax = Utility.getFetch(Settings.baseUrl + "/bill/" + this.props.bill_id + "/tax/");
        Promise.all([bills, bill_items]).then((values) => {
            let tax_number = values[0].bill_number
            let items = []
            for (let i in values[1]) {
                let item = values[1][i]
                // check if same product
                if(item.kind == "BU"){
                    items.push(item)
            }
            }
            this.setState({
                loader: false,
                tax_number: tax_number,
                bill_info: values[0],
                bill_items: items
            })
        });
    }
    render() {

        const {branch} = this.props.auth

        let title = 'สรุปรายการซื้อ-ขายทอง';
        let filename = 'bills-slip'



        return (<div>
            <Modal id='modalPreview' open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    {this.state.loader ? <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment> : <div id='view-print'>
                    <DisplayPrint subtitle='ต้นฉบับ'branch={branch} bill_info={this.state.bill_info} bill_items={this.state.bill_items} tax_number={this.state.tax_number} tax_code={this.state.tax_code}/>
                    {/* <DisplayPrint subtitle='ต้นฉบับ'branch={branch} bill_info={this.state.bill_info} bill_items={this.state.bill_items} tax_number={this.state.tax_number} tax_code={this.state.tax_code}/> */}
                    </div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="ui primary button small"
                        table="table-to-xls"
                        filename={filename}
                        sheet={title}
                        buttonText="Download as XLS" >
                    </ReactHTMLTableToExcel>

                    <Button primary icon='print' size='small' onClick={() => {
                        var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                        mywindow.document.write('<html><head><title>' + title + '</title>');
                        mywindow.document.write('</head><body style="font-size: 12px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif;width: 100%;height: 100%; margin: 0;padding: 0;">');
                        mywindow.document.write('<style>');
                        mywindow.document.write('@page {size: A4;margin: 5mm;} ');
                        mywindow.document.write('</style>');
                        mywindow.document.write(document.getElementById('view-print').innerHTML);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        mywindow.print();
                        setTimeout(()=>{
                            mywindow.close();
                        },2000)
                    }} labelPosition='right' content='Print' />

                    <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}


const mapStateToProps = state =>{
    return ({
      auth: state.auth,
    })
  }

  export default connect(
    mapStateToProps,
  )(InvoiceOldPrintPreview)