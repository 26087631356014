/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import LedgerCategoryForm from './LedgerCategoryForm'
import { connect } from 'react-redux'
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Icon,
  Modal, Dropdown,
  Grid,
  Header,
  TextArea,
  FormField
} from 'semantic-ui-react';
import InputSearchCustomer from '../Customer/InputSearchCustomer';
import NumberFormat from 'react-number-format';

class LedgerForm extends Component{

    constructor(props){
        super(props);
        this.ledger_category_list = props.ledger_category.list

        this.state = {
            msg_error: {},
            ledger: props.ledger,
            card_code:'',
            payment_type:[],
            bank_cards: [],
        }
        this.handlerModalInputChange = this.handlerModalInputChange.bind(this)
        this.handleSubmitledger = this.handleSubmitledger.bind(this)
        this.updateLedgerCategory = this.updateLedgerCategory.bind(this)
    }

    setLedgerCategoryOption(kind){
      let ledger_category_options = []

      for(let i in this.ledger_category_list){
        let d =this.ledger_category_list[i]

        if(d.kind != kind || (d.is_standard==1 && d.id !=12))
          continue
        ledger_category_options.push({
          value: d.id,
          key: d.id,
          text: d.title
        })
      }
      this.setState({ledger_category_options: ledger_category_options})
    }

    componentDidMount(){
      this.setState({st:true})
      const {auth} = this.props
      let payment_type = []
      for(let i in Settings.payment_option){
        if(Settings.payment_option[i].value != 'CH'){
          payment_type.push({
            key: Settings.payment_option[i].key,
            value: Settings.payment_option[i].value,
            text: Settings.payment_option[i].text,
          })
        }
      }
      this.setState({
          ledger: {
            card_service:'',
            transfer:'',
            check:'',
            card:'',
            cash:'',
            customer_id: 0,
            ledger_date: moment(),
            kind: 'IN',
            card_bank_card: '',
          },
          payment_type:payment_type
        })
      Utility.get(Settings.baseUrl + "/banks/?is_enabled=1", (e, resJson) => {
        this.banks = resJson;
        let banks = []
        for (let i in resJson) {
          banks.push({
            value: resJson[i].id,
            key: resJson[i].id,
            text: resJson[i].name
          });
        }
        this.setState({
          banks: banks
        });
      })
      Utility.get(Settings.baseUrl + "/staffs/?is_enabled=1&branch="+auth.branch.id, (e, resJson) => {
        this.staffs = resJson;
        let staffs = []
        for (let i in resJson) {
          staffs.push({
            value: resJson[i].id,
            key: resJson[i].id,
            text: resJson[i].name
          });
        }
        this.setState({
          st:false,
          staffs: staffs
        });
      })
      Utility.get(Settings.baseUrl + "/bank_cards/?is_enabled=1", (e, resJson) => {
        this.bank_cards = resJson;
        let bank_cards = []
        for (let i in resJson) {
          if(resJson[i].period !== 0) continue
          bank_cards.push({
            value: resJson[i].id,
            key: resJson[i].id,
            text: resJson[i].kind + " - " + resJson[i].bank.name
          });
        }
        this.setState({
          bank_cards: bank_cards
        });
      })

      Utility.get(Settings.baseUrl + "/bank_account/", (e, resJson) => {
        this.bank_accounts = resJson;
  
        let bank_accounts = []
        for (let i in this.bank_accounts) {
          bank_accounts.push({
            value: this.bank_accounts[i].id,
            key: this.bank_accounts[i].id,
            text: this.bank_accounts[i].bank.name + ' ' + this.bank_accounts[i].number + ' (' + this.bank_accounts[i].name + ')',
            content: <Header as='h4' content={this.bank_accounts[i].bank.name} subheader={this.bank_accounts[i].number + ' (' + this.bank_accounts[i].name + ')'} />
          });
        }
        this.setState({
          bank_accounts: bank_accounts
        });
      })

      

      this.setLedgerCategoryOption(this.props.ledger.kind)
    }

    handleChangeKind(kind){
      let ledger = this.state.ledger
      ledger.kind = kind
      this.setLedgerCategoryOption(kind)
      this.setState({ ledger: ledger })
      let payment_type = []
      if(ledger.kind == 'IN'){
        // payment_type = Settings.payment_option
        if(this.state.ledger.payment == 'CH'){
          this.state.ledger.payment = null
        }
        for(let i in Settings.payment_option){
          if(Settings.payment_option[i].value != 'CH'){
            payment_type.push({
              key: Settings.payment_option[i].key,
              value: Settings.payment_option[i].value,
              text: Settings.payment_option[i].text,
            })
          }
        }
      }else{
        if(this.state.ledger.payment != 'CS' && this.state.ledger.payment != 'CH'){
          this.state.ledger.payment = null
        }
        payment_type.push({
          key: Settings.payment_option[1].key,
          value: Settings.payment_option[1].value,
          text: Settings.payment_option[1].text,
        })
        payment_type.push({
          key: Settings.payment_option[2].key,
          value: Settings.payment_option[2].value,
          text: Settings.payment_option[2].text,
        })
      }
      this.setState({payment_type})
    }

    handlerModalInputChange(e, d) {
      let ledger = this.state.ledger;
      ledger[d.name] = d.value;

      if(d.name == 'card_fee' || d.name == 'card_service' || d.name == 'check' || d.name == 'transfer' || d.name == 'card' || d.name == 'cash' || d.name == 'card_swipe_balance' ){
        d.value=d.value.toString().replace(/,/g,'')
        if (isNaN(parseFloat(d.value))) {
          ledger[d.name] = ''
        }else {
          let c = d.value
          c = c<0?c*-1:c
          ledger[d.name] =c ;
        }
      }

      if(d.name == 'check_code' || d.name == 'card_code'){
        d.value=d.value.toString().replace(/,/g,'')
        if(isNaN(parseInt(d.value))){
          ledger[d.name] = ''
        }else{
          ledger[d.name] = parseInt(d.value)
        }
      }

      if(d.name=='card_bank_card'){
        let o = Utility.getObject(this.bank_cards,d.value)
        ledger.card_fee = o.service;
      }
      if(d.name == 'ledger_category' && ledger.kind == 'IN'){
        let payment_type = []
        if(d.value == 12){
        payment_type.push({
          key: Settings.payment_option[1].key,
          value: Settings.payment_option[1].value,
          text: Settings.payment_option[1].text,
        })
        if(this.state.ledger.payment != 'CS'){
          this.state.ledger.payment = null
        }
      }
        else{
          // payment_type = Settings.payment_option
          for(let i in Settings.payment_option){
            if(Settings.payment_option[i].value != 'CH'){
              payment_type.push({
                key: Settings.payment_option[i].key,
                value: Settings.payment_option[i].value,
                text: Settings.payment_option[i].text,
              })
            }
          }
        }
        this.setState({payment_type})
      }
      if(d.name == 'card_service'){
        if (d.value.split('.').length != 1)
                        if (d.value.split('.')[1].length >2){
                            return;
                        }
      }
      
      if (!((this.state.ledger.card_fee==''||this.state.ledger.card_fee==undefined) || (this.state.ledger.card_swipe_balance==''||this.state.ledger.card_swipe_balance==undefined))){
        ledger.card = Utility.parseFloat(Utility.parseFloat(this.state.ledger.card_swipe_balance) - (Utility.parseFloat(this.state.ledger.card_swipe_balance) * Utility.parseFloat((this.state.ledger.card_fee/100)))).toFixed(2)
        // ledger.card = Utility.parseFloat(Utility.parseFloat((this.state.ledger.card_swipe_balance * (this.state.ledger.card_fee/100))) + Utility.parseFloat(this.state.ledger.card_swipe_balance)).toFixed(2)
      }
      
      this.setState({
        ledger: ledger,
        cash_ex : ledger.card
      })
  }

    updateLedgerCategory(ledger_category=null){
      Utility.get(Settings.baseUrl + "/ledger_category/?is_enabled=1",(status,data)=>{
        this.ledger_category_list = data
        let ledger = this.state.ledger
        this.setLedgerCategoryOption(ledger.kind )
        
        if(ledger_category!=null){
          if(ledger_category.kind === ledger.kind)
            ledger.ledger_category = ledger_category.id
        }
        if(ledger.kind == 'IN'){
          let payment_type = []
          if(ledger_category.id != 12){
            for(let i in Settings.payment_option){
              if(Settings.payment_option[i].value != 'CH'){
                payment_type.push({
                  key: Settings.payment_option[i].key,
                  value: Settings.payment_option[i].value,
                  text: Settings.payment_option[i].text,
                })
              }
            }
            this.setState({payment_type})
          }
        }
        this.setState({ledger_category_open: false,ledger: ledger})
      })
    }

    handleSubmitledger(e) {
      e.preventDefault()

      const {branch} = this.props.auth

      let msg_error = {}
      let data = this.state.ledger
      
      data.card_code = this.state.card_code
      data.ledger_category = 13 // รูดเงินสด
      data.payment = 'CD'

        if (data.staff == null) {
  
          msg_error['staff'] = ['*กรุณาเลือก พนักงาน']
        }

        if(data.description==null || data.description==''){
          msg_error['description'] = ['*กรุณาเลือกระบุหมายเหตุ']
        }

        // if((data.card==null || data.card=='')){
        //   msg_error['card'] = ['*กรุณาระบุยอดเงิน']
        // }
        // if(data.card.toString().split('.')[0].length > 15){
        //   msg_error['card'] = ['*ไม่สามารถกรอกจำนวนเงินเกิน 15 หลักได้']
        // }
        if((data.card_code==null || !data.card_code.toString().trim().length|| data.card_code=='' || !(/(^[0-9]+(-[0-9]+)+$)|(^\d*$)/.test(data.card_code)))){
          msg_error['card_code'] = ['*กรุณาระบุรหัสบัตรเครดิต']
        }

        if(data.card_code.length != 16){
          msg_error['card_code'] = ['*กรอกรหัสบัตรผิดรูปแบบ']
        }
        
        if((data.card_bank_card==null || data.card_bank_card=='')){
          msg_error['card_bank_card'] = ['*กรุณาระบุชนิดบัตรเครดิต']
        }  

        if(data.card_fee>100){
          msg_error['card_fee'] = ['*ไม่สามารถกรอกจำนวนเกิน 100%']
        }

        if((data.card_swipe_balance==null || data.card_swipe_balance=='')){
          msg_error['card_swipe_balance'] = ['*กรุณาระบุยอดเงิน']
        }

        if (Object.keys(msg_error).length > 0) {
          this.setState({
            msg_error: msg_error
          })
          return;
        }

        data.total = data.card_swipe_balance
        

        if(this.state.ledger.staff==null)
          this.state.ledger.staff = ''
        if(this.state.ledger.card_bank_card==null)
          this.state.ledger.card_bank_card = ''
      
      if (this.props.action == 'EDIT') {
        let url = Settings.baseUrl + "/ledger/" + this.state.ledger.id + "/"
        let data = this.state.ledger
  
        data.branch = data.branch.id;
        this.setState({
          loadingSubmitledger: true
        })
        Utility.patch(url, data, (s, d) => {
          if(s){
            this.props.onSave()
          }else{
            alert('ผิดพลาด กรุณาลองอีกครั้ง')
          }
        });
      } else {
        
        let url = Settings.baseUrl + "/ledger/";
        let data = Utility.cloneObjectJson(this.state.ledger)
        data.ledger_date = Utility.formatDate2(data.ledger_date)
        data.branch = branch.id
        data.service_charge = Utility.parseFloat(data.card_swipe_balance * (data.card_fee/100)).toFixed(2)
        data.service_charge_percent = data.card_fee
        data.card = data.card_swipe_balance
        this.setState({
          loadingSubmitledger: true
        })
        Utility.post(url, data, (s, d) => {
          if(s){
            this.ledger_ex(data)
            // this.props.onSave()
          }else{
            msg_error = d
            if (d.description !== undefined){
              if(d.description[0] === "Ensure this field has no more than 255 characters."){
              msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
            }}
            this.setState({
              loadingSubmitledger: false,
              msg_error: msg_error
            })
          }
        });
      }
    }

    ledger_ex(data){
      let msg_error = {}
      data.card = ""
      data.payment = "CS"
      data.kind = "EX"
      data.ledger_category = 24
      data.card_bank_card = ""
      data.card_swipe_balance = ""
      data.card_fee = ""
      data.card_code = ""
      data.service_charge = ""
      data.service_charge_percent = ""
      data.cash = this.state.cash_ex
      data.total = this.state.cash_ex
      let url = Settings.baseUrl + "/ledger/";
      Utility.post(url, data, (s, d) => {
        if(s){
          this.props.onSave()
        }else{
          msg_error = d
          if (d.description !== undefined){
            if(d.description[0] === "Ensure this field has no more than 255 characters."){
            msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
          }}
          this.setState({
            loadingSubmitledger: false,
            msg_error: msg_error
          })
        }
      });
    }

    render(){
            return(<div>
              
                <Modal size='mini' open={true} /*dimmer='blurring'*/>
                <Button id='closeIn-Out' circular icon='close' basic floated='right' name='' onClick={this.props.onClose}/>
            <Modal.Header>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    รูดเงินสด
                </Grid.Column>
                  <Grid.Column>
                    
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Header>
            <Modal.Content className="scrolling">
              <Form size='small'>       
                 <InputSearchCustomer label='ชื่อลูกค้า' placeholder='ชื่อลูกค้า' onChange={this.handlerModalInputChange} name="customer" value={this.state.ledger.customer} />
                <Form.Field width={16} error={this.state.msg_error.staff != null}>
                  <label>*พนักงาน <MsgInput text={this.state.msg_error.staff} /></label>
                  <Dropdown id='staff' label='พนักงาน' placeholder='พนักงาน' search selection name="staff" value={this.state.ledger.staff}
                    options={this.state.staffs}
                    onChange={this.handlerModalInputChange}
                    loading={this.state.st} />
                </Form.Field>

                <Form.Field width={16} error={this.state.msg_error.card_swipe_balance != null}>
                  <label>*ยอดเงินที่ต้องการรูด <MsgInput text={this.state.msg_error.card_swipe_balance} /></label>
                  <Input 
                  maxLength={15}
                  id='inputcreditIn-Out' placeholder='ยอดเงินที่ต้องการรูด' search selection name="card_swipe_balance" 
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault()
                      if(this.state.ledger.payment == 'CD')
                        this.textInput.focus()
                      else 
                      this.textInputcash.focus()
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                        if (this.state.focus_card) {
                            this.setState({
                                focus_card: false,
                            });
                        }
                    }, 0);
                }}
                onFocus={(e) => {
                  e.target.select()
                  if (!this.state.focus_card) {
                      this.setState({
                          focus_card: true,
                      });
                  }
              }}
              value={this.state.ledger.card_swipe_balance==''||this.state.ledger.card_swipe_balance==undefined?'':this.state.focus_card ?Utility.removeCommas(this.state.ledger.card_swipe_balance) : Utility.priceFormat(this.state.ledger.card_swipe_balance)}
                  min={0}
                    onChange={(e,d)=>{
                      d.value = d.value.toString().replace(/,/g,'')
                      if (isNaN(Number(d.value))) {
                      }else{
                        if (d.value.split('.').length != 1)
                        if (d.value.split('.')[1].length >2){
                            return;
                        }
                        this.handlerModalInputChange(e,d)
                      }
                    }} />
                </Form.Field>

                <Form.Field error={this.state.msg_error.card_code != null}><label>*รหัสบัตรเครดิต <MsgInput text={this.state.msg_error.card_code} /></label>
                  <NumberFormat value={this.state.card_code} format="#### #### #### ####" 
                    onValueChange={(v) => {
                        this.setState({ card_code: v.value });
                    }} 
                    placeholder='รหัสบัตรเครดิต'
                    />
                  </Form.Field> 

                <Form.Field error={this.state.msg_error.card_bank_card != null}><label>*ชนิดบัตรเครดิต <MsgInput text={this.state.msg_error.card_bank_card} /></label>
                <Form.Dropdown id='Typecredit' placeholder='ชนิดบัตรเครดิต' search selection name="card_bank_card" value={this.state.ledger.card_bank_card}
                  options={this.state.bank_cards}
                  onChange={this.handlerModalInputChange}/> </Form.Field> 
                
                <Form.Field error={this.state.msg_error.card_fee != null}><label>ค่าบริการ {this.state.ledger.card_fee==''||this.state.ledger.card_fee==undefined?'':this.state.ledger.card_fee + '%'}<MsgInput text={this.state.msg_error.card_fee} /></label>
                <Form.Input id='percenVatcredit' 
                // type='number' 
                disabled
                placeholder='*%ค่าบริการ' search selection name="card_fee" 
                onBlur={() => {
                  setTimeout(() => {
                      if (this.state.focus_card_fee) {
                          this.setState({
                              focus_card_fee: false,
                          });
                      }
                  }, 0);
              }}
              onFocus={(e) => {
                e.target.select()
                if (!this.state.focus_card_fee) {
                    this.setState({
                        focus_card_fee: true,
                    });
                }
            }}
            value={(this.state.ledger.card_fee==''||this.state.ledger.card_fee==undefined) || (this.state.ledger.card_swipe_balance==''||this.state.ledger.card_swipe_balance==undefined)?'':Utility.parseFloat(this.state.ledger.card_swipe_balance * (this.state.ledger.card_fee/100)).toFixed(2)}
                onChange={this.handlerModalInputChange}/> </Form.Field> 

                <Form.Field width={16} error={this.state.msg_error.card != null}>
                  <label>จำนวนเงินสดที่ต้องจ่าย <MsgInput text={this.state.msg_error.card} /></label>
                  <Input id='inputcreditIn-Out' placeholder='จำนวนเงินชำระ' search selection name="card" 
                  disabled
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault()
                      if(this.state.ledger.payment == 'CD')
                        this.textInput.focus()
                      else 
                      this.textInputcash.focus()
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                        if (this.state.focus_card) {
                            this.setState({
                                focus_card: false,
                            });
                        }
                    }, 0);
                }}
                onFocus={(e) => {
                  e.target.select()
                  if (!this.state.focus_card) {
                      this.setState({
                          focus_card: true,
                      });
                  }
              }}
              value={this.state.ledger.card==''||this.state.ledger.card==undefined?'':this.state.focus_card ? this.state.ledger.card.toString().replace(/,/g,'') : this.state.ledger.card.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
            
                  min={0}
                    onChange={(e,d)=>{
                      d.value = d.value.toString().replace(/,/g,'')
                      if (isNaN(Number(d.value))) {
                      }else{
                        if (d.value.split('.').length != 1)
                        if (d.value.split('.')[1].length >2){
                            return;
                        }
                        this.handlerModalInputChange(e,d)
                      }
                    }} />
                </Form.Field>

                <Form.Field error={this.state.msg_error.description != null}>

                  <label>*หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea
                    maxLength={255}
                    id='ex' 
                    ref={(input) => { this.textInput = input; }}
                    placeholder='หมายเหตุ' 
                    search selection 
                    name="description" 
                    value={this.state.ledger.description}
                    onChange={this.handlerModalInputChange} />
                  </Form.Field> 
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button id='confirmIn-Out' size='small' primary loading={this.state.loadingSubmitledger} onClick={this.handleSubmitledger}>
                บันทึก
            </Button>

            </Modal.Actions>
          </Modal>
            </div>)
    }
}

const mapStateToProps = state =>{
  return ({
    auth: state.auth,
    branches: state.branches,
    ledger_category: state.ledger_category
  })
}

export default connect(
  mapStateToProps
)(LedgerForm)