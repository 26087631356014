import update from 'immutability-helper';
const default_item = {
  product: '',
  product_error: '',
  amount: '',
  amount_error: '',
  weight_real: '',
  weight: '',
  weight_real_error: '',
  cost: '',
  cost_error: '',
  fee: '',
  fee_error: '',
}
const default_state = {
  is_ready: false,
  is_open: false,
  invoice: 0,
  items: [default_item]
}


export const invoice_item_open = (invoice) => ({
  type: "INVOICE_ITEM_OPEN",
  data: {
    invoice
  }
});

export const invoice_item_close = () => ({
  type: "INVOICE_ITEM_CLOSE"
});
export const invoice_item_add = (item = default_item) => ({
  type: "INVOICE_ITEM_ADD",
  data: { item: item ? item : default_item }
});
export const invoice_item_remove = (index) => ({
  type: "INVOICE_ITEM_REMOVE",
  data: { index }
});

export const invoice_item_set_value = (index, key, value) => ({
  type: "INVOICE_ITEM_SET_VALUE",
  data: { index, key, value }
});
const invoice_item_modal = (state = default_state, action) => {
  switch (action.type) {
    case 'INVOICE_ITEM_OPEN':
      return update(state, { is_open: { $set: true }, invoice: { $set: action.data.invoice }, items: { $set: [default_item] } });
    case 'INVOICE_ITEM_CLOSE':
      return update(default_state, { is_open: { $set: false }, cost: { $set: 0 }, items: { $set: [default_item] } });
    case 'INVOICE_ITEM_ADD':
      return update(state, { items: { $push: [action.data.item] } });
    case 'INVOICE_ITEM_REMOVE':
      return update(state, { items: { $splice: [[action.data.index, 1]] } });
    case 'INVOICE_ITEM_SET_VALUE':
      let item = state.items[action.data.index]
      item = update(item, { [action.data.key]: { $set: action.data.value } })
      return update(state, { items: { [action.data.index]: { $set: item } } });
    default:
      return state
  }
}

export default invoice_item_modal  