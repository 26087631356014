/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table,
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment()
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var ledger_category = Utility.getFetch(Settings.baseReportUrl + "/ledger_category/?is_enabled=1")
        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches, ledger_category]).then((values) => {
            this.branches = values[0];
            this.ledger_category = values[1]
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let qrstring = Utility.jsonToQueryString(search)
        this.setState({
            loading: true
        })
        let date_before = new Date(search.start_date)
        date_before.setDate(date_before.getDate() - 1)
        date_before = Utility.formatDate2(date_before)
        const daily_ledger_balance = Utility.getAsync(Settings.baseReportUrl + "/daily_ledger_balance/?date=" + date_before + "&branch=" + this.state.search.branch)
        var ledger = Utility.getFetch(Settings.baseReportUrl + '/ledger/?is_enabled=1&' + qrstring);
        Promise.all([ledger,daily_ledger_balance]).then((values) => {
            this.setState({
                ledger_balance_cash:values[1].data.length == 0 ? Utility.parseFloat(0) : Utility.parseFloat(values[1].data[0].cash),
                loading: false,
                items: values[0]
            });
        });
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }


    render() {
        let daily_ledger_cash = this.state.ledger_balance_cash
        let title = 'รายงานบิลรับเงิน-จ่ายเงินแบ่งตามประเภท';
        let filename = 'ledger-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let items = this.state.items
        let min = null
        let max = null
        let rev_cash = 0
        let rev_card = 0
        let rev_transfer = 0
        let rev_online = 0
        let exc_cash = 0
        let exc_card = 0
        let exc_check = 0
        for (let i in items) {
            if (min == null)
                min = items[i]
            if (max == null)
                max = items[i]
            if (min.id > items[i].id)
                min = items[i]
            if (max.id < items[i].id)
                max = items[i]

            if (items[i].kind == 'IN') {
                rev_cash += parseFloat(items[i].cash)
                rev_card += parseFloat(items[i].card) + parseFloat(items[i].card_service)
                rev_online += (items[i].payment === 'TF' ? parseFloat(items[i].transfer) : 0)
                rev_transfer += (items[i].payment !== 'TF' ? parseFloat(items[i].transfer) : 0)
            } else {
                exc_cash += parseFloat(items[i].cash)
                exc_check += parseFloat(items[i].check)
            }
        }




        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานบิลรับเงิน-จ่ายเงินแบ่งตามประเภท</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='6'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ : {Utility.formatDate(this.state.search.end_date)} </center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='6' style={textCenter}><b id='no_bill'>เลขที่</b> {min == null ? '' : min.number} - {max == null ? '' : max.number}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell style={textCenter}>ยอดเงินสดรับ</Table.Cell>
                                            <Table.Cell id='rev_cash' style={textCenter}>{Utility.priceFormat(rev_cash+daily_ledger_cash)}</Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}>ยอดเครดิตรับ</Table.Cell>
                                            <Table.Cell id='rev_card' style={textCenter}>{Utility.priceFormat(rev_card)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell style={textCenter}>ยอดเงินโอนรับ</Table.Cell>
                                            <Table.Cell id='rev_transfer' style={textCenter}>{Utility.priceFormat(rev_transfer)}</Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}>ยอดต่อดอกออนไลน์</Table.Cell>
                                            <Table.Cell id='rev_online' style={textCenter}>{Utility.priceFormat(rev_online)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell style={textCenter} colSpan='4'></Table.Cell>
                                            <Table.Cell style={textCenter}>ยอดรับรวม</Table.Cell>
                                            <Table.Cell id='rev_total' style={textCenter}>{Utility.priceFormat(rev_card + rev_cash+daily_ledger_cash + rev_transfer + rev_online)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell style={textCenter}>ยอดเงินสดจ่าย</Table.Cell>
                                            <Table.Cell id='exc_cash' style={textCenter}>{Utility.priceFormat(exc_cash)}</Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell id='exc_cash' style={textCenter}>
                                                {/* {Utility.priceFormat(exc_check)} */}
                                                </Table.Cell>
                                            <Table.Cell style={textCenter}>ยอดจ่ายรวม</Table.Cell>
                                            <Table.Cell id='exc_total' style={textCenter}>{Utility.priceFormat(exc_cash)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell style={textCenter}>ยอดเงินสดคงเหลือ</Table.Cell>
                                            <Table.Cell id='balance_cash' style={textCenter}>{Utility.priceFormat((rev_cash+daily_ledger_cash) - exc_cash)}</Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}>ยอดคงเหลือทั้งหมด</Table.Cell>
                                            <Table.Cell id='balance_total' style={textCenter}>{Utility.priceFormat((rev_card + rev_cash+daily_ledger_cash + rev_transfer + rev_online) - (exc_cash + exc_card))}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches,
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)