/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Popup,
  Modal,
  Input,
  Grid,
  Radio,
  Pagination,
  Icon,
} from "semantic-ui-react";
import moment from "moment";
import { Table, Column, Cell } from "fixed-data-table";
import Settings from "../../Settings";
import Utility from "../../Utility";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import { lease_modal_edit, lease_modal_add_open, lease_modal_close } from "../../actions";
import MsgInput from "../Error/MsgInput";
import DatePicker from "react-datepicker";
import InputSearchCustomer from '../Customer/InputSearchCustomer';
import OptionItemsCellRewardPickup from "./OptionItemsCellRewardPickup";

function collect(props) {
  return { positon: props.positon };
}
class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = this.props.width - 10 + "px";
    var ellipsisStyle = {
      whiteSpace: "nowrap",
      width: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    return (
      <ContextMenuTrigger
        id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}
      >
        <Cell {...this.props} onClick={() => { }}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            trigger={
              <div
                style={ellipsisStyle}
                className={
                  (this.props.textAlign == null ? "" : this.props.textAlign) +
                  " text-cell"
                }
              >
                {data[rowIndex][field]}
              </div>
            }
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

const status = [
    {
        value: 'none',
        text: 'ทั้งหมด',
    },
    {
        value: 'not_receive',
        text: 'ยังไม่ได้รับสินค้า'
    },
    {
        value: 'receive',
        text: 'รับสินค้าแล้ว'
    }
]

class RewardPickup extends Component {
  constructor(props) {
    super(props);
    this.data_search = null;
    this.state = {
      items: [],
      total_item: 0,
      open: false,
      in_date_start: '',
      in_date_end: moment(),
      totalPages: 1,
      page: 1,
      status:'none',
      ref_ci_id:'',
      open_modal_edit_status: false,
      select_id: '',
      button_class: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loaddata2 = this.loaddata2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onClickEditStatusReward = this.onClickEditStatusReward.bind(this);
  }

  loaddata2(page) {
    this.setState({ loader_active: true });
    var reward_pickup = Utility.getFetch(Settings.baseUrl + "/reward_receive_branch/?"+this.state.qr+"&page="+page)
    Promise.all([reward_pickup]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
    });
  }

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]["created_at"] =
        item[i].created_at == null ? "" : Utility.formatDate(item[i].created_at);
      item[i]["updated_at"] =
        item[i].updated_at == null ? "" : Utility.formatDate(item[i].updated_at);
    }
    return item;
  }

  componentDidMount() {
    this.handleSubmit();
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById("client_width");
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleInputChange(e, { name, value, q }) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    this.setState({ loader_active: true });
    const { branch } = this.props.auth
    var s = "";
    if (this.state.in_date_end != null && this.state.in_date_end != "") {
      s +=
        (s == "" ? "" : "&") + "end_date=" + Utility.formatDate2(this.state.in_date_end);
    }
    if (this.state.in_date_start != null && this.state.in_date_start != "") {
      s +=
        (s == "" ? "" : "&") +
        "start_date=" +
        Utility.formatDate2(this.state.in_date_start);
    }
    if (this.state.customer_id != null && this.state.customer_id != "") {
        s += (s == "" ? "" : "&") + "customer_id=" + this.state.customer_id;
    }
    if (this.state.status != "none") {
        s += (s == "" ? "" : "&") + "status=" + this.state.status;
    }
    if (this.state.ref_ci_id != null && this.state.ref_ci_id != "") {
        s += (s == "" ? "" : "&") + "ref_ci_id=" + encodeURIComponent(this.state.ref_ci_id);
    }
    if (branch) {
        s += (s == "" ? "" : "&") + "branch=" + branch.id;
    }
    this.setState({ qr: s });
    var reward_pickup = Utility.getFetch(Settings.baseUrl + "/reward_receive_branch/?"+s+"&page=1")
    Promise.all([reward_pickup]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
    });
    
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage });
    this.loaddata2(activePage);
  }

  clearFormSearch() {
    this.setState({
      in_date_start: '',
      in_date_end: moment(),
      customer_id: "",
      status: "none",
      ref_ci_id: "",
    });
  }

  searchFormToday() {
    this.setState({
      in_date_start: moment(),
      in_date_end: moment(),
      customer_id: "",
      status: "none",
      ref_ci_id: "",
    });
  }

  handleClick(e, data) {
    this.setState({ open_modal_edit_status: true, select_id: this.state.items[data.positon].id, button_class: '' });
  }
  
  onClickEditStatusReward() {
    this.setState({ button_class: 'loading' })
    var update_reward_pickup = Utility.patchAsync(Settings.baseUrl + "/reward_receive_branch/"+this.state.select_id+"/", "")
    Promise.all([update_reward_pickup]).then((values) => {
      this.setState({ button_class: '', open_modal_edit_status: false })
      this.loaddata2(this.state.page);
    });
  }

  render() {
    const items = this.state.items;
    const { branch, role } = this.props.auth;
    const { in_date_start, in_date_end } = this.state;
    return (
      <div>
        <Form>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated="left" as="h3">
                นัดรับสินค้าที่สาขา
              </Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <Form
          className="attached fluid"
          size="small"
          onSubmit={this.handleSubmit}
          autocomplete="off"
        >
          <Form.Group widths='equal'>
            <Form.Select
                fluid
                label='สถานะ'
                options={status}
                value={this.state.status}
                onChange={(e, data) => {
                    this.setState({ status: data.value });
                }}
            />
            <Form.Field>
              <label>เลขที่รายการ</label>
              <Input
                maxLength={10}
                fluid
                onChange={this.handleInputChange}
                name="ref_ci_id"
                value={this.state.ref_ci_id}
                placeholder="เลขที่รายการ"
              />
            </Form.Field>
            <InputSearchCustomer
              label='ชื่อลูกค้า'
              placeholder='พิมพ์ ชื่อ หรือ รหัสลูกค้า'
              onChange={this.handleInputChange}
              name="customer_id"
              value={this.state.customer_id}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>จากวันที่</label>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                autocomplete="off"
                selected={this.state.in_date_start}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.in_date_end < date) {
                    this.setState({
                      in_date_end: date,
                    })
                  }
                  this.setState({
                    in_date_start: date,
                  })
                }}
                name="in_date_start"
              />
            </Form.Field>
            <Form.Field>
              <label>ถึงวันที่</label>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                autocomplete="off"
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.in_date_start > date) {
                    this.setState({
                      in_date_start: date,
                    })
                  }
                  this.setState({
                    in_date_end: date,
                  })
                }}
                name="in_date_end"
                selected={this.state.in_date_end}
              />
            </Form.Field>
            <Form.Field>
              <label><br /></label>
              <Button
                id="search"
                floated="left"
                type="button"
                size="small"
                onClick={() => {
                  this.handleSubmit();
                  this.setState({ page: 1 });
                }}
              >
                {" "}
                <Icon name="search" /> ค้นหา
              </Button>
              <Button
                id="search"
                floated="left"
                type="button"
                size="small"
                onClick={() => {
                  this.searchFormToday();
                  setTimeout(() => {
                    this.setState({ page: 1 });
                    this.handleSubmit();
                  }, 500);
                }}
              >
                {" "}
                <Icon name="search" /> วันนี้
              </Button>
              <Button id='clear_search' floated='left' type='button' size='small' onClick={() => {
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="relative">
          <div id="client_width">
            <div className="relative">
              <Dimmer active={this.state.loader_active} inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
              <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={50}
                width={this.state.table_width}
                height={420}
              >
                <Column
                  header={<Cell></Cell>}
                  cell={
                    <OptionItemsCellRewardPickup
                      action={"edit"}
                      data={items}
                      onClickMenu={this.handleClick}
                      edit_only={true}
                    />
                  }
                  width={70}
                />
                <Column
                  header={<Cell className="text-center">สาขา</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="branch_name"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">รหัสลูกค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="customer_code"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">ชื่อลูกค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="customer_name"
                      textAlign="text-center"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell className="text-center">เบอร์มือถือ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="mobile"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">สถานะ</Cell>}
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="status_display"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">เลขที่รายการ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="ref_ci_id"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">วันที่สร้างรายการ</Cell>}
                  cell={
                    <ItemsCell
                      position="top right"
                      width={100}
                      data={items}
                      field="created_at"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">ชื่อพนักงานที่อัพเดทรายการ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="username"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
                <Column
                  header={<Cell className="text-center">วันที่อัพเดทรายการ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={100}
                      data={items}
                      field="updated_at"
                      textAlign="text-center"
                    />
                  }
                  width={100}
                />
              </Table>
            </div>
          </div>
        </div>
        <br />
        <Form size="small">
          <Form.Group>
            <Form.Input
              id="amounttrans"
              label="จำนวน"
              placeholder=""
              className="text-right"
              width={2}
              value={Utility.numberFormat(this.state.counter)}
            />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && (
                <Pagination
                  floated="right"
                  size="tiny"
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />
              )}
            </Form.Field>
          </Form.Group>
        </Form>
        <Modal open={this.state.open_modal_edit_status} size="mini">
          <Button 
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={(e) => {
              this.setState({ open_modal_edit_status: false });
            }} 
          />
          <Modal.Header>ปรับสถานะสินค้า</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ wordBreak: 'break-all' }}>
              <p>ยืนยันการจัดส่งสินค้าให้กับลูกค้าแล้ว</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id="AddConfirm"
              size='small'
              primary
              onClick={this.onClickEditStatusReward}
              className={this.state.button_class}
            >
              บันทึก
            </Button>
            <Button
              id="AddCancel"
              size='small'
              onClick={(e) => {
                this.setState({ open_modal_edit_status: false });
              }}
            >
              ยกเลิก
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    branches: state.branches,
  };
};

export default connect(mapStateToProps)(RewardPickup);
