/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table,
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                start_date: moment(),
                end_date: moment()
            },
            items: [],
            sum_data: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerSubmitSum = this.handlerSubmitSum.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var ledger_category = Utility.getFetch(Settings.baseUrl + "/ledger_category/?is_enabled=1")
        var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
        Promise.all([branches, ledger_category]).then((values) => {
            this.branches = values[0];
            this.ledger_category = values[1]
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if (search.branch && search.branch.length == 0) {
            delete search["branch"]
        }
        let qrstring = Utility.jsonToQueryString(search)

        this.setState({
            loading: true
        })

        var rating = Utility.getFetch(Settings.baseUrl + '/report/branch_grading/?' + qrstring);
        Promise.all([rating]).then((values) => {
            this.setState({
                loading: false,
                items: this.setFieldValue(values[0])
            });
        });
    }

    handlerSubmitSum(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        var d = new Date();
        search.end_date = Utility.formatDate2(d)
        d.setMonth(d.getMonth() - 3);
        search.start_date = Utility.formatDate2(d)
        delete search["branch"]
        let qrstring = Utility.jsonToQueryString(search)

        this.setState({
            loading: true
        })

        var rating = Utility.getFetch(Settings.baseUrl + '/report/branch_grading/?' + qrstring);
        Promise.all([rating]).then((values) => {
            this.setState({
                loading: false,
                sum_data: this.setSumFieldValue(values[0])
            });
        });
    }

    setFieldValue(_items) {
        let items = []
        let logs = _items.logs
        let customers = _items.customer
        for (let i in logs) {
            let item = logs[i]
            let customer = customers.find((i) => i.user == item.user)
            item.customer_name = customer.customer_name
            item.customer_code = customer.customer_code
            items.push(item)
        }

        return items
    }

    setSumFieldValue(_items) {
        let logs = _items.logs

        let sum_data = []
        for (let i in this.state.branches) {
            let b = this.state.branches[i]
            let data_branch = logs.filter((i) => i.branch == b.value)
            let sum_point = 0
            let count = 0
            for (let j in data_branch) {
                sum_point += data_branch[j].rating
                count += 1
            }
            sum_data.push({
                'branch_name': b.text,
                'avg': count == 0 ? 0 : (sum_point / count).toFixed(2),
            })
        }

        return sum_data
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    getSumItem(items, key) {
        let s = 0
        for (let i in items) {
            s += parseFloat(items[i][key])
        }
        return s
    }
    render() {
        let title = this.state.sum ? 'สรุปรายงานการให้คะแนนสาขา' : 'รายงานการให้คะแนนสาขา';
        let filename = this.state.sum ? 'sum_branch_grading-' + Utility.formatDate3(moment()) : 'branch_grading-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }

        const colorRed = {
            'color': 'red',
        }

        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const descriptionWidth = {
            'width': '300px',
            'word-break': 'break-word'
        }
        let items = this.state.items

        let data = []
        for (let i in this.state.branches) {
            let b = this.state.branches[i]
            if (this.state.search.branch) {
                if (this.state.search.branch.length != 0)
                    if (!this.state.search.branch.includes(b.value)) {
                        continue
                    }
            }
            data.push({
                'branch_name': b.text,
                'items': items.filter((i) => i.branch == b.value)
            })
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานการให้คะแนนสาขา</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา<small style={{ 'color': 'red' }}> *เลือกได้มากกว่า 1</small></label>
                                <Dropdown multiple search selection
                                    placeholder='- ทั้งหมด -'
                                    options={
                                        this.state.branches
                                    }
                                    name="branch"
                                    value={this.state.search.branch} onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    sum: true
                                })
                                this.handlerSubmitSum(e)
                            }}>พิมพ์สรุป</Button>
                        </Form>
                    </Segment>
                </div>{this.state.open &&
                    <Modal open={true} onClose={() => { this.setState({ open: false }) }} size='fullscreen' /*dimmer='blurring'*/>
                        <Modal.Header>Preview</Modal.Header>
                        <Modal.Content className='scrolling'>
                            {this.state.loading && <Dimmer active={this.state.loading} inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>}
                            <div id='view-print'>
                                <div id='paperA4-portrait'>
                                    <Table basic id='table-to-xls' style={divStyle}>
                                        <Table.Header>
                                            <Table.Row >
                                                <Table.HeaderCell colSpan='5'><center>{title}</center></Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.HeaderCell colSpan='5'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ : {Utility.formatDate(this.state.search.end_date)} </center></Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>รหัสลูกค้า</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>ชื่อลูกค้า</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>คะแนน</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>ข้อเสนอแนะ</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {data.map((row, i) => {
                                            return (
                                                <Table.Body>
                                                    <Table.Row key={i}>
                                                        <Table.Cell style={textLeft} colSpan='5'><b>สาขา :{row.branch_name}</b></Table.Cell>
                                                    </Table.Row>
                                                    {row.items.map((row2, j) => {
                                                        return (<Table.Row key={j}>
                                                            <Table.Cell style={textCenter}>{Utility.formatDate(row2.record_date)}</Table.Cell>
                                                            <Table.Cell style={textCenter}>{row2.customer_code}</Table.Cell>
                                                            <Table.Cell style={textLeft}>{row2.customer_name}</Table.Cell>
                                                            <Table.Cell style={textCenter}>{row2.rating}/5</Table.Cell>
                                                            <Table.Cell style={textLeft, descriptionWidth}>{row2.suggestion}</Table.Cell>
                                                        </Table.Row>)
                                                    })
                                                    }
                                                    <Table.Row key={i}>
                                                        <Table.Cell style={textLeft} colSpan='5'></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>)
                                        })
                                        }
                                    </Table>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="ui primary button small"
                                table="table-to-xls"
                                filename={filename}
                                sheet={title}
                                buttonText="Download as XLS" >
                            </ReactHTMLTableToExcel>

                            <Button primary icon='print' size='small' onClick={() => {
                                var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                                mywindow.document.write('<html><head><title>' + title + '</title>');
                                mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                                mywindow.document.write(document.getElementById('view-print').innerHTML);
                                mywindow.document.write('</body></html>');
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10*/
                                mywindow.print();
                                setTimeout(()=>{
                                    mywindow.close();
                                },2000)
                            }} labelPosition='right' content='Print' />

                            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                        </Modal.Actions>
                    </Modal>
                }
                {this.state.sum &&
                    <Modal open={true} onClose={() => { this.setState({ sum: false }) }} size='fullscreen' /*dimmer='blurring'*/>
                        <Modal.Header>Preview</Modal.Header>
                        <Modal.Content className='scrolling'>
                            {this.state.loading && <Dimmer active={this.state.loading} inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>}
                            <div id='view-print'>
                                <div id='paperA4-portrait'>
                                    <Table basic id='table-to-xls' style={divStyle}>
                                        <Table.Header>
                                            <Table.Row >
                                                <Table.HeaderCell colSpan='2'><center>{title}</center></Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.HeaderCell colSpan='2'><center id='date'>ณ วันที่ {Utility.formatDate(moment())} </center></Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                                <Table.HeaderCell style={textCenter}>คะแนนเฉลี่ย</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.sum_data.map((row, j) => {
                                                return (<Table.Row key={j}>
                                                    <Table.Cell style={textCenter}>{row.branch_name}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{row.avg}/5</Table.Cell>
                                                </Table.Row>)
                                            })
                                            }
                                            <Table.Row >
                                                <Table.Cell style={textLeft} colSpan='2'></Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={textLeft, colorRed} colSpan='2'>*สรุปการให้คะแนนสาขาย้อนหลัง 3 เดือน</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="ui primary button small"
                                table="table-to-xls"
                                filename={filename}
                                sheet={title}
                                buttonText="Download as XLS" >
                            </ReactHTMLTableToExcel>

                            <Button primary icon='print' size='small' onClick={() => {
                                var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                                mywindow.document.write('<html><head><title>' + title + '</title>');
                                mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                                mywindow.document.write(document.getElementById('view-print').innerHTML);
                                mywindow.document.write('</body></html>');
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10*/
                                mywindow.print();
                                setTimeout(()=>{
                                    mywindow.close();
                                },2000)
                            }} labelPosition='right' content='Print' />

                            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ sum: false }) }}>ปิด</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)