/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table,Input
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
// import Settings from '../../../Settings'

class CheckVatPrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            kinds: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.handlerSubmitSummary = this.handlerSubmitSummary.bind(this)
        this.setFieldValue2 = this.setFieldValue2.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }
            
            

            
            this.setState({
                loader_active: false,
                branches: branches,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/bill_items_vat/?kind=SE,XC&status_stock=Y&" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.bill_item, d.bill, d.product, d.customer, d.gold_price, d.branch, d.category),
                    kinds: d.kind,
                })
            }
        })
    }

    handlerSubmitSummary(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/bill_items_vat/?kind=SE,XC&status_stock=Y&sum=true&" + fromData

        this.setState({
            open_summary: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue2(d.bill_item)
                })
            }
        })
    }

    setFieldValue2(_items){
        let amount_sell = 0
        let weight_sell = 0
        let amount_exchange = 0
        let weight_exchange = 0
        let amount_sell_bar = 0
        let weight_sell_bar = 0
        let total_amount = 0
        let total_weight = 0
        for (let i in _items) {
            let item = _items[i]
            if(item.bill_kind == "SE" && item.category_code != "965"){
                amount_sell += item.amount
                weight_sell += parseFloat(item.weight)
                total_amount += item.amount
                total_weight += parseFloat(item.weight)
            } else if(item.bill_kind == "SE" && item.category_code == "965"){
                amount_sell_bar += item.amount
                weight_sell_bar += parseFloat(item.weight)
                total_amount += item.amount
                total_weight += parseFloat(item.weight)
            } else if(item.bill_kind == "XC" && item.kind == "SE"){
                amount_exchange += item.amount
                weight_exchange += parseFloat(item.weight)
                total_amount += item.amount
                total_weight += parseFloat(item.weight)
            }
        }
        this.setState({amount_sell,weight_sell,amount_sell_bar,weight_sell_bar,amount_exchange,weight_exchange,total_amount,total_weight})
    }

    setFieldValue(_items, _bills, products, customers, gold_prices, branchs, categories) {
        let items = []
        let bills = []
        for(let i in _bills){
            let bill = _bills[i]
            bill.customer = customers.find((c) => c.id == bill.customer)
            bill.gold_price = gold_prices.find((g) => g.id == bill.gold_price)
            bill.branch = branchs.find((b) => b.id == bill.branch)
            bills.push(bill)
        }
        for (let i in _items) {
            let item = _items[i]
            item.product = products.find((p) => p.id == item.product)
            item.category = categories.find((c) => c.id == item.category)
            item.bill = bills.find((b) => b.id == item.bill)
            items.push(item)
        }
        return items
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
        if (v.name == 'categories') {
            this.resetCategory(v.value);
        }
    }

    resetCategory(category_id) {
        let type = [];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id || this.product_types[i].category_id == 0)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_type: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_type: type });
        }
    }

    getMonth(m){
        if(m == 1){
            return 'มกราคม'
        } else if(m == 2){
            return 'กุมภาพันธ์'
        } else if(m == 3){
            return 'มีนาคม'
        } else if(m == 4){
            return 'เมษายน'
        } else if(m == 5){
            return 'พฤษภาคม'
        } else if(m == 6){
            return 'มิถุนายน'
        } else if(m == 7){
            return 'กรกฏาคม'
        } else if(m == 8){
            return 'สิงหาคม'
        } else if(m == 9){
            return 'กันยายน'
        } else if(m == 10){
            return 'ตุลาคม'
        } else if(m == 11){
            return 'พฤศจิกายน'
        } else if(m == 12){
            return 'ธันวาคม'
        }
    }

    getMonthDisplay(date1,date2){
        let m1 = date1.month() +1
        let m2 = date2.month() +1
        let m_b = this.getMonth(m1)
        let m_a = this.getMonth(m2)

        if(m1 == m2) {
            let year = date1.year() +543
            return m_b +"  " +year.toString() 
        } else {
            let year1 = date1.year() +543
            let year2 = date2.year() +543
            return m_b +"  " +year1.toString() + "  -  " + m_a +"  " +year2.toString()
        }
        
    }

    changeYear(date){
        date = date.split("/")
        let year = (Number(date[2])+543).toString()
        return date[0]+"/"+date[1]+"/"+year
    }

    render() {

        let title = 'รายงานตรวจสอบยอดภาษี';
        let title2 = 'สรุปภาษีขาย ';
        let filename = 'vat';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        const staffWidth = {
            'width': '200px',
            'word-break': 'break-word'
        }

        const billWidth = {
            'width': '120px',
            'word-break': 'break-word'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        let bill_number = {}
        for(let i in this.state.items){
            let item = this.state.items[i]
            if (bill_number[item.bill.bill_number] == null){
                    bill_number[item.bill.bill_number] = {
                        items: [],
                        title: item.bill.bill_number,
                        record_date: item.bill.record_date,
                        kind:item.bill.kind,
                        kind_display:item.bill.kind == "SE" ?  "ขายทอง" : "เปลี่ยนทอง",
                        total:item.bill.total,
                        date:item.bill.bill_date,
                        customer_name:item.bill.customer.name,
                        customer_citizen_id:item.bill.customer.citizen_id,
                        branch:item.bill.branch.name,
                        gold_price:item.bill.gold_price,
                        total:item.bill.kind == "SE" ? item.bill.total : item.bill.sell,
                        category:item.category.code,
                        is_free: item.bill.kind == "XC"? Number(item.bill.total) == 0? true : false : false,
                        exchange:parseFloat(item.bill.exchange),
                        exchange_diff:parseFloat(item.bill.exchange_diff),
                    }
            }
            bill_number[item.bill.bill_number].items.push(item)
        }
      
        let bill = []
        for(let i in bill_number){
            let product_type = ""
            let is_ta = true
            let discount_product_value = 0
            let gold_ornaments_buy = bill_number[i].gold_price.gold_ornaments_buy //ราคาซื้อคืนทองรูปพรรณ
            
            let gold_bar_sell = bill_number[i].gold_price.gold_bar_sell
            let gold_price=0
            let gold_diff = bill_number[i].exchange + bill_number[i].exchange_diff
            for(let j in bill_number[i].items){
                let item = bill_number[i].items[j]
                
                if(item.kind == "SE"){
                    let kind = item.product ? this.state.kinds.find((k) => k.id == item.product.kind) : undefined
                    // let b = (Number(weightReal) / Number(cat.weight)) * Number(cat.m_sell);
                    gold_price += parseFloat(item.sell) - parseFloat(item.fee)
                    // gold_price += parseFloat(gold_bar_sell)*((parseFloat(item.weight)/parseFloat(item.category.weight))*parseFloat(item.category.m_sell))
                    gold_diff += parseFloat(item.fee)
                    if(item.product.type_sale == 1 && item.category.code == "96"){ //ทองรูปพรรณ 96.5
                        discount_product_value += (parseFloat(gold_ornaments_buy)/15.16)*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)
                    }
                    else if(item.product.type_sale == 1 && item.category.code == "90"){ //ทองรูปพรรณ 90
                        discount_product_value += (parseFloat(gold_ornaments_buy)/15.16)*((parseFloat(item.weight)/(parseFloat(item.category.weight)))*15.16)*0.9
                    } 
                    else if(item.category.code == "965"){ //ทองแท่ง
                        discount_product_value += parseFloat(gold_price)
                        if(!(bill_number[i].kind_display.includes("แท่ง")))
                            bill_number[i].kind_display = bill_number[i].kind_display+"แท่ง"
                    }
                    else if(item.product.type_sale == 2) {
                        if(!(bill_number[i].kind_display.includes("ชิ้น")))
                            bill_number[i].kind_display = bill_number[i].kind_display+"ชิ้น"
                    }
                    if(kind.code != "TA"){
                        is_ta = false
                    }
                    if(!(product_type.includes(item.product.name))){
                        product_type += product_type == "" ? item.product.name : "," + item.product.name
                    }

                }
               
            }
            if(bill_number[i].kind == "XC" && gold_diff == 0){
                if(is_ta){
                    bill_number[i].kind_display = "เปลี่ยน(ตะขอ)ฟรี"
                    gold_price = 0
                    discount_product_value = 0
                }
                else{
                    bill_number[i].kind_display = "เปลี่ยน("+product_type+") DF"
                    gold_diff -= (bill_number[i].exchange + bill_number[i].exchange_diff) 
                }
            }
            let diff = gold_price - discount_product_value
            gold_diff = (gold_diff*(100/107)).toFixed(2)
            let vat_diff = parseFloat(diff) + parseFloat(gold_diff)
            bill_number[i].gold_price = gold_price
            bill_number[i].discount_product_value = discount_product_value
            bill_number[i].diff = diff
            bill_number[i].gold_diff = gold_diff
            bill_number[i].vat_diff = vat_diff
            bill_number[i].vat = (vat_diff * 0.07).toFixed(2)
            bill.push(bill_number[i]) 
        }
        let total_gold_price = 0
        let total_discount_product_value = 0
        let total_diff = 0
        let total_gold_diff = 0
        let total_vat_diff = 0
        let total_vat = 0

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานตรวจสอบยอดภาษี</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                            {/* <Button type='button' onClick={this.handlerSubmitSummary}>สรุป</Button> */}
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.Cell colSpan='13'><center><b>{title}</b></center></Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell colSpan='13'><center id='date'><b>ระหว่างวันที่ : {this.changeYear(Utility.formatDate(this.state.search.start_date))} ถึง {this.changeYear(Utility.formatDate(this.state.search.end_date))}</b></center></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={textCenter}><b>ลำดับ</b></Table.Cell>
                                            <Table.Cell style={textCenter}><b>วัน/เดือน/ปี</b></Table.Cell>
                                            <Table.Cell style={textCenter}><b>เลขที่บิล</b></Table.Cell>
                                            <Table.Cell style={textLeft}><b>รายการ</b></Table.Cell>
                                            <Table.Cell style={textLeft,staffWidth}><b>ชื่อผู้ซื้อสินค้า/ผู้รับบริการ</b></Table.Cell>
                                            <Table.Cell style={textCenter}><b>เลขประจำตัวผู้เสียภาษี</b></Table.Cell>
                                            <Table.Cell style={textLeft}><b>สถานประกอบการสำนักงานใหญ่สาขาที่</b></Table.Cell>
                                            
                                            <Table.Cell style={textRight}><b>ราคาขายตามสมาคม</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>มูลค่าสินค้าทองลดหย่อน</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>ส่วนต่าง</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>ส่วนต่างทอง</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>ส่วนต่างภาษี</b></Table.Cell>
                                            <Table.Cell style={textRight}><b>vat</b></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                    {bill.map((row,i)=>
                                        {
                                            total_gold_price += parseFloat(row.gold_price)
                                            total_discount_product_value += parseFloat(row.discount_product_value)
                                            total_diff += parseFloat(row.diff)
                                            total_gold_diff += parseFloat(row.gold_diff)
                                            total_vat_diff += parseFloat(row.vat_diff)
                                            total_vat += parseFloat(row.vat)
                                            return (
                                                <Table.Row >
                                                    <Table.Cell style={textCenter}>{Utility.numberFormat(Number(i)+1)}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{this.changeYear(Utility.formatDate(row.date))}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{row.title}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row.kind_display}</Table.Cell>
                                                    <Table.Cell style={textLeft,staffWidth}>{row.customer_name}</Table.Cell>
                                                    <Table.Cell style={textCenter}>{row.customer_citizen_id}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{row.branch}</Table.Cell>
                                                    
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.gold_price)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.discount_product_value)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.diff)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.gold_diff)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.vat_diff)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row.vat)}</Table.Cell>
                                                </Table.Row>
                                            
                                            );
                                        }
                                    )}
                                                <Table.Row >
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textCenter}><b style={textU}>รวมทั้งหมด</b></Table.Cell>
                                                    
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_gold_price)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_discount_product_value)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_diff)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_gold_diff)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_vat_diff)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_vat)}</b></Table.Cell>
                                                </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>
                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.open_summary} onClose={this.close} /*dimmer='blurring'*/>
                    <Modal.Header><center>{title2 + this.changeYear(Utility.formatDate(this.state.search.start_date))} ถึง {this.changeYear(Utility.formatDate(this.state.search.end_date))}</center></Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <Table>
                            <Table.Body>

                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนชิ้นขาย</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='amount_sell' value={Utility.numberFormat(this.state.amount_sell)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >น้ำหนักชิ้นขาย</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='weight_sell' value={Utility.weightFormat(this.state.weight_sell)} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนชิ้นเปลี่ยน</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='amount_exchange' value={Utility.numberFormat(this.state.amount_exchange)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >น้ำหนักชิ้นเปลี่ยน</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='weight_exchange' value={Utility.weightFormat(this.state.weight_exchange)} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนชิ้นขายทองแท่ง</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='amount_sell_bar' value={Utility.numberFormat(this.state.amount_sell_bar)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >น้ำหนักชิ้นขายทองแท่ง</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='weight_sell_bar' value={Utility.weightFormat(this.state.weight_sell_bar)} className='text-right' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={textRight} >จำนวนชิ้นรวม</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='total_amount' value={Utility.numberFormat(this.state.total_amount)} className='text-right' /></Table.Cell>
                                    <Table.Cell style={textRight} >น้ำหนักชิ้นรวม</Table.Cell>
                                    <Table.Cell style={textRight} ><Input id='total_weight' value={Utility.weightFormat(this.state.total_weight)} className='text-right' /></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button id='btnPrintmodal' size='small' type='button' onClick={() => { this.setState({ open2: true, open: false }) }} primary>พิมพ์</Button> */}
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open_summary: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(CheckVatPrintPreview)
