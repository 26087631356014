/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import image from '../../images/image.png'
import {
    Form,
    Button,
    Confirm, Dimmer, Loader,
    Header,
    Pagination,
    Image,
    Input,
    Icon,
    Dropdown,
    Label,
    Popup,
    Checkbox,
    Accordion,
    Grid,
    Modal,
    Table,
} from 'semantic-ui-react';

class RewardList extends Component {
    constructor(props) {
        super(props);
        this.limit = 10
        this.state = {
            items: [],
            text: '',
            status: 'A',
            select: [],
            item_edit: null,
            total: 0,
            action: '',
            categories: [],
            category: 0,
            base_score: 1,
            value: '',
        }

        this.setFieldValueCategory = this.setFieldValueCategory.bind(this)
        this.setFieldValueProduct = this.setFieldValueProduct.bind(this)
        this.loadCategory = this.loadCategory.bind(this)
        this.loadProduct = this.loadProduct.bind(this)
        this.submitData = this.submitData.bind(this)
    }

    componentDidMount() {
        let system_setting = JSON.parse(localStorage.getItem('system_setting'))
        let base_score = system_setting.filter(setting => setting.setting == 'REW')
        this.setState({
            base_score: base_score[0].value
        }, () => {
            this.loadCategory()
            setTimeout(() => {
                this.loadProduct()
            }, 200)
        })
    }

    loadProduct(page = 1) {
        this.setState({
            loader_active: true,
        });
        let cat_fillter = this.state.category == 0 ? '' : '&category_id=' + this.state.category
        var product = Utility.getFetch(Settings.baseUrl + '/reward/product/?perPage=10&page=' + page + cat_fillter + '&status=' + this.state.status);
        Promise.all([product]).then((values) => {
            this.setFieldValueProduct(values[0].products, values[0].PRODUCT_STATUS, values[0].PRODUCT_SCORE)
            this.setState({
                loader_active: false,
            });
        });
    }

    setFieldValueProduct(product, status, score) {
        let items = []
        for (let i in product.data) {
            let p = product.data[i]
            let category = this.state.categories.find((cat) => cat.value == p.category_id)
            p.status = status[p.id] == 'O' ? 'O' : 'C'
            p.category_display = category ? category.text : 'ไม่พบหมวดหมู่'
            p.status_display = status[p.id] == 'O' ? 'เปิด' : 'ปิด'
            p.score = score[p.id] == undefined || score[p.id] == 'null' ? this.state.base_score : score[p.id]
            p.base = score[p.id] == undefined || score[p.id] == 'null' ? true : false
            p.point = p.score * p.current_price
            items.push(p)
        }
        this.setState({
            current_page: product.meta.current_page,
            last_page: product.meta.last_page,
            from: product.meta.from,
            to: product.meta.to,
            total: product.meta.total,
            items: items,
            select: [],
            action: '',
        })
    }


    submitData(data) {
        let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/product/', data)
        Promise.all([r]).then((values) => {
            this.loadProduct(this.state.current_page)
            this.setState({ item_edit: null })
        })
    }

    loadCategory() {
        this.setState({
            loader_active: true
        });
        var category = Utility.getFetch(Settings.baseUrl + '/reward/category');
        Promise.all([category]).then((values) => {
            this.categories = values[0].categories
            this.status = values[0].CATEGORY_STATUS
            this.name = values[0].CATEGORY_NAME
            this.setFieldValueCategory()
            this.setState({
                loader_active: false,
            });
        });
    }

    setFieldValueCategory() {
        let cats = [{
            key: 0,
            value: 0,
            text: 'ทั้งหมด'
        }]
        for (let i in this.categories) {
            let cat = this.categories[i]
            cat.status = this.status[cat.id] == undefined ? 'C' : this.status[cat.id]
            cat.name = this.name[cat.id] == undefined ? cat.name : this.name[cat.id]
            cats.push({
                key: cat.id,
                value: cat.id,
                text: `${cat.name}${cat.status == 'C' ? ' ( ปิด )' : ''}`,
                content: `${cat.name}${cat.status == 'C' ? ' ( ปิด )' : ''}`,
                style: cat.status == 'C' ? { 'color': 'grey', 'font-style': 'italic' } : {}
            })
            for (let j in cat.children) {
                let subcat = cat.children[j]
                subcat.status = this.status[subcat.id] == undefined ? 'C' : this.status[subcat.id]
                subcat.name = this.name[subcat.id] == undefined ? subcat.name : this.name[subcat.id]
                cats.push({
                    key: subcat.id,
                    value: subcat.id,
                    text: `${subcat.name}${subcat.status == 'C' ? ' ( ปิด )' : ''}`,
                    content: `-- ${subcat.name}${subcat.status == 'C' ? ' ( ปิด )' : ''}`,
                    style: subcat.status == 'C' ? { 'color': 'grey', 'font-style': 'italic' } : {}

                })
            }

        }
        this.setState({ categories: cats })
    }

    render() {
        const items = this.state.items
        return (
            <div>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Header floated='left' as='h2'>จัดการสินค้า</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                    <Loader content='Loading' inverted />
                </Dimmer>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field width={3}>
                            <label>หมวดหมู่</label>
                            <Dropdown
                                selection fluid
                                onChange={(e, v) =>
                                    this.setState({ category: v.value }, () => {
                                        this.loadProduct(this.state.current_page)
                                    })
                                } v
                                value={this.state.category}
                                options={this.state.categories}
                            />
                        </Form.Field>
                        <Form.Field width={3}>
                            <label>สถานะ</label>
                            <Dropdown
                                selection fluid
                                onChange={(e, v) =>
                                    this.setState({ status: v.value }, () => {
                                        this.loadProduct()
                                    })
                                } value={this.state.status}
                                options={
                                    [{
                                        key: 'A',
                                        value: 'A',
                                        text: 'ทั้งหมด'
                                    },
                                    {
                                        key: 'C',
                                        value: 'C',
                                        text: 'ปิด'
                                    },
                                    {
                                        key: 'O',
                                        value: 'O',
                                        text: 'เปิด'
                                    }]
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1} />
                            <Table.HeaderCell width={2} >หมวดหมู่สินค้า</Table.HeaderCell>
                            <Table.HeaderCell width={4}>ชื่อสินค้า</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='right'>ราคา</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ตัวคูณแต้ม</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='right'>แต้ม</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>สถานะสินค้า</Table.HeaderCell>
                            <Table.HeaderCell width={1} />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.items.length == 0 ?
                            <Table.Row><Table.Cell></Table.Cell></Table.Row> :
                            this.state.items.map((row, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell textAlign='center'>
                                        <Checkbox style={{ 'padding-right': '10%' }}
                                            checked={this.state.select.includes(row.id)}
                                            onChange={(e, v) => {
                                                let arr = this.state.select
                                                if (v.checked) {
                                                    arr.push(row.id)
                                                } else {
                                                    arr = arr.filter(value => value != row.id)
                                                }
                                                this.setState({
                                                    select: arr,
                                                })
                                            }}
                                        />
                                    </Table.Cell>
                                    {/* <Table.Cell textAlign='center'>
                                        <a onClick={(e) => {
                                            this.setState({
                                                item_edit: row,
                                                value: row.base ? '' : row.score,
                                            })
                                        }}><Icon name='edit' /></a>
                                    </Table.Cell> */}
                                    <Table.Cell>
                                        {row.category_display}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign={"right"}>
                                        {Utility.priceFormat(row.current_price)}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center' style={{ 'color': row.base ? 'grey' : 'green' }}>
                                        {row.score}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {Utility.priceFormat(row.point)}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {row.status_display}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <Checkbox toggle checked={row.status == 'O'} onChange={(e, v) => {
                                            let ids = {}
                                            if (v.checked) {
                                                ids[row.id] = 'O'
                                            } else {
                                                ids[row.id] = 'C'
                                            }
                                            this.submitData({
                                                'status': ids,
                                            })
                                        }} />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={4}>
                                แสดง {this.state.from ? this.state.from : '0'} - {this.state.to ? this.state.to : '0'} (Total: {this.state.total ? this.state.total : '0'})
                        </Table.HeaderCell>
                            <Table.HeaderCell colSpan={4} textAlign='right'>
                                <Pagination
                                    activePage={this.state.current_page}
                                    totalPages={this.state.last_page}
                                    onPageChange={(e, { activePage }) => {
                                        this.loadProduct(activePage)
                                    }}
                                />

                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>
                                <Checkbox
                                    checked={this.state.select.length == this.state.items.length}
                                    label='ทั้งหมด'
                                    onChange={(e, v) => {
                                        let arr = []
                                        if (v.checked) {
                                            this.state.items.forEach(i => {
                                                arr.push(i.id)
                                            })
                                        }
                                        this.setState({
                                            select: arr,
                                        })
                                    }}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='7'>
                                <br />
                                <Form>
                                    <Form.Group>
                                        <Form.Field width={2}>
                                            <Dropdown
                                                selection
                                                fluid
                                                onChange={(e, v) =>
                                                    this.setState({ action: v.value })
                                                } value={this.state.action}
                                                options={
                                                    [{
                                                        key: 'C',
                                                        value: 'C',
                                                        text: 'ปิด'
                                                    },
                                                    {
                                                        key: 'O',
                                                        value: 'O',
                                                        text: 'เปิด'
                                                    }]
                                                }
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Button size='small' type='button' primary onClick={() => {
                                                if (this.state.action == '') {
                                                    alert('กรุณาเลือกสถานะที่จะทำการเปลี่ยน')
                                                    return
                                                }
                                                if (this.state.select.length == 0){
                                                    alert('กรุณาเลือกสินค้าที่จะเปลี่ยนแปลงสถานะ')
                                                    return
                                                }
                                                let ids = {}
                                                let action = this.state.action
                                                for (let i in this.state.select) {
                                                    ids[this.state.select[i]] = action
                                                }
                                                this.submitData({ 'status': ids })
                                            }}>บันทึก</Button>
                                        </Form.Field>
                                        <Form.Field>
                                            <p>{this.state.select.length} จาก {this.state.items.length} รายการ</p>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                {this.state.item_edit != null &&
                    <Modal id='modalCreateBranch' open={true} size='mini' /*dimmer='blurring'*/ >
                        <Button
                            id='btnClose'
                            circular
                            icon='close'
                            basic
                            floated='right'
                            name=''
                            onClick={() => this.setState({ item_edit: null })} />
                        <Modal.Header>แก้ไข: {this.state.item_edit.name}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form size='small' >
                                    <Form.Field>
                                        <label>ตัวคูณแต้ม</label>
                                        <Input
                                            value={this.state.value}
                                            onChange={(e, v) => {
                                                if (!isNaN(Number(v.value))) {
                                                    v.value = v.value.trim()
                                                    let str = v.value.split(".");
                                                    if (Number(v.value) > 999) {
                                                        v.value = 999
                                                    }
                                                    str[1] = str[1] == undefined ? "" : "." + str[1];
                                                    if (str.length != 1) {
                                                        if (str[1].length > 3) {
                                                            return;
                                                        }
                                                    }
                                                    this.setState({
                                                        value: v.value
                                                    })
                                                }
                                            }}
                                        />
                                    </Form.Field>
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button size='small' color='red'
                                disabled={this.state.item_edit.base}
                                onClick={() => {
                                    this.submitData({
                                        'score': {
                                            [this.state.item_edit.id]: 'null'
                                        },
                                    })
                                }}
                                className={this.state.button_class}>Reset</Button>
                            <Button size='small' primary
                                disabled={!(this.state.value > 0)}
                                onClick={() => {
                                    this.submitData({
                                        'score': {
                                            [this.state.item_edit.id]: this.state.value
                                        },
                                    })
                                }}
                                className={this.state.button_class}>บันทึก</Button>
                            <Button size='small' onClick={(e) => { e.preventDefault(); this.setState({ item_edit: null }); }}>ยกเลิก</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>

        );
    }
}

export default RewardList;
