/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const data = this.props.items
        let kind = {}
        let kind_arr = []
        let total = 0
        let weight = 0
        let weight_buy = 0
        let weight_sell_total = 0
        let price_sell_total = 0
        let weight_buy_total = 0
        let price_buy_total = 0
        let total_fee = 0
        let total_weight_diff = 0
        let total_exchange_diff = 0
        let total_exchange = 0
        let total_received = 0
        let buy = 0
        let sell = 0
        let fee = 0
        let weight_diff = 0

        
        let it = []
        let it1 = []
        let total_fee_sum = 0
        for(let i in this.props.items.items){
            it.push(this.props.items.items[i])
            for(let j in this.props.items.items[i].items){
                it1.push(this.props.items.items[i].items[j])
            }
            
        }
        for(let j in it){
            let total_fee = 0
            let weight_sell = 0
            let weight_buy = 0
            let exchange_diff = 0
            let exchange = 0
            let received = 0
            for(let k in it[j].items){
                total_fee += Number(it[j].items[k].fee)
                exchange_diff = it[j].items[k].bill.exchange_diff
                exchange = it[j].items[k].bill.exchange
                // received = it[j].items[k].bill.total
                weight_diff = it[j].items[k].bill.weight_diff
                // if(it[j].items[k].kind == "BU"){
                //     weight_buy += Number(it[j].items[k].weight)
                // } else if(it[j].items[k].kind == "SE"){
                //     weight_sell += Number(it[j].items[k].weight)
                // }
            }
            it[j].total_fee = total_fee
            it[j].weight_diff = weight_diff
            // it[j].weight_diff = Math.ceil((weight_sell - weight_buy)*10)/10
            it[j].exchange_diff = exchange_diff
            it[j].exchange = exchange
            // it[j].received = received
            it[j].received = (parseFloat(total_fee) + parseFloat(exchange) + parseFloat(exchange_diff))
        }

        // for(let j in it1){
        //     if(it1[j].kind == "SE"){
        //         weight_sell_total += it1[j].weight
        //     }
        //     console.log(it1[j])
        // }
        
        // for (let i in data.items) {
        //     let item = Utility.cloneObjectJson(data.items[i])
        //     if (kind[item.category.id] == null)
        //         kind[item.category.id] = {
        //             items: [],
        //             kind_name: item.category.name,
        //             n: 0,
        //             weight: 0
        //         }
        //     kind[item.category.id].items.push(item)
        // }

        // for (let i in kind) {
        //     for (let j in kind[i].items) {
        //         kind_arr.push(kind[i].items[j])
        //         weight += parseFloat(kind[i].items[j].weight)
        //         buy += parseFloat(kind[i].items[j].buy)
        //         sell += parseFloat(kind[i].items[j].sell)
        //         fee += parseFloat(kind[i].items[j].fee)
        //         if (parseFloat(kind[i].items[j].buy) == 0){
        //             weight_sell += parseFloat(kind[i].items[j].weight)
        //         }
        //         else {
        //             weight_buy += parseFloat(kind[i].items[j].weight)
        //             total += parseFloat(kind[i].items[j].bill.total)
        //         }


        //     }
        // }
        // weight_diff = weight_sell - weight_buy
        // weight_diff = Math.ceil(weight_diff*10)/10

        return (<Table.Body>
            <Table.Row>
                <Table.Cell colSpan='1' style={textLeft}><b>{data.title}</b></Table.Cell>
                <Table.Cell colSpan='1' style={textLeft}></Table.Cell>
                <Table.Cell colSpan='9'></Table.Cell>
                <Table.Cell style={textCenter}></Table.Cell>
                <Table.Cell style={textCenter}></Table.Cell>
                <Table.Cell style={textCenter}></Table.Cell>
                <Table.Cell style={textCenter}></Table.Cell>
                <Table.Cell style={textCenter}></Table.Cell>
                <Table.Cell style={textCenter}>{Utility.formatDate(data.record_date)}</Table.Cell>
                <Table.Cell style={textCenter}>{Utility.formatTime(data.record_date)}</Table.Cell>
            </Table.Row>
            
            {it.map((row,i) => {
                total_fee += Number(row.total_fee)
                total_weight_diff += Number(row.weight_diff)
                total_exchange_diff += Number(row.exchange_diff)
                total_exchange += Number(row.exchange)
                total_received += Number(row.received)
                return (
                    // <div>
                    // <Table.Body>
                <Table.Row>
                    <Table.Cell colSpan='1'></Table.Cell>
                    <Table.Cell colSpan='1' style={textLeft}>{row.title}</Table.Cell>
                    <Table.Cell colSpan='9' style={textCenter}></Table.Cell>
                    <Table.Cell colSpan='1' style={textRight}>{Utility.priceFormat(row.total_fee)}</Table.Cell>
                    <Table.Cell colSpan='1' style={textRight}>{Utility.weightFormat(row.weight_diff)}</Table.Cell>
                    <Table.Cell colSpan='1' style={textRight}>{Utility.priceFormat(row.exchange_diff)}</Table.Cell>
                    <Table.Cell colSpan='1' style={textRight}>{Utility.priceFormat(row.exchange)}</Table.Cell>
                    <Table.Cell colSpan='1' style={textRight}>{Utility.priceFormat(row.received)}</Table.Cell>
                    <Table.Cell colSpan='2'></Table.Cell>
                </Table.Row>
                
                // </Table.Body>
                
                
                );
                
            })}
            {it1.map((row,i) => {
                if(row.kind == "SE"){
                    weight_sell_total += Number(row.weight)
                    price_sell_total += Number(row.sell)
                }
                if(row.kind == "BU"){
                    weight_buy_total += Number(row.weight)
                    price_buy_total += Number(row.buy)
                }
                
                return (
                <Table.Row>
                    <Table.Cell colSpan='2'></Table.Cell>
                    <Table.Cell style={textLeft}>{row.buy == 0 ? row.product.code : ''}</Table.Cell>
                    <Table.Cell style={textLeft}>{row.buy == 0 ? row.product.kind.name : ''}</Table.Cell>
                    <Table.Cell style={textLeft}>{row.buy == 0 ? row.product.name : ''}</Table.Cell>
                    <Table.Cell style={textRight}>{row.buy == 0 ? row.amount : ''}</Table.Cell>
                    <Table.Cell style={textRight}>{row.buy == 0 ? Utility.weightFormat(row.weight) : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.buy == 0 ? Utility.priceFormat(row.sell) : ''}</Table.Cell>
                <Table.Cell style={textCenter}>{row.sell == 0 ? row.category.name : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.sell == 0 ? Utility.weightFormat(row.weight) : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.sell == 0 ? Utility.priceFormat(row.buy) : ''}</Table.Cell>
                <Table.Cell colSpan='7'></Table.Cell>
                {/* <Table.Cell style={textRight}>{row.sell == 0 ? Utility.priceFormat(row.bill.total) : ''}</Table.Cell> */}
                </Table.Row>
                
                // </Table.Body>
                
                
                );
                
            })}
            {/* {kind_arr.map((row, i) => <Table.Row> */}
                {/* <Table.Cell ></Table.Cell> */}
            {/* <Table.Cell >{row.code_change}</Table.Cell> */}
                {/* <Table.Cell style={textCenter}>{row.buy == 0 ? row.product.code : ''}</Table.Cell>
                <Table.Cell style={textCenter}>{row.buy == 0 ? row.product.kind.name : ''}</Table.Cell>
                <Table.Cell style={textLeft}>{row.buy == 0 ? row.product.name : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.buy == 0 ? row.amount : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.buy == 0 ? Utility.weightFormat(row.weight) : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.buy == 0 ? Utility.priceFormat(row.sell) : ''}</Table.Cell>
                <Table.Cell style={textCenter}>{row.sell == 0 ? row.category.name : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.sell == 0 ? Utility.weightFormat(row.weight) : ''}</Table.Cell>
                <Table.Cell style={textRight}>{row.sell == 0 ? Utility.priceFormat(row.buy) : ''}</Table.Cell> */}
                {/* <Table.Cell style={textRight}>{row.sell == 0 ? Utility.priceFormat(row.bill.total) : ''}</Table.Cell> */}
                {/* <Table.Cell colSpan='2'></Table.Cell> */}
            {/* </Table.Row>)} */}
            <Table.Row>
                <Table.Cell colSpan='5'></Table.Cell>
                <Table.Cell style={textCenter}><div style={textU}><b>รวม</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_sell_total)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(price_sell_total)}</b></div></Table.Cell>
                <Table.Cell colSpan='1'></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_buy_total)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(price_buy_total)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_fee)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(total_weight_diff)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_exchange_diff)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_exchange)}</b></div></Table.Cell>
                <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_received)}</b></div></Table.Cell>
                <Table.Cell colSpan='3'></Table.Cell>
                {/* <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(sell)}</div></Table.Cell> */}
                {/* <Table.Cell ></Table.Cell> */}
                {/* <Table.Cell style={textRight}><div style={textU}>{Utility.weightFormat(weight_buy)}</div></Table.Cell> */}
                {/* <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(buy)}</div></Table.Cell> */}
                {/* <Table.Cell colSpan='4'></Table.Cell> */}
                {/* <Table.Cell style={textRight}><div style={textU}>{Utility.priceFormat(total)}</div></Table.Cell> */}
            </Table.Row>
        </Table.Body>)
    }
}

class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            branches: [],
            items: []
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        Promise.all([branches, staffs]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }



            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/bill_items/?kind=XC&status_stock=Y&" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            //alert(s)
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.branch) {
                if (item.bill.branch.id != this.state.search.branch)
                    continue;
            }

            if (this.state.search.product_type) {
                if (item.product.kind.id != this.state.search.product_type)
                    continue;
            }

            // if (this.state.search.product_type) {
                if (item.status_stock != "Y")
                    continue;
            // }



            item.date = Utility.formatDate(item.record_date)
            item.time = Utility.formatTime(item.record_date)
            item.number = item.bill.bill_number
            item.customer_name = item.bill.customer.name
            //item.product_code = item.product.code
            item.weight_b = Utility.weightFormat(item.weight / item.category.weight)

            try {
                let staffs = JSON.parse(item.bill.staffs)
                if (staffs.length > 0) {
                    let name = '';
                    let is_staff = false
                    for (let s in staffs) {
                        let st = Utility.getObject(this.staffs, staffs[s])
                        if (name != '')
                            name += ','
                        if (st) {
                            name += st.name
                        }

                        // check staff id
                        if (this.state.search.staff && is_staff == false) {
                            is_staff = this.state.search.staff == staffs[s]
                        } else {
                            is_staff = true;
                        }
                    }

                    if (!is_staff)
                        continue
                    item.staff_name = name
                } else if (this.state.search.staff != null)
                    continue
            } catch (e) {
                if (this.state.search.staff != null)
                    continue
            }
            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data

        let title = 'รายงานเปลี่ยนทอง';
        let filename = 'EXC-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let total = 0
        let weight = 0
        let weight_b = 0
        let sell = 0
        let net = 0
        let total_buy = 0
        let total_sell = 0
        let total_change = 0
        let weight_buy = 0
        let weight_sell = 0

        let total_fee_summary = 0
        let total_received = 0
        let bill_number = {}
        for (let i in this.state.items) {
            let item = this.state.items[i]
            if (bill_number[item.bill.bill_number] == null){
                bill_number[item.bill.bill_number] = {
                    items: {},
                    title: item.bill.bill_number,
                    record_date: item.bill.record_date,
                    exchange:item.bill.exchange,
                    exchange_diff:item.bill.exchange_diff,
                    total:item.bill.total,
                    weight_diff:item.bill.weight_diff,
                }
            }
            if(bill_number[item.bill.bill_number].items[item.code_change] == null){
                // total_received += parseFloat(item.bill.total)
                bill_number[item.bill.bill_number].items[item.code_change] = {
                    items:[],
                    title:item.code_change
                }
            }
            bill_number[item.bill.bill_number].items[item.code_change].items.push(item)

            // bill_number[item.bill.bill_number].items.push(item)

            weight += parseFloat(item.weight)

            total_buy += parseFloat(item.buy)
            total_sell += parseFloat(item.sell)
            if (parseFloat(item.buy) > 0) {
                total_change += parseFloat(item.bill.total)
                weight_buy += parseFloat(item.weight)
            } else {
                weight_sell += parseFloat(item.weight)
                total_fee_summary += parseFloat(item.fee)
                total_received += parseFloat(item.fee)
            }


        }
        let total_exchange = 0
        let total_exchange_diff = 0
        let total_weight_diff = 0
        let items2 = []
        for (let i in bill_number) {
            
            total_exchange += Number(Number(bill_number[i].exchange).toFixed(2))
            total_exchange_diff += Number(Number(bill_number[i].exchange_diff ).toFixed(2))
            total_weight_diff += Number(bill_number[i].weight_diff)
            items2.push(bill_number[i])
            total_received += Number(Number(bill_number[i].exchange).toFixed(2))
            total_received += Number(Number(bill_number[i].exchange_diff ).toFixed(2))
        }



        let branch_name = ''
        if (this.state.search.branch) {
            let b = Utility.getObjectByValue(this.state.branches, this.state.search.branch)
            if (b)
                branch_name = b.text
        }     

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานเปลี่ยนทอง</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='extra-wide2'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='4'><center></center></Table.HeaderCell>
                                            <Table.HeaderCell ><center>ทองใหม่</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='4'><center></center></Table.HeaderCell>
                                            <Table.HeaderCell ><center>ทองเก่า</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='3'><center></center></Table.HeaderCell>
                                            <Table.HeaderCell ><center>สรุปเปลี่ยนทอง</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='4'><center></center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft} >เลขที่บิล</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft} >รหัสเปลี่ยน</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft} >รหัส</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft} >รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>รวม น.น.(g)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เป็นเงิน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>%ทองเก่า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.ทอง(g)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เป็นเงิน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่ากำเหน็จรวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.(g)ส่วนต่าง</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เป็นเงินส่วนต่าง</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าเปลี่ยนทอง</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินที่ได้รับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เวลา</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {items2.map((row, i) => 
                                    <GroupRow key={i} items={row} />
                                    )}
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell colSpan='5'></Table.Cell>
                                            <Table.Cell style={textCenter}><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='weight_sell' style={textU}><b>{Utility.weightFormat(weight_sell)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_sell' style={textU}><b>{Utility.priceFormat(total_sell)}</b></div></Table.Cell>
                                            <Table.Cell ></Table.Cell>
                                            <Table.Cell style={textRight}><div id='weight_buy' style={textU}><b>{Utility.weightFormat(weight_buy)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_buy' style={textU}><b>{Utility.priceFormat(total_buy)}</b></div></Table.Cell>

                                            <Table.Cell style={textRight}><div id='total_buy' style={textU}><b>{Utility.priceFormat(total_fee_summary)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_buy' style={textU}><b>{Utility.weightFormat(total_weight_diff)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_buy' style={textU}><b>{Utility.priceFormat(total_exchange_diff)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_buy' style={textU}><b>{Utility.priceFormat(total_exchange)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_buy' style={textU}><b>{Utility.priceFormat(total_received)}</b></div></Table.Cell>
                                            <Table.Cell colSpan='7'></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div id='total_change' style={textU}><b>{Utility.priceFormat(total_change)}</b></div></Table.Cell> */}
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)