/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'

import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader,
  Dropdown,
  Header,
  Popup, TextArea,
  Menu,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

    //this.props.onClickMenu(e);
  }

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell><center>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a>
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class Categories extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      code: '',
      name: '',
      description: '',
      weight: '',
      m_buy: '',
      m_sell: '',
      discount_buy: '',
      modal_open: false,
      msg_error: {},
      activeItem: 'แสดง',
    }
    this.submitData = this.submitData.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  submitData() {
    let msg_error = {}
    var formData = {
      code: this.state.code,
      name: this.state.name,
      weight: this.state.weight,
      description: this.state.description,
      m_buy: this.state.m_buy,
      m_sell: this.state.m_sell,
      discount_buy: this.state.discount_buy,
      is_enabled: this.state.is_enabled
    };

    const self = this;
    this.setState({ button_class: 'loading', msg_error: {} })
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/categories/';
      Utility.post(url, formData, (status, data) => {

        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.code !== undefined) {
            if (data.code[0] === 'category และ code มีอยู่แล้ว') {
              msg_error['code'] = ['มีรหัสกลุ่มสินค้านี้อยู่ในระบบแล้ว']
            }
          }
          if (data.name !== undefined) {
            if (data.name[0] === 'category และ name มีอยู่แล้ว') {
              msg_error['name'] = ['มีชื่อกลุ่มสินค้านี้อยู่ในระบบแล้ว']
            }
          }
          if (formData.code.length > 5) {
            msg_error['code'] = ['ความยาวต้องไม่มากกว่า5ตัวอักษร']
          }
          if (formData.code.length === 0) {
            msg_error['code'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.name.length === 0) {
            msg_error['name'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.weight.length === 0) {
            msg_error['weight'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.m_buy.length === 0) {
            msg_error['m_buy'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.m_sell.length === 0) {
            msg_error['m_sell'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.discount_buy.length === 0) {
            msg_error['discount_buy'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (isNaN(Number(formData.discount_buy))) {
            msg_error['discount_buy'] = ['ต้องเป็นตัวเลข']
          }
          if (formData.weight < 0) {
            msg_error['weight'] = ['ต้องไม่ติดลบ']
          }
          if (formData.m_buy < 0) {
            msg_error['m_buy'] = ['ต้องไม่ติดลบ']
          }
          if (formData.m_sell < 0) {
            msg_error['m_sell'] = ['ต้องไม่ติดลบ']
          }
          if (Object.keys(msg_error).length > 0) {
            this.setState({ msg_error: msg_error })
            return;
          }
          // if (data.length !== 0){
          //   this.setState({
          //     msg_error: data
          //   })
          // }
        }
      });
    } else {
      const url = Settings.baseUrl + '/categories/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {

        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (formData.code.length > 5) {
            msg_error['code'] = ['ความยาวต้องไม่มากกว่า5ตัวอักษร']
          }
          if (formData.code.length === 0) {
            msg_error['code'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.name.length === 0) {
            msg_error['name'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.weight.length === 0) {
            msg_error['weight'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.m_buy.length === 0) {
            msg_error['m_buy'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.m_sell.length === 0) {
            msg_error['m_sell'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (formData.discount_buy.length === 0) {
            msg_error['discount_buy'] = ['ต้องไม่เป็นค่าว่าง']
          }
          if (isNaN(Number(formData.discount_buy))) {
            msg_error['discount_buy'] = ['ต้องเป็นตัวเลข']
          }
          if (formData.weight < 0) {
            msg_error['weight'] = ['ต้องไม่ติดลบ']
          }
          if (formData.m_buy < 0) {
            msg_error['m_buy'] = ['ต้องไม่ติดลบ']
          }
          if (formData.m_sell < 0) {
            msg_error['m_sell'] = ['ต้องไม่ติดลบ']
          }
          if (Object.keys(msg_error).length > 0) {
            this.setState({ msg_error: msg_error })
            return;
          }
          // if (data.length !== 0){
          //   this.setState({
          //     msg_error: data
          //   })
          // }
        }
      });
    }
  }

  resetForm() {
    this.setState({
      code: '',
      name: '',
      weight: '',
      description: '',
      button_class: false,
      discount_buy: '',
      m_buy: '',
      m_sell: '',
      msg_error: {},
      is_enabled: 1
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }
    Utility.get(Settings.baseUrl + '/categories/?is_enabled=' + status, (status, data) => {
      this.setState({
        items: data,
        loader_active: false,
        // activeItem: 'แสดง'
      });
    });
  }

  componentWillMount() {
    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  confirmDelete(e, category) {
    if (window.confirm('ยืนยันลบ')) {

      let url = Settings.baseUrl + "/categories/" + category.id + "/"
      Utility.delete(url, (s, r, code) => {

        if (s) {
          this.componentDidMount();
        } else {
          alert(r.error)
        }
      })

    }
  }

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไขกลุ่มสินค้า',
        code: row.code,
        name: row.name,
        is_enabled: row.is_enabled,
        weight: row.weight,
        m_sell: row.m_sell,
        m_buy: row.m_buy,
        discount_buy: row.discount_buy,
        description: row.description,
        object_id: row.id
      });
    } else {
      this.confirmDelete(e, row)
    }
  }

  handleItemClick = (e, { name }) => {
    let status = 1
    if (name === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/categories/?is_enabled=' + status, (status, data) => {
      if (status)
        this.setState({
          items: data,
          loader_active: false
        });
    });
    this.setState({ activeItem: name })
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form >
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>กลุ่มสินค้า</Header>
            </Form.Field>
            <Form.Field width={10}>
              <Button id='addCategory' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างกลุ่มสินค้า'
                });
              }}
                floated='right' icon='plus' labelPosition='left' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='close_catagory'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <Form>
                <Form.Field error={this.state.msg_error.code != null}>
                  <label>*รหัส <MsgInput text={this.state.msg_error.code} /></label>
                  <Input id='categoryID'
                    // type='number' 
                    maxLength={5}
                    placeholder='รหัส'
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        this.setState({ code: e.target.value })
                      }
                    }
                    } value={this.state.code} />
                  <i>*เป็นตัวเลขเท่านั้น และไม่ควรเกิน 5 ตัวอักษร</i>
                </Form.Field>
                <Form.Field error={this.state.msg_error.name != null}>
                  <label>*ชื่อ <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='name_category'
                    placeholder='ชื่อ'
                    maxLength={100}
                    onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />

                </Form.Field>
                <Form.Field error={this.state.msg_error.weight != null}>
                  <label>*น้ำหนัก(กรัม)/1บาท <MsgInput text={this.state.msg_error.weight} />
                    <Popup trigger={<Icon circular name='info circle' />} >
                      คือน้ำหนักทอง 1บาทที่ควรจะเป็นของกลุ่มสินค้านี้
                      เช่น ทอง 1 บาท ควรหนัก 15.2 กรัม
                    </Popup>
                  </label>
                  <Input id='weight_category'
                    // type='number'
                    maxLength={17}
                    placeholder='น้ำหนัก(กรัม)/1บาท'
                    min={0} onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.split('.')[0].length > 5) {
                          return;
                        }
                        if (e.target.value.split('.').length != 1)
                          if (e.target.value.split('.')[1].length > 3) {
                            return;
                          }
                        this.setState({ weight: e.target.value })
                      }
                    }} value={this.state.weight} />

                </Form.Field>

                <Form.Field error={this.state.msg_error.m_buy != null}>
                  <label>*ตัวคูณซื้อทอง <MsgInput text={this.state.msg_error.m_buy} />
                    <Popup trigger={<Icon circular name='info circle' />} >
                      คือตัวแปลหาค่าน้ำหนักทอง เช่น ทอง90 1บาท ชั่งน้ำหนักจริง 15.2 กรัม ตัวคูณทอง90 คือ 0.9
                      จะได้ 15.2 x 0.9 = 13.68 กรัม
                    </Popup></label>
                  <Input id='m_buy_category'
                    // type='number' 
                    maxLength={18}
                    placeholder='ตัวคูณซื้อทอง'
                    min={0} onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.split('.')[0].length > 5) {
                          return;
                        }
                        if (e.target.value.split('.').length != 1)
                          if (e.target.value.split('.')[1].length > 2) {
                            return;
                          }
                        this.setState({ m_buy: e.target.value })
                      }
                    }} value={this.state.m_buy} />

                </Form.Field>
                <Form.Field error={this.state.msg_error.m_sell != null}>
                  <label>*ตัวคูณขายทอง <MsgInput text={this.state.msg_error.m_sell} />
                    <Popup trigger={<Icon circular name='info circle' />} >
                      คือตัวแปลหาค่าน้ำหนักทอง เช่น ทอง90 1บาท ชั่งน้ำหนักจริง 15.2 กรัม ตัวคูณทอง90 คือ 0.9
                      จะได้ 15.2 x 0.9 = 13.68 กรัม
                    </Popup></label>
                  <Input id='m_sell_category'
                    // type='number'
                    maxLength={18}
                    placeholder='ตัวคูณขายทอง'
                    min={0} onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.split('.')[0].length > 5) {
                          return;
                        }
                        if (e.target.value.split('.').length != 1)
                          if (e.target.value.split('.')[1].length > 2) {
                            return;
                          }
                        this.setState({ m_sell: e.target.value })
                      }
                    }} value={this.state.m_sell} />

                </Form.Field>
                <Form.Field error={this.state.msg_error.discount_buy != null}>
                  <label>*ค่าหักรับซื้อทองเก่า <i></i> <MsgInput text={this.state.msg_error.discount_buy} /><Popup trigger={<Icon circular name='info circle' />} >
                    คือจำนวนที่ใช้หักค่าราคาทองก่อนซื้อทองเก่า ต่อ 1บาท เช่น  -200 , ห้ามใส่ - ด้านหลังตัวเลขค่าจะออกมา 0 ทันที
                    </Popup></label>
                  <Input
                    id='discount_buy_category'
                    // type='number'
                    placeholder='ค่าหักรับซื้อทองเก่า'
                    maxLength={18}
                    onChange={(e, d) => {
                      if (isNaN(Number(d.value)) && d.value != '-') {
                      } else {
                        if (d.value.split('.')[0].length > 5) {
                          return;
                        }
                        if (d.value.split('.').length != 1)
                          if (d.value.split('.')[1].length > 2) {
                            return;
                          }
                        this.setState({ discount_buy: d.value })
                      }
                    }
                    }
                    // onChange={(e) => 
                    //   this.setState({ discount_buy: e.target.value })} 
                    // type='number' 
                    value={this.state.discount_buy} />

                </Form.Field>


                <Form.Field error={this.state.msg_error.description != null}>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea
                    maxLength={255}
                    placeholder='หมายเหตุ'
                    onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} />
                </Form.Field>

                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown id='status_category' selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />

                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='confirm_category' size='small' primary onClick={(e) => {
              e.preventDefault();
              this.submitData();
              this.setState({ button_class: true })
            }}
              className={this.state.button_class ? 'loading' : ''}>บันทึก</Button>
            <Button id='cancel_category' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={387}>
          <Column
            width={80}
            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell onClickMenu={this.handleClick} />}
          />
          <Column
            header={<Cell>รหัส</Cell>}
            cell={
              <ItemsCell position='top left' width={80} id='table_code_category' data={items} field="code" />
            }
            width={80}
          />
          <Column
            header={<Cell>ชื่อ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="name" />
            }
            width={200}
          />
          <Column
            header={<Cell className='text-right' >น้ำหนัก/บาท</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="weight" textAlign='text-right' />
            }
            width={120}
          />
          <Column
            header={<Cell className='text-right'>ตัวคูณซื้อทอง</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="m_buy" textAlign='text-right' />
            }
            width={120}
          />
          <Column
            header={<Cell className='text-right'>ตัวคูณขายทอง</Cell>}
            cell={
              <ItemsCell position='top right' width={120} data={items} field="m_sell" textAlign='text-right' />
            }
            width={120}
          />
          <Column
            header={<Cell className='text-right'>หักค่ารับซื้อทองเก่า</Cell>}
            cell={
              <ItemsCell position='top right' width={180} data={items} field="discount_buy" textAlign='text-right' />
            }
            width={180}
          />
          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="description" />
            }
            width={200}
          />
        </Table></div>
        <Form size='small'>
              <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
              </Form.Group>
            </Form>
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/categories/' + this.state.object_id + "/";
            var object = this.state.object;
            object.is_enabled = 0
            var self = this;
            Utility.put(url, object, function (status, data) {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              }
            });

          }}
        />
      </div>
    );
  }
}
export default Categories;
