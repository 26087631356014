/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,
    Button,
    Grid,
    Input,
    Header,
    Segment,
} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import MsgInput from '../Error/MsgInput'
import { connect } from 'react-redux'


class Setting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: {},
            value: 1,
        }

        this.saveSetting = this.saveSetting.bind(this)
    }

    componentDidMount() {
        this.setState({
            loader_active: true
        });
        let system_setting = JSON.parse(localStorage.getItem('system_setting'))
        let reward_setting = system_setting.filter(setting => setting.setting == 'REW')
        this.sys_setting_id = reward_setting[0].id
        this.setState({
            value: reward_setting[0].value,
            loader_active: false
        });
    }

    saveSetting(e) {
        let error = {}
        if (this.state.value == null || this.state.value == '') {
            error['value'] = '*ไม่ควรเป็นค่าว่าง'
        }
        if (!isNaN(Number(this.state.value)) && Number(this.state.value) == 0) {
            error['value'] = '*ตัวคูณแต้มต้องมากกว่า 0'
        }

        if (Object.keys(error).length > 0) {
            this.setState({ error: error })
            return;
        }

        this.setState({
            button_loading: true
        })

        let url = Settings.baseUrl + "/system_setting/" + this.sys_setting_id + '/';
        Utility.put(url, { value: this.state.value }, (s, d) => {
            let error = {}
            if (s) {
                Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
                    if (status) {
                        localStorage.setItem("system_setting", JSON.stringify(data));
                    }
                });
                setTimeout(() => {
                    this.componentDidMount()
                    alert('บันทึกข้อมูลสำเร็จ!')
                }, 200)
            } else {
                error = d
            }
            this.setState({
                button_loading: false,
                error: error
            })
        });

    }

    render() {
        return (
            <div>
                <Form className='attached fluid ' size='small'>
                    <Grid divided='vertically'>
                        <Grid.Row >
                            <Grid.Column width={6}>
                                <Segment>
                                    <Header size='huge'>ตั้งค่าของรางวัล</Header>
                                    <Form.Field error={this.state.error.value != null}>
                                        <label>ตัวคูณแต้ม <MsgInput text={this.state.error.value} /></label>
                                        <Input
                                            className='text-right'
                                            value={this.state.value}
                                            onChange={(e, v) => {
                                                if (!isNaN(Number(v.value))) {
                                                    v.value = v.value.trim()
                                                    let str = v.value.split(".");
                                                    if (Number(v.value) > 999) {
                                                        v.value = 999
                                                    }
                                                    str[1] = str[1] == undefined ? "" : "." + str[1];
                                                    if (str.length != 1) {
                                                        if (str[1].length > 3) {
                                                            return;
                                                        }
                                                    }
                                                    this.setState({ value: v.value })
                                                }
                                            }}
                                        />
                                    </Form.Field>
                                    <Button content='บันทึก'
                                        onClick={this.saveSetting}
                                        loading={this.state.button_loading}
                                        floated='right' type='button' primary />
                                    <br /><br />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>);
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(Setting)
