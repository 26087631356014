/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Header, Button, Loader, Dimmer, Icon, Input, Pagination
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import BillTable from './BillTable'
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import BillModal from './BillModal';
import { connect } from 'react-redux'

class ExportCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_detail: '',
      start_date: moment(),
      end_date: moment(),
      is_clear_stock: false,
      is_clear_bill: false,
      total_item: 0,
      table_hiegh: Settings.table_hiegh,
      branch_id: 0,
      page: 1,
      totalPages: 1,
      qrstring: '',
      colSortDirs: {
        'number': 'ASC',
      },
      counter:0,
    }
    this.columnKey = 'number'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];
    this.onClickitem = this.onClickitem.bind(this);
    this.loaddata = this.loaddata.bind(this);
    this.loaddata2 = this.loaddata2.bind(this);
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  loaddata2(page) {
    const { branch } = this.props.auth
    this.setState({
      loader_active: true,
      total_item: 0
    });
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var vendors = Utility.getFetch(Settings.baseUrl + '/vendors/?is_enabled=1');
    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_old/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + this.state.qrstring + '&page=' + page);
    var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_category/?is_enabled=1&branch=' + branch.id);
    Promise.all([branches, vendors, invoices, stock_category]).then((values) => {
      this.branches = values[0];
      this.vendors = values[1];
      this.stock_category = values[3];
      let branches = [{
        key: 0,
        value: 0,
        text: '- ทั้งหมด -',
      }];
      for (let i in values[0]) {

        if (values[0][i].is_enabled == 1)
          branches.push({
            key: values[0][i].id,
            value: values[0][i].id,
            text: values[0][i].name,
          });
      }

      let vendors = [];
      for (let i in values[1]) {
        vendors.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        });
      }

      var items = this.setFieldValue(values[2].results);
      let stock_category = [];
      for (let i in values[3]) {
        stock_category.push({
          key: values[3][i].id,
          value: values[3][i].id,
          text: values[3][i].category.name,
        });
      }
      var items = this.setFieldValue(values[2].results);

      this.setState({
        stock_category: stock_category,
        products: this.products,
        branches: branches,
        vendors: vendors,
        items: items,
        loader_active: false,
        totalPages: values[2].next == null && values[2].previous == null ? 0 : Math.ceil(values[2].count / 10),
        counter: values[2].count,
        total_item: items.length
      });
      let elHeight = document.getElementById('table_width')
      if (elHeight)
        this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 560 });
    });
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        start_date: moment(temp_start, 'DD/MM/YYYY'),
        end_date: moment(temp_end, 'DD/MM/YYYY')
      })
    }

  }

  loaddata(page) {
    const { branch } = this.props.auth
    this.setState({
      loader_active: true,
      total_item: 0
    });

    var s = '';
    if (this.state.end_date != null && this.state.end_date != '') {
      s += (s == '' ? '' : '&') + 'end_date=' + Utility.formatDate2(this.state.end_date);
    } else {
      this.setState({ 'end_date': this.state.start_date })
    }
    if (this.state.start_date != null && this.state.start_date != '') {
      s += (s == '' ? '' : '&') + 'start_date=' + Utility.formatDate2(this.state.start_date);
    }

    if (this.state.branch_id != null && this.state.branch_id != '') {
      s += (s == '' ? '' : '&') + 'branch_id=' + this.state.branch_id;
    }

    if (this.state.is_clear_stock) {
      s += (s == '' ? '' : '&') + 'status_stock=N';
    }

    if (this.state.is_clear_bill) {
      s += (s == '' ? '' : '&') + 'status_bill=N';
    }

    this.setState({ qrstring: s })

    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    var vendors = Utility.getFetch(Settings.baseUrl + '/vendors/?is_enabled=1');
    var invoices = Utility.getFetch(Settings.baseUrl + '/invoices_old/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + s + '&page=' + page);
    var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_category/?is_enabled=1&branch=' + branch.id);
    Promise.all([branches, vendors, invoices, stock_category]).then((values) => {
      this.branches = values[0];
      this.vendors = values[1];
      this.stock_category = values[3];
      let branches = [{
        key: 0,
        value: 0,
        text: '- ทั้งหมด -',
      }];
      for (let i in values[0]) {

        if (values[0][i].is_enabled == 1)
          branches.push({
            key: values[0][i].id,
            value: values[0][i].id,
            text: values[0][i].name,
          });
      }

      let vendors = [];
      for (let i in values[1]) {
        vendors.push({
          key: values[1][i].id,
          value: values[1][i].id,
          text: values[1][i].name,
        });
      }

      var items = this.setFieldValue(values[2].results);
      let stock_category = [];
      for (let i in values[3]) {
        stock_category.push({
          key: values[3][i].id,
          value: values[3][i].id,
          text: values[3][i].category.name,
        });
      }
      var items = this.setFieldValue(values[2].results);

      this.setState({
        stock_category: stock_category,
        products: this.products,
        branches: branches,
        vendors: vendors,
        items: items,
        loader_active: false,
        totalPages: values[2].next == null && values[2].previous == null ? 0 : Math.ceil(values[2].count / 10),
        counter: values[2].count,
        total_item: items.length
      });
      let elHeight = document.getElementById('table_width')
      if (elHeight)
        this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 560 });
    });
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        start_date: moment(temp_start, 'DD/MM/YYYY'),
        end_date: moment(temp_end, 'DD/MM/YYYY')
      })
    }

  }


  componentDidMount() {
    this.loaddata(1)

  }

  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]['status_stock_title'] = Settings.status_stock[item[i].status_stock]
      item[i]['status_bill_title'] = Settings.status_bill[item[i].status_bill]
      item[i]['update_date_title'] = item[i].stock_date ? Utility.formatDate(item[i].stock_date) : ''
      item[i]['invoice_date_title'] = Utility.formatDate(item[i].bill_date)
      let v = Utility.getObject(this.vendors, item[i].vendor);
      item[i]['vendor_title'] = v != null ? v.name : '';
      item[i]['branche_title'] = Utility.getObject(this.branches, item[i].branch).name
      item[i]['number_title'] = item[i].number

      let b = Utility.getObject(this.branches, item[i].branch_to)
      if (b)
        item[i]['branche_to_title'] = b.name
      //this.branches
    }
    return item;
  }

  clearFormSearch() {
    this.setState({
      branch_id: 0,
      vendor: '',
      start_date: moment(),
      end_date: moment(),
      is_clear_stock: false,
      is_clear_bill: false
    })
  }

  onClickitem(e, d) {

    let data = this.state.items[d.positon];


    e.preventDefault();
    this.setState({
      open_invoice: true,
      invoice_detail: data,
      bill_number: data.number,
      modal_action: 'edit'
    });
  }

  resetForm() {

  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loaddata2(activePage)
    // this.componentDidMount()
  }

  render() {
    let items = this.state.items;
    return (

      <div className='invoice-box'>

        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>นำออกทองเก่า</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button id='addExportCategory' size='small' content='สร้างใบนำออกทองเก่า' onClick={() => {
                this.setState({ open_invoice: true, modal_action: 'add' });
              }} floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>

        <Form className='attached fluid' size='small'>
          <Form.Group>
            <Form.Dropdown id='searchbranch' width={4} label='สาขา' search selection options={this.state.branches} value={this.state.branch_id} onChange={(e, data) => this.setState({ branch_id: data.value })} />

            <Form.Field width={4}>
              <label>จากวันที่</label>
              <DatePicker
                id='searchstartdate'
                dateFormat="DD/MM/YYYY"
                selected={this.state.start_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  if (this.state.end_date < date) {
                    this.setState({ end_date: date });
                  }
                  this.setState({ start_date: date });
                }}
              />
            </Form.Field>
            <Form.Field width={4} >
              <label>ถึงวันที่</label>
              <DatePicker
                id='searchenddate'
                dateFormat="DD/MM/YYYY"
                selected={this.state.end_date}
                minDate={this.state.start_date}
                onChange={(date) => {
                  date = date ? date : moment()
                  this.setState({ end_date: date });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Checkbox id='update' width={3} label='ยังไม่ปรับปรุงสต็อก' floated='right'
              checked={this.state.is_clear_stock}
              value={this.state.is_clear_stock} onChange={(e, v) => {

                this.setState({
                  is_clear_stock: !this.state.is_clear_stock
                });
              }} />
            <Form.Checkbox id='clear' width={3} label='ยังไม่เคลียร์บิล' checked={this.state.is_clear_bill} value={this.state.is_clear_bill} onChange={(e, v) => {
              this.setState({
                is_clear_bill: !this.state.is_clear_bill
              });
            }} />
            <Form.Field width={10}>
              <Button id='clear_search' floated='right' type='button' size='small' onClick={() => {
                // this.componentDidMount();
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
              <Button id='searchtoday' floated='right' type='button' size='small' onClick={() => {
                this.clearFormSearch();
                setTimeout(() => {
                  this.setState({ page: 1 })
                  this.loaddata(1);
                }, 500)
              }}  ><Icon name='search' /> วันนี้</Button>
              <Button id='search' floated='right' type='button' size='small' onClick={() => {
                this.setState({ page: 1 })
                this.loaddata(1);
              }} ><Icon name='search' /> ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer active={this.state.loader_active} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>}
        <div id="table_width">
          <BillTable items={items} onSortChange={this.onSortChange} colSortDirs={this.state.colSortDirs} table_width={this.state.table_width} onActionItemCell={this.onClickitem} />
          <br />
          <Form size='small'>
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
              <br />
              <Form.Field width={14}>
                <br />
                {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
          {/* {this.state.totalPages>1 && <Pagination
                    activePage={this.state.page}
                    onPageChange={this.handlePaginationChange.bind(this)}
                    totalPages={this.state.totalPages}
                  />} */}
        </div>
        <br />
        <Form size='small' >
          <Form.Group>
            {/* <Form.Field width={2}>
              <lable>จำนวน</lable>
              <Input id='amount' value={this.state.total_item.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} className='text-right' />
            </Form.Field> */}
            <Form.Field width={16}>
              <br />
              {this.state.open_invoice ? <BillModal
                modal_action={this.state.modal_action}
                invoice_detail={this.state.invoice_detail}
                branches={this.state.branches}
                vendors={this.state.vendors}
                stock_category={this.stock_category}
                stock_category_name={this.state.stock_category}
                bill_number={this.state.bill_number}
                open={true}
                onClose={() => {
                  this.setState({ open_invoice: false });
                  this.loaddata(this.state.page);
                }}
                onAddInvoice={(d) => {
                  if (d != null) {
                    this.setState({
                      invoice_detail: d,
                      modal_action: 'edit'
                    });
                  }
                  this.loaddata(this.state.page);
                }}
              /> : ''}
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}
export default connect(
  mapStateToProps,
)(ExportCategory)