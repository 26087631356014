/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import MsgInput from "../../Error/MsgInput";
import {
  Menu,
  Form,
  Message,
  Button,
  Modal,
  Input,
  Dimmer,
  Loader,
  Header,
  Label,
  FormGroup,
  FormField,
  Checkbox,
  Popup,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { Table, Column, Cell } from "fixed-data-table";
import SelectOption from "../../SelectOption";
import Utility from "../../../Utility";
import Settings from "../../../Settings";
import moment from "moment";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

class IconCell extends Component {
  render() {
    const { rowIndex, field, data, type, action, ...props } = this.props;

    return (
      <Cell {...props}  >
        <a id='btnDelete' onClick={(e) => { if (window.confirm('ยืนยันลบรายการนี้')) this.props.onDelete(e, rowIndex) }}><Icon name='window close outline' /></a>
      </Cell>
    );
  }
}

function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = this.props.width * 0.9 + "px";
    var ellipsisStyle = {
      whiteSpace: "nowrap",
      width: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: this.props.textAlign,
    };
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            trigger={
              <div
                style={ellipsisStyle}
              >
                {this.props.type == "price"
                  ? Utility.priceFormat(data[rowIndex][field])
                  : this.props.type == "weight"
                    ? Utility.weightFormat(data[rowIndex][field])
                    : this.props.type == "boolean"
                      ? data[rowIndex][field] ? <Icon name='check' size='small' color="green" /> : <Icon name='close' size='small' color="red" />
                      : data[rowIndex][field]}
              </div>
            }
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class SellForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "ขายทอง",
      gold_bar_sell: "",
      is_price_aag: true,
      buy_categories_id: 0,
      QRCODE: "",
      is_submit_qrcode: false,
      sell_weight_real_g: "",
      product_price: "",
      fee: [],
      fee_price: 0,
      fee_min: 0,
      fee_max: 0,
      // products: [],
      category: [],
      sell_price: "",
      product_code: "",
      product_id: "",
      product_type: "",
      product_type_id: "",
      product_name: "",
      amount: "",
      discount: "",
      exchange: 0,
      msg_error: "",
      buy_weight_real_g: "",
      buy_weight_real_b: "",
      buy_price: "",
      round: 100,
      gold_rec1: 0.92,
      gold_rec2: 0.945,
      buy_categories_name: "",
      buy_product_id: "",
      received: "",
      w_diff: 150,
      weight_diff: "",
      exchange_diff: "",
      is_aagold_disabled: true,
      kind_item: [],
      stocks: [],
      staff_product_stock: 0,
      max_weight: 0,
      stock_select: [],
    };
    // this.products = this.props.products;
    this.handleSeting = this.handleSeting.bind(this);
    this.handlerQRCODE = this.handlerQRCODE.bind(this);
    this.handlerChangeWeightReal = this.handlerChangeWeightReal.bind(this);
    this.handlerSellPrice = this.handlerSellPrice.bind(this);
    this.handlerfee = this.handlerfee.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.handlerSubmitBuy = this.handlerSubmitBuy.bind(this);
    this.handlerBuyWeightG = this.handlerBuyWeightG.bind(this);
    this.handlerBuyWeightB = this.handlerBuyWeightB.bind(this);
    this.handlerBuyPrice = this.handlerBuyPrice.bind(this);
    this.setSummary = this.setSummary.bind(this);
    this.handlerSubmitExchange = this.handlerSubmitExchange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.filterStock = this.filterStock.bind(this);

    this.state.items = [];
  }

  filterStock() {
    let staff_product_stock_list = this.state.stock_select
    for (let i = 0; i < this.props.billItems.length; i++) {
      staff_product_stock_list.push(Number(this.props.billItems[i].staff_product_stock))
    }
    let stocks = [];
    for (let i = 0; i < this.props.stock.length; i++) {
      let items = this.props.stock[i]
      if (!staff_product_stock_list.includes(items.id)) {
        stocks.push({
          value: items.id,
          key: items.id,
          text: items.product_name,
          weight: items.weight,
          category: items.category,
        });
      }
    }
    this.setState({
      stocks: stocks,
      stock_select: staff_product_stock_list,
    })
  }

  componentDidMount() {
    this.filterStock()
    Utility.get(Settings.baseUrl + "/categories/", (s, d) => {
      this.setState({
        category: d,
      });
    });
    let system_setting = JSON.parse(localStorage.getItem('system_setting'))
    let system_setting_fee = system_setting.filter(setting => setting.setting == 'FEE')
    let system_setting_exchange_diff = system_setting.filter(setting => setting.setting == 'WEIGHTDIFF')
    let qouta = system_setting.filter(setting => setting.setting == 'QOUTA')
    let v = JSON.parse(system_setting_fee[0].value);
    let exchange_diff = JSON.parse(system_setting_exchange_diff[0].value);
    this.setState({
      fee: v,
      qouta: JSON.parse(qouta[0].value),
      can_sale: Number(JSON.parse(qouta[0].value)) - Number(this.props.used) - Number(this.props.used_this),
    });
    let gold_price = Number(Utility.removeCommas(this.props.gold_price.gold_bar_sell))
    let exchange_d = exchange_diff.filter(diff => gold_price >= Number(diff.min) && gold_price <= Number(diff.max))
    this.setState({ exchange_d: Number(exchange_d[0].exchange_diff) })

    let code_change = 0
    if (this.props.billItems.length > 0)
      code_change = this.props.billItems[this.props.billItems.length - 1].id_change + 1
    this.setState({ code_change })

    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById("root-table-bill");
    this.setState({ table_width: elHeight.clientWidth + 80 });
  };

  handleItemClick = (e, d) => {
    let name = d == undefined ? this.state.activeItem : d.name;
    this.setState({ activeItem: name });
    this.setState({ msg_error: {} })
  };

  handleSeting(e, v) {
    this.setState({
      is_price_aag: v.checked,
    });
  }

  handlerQRCODE(e, d) {
    if (this.state.is_submit_qrcode) {
      this.setState({
        category_id: "",
        category_name: "",
        product_type_id: "",
        product_type: "",
        product_id: "",
        // product_name: "",
        sell_weight_real_g: "",
        sell_price: "",
        product_price: "",
        fee_price: 0,
        product_name_sell: "",
      });
    }
    this.setState({ QRCODE: d.value });
  }

  submitQRcode() {
    if (this.state.can_sale <= 0) {
      alert('ไม่เหลือสิทธิ์สำหรับการซื้อสินค้า')
      // this.setState({ QRCODE: '' })
      // return
    }
    let data = [];
    data["code"] = this.state.QRCODE.trim();
    data["stock_type"] = "new";
    data["branch"] = this.props.auth.branch.id;
    this.setState({ is_submit_qrcode: true });

    let qrstring = Utility.jsonToQueryString(data);
    var qrcode = Utility.getFetch(Settings.baseUrl + "/qrcode?" + qrstring);
    Promise.all([qrcode]).then((values) => {
      if (values[0].detail === undefined) {
        this.setState({
          category_id: values[0].stock_product.product.category.id,
          category_name: values[0].stock_product.product.category.name,
          category_code: values[0].stock_product.product.category.code,
          product_type_id: values[0].stock_product.product.kind.id,
          product_type: values[0].stock_product.product.kind.name,
          product_id: values[0].stock_product.product.id,
          product: values[0].stock_product.product,
          // product_name: values[0].stock_product.product.name,
          product_name_sell: values[0].stock_product.product.name,
          sell_weight_real_g: values[0].stock_product.product.weight,
          QRCODE2: values[0].qrcode,
          type_sale: values[0].product.type_sale,
        });
        // if(this.props.kind == 'XC'){
        //   this.setState({
        //     buy_categories_id:values[0].stock_product.product.category.id,
        //   })
        // }

        this.setState({
          fee_min: values[0].fee,
          fee_price: values[0].fee,
        })

        this.setState({
          kind_code: values[0].stock_product.product.kind.code,
        })
        if (this.props.kind == "XC") {
          this.setState({
            exchange: values[0].fee,
          });
        }

        const sell_weight_real_g = Utility.parseFloat(
          values[0].stock_product.product.weight,
          this.state.sell_weight_real_g
        );
        // const p = Utility.getObject(this.products, this.state.product_id);
        if (this.state.product) {
          this.setState({ sell_weight_real_g: sell_weight_real_g });
          this.setPriceSell(this.state.product, sell_weight_real_g, 1, this.state.fee_price);
        }
        this.weightRealGInput.focus();
      } else {
        alert(values[0].detail);
        this.setState({
          category_id: "",
          category_name: "",
          product_type_id: "",
          product_type: "",
          product_id: "",
          // product_name: "",
          sell_weight_real_g: "",
          sell_price: "",
          product_price: "",
          fee_price: 0,
          QRCODE: "",
          product_name_sell: "",
        });

        const sell_weight_real_g = Utility.parseFloat("", this.state.sell_weight_real_g);
        // const p = Utility.getObject(this.products, this.state.product_id);
        if (this.state.product) {
          this.setState({ sell_weight_real_g: sell_weight_real_g });
          this.setPriceSell(this.state.product, sell_weight_real_g, 1, this.state.fee_price);
        }
      }
    });
  }

  setPriceSell(product, weightReal, amount, fee) {
    let productPrice = 0;
    let sell_price = 0;
    const gold_bar_sell = Utility.removeCommas(this.props.gold_price.gold_bar_sell);

    if (product) {
      let productPrice = 0;
      let cat = Utility.getObject(this.state.category, product.category.id);
      if (product.type_sale == 1) {
        let b = (Number(weightReal) / Number(cat.weight)) * Number(cat.m_sell);
        productPrice = b * Number(gold_bar_sell);
      } else {
        productPrice = product.price_tag;
      }
      const product_price = Math.ceil(productPrice);
      this.setState({
        product_price: product_price,
        sell_price: Math.ceil(product_price * amount + Number(fee)),
        sell_price_temp: Math.ceil(product_price * amount),
      });
      // if(this.props.kind == 'XC'){
      this.setState({
        buy_price: product_price,
      });
      // }
    }
  }

  handlerChangeWeightReal(e, v) {
    v.value = v.value.toString().replace(/,/g, "");
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0;
      }
      if (v.value.length > 0) {
        let str = v.value.split('.')
        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        str[1] = str[1] == undefined ? '' : '.' + str[1]
        if (str.length != 1) {
          if (str[1].length > 4) {
            return;
          }
        }
      }
      const sell_weight_real_g = Utility.parseFloat(
        v.value,
        this.state.sell_weight_real_g
      );
      // const p = Utility.getObject(this.products, this.state.product_id);
      if (this.state.product) {
        this.setState({ sell_weight_real_g: v.value });
        let fee = this.state.fee_price
        this.setPriceSell(this.state.product, sell_weight_real_g, 1, fee);
      }
    }
  }

  handlerSellPrice(e, v) {
    const gold_bar_sell = Utility.removeCommas(this.state.gold_bar_sell);
    v.value = v.value.toString().replace(/,/g, "");
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0;
      }
      const sell_price = Utility.parseFloat(v.value, this.state.sell_price);

      this.setState({
        sell_price,
      });
      // const p = Utility.getObject(this.products, this.state.product_id);
      // this.setState({product_price:sell_price})
      this.setState({ fee_price: sell_price - this.state.product_price });
      // if(this.state.type === 1){
      //   this.setState({fee_price:0})
      // }
    }
  }

  handlerfee(e, v) {
    v.value = v.value.toString().replace(/,/g, "");
    if (isNaN(Number(v.value))) {
    } else {
      if (v.value.length === 0) {
        v.value = 0;
      }
      const fee_price = Utility.parseFloat(v.value, this.state.fee_price);
      // const p = Utility.getObject(this.products, this.state.product_id);
      if (this.state.product) {
        this.setState({ fee_price: fee_price });
        this.setPriceSell(this.state.product, this.state.sell_weight_real_g, 1, fee_price);
      }
    }
  }

  handlerSubmit(e, d) {
    this.setState({ msg_error: {} });
    let msg_error = {};
    if (
      this.state.QRCODE === undefined ||
      this.state.QRCODE === null ||
      this.state.QRCODE === "" ||
      this.state.product_name_sell === undefined ||
      this.state.product_name_sell === null ||
      this.state.product_name_sell === ""
    ) {
      alert("กรุณาเพิ่มสินค้า");
      return;
    }

    if (this.state.fee_price < this.state.fee_min) {
      msg_error["fee"] = ["กรุณากรอกค่ากำเหน็จให้ถูกต้อง"];
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    } else {
      this.setState({ msg_error: {} });
    }

    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].qrcode === this.state.QRCODE.trim()) {
        alert("ได้ทำการเลือกสินค้านี้แล้ว");
        this.setState({
          category_id: "",
          category_name: "",
          product_type_id: "",
          product_type: "",
          product_id: "",
          product: null,
          // product_name: "",
          sell_weight_real_g: "",
          sell_price: "",
          product_price: "",
          fee_price: 0,
          QRCODE: "",
          product_name_sell: "",
        });
        return;
      }
      if (this.state.items[i].type == "ขาย") {
        if (this.state.items[i].product.category.name != this.state.category_name) {
          alert("ลงรายการได้เฉพาะกลุ่มสินค้าเดียวกันเท่านั้น");
          this.setState({
            category_id: "",
            category_name: "",
            product_type_id: "",
            product_type: "",
            product_id: "",
            product: null,
            // product_name: "",
            sell_weight_real_g: "",
            sell_price: "",
            product_price: "",
            fee_price: 0,
            QRCODE: "",
            product_name_sell: "",
          });
          return;
        } else {
          if (this.state.items[i].product.type_sale != this.state.type_sale) {
            alert("ลงรายการได้เฉพาะกลุ่มสินค้าเดียวกันเท่านั้น");
            this.setState({
              category_id: "",
              category_name: "",
              product_type_id: "",
              product_type: "",
              product_id: "",
              product: null,
              // product_name: "",
              sell_weight_real_g: "",
              sell_price: "",
              product_price: "",
              fee_price: 0,
              QRCODE: "",
              product_name_sell: "",
            });
            return;
          }
        }
      }
    }

    for (let i = 0; i < this.props.billItems.length; i++) {
      if (this.props.billItems[i].qrcode === this.state.QRCODE.trim()) {
        alert("ได้ทำการเลือกสินค้านี้แล้ว");
        this.setState({
          category_id: "",
          category_name: "",
          product_type_id: "",
          product_type: "",
          product_id: "",
          product: null,
          // product_name: "",
          sell_weight_real_g: "",
          sell_price: "",
          product_price: "",
          fee_price: 0,
          QRCODE: "",
          product_name_sell: "",
        });
        return;
      }
    }

    let sell_item = this.state.items;
    const code_change = this.state.code_change;
    // const p = Utility.getObject(this.products, this.state.product_id);
    sell_item.push({
      type: "ขาย",
      qrcode: this.state.QRCODE2,
      code: this.state.product.code,
      product_name: this.state.product_name_sell,
      product_type: this.state.product_type,
      discount: this.state.discount,
      weight: Number(this.state.sell_weight_real_g).toFixed(3),
      sell_price: this.state.sell_price,
      buy_price: 0,
      fee: this.state.fee_price,
      product_price: this.state.product_price,
      gold_price: this.props.gold_price.gold_bar_sell,
      id_change: code_change,
      product_code: this.state.product_code,
      product_id: this.state.product_id,
      product: this.state.product,
      is_price_aag: true,
    });
    this.setState({ items: sell_item });

    this.setState({
      category_id: "",
      category_name: "",
      product_type_id: "",
      product_type: "",
      product_id: "",
      product: null,
      // product_name: "",
      sell_weight_real_g: "",
      sell_price: "",
      product_price: "",
      fee_price: 0,
      QRCODE: "",
      product_name_sell: "",
    });
    this.setSummary();
  }

  handlerSubmitBuy(e, d) {
    this.setState({ msg_error: {} });
    let msg_error = {};

    if (this.state.buy_categories_id == "" || this.state.buy_categories_id == 0) {
      msg_error["buy_categories_id"] = [" เลือก%ทอง"];
    }

    if (this.state.product_name == "") {
      msg_error["product_name"] = [" ต้องไม่เป็นค่าว่าง"];
    }

    if (this.state.buy_weight_real_g == "") {
      msg_error["buy_weight_real_g"] = [" ต้องไม่เป็นค่าว่าง"];
    }

    if (this.state.buy_weight_real_b == "") {
      msg_error["buy_weight_real_b"] = [" ต้องไม่เป็นค่าว่าง"];
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    let buy_item = this.state.items;
    const code_change = this.state.code_change;
    const cate = Utility.getObject(
      this.props.formValue.categories,
      this.state.buy_categories_id
    );
    buy_item.push({
      type: "ซื้อ",
      code: "",
      // code: cate.name,
      product_name: this.state.product_name,
      weight: Number(this.state.buy_weight_real_g).toFixed(3),
      buy_price: this.state.buy_price,
      sell_price: 0,
      fee: 0,
      product_price: this.state.product_price,
      gold_price: this.props.gold_price.gold_bar_sell,
      id_change: code_change,
      buy_weight_real_g: Number(this.state.buy_weight_real_g).toFixed(3),
      buy_weight_real_b: this.state.buy_weight_real_b,
      buy_product_id: this.state.buy_product_id,
      category_id: this.state.buy_categories_id,
      is_price_aag: this.state.is_price_aag,
      staff_product_stock: this.state.staff_product_stock,
    });
    let stock_select = this.state.stock_select
    stock_select.push(Number(this.state.staff_product_stock))
    this.setState({
      stock_select: stock_select,
    }, () => {
      this.filterStock()
    })
    this.setState({ items: buy_item });
    // reset
    this.setState({
      buy_categories_id: 0,
      product_name: "",
      buy_weight_real_g: "",
      buy_weight_real_b: "",
      buy_price: "",
    });

    this.setSummary();
  }

  handlerBuyWeightG(e, d) {
    d.value = d.value.toString().replace(/,/g, "");
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0;
      }
      if (d.value.length > 0) {
        let str = d.value.split('.')
        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        str[1] = str[1] == undefined ? '' : '.' + str[1]
        if (str.length != 1) {
          if (str[1].length > 4) {
            return;
          }
        }
      }
      if (d.value > this.state.max_weight) {
        d.value = this.state.max_weight
      }
      e.preventDefault();
      const buy_weight_real_g = Utility.parseFloat(d.value, this.state.buy_weight_real_g);
      const cate = Utility.getObject(
        this.props.formValue.categories,
        this.state.buy_categories_id
      );
      if (cate != null) {
        let b = (buy_weight_real_g / Number(cate.weight)) * Number(cate.m_buy);
        this.setState({
          buy_weight_real_g: d.value,
          buy_weight_real_b: (buy_weight_real_g / cate.weight).toFixed(3),
        });
        let gold_rec = this.state.gold_rec2;
        if (!this.state.is_price_aag) {
          gold_rec = this.state.gold_rec1;
        }
        let weight_bath = Number((buy_weight_real_g / cate.weight).toFixed(3));
        let buy_price = (b * Number(Utility.removeCommas(this.props.gold_price.gold_bar_sell)));
        buy_price = buy_price < 0 ? 0 : buy_price
        this.setState({
          buy_price: buy_price.toFixed(0),
          buy_price_compare: buy_price.toFixed(0),
        });
      }
    }
  }

  handlerBuyWeightB(e, d) {
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0;
      }
      if (d.value.length > 0) {
        let str = d.value.split('.')
        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        str[1] = str[1] == undefined ? '' : '.' + str[1]
        if (str.length != 1) {
          if (str[1].length > 4) {
            return;
          }
        }
      }
      e.preventDefault();

      let buy_weight_real_b = Utility.parseFloat(d.value, this.state.buy_weight_real_b);
      const cate = Utility.getObject(
        this.props.formValue.categories,
        this.state.buy_categories_id
      );
      if (buy_weight_real_b > (this.state.max_weight / cate.weight).toFixed(3)) {
        buy_weight_real_b = (this.state.max_weight / cate.weight)
      }
      if (cate != null) {
        let b = buy_weight_real_b * Number(cate.m_buy);
        this.setState({
          buy_weight_real_b: buy_weight_real_b,
          buy_weight_real_g: Number(buy_weight_real_b * cate.weight).toFixed(3),
        });

        let gold_rec = this.state.gold_rec2;
        if (!this.state.is_price_aag) {
          gold_rec = this.state.gold_rec1;
        }
        let buy_weight_real_g = Number(
          Number(buy_weight_real_b * cate.weight).toFixed(3)
        );
        let buy_price = (b * Number(Utility.removeCommas(this.props.gold_price.gold_bar_sell)));

        buy_price = buy_price < 0 ? 0 : buy_price
        this.setState({
          buy_price: buy_price.toFixed(0),
          buy_price_compare: buy_price.toFixed(0),
        });

      }
    }
  }

  handlerBuyPrice(e, d) {
    d.value = d.value.toString().replace(/,/g, "");
    if (isNaN(Number(d.value))) {
    } else {
      if (d.value.length === 0) {
        d.value = 0;
      }
      if (Number(d.value) > Number(this.state.buy_price_compare)) {
        d.value = this.state.buy_price_compare;
      }
      const buy_price = Utility.parseFloat(d.value, this.state.buy_price).toFixed(0);
      e.preventDefault();
      this.setState({ buy_price });

    }
  }

  setSummary() {
    let total_weight_sell = 0;
    let total_weight_buy = 0;
    let total_price_sell = 0;
    let total_price_buy = 0;
    let total_fee = 0;
    let total_price_sell_summary = 0;
    let weight_diff = 0;
    let exchange = 0;
    let exchange_diff = 0;
    let total_pay = 0;
    let received = 0;
    let kind = []
    this.setState({ kind_item: [] })
    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].type == "ขาย") {
        kind.push("SE")
        total_weight_sell += Number(this.state.items[i].weight);
      } else {
        kind.push("BU")
        total_weight_buy += Number(this.state.items[i].weight);
      }
      total_price_sell += Number(this.state.items[i].sell_price) - Number(this.state.items[i].fee);
      total_price_buy += Number(this.state.items[i].buy_price);
      total_fee += Number(this.state.items[i].fee);
    }

    let d = parseFloat(total_weight_buy).toFixed(3) - parseFloat(total_weight_sell).toFixed(3)
    let s = Math.abs(parseFloat(d).toFixed(3))
    weight_diff = Math.ceil((s) * 10) / 10;
    weight_diff = d < 0 ? (weight_diff * -1) : (weight_diff)

    total_price_sell_summary = total_price_sell + total_fee;
    received = total_fee;
    exchange = received - total_fee;
    // exchange_diff = (weight_diff*-1) <= 0 ? 0 : this.state.exchange_d;
    exchange_diff = (weight_diff * -1) < 0 || (weight_diff * -1) > 0.3 ? 0 : this.state.exchange_d * ((weight_diff * -1) < 0.3 ? (weight_diff * -1) * 10 : 3);
    total_pay = exchange_diff + received;
    total_weight_buy = total_weight_buy.toFixed(3)

    this.setState({
      total_weight_sell,
      total_weight_buy,
      total_price_sell,
      total_price_buy,
      total_fee,
      weight_diff,
      exchange_diff,
      received,
      total_price_sell_summary,
      exchange,
      total_pay,
      kind_item: kind,
    });
  }

  handlerSubmitExchange(e, d) {
    e.preventDefault();
    for (let i = 0; i < this.state.items.length; i++) {
      let sell = ''
      let buy = ''
      if (this.state.items[i].type == 'ขาย') {
        sell = {
          product_code: this.state.product_code,
          product_id: this.state.items[i].product_id,
          product_type: this.state.items[i].product_type,
          amount: 1,
          product: this.state.items[i].product,
          discount: this.state.items[i].discount,
          sell_weight_real_g: Number(this.state.items[i].weight).toFixed(3),
          product_price: this.state.items[i].product_price,
          sell_price: this.state.items[i].sell_price,
          gold_price: this.state.items[i].gold_price,
          exchange: this.state.exchange,
          id_change: this.state.items[i].id_change,
          qrcode: this.state.items[i].qrcode,
          fee: this.state.items[i].fee,
          exchange_diff: this.state.exchange_diff,
          total_pay: this.state.total_pay,
        }
      } else {
        const cate = Utility.getObject(this.props.formValue.categories, this.state.items[i].category_id);
        const code_change = (new Date()).getTime()
        buy = {
          category_id: this.state.items[i].category_id,
          category_name: cate.name,
          buy_product_id: this.state.buy_product_id,
          buy_weight_real_g: Number(this.state.items[i].buy_weight_real_g).toFixed(3),
          buy_weight_real_b: this.state.items[i].buy_weight_real_g,
          buy_price: this.state.items[i].buy_price,
          buy_name: this.state.items[i].product_name,
          gold_price: this.state.items[i].gold_price,
          exchange: this.state.exchange,
          id_change: this.state.items[i].id_change,
          is_price_aag: this.state.items[i].is_price_aag,
          exchange_diff: this.state.exchange_diff,
          total_pay: this.state.total_pay,
          staff_product_stock: this.state.items[i].staff_product_stock,
        }
      }

      this.props.onSubmit(e, {
        sell: sell,
        buy: buy,
      });

      this.props.onClose()

    }
  }

  onDelete(e, i) {
    const items = this.state.items
    if (items[i].staff_product_stock) {
      let stock_select = this.state.stock_select
      stock_select = stock_select.filter((x) => x != items[i].staff_product_stock)
      this.setState({
        stock_select: stock_select,
      }, () => {
        this.filterStock()
      })
    }
    items.splice(i, 1)
    this.setState({ items })
    this.setSummary()
  }

  render() {
    const { auth } = this.props;
    const items = this.state.items;

    const textRight = {
      "text-align": "right",
    };

    const textLeft = {
      "text-align": "left",
    };
    const textCenter = {
      "text-align": "center",
    };

    return (
      <Modal open={this.props.open} /*dimmer='blurring'*/>
        <Button
          id="btnCloseProductSelect"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header id="headerModalSelectProduct" as="h3">
          เลือกรายการสินค้า
        </Modal.Header>
        <Modal.Content id="contentModalSelectProduct">
          <Dimmer active={this.state.is_loading} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
          <Modal.Description>
            <Menu size="mini" attached="top" tabular>
              <Menu.Item
                name="ขายทอง"
                active={this.state.activeItem === "ขายทอง"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="ซื้อทอง"
                active={this.state.activeItem === "ซื้อทอง"}
                onClick={this.handleItemClick}
              />
            </Menu>
            <div>
              {this.state.activeItem === "ขายทอง" ? (
                <div>
                  <Message
                    id="selectForSell"
                    color="green"
                    attached
                    size="tiny"
                    header="ขายทอง"
                  />
                  <Form
                    className="attached fluid segment green"
                    color="red"
                    size="small"
                    onSubmit={(e) => {
                      this.handlerSubmit(e);
                    }}
                  >
                    <FormGroup>
                      <FormField width={8}>
                        <label>* QR Code </label>
                        <Form.Input
                          autoFocus
                          placeholder="Qrcode"
                          value={this.state.QRCODE}
                          onChange={this.handlerQRCODE}
                          onKeyPress={(e) => {
                            if (e.key == "Enter") {
                              this.submitQRcode();
                              // this.weightRealGInput.focus()
                              e.preventDefault();
                            }
                          }}
                          className="text-right"
                        />
                      </FormField>
                    </FormGroup>
                    <Form.Group>
                      <Form.Field width={4}>
                        <label>*%ทอง </label>
                        <Input
                          placeholder="%ทอง"
                          value={this.state.category_name}
                          readOnly
                          className="text-right"
                        />
                      </Form.Field>
                      <Form.Field width={4}>
                        <label>ประเภทสินค้า </label>
                        <Input
                          placeholder="ประเภทสินค้า"
                          value={this.state.product_type}
                          readOnly
                          className="text-right"
                        />
                      </Form.Field>

                      <Form.Field width={6}>
                        <label>*สินค้า</label>
                        <Input
                          placeholder="สินค้า"
                          value={this.state.product_name_sell}
                          readOnly
                          className="text-right"
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        // error={this.state.msg_error.sell_weight_real_g != null}
                        width={5}
                      >
                        <label>
                          *น.น.ชั่ง(ก.)
                          {/* <MsgInput text={this.state.msg_error.sell_weight_real_g} /> */}
                        </label>
                        <Input
                          placeholder="น.น.ชั่ง(ก.)"
                          maxLength={7}
                          value={
                            this.state.focus_weight
                              ? this.state.sell_weight_real_g.toString().replace(/,/g, "")
                              : this.state.sell_weight_real_g
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                          }
                          ref={(c) => {
                            this.weightRealGInput = c;
                          }}
                          // onFocus={(e) => { e.target.select() }}
                          onChange={this.handlerChangeWeightReal}
                          onKeyPress={(e) => {
                            if (e.key == "Enter") {
                              this.inputFee.focus();
                              e.preventDefault();
                            }
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              if (this.state.focus_weight) {
                                this.setState({
                                  focus_weight: false,
                                });
                              }
                            }, 0);
                          }}
                          onFocus={(e) => {
                            e.target.select();
                            if (!this.state.focus_weight) {
                              this.setState({
                                focus_weight: true,
                              });
                            }
                          }}
                          className="text-right"
                        />
                      </Form.Field>
                      <Form.Input
                        label="*ราคา/ชิ้น"
                        placeholder="ราคา/ชิ้น"
                        readOnly
                        width={5}
                        value={this.state.product_price
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        className="text-right"
                      />
                      <Form.Field error={this.state.msg_error.fee != null} width={6}>
                        <label>
                          *ค่ากำเหน็จ
                          <MsgInput text={this.state.msg_error.fee} />
                        </label>
                        <Input
                          maxLength={8}
                          placeholder="ค่ากำเหน็จ"
                          id="inputFee"
                          ref={(c) => {
                            this.inputFee = c;
                          }}
                          width={5}
                          value={
                            this.state.focus_fee
                              ? this.state.fee_price.toString().replace(/,/g, "")
                              : this.state.fee_price
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                          }
                          onChange={this.handlerfee}
                          onKeyPress={(e) => {
                            if (e.key == "Enter") {
                              this.totalSellInput.focus();
                              e.preventDefault();
                            }
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              if (this.state.focus_fee) {
                                this.setState({
                                  focus_fee: false,
                                });
                              }
                            }, 0);
                          }}
                          onFocus={() => {
                            if (!this.state.focus_fee) {
                              this.setState({
                                focus_fee: true,
                              });
                            }
                          }}
                          className="text-right"
                        />
                      </Form.Field>

                      <Form.Field
                        //   error={this.state.msg_error.sell_price != null}
                        width={4}
                      >
                        <label>
                          *ราคาขาย
                          {/* <MsgInput text={this.state.msg_error.sell_price} /> */}
                        </label>
                        <Input
                          maxLength={8}
                          id="inputPrice"
                          placeholder="ราคาขาย"
                          width={5}
                          value={
                            this.state.focus_sell
                              ? this.state.sell_price.toString().replace(/,/g, "")
                              : this.state.sell_price
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                          }
                          min={
                            this.props.auth.role == "A" ||
                              this.props.auth.role == "M" ||
                              this.props.auth.role == "S"
                              ? 0
                              : this.state.product_price * this.state.amount
                          }
                          // onFocus={(e) => { e.target.select() }}
                          onChange={this.handlerSellPrice}
                          ref={(c) => {
                            this.totalSellInput = c;
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              if (this.state.focus_sell) {
                                this.setState({
                                  focus_sell: false,
                                });
                              }
                            }, 0);
                          }}
                          onFocus={() => {
                            if (!this.state.focus_sell) {
                              this.setState({
                                focus_sell: true,
                              });
                            }
                          }}
                          className="text-right"
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Field style={{ textAlign: "end" }}>
                      <span style={{ color: 'red', float: "left" }}>ในรอบเดือนนี้ลูกค้ามีสิทธิ์ซื้อทองคงเหลือ {Utility.weightFormat(Number(this.state.qouta) - Number(this.props.used) - Number(this.props.used_this))}ก. ซื้อไปแล้ว {Utility.weightFormat(this.props.used)}ก. ลงรายการในบิลนี้ {Utility.weightFormat(this.props.used_this)}ก.</span>
                      <Button
                        id="confirmProduct"
                        type="submit"
                        size="small"
                        primary
                        disabled={this.state.fee_price < 0}
                      // onClick={this.handlerSubmit}
                      >
                        ลงรายการ
                      </Button>
                    </Form.Field>
                  </Form>
                </div>
              ) : (
                  <div>
                    <Message color="red" attached header="ซื้อทอง" size="tiny" />
                    <Form
                      className="attached fluid segment red"
                      color="red"
                      size="small"
                      onSubmit={(e) => {
                        this.handlerSubmitBuy(e);
                      }}
                    >
                      <Form.Group>
                        <Form.Field width={16} error={this.state.msg_error.staff_product_stock != null}>
                          <label>*สินค้า <MsgInput text={this.state.msg_error.staff_product_stock} /></label>
                          <SelectOption
                            search selection width={4} options={this.state.stocks}
                            onRef={(c) => { }}
                            onChange={(e, d) => {
                              let item = Utility.getObjectByValue(this.state.stocks, d.value)
                              this.setState({
                                staff_product_stock: d.value,
                                buy_categories_id: item.category,
                                product_name: item.text,
                                max_weight: Number(item.weight),
                              });
                              const buy_weight_real_g = Number(item.weight)
                              const cate = Utility.getObject(this.props.formValue.categories, item.category);
                              if (cate != null) {
                                let b = buy_weight_real_g / Number(cate.weight) * Number(cate.m_buy);
                                this.setState({
                                  buy_weight_real_g: Number(item.weight),
                                  buy_weight_real_b: (buy_weight_real_g / cate.weight).toFixed(3),
                                })
                                let gold_rec = this.state.gold_rec2
                                if (!this.state.is_price_aag) {
                                  gold_rec = this.state.gold_rec1
                                }
                                let buy_price = (b * Number(Utility.removeCommas(this.props.gold_price.gold_bar_sell)));
                                buy_price = buy_price < 0 ? 0 : buy_price
                                this.setState({
                                  buy_price: buy_price.toFixed(0),
                                  buy_price_compare: buy_price.toFixed(0),
                                });
                              }
                            }
                            }
                            value={this.state.staff_product_stock}
                            defaultValue={this.state.staff_product_stock}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field
                          width={3}
                          id="percengold"
                          error={this.state.msg_error.buy_categories_id != null}
                        >
                          <label>
                            *%ทอง
                          <MsgInput text={this.state.msg_error.buy_categories_id} />
                          </label>
                          <Input value={this.state.buy_categories_id == ''
                            ? '' : Utility.getObjectByValue(this.props.formValue.state.categories, this.state.buy_categories_id).text}
                            readOnly
                            className='text-right'
                            placeholder='%ทอง'
                          />
                        </Form.Field>

                        <Form.Field
                          id="ProductName"
                          width={4}
                          error={this.state.msg_error.product_name != null}
                        >
                          <label>
                            *ชื่อสินค้า

                          <MsgInput text={this.state.msg_error.product_name} />
                          </label>

                          <Input
                            fluid
                            readOnly
                            list="products"
                            placeholder="ชื่อสินค้า"
                            maxLength={100}
                            value={this.state.product_name}
                            onChange={(e, v) => this.setState({ product_name: v.value })}
                            ref={(input) => {
                              this.productNameInput = input;
                            }}
                            onKeyPress={(e) => {
                              if (e.key == "Enter") {
                                this.weightRealInput.focus();
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Field>
                        <Form.Field
                          id="weight-g"
                          width={4}
                          error={this.state.msg_error.buy_weight_real_g != null}
                        >
                          <label>
                            *น.น.ซื้อ (ก.)
                          <MsgInput text={this.state.msg_error.buy_weight_real_g} />
                          </label>
                          <Input
                            placeholder="น.น.ซื้อ (ก.)"
                            maxLength={7}
                            ref={(c) => {
                              this.weightRealInput = c;
                            }}
                            onKeyPress={(e) => {
                              if (e.key == "Enter" && this.props.kind == "XC") {
                                this.changePriceInput.focus();
                                e.preventDefault();
                              }
                            }}
                            value={
                              this.state.focus_buy_weight
                                ? this.state.buy_weight_real_g.toString().replace(/,/g, "")
                                : this.state.buy_weight_real_g
                                  .toString()
                                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                            }
                            onChange={this.handlerBuyWeightG}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_buy_weight) {
                                  this.setState({
                                    focus_buy_weight: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={() => {
                              if (!this.state.focus_buy_weight) {
                                this.setState({
                                  focus_buy_weight: true,
                                });
                              }
                            }}
                            className="text-right"
                          />
                        </Form.Field>
                        <Form.Field
                          id="weight-b"
                          width={4}
                          error={this.state.msg_error.buy_weight_real_b != null}
                        >
                          <label>
                            *น.น.ซื้อ (บ.)
                          <MsgInput text={this.state.msg_error.buy_weight_real_b} />
                          </label>
                          <Input
                            placeholder="น.น.ซื้อ (บ.)"
                            maxLength={7}
                            width={3}
                            // onKeyPress={(e) => {
                            //   if (e.key == "Enter") {
                            //     this.buyPriceInput.focus();
                            //     e.preventDefault();
                            //   }

                            // }}
                            onKeyPress={(e) => {
                              if (e.key == "Enter" && this.props.kind == "XC") {
                                this.changePriceInput.focus();
                                e.preventDefault();
                              }
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_buy_bath) {
                                  this.setState({
                                    focus_buy_bath: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={() => {
                              if (!this.state.focus_buy_bath) {
                                this.setState({
                                  focus_buy_bath: true,
                                });
                              }
                            }}
                            value={
                              this.state.buy_weight_real_b == "" ||
                                this.state.buy_weight_real_b == null
                                ? ""
                                : this.state.focus_buy_bath
                                  ? this.state.buy_weight_real_b.toString().replace(/,/g, "")
                                  : this.state.buy_weight_real_b
                                    .toString()
                                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                            }
                            onChange={this.handlerBuyWeightB}
                            className="text-right"
                          />
                        </Form.Field>
                        <Form.Field id="money" width={3}>
                          <label>เป็นเงิน</label>
                          <Input
                            readOnly
                            placeholder="เป็นเงิน"
                            value={
                              this.state.focus_buy_price
                                ? this.state.buy_price.toString().replace(/,/g, "")
                                : this.state.buy_price
                                  .toString()
                                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                            }
                            // onFocus={(e) => { e.target.select() }}
                            ref={(e) => {
                              this.buyPriceInput = e;
                            }}
                            // onKeyPress={(e) => {
                            //   if (e.key == "Enter" && this.props.kind == "XC") {
                            //     this.changePriceInput.focus();
                            //     e.preventDefault();
                            //   }
                            // }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (this.state.focus_buy_price) {
                                  this.setState({
                                    focus_buy_price: false,
                                  });
                                }
                              }, 0);
                            }}
                            onFocus={() => {
                              if (!this.state.focus_buy_price) {
                                this.setState({
                                  focus_buy_price: true,
                                });
                              }
                            }}
                            onChange={this.handlerBuyPrice}
                            className="text-right"
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          label="ราคาทองวันนี้"
                          placeholder=""
                          width={4}
                          value={this.props.gold_price.gold_bar_sell
                            .toString()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          className="text-right"
                          readOnly
                        />
                        <Form.Input
                          label="ราคาซื้อคืนทองรูปพรรณ"
                          placeholder=""
                          width={4}
                          value={this.props.gold_price.gold_ornaments_buy
                            .toString()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          className="text-right"
                          readOnly
                        />
                        {/* <Form.Input
                        label="เป็นเงินเปลี่ยน"
                        placeholder=""
                        width={4}
                        readOnly
                        // value={(this.state.exchange-this.state.fee_price).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        className="text-right"
                      /> */}
                        <Form.Field width={3}>
                          <Checkbox
                            style={{ marginTop: "14%" }}
                            // toggle
                            readOnly
                            label={<label>ทองเอเอ </label>}
                            onChange={this.handleSeting}
                            checked={this.state.is_price_aag}
                            disabled={this.state.is_aagold_disabled}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field style={{ textAlign: "end" }}>
                        <Button
                          id="confirmProduct"
                          type="submit"
                          size="small"
                          primary
                        // onClick={this.handlerSubmit}
                        >
                          ลงรายการ
                      </Button>
                      </Form.Field>
                    </Form>
                  </div>
                )}
            </div>
            <br />
            <div id="table-price">
              <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={35}
                width={this.state.table_width}
                height={300}
              >
                <Column
                  header={<Cell></Cell>}
                  cell={
                    <IconCell data={items} onDelete={this.onDelete} />
                  }
                  width={50}
                  fixed={true}
                />
                <Column
                  header={<Cell style={textCenter}>ทองเอเอ</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="is_price_aag"
                      textAlign={"center"}
                      type="boolean"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell>ประเภท</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="type"
                      textAlign={"left"}
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell>รหัสสินค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="code"
                      textAlign={"left"}
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell>ชื่อสินค้า</Cell>}
                  cell={
                    <ItemsCell
                      position="top left"
                      width={120}
                      data={items}
                      field="product_name"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell style={textRight}>น้ำหนัก</Cell>}
                  cell={
                    <ItemsCell
                      position="top right"
                      width={120}
                      data={items}
                      field="weight"
                      type="weight"
                      style={textRight}
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell style={textRight}>ราคาขาย</Cell>}
                  cell={
                    <ItemsCell
                      style={textRight}
                      position="top left"
                      width={120}
                      data={items}
                      field="sell_price"
                      type="price"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell style={textRight}>ราคาซื้อ</Cell>}
                  cell={
                    <ItemsCell
                      style={textRight}
                      position="top left"
                      width={120}
                      data={items}
                      field="buy_price"
                      type="price"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell style={textRight}>ค่ากำเหน็จ</Cell>}
                  cell={
                    <ItemsCell
                      style={textRight}
                      position="top left"
                      width={120}
                      data={items}
                      field="fee"
                      type="price"
                    />
                  }
                  width={120}
                />
                <Column
                  header={<Cell style={textRight}>ราคาทอง/บาท</Cell>}
                  cell={
                    <ItemsCell
                      style={textRight}
                      position="top left"
                      width={120}
                      data={items}
                      field="gold_price"
                      type="price"
                    />
                  }
                  width={120}
                />
              </Table>
            </div>
            <br />
            <div>
              <Form onSubmit={(e) => {
                this.handlerSubmitExchange(e);
              }}>
                <Form.Group>
                  <Form.Field width={6}>
                    <label>ขายน้ำหนักรวม </label>
                    <Input
                      placeholder="ขายน้ำหนักรวม"
                      value={Utility.weightFormat(this.state.total_weight_sell)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>
                  <Form.Field width={6}>
                    <label>ราคารวมขาย </label>
                    <Input
                      placeholder="ราคารวมขาย"
                      value={Utility.priceFormat(this.state.total_price_sell)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>

                  <Form.Field width={8}>
                    <label>ค่ากำเหน็จ</label>
                    <Input
                      placeholder="ค่ากำเหน็จ"
                      value={Utility.priceFormat(this.state.total_fee)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>
                  <Form.Field width={6}>
                    <label>สรุปราคาขาย</label>
                    <Input
                      placeholder="สรุปราคาขาย"
                      value={Utility.priceFormat(this.state.total_price_sell_summary)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>
                  <Form.Field width={6} error={this.state.msg_error.exchange != null}>
                    <label>เป็นเงินเปลี่ยน <MsgInput text={this.state.msg_error.exchange} /></label>
                    <Input
                      placeholder="เป็นเงินเปลี่ยน"
                      value={Utility.priceFormat(this.state.exchange)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width={6}>
                    <label>ซื้อน้ำหนักรวม </label>
                    <Input
                      placeholder="ซื้อน้ำหนักรวม"
                      value={Utility.weightFormat(this.state.total_weight_buy)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>
                  <Form.Field width={6}>
                    <label>ราคารวมซื้อ </label>
                    <Input
                      placeholder="ราคารวมซื้อ"
                      value={Utility.priceFormat(this.state.total_price_buy)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>

                  <Form.Field width={8}>
                    <label style={{ display: "flex" }}>เป็นเงินส่วนต่าง<p style={{ color: Math.abs(this.state.weight_diff) > 0.3 ? "red" : "green", marginLeft: "2px" }}>({Number(this.state.weight_diff) <= 0 ? this.state.weight_diff : "+" + this.state.weight_diff} กรัม)</p></label>
                    <Input
                      // width={4}
                      maxLength={8}
                      placeholder="เงินส่วนต่าง"
                      value={
                        this.state.focus_exchange_diff
                          ? this.state.exchange_diff.toString().replace(/,/g, "")
                          : this.state.exchange_diff
                            .toString()
                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_exchange_diff) {
                            this.setState({
                              focus_exchange_diff: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={() => {
                        if (!this.state.focus_exchange_diff) {
                          this.setState({
                            focus_exchange_diff: true,
                          });
                        }
                      }}
                      // value={this.state.exchange_diff}
                      readOnly={this.props.auth.role == "U" || Math.abs(this.state.weight_diff) > 0.3}
                      onChange={(e, d) => {
                        let str = d.value.split('.')
                        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        str[1] = str[1] == undefined ? '' : '.' + str[1]
                        if (str.length != 1) {
                          if (str[1].length > 3) {
                            return;
                          }
                        }
                        d.value = d.value.toString().replace(/,/g, '')
                        let ex_diff = Utility.removeCommas(d.value)
                        if (isNaN(Number(ex_diff)) || Number(ex_diff) < 0) { }
                        else {
                          this.setState({ exchange_diff: ex_diff });
                          this.setState({
                            total_pay: Number(ex_diff) + Number(this.state.received),
                          });
                        }
                      }}
                      className="text-right"
                    />
                  </Form.Field>
                  <Form.Field width={6}>
                    <label>เงินที่ได้รับ</label>
                    <Input
                      maxLength={8}
                      placeholder="เงินที่ได้รับ"
                      value={
                        this.state.focus_received
                          ? this.state.received.toString().replace(/,/g, "")
                          : this.state.received
                            .toString()
                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_received) {
                            this.setState({
                              focus_received: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={() => {
                        if (!this.state.focus_received) {
                          this.setState({
                            focus_received: true,
                          });
                        }
                      }}
                      // value={this.state.received}
                      // readOnly
                      onChange={(e, d) => {
                        let str = d.value.split('.')
                        str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        str[1] = str[1] == undefined ? '' : '.' + str[1]
                        if (str.length != 1) {
                          if (str[1].length > 3) {
                            return;
                          }
                        }
                        d.value = d.value.length == 0 ? 0 : d.value
                        d.value = d.value.toString().replace(/,/g, '')
                        let received = Utility.removeCommas(d.value)
                        // received = received.length > 0 ? received : 0
                        if (isNaN(Number(received)) || Number(received) < 0) { }
                        else {
                          this.setState({ received: received });
                          this.setState({
                            total_pay: Number(this.state.exchange_diff) + Number(received),
                          });
                          this.setState({
                            exchange: Number(received) - Number(this.state.total_fee),
                          });
                        }
                      }}
                      ref={(c) => {
                        this.totalReceivedInput = c;
                      }}
                      onKeyPress={(e) => {
                        if (e.key == "Enter") {
                          this.handlerSubmitExchange(e);
                          e.preventDefault();
                        }
                      }}
                      className="text-right"
                    />
                  </Form.Field>
                  <Form.Field width={6}>
                    <label>สรุปยอดที่ต้องชำระ</label>
                    <Input
                      placeholder="สรุปยอดที่ต้องชำระ"
                      value={Utility.priceFormat(this.state.total_pay)}
                      readOnly
                      className="text-right"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="confirmProduct"
            type="submit"
            size="small"
            color="green"
            disabled={
              this.state.items.length <= 1
              ||
              (
                Math.abs(this.state.weight_diff) > 0.3
              )
              || !(this.state.kind_item.includes("SE") && this.state.kind_item.includes("BU"))
            }
            onClick={this.handlerSubmitExchange}
          >
            ลงรายการ
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        //e.preventDefault()
      },
    },
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(SellForm));
