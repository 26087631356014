/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Button, Modal,Dimmer,Loader
} from 'semantic-ui-react';
import {hotkeys} from 'react-keyboard-shortcuts'
import Utility from '../../Utility';
import Settings from '../../Settings';
var QRCode = require('qrcode.react');

class PrintPreviewModal extends Component {

    constructor(props){
        super(props)
        this.state = {
            is_loading: true,
            pdf_url: null
        }

        this.handlerPrint = this.handlerPrint.bind(this)
    }

    async componentDidMount(){
        const data = {
            items: JSON.stringify(this.props.items)
        }
        const res = await Utility.postAsync(Settings.url_genpdf_qrcode,data)

        if(res.status_code==200)
            this.setState({pdf_url: res.data.data.url,is_loading: false})
    }

    handlerPrint(){
        
        var printWindow = window.open('', 'PRINT','height=600,width=1024');
        printWindow.document.write('<html><body style="margin: 0;" ><embed width="100%" height="100%" type="application/pdf" src="' + this.state.pdf_url + '" ></embed></body></html>');
        // printWindow.document.close();
        // printWindow.document.open();
        // printWindow.document.write('<html><head><title>Print Preview</title></head><body>');
        // printWindow.document.write('<img src="' + this.state.pdf_url + '" onload="window.print(); window.close();" />');
        // printWindow.document.write('</body></html>');
        // printWindow.document.close();
        // mywindow.document.write('<style>@page {size: aa_gold_print_qr;margin: 0mm;}</style>');
        // mywindow.document.write('<html><head><title>' +this.props.title + '</title>');
        // mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
        // mywindow.document.write(document.getElementById('view-print').innerHTML);
        // mywindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
        }, 2000);

    }

    render() {
        const {items} = this.props

        return (<div>
            <Modal  open={true} onClose={this.close}  /*dimmer='blurring'*/>
            <Button
            id='closePreview'
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={this.props.onClose}/>
            <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>

                    <div >
                        {this.state.is_loading?
                            <div>
                                <Dimmer active>
                                    <Loader />
                                    </Dimmer>
                            </div>
                                :
                            <div>
                                <iframe id='view-print' style={{width: '100%', height: '400px'}} src={this.state.pdf_url}></iframe>
                            </div>}
                        {/* {items.map((item,i)=>{
                            // var temp = item.text
                            // var count_th = (temp.match(/[ก-ฮ0-9]/g) || []).length;

                            return (<div key={i} id='paper75x13' style={{ 'page-break-before': 'always' }}>
                                <table style={{width: '4.7cm',marginLeft:'0.10in',marginTop:'0.08in'}} >
                                    <tr>
                                        <td><QRCode value={item.qrcode} renderAs='svg' size='30' /></td>
                                        <td style={{'font-size': '7px','width': '5%'}}>{item.qrcode}</td>
                                        <td style={{'font-size': '7px',paddingLeft:'0.20in'}}>
                                            <div>{item.text.substring(0,15)}</div>
                                            <div>{`น.น.ทอง ${item.weight}ก.`}</div>
                                            <div>ห้างทองเอเอ</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>)
                        })} */}
                    </div>
                    
                </Modal.Content>
                <Modal.Actions>
                    <span style={{'float':'left','font-size':'10px',color:'red',whiteSpace:'pre-line'}}>*ตั้งค่า printer driver -> width:3.11 in / height:0.51 in / left:0.08 in / right:0.08 in</span>
                    <br/>
                    <span style={{'float':'left','font-size':'10px',color:'red'}}>*ตั้งค่า google chrome -> scale: 92 / margin: None</span>
                    <br/>
                </Modal.Actions>
                {/* <Modal.Actions>
                    <Button primary icon='print' size='small' onClick={this.handlerPrint} labelPosition='right' content='พิมพ์ (F8)' />
                </Modal.Actions> */}
            </Modal>
        </div>)
    }

    hot_keys = {
        'f8': {
          priority: 1,
          handler: (event) => {
            this.handlerPrint()
            event.preventDefault()
          }
        },
        'esc': {
            priority: 1,
            handler: (event) => {
                this.props.onClose()
              event.preventDefault()
            }
          }
      }
}

export default hotkeys(PrintPreviewModal)