import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Pagination
} from 'semantic-ui-react';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class StockOldGoldReportPrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: [],
            total_item: 0,
            page_size: 5,
            activePage: 1,
        }
  
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
        this.loadDataInterval = setInterval(() => {
        this.loadReportStockOldGold(this.state.activePage)
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.loadDataInterval)
    }

    componentDidMount() {
        this.loadReportStockOldGold(this.state.activePage);
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ activePage: activePage })
        this.loadReportStockOldGold(activePage);
    }

    loadReportStockOldGold(page) {
        let url = Settings.baseReportUrl + `/stock_old_gold_list/?page=${page}&page_size=${this.state.page_size}`
        this.setState({
            loading: true
        })
        Utility.get(url, (s, d) => {
                console.log(s, d)
            if (s == true) {
                this.setState({
                    loading: false,
                    total_item: d.count,
                    item: d.results,
                    total_page: d.next == null && d.previous == null ? 0 : Math.ceil(d.count / this.state.page_size),
                })
            }
        })
    }


    handlerSubmit(e) {
        e.preventDefault();
        const date_lt = Utility.formatDate2(moment())
        let url = Settings.baseUrl + "/stock_old_gold/" 
        this.setState({
            loading: true
        })
        Utility.post(url, { date_lt }, (s, d) => {
            
            this.loadReportStockOldGold(1);
            this.setState({
                loading: false,
                activePage: 1,
            })
        })
    }

    delReportStockOldGold(id) {
        if (window.confirm('ยืนยันลบ')) {
            let url = Settings.baseReportUrl + `/stock_old_gold_delete/${id}/`
            this.setState({
                loading: true
            })
            Utility.delete(url, (s, d) => {
                if (s == true) {
                    var tmppage =  this.state.item.length==1?this.state.activePage==1?1:this.state.activePage-1:this.state.activePage
                    this.loadReportStockOldGold(tmppage);
                    this.setState({
                        loading: false,
                        activePage: tmppage,
                    })
                }
            })
        }
    }
    

    render() {
        const tableStyle = {
            color: '#111',
            width: '100%',
            lineHeight: '20px',
        };
        const headerCellStyle = {
            textAlign: 'center',
            color: '#fff',
        }
        const tableHeaderStyle = {
            backgroundColor: '#db2828',
        }
        const textCenter = {
            textAlign: 'center'
        }
        return (
            <div>
                <Segment color='black' style={{
                    height: '90vh',
                    overflow: 'auto'
                }}>
                    <Form >
                        <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                                <Header as='h4'>รายงานสรุปมูลค่าสต็อกทองเก่า</Header>
                            </div>
                            <div style={{ marginRight: '5px'}}>
                                <Button color='blue' onClick={this.handlerSubmit}>สร้างรายงาน</Button>
                            </div>
                        </Form.Group>
                    </Form>
                    
                    {this.state.total_item > 0 &&
                        <>
                            <Table basic style={tableStyle}>
                                <Table.Header style={tableHeaderStyle}>
                                    <Table.Row>
                                        <Table.HeaderCell style={headerCellStyle} width={6}>ชื่อรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>วันที่ออกรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={3}>สถานะรายงาน</Table.HeaderCell>
                                        <Table.HeaderCell style={headerCellStyle} width={4}>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.item.map((item) => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.filename}</Table.Cell>
                                            <Table.Cell style={textCenter}>{`${Utility.formatDate(item.created_at)} ${Utility.formatTimeSecond(item.created_at)}`}</Table.Cell>
                                            <Table.Cell style={textCenter}>{item.status_display}</Table.Cell>
                                            <Table.Cell style={textCenter}>
                                                <Button onClick={()=>this.delReportStockOldGold(item.id)} color='red'><Icon name="trash"/>ลบ</Button>
                                                <Button target="__blank" href={`${Settings.mediaUrl}${item.subpath}`} color='blue' disabled={item.status!==1}><Icon name="cloud download"/>ดาวน์โหลด</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            <Form size='small'>
                                <Form.Group>
                                    <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.total_item)} />
                                    <Form.Field width={16}>
                                        <br />
                                        {this.state.total_page > 1 &&
                                            <Pagination
                                                floated='right'
                                                size='tiny'
                                                activePage={this.state.activePage}
                                                onPageChange={this.handlePaginationChange.bind(this)}
                                                totalPages={this.state.total_page}
                                            />
                                        }
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </>
                    }
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(StockOldGoldReportPrintPreview)