/*eslint eqeqeq: "off"*/
import Utility from '../Utility';
import Settings from '../Settings';
const productHelper = {

    getCode: (category, type, pattern, type_weight, weight, weight_g, type_sale,price_tag) => {

        //let price_tag = 25000
        let typ_w = type_weight == 1 ? 'B' : 'G'

        let w = type_weight == 1 ? parseInt(weight*10*10) : parseInt(Number(weight_g)*10*10) ;
        weight_g = Utility.weightFormat(weight_g)

        if (type_sale == 1) {
            
            let code = type.code + category.code + typ_w + w + '-' + pattern.code;
            let n_w = type_weight == 1 ? productHelper.getNameBaht(weight) : weight_g + ' ก.';
            let name = type.name + ' ' + n_w + ' (' + category.name + ')' + ' ' + pattern.name;
            
            return {
                name: name,
                code: code
            };
        } else {
            let code = type.code + category.code + 'F' + parseInt(price_tag) + '-' + pattern.code;
            // let n_w = type_weight == 1 ? productHelper.getNameBaht(weight) : weight_g + ' กรัม';
            let n_p = Utility.numberFormat(price_tag) + 'บาท'
            let name = type.name + ' (' + category.name + ')' + '' + pattern.name + ' ' + n_p;
            return {
                name: name,
                code: code
            };
        }
    },

    getNameBaht: (v) => {
        let o = Utility.getObjectByValue(Settings.weight, v);
        if (o == null) {
            return Utility.priceFormat(v) + 'บ.'
        } else {
            return o.text
        }
    }
}

export default productHelper