/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import InputSearchCustomer from '../../Customer/InputSearchCustomer';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                // branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            customer_name:'ทั้งหมด'
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
        Promise.all([branches]).then((values) => {
            this.branches = values[0];
            let branches = [{
                value : 0,
                key : 0,
                text : '-- ทั้งหมด --'
            }]
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {
        this.setState({
            search: {
                // branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            customer_name:'ทั้งหมด'
        })
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        if(search.branch == 0){
            delete search.branch;
        }
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseUrl + "/staff_product_stock_report/?" + fromData
        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.staff_product_stock_log,
                        d.staff_product_stock,d.branch,d.category,d.kind,d.bill_item,
                        d.product,d.customer,d.bill,d.approver)
                })
            }
        })
    }

    setFieldValue(staff_product_stock_log,staff_product_stock,branch,category,kind,bill_item,product,customer,bill,approver) {
        let items = []
        for (let i in staff_product_stock_log) {
            let item = Utility.cloneObjectJson(staff_product_stock_log[i])
            console.log(item.bill_item)
            const staff_product_stock_items = staff_product_stock.find(k => k.id === item.staff_product_stock)
            const branch_show = branch.find(k => k.id === staff_product_stock_items.branch)
            const category_show = category.find(k => k.id === staff_product_stock_items.category)
            const kind_show = kind.find(k => k.id === staff_product_stock_items.product_type)
            // const bill_item_show = bill_item.find(k => k.id === item.bill_item)
            // const bill_show = bill.find(k => k.id === bill_item_show.bill)
            // const product_show = product.find(k => k.id === bill_item_show.product)
            const customer_show = customer.find(k => k.id === staff_product_stock_items.customer)
            const approver_show = approver.find(k => k.id === item.approver)
            let product_code = ''
            let bill_number = ''
            if(item.bill_item != null){
                const bill_item_show = bill_item.find(k => k.id === item.bill_item)
                const product_show = product.find(k => k.id === bill_item_show.product)
                const bill_show = bill.find(k => k.id === bill_item_show.bill)
                product_code = product_show.code
                bill_number = bill_show.bill_number
            }
            
            item.branch_name = branch_show.name
            item.category_name = category_show.name
            item.kind_name = kind_show.name
            item.product_code = product_code
            item.staff_product_stock = staff_product_stock_items
            item.customers = customer_show
            item.bill_number = bill_number
            item.approvers = approver_show
            items.push(item)
        }
        return items
    }
    handlerInput(e, v,data) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
        if(data){
        this.setState({customer_name:data.name})
        }
        
    }
    render() {
        let data = this.props.data

        let title = 'รายงานพนักงานซื้อทอง';
       
        let filename = 'sell';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const staffWidth = {
            'width': '300px'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        
        let weight = 0
        for (let i in this.state.items) {
            weight += parseFloat(this.state.items[i].weight)
        }
        
        let branch_name = 'ทั้งหมด'
        if (this.state.search.branch && this.state.branches != null) {
            let b = Utility.getObjectByValue(this.state.branches, this.state.search.branch)
            if (b) {
                branch_name = b.text
            }
        } 
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานพนักงานซื้อทอง</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>ลูกค้า</label>
                                <InputSearchCustomer is_staff placeholder='ลูกค้า' width={16} onChange={this.handlerInput} name="customer" value={this.state.search.customer} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                <Table.HeaderCell colSpan='13'><center id='branch'>สาขา : {branch_name} ลูกค้า : {this.state.customer_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>กลุ่มสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ประเภทสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รหัสสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell >ชื่อสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนัก(กรัม)</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รหัสลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่บิล</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ผู้อนุมัติ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.items.map((row, i) => <Table.Row key={i}>
                                            <Table.Cell style={textCenter}>{Utility.formatDate(row.created_datetime)}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.branch_name}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.category_name}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.kind_name}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.product_code}</Table.Cell>
                                            <Table.Cell >{row.staff_product_stock.product_name}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight)}</Table.Cell>
                                            <Table.Cell style={textLeft, staffWidth}>{row.customers.name}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.customers.code}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.bill_number}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.approvers.name}</Table.Cell>
                                            
                                        </Table.Row>)}

                                        <Table.Row >
                                            <Table.Cell colSpan='10' style={textRight}><b>น้ำหนักรวม</b></Table.Cell>
                                            <Table.Cell style={textRight}><b><div id='amount' style={textU}>{Utility.weightFormat(weight)}</div></b></Table.Cell>
                                            <Table.Cell colSpan='1' style={textRight}></Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell colSpan='10' style={textRight}><b>จำนวนรวม</b></Table.Cell>
                                            <Table.Cell style={textRight}><b><div id='amount' style={textU}>{Utility.numberFormat(this.state.items.length)}</div></b></Table.Cell>
                                            <Table.Cell colSpan='1' style={textRight}></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)