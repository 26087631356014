/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
import { element } from 'prop-types';

class GroupRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        const data = this.props.data
        let kind_list = {}
        let kind_arr = []
        for (let i in data.items) {
            let item = Utility.cloneObjectJson(data.items[i])
            const product = this.props.product_list.find(k => k.id === item.invoice_item.product)
            const branch = this.props.branch_list.find(k => k.id === item.branch)
            const kind = this.props.kind_list.find(k => k.id === product.kind)
            item['product'] = product
            item['branch'] = branch
            if (kind_list[kind.id] == null)
                kind_list[kind.id] = {
                    items: [],
                    kind_name: kind.name,
                    n: 0,
                    weight: 0,
                    cost: 0,
                    fee: 0,
                    type_sale: item.product.type_sale
                }
            kind_list[kind.id].n += parseInt(item.after)
            kind_list[kind.id].weight += parseFloat(product.weight)
            kind_list[kind.id].cost += parseFloat(item.cost)
            kind_list[kind.id].fee += parseFloat(item.fee)
            kind_list[kind.id].items.push(item)
        }

        for (let i in kind_list) {
            let is_title = true

            kind_list[i].items.sort(function (first, second) {
                if (first.product.code < second.product.code) {
                    return -1;
                }
                if (first.product.code > second.product.code) {
                    return 1;
                }
                return 0;
            });
            kind_list[i].items.sort(function (first, second) {
                return first.product.weight - second.product.weight;
            });
            let total_after = 0
            let total_weight = 0
            let total_cost = 0
            let total_fee = 0

            let summary_total_after = 0
            let summary_total_cost = 0
            let summary_total_weight = 0
            let summary_total_fee = 0
            for (let j in kind_list[i].items) {
                summary_total_after += Number(kind_list[i].items[j].after)
                summary_total_cost += Number(kind_list[i].items[j].cost)
                summary_total_fee += Number(kind_list[i].items[j].fee)
                summary_total_weight += Number(kind_list[i].items[j].weight)
                if (is_title)
                    kind_arr.push({
                        is_title: is_title,
                        title: kind_list[i].kind_name
                    });
                is_title = false
                kind_arr.push(kind_list[i].items[j])

                if (j == 0) {
                    total_after = parseInt(kind_list[i].items[j].after)
                    total_weight = parseFloat(kind_list[i].items[j].weight)
                    total_cost = parseFloat(kind_list[i].items[j].cost)
                    total_fee = parseFloat(kind_list[i].items[j].fee)
                } else if (j != 0) {
                    if (Number(kind_list[i].items[j].product.weight) != Number(kind_list[i].items[Number(j) - 1].product.weight)) {
                        total_after = parseInt(kind_list[i].items[j].after)
                        total_weight = parseFloat(kind_list[i].items[j].weight)
                        total_cost = parseFloat(kind_list[i].items[j].cost)
                        total_fee = parseFloat(kind_list[i].items[j].fee)
                    } else {
                        total_after += parseInt(kind_list[i].items[j].after)
                        total_weight += parseFloat(kind_list[i].items[j].weight)
                        total_cost += parseFloat(kind_list[i].items[j].cost)
                        total_fee += parseFloat(kind_list[i].items[j].fee)
                    }

                }

                if (Number(j) + 1 < kind_list[i].items.length) {
                    if (Number(kind_list[i].items[j].product.weight) != Number(kind_list[i].items[Number(j) + 1].product.weight)) {
                        kind_arr.push({
                            is_sum: true,
                            total_after: total_after,
                            total_weight: total_weight,
                            total_cost: total_cost,
                            total_fee: total_fee,
                        })
                    }
                } else {
                    kind_arr.push({
                        is_sum: true,
                        total_after: total_after,
                        total_weight: total_weight,
                        total_cost,
                        total_fee,
                    })
                }
            }
            kind_arr.push({
                is_sum_total: true,
                sum: 'รวม ' + kind_list[i].kind_name,
                summary_total_after: summary_total_after,
                summary_total_weight: summary_total_weight,
                summary_total_cost: summary_total_cost,
                summary_total_fee: summary_total_fee,
            })

        }

        return (<Table.Body>
            <Table.Row>
                <Table.Cell colSpan='2' style={textLeft}><b>{data.title}</b></Table.Cell>
                <Table.Cell colSpan='10'></Table.Cell>
            </Table.Row>
            {kind_arr.map((row, i) => row.is_title ? <Table.Row>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textLeft}>{row.title}</Table.HeaderCell>
                <Table.HeaderCell colSpan='10'></Table.HeaderCell>
            </Table.Row> :
                row.is_sum ? <Table.Row>
                    <Table.HeaderCell ></Table.HeaderCell>
                    <Table.HeaderCell ></Table.HeaderCell>
                    <Table.HeaderCell ></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><b>รวม</b></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(row.total_after)}</div></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(row.total_weight)}</div></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row.total_cost)}</div></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row.total_fee)}</div></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}></div></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(Number(row.total_fee) + Number(row.total_cost))}</div></Table.HeaderCell>
                    <Table.HeaderCell style={textRight}><div style={textU}></div></Table.HeaderCell>
                </Table.Row> :
                    row.is_sum_total ?
                        <Table.Row>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b>{row.sum}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.numberFormat(row.summary_total_after)}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.weightFormat(row.summary_total_weight)}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(row.summary_total_cost)}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(row.summary_total_fee)}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}></b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(row.summary_total_fee + row.summary_total_cost)}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}></b></Table.HeaderCell>

                        </Table.Row> :
                        <Table.Row id={row.product.code}>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell style={textLeft}>&nbsp;&nbsp;{row.product.name}</Table.HeaderCell>
                            <Table.HeaderCell style={textCenter}>{row.product.code}</Table.HeaderCell>
                            <Table.HeaderCell style={textRight}>{Utility.weightFormat(row.product.weight)}</Table.HeaderCell>
                            <Table.HeaderCell style={textRight}>{Utility.numberFormat(row.after)}</Table.HeaderCell>
                            <Table.HeaderCell style={textRight}>{Utility.weightFormat(Utility.removeCommas(row.weight))}</Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(Utility.removeCommas(row.cost))}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(Utility.removeCommas(row.fee))}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>
                                {Utility.priceFormat(
                                    row.type_sale == '1'
                                        ? ((Number(row.fee) + Number(row.cost)) / Number(row.weight))
                                        : ((Number(row.fee) + Number(row.cost)) / Number(row.after))
                                )}
                            </b></Table.HeaderCell>
                            <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(Number(Utility.removeCommas(row.cost)) + Number(Utility.removeCommas(row.fee)))}</b></Table.HeaderCell>
                            <Table.HeaderCell style={textCenter}>&nbsp;&nbsp;{this.props.branch_name}</Table.HeaderCell>
                        </Table.Row>

            )}
            <Table.Row>
                <Table.HeaderCell colSpan='4' style={textRight}>รวม {data.title}</Table.HeaderCell>
                <Table.HeaderCell style={textRight}><div id={'amount_' + data.id} style={textU}>{Utility.numberFormat(data.n)}</div></Table.HeaderCell>
                <Table.HeaderCell style={textRight}><div id={'weight_' + data.id} style={textU}>{Utility.weightFormat(data.weight)}</div></Table.HeaderCell>
                <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(data.cost)}</b></Table.HeaderCell>
                <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(data.fee)}</b></Table.HeaderCell>
                <Table.HeaderCell ></Table.HeaderCell>
                <Table.HeaderCell style={textRight}><b style={textU}>{Utility.priceFormat(data.fee + data.cost)}</b></Table.HeaderCell>
                <Table.HeaderCell ></Table.HeaderCell>
            </Table.Row>

        </Table.Body>)
    }
}


class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                date_lt: moment(),
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                stock_type : 'all'
            },
            stock_type: [
                {
                    value: 'all',
                    key: 'aagold_all',
                    text: 'สต็อกทองทั้งหมด'
                },
                {
                    value: 'aagold_own',
                    key: 'aagold_own',
                    text: 'สต็อกทองAA'
                },
                {
                    value: 'aagold_borrow',
                    key: 'aagold_borrow',
                    text: 'สต็อกทองยืม'
                }
            ],
            name_stock:'',
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([branches, staffs, product_types]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }

            this.product_types = values[2];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                product_types: product_types
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_date = url.searchParams.get("date_lt");
        if (temp_date !== null) {
            this.setState({
                search: {
                    date_lt: moment(temp_date, 'DD/MM/YYYY'),
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                date_lt: moment(),
                stock_type : 'all'
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date_lt = Utility.formatDate2(search.date_lt)
        search.acc = true
        let fromData = Utility.jsonToQueryString(search)
        if (search.stock_type == 'all') {
            let url = Settings.baseReportUrl + "/stock_product_report/0/items?" + fromData

            this.setState({
                open: true,
                loading: true
            })
            Utility.get(url, (s, d) => {
                if (s == true) {
                    this.setState({
                        loading: false,
                        items: this.setFieldValue(d.stock_product),
                        product: this.setFieldValue(d.product),
                        category: this.setFieldValue(d.category),
                        kind: this.setFieldValue(d.kind),
                        branch: this.setFieldValue(d.branch),
                    })
                }
            })
        } else {
            let url = Settings.baseReportUrl + "/stock_borrow_product_acc_report/?" + fromData
            this.setState({
                open: true,
                loading: true
            })
            Utility.get(url, (s, d) => {
                if (s == true) {
                    this.setState({
                        loading: false,
                        items1: this.setFieldValue(d.stock_product),
                        product1: this.setFieldValue(d.product),
                        category1: this.setFieldValue(d.category),
                        kind1: this.setFieldValue(d.kind),
                        branch1: this.setFieldValue(d.branch),
                        stock_borrow1: this.setFieldValue(d.stock_borrow),
                        new_product1: this.setFieldValue(d.new_product)
                    })
                }
            })
        }
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            items.push(item)
        }
        return items
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let data = this.props.data

        let title = 'รายงานสต็อกสินค้า';
        let filename = 'stock';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let category_list = {}
            let n = 0
            let weight = 0
            let cost = 0
            let fee = 0

            let items = []
            let items2 = []
            let product_list = {}
            let kind_list = {}
            let n_borrow=0
            let weight_borrow = 0
        if (this.state.search.stock_type == 'all') {
            
            for (let i in this.state.items) {
                if (items.find(element => element.invoice_item.id == this.state.items[i].invoice_item.id) == undefined) {
                    items.push(this.state.items[i])
                }
            }

            for (let i in items) {

                let item = items[i]
                const product = this.state.product.find(k => k.id === item.invoice_item.product)
                const category = this.state.category.find(k => k.id === product.category)
                const new_product = item.invoice_item

                if (category_list[product.category] == null)
                    category_list[product.category] = {
                        items: [],
                        items_code: [],
                        title: category.name,
                        id: product.category,
                        n: 0,
                        weight: 0,
                        cost: 0,
                        fee: 0,
                    }

                category_list[product.category].n += parseInt(item.after)
                category_list[product.category].weight += parseInt(item.after) * parseFloat(product.weight)
                category_list[product.category].cost += parseInt(item.after) * parseFloat(new_product.cost)
                category_list[product.category].fee += parseInt(item.after) * parseFloat(new_product.fee)
                if (category_list[product.category].items[product.code] == null) {
                    category_list[product.category].items[product.code] = item
                    category_list[product.category].items[product.code].type_sale = product.type_sale
                    category_list[product.category].items[product.code].after = parseInt(item.after)
                    category_list[product.category].items[product.code].weight = parseInt(item.after) * parseFloat(product.weight)
                    category_list[product.category].items[product.code].cost = parseInt(item.after) * parseFloat(new_product.cost)
                    category_list[product.category].items[product.code].fee = parseInt(item.after) * parseFloat(new_product.fee)
                    category_list[product.category].items_code.push(product.code)
                } else {
                    category_list[product.category].items[product.code].after += parseInt(item.after)
                    category_list[product.category].items[product.code].weight += parseInt(item.after) * parseFloat(product.weight)
                    category_list[product.category].items[product.code].cost += parseInt(item.after) * parseFloat(new_product.cost)
                    category_list[product.category].items[product.code].fee += parseInt(item.after) * parseFloat(new_product.fee)
                }

                weight += (parseInt(item.after) * parseFloat(product.weight))
                n += parseInt(item.after)
                cost += (parseInt(item.after) * parseFloat(new_product.cost))
                fee += (parseInt(item.after) * parseFloat(new_product.fee))

            }

            
            for (let i in category_list) {
                items2.push(category_list[i])
            }
        } else {
            for (let i in this.state.items1) {
                let item = this.state.items1[i]
                const product = this.state.product1.find(k => k.id === item.invoice_item.product)
                const category = this.state.category1.find(k => k.id === product.category)
                
                if(product_list[product.id] == null){
                    product_list[product.id] = {
                        product_code: product.code,
                        balance:0,
                        weight:0,
                        product_id:product.id,
                        category_id:category.id,
                        product_name:product.name,
                        product_kind:product.kind,
                        weight_real:product.weight,
                        product_type_sale:product.type_sale,
                    }
                }
                product_list[product.id].balance += parseInt(item.after)
                product_list[product.id].weight += parseFloat(item.invoice_item.weight)
            }

            let items_product = []
            for (let i in product_list) {
                items_product.push(product_list[i])
            }
            if(items_product.length > 0) {
            items_product.sort(function (first, second) {
                if (first.product_code < second.product_code) {
                    return -1;
                }
                if (first.product_code > second.product_code) {
                    return 1;
                }
                return 0;
            });
            items_product.sort(function (first, second) {
                return first.weight_real - second.weight_real;
            });
        }
            for(let i in items_product) {
                let item = items_product[i]
                const stock = this.state.stock_borrow1.find(k => k.stock_borrow_product.product === item.product_id)
                const new_products = this.state.new_product1.filter(k => k.product === item.product_id);
                let stock_borrow = 0
                let stock_aa = item.balance
                if(stock) {
                    stock_borrow = stock.balance
                    stock_aa = 0
                    if(item.balance >= stock.balance) {
                        stock_aa = item.balance - stock.balance
                    }
                    stock_borrow = item.balance - stock_aa
                
                } 
                if (this.state.search.stock_type == 'aagold_own') {
                    item.balance = stock_aa
                } else {
                    item.balance = stock_borrow
                }
                
                let cost_product = 0
                let fee_product = 0
                for (let a = 0; a < item.balance; a++) {
                    cost_product += parseFloat(new_products[a].cost)
                    fee_product += parseFloat(new_products[a].fee)
                }
                item.cost_product = cost_product
                item.fee_product = fee_product
            }
    
            for(let i in items_product) {
                
                const item = items_product[i]
                n += parseInt(item.balance)
                weight += parseFloat(item.balance) * parseFloat(item.weight_real)
                cost += parseFloat(item.cost_product)
                fee += parseFloat(item.fee_product)
                const kind = this.state.kind1.find(k => k.id === item.product_kind)
                if(kind_list[kind.id] == null){
                    kind_list[kind.id] = {
                        kind_name : kind.name,
                        items : [],
                        category_id:item.category_id,
                        type_sale:item.product_type_sale,
                        it:[]
                    }
                }
                kind_list[kind.id].items.push(item)
            }
            let kinds = []
            for(let i in kind_list){
                let total_balance = 0
                let total_weight = 0
                let total_cost = 0
                let total_fee = 0
                let balance_total = 0
                let weight_total = 0
                let cost_product_total = 0
                let fee_product_total = 0
                for(let j in kind_list[i].items) {
                    kind_list[i].it.push(kind_list[i].items[j])
                    balance_total += kind_list[i].items[j].balance
                    cost_product_total += kind_list[i].items[j].cost_product
                    fee_product_total += kind_list[i].items[j].fee_product
                    weight_total += kind_list[i].items[j].weight_real * kind_list[i].items[j].balance
                    if (j == 0) {
                        total_balance = parseInt(kind_list[i].items[j].balance)
                        total_weight = parseFloat(kind_list[i].items[j].weight_real) * parseFloat(kind_list[i].items[j].balance)
                        total_cost = parseFloat(kind_list[i].items[j].cost_product)
                        total_fee = parseFloat(kind_list[i].items[j].fee_product)
                    } else if (j != 0) {
                        
                        if (Number(kind_list[i].items[j].weight_real) != Number(kind_list[i].items[Number(j) - 1].weight_real)) {
                            total_balance = parseInt(kind_list[i].items[j].balance)
                            total_weight = parseFloat(kind_list[i].items[j].weight_real)* parseFloat(kind_list[i].items[j].balance)
                            total_cost = parseFloat(kind_list[i].items[j].cost_product)
                            total_fee = parseFloat(kind_list[i].items[j].fee_product)
                        } else {
                            total_balance += parseInt(kind_list[i].items[j].balance)
                            total_weight += (parseFloat(kind_list[i].items[j].weight_real)* parseFloat(kind_list[i].items[j].balance))
                            total_cost += parseFloat(kind_list[i].items[j].cost_product)
                            total_fee += parseFloat(kind_list[i].items[j].fee_product)
                        }
    
                    }

                        if (Number(j) + 1 < kind_list[i].items.length) {
                        if (Number(kind_list[i].items[j].weight_real) != Number(kind_list[i].items[Number(j) + 1].weight_real)) {
                            kind_list[i].it.push({
                                is_weight_sum: true,
                                total_balance: total_balance,
                                total_weight: total_weight,
                                total_cost: total_cost,
                                total_fee: total_fee,
                            })

                        }
                    } else {
                        kind_list[i].it.push({
                            is_weight_sum: true,
                            total_balance: total_balance,
                            total_weight: total_weight,
                            total_cost,
                            total_fee,
                        })
                    }
                }
                kind_list[i].it.push({is_sum:true,balance_total:balance_total,weight_total:weight_total,cost_product_total,fee_product_total})
                kinds.push(kind_list[i])
            }

            for(let i in kind_list) {
                const item = kind_list[i]
                const category = this.state.category1.find(k => k.id === item.category_id)
                
                if(category_list[category.id] == null){
                    category_list[category.id] = {
                        category_name : category.name,
                        items : [],
                    }
                }
                category_list[category.id].items.push(item)
            }
    
            for (let i in category_list) {
                items2.push(category_list[i])
            }
        }  

        let sum_total = 0;
        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }
        const found = this.state.stock_type.find(element => element.value === this.state.search.stock_type);
        const titles = 'รายงานสต็อกสินค้า ('+found.text+')'
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานสต็อกสินค้า</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field>
                                <label>ประเภทสต็อก</label>
                                <Dropdown placeholder='- ทั้งหมด -' search selection options={this.state.stock_type} value={this.state.search.stock_type} name='stock_type' onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.date_lt}
                                    selected={this.state.search.date_lt}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'date_lt',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='11'><center>{titles}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='11'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell style={textCenter} colSpan='11'><center id='date'>ประจำวันที่ : {Utility.formatDate(this.state.search.date_lt)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2' >ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >รหัสสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น.น./ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >น.น.รวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >มูลค่าทองรวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >มูลค่าค่าแรงรวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >ต้นทุนเฉลี่ยต่อกรัม/ต้นทุนต่อชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} >มูลค่ารวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >สาขา</Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Header>
                                    {this.state.search.stock_type == 'all' && items2.map((row, i) => <GroupRow key={i} data={row} product_list={this.state.product} kind_list={this.state.kind} branch_list={this.state.branch} branch_name={branch_name}/>)}
                                    {
                                       (this.state.search.stock_type == 'aagold_own' || this.state.search.stock_type == 'aagold_borrow') && items2.map((row,i)=>
                                        {
                                            let total_balance_category = 0
                                            let total_weight_category = 0
                                            let total_cost_product_category = 0
                                            let total_fee_product_category = 0
                                            return(<><Table.Row>
                                                <Table.HeaderCell colSpan='21'><b>{row.category_name}</b></Table.HeaderCell>
                                            </Table.Row>
                                            {row.items.map((row2,i2) => {
                                                let sum_balance = 0
                                                let sum_weight = 0
                                                return (
                                                    <>
                                                    <Table.Row>
                                                        <Table.HeaderCell  colSpan='21' ><b>{row2.kind_name}</b></Table.HeaderCell>
                                                    </Table.Row>
                                                    {row2.it.map((row3,i)=> {
                                                        if(row3.is_sum == true) {
                                                            total_balance_category += row3.balance_total
                                                            total_weight_category += row3.weight_total
                                                            total_cost_product_category += row3.cost_product_total
                                                            total_fee_product_category += row3.fee_product_total
                                                        } 
                                                        
                                                        return (
                                                            <>
                                                                {row3.is_sum == true ? 
                                                                <Table.Row>
                                                                    <Table.HeaderCell  colSpan='3' ></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><b>รวม {row2.kind_name}</b></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(row3.balance_total)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(row3.weight_total)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row3.cost_product_total)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row3.fee_product_total)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row3.cost_product_total + row3.fee_product_total)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell ></Table.HeaderCell>
                                                                </Table.Row> : 
                                                                row3.is_weight_sum == true ? 
                                                                <Table.Row>
                                                                    <Table.HeaderCell  colSpan='3' ></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><b>รวม</b></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(row3.total_balance)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(row3.total_weight)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row3.total_cost)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row3.total_fee)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}></Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(row3.total_cost + row3.total_fee)}</div></Table.HeaderCell>
                                                                    <Table.HeaderCell ></Table.HeaderCell>
                                                                </Table.Row> :
                                                                <>
                                                                <Table.Row>
                                                                    <Table.HeaderCell colSpan='2' >{row3.product_name}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textCenter}>{row3.product_code}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>{Utility.weightFormat(row3.weight_real)}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>{Utility.numberFormat(row3.balance)}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>{Utility.weightFormat(row3.balance * row3.weight_real)}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>{Utility.priceFormat(row3.cost_product)}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>{Utility.priceFormat(row3.fee_product)}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>
                                                                        {/* {Utility.priceFormat(row3.cost_product / row3.balance)} */}
                                                                        {Utility.priceFormat(
                                                                            row.type_sale == 1
                                                                                ? ((Number(row3.fee_product) + Number(row3.cost_product)) / Number(row3.balance * row3.weight_real))
                                                                                : ((Number(row3.fee_product) + Number(row3.cost_product)) / Number(row3.balance))
                                                                        )}
                                                                        </Table.HeaderCell>
                                                                    <Table.HeaderCell style={textRight}>{Utility.priceFormat(row3.cost_product + row3.fee_product)}</Table.HeaderCell>
                                                                    <Table.HeaderCell style={textCenter}>{branch_name}</Table.HeaderCell>
                                                                </Table.Row>
                                                                </>}
                                                            </>
                                                        )
                                                    })}
                                                    </>
                                                )
                                            })}
                                            <Table.Row>
                                                <Table.HeaderCell  colSpan='3' ></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><b>รวม {row.category_name}</b></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.numberFormat(total_balance_category)}</b></div></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.weightFormat(total_weight_category)}</b></div></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_cost_product_category)}</b></div></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_fee_product_category)}</b></div></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><div style={textU}><b></b></div></Table.HeaderCell>
                                                <Table.HeaderCell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_cost_product_category + total_fee_product_category)}</b></div></Table.HeaderCell>
                                                <Table.HeaderCell ></Table.HeaderCell>
                                            </Table.Row>
                                            </>
                                            )
                                        }
                                        )
                                    }
                                    <Table.Row>
                                        <Table.Cell colSpan='13' style={textRight}></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='3' style={textRight}></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>รวมทั้งหมด</Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><div style={textU}>{Utility.numberFormat(n)}</div></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><div style={textU}>{Utility.weightFormat(weight)}</div></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(cost)}</div></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(fee)}</div></Table.HeaderCell>
                                        {/* <Table.HeaderCell style={textRight}><div style={textU}>{this.state.search.stock_type == 'aagold_own' || this.state.search.stock_type == 'aagold_borrow' ? Utility.priceFormat(cost/n): ''}</div></Table.HeaderCell> */}
                                        <Table.HeaderCell ></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(fee + cost)}</div></Table.HeaderCell>
                                        <Table.HeaderCell ></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell colSpan='13' style={textRight}></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='3' style={textRight}></Table.HeaderCell>
                                        <Table.HeaderCell style={textRight}>รวมต้นทุนเฉลี่ยต่อกรัม/ต้นทุนต่อชิ้น</Table.HeaderCell>
                                        <Table.HeaderCell colSpan='9' style={textRight}><div style={textU}>{weight === 0 ? Utility.priceFormat(0):Utility.priceFormat((fee + cost)/weight)}</div></Table.HeaderCell>
                                    </Table.Row>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)