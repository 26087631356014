/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
  Form, 
  Segment, 
  Header,
  Grid, 
  Menu,
  Icon,
} from 'semantic-ui-react';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';
import Settings from '../../Settings';
import Utility from '../../Utility';
import SearchByNumber from './SearchByNumber'

import {activations_branch} from '../../actions'
// import LeaseOut from './LeaseOut'
import Saving from './Saving'
// import LeaseForm from './LeaseForm'
// import LeaseStock from './LeaseStock'
import { connect } from 'react-redux'
// import DropdownBranch from '../Widget/DropDown/DropDownBranch'
import {lease_modal_edit} from '../../actions'
// import LeaseSearchByNumber from './LeaseSearchByNumber'

class Savings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: window.location.pathname
    }

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, { name, to }) {
    this.setState({
      activeItem: to
    });
  }

  handleBranchChange(e,{value}){
    const {branches} = this.props
    const branch = branches.find((item)=>item.id==value)
    this.props.handleBranchChange(branch)
  }

  render() {
    const {auth} = this.props
    const {role,branch} = auth
    const {lease_modal} = this.props
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={7}>
              <Header floated='left' as='h3'>
                <div style={{padding: '7px'}} className="pull-left"><Icon name='money'  /> ออมทอง </div>
                {/* <div className="pull-left">{role=='A' || role=='M' || role=='S' ? <DropdownBranch  fluid size='small' fluid selection onChange={this.handleBranchChange.bind(this)} name="branch" value={branch.id}  />:''}</div> */}
              </Header>
            </Form.Field>
            
          </Form.Group>
        </Form>
        <Router>
          <Grid>
            <Grid.Column width={3}>
              <Segment color='black'>
                <Menu secondary vertical className='menu-left'>
                  <Menu.Item 
                    id='btnSavingPOS'
                    name='lease'
                    active={this.state.activeItem == '/saving/pos'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/saving/pos'
                  >
                    บัญชีออมทอง
      </Menu.Item>

                  <Menu.Item
                    id='btnSavingDeposit'
                    name='lease'
                    active={this.state.activeItem == '/saving/deposit'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/saving/deposit'
                  >
                    ฝากเงิน
      </Menu.Item>

                  <Menu.Item
                    id='btnSavingWithdraw'
                    name='lease'
                    active={this.state.activeItem == '/saving/withdraw'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/saving/withdraw'
                  >
                    ถอนเงิน
      </Menu.Item>
                  {/* <Menu.Item
                    id='btnSavingClose'
                    name='out'
                    active={this.state.activeItem == '/saving/close'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/saving/close'
                  >
                    ปิดบัญชี
      </Menu.Item> */}
                </Menu>
              </Segment>
            </Grid.Column>
            <Grid.Column stretched width={13} className="content-body" >
              <Segment color='black'>

                <Route path="/saving/pos" component={() => (<Saving action='pos' />)} />
                <Route path="/saving/deposit" component={() => (<SearchByNumber action='deposit' title='ฝากเงิน'   />)} />
                <Route path="/saving/withdraw" component={() => (<SearchByNumber action='withdraw' title='ถอนเงิน'  />)} />
                {/* <Route path="/saving/close" component={() => (<SearchByNumber action='close' title='ปิดบัญชี'/>)} /> */}

              </Segment>
            </Grid.Column>
          </Grid>
        </Router>
      </div>
    );
  }
}


const mapStateToProps = state =>{
  return ({
    //lease_modal: state.lease_modal,
    auth: state.auth,
    branches: state.branches
  })
}
const mapDispatchToProps = dispatch => ({
  handleBranchChange: (branch) => {
    dispatch(activations_branch(dispatch,branch))
  },
//   handleOpenLease: (lease)=>{
//     lease_modal_edit(dispatch,lease.id)
//   }
  
})
export default connect(
  mapStateToProps,mapDispatchToProps
)(Savings)

