/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment, Header, Dimmer, Loader, Icon,  Label, Table
} from 'semantic-ui-react';
import moment from 'moment';
import Settings from '../../Settings';
import Utility from '../../Utility';
import ModalGoldPrice from '../GoldPrice/ModalGoldPrice'

class Gole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gold_bar_sell: '',
            gold_bar_buy: '',
            gold_ornaments_buy: '',
            record_date: moment(),
            open_gold: false
        }

        this.onSubmit = this.onSubmit.bind(this);
        let goldpric = localStorage.getItem("goldpric");
        if (goldpric != null && goldpric != '' && goldpric.record_date != null) {
            this.state = goldpric;
            this.state.open_gold = false
        }

    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({
            visible: true
        })

        setTimeout(() => {
            this.setState({
                visible: false
            })
        }, 1000)
        if (this.props.onSubmit) {
            this.props.onSubmit()
        }
    }

    componentDidMount() {
        Utility.get(Settings.baseUrl + '/gold_price/?limit=30', (e, resJson) => {

            if (e == true) {
                let last = resJson[0]
                localStorage.setItem("goldpric", JSON.stringify(last));
                let data = {
                    is_ready: true,
                    gold_bar_buy: last.gold_bar_buy,
                    diff_gold_bar_buy: '',
                    gold_bar_sell: last.gold_bar_sell,
                    diff_gold_bar_sell: '',
                    gold_ornaments_buy: last.gold_ornaments_buy,
                    diff_gold_ornaments_buy: '',
                    gold_ornaments_sell: last.gold_ornaments_sell,
                    diff_gold_ornaments_sell: ''
                }

                for (let i in resJson) {
                    let d = resJson[i]
                    let is_diff = false;
                    if (last.gold_bar_buy == d.gold_bar_buy
                        && last.gold_bar_sell == d.gold_bar_sell
                        && last.gold_ornaments_buy == d.gold_ornaments_buy
                        && last.gold_ornaments_sell == d.gold_ornaments_sell) {
                        continue
                    }

                    if (last.gold_bar_buy != d.gold_bar_buy) {
                        is_diff = true
                        let diff = parseInt(last.gold_bar_buy - d.gold_bar_buy);
                        data.diff_gold_bar_buy = d.gold_bar_buy < last.gold_bar_buy ? <Icon name='caret up' color='green' >{diff}</Icon> : <Icon name='caret down' color='red' >{diff}</Icon>
                    }
                    if (last.gold_bar_sell != d.gold_bar_sell) {
                        is_diff = true
                        let diff = parseInt(last.gold_bar_sell - d.gold_bar_sell)
                        data.diff_gold_bar_sell = d.gold_bar_sell < last.gold_bar_sell ? <Icon name='caret up' color='green' >{diff}</Icon> : <Icon name='caret down' color='red' >{diff}</Icon>
                    }
                    if (last.gold_ornaments_buy != d.gold_ornaments_buy) {
                        is_diff = true
                        let diff = parseInt(last.gold_ornaments_buy - d.gold_ornaments_buy)
                        data.diff_gold_ornaments_buy = d.gold_ornaments_buy < last.gold_ornaments_buy ? <Icon name='caret up' color='green' >{diff}</Icon> : <Icon name='caret down' color='red' >{diff}</Icon>
                    }
                    if (last.gold_ornaments_sell != d.gold_ornaments_sell) {
                        is_diff = true
                        let diff = parseInt(last.gold_ornaments_sell - d.gold_ornaments_sell)
                        data.diff_gold_ornaments_sell = d.gold_ornaments_sell < last.gold_ornaments_sell ? <Icon name='caret up' color='green' >{diff}</Icon> : <Icon name='caret down' color='red' >{diff}</Icon>
                    }

                    if (is_diff)
                        break
                }

                this.setState(data)
            }
        });
    }


    render() {
        return (
            <Segment >

                <Dimmer active={!this.state.is_ready} inverted>
                                            <Loader inverted>Loading..</Loader>
                                        </Dimmer> 
                <div style={{ float: 'right' }}>
                    <Label as='span' basic><Icon name="clock" /><i>{Utility.formatDate(this.state.record_date)} {Utility.formatTime(this.state.record_date)}</i></Label>
                    <Label as='a' basic onClick={() => { this.setState({ open_gold: true }) }}><center><Icon name='table' /></center></Label>
                </div>
                <Header floated='left' as='h2'>ราคาทอง</Header>
                <Table basic='very' celled collapsing style={{ width: '100%' }}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>ราคาซื้อทองคำแท่ง</Table.Cell>
                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.gold_bar_buy)}</div></Table.Cell>
                            <Table.Cell><center>{this.state.diff_gold_bar_buy}</center></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>ราคาขายทองคำแท่ง</Table.Cell>
                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.gold_bar_sell)}</div></Table.Cell>
                            <Table.Cell><center>{this.state.diff_gold_bar_sell}</center></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>ราคาซื้อคืนทองรูปพรรณ</Table.Cell>
                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.gold_ornaments_buy)}</div></Table.Cell>
                            <Table.Cell><center>{this.state.diff_gold_ornaments_buy}</center></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>ราคาขายทองรูปพรรณ</Table.Cell>
                            <Table.Cell ><div className='text-right'>{Utility.priceFormat(this.state.gold_ornaments_sell)}</div></Table.Cell>
                            <Table.Cell><center>{this.state.diff_gold_ornaments_sell}</center></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                {this.state.open_gold ? <ModalGoldPrice onClose={() => { this.setState({ open_gold: false }); this.componentDidMount() }} /> : ''}
            </Segment>
        );
    }
}

export default Gole;
