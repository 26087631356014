/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import image from '../../images/image.png'
import { connect } from 'react-redux';
import {
    Form,
    Button,
    Confirm, Dimmer, Loader,
    Header,
    Pagination,
    Image,
    Input,
    Icon,
    Dropdown,
    Label,
    Popup,
    Checkbox,
    Accordion,
    Grid,
    Modal,
} from 'semantic-ui-react';

class RewardList extends Component {
    constructor(props) {
        super(props);
        this.limit = 10
        this.state = {
            items: [],
            text: '',
            status: 'A',
            select: [],
            item_edit: null,
            total: 0,
            action: '',
            loading: false,
        }

        this.setFieldValue = this.setFieldValue.bind(this)
        this.loadCategory = this.loadCategory.bind(this)
        this.submitData = this.submitData.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.saveImage = this.saveImage.bind(this)
    }

    componentDidMount() {
        this.loadCategory()
    }

    saveImage() {
        this.setState({ loading: true })
        let icon_path = this.uploadImage(this.state.icon_image, 'ico')
        let banner_path = this.uploadImage(this.state.banner_image, 'ban')
        Promise.all([icon_path, banner_path]).then((values) => {
            let formData = {}
            let nameData = {
                [this.state.item_edit.id]: this.state.name
            }
            formData['name'] = nameData
            if (values[0]) {
                let iconData = {
                    [this.state.item_edit.id]: values[0]
                }
                formData['icon'] = iconData
            }
            if (values[1]) {
                let bannerData = {
                    [this.state.item_edit.id]: values[1]
                }
                formData['banner'] = bannerData
            }
            this.submitData(formData)
        });
    }

    submitData(data) {
        let r = Utility.patchAsyncFormdata(Settings.baseUrl + '/reward/category/', data)
        Promise.all([r]).then((values) => {
            this.setState({ item_edit: null, loading: false })
            this.loadCategory()
        })
    }

    async uploadImage(image, kind) {
        if (image) {
            var formData = new FormData();
            formData.append('image', image);
            formData.append('kind', kind);
            const url = Settings.baseUrl + '/upload_reward_image/';
            const res = await Utility.postUploadAsync(url, formData)
            if (res.status) {
                let ret = res.data.image
                return ret
            } else {
                return null
            }
        } else {
            return null
        }
    }

    loadCategory() {
        this.setState({
            loader_active: true
        });
        var category = Utility.getFetch(Settings.baseUrl + '/reward/category/');
        Promise.all([category]).then((values) => {
            this.categories = values[0].categories
            this.status = values[0].CATEGORY_STATUS
            this.icon = values[0].CATEGORY_ICON
            this.banner = values[0].CATEGORY_BANNER
            this.name = values[0].CATEGORY_NAME
            this.setFieldValue()
            this.setState({
                loader_active: false,
            });
        })
    }

    setFieldValue() {
        let items = []
        let total = 0
        for (let i in this.categories) {
            let cat = this.categories[i]
            let add = false
            cat.status = this.status[cat.id] == undefined ? 'C' : this.status[cat.id]
            cat.status_display = this.status[cat.id] == 'O' ? 'เปิด' : 'ปิด'
            cat.icon = this.icon[cat.id] == undefined ? '' : this.icon[cat.id]
            cat.banner = this.banner[cat.id] == undefined ? '' : this.banner[cat.id]
            cat.name = this.name[cat.id] == undefined ? cat.name : this.name[cat.id]
            if (cat.status == this.state.status || this.state.status == 'A') {
                add = true
            }
            cat.child = []
            for (let j in cat.children) {
                let subcat = cat.children[j]
                subcat.status = this.status[subcat.id] == undefined ? 'C' : this.status[subcat.id]
                subcat.status_display = this.status[subcat.id] == 'O' ? 'เปิด' : 'ปิด'
                subcat.icon = this.icon[subcat.id] == undefined ? '' : this.icon[subcat.id]
                subcat.banner = this.banner[subcat.id] == undefined ? '' : this.banner[subcat.id]
                subcat.name = this.name[subcat.id] == undefined ? subcat.name : this.name[subcat.id]
                if (subcat.status == this.state.status || this.state.status == 'A') {
                    total += 1
                    cat.child.push(subcat)
                    add = true
                }
            }
            if (add) {
                total += 1
                items.push(cat)
            }
        }
        this.setState({ items: items, total: total, select: [], action: '' })
    }

    render() {
        const items = this.state.items
        return (
            <div>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field width={6}>
                            <Header floated='left' as='h2'>จัดการหมวดหมู่</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                    <Loader content='Loading' inverted />
                </Dimmer>
                <Form size='small'>
                    <Form.Group>
                        <Form.Field width={3}>
                            <label>สถานะ</label>
                            <Dropdown
                                selection fluid
                                onChange={(e, v) =>
                                    this.setState({ status: v.value }, () => {
                                        this.setFieldValue()
                                    })
                                } value={this.state.status}
                                options={
                                    [{
                                        key: 'A',
                                        value: 'A',
                                        text: 'ทั้งหมด'
                                    },
                                    {
                                        key: 'C',
                                        value: 'C',
                                        text: 'ปิด'
                                    },
                                    {
                                        key: 'O',
                                        value: 'O',
                                        text: 'เปิด'
                                    }]
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Accordion styled fluid>
                    {this.state.items.map((row, i) => <div key={i}>
                        <Accordion.Title
                            active={true}
                            index={i}
                        >
                            <Grid verticalAlign='middle' divided='vertically'>
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <Checkbox style={{ 'padding-right': '10%' }}
                                            checked={this.state.select.includes(row.id)}
                                            onChange={(e, v) => {
                                                let arr = this.state.select
                                                if (v.checked) {
                                                    arr.push(row.id)
                                                } else {
                                                    let remove_id = []
                                                    remove_id.push(row.id)
                                                    for (let i in row.child) {
                                                        remove_id.push(row.child[i].id)
                                                    }
                                                    for (let j in remove_id) {
                                                        arr = arr.filter(value => value != remove_id[j])
                                                    }
                                                }
                                                this.setState({
                                                    select: arr,
                                                })
                                            }}
                                        />
                                        {this.props.auth.role == 'S' ?
                                            <a onClick={(e) => {
                                                this.setState({
                                                    item_edit: row,
                                                    icon_preview: row.icon,
                                                    name: row.name,
                                                    icon_image: null,
                                                    banner_preview: row.banner,
                                                    banner_image: null,
                                                })
                                            }}><Icon name='edit' /></a>
                                            : ''}
                                        {row.name} ({row.child.length})
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image src={row.icon == '' ? image : Settings.mediaUrl + row.icon} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image src={row.banner == '' ? image : Settings.mediaUrl + row.banner} />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        สถานะ :{row.status_display}
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Checkbox toggle checked={row.status == 'O'} onChange={(e, v) => {
                                            let ids = {}
                                            if (v.checked) {
                                                ids[row.id] = 'O'
                                            } else {
                                                ids[row.id] = 'C'
                                                for (let i in row.child) {
                                                    ids[row.child[i].id] = 'C'
                                                }
                                            }
                                            this.submitData({
                                                'status': ids,
                                            })
                                        }} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Accordion.Title>
                        {row.child.length != 0 &&
                            <Accordion.Content active={true}>
                                <Grid verticalAlign='middle' divided='vertically'>
                                    {row.child.map((subrow, j) =>
                                        <Grid.Row key={j}>
                                            <Grid.Column width={1} />
                                            <Grid.Column width={6}>
                                                <Checkbox style={{ 'padding-right': '10%' }}
                                                    checked={this.state.select.includes(subrow.id)}
                                                    onChange={(e, v) => {
                                                        let arr = this.state.select
                                                        if (v.checked) {
                                                            if (!this.state.select.includes(row.id))
                                                                arr.push(row.id)
                                                            arr.push(subrow.id)
                                                        } else {
                                                            arr = arr.filter(value => value != subrow.id)
                                                        }
                                                        this.setState({
                                                            select: arr,
                                                        })
                                                    }}
                                                />
                                                {this.props.auth.role == 'S' ?
                                                    <a onClick={(e) => {
                                                        this.setState({
                                                            item_edit: subrow,
                                                            icon_preview: subrow.icon,
                                                            icon_image: null,
                                                            name: subrow.name,
                                                            banner_preview: subrow.banner,
                                                            banner_image: null,
                                                        })
                                                    }}><Icon name='edit' /></a>
                                                    : ''}
                                                {subrow.name}
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Image src={subrow.icon == '' ? image : Settings.mediaUrl + subrow.icon} />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Image src={subrow.banner == '' ? image : Settings.mediaUrl + subrow.banner} />
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                สถานะ :{subrow.status_display}
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Checkbox toggle checked={subrow.status == 'O'} onChange={(e, v) => {
                                                    let ids = {}
                                                    if (v.checked) {
                                                        ids[subrow.id] = 'O'
                                                        if (row.status == 'C') {
                                                            ids[row.id] = 'O'
                                                        }
                                                    } else {
                                                        ids[subrow.id] = 'C'
                                                    }
                                                    this.submitData({
                                                        'status': ids
                                                    })
                                                }} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Accordion.Content>
                        }
                    </div>
                    )}
                    <Accordion.Title
                        active={true}
                    >
                        <br />
                        <Form>
                            <Form.Group>
                                <Form.Field >
                                    <Checkbox
                                        checked={this.state.select.length == this.state.total}
                                        label='ทั้งหมด'
                                        onChange={(e, v) => {
                                            let arr = []
                                            if (v.checked) {
                                                this.state.items.forEach(i => {
                                                    arr.push(i.id)
                                                    for (let j in i.child) {
                                                        arr.push(i.child[j].id)
                                                    }
                                                })
                                            }
                                            this.setState({
                                                select: arr,
                                            })
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field width={2}>
                                    <Dropdown
                                        selection
                                        fluid
                                        onChange={(e, v) =>
                                            this.setState({ action: v.value })
                                        } value={this.state.action}
                                        options={
                                            [{
                                                key: 'C',
                                                value: 'C',
                                                text: 'ปิด'
                                            },
                                            {
                                                key: 'O',
                                                value: 'O',
                                                text: 'เปิด'
                                            }]
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button size='small' type='button' primary onClick={() => {
                                        if (this.state.action == '') {
                                            alert('กรุณาเลือกสถานะที่จะทำการเปลี่ยน')
                                            return
                                        }
                                        let ids = {}
                                        let action = this.state.action
                                        for (let i in this.state.select) {
                                            ids[this.state.select[i]] = action
                                        }
                                        this.submitData({ 'status': ids })
                                    }}>บันทึก</Button>
                                </Form.Field>
                                <Form.Field>
                                    <p>{this.state.select.length} จาก {this.state.total} รายการ</p>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Accordion.Title>
                </Accordion>
                {this.state.item_edit != null &&
                    <Modal id='modalCreateBranch' open={true} size='mini' /*dimmer='blurring'*/ >
                        <Button
                            id='btnClose'
                            circular
                            icon='close'
                            basic
                            floated='right'
                            name=''
                            disabled={this.state.loading}
                            onClick={() => this.setState({ item_edit: null })} />
                        <Modal.Header>แก้ไข: {this.state.item_edit.name}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form size='small' >
                                    <Form.Field>
                                        <label>ชื่อหมวดหมู่</label>
                                        <Input
                                            onChange={(e) => {
                                                this.setState({ name: e.target.value })
                                            }}
                                            value={this.state.name}
                                            maxLength={100}
                                        />
                                    </Form.Field>
                                </Form>
                                <Form size='small' >
                                    <Form.Field>
                                        <label>Icon (100x100)</label>
                                        <Input onChange={(e) => this.setState({ icon_image: e.target.files[0], icon_preview: URL.createObjectURL(e.target.files[0]) })} type="file" accept="image/*" />
                                        <Image src={this.state.icon_image ? this.state.icon_preview : this.state.icon_preview ? Settings.mediaUrl + this.state.icon_preview : this.state.icon_preview} size='small' bordered />
                                    </Form.Field>
                                </Form>
                                <br />
                                <Form size='small' >
                                    <Form.Field>
                                        <label>Banner (600x250)</label>
                                        <Input onChange={(e) => this.setState({ banner_image: e.target.files[0], banner_preview: URL.createObjectURL(e.target.files[0]) })} type="file" accept="image/*" />
                                        <Image src={this.state.banner_image ? this.state.banner_preview : this.state.banner_preview ? Settings.mediaUrl + this.state.banner_preview : this.state.banner_preview} size='huge' bordered />
                                    </Form.Field>
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button id='btnSave' size='small' primary
                                onClick={this.saveImage}
                                loading={this.state.loading}
                                disabled={this.state.loading}
                                className={this.state.button_class}>บันทึก</Button>
                            <Button id='btnCancel' size='small' disabled={this.state.loading} onClick={(e) => { e.preventDefault(); this.setState({ item_edit: null }); }}>ยกเลิก</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps)(RewardList)
