/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import RedeemForm from '../RedeemForm'
import {
  Form,
  Button,
  Confirm, Dimmer, Loader,
  Header,
  Pagination,
  Label,
  Input,
  Icon,
  Dropdown,
  Popup,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}
class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    var v = data[rowIndex][field]
    if (this.props.type && this.props.type == 'number')
      v = Utility.numberFormat(v)
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={v}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{v}</div>}
        />
      </Cell>
    );
  }
}

class StatusItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, field2, data, ...props } = this.props;
    var v = data[rowIndex][field]
    var k = data[rowIndex][field2]
    var c = 'grey'
    if (k == 1) {
      c = 'yellow'
    } else if (k == 2 || k == 3) {
      c = 'green'
    }
    return (
      <Cell {...props}>
        <div className='text-center'>
          <Label color={c} horizontal>
            {v}{k}
          </Label>
        </div>
      </Cell>
    );
  }
}


class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

    //this.props.onClickMenu(e);
  }

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell><center style={{ padding: '4px' }}>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
      </center></Cell>
    );
  }
}
class RedeemList extends Component {
  constructor(props) {
    super(props);
    this.limit = 10
    this.state = {
      msg_error: {},
      items: [],
      activePage: 1,
      reward: {},
      text: '',
      status: 0,
      kind: 0
    }

    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.loadDataList(activePage)
  }

  componentWillMount() {

    this.loadDataList(this.state.activePage)

    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)
    

    let url = Settings.baseUrl + '/redeem/'
    Utility.options(url, (s, r, c) => {

      this.status_choices = r.actions.POST.status.choices
      this.kind_choices = r.actions.POST.kind.choices
      this.setState({
        status_choices: Utility.setOptionFetch(r, 'status', { value: 0, key: 0, text: 'สถานะ' }),
        kind_choices: Utility.setOptionFetch(r, 'kind', { value: 0, key: 0, text: 'วิธีรับสินค้า' })
      })
    })


  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  loadDataList(activePage) {

    activePage = activePage < 1 ? 1 : activePage
    let q = Utility.jsonToQueryString({
      page: activePage,
      page_size: this.limit,
    })

    if (this.state.text != '')
      q = q + '&text=' + this.state.text.trim()
    if (this.state.kind != 0)
      q = q + '&kind=' + this.state.kind

    if (this.state.status != 0)
      q = q + '&status=' + this.state.status

    let url = Settings.baseUrl + '/redeem/?' + q
    Utility.get(url, (status, res, code) => {
      if (status)
        this.setState({
          items: this.setFieldValue(res.results),
          totalPages: res.next == null && res.previous == null ? 0 : Math.ceil(res.count / this.limit),
        })
    })
  }


  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.setState({
        modal_open: true,
        modal_title: 'แก้ไข',
        modal_action: 'edit',
        redeem: data
      })
    } else {

      if (window.confirm('ยืนยันลบ')) {
        const url = Settings.baseUrl + '/redeem/' + data.id + "/";
        Utility.delete(url, (status, data, status_code) => {
          if (status) {
            this.loadDataList(1);
          } else {
            alert(data.error)
          }
        });
      }
    }
  }

  setFieldValue(_items) {

    let items = []

    for (let i in _items) {
      let item = _items[i]

      item['date'] = Utility.formatDate(item.created_datetime)
      item['time'] = Utility.formatTime(item.created_datetime)
      item['reward_code'] = item.reward.code
      item['reward_name'] = item.reward.name
      item['customer_name'] = item.customer.name
      if (item.staff != null)
        item['staff_name'] = item.staff.name
      if (item.kind == 1) {
        item['kind_display'] = item.kind_display + '(' + item.branch.name + ')'
        item['receipt_date_display'] = Utility.formatDate(item.receipt_date)
      }

      items.push(item)
    }

    return items
  }

  render() {
    const items = this.state.items
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>รายการของแลกรางวัล</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างรายการของรางวัล'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>

        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        {this.state.modal_open && <RedeemForm
          redeem={this.state.redeem}
          onClose={() => this.setState({ modal_open: false, activePage: 1 })}
          action={this.state.modal_action} onSaved={() => {
            this.setState({ modal_open: false, activePage: 1 })
            this.loadDataList(1)
          }} />}

        <Form size='small'>
          <Form.Group>
            <Form.Field width={8}>
              <Input icon='search' iconPosition='left' placeholder='รหัสแลก,รหัสของรางวัล,ชื่อของรางวัล,ชื่อลูกค้า,รหัสลูกค้า, ชื่อ, คะแนน' onChange={(e, v) => this.setState({ text: v.value })} value={this.state.text} name='text' />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown selection fluid onChange={(e, v) => this.setState({ status: v.value })} value={this.state.status} options={this.state.status_choices} />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown selection fluid onChange={(e, v) => this.setState({ kind: v.value })} value={this.state.kind} options={this.state.kind_choices} />
            </Form.Field>
            <Form.Field width={4}>
              <Button size='small' floated='right' onClick={(e) => {
                this.setState({ text: '', is_enabled: 2, status: 0, kind: 0 })
                this.state.text = ''
                this.state.status = 0
                this.state.kind = 0
                this.state.is_enabled = 2
                this.loadDataList(1)
              }} type='button' >ทั้งหมด</Button>
              <Button size='small' onClick={(e) => {

                this.loadDataList(1)
              }} floated='right' type='submit'><Icon name='search' />ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>

        <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={40}
          headerHeight={35}
          width={this.state.table_width}
          height={402}>
          <Column
            width={50}

            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell onClickMenu={this.handleClick} />}
          />
          <Column
            header={<Cell>วันที่</Cell>}
            cell={
              <ItemsCell position='top left' width={100} data={items} field="date" />
            }
            width={100}
          />
          <Column
            header={<Cell>เวลา</Cell>}
            cell={
              <ItemsCell position='top left' width={60} data={items} field="time" />
            }
            width={60}
          />
          <Column
            header={<Cell>รหัสแลก</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="code" />
            }
            width={120}
          />
          <Column
            header={<Cell>รหัสของรางวัล</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="reward_code" />
            }
            width={120}
          />
          <Column
            header={<Cell>ชื่อของรางวัล</Cell>}
            cell={
              <ItemsCell position='top left' width={260} data={items} field="reward_name" />
            }
            width={260}
          />
          <Column
            header={<Cell>ชื่อลูกค้า</Cell>}
            cell={
              <ItemsCell position='top left' width={150} data={items} field="customer_name" />
            }
            width={150}
          />
          <Column
            header={<Cell className='text-center'>สถานะ</Cell>}
            cell={
              <StatusItemsCell data={items} textAlign='text-center' field="status_display" field2="status" />
            }
            width={180}
          />
          <Column
            header={<Cell>วิธีรับ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="kind_display" />
            }
            width={200}
          />
          <Column
            header={<Cell>วันที่รับที่สาขา</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="receipt_date_display" />
            }
            width={200}
          />
          <Column
            header={<Cell>พนักงาน</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="staff_name" />
            }
            width={200}
          />

          <Column
            header={<Cell className=''>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={300} data={items} textAlign='' field="remrak" />
            }
            width={300}
          />
        </Table></div>
        <br />
        <Pagination
          activePage={this.state.activePage}
          onPageChange={this.handlePaginationChange}
          totalPages={this.state.totalPages}
        />
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/banks/' + this.state.object_id + "/";
            Utility.delete(url, (status, data) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.componentDidMount();
                this.resetForm();
              } else {
                alert(data.error)
              }
            });

          }}
        />
      </div>

    );
  }
}

export default RedeemList;
