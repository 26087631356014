/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment,
    Header,
    Grid,
    Statistic,
    Loader,
    Dimmer
} from 'semantic-ui-react';
import moment from 'moment';
import Settings from '../../Settings';
import Utility from '../../Utility';

import { connect } from 'react-redux'


class Gole extends Component {

    constructor(props) {
        super(props);

        this.state = {
            income: 0,
            expenses: 0,
            total: 0,
            daily_ledger_balance: 0,
        }
        this.loadLedger = this
            .loadLedger
            .bind(this)
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ visible: true })
        localStorage.setItem("goldpric", JSON.stringify({ gold_bar_sell: this.state.gold_bar_sell, gold_bar_buy: this.state.gold_bar_buy, gold_ornaments_buy: this.state.gold_ornaments_buy, time: moment() }));

        setTimeout(() => {
            this.setState({ visible: false })
        }, 1000)
        if (this.props.onSubmit) {
            this
                .props
                .onSubmit()
        }
    }

    componentDidMount() {

        const { auth } = this.props
        const { branch } = auth
        this.loadLedger(branch)
    }

    loadLedger(branch) {
        if (branch != undefined) {
            let s1 = {
                start_date: Utility.formatDate2(moment()),
                end_date: Utility.formatDate2(moment())
            }
            s1.branch = branch.id

            let date_before = new Date(s1.start_date)
            date_before.setDate(date_before.getDate() - 1)
            date_before = Utility.formatDate2(date_before)

            let s = Utility.jsonToQueryString(s1)
            var ledger = Utility.getFetch(Settings.baseUrl + '/ledger/?is_enabled=1&' + s);
            var daily_ledger = Utility.getFetch(Settings.baseUrl + "/daily_ledger_balance/?date=" + date_before + "&branch=" + branch.id)
            Promise
                .all([ledger, daily_ledger])
                .then((values) => {

                    let income = 0
                    let expenses = 0
                    let n_income = 0
                    let n_expenses = 0
                    for (let i in values[0]) {

                        if (values[0][i].kind == 'IN') {
                            income += parseFloat(values[0][i].total)
                            n_income++;
                        } else {
                            expenses += parseFloat(values[0][i].total)
                            n_expenses++
                        }
                    }

                    let daily_ledger_balance = 0
                    if (values[1].length != 0) {
                        daily_ledger_balance = Number(values[1][0].cash)
                    }

                    this.setState({
                        income: income,
                        expenses: expenses,
                        total: (daily_ledger_balance + income) - expenses,
                        n_income: n_income,
                        n_expenses: n_expenses,
                        is_ready: true,
                        daily_ledger_balance: daily_ledger_balance,
                    })

                })
        }
    }

    render() {
        return (
            <div>
                {this.props.mod == null
                    ? <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Segment color='green'>
                                    <Header as='h2'>รายรับ</Header>
                                    <right>
                                        <Statistic size='tiny' color='green'>
                                            <Statistic.Value >{Utility.priceFormat(this.state.income)}</Statistic.Value>
                                        </Statistic>
                                    </right>
                                    <Dimmer active={!this.state.is_ready} inverted>
                                        <Loader inverted>Loading..</Loader>
                                    </Dimmer>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Segment color='red'>

                                    <Header as='h2'>รายจ่าย</Header>
                                    <right>
                                        <Statistic size='tiny' color='red'>
                                            <Statistic.Value >{Utility.priceFormat(this.state.expenses)}</Statistic.Value>
                                        </Statistic>
                                    </right>
                                    <Dimmer active={!this.state.is_ready} inverted>
                                        <Loader inverted>Loading..</Loader>
                                    </Dimmer>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    :
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment color='grey'>

                                    <Header as='h2'>เงินสดยกมา</Header>
                                    <right>
                                        <Statistic size='tiny' color='grey'>
                                            <Statistic.Value >{Utility.priceFormat(this.state.daily_ledger_balance)}</Statistic.Value>
                                        </Statistic>
                                    </right>
                                    <Dimmer active={!this.state.is_ready} inverted>
                                        <Loader inverted>Loading..</Loader>
                                    </Dimmer>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment color='yellow'>

                                    <Header as='h2'>คงเหลือ</Header>
                                    <right>
                                        <Statistic size='tiny' color='yellow'>
                                            <Statistic.Value >{Utility.priceFormat(this.state.total)}</Statistic.Value>
                                        </Statistic>
                                    </right>
                                    <Dimmer active={!this.state.is_ready} inverted>
                                        <Loader inverted>Loading..</Loader>
                                    </Dimmer>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>}
            </div>
        );
    }
}

const mapStateToProps = state => {

    return ({
        branches: state.branches,
        auth: state.auth
    })
}
export default connect(
    mapStateToProps
)(Gole)
