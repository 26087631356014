/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import OptionItemsCell from './OptionItemsCell'
import MsgInput from '../Error/MsgInput'

import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader, Dropdown, Header,
  Popup,
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, type, data, ...props } = this.props;
    let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
      : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
        : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
          : data[rowIndex][field];
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={v}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{v}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class ItemsCellOption extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props} className='cell-time-click'>
        <Button.Group basic size='mini'>
          <Button onClick={(e) => {
            this.props.onClickEdit(e, data[rowIndex]);
          }}><Icon color='green' name='edit' /></Button>
          <Button onClick={(e) => {
            this.props.onClickDelete(e, data[rowIndex]);
          }}><Icon color='red' name='delete' /></Button>
        </Button.Group>
      </Cell>
    );
  }
}

export const periodOption = [
  {
    key: '1',
    value: '0',
    text: 'ชำระเต็มจำนวน',
  },
  {
    key: '4',
    value: '4',
    text: 'ผ่อนชำระ 4 เดือน',
  },
  {
    key: '6',
    value: '6',
    text: 'ผ่อนชำระ 6 เดือน',
  },
  {
    key: '10',
    value: '10',
    text: 'ผ่อนชำระ 10 เดือน',
  },
]

class BankCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      code: '',
      remark: '',
      modal_open: false,
      msg_error: {},
      fee: '',
      card_swipe_fee: '',
      banks: [],
      search: { bank: '', period: '' },
    }
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handlerSearch = this.handlerSearch.bind(this)
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.clearFormSearch = this.clearFormSearch.bind(this);
    this.searchAll = this.searchAll.bind(this);
  }

  submitData() {
    var msg_error = {}
    if (this.state.banks_id == null || this.state.banks_id == '') {
      msg_error['bank'] = ['*เลือกธนาคาร']
    }

    if (this.state.period == null || this.state.period == '') {
      msg_error['period'] = ['*ต้องไม่เป็นค่าว่าง']
    }

    if (this.state.kind == null || this.state.kind == '') {
      msg_error['kind'] = ['*ต้องไม่เป็นค่าว่าง']
    }

    if (this.state.fee == null || this.state.fee == '') {
      msg_error['fee'] = ['*ต้องไม่เป็นค่าว่าง & *ต้องไม่มีตัวอักษร']
    }

    if (this.state.card_swipe_fee == null || this.state.card_swipe_fee == '') {
      msg_error['card_swipe_fee'] = ['*ต้องไม่เป็นค่าว่าง & *ต้องไม่มีตัวอักษร']
    }


    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error
      })
      return;
    }

    var formData = {
      bank: this.state.banks_id,
      period: this.state.period,
      kind: this.state.kind,
      fee: this.state.fee,
      service: this.state.card_swipe_fee,
    };

    const self = this;
    this.setState({ button_class: 'loading' })
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/bank_cards/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          this.handlerSubmitSearch();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/bank_cards/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          this.handlerSubmitSearch();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      kind: '',
      fee: '',
      card_swipe_fee: '',
      button_class: '',
      msg_error: {},
      banks_id: '',
      period: '',
    });
  }

  clearFormSearch() {
    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }
    this.setState({ search })
  }

  searchAll() {
    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }
    this.setState({ search }, this.handlerSubmitSearch)
  }


  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    this.handlerSubmitSearch()

    Utility.get(Settings.baseUrl + '/banks/?is_enabled=1', (status, data) => {
      let banks = []
      for (let i in data) {
        banks.push({
          value: data[i].id,
          text: data[i].name
        });
      }
      this.setState({
        banks: banks
      });
    });
  }

  handlerSubmitSearch(e) {
    this.setState({
      loader_active: true
    });
    let str = Utility.jsonToQueryString(this.state.search);
    Utility.get(Settings.baseUrl + '/bank_cards/?is_enabled=1&' + str, (status, data) => {
      this.setState({
        items: this.setFieldValue(data),
        loader_active: false
      });
    });
  }

  setFieldValue(v) {

    for (let i in v) {
      v[i].bank_name = v[i].bank.name
    }

    return v;
  }

  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: data.name,
        kind: data.kind,
        fee: data.fee,
        card_swipe_fee: data.service,
        banks_id: data.bank.id,
        period: String(data.period),
        object_id: data.id
      });
    } else {
      this.setState({ confirm_open: true, object_id: data.id, object_bank_id: data.bank.id });
    }
  }

  handlerSearch(event, v) {
    if (v.name) {
      let search = this.state.search;
      search[v.name] = v.value;
      this.setState({
        search: search
      });
    }
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>ชนิดบัตร</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button id='btnAddCard' size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างชนิดบัตร'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/ >
          <Button
            id='btnCloseCard'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.kind != null}>
                  <label>*ประเภทบัตร <MsgInput text={this.state.msg_error.kind} /></label>
                  <Input id='inputCardType' placeholder='ประเภทบัตร' onChange={(e) => this.setState({ kind: e.target.value })}
                    value={this.state.kind} maxLength={100} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.fee != null}>
                  <label>*%ค่าธรรมเนียม <MsgInput text={this.state.msg_error.fee} /></label>
                  <Input id='inputCardFree'
                    // type='number' 
                    placeholder='%ค่าธรรมเนียม'
                    onBlur={() => {
                      setTimeout(() => {
                        if (this.state.focus_fee) {
                          this.setState({
                            focus_fee: false,
                          });
                        }
                      }, 0);
                    }}
                    onFocus={(e) => {
                      e.target.select()
                      if (!this.state.focus_fee) {
                        this.setState({
                          focus_fee: true,
                        });
                      }
                    }}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.toString().split(".")[1] != undefined)
                          if (e.target.value.toString().split(".")[1].length > 2)
                            return
                        if (e.target.value < 0) {
                          e.target.value = 0
                        }
                        if (e.target.value > 100) {
                          e.target.value = 100
                        }
                        this.setState({ fee: e.target.value })
                      }
                    }}
                    min={0} max={100}
                    maxLength={18}
                    value={this.state.fee == '' ? '' : this.state.focus_fee ? this.state.fee : Utility.priceFormat(this.state.fee)}
                  />
                </Form.Field>
                <Form.Field error={this.state.msg_error.card_swipe_fee != null}>
                  <label>*%ค่าบริการรูดบัตร <MsgInput text={this.state.msg_error.card_swipe_fee} /></label>
                  <Input id='inputCardSwipeFee'
                    // type='number' 
                    placeholder='%ค่าบริการรูดบัตร'
                    onBlur={() => {
                      setTimeout(() => {
                        if (this.state.focus_card_swipe_fee) {
                          this.setState({
                            focus_card_swipe_fee: false,
                          });
                        }
                      }, 0);
                    }}
                    onFocus={(e) => {
                      e.target.select()
                      if (!this.state.focus_card_swipe_fee) {
                        this.setState({
                          focus_card_swipe_fee: true,
                        });
                      }
                    }}
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.toString().split(".")[1] != undefined)
                          if (e.target.value.toString().split(".")[1].length > 2)
                            return
                        if (e.target.value < 0) {
                          e.target.value = 0
                        }
                        if (e.target.value > 100) {
                          e.target.value = 100
                        }
                        this.setState({ card_swipe_fee: e.target.value })
                      }
                    }}
                    min={0} max={100}
                    maxLength={18}
                    value={this.state.card_swipe_fee == '' ? '' : this.state.focus_card_swipe_fee ? this.state.card_swipe_fee : Utility.priceFormat(this.state.card_swipe_fee)}
                  />
                </Form.Field>
                <Form.Field error={this.state.msg_error.bank != null}>
                  <label>*ประเภทชำระ <MsgInput text={this.state.msg_error.period} /></label>
                  <Dropdown id='dorpDownPeriod' selection width={14} options={periodOption}
                    placeholder='ประเภทชำระ'
                    onChange={(e, v) => {
                      this.setState({ period: v.value });
                    }} defaultValue={this.state.period} value={this.state.period} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.bank != null}>
                  <label>*ชื่อธนาคาร <MsgInput text={this.state.msg_error.bank} /></label>
                  <Dropdown id='dorpDownBankName' search selection width={14} options={this.state.banks}
                    placeholder='ชื่อธนาคาร'
                    onChange={(e, v) => {
                      this.setState({ banks_id: v.value });
                    }} defaultValue={this.state.banks_id} value={this.state.banks_id} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSaveCard' size='small' primary onClick={(e) => {
              e.preventDefault();
              this.submitData();

            }}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancelCard' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <label>ธนาคาร</label>
              <Dropdown id='searchBank' placeholder='ทั้งหมด' search selection value={this.state.search.bank} options={[{ value: '', text: 'ทั้งหมด' }].concat(this.state.banks)} onChange={this.handlerSearch} name='bank' />
            </Form.Field>
            <Form.Field width={5}>
              <label>ประเภทชำระ</label>
              <Dropdown id='searchPeriod' placeholder='ทั้งหมด' search selection value={this.state.search.period} options={[{ key: '', value: '', text: 'ทั้งหมด' }].concat(periodOption)} onChange={this.handlerSearch} name='period' />
            </Form.Field>

            <Form.Field width={6}>
              <label><br /></label>
              <Button onClick={this.handlerSubmitSearch} size='small' type='button'><Icon name='search' /> ค้นหา</Button>
              <Button onClick={this.searchAll} size='small' type='button' >ทั้งหมด</Button>
              <Button id='clearSearch' onClick={this.clearFormSearch} size='small' type='button' >รีเซ็ต</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />
            <Column
              header={<Cell>ประเภทบัตร</Cell>}
              cell={
                <ItemsCell position='top left' width={150} id='cardType' data={items} field="kind" />
              }
              width={150}
            />
            <Column
              header={<Cell>ประเภทชำระ</Cell>}
              cell={
                <ItemsCell position='top left' width={100} id='period' data={items} field="period_display" />
              }
              width={100}
            />
            <Column
              header={<Cell className='text-right'>ค่าธรรมเนียม</Cell>}
              cell={
                <ItemsCell position='top right' width={150} data={items} field="fee" textAlign="text-right" type='price' />
              }
              width={150}
            />
            <Column
              header={<Cell className='text-right'>ค่าบริการรูดบัตร</Cell>}
              cell={
                <ItemsCell position='top right' width={150} data={items} field="service" textAlign="text-right" type='price' />
              }
              width={150}
            />
            <Column
              header={<Cell>ชื่อธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="bank_name" />
              }
              width={200}
            />
          </Table></div>
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
          </Form.Group>
        </Form>
        <Confirm
          id='modalConfirmDeleteCard'
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/bank_cards/' + this.state.object_id + "/"
            Utility.delete(url, (status, data, code) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.handlerSubmitSearch();
                this.resetForm();
              } else if (data.error && (code == 400 || code == 403)) {
                this.setState({ confirm_open: false });
                alert(data.error)
              } else if (code == 404) {
                alert('ถูกลบไปแล้ว')
              }
            });

          }}
        />
      </div>
    );
  }
}
export default BankCard;
