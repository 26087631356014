/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Form,
  Button,
  Dimmer,
  Loader,
  Header,
  Pagination,
  Popup,
  Icon,
} from 'semantic-ui-react';
import StockCategoryDetail from './StockCategoryDetail'

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <Cell {...props} onClick={() => {
        this.props.onContextMenu(data[rowIndex].id, data[rowIndex].category);
      }} className="cell-time-click">
        <Popup
          position={this.props.position}
          content={(field === 'average') ?
            Utility.priceFormat(data[rowIndex][field])
            :
            (field === 'weight') ?
              Utility.weightFormat(data[rowIndex][field])
              :
              data[rowIndex][field]
          }
          style={Width300}
          trigger={(field === 'average') ?
            <div style={ellipsisStyle} className={this.props.textAlign}>{Utility.priceFormat(data[rowIndex][field])}</div>
            :
            (field === 'weight') ?
              <div style={ellipsisStyle} className={this.props.textAlign}>{Utility.weightFormat(data[rowIndex][field])}</div>
              :
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>
          }
        />
      </Cell>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class ProductType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      branches_id: 0,
      table_hiegh: Settings.table_hiegh,
      totalPages: 1,
      page: 1,
      qrstring: '',
      colSortDirs: {
        'category__name': 'ASC',
      },
      counter:0,
    }

    this.columnKey = 'category__name'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.loaddata = this.loaddata.bind(this)
    this.loaddata2 = this.loaddata2.bind(this)
    this.onContextMenu = this.onContextMenu.bind(this);
    this.clearFormSearch = this.clearFormSearch.bind(this);
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  resetForm() {
    this.setState({
      branches_id: 0,
      page: 1
    }, () => {
      this.componentDidMount()
    })
  }

  clearFormSearch() {
    this.setState({
      branches_id: 0,
      page: 1
    })
  }
  loaddata2(page) {
    this.setState({
      loader_active: true
    });

    var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_old_product/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + this.state.qrstring + '&page=' + page + '&page_size=' + 10);
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    Promise.all([stock_category, branches]).then((values) => {
      this.stock_category = values[0];

      this.branches = values[1];
      let branches = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      for (let i in this.branches) {
        if (this.branches[i].is_enabled == 1)
          branches.push({
            value: this.branches[i].id,
            key: this.branches[i].id,
            text: this.branches[i].name
          });
      }

      this.setState({
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        branches: branches,
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter:values[0].count
      });
      let elHeight = document.getElementById('table_width')
      this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 380 });
    });
  }

  loaddata(page) {
    var self = this;
    this.setState({
      loader_active: true
    });
    var s = '';
    if (this.state.branches_id != null && this.state.branches_id != '') {
      s += (s == '' ? '' : '&') + 'branch=' + this.state.branches_id
    }
    this.setState({ qrstring: s })

    var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_old_product/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&' + s + '&page=' + page + '&page_size=' + 10);
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    Promise.all([stock_category, branches]).then((values) => {
      this.stock_category = values[0];

      this.branches = values[1];
      let branches = [{
        value: 0,
        key: 0,
        text: '- ทั้งหมด -'
      }]
      for (let i in this.branches) {
        if (this.branches[i].is_enabled == 1)
          branches.push({
            value: this.branches[i].id,
            key: this.branches[i].id,
            text: this.branches[i].name
          });
      }

      this.setState({
        loader_active: false,
        items: this.setFieldValue(values[0].results),
        branches: branches,
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter:values[0].count
      });
      let elHeight = document.getElementById('table_width')
      this.setState({ table_width: elHeight.clientWidth, table_hiegh: window.innerHeight - 380 });
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });

    this.loaddata(1)

    // var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_old_product/?' + s+'&page=' + 1 + '&page_size=' + 10);
    // var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
    // Promise.all([stock_category, branches]).then((values) => {
    //   this.stock_category = values[0];
    //   this.branches = values[1];
    //   let branches = [{
    //       value: 0,
    //       key: 0,
    //       text: '- ทั้งหมด -'
    //   }]
    //   for (let i in this.branches) {
    //     if (this.branches[i].is_enabled == 1)
    //       branches.push({
    //         value: this.branches[i].id,
    //         key: this.branches[i].id,
    //         text: this.branches[i].name
    //       });
    //   }
    // this.setState({
    //   query_string:s
    // })

    //   this.setState({
    //     loader_active: false,
    //     items: this.setFieldValue(values[0].results),
    //     branches: branches,
    //     totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
    //     query_string:s
    //   });

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
    // });
  }



  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  submitSearch(event) {
    event.preventDefault();
    this.setState({ page: 1 })
    this.componentDidMount();
  }

  getTypeSale(key) {
    let type = Settings.type_sale.filter((o) => { return o.value == key });
    return type[0];
  }

  setFieldValue(items) {

    for (let i = 0; i < items.length; i++) {
      items[i]['in_date_title'] = Utility.formatDate(items[i].in_date)
      items[i]['out_date_title'] = Utility.formatDate(items[i].out_date)
      let d = parseFloat(items[i].category.weight)
      items[i]['category'] = items[i].category.name
      items[i]['branch'] = items[i].branch.name
      items[i]['weight'] = items[i].weight_total
      items[i]['amount'] = items[i].amount
      items[i]['average'] = items[i].cost_avg_per_bath
      // items[i]['average'] = items[i].weight == 0 ? 0 : Utility.weightFormat(parseFloat(items[i].total) / (parseFloat(items[i].weight) / d))
    }

    return items;
  }
  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage })
    this.loaddata2(activePage)
  }

  onContextMenu(id, name) {
    this.setState({
      id: id,
      name: name
    })

    this.setState({
      open_detail: true
    })
  }

  render() {
    const items = this.state.items;
    return (
      <div id="table_width">
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>สต็อกทองเก่า</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>}
        <Form className='fluid' size='small'>
          <Form.Group>
            <Form.Dropdown id='dropDownBranch' label='สาขา' search selection width={4} options={this.state.branches} value={this.state.branches_id} onChange={(e, data) => {
              this.setState({ branches_id: data.value });
            }} />
            <Form.Field width={5}>
              <label><br /></label>
              <Button id='btnSearch' onClick={this.submitSearch}><Icon name='search' />ค้นหา</Button>
              <Button id='btnAll' onClick={this.resetForm} >ทั้งหมด</Button>
              {/* <Button id='clearSearch' onClick={this.clearFormSearch} >รีเซ็ต</Button> */}
            </Form.Field>
          </Form.Group>
        </Form>
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={387}>
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='branch__name'
              sortDir={this.state.colSortDirs.branch__name}>
              สาขา
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} id='branch' data={items} field="branch" onContextMenu={this.onContextMenu} />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.onSortChange}
              field='category__name'
              sortDir={this.state.colSortDirs.category__name}>
              กลุ่มสินค้า
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} data={items} field="category" onContextMenu={this.onContextMenu} />
            }
            width={150}
          />
          <Column
            header={<Cell className='text-right'>น้ำหนักทองเก่ารวม</Cell>}
            cell={
              <ItemsCell position='top right' width={150} data={items} field="weight" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.onSortChange}
              field='amount'
              sortDir={this.state.colSortDirs.amount}>
              จำนวนชิ้น
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={150} data={items} field="amount" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={150}
          />

          <Column
            header={<Cell className='text-right'>ราคาทองเฉลี่ย</Cell>}
            cell={
              <ItemsCell position='top right' width={160} data={items} field="average" textAlign='text-right' onContextMenu={this.onContextMenu} />
            }
            width={160}
          />

        </Table>
        <br />
        <Form size='small'>
              <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
                <br/>
                <Form.Field width={14}>
                  <br/>
                  {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
            activePage={this.state.page}
            onPageChange={this.handlePaginationChange.bind(this)}
            totalPages={this.state.totalPages}
          />}
                </Form.Field>
              </Form.Group>
            </Form>
        {/* {this.state.totalPages > 1 && <Pagination
          activePage={this.state.page}
          onPageChange={this.handlePaginationChange.bind(this)}
          totalPages={this.state.totalPages}
        />} */}
        {this.state.open_detail ? <StockCategoryDetail
          id={this.state.id}
          name={this.state.name}
          open={true}
          onClose={() => {
            this.setState({ open_detail: false });
            //this.componentDidMount();
          }}
        /> : ''}
      </div>
    );
  }
}
export default ProductType;
