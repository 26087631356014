/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Header,
  Button,
  Dimmer,
  Loader,
  Icon,
  Modal,
  Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import Settings from '../../Settings';
import Utility from '../../Utility';
import { Table, Column, Cell } from 'fixed-data-table';



class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}

class Gole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      gold_bar_sell: '',
      gold_bar_buy: '',
      gold_ornaments_buy: '',
      record_date: moment(),
      table_width: window.innerWidth,
      table_hiegh: 300
    }

    this.handleUpdate = this.handleUpdate.bind(this)

    let goldpric = localStorage.getItem("goldpric");
    if (goldpric != null && goldpric != '' && goldpric.record_date != null)
      this.state = goldpric;

    this.state.items = []
  }

  handleUpdate() {
    this.loadGoldPrice()
  }

  loadGoldPrice() {
    this.setState({ loader_active: true })
    Utility.get(Settings.baseUrl + '/gold_price/?limit=30', (e, resJson) => {
      localStorage.setItem("goldpric", JSON.stringify(resJson[0]));
      this.setState({
        items: this.setFieldValue(resJson),
        loader_active: false
      })
    });
  }

  componentDidMount() {
    this.loadGoldPrice()
  }

  setFieldValue(items) {
    for (let i in items) {
      items[i].date = Utility.formatDate(items[i].record_date)
      items[i].time = Utility.formatTime(items[i].record_date)
      items[i].gold_bar_sell = Utility.priceFormat(items[i].gold_bar_sell)
      items[i].gold_bar_buy = Utility.priceFormat(items[i].gold_bar_buy)
      items[i].gold_ornaments_buy = Utility.priceFormat(items[i].gold_ornaments_buy)
      items[i].gold_ornaments_sell = Utility.priceFormat(items[i].gold_ornaments_sell)
    }
    return items
  }

  render() {

    const items = this.state.items
    return (
      <Modal open={true} /*dimmer='blurring'*/ onClose={this.close} size='large'>
        <Button
          circular
          icon='close'
          basic
          floated='right'
          name=''
          onClick={this.props.onClose} />
        <Modal.Header>
          ราคาทอง
        </Modal.Header>
        <Modal.Content>

          <div className="segment">
            <div id='table-price'>
              <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={35}
                width={this.state.table_width}
                height={this.state.table_hiegh}>

                <Column

                  header={<Cell>วันที่อัพเดท</Cell>}
                  cell={
                    <ItemsCell position='top left' width={120} data={items} field="date" />
                  }
                  width={120}
                />
                <Column

                  header={<Cell>เวลา</Cell>}
                  cell={
                    <ItemsCell position='top left' width={80} data={items} field="time" />
                  }
                  width={80}
                />
                <Column
                  header={<Cell className='text-right'>ราคาขายทองคำแท่ง</Cell>}
                  cell={
                    <ItemsCell position='top right' width={160} data={items} field="gold_bar_sell" textAlign='text-right' />
                  }
                  width={160}
                />
                <Column
                  header={<Cell className='text-right'>ราคาซื้อทองคำแท่ง</Cell>}
                  cell={
                    <ItemsCell position='top right' width={160} data={items} field="gold_bar_buy" textAlign='text-right' />
                  }
                  width={160}
                />
                <Column
                  header={<Cell className='text-right'>ราคาซื้อคืนทองรูปพรรณ</Cell>}
                  cell={
                    <ItemsCell position='top right' width={160} data={items} field="gold_ornaments_buy" textAlign='text-right' />
                  }
                  width={160}
                />

                <Column
                  header={<Cell className='text-right'>ราคาขายทองรูปพรรณ</Cell>}
                  cell={
                    <ItemsCell position='top right' width={160} data={items} field="gold_ornaments_sell" textAlign='text-right' />
                  }
                  width={160}
                />
              </Table>
            </div>
          </div>
          <Dimmer active={this.state.loader_active} inverted>
            <Loader inverted content='Loading' />
          </Dimmer>

        </Modal.Content>
        <Modal.Actions>

          <Button size='small' type='button' onClick={this.handleUpdate}><Icon name='refresh' />อัพเดท</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Gole;
