/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import {Table, Column, Cell} from 'fixed-data-table';
import Settings from '../../../../Settings';
import Utility from '../../../../Utility';
import ItemsCell from '../../ItemsCell'
import OptionItemsCell from '../../OptionItemsCell'
import {
    Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid
  } from 'semantic-ui-react';
class BillItemTable extends Component {

    constructor(props){
        super(props)
        this.state = {
            table_width: 600,
            width_lis: false,
            items: this.props.items,
            log:[],
            page:1,
            loader_log: true

        }
        this.loadlogdata = this.loadlogdata.bind(this)

    }

    componentDidMount(){
        setTimeout(()=>{
            window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()
          },400)
          this.loadlogdata()
    }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    
      updateDimensions = () => {
        let elHeight = document.getElementById('table_width2')
        this.setState({ 
            table_width: elHeight.clientWidth-42,
            table_width_cell: (elHeight.clientWidth-42)/8,
            width_lis:true
     });
      };
      loadlogdata = () =>
      {
        var invoices = Utility.getFetch(Settings.baseUrl + '/gold_99_log/?page=' +this.state.page+'&page_size='+10+'&pk='+this.state.items.id);
        Promise.all([invoices]).then((values) => {
          var items = values[0].results
          this.setState({
            log: items,
            loader_log: false,
          });
        });

      }
    render() {
        const items = []
        return (
        <Modal size="large" open={true} /*dimmer='blurring'*/>
            {this.state.loader_log&&!this.state.width_lis && <Dimmer active={this.state.loader_log&&!this.state.width_lis} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>}
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
           
            <>
         <Modal.Header>
            <Header as='h4' className="text-center">
             <Header.Content >
              รายการยกเลิก
           </Header.Content>
         </Header>
       </Modal.Header>
       <Modal.Content id="table_width2">
       {this.state.width_lis ?
       <div id="table_width2">
            <Table
                rowsCount={this.state.log.length}
                rowHeight={35}
                headerHeight={30}
                width={this.state.table_width}
                height={300}>
               
                <Column
                    header={< Cell className = 'text-center' > เลขที่บิล </Cell>}
                    cell={< ItemsCell position='top center' width={100} data = {
                        this.state.log
                }
                field = "number" textAlign = 'text-center' />}
                    width={120}/>
                
                <Column
                    header={< Cell className = 'text-center' > วันที่ </Cell>}
                    cell={< ItemsCell position='top center' width={this.state.table_width_cell} data = {
                        this.state.log
                }
                field = "import_date" textAlign = 'text-center' />}
                    width={120}/>
                <Column
                    header={< Cell className = 'text-center' > ชื่อโรงงาน </Cell>}
                    cell={< ItemsCell position='top center' width={150} data = {
                        this.state.log
                }
                field = "vendor_name" textAlign = 'text-center' />}
                    width={120}/>
                <Column
                    header={< Cell className = 'text-right' > น้ำหนักนำเข้า </Cell>}
                    cell={< ItemsCell position='top right' width={150} data = {
                        this.state.log
                }
                field = "weight" textAlign = 'text-right' />}
                    width={120}/>
                <Column
                    header={< Cell className = 'text-right' > ราคาทองคำ(ต้นทุน/กรัม) </Cell>}
                    cell={< ItemsCell position='top right' width={150} data = {
                        this.state.log
                }
                field = "cost" textAlign = 'text-right' />}
                    width={180}/>
                 <Column
                    header={< Cell className = 'text-right' > ราคาทองคำ </Cell>}
                    cell={< ItemsCell position='top right' width={150} data = {
                        this.state.log
                }
                field = "gold_price" textAlign = 'text-right' />}
                    width={120}/>
                <Column
                    header={< Cell className = 'text-center' > ผู้ทำรายการยกเลิก  
                    </Cell>}
                    cell={< ItemsCell position='top center' width={150} data = {
                        this.state.log
                }
                field = "void_username" textAlign = 'text-center' />}
                    width={150}/>

                <Column
                    header={< Cell className = 'text-center' > วันที่/เวลา ยกเลิก   </Cell>}
                    cell={< ItemsCell position='top center' width={this.state.table_width_cell} data = {
                        this.state.log
                }
                field = "void_datetime" textAlign = 'text-center' />}
                    width={150}/>
                <Column
                    header={< Cell > เหตุผลที่ยกเลิก   </Cell>}
                    cell={< ItemsCell position='top left' width={this.state.table_width_cell} data = {
                        this.state.log
                }
                field = "description_void" textAlign = 'text-left' />}
                    width={150}/>

            </Table></div>:''}
       </Modal.Content>
       
         </>
      </Modal>
        )
    }

}

export default BillItemTable