/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Header, Dropdown
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';

class Customer extends Component {
    constructor(props) {
        super(props);

        this.modal_open = false;
        this.state = {
            customers: []
        }
        this.handlerSearchCustomer = this.handlerSearchCustomer.bind(this)
        this.handlerDropdownSearch = this.handlerDropdownSearch.bind(this)
    }

    handlerSearchCustomer(e, v) {

        this.setState({
            isLoading: true
        });
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => { this.loadCustomerPage(v.searchQuery) }, 1600)
    }

    handlerDropdownSearch(e, v) {
        if (this.props.onChange != null && this.data) {

            let data = Utility.getObject(this.data.results, v.value)
            this.props.onChange(e, v, data)
        }

    }

    componentDidMount() {
    }
    loadCustomerPage(text) {

        let q1 = {
            columnKey: 'name',
            page: 1,
            sortDi: 'ASC',
            text: text
        };
        if (this.props.is_employee) {
            q1['is_employee'] = 'True'
        }
        let q = Utility.jsonToQueryString(q1)
        let url = '/customers/?is_enabled=1&page_size=30&'
        if(this.props.is_staff){
            url += 'is_employee=True&'
        }
        Utility.get(Settings.baseUrl + url + q, (status, data) => {
            if (status == true) {
                this.data = data
                this.setOption(data.results)

            }
        });
    }

    setOption(data) {
        let result = []

        for (let i in data) {
            let d = data[i]
            let l = []
            if (d.code && d.code != '')
                l.push(d.code)
            if (d.mobile && d.mobile != '')
                l.push(d.mobile)
            if (d.citizen_id && d.citizen_id != '')
                l.push(d.citizen_id)

            result.push({
                key: d.id,
                value: d.id,
                text: d.name + (l.length > 0 ? ' (' + l.join(',') + ')' : ''),
                data: d,
                mobile: d.mobile,
                content: <div>
                    <b>{d.name}</b><br />
                    <small>Code: {d.code}</small><br />
                    <small>CitizenID: {d.citizen_id}</small><br />
                    <small>Phone: {d.mobile}</small>
                </div>,
            })
        }

        this.setState({
            isLoading: false,
            customers: result,
        })
    }

    loadCustomerInfo(id) {
        this.value = id;
        Utility.get(Settings.baseUrl + '/customers/' + id + "/", (status, data) => {
            if (status == true) {
                this.setOption([data])
            }
        });
    }

    render() {

        if (this.props.value != null && this.props.value != '' && this.props.value != this.value) {
            this.loadCustomerInfo(this.props.value)
        }

        if (this.props.as == 'Dropdown')
            return (
                <Dropdown
                    id='searchNameCus2'
                    placeholder={this.props.placeholder} search fluid
                    label={this.props.label}
                    width={this.props.width}
                    selection
                    name={this.props.name}
                    loading={this.state.isLoading}
                    isFetching={this.state.isLoading}
                    onSearchChange={this.handlerSearchCustomer}
                    value={this.props.value}
                    disabled={this.state.isLoading}
                    options={this.state.customers}
                    searchInput={this.props.searchInput}
                    onKeyPress={this.props.onKeyPress}
                    onChange={this.handlerDropdownSearch} />

            );
        else
            return (
                <Form.Dropdown
                    id='searchNameCus'
                    placeholder={this.props.placeholder} search fluid
                    label={this.props.label}
                    width={this.props.width}
                    selection
                    name={this.props.name}
                    loading={this.state.isLoading}
                    isFetching={this.state.isLoading}
                    onSearchChange={this.handlerSearchCustomer}
                    value={this.props.value}
                    options={this.state.customers}
                    disabled={this.state.isLoading}
                    searchInput={this.props.searchInput}
                    onChange={this.handlerDropdownSearch} />)
    }
}

export default Customer;
