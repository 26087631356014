/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form,
    Input,
    Header,
    Button,
    Grid,
    Modal,
    Dimmer,
    Loader,
    TextArea,
    Label,
    Icon,
    Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-dates/lib/css/_datepicker.css';
import { Table, Column, Cell } from 'fixed-data-table';
import Utility from '../../Utility';
import Settings from '../../Settings';
import { get_noti } from '../../actions';

import { connect } from 'react-redux'
class ItemsIconCell extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            rowIndex,
            field,
            data,
            ...props
        } = this.props;
        return (
            <Cell {...props}>
                <div className='text-center'>{!data[rowIndex][field] ? <Icon name='minus circle' color='yellow' /> : <Icon color='green' name='check circle outline' />}</div>
            </Cell>
        );
    }
}

class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            rowIndex,
            field,
            data,
            ...props
        } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (
            <Cell {...props}>
                <Popup
                    position={this.props.position}
                    content={data[rowIndex][field]}
                    trigger={
                        <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
                />
            </Cell>
        );
    }
}


class LeaseOutModal extends Component {
    constructor(props) {
        super(props);
        this.setting = props.setting
        this.state = {
            amount: this.props.lease.amount,
            bill_number: this.props.lease.number,
            customer_name: this.props.lease.customer_name,
            citizen_id: this.props.lease.citizen_id,
            weight: this.props.lease.weight,
            description: this.props.lease.description,
            total_interest: this.props.lease.total_interest,
            interest: this.props.lease.interest,
            month: this.props.lease.month,
            phone: this.props.lease.phone,
            lease_product: this.setFieldValue(this.props.lease_product),
            start_date: moment(new Date(this.props.lease.start_date)),
            end_date: moment(new Date(this.props.lease.end_date)),
            code: '',
        }
        this.handlerOut = this.handlerOut.bind(this)
    }

    componentDidMount() {

    }

    handlerOut(e) {
        if (!window.confirm('ยืนยันคัดออก'))
            return;
        if (!window.confirm('ต้องการบันทึกประเภทและน้ำหนัก ไปยังสต็อกทองเก่าหรือไม่?'))
            return;
        this.setState({
            loading_save: true
        })
        const url = Settings.baseUrl + "/lease/" + this.props.lease.id + "/stock/?action=in"
        Utility.getAuth(url, () => {
            console.log('out')
            alert('คัดออกสำเร็จ')
            this.props.getNoti(this.props.auth.branch)
            this.props.onClose()
        })
    }

    submitQRcode() {
        let number = this.state.code.trim()
        let lease_product = this.state.lease_product
        let check = false
        for (let i in lease_product) {
            if (lease_product[i].qrcode == number) {
                if (lease_product[i].check) {
                    alert('สินค้าถูกเลือกแล้ว')
                }
                lease_product[i].check = true
                check = true
                break;
            }
        }
        if (check) {
            this.setState({
                lease_product: lease_product,
            })
        }
        else {
            alert('ไม่มีสินค้านี้ในใบขายฝาก')
        }
        this.setState({
            code: '',
        })
    }

    setFieldValue(list) {
        const items = []
        let item_all = list.length
        let item_check = 0
        for (let i in list) {
            let item = list[i]
            item['category_title'] = list[i].category.name
            if (item['check']) {
                item_check++;
            }
            items.push(item)
        }
        this.setState({
            item_all: item_all,
            item_check: item_check,
        })
        return items;
    }

    render() {
        let items = this.state.lease_product
        let item_all = items.length
        let item_check = 0
        for (let i in items) {
            if (items[i]['check']) {
                item_check++;
            }
        }
        return (

            <Modal id='ModalLeaseOut' size="large" open={true} >
                <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={(this.props.onClose)} />

                <Modal.Header>
                    <Header as='h4'>
                        <Header.Content>
                            คัดออกใบขายฝาก : {this.state.bill_number}
                        </Header.Content>
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <div >
                        <Dimmer active={this.props.loading || this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <div>
                            <Form className='attached fluid' size='small'>
                                <Grid >
                                    <Grid.Row >
                                        <Grid.Column width={5}>
                                            <Form.Field width={16} >
                                                <label>ชื่อลูกค้า</label>
                                                <Input
                                                    id='customer'
                                                    value={this.state.customer_name}
                                                    name='customer_name'
                                                    readOnly={true}
                                                />
                                            </Form.Field>
                                            <Form.Field width={16}>
                                                <label>เลขบัตรประชาชน</label>
                                                <Input
                                                    id='inputID'
                                                    value={this.state.citizen_id}
                                                    name='citizen_id'
                                                    readOnly={true}
                                                />
                                            </Form.Field >
                                            <Form.Field width={16}>
                                                <label>เบอร์โทรศัพท์</label>
                                                <Input
                                                    id='inputPhone'
                                                    value={this.state.phone}
                                                    readOnly={true}
                                                    name='phone'
                                                />
                                            </Form.Field >
                                            <Form.Field width={16}>
                                                <label>จำนวนเงิน</label>
                                                <Input
                                                    id='inputAmount'
                                                    readOnly={true}
                                                    value={Utility.priceFormat(this.state.amount)}
                                                    className="text-right"
                                                />
                                            </Form.Field>
                                            <Form.Field width={16}>
                                                <label>ระยะเวลา (เดือน)</label>
                                                <Input
                                                    id='inputTime'
                                                    className="text-right"
                                                    readOnly={true}
                                                    value={this.state.month}
                                                />
                                            </Form.Field>
                                            <Form.Group>
                                                <Form.Field width={16}>
                                                    <label>อัตราดอกเบี้ย (%)</label>
                                                    <Input
                                                        id='inputInterestRatio'
                                                        className="text-right"
                                                        readOnly={true}
                                                        value={this.state.interest.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                    />
                                                </Form.Field>
                                                <Form.Field width={16}>
                                                    <label>ดอกเบี้ย/เดือน</label>
                                                    <Input
                                                        id='inputInterestPerMonth'
                                                        className="text-right"
                                                        readOnly={true}
                                                        value={Utility.priceFormat(this.state.total_interest)}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Field width={16}>
                                                    <label>วันนำเข้า</label>
                                                    <DatePicker
                                                        selected={this.state.start_date}
                                                        selectsStart
                                                        readOnly={true}
                                                        dateFormat="DD/MM/YYYY"
                                                    />
                                                </Form.Field>
                                                <Form.Field width={16}>
                                                    <label>วันครบกำหนด</label>
                                                    <DatePicker
                                                        selected={this.state.end_date}
                                                        readOnly={true}
                                                        selectsEnd
                                                        dateFormat="DD/MM/YYYY"
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Field width={16}>
                                                    <label>หมายเหตุ</label>
                                                    <TextArea
                                                        width={16}
                                                        readOnly={true}
                                                        value={this.state.description} />
                                                </Form.Field>
                                            </Form.Group>
                                        </Grid.Column>
                                        <Grid.Column width={11}>
                                            <Form size='small'>
                                                <Form.Group>
                                                    <Form.Field width={10}>
                                                        <Header.Content floated='left' as='h3'>
                                                            รายการสินค้า
                  <Header.Subheader>
                                                                <small>
                                                                    <Label as='span' basic>
                                                                        <Icon color='green' name='check circle outline' /> {item_check}</Label> <Label as='span' basic><Icon name='minus circle' color='yellow' /> {item_all - item_check}</Label></small></Header.Subheader>
                                                        </Header.Content>
                                                    </Form.Field>
                                                    <Form.Field width={6}>
                                                        <label>QR Code</label>
                                                        <Input
                                                            floated='right'
                                                            className='text-right'
                                                            autoFocus
                                                            ref={(c) => {
                                                                this.qrcodee = c
                                                            }}
                                                            onKeyPress={(e) => {
                                                                if (e.key == 'Enter') {
                                                                    this.submitQRcode()
                                                                }
                                                            }}
                                                            onChange={(e) => this.setState({ code: e.target.value })}
                                                            value={this.state.code}
                                                        />
                                                    </Form.Field>
                                                </Form.Group>
                                            </Form>
                                            <div id="table_width2">
                                                <Table
                                                    rowsCount={items.length}
                                                    rowHeight={35}
                                                    headerHeight={35}
                                                    width={1000}
                                                    height={400}>
                                                    <Column
                                                        header={< Cell className='text-center' > สถานะ </Cell>}
                                                        cell={< ItemsIconCell data={
                                                            items
                                                        }
                                                            field="check" />}
                                                        width={50} />
                                                    <Column
                                                        header={< Cell > %ทอง </Cell>}
                                                        cell={< ItemsCell position='top left' width={120} data={
                                                            items
                                                        }
                                                            field="category_title" />}
                                                        width={120} />
                                                    <Column
                                                        header={< Cell > ชื่อสินค้า </Cell>}
                                                        cell={< ItemsCell position='top left' width={150} id='ProductName' data={
                                                            items
                                                        }
                                                            field="product_name" />}
                                                        width={150} />
                                                    <Column
                                                        header={< Cell className='text-right' > น้ำหนัก</Cell >}
                                                        cell={< ItemsCell position='top right' width={100} data={
                                                            items
                                                        }
                                                            field="weight" textAlign='text-right' />}
                                                        width={100} />
                                                </Table>
                                            </div>
                                            <br />
                                            <Form className='fluid' size='small'>
                                                <Form.Group >
                                                    <Form.Input
                                                        id='invoice_weight_total'
                                                        label='น้ำหนักรวม'
                                                        placeholder=''
                                                        className='text-right'
                                                        width={4}
                                                        value={this.state.weight}
                                                        readOnly />
                                                    <Form.Input
                                                        id='invoice_itme_total'
                                                        label='จำนวนรายการ'
                                                        placeholder=''
                                                        className='text-right'
                                                        width={4}
                                                        value={this.state.lease_product.length}
                                                        readOnly />
                                                </Form.Group>
                                            </Form>

                                        </Grid.Column>
                                    </Grid.Row >
                                </Grid>
                            </Form>
                        </div>
                    </div>

                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id='btnSaveLease'
                        size='small'
                        color={'red'}
                        icon='cancel'
                        loading={this.state.loading_save}
                        disabled={item_check != item_all}
                        content={'คัดออกและอัพเดทสต็อก'}
                        onClick={this.handlerOut}
                    />
                </Modal.Actions>
            </Modal >
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getNoti: branch => get_noti(dispatch, branch)
})

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches,
        lease_modal: state.lease_modal
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaseOutModal)
