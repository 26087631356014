/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, Radio
} from 'semantic-ui-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import InputSearchCustomer from '../../Customer/InputSearchCustomer';
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        user_type: "",
        user_id: "",
        user_name: "",
        start_date: moment(),
        end_date: moment(),
      },
      items: [],
      cal: [],
      new_data: [],
      user_type: [
        {
          value: "",
          key: 0,
          text: 'ทั้งหมด'
        },
        {
          value: "True",
          key: 1,
          text: 'พนักงาน'
        },
        {
          value: "False",
          key: 2,
          text: 'ลูกค้า'
        }
      ],
      choice:  0,
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }

  componentDidMount() {
    this.setState({ st: true })
    var url_string = window.location.toString();
    var url = new URL(url_string);
    var temp_start = url.searchParams.get("start_date");
    var temp_end = url.searchParams.get("end_date");
    if (temp_start !== null && temp_end !== null) {
      this.setState({
        search: {
          start_date: moment(temp_start, 'DD/MM/YYYY'),
          end_date: moment(temp_end, 'DD/MM/YYYY')
        }
      })
    }
  }

  resetForm(e) {
    this.setState({
      search: {
        user_type: "",
        user_name: "",
        user_id: "",
        start_date: moment(),
        end_date: moment(),
      },
      choice: 0,
    })
  }

  changForm(e) {
    if (this.state.choice === 0){
      this.setState({
        choice:1,
        items:[]
      })
    } else {
      this.setState({
        choice:0,
        items:[]
      })
    }
  }

  handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)
    search.start_date = Utility.formatDate2(search.start_date)
    search.end_date = Utility.formatDate2(search.end_date)
    let fromData = Utility.jsonToQueryString(search)
    var url = Settings.baseReportUrl + '/score/report/?'
    if (this.state.choice === 1){
      var url = url + `start_date=${search.start_date}&end_date=${search.end_date}&`
    }
    if (search.customer_id){
      var url = url + `cus_code=${search.customer_id}`
    }
    // let search_categories = []
    this.setState({
      open: true,
      loading: true
    })
    Utility.get(url, (s, d) => {
     

      if (s == true) {
        this.setState({
          items: d.data,
          cal:d.score_item
        })
        // this.state.items.map((score, i) => {
        //   let cal_amount = 0
        //   let total = score.total
        //   let del = 0
        //   this.state.cal.map((score_item, j) => {
        //     if (score.id === score_item.score){
        //       if (score_item.action === 'ADD'){
        //         cal_amount += score_item.amount
        //       } else {
        //         del -= score_item.amount
        //       }
        //     }
        //   })
        //   score["total_amount"] = total
        //   score["amount"] = cal_amount
        //   score["del"] = del
        // })
        // var newArray = this.state.items.filter(function (el) {
        //   return el.amount > 0
        // });
        // this.setState({items:newArray})
        
        this.setState({
          loading:false,
        })
      }
    })
    var url = Settings.baseReportUrl + "/customers/"
    Utility.get(url, (s, d) => {
      if (s == true){
        this.setState({
          all_customer: d.count
        })
      }
    })
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search,
      items:[]
    })
  }
  render() {
    let data = this.props.data

    let title = 'รายงานแต้มสะสม';
    let filename = 'score';

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }

    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center',
      'width': '200px'

    }
    const staffWidth = {
      'width': '300px'
    }
    const textU = {
      'text-decoration': 'underline'
    }
    let amount = 0
    let weight = 0
    let weight_b = 0
    let sell = 0
    let wage = 0
    let net = 0
   

    let branch_name = ''
    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>รายงานแต้มสะสม</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >
            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
              <Form.Field>
                <label>ลูกค้า</label>
                <InputSearchCustomer placeholder='ลูกค้า' width={16} onChange={this.handlerInput} name="customer_id" value={this.state.search.customer_id} />
              </Form.Field>
              <Form.Field>
                <Radio toggle label={this.state.choice === 0 ? 'ทั้งหมด':'เลือกวันที่'} checked={this.state.choice} onChange={(e)=>this.changForm(e)} />
              </Form.Field>
              {this.state.choice === 1 ?
              <>
              <Form.Field>
                  <label>จากวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.start_date}
                      selected={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          if (this.state.search.end_date < date) {
                              this.handlerInput(null, {
                                  name: 'end_date',
                                  value: date
                              });
                          }
                          this.handlerInput(null, {
                              name: 'start_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              <Form.Field >
                  <label>ถึงวันที่</label>
                  <DatePicker
                      dateFormat="DD/MM/YYYY"
                      value={this.state.search.end_date}
                      selected={this.state.search.end_date}
                      minDate={this.state.search.start_date}
                      onChange={(date) => {
                          date = date ? date : moment()
                          this.handlerInput(null, {
                              name: 'end_date',
                              value: date
                          });
                      }}
                  />
              </Form.Field>
              </>
              :
              []
              }
              {/* <Form.Field>
                <label>ชื่อลูกค้า</label>
                <Input placeholder="ชื่อลูกค้า" value={this.state.search.user_name} name="user_name" onChange={this.handlerInput} />
              </Form.Field>
              <Form.Field>
                <label>รหัสลูกค้า</label>
                <Input placeholder="รหัสลูกค้า" value={this.state.search.user_id} name="user_id" onChange={this.handlerInput} />
              </Form.Field>
              <Form.Field>
                <label>ประเภทลูกค้า</label>
                <Dropdown placeholder='- ทั้งหมด -' search selection options={this.state.user_type} value={this.state.search.user_type} name='user_type' onChange={this.handlerInput} />
              </Form.Field> */}
              <Button>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='paperA4-portrait'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan= {this.state.cal.length > 0 ? '8' : '7' }><center>รายงานแต้มสะสม</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan = '1' style={textCenter}>ลำดับ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '1' style={textCenter}>รหัสลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '2' style={textCenter}>ชื่อลูกค้า</Table.HeaderCell>
                      {this.state.cal.length > 0 ?
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right", paddingRight: "40px"}}>แต้มสะสม</Table.HeaderCell>:null}
                      <Table.HeaderCell colSpan = '1' style={{textAlign: "right", paddingRight: "40px"}}>แต้มสะสมคงเหลือ</Table.HeaderCell>
                      <Table.HeaderCell colSpan = '2' style={{textAlign: "right", width: "150px"}}>วันที่ล่าสุดที่ได้รับแต้มสะสม<span style={{paddingRight: "80px"}} /></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.items.length > 0 ? this.state.items.map((row, i) =>{
                        let total =this.state.cal.length > 0 ?  this.state.cal.find((item) => item.score_id == row.id) : null
                        // console.log(row.id,'log-->',total);
                        
                      return(
                      <Table.Row key={i}>
                        <Table.Cell colSpan = '1' style={textCenter}>{i+1}</Table.Cell>
                        <Table.Cell colSpan = '1' style={textCenter}>{row.cus_code}</Table.Cell>
                        <Table.Cell colSpan = '2' style={{}}>{row.user_name}</Table.Cell>
                        {this.state.cal.length > 0 ?
                        <Table.Cell colSpan = '1' style={{textAlign: "right", paddingRight: "40px"}}>{total ? Utility.numberFormat(total.dsum):0}</Table.Cell>:null}
                        <Table.Cell colSpan = '1' style={{textAlign: "right", paddingRight: "40px"}}>{row.total > 0 ?  Utility.numberFormat(row.total) : 0 }</Table.Cell>
                        <Table.Cell colSpan = '2' style={{textAlign: "right"}}> {total ? (Utility.formatDate(total.createdate)): (Utility.formatDate(row.last_update))}<span style={{paddingRight: "110px"}} /></Table.Cell>
                      </Table.Row>)
                      }) : null
                    }

                    <Table.Row >
                      {/* <Table.Cell colSpan = '1' style={textCenter}></Table.Cell>
                      <Table.Cell colSpan = '1' style={textCenter}></Table.Cell>
                      <Table.Cell colSpan = '2' style={{paddingLeft: "100px"}}></Table.Cell>
                      <Table.Cell colSpan = '1' style={{paddingLeft: "60px"}}></Table.Cell>
                      <Table.Cell colSpan = '2' style={{textAlign: "center"}}><b>มีผู้ได้รับแต้มทั้งหมด {Utility.numberFormat(this.state.items.length)} คน จากลูกค้าทั้งหมด {Utility.numberFormat(this.state.all_customer)} คน</b></Table.Cell> */}
                      <Table.Cell colSpan={this.state.cal.length > 0 ? '8' : '7' } style={{textAlign: "right"}}><b>มีผู้ได้รับแต้มทั้งหมด {Utility.numberFormat(this.state.items.length)} คน จากลูกค้าทั้งหมด {Utility.numberFormat(this.state.all_customer)} คน</b><span style={{paddingRight: "80px"}} /></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(()=>{
                mywindow.close();
              },2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)