/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import ImportPattern from '../Import/ImportPattern'
import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader, Dropdown,
  Header, TextArea, Menu,
  Popup,
  Pagination,
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { connect } from 'react-redux'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {
  }

  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell><center>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a>
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class Pattern extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      description: '',
      code: '',
      modal_open: false,
      categories_id: 0,
      msg_error: {},
      is_enabled: 1,
      activeItem: 'แสดง',
      totalPages: 0,
      activePage: 1,
      count: 0,
      search: { },
      colSortDirs: {
        'code': 'ASC',
      },
    }
    this.columnKey = 'code'
    this.sortDi = 'ASC'
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.clearFormSearch = this.clearFormSearch.bind(this);
    this.onSortChange = this.onSortChange.bind(this)
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.handlerSubmitSearch()

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handleItemClick = (e, { name }) => {
    let status = 1
    if (name === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    this.setState({
      loader_active: true
    });
    let str = Utility.jsonToQueryString(this.state.search);
    Utility.get(Settings.baseUrl + '/pattern/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&page=1&'+str, (status, data) => {
      if (status)
        this.setState({
          items: this.setFieldValue(data.results),
          loader_active: false,
          activePage: 1,
          totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
          count: data.count
        });
    });
    this.setState({ activeItem: name })
  }

  handlePaginationChange = (e, { activePage }) => {
    let status = 1
    if (this.state.activeItem === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    this.setState({
      loader_active: true
    });
    let str = Utility.jsonToQueryString(this.state.search);
    Utility.get(Settings.baseUrl + '/pattern/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&page=' + activePage+ '&' + str, (status, data) => {
      if (status)
        this.setState({
          items: this.setFieldValue(data.results),
          loader_active: false,
          activePage: activePage,
          totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
          count: data.count
        });
    });
  }

  submitData(e) {
    console.log('rr')
    e.preventDefault()
    var formData = {
      name: this.state.name,
      // code: this.state.code,
      // category_id: this.state.categories_id,
      description: this.state.description,
      is_enabled: this.state.is_enabled,
    };

    const self = this;
    var msg_error = {}
    // if (this.state.code.length != 3) {
    //   msg_error.code = "*ต้องมี3ตัวอักษร"
    //   if (this.state.name.length == 0) {
    //     msg_error.name = "*ต้องไม่เป็นค่าว่าง"
    //   }
    //   this.setState({
    //     msg_error: msg_error
    //   })
    // }else{
    this.setState({ button_class: 'loading' ,msg_error: {}})
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/add_pattern/';
      Utility.post(url, formData, (status, data)=> {
        msg_error = data
        this.setState({ button_class: '' ,msg_error: {}})
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        }else { // error
          if (data.code !== undefined){
            if(data.code[0] === "Ensure this field has no more than 3 characters."){
            msg_error.code = ["*ต้องไม่มากกว่า3ตัวอักษร"]
          }
          if(data.code[0] == 'pattern และ code มีอยู่แล้ว'){
            msg_error.code = ["*รหัสนี้มีอยู่ในระบบแล้ว"]
          }
          }
          if (data.name !== undefined){
            if(data.name[0] == 'pattern และ name มีอยู่แล้ว'){
              msg_error.name = ["*ชื่อนี้มีอยู่ในระบบแล้ว"]
            }
            if (data.name !== undefined) {
              if (data.name[0] == 'pattern และ name มีอยู่แล้ว') {
                msg_error.name = ["*ชื่อนี้มีอยู่ในระบบแล้ว"]
              }

            }
            this.setState({
              msg_error: msg_error
            })
          }
          this.setState({
            msg_error: msg_error
          })
        }
      });
    } else {
      const url = Settings.baseUrl + '/pattern/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data)=> {
        msg_error = data
        this.setState({ button_class: '' ,msg_error: {}})
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        }else { // error
          if (data.code !== undefined){
            if(data.code[0] === "Ensure this field has no more than 3 characters."){
            msg_error.code = ["*ต้องไม่มากกว่า3ตัวอักษร"]
          }
          if(data.code[0] == 'pattern และ code มีอยู่แล้ว'){
            msg_error.code = ["*รหัสนี้มีอยู่ในระบบแล้ว"]
          }
          }
          if (data.name !== undefined){
            if(data.name[0] == 'pattern และ name มีอยู่แล้ว'){
              msg_error.name = ["*ชื่อนี้มีอยู่ในระบบแล้ว"]
            }
            if (data.name !== undefined) {
              if (data.name[0] == 'pattern และ name มีอยู่แล้ว') {
                msg_error.name = ["*ชื่อนี้มีอยู่ในระบบแล้ว"]
              }

            }
            this.setState({
              msg_error: msg_error
            })
          }
        }
      });
    }
  // }
}

  resetForm() {
    console.log('ggg')
    this.setState({
      name: '',
      code: '',
      description: '',
      categories_id: 0,
      button_class: false,
      is_enabled: 1,
    });
  }

  componentDidMount() {
    this.setState({
      loader_active: true,
      // activeItem: 'แสดง',
    });
    let status = 1
    if (this.state.activeItem != 'แสดง') {
      status = 0
    }
    var categories = Utility.getFetch(Settings.baseUrl + '/categories?is_enabled=1');
    var product_types = Utility.getFetch(Settings.baseUrl + '/pattern/??columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&page=1');
    Promise.all([categories, product_types]).then((values) => {

      this.categories = values[0];
      let cat = [{
        key: 0,
        value: 0,
        text: 'ทั้งหมด',
      }];
      for (let i in values[0]) {
        cat.push({
          key: values[0][i].id,
          value: values[0][i].id,
          text: values[0][i].name,
        });
      }

      this.setState({
        categories: cat,
        items: this.setFieldValue(values[1].results),
        loader_active: false,
        activePage: 1,
        totalPages: values[1].next == null && values[1].previous == null ? 0 : Math.ceil(values[1].count / 10),
        count: values[1].count
      });
    });
  }

  setFieldValue(v) {

    for (let k in v) {
      let cat = Utility.getObject(this.categories, v[k].category_id);
      if (cat)
        v[k].category_name = cat.name;
      else
        v[k].category_name = 'ทั้งหมด';
    }

    return v;
  }
  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: row.name,
        code: row.code,
        is_enabled: row.is_enabled,
        description: row.description,
        object_id: row.id,
        msg_error: {}
      });
    } else {
      this.setState({ confirm_open: true, object_id: row.id });
    }

  }

  handlerSearch(event, v) {
      let search = this.state.search;
      search[v.name] = v.value;
      if (v.name == 'text') {
        search[v.name] = v.value.trim();
      }
      this.setState({
        search: search
      });
  }

  handlerSubmitSearch(e, data) {
    let page = data == undefined ? this.state.activePage : 1
    this.setState({
      loader_active: true
    });
    let str = Utility.jsonToQueryString(this.state.search);
    let active = this.state.activeItem == 'แสดง' ? 1 : 0
    Utility.get(Settings.baseUrl + '/pattern/?columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + active + '&page=' + 1 + '&' + str, (s, data) => {
      let results = data.results
      this.setState({
        loader_active: false,
        items: results,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        activePage: page,
        count: data.count,
        // activeItem: 'แสดง',
      });
    })
  }

  resetForm() {

    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }
    this.setState({
      search: search,
    });
    this.componentDidMount()
  }

  clearFormSearch() {

    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }

    this.setState({
      search: search,
    });
  }

  render() {
    const items = this.state.items;
    const { auth } = this.props
    return (
      <div>
        {this.state.import_pattern_open ?
          <ImportPattern
            onClose={() => {
              this.setState({
                import_pattern_open: false,
              });
            }}
            onUpdate={() => {
              this.setState({
                import_pattern_open: false,
              });
              this.componentDidMount()
            }} /> : ''}
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>ลายทอง</Header>
            </Form.Field>
            <Form.Field width={16}>
              {auth.role == 'A' || auth.role == 'S' ?
                <Button
                  id='importBranch'
                  size='small' content='Import'
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      import_pattern_open: true,
                    });
                  }}
                  floated='right' icon='file alternate outline' labelPosition='left' type='button' primary />
                : ''}
              <Button id='addPattern' size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  msg_error: {},
                  modal_action: 'add',
                  modal_title: 'สร้างลายทอง',
                  name:'',
                  description:'',
                });
              }}
                floated='right' icon='plus' labelPosition='left' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} /*dimmer='blurring'*/ size='tiny' >
          <Button
            id='closePattern'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                {/* <Form.Field error={this.state.msg_error.code}>
                  <label>*รหัส <MsgInput text={this.state.msg_error.code} /></label>
                  <Input id='patternID' placeholder='รหัส' onChange={(e) => this.setState({ code: e.target.value })} value={this.state.code} input={<input maxlength="3" minlength="3" />} />
                  <i>*เป็นตัวเลขหรือตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ 3 ตัวอักษรเท่านั้น</i>
                </Form.Field> */}
                <Form.Field error={this.state.msg_error.name}>
                  <label>*ชื่อ <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='patternName' placeholder='ชื่อ' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={100} />

                </Form.Field>

                <Form.Field error={this.state.msg_error.description != null}>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea placeholder='หมายเหตุ' onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} maxLength={255} />
                </Form.Field>

                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown id='patternStatus' selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />

                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='confirmPattern' size='small' primary onClick={this.submitData}
              className={this.state.button_class ? 'loading' : ''}>บันทึก</Button>
            <Button id='cancelPattern' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <label>รหัสลายทอง,ชื่อลายทอง</label>
              <Input maxLength={100} placeholder='รหัสลายทอง,ชื่อลายทอง' 
              value={this.state.search.text} 
              onChange={this.handlerSearch} 
              name='text' />
            </Form.Field>

            <Form.Field width={6}>
              <label><br /></label>
              <Button id='btnsearchStaff' 
              onClick={this.handlerSubmitSearch} 
              size='small' type='button'><Icon name='search' /> ค้นหา</Button>
              <Button id='allStaff' 
              onClick={this.resetForm} 
              size='small' type='button' >ทั้งหมด</Button>
              <Button id='clearSearch' 
              onClick={this.clearFormSearch} 
              size='small' type='button' >รีเซ็ต</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='code'
                sortDir={this.state.colSortDirs.code}>
                รหัส
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={80} data={items} field="code" />
              }
              width={80}
            />
            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='name'
                sortDir={this.state.colSortDirs.name}>
                ชื่อลายทอง
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={300} data={items} field="name" />
              }
              width={300}
            />
            <Column
              header={<Cell>หมายเหตุ</Cell>}
              cell={
                <ItemsCell position='top left' width={300} data={items} field="description" />
              }
              width={300}
            />
          </Table></div>
        <Form size='small'>
          <Form.Group>
            <Form.Input width={2} id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.count)} />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>
        </Form>
        <Confirm
          id='deletePattern'
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/pattern/' + this.state.object_id + "/";
            var self = this;
            Utility.delete(url, (status, data) => {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              } else {
                alert(data.error)
              }
            });

          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(mapStateToProps)(Pattern);
