/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { Table, Column, Cell } from 'fixed-data-table';
import ItemsCell from '../ItemsCell'
import OptionItemsCell from '../OptionItemsCell'

import { Icon, Popup } from 'semantic-ui-react';

class IconItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    return (
      <Cell {...props}>
        { }
        <Icon name={!data[rowIndex].bill_status ? 'warning circle' : 'check circle'} color={!data[rowIndex].bill_status ? 'yellow' : 'green'} />
      </Cell>
    );
  }
}
class IconActionCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, onActionItemCell, ...props } = this.props;
    let rowdata = data[rowIndex]
    let rowitems = rowIndex

    return (<>
      {
        !rowdata.bill_import ?
          <OptionItemsCell delete={false} onClickMenu={(e) => { onActionItemCell(e, rowitems, 'add') }} />
          : rowdata.bill_import.weight === rowdata.bill_import.weight_remain ?
            <OptionItemsCell edit={false} delete={true} onClickMenu={(e) => { onActionItemCell(e, rowitems, 'del') }} />
            : <OptionItemsCell view={true} delete={false} edit={false} onClickMenu={(e) => { onActionItemCell(e, rowitems, 'view') }} />
      }</>
    );
  }
}
class ButtonActionCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, onActionItemCell, ...props } = this.props;
    let rowdata = data[rowIndex]
    let rowitems = rowIndex

    return (<Cell>

      {rowdata.have_cencell ?
        <button type="button" onClick={(e) => { onActionItemCell(e, rowIndex, 'log') }}
        >Log</button>
        : <></>}
    </Cell>
    );
  }
}
class ItemOBJCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let width = (this.props.width - 10) + 'px'
    const { rowIndex, objfeild, field, data, onActionItemCell, ...props } = this.props;
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    if (data[rowIndex][field] !== null) {
      return (
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field][objfeild]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign} >{data[rowIndex][field][objfeild]}</div>}
          />
        </Cell>
      );
    } else {
      return (
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={''}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign} ></div>}
          />
        </Cell>
      );
    }
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class GoldMeltTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      table_width: 100
    }
  }

  componentDidMount() {

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  render() {
    const items = this.props.items

    return (
      <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={387}>
          <Column
            width={40}
            header={
              <Cell ></Cell>
            }
            cell={
              <IconActionCell data={items} onActionItemCell={this.props.onActionItemCell} e={this} />
            }
          //   {
          //     items.status_stock == 'N' ? 
          //     <OptionItemsCell delete={false} onClickMenu={this.props.onActionItemCell} /> 
          //     : 
          //     <OptionItemsCell edit={false} delete={true} onClickMenu={this.props.onActionItemCell} /> 
          // }</ItemsCell>
          //     }
          // cell={<OptionItemsCell delete={false} onClickMenu={this.props.onActionItemCell} />}
          />
          <Column

            header={<Cell></Cell>}
            cell={
              <IconItemsCell data={items} field="status_stock_title" />
            }
            width={30}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='import_gold'
              sortDir={this.props.colSortDirs.import_gold}>
              สถานะปรับปรุงสต็อก
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={150} data={items} field="bill_status_display" />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='number'
              className='text-center'
              sortDir={this.props.colSortDirs.number}>
              เลขที่ Lot
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top left' width={120} id='tablebill' data={items} field="number" className='text-center' />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='number_b99'
              className='text-center'
              sortDir={this.props.colSortDirs.number_b99}>
              เลขที่บิลนำเข้า
            </SortHeaderCell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="number" width={120} position='top center' className='text-center' />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='lot_date'
              className='text-center'
              sortDir={this.props.colSortDirs.lot_date}>
              วันที่สร้างรายการ
            </SortHeaderCell>}
            // header={<Cell className='text-center'>วันที่สร้างรายการ</Cell>}
            cell={
              <ItemsCell position='top left' width={120} id='tablebill' data={items} field="lot_date" className='text-center' />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='import_date_b99'
              className='text-center'
              sortDir={this.props.colSortDirs.import_date_b99}>
              วันที่ปรับปรุงสต็อก
            </SortHeaderCell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="import_date" width={120} position='top center' className='text-center' />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              onSortChange={this.props.onSortChange}
              field='vendor_b99'
              sortDir={this.props.colSortDirs.vendor_b99}>
              ชื่อโรงงาน
            </SortHeaderCell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="vendor_name" width={120} position='top center' />
            }
            width={120}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight_out'
              sortDir={this.props.colSortDirs.weight}>
              น้ำหนักชั่งส่งหลอม (96.5)
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={130} data={items} field="weight_out" textAlign='text-right' />
            }
            width={150}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='gold_price'
              sortDir={this.props.colSortDirs.gold_price}>
              ราคาทองคำ 99.99 ณ วันส่งหลอม
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={250} data={items} field="gold_price" textAlign='text-right' />
            }
            width={250}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='cost'
              sortDir={this.props.colSortDirs.cost}>
              มูลค่าต้นทุนทองคำ 96.5
            </SortHeaderCell>}
            cell={
              <ItemsCell position='top right' width={180} data={items} field="cost" textAlign='text-right' />
            }
            width={180}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='weight_b99'
              sortDir={this.props.colSortDirs.weight_b99}>
              น้ำหนักทองคำ 99.99 นำเข้า
            </SortHeaderCell>}
            // header={<Cell className='text-right'> น้ำหนักทองคำนำเข้า</Cell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="weight" width={180} position='top center' textAlign='text-right' />
            }
            width={180}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='cost_b99'
              sortDir={this.props.colSortDirs.cost_b99}>
              ต้นทุนทองคำ 99.99 นำเข้า(กรัม)
            </SortHeaderCell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="cost" width={200} position='top center' textAlign='text-right' />
            }
            width={200}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='cost_b99'
              sortDir={this.props.colSortDirs.cost_b99}>
              ต้นทุนทองคำ 99.99 นำเข้า(บาท)
            </SortHeaderCell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="cost_bath" width={200} position='top center' textAlign='text-right' />
            }
            width={200}
          />
          <Column
            header={<SortHeaderCell
              className='text-right'
              onSortChange={this.props.onSortChange}
              field='gold_price_b99'
              sortDir={this.props.colSortDirs.gold_price_b99}>
              ราคาทองคำ 99.99 ณ วันนำเข้า
            </SortHeaderCell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="gold_price" width={120} position='top center' textAlign='text-right' />

            }
            width={200}
          />
          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemOBJCell data={items} field="bill_import" objfeild="description" width={120} position='top center' />
            }
            width={150}
          />
          <Column
            header={<Cell className='text-center'>Log</Cell>}
            cell={
              <ButtonActionCell data={items} width={50} onActionItemCell={this.props.onLogCell} position='top center' className='text-center' />
            }
            width={50}
          />
        </Table></div>
    )
  }

}

export default GoldMeltTable

