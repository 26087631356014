/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import CustomerProfile from './CustomerProfile';
import MsgInput from '../Error/MsgInput'
import ImportCustomer from '../Import/ImportCustomer'
import CustomerModal from './CustomerModal'
import SettingModal from './SettingModal'
import moment from 'moment'
import { connect } from 'react-redux'

import {
  Form,
  Input,
  Button,
  Icon,
  Dimmer,
  Loader,
  Header,
  Segment,
  Pagination,
  Popup
} from 'semantic-ui-react';

import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

    //this.props.onClickMenu(e);
  }

  render() {
    const { rowIndex, field, data, role, ...props } = this.props;
    return (
      <Cell>
        {
          data[rowIndex].id !== 1 &&
          <center>
            <a onClick={(e) => { this.props.onClickMenu(e, { action: 'view', positon: rowIndex }) }} ><Icon name='address card' /></a>
            <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
            {role == 'A' || role == 'M' || role == 'S' ? <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a> : ''}
          </center>
        }
      </Cell>
    );
  }
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={<div className={this.props.textAlign} id={rowIndex/*+'-'+data[rowIndex].id*/} style={ellipsisStyle}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class Customer extends Component {

  constructor(props) {
    super(props);

    // limit item
    this.limit = 10
    this.gender_options = [
      {
        key: 'MA',
        value: 'MA',
        text: 'ชาย',
      }, {
        key: 'FE',
        value: 'FE',
        text: 'หญิง',
      }, {
        key: 'NA',
        value: 'NA',
        text: 'ไม่ระบุ',
      }
    ]

    this.state = {
      activeItem: 'info',
      items: [],
      name: '',
      code: '',
      mobile: '',
      description: '',
      citizen_id: '',
      modal_open: false,
      search: {
        text: ''
      },
      limit: 0,
      page: 1,
      activePage: 1,
      counter: 0,
      setting: [],
      colSortDirs: {
        'code': 'ASC',
      },
      table_hiegh: 500,
      msg_error: {},
      totalPages: 1
    }
    this.columnKey = 'code'
    this.sortDi = 'ASC'
    this.submitData = this.submitData.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClick = this.handleClick.bind(this)

    this.handleItemClick = this.handleItemClick.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.loadCustomerPage = this.loadCustomerPage.bind(this)
    this.loadCustomerPage2 = this.loadCustomerPage2.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.loadCustomerPage(activePage)
  }

  submitData() {

    let msg_error = {}
    if (this.state.name == null || this.state.name == '') {
      msg_error['name'] = ['ต้องไม่เป็นค่าว่าง']
    }

    if (this.state.code == null || this.state.code == '') {
      msg_error['code'] = ['ต้องไม่เป็นค่าว่าง']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error })
      return;
    }

    var formData = {
      name: this.state.name,
      code: this.state.code,
      mobile: this.state.mobile,
      description: this.state.description,
      citizen_id: this.state.citizen_id,
      birth_date: '2017-01-01'
    };
    let setting = this.state.setting
    for (let i in setting) {
      const o = setting[i]
      if (this.state[o.key])
        formData[o.key] = this.state[o.key]
    }

    const self = this;
    this.setState({ button_class: 'loading' })
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/customers/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/customers/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      code: '',
      description: '',
      mobile: '',
      citizen_id: '',
      button_class: '',
      search: { text: '' },
      msg_error: {}
    });
  }


  loadCustomer(customer, action) {

    const url = Settings.baseUrl + '/customers/' + customer.id + "/";
    Utility.get(url, (s, r, c) => {
      r['career'] = r.career != null ? r.career.id : ''
      r['workplace'] = r.workplace != null ? r.workplace.id : ''
      if (action == 'view') {
        this.setState({
          modal_profile_open: true,
          modal_title: r.name,
          name: r.name,
          code: r.code,
          citizen_id: r.citizen_id,
          mobile: r.mobile,
          description: r.description,
          object_id: r.id,
          positon: r.positon,
          customer: r
        });
      } else {
        this.setState({
          modal_open: true,
          modal_action: 'edit',
          customer: r
        });
      }


    })
  }


  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      search: { text: '' }
    });
    let system_setting = JSON.parse(localStorage.getItem('system_setting'))
    system_setting = system_setting.filter(setting => setting.setting == 'CUSF')
    if (system_setting.length == 0) {
      Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
        for (let i in data) {
          if (data[i].setting == 'CUSF') {
            this.sys_setting_id = data[i].id
            this.setState({
              setting: JSON.parse(data[i].value)
            })
            break
          }
        }
      });
    } else {
      this.sys_setting_id = system_setting[0].id
      this.setState({
        setting: JSON.parse(system_setting[0].value)
      })
    }

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
    this.loadCustomerPage(1);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  loadCustomerPage2(page) {
    this.setState({ activePage: 1 })
    let q1 = {
      columnKey: this.columnKey,
      page: page,
      sortDi: this.sortDi,
      page_size: this.limit,
    };
    let q = Utility.jsonToQueryString(q1)
    Utility.get(Settings.baseUrl + '/customers/?is_enabled=1&' + q, (status, data) => {
      if (status == true) {
        this.customers = data.results;
        let customers = [];
        this.setState({
          items: this.setFieldValue(data.results),
          totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / this.limit),
          counter: data.count,
          loader_active: false,
        });
      }
    });
  }

  loadCustomerPage(activePage) {

    this.setState({
      loader_active: true,
      activePage: activePage
    });

    let q1 = {
      columnKey: this.columnKey,
      page: activePage,
      sortDi: this.sortDi,
      page_size: this.limit,
      text: this.state.search.text.trim()
    };
    let q = Utility.jsonToQueryString(q1)

    Utility.get(Settings.baseUrl + '/customers/?is_enabled=1&' + q, (status, data) => {
      if (status == true) {
        this.customers = data.results;
        let customers = [];
        this.setState({
          items: this.setFieldValue(data.results),
          totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / this.limit),
          counter: data.count,
          loader_active: false,
        });
      }
    });
  }


  handlerSearch(event, v) {

    if (v.name) {
      let search = this.state.search;
      search[v.name] = v.value;
      this.setState({
        search: search
      });
      return;
    } else {
      const target = event.target;
      const value = target.type == 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let search = this.state.search;
      search[name] = value;
      this.setState({
        search: search
      });
    }
  }

  handlerSubmitSearch(e) {

    this.loadCustomerPage(1)
  }

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit' || d.action == 'view') {

      this.resetForm();
      this.loadCustomer(row, d.action)

    } else {
      if (window.confirm('ยืนยันลบ')) {
        const url = Settings.baseUrl + '/customers/' + row.id + "/";
        Utility.delete(url, (status, data) => {
          if (status) {
            this.loadCustomerPage(this.state.activePage)
            this.resetForm();
          } else {
            alert(data.error)
          }
        });
      }


    }
  }



  setFieldValue(items) {
    let _items = []
    for (let i in items) {
      const item = Utility.cloneObjectJson(items[i])
      item['career_name'] = item.career != null ? item.career.name : ''
      item['workplace_name'] = item.workplace != null ? item.workplace.name : ''
      _items.push(item)
    }
    return _items
  }


  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loadCustomerPage(1)

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handleItemClick(e, v) {
    this.setState({
      activeItem: v.name
    })
  }

  render() {
    const items = this.state.items;
    const { role } = this.props.auth
    let setting = []
    for (let i in this.state.setting) {
      if (this.state.setting[i].check)
        setting.push(this.state.setting[i])
    }

    var { colSortDirs } = this.state;
    let activeItem = this.state.activeItem
    return (
      <div>

        {this.state.import_open ?
          <ImportCustomer

            onClose={() => {
              this.setState({
                import_open: false,
              });
            }}
            onUpdate={() => {
              this.setState({
                import_open: false,
              });
              this.componentDidMount()
            }} /> : ''}
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h3'><Icon name='users' /> ลูกค้า</Header>
            </Form.Field>
            <Form.Field width={10}>
              {role == 'A' || role == 'S' ?
                <Button id='btnImport' content='Import' size='small' onClick={() => this.setState({ import_open: true })} floated='right' icon='file alternate outline' labelPosition='left' type='button' primary />
                : ''}
              <Button size='small' content='เพิ่ม' onClick={(e) => {
                e.preventDefault()
                this.resetForm()
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างลูกค้า'
                });
              }}
                floated='right' icon='plus' labelPosition='left' type='button' primary />
              <Button size='small' content='ตั้งค่า' onClick={(e) => {
                e.preventDefault();
                this.setState({
                  open_setting: true
                })
              }}
                floated='right' icon='setting' labelPosition='left' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <Segment color='black'>


          <ContextMenu id="menu_lese_list">
            <MenuItem
              data={{ action: 'view' }}
              onClick={this.handleClick}>ดูโปรไฟล์</MenuItem>
            <MenuItem
              data={{ action: 'edit' }}
              onClick={this.handleClick}>แก้ไข</MenuItem>
            <MenuItem
              data={{ action: 'delete' }}
              onClick={this.handleClick}>ลบ</MenuItem>
          </ContextMenu>
          <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
            <Loader content='Loading' inverted />
          </Dimmer>

          {this.state.modal_profile_open ? <CustomerProfile onClickMenu={this.handleClick} onClose={(e) => { this.setState({ modal_profile_open: false }) }} customer_id={this.state.object_id} positon={this.state.positon} setting={this.state.setting} /> : ''}

          {this.state.modal_open && <CustomerModal open={true}
            onUpdate={() => {
              this.setState({ modal_open: false })
              this.loadCustomerPage(this.state.activePage)
            }}
            onCreate={() => {
              this.loadCustomerPage(this.state.totalPages == 0 ? 1 : this.state.totalPages)
            }} onClose={() => { this.setState({ modal_open: false }) }} customer={this.state.customer} action={this.state.modal_action} />}

          <div id='root-segment'>
            <Form size='small'>
              <Form.Group>
                <Form.Field width={13}>
                  <Input maxLength={255} icon='search' iconPosition='left' placeholder='รหัสลูกค้า, ชื่อลูกค้า, เบอร์มือถือ, เลขประจำตัวประชาชน, อีเมล' onChange={this.handlerSearch} value={this.state.search.text} name='text' />
                </Form.Field>
                <Form.Field width={3}>
                  <Button size='small' floated='right' onClick={(e) => {
                    this.resetForm(e);
                    this.loadCustomerPage2(1)
                    // this.componentDidMount();
                  }} type='button' >ทั้งหมด</Button>
                  <Button size='small' onClick={this.handlerSubmitSearch} floated='right' type='submit'><Icon name='search' />ค้นหา</Button>
                </Form.Field>
              </Form.Group>
            </Form>
            <div className='tabled-customer' id="table_width">
              <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={35}
                width={this.state.table_width}
                height={387}>
                <Column
                  width={80}
                  header={
                    <Cell ></Cell>
                  }
                  cell={<OptionItemsCell onClickMenu={this.handleClick} role={role} data={items} />}
                />
                <Column
                  header={<SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='code'
                    sortDir={colSortDirs.code}>
                    รหัสลูกค้า
            </SortHeaderCell>}
                  cell={
                    <ItemsCell position='top left' data={items} width={120} field="code" />
                  }
                  width={120}
                />
                <Column
                  header={<SortHeaderCell
                    onSortChange={this.onSortChange}
                    field='name'
                    sortDir={colSortDirs.name}>
                    ชื่อ
            </SortHeaderCell>}
                  cell={
                    <ItemsCell position='top left' width={200} id='tableSeachCustomerName' data={items} field="name" />
                  }
                  width={200}
                />
                <Column
                  header={<Cell>เบอร์มือถือ</Cell>}
                  cell={
                    <ItemsCell position='top left' width={200} id='tableSeachCustomerPhone' data={items} field="mobile" />
                  }
                  width={200}
                />
                <Column
                  header={<Cell>อีเมล</Cell>}
                  cell={
                    <ItemsCell position='top left' width={200} id='tableSeachCustomerEmail' data={items} field="email" />
                  }
                  width={200}
                />
                <Column
                  header={<Cell>อาชีพ</Cell>}
                  cell={
                    <ItemsCell position='top left' width={200} id='tableSeachCustomerEmail' data={items} field="career_name" />
                  }
                  width={200}
                />
                <Column
                  header={<Cell>สถานที่ทำงาน</Cell>}
                  cell={
                    <ItemsCell position='top left' width={200} id='tableSeachCustomerEmail' data={items} field="workplace_name" />
                  }
                  width={200}
                />
                <Column
                  header={<Cell>เลขประจำตัวประชาชน</Cell>}
                  cell={
                    <ItemsCell position='top left' width={200} id='tableSeachCustomerCardID' data={items} field="citizen_id" />
                  }
                  width={200}
                />
                <Column
                  header={<Cell>หมายเหตุ</Cell>}
                  cell={
                    <ItemsCell position='top left' width={200} data={items} field="description" />
                  }
                  width={200}
                />
              </Table>
            </div>
            <br />
            <Form size='small'>
              <Form.Group>
                <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
                <br />
                <Form.Field width={14}>
                  <br />
                  {this.state.totalPages > 1 && <Pagination
                    size='tiny'
                    floated='right'
                    activePage={this.state.activePage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={this.state.totalPages}
                  />}
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
          {this.state.open_setting && <SettingModal
            sys_setting_id={this.sys_setting_id}
            setting={this.state.setting}
            onClose={() => { this.setState({ open_setting: false }) }}
            onChange={(setting) => this.setState({ setting: setting })}
          />}
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = state => {

  return ({
    auth: state.auth
  })
}
export default connect(
  mapStateToProps
)(Customer)
