/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';
class ImportProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields([],[]),
            is_loading: false
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]

            let url_search = Settings.baseUrl + '/lease/?number='+data.lease
            Utility.get(url_search,(e,res)=>{
                
                if(res.length>0){
                    let url = Settings.baseUrl + '/lease/'+res[0].id+'/interest/'
                    data['lease'] = res[0].id
                    data['status'] = 2
                    data['import'] = true
                    if (res[0].status == 1){
                        run()
                    }else{
                        Utility.post(url,data,(e,res)=>{
                            position++;
                            if(position<data_list.length)
                                run()
                            else
                                this.props.onUpdate()
                        })
                    }
                }else{
                    position++;
                        if(position<data_list.length)
                            run()
                        else
                            this.props.onUpdate()
                }
        })

        }
        run()
    }

    setFields(){
        return [
            {
                name: 'lease',
                title: '*เลขที่ขายฝาก',
                type: 'string',
                required: true,
            },
            {
                name: 'pay_date',
                title: '*วันที่จ่าย (ดด/วว/ปปปป)',
                type: 'date',
                required: true
            },
            {
                name: 'interest_date',
                title: '*วันที่ครบกำหนด (ดด/วว/ปปปป)',
                type: 'date',
                required: true
            },
            {
                name: 'total',
                title: '*จำนวนเงิน',
                type: Number,
                required: true,
            },
            {
                name: 'total_receive',
                title: '*จำนวนเงินรับ',
                required: true,
                type: Number
            },
            {
                name: 'description',
                title: 'หมายเหตุ',
                required: false,
                type: 'string'
            }
        ]
    }

  componentDidMount() {


  }

    render() {

        return (
            <div>
                <ImportCSV
                    name='LeaseInterest'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportProduct