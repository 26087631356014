/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';

import Utility from '../../Utility';
import Settings from '../../Settings';
import DeleteItemsCell from './Cell/DeleteItemsCell'
import {
    Form,
    Input,
    Button,
    Header,
    Dimmer,
    Loader,
    Popup,
} from 'semantic-ui-react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import AddProductModal from './Modal/AddProductModal'
import 'react-dates/lib/css/_datepicker.css';
import { connect } from 'react-redux'




function collect(props) {
    return { positon: props.positon };
}
class ItemsCell extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { rowIndex, field, type, data, ...props } = this.props;

        let v = type == 'number' ? Utility.numberFormat(data[rowIndex][field])
            : type == 'price' ? Utility.priceFormat(data[rowIndex][field])
                : type == 'weight' ? Utility.weightFormat(data[rowIndex][field])
                    : data[rowIndex][field];
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        return (
            <ContextMenuTrigger id="menu_product_list"
                holdToDisplay={1000}
                key={rowIndex}
                positon={rowIndex}
                collect={collect}>
                <Cell {...props} onClick={(e) => {
                }} className='cell-time-click'>
                    <Popup
                        position={this.props.position}
                        content={v}
                        trigger={
                            <div style={ellipsisStyle} className={(this.props.textAlign == null ? '' : this.props.textAlign) + ' text-cell'}>{v}</div>}
                    />

                </Cell>
            </ContextMenuTrigger>
        );
    }
}

class ProductList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeItem: window.location.pathname,
            table_width: 600,
            table_hiegh: 100,
            lease_product: [],
            total_weight: '',
        }

        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleSubmitProduct = this.handleSubmitProduct.bind(this)
        this.handleAddProduct = this.handleAddProduct.bind(this)
        this.handleModalAddProduct = this.handleModalAddProduct.bind(this)
        this.handlerSearch = this.handlerSearch.bind(this)
        this.handleResultSelect = this.handleResultSelect.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.lease_product = []
    }

    handleDelete(e, d) {
    }

    handleModalAddProduct(e, d) {
        this.props.reload_lease_data();
        setTimeout(() => {
            if (this.props.status == 3 || this.props.status == 4)
                alert('ไม่สามารถเพิ่มรายการสินค้าในใบขายฝากที่มีสถานะ ไถ่คืน/คัดออก')
            else
                this.setState({ modal_product: true })
        }, 200);
    }

    handleResultSelect(e, value) {

        this.setState({
            name: value.result.title
        })
    }

    handlerInput(e, v) {
        this.setState({
            [v.name]: v.value
        })
    }

    handlerSearch(e, value) {

        this.setState({
            name: value.value
        })
        // clearTimeout(this.timeout)
        // this.timeout = setTimeout(() => {
        //     let url = Settings.baseUrl + "/product_name/?q=" + value.value
        //     Utility.get(url, (s, d) => {
        //         let search_results = [];

        //         for (let i in d) {
        //             search_results.push({
        //                 "title": d[i].name,
        //             })
        //         }

        //         this.setState({
        //             search_results: search_results
        //         })
        //     })
        // }, 1000);

    }

    handleAddProduct(e) {
        e.preventDefault()
    }

    resetForm() {
        this.setState({
            category_id: '',
            name: '',
            weight: '',
            amount: '',
        })
    }


    handleItemClick(e, { name, to }) {
        this.setState({
            activeItem: to
        });
    }

    componentDidMount() {
        var categories = Utility.getFetch(Settings.baseUrl + '/categories/?is_enabled=1');
        Promise.all([categories]).then((values) => {
            this.categories = values[0]
            let categories = []
            for (let i in this.categories) {
                categories.push({
                    key: this.categories[i].id,
                    value: this.categories[i].id,
                    text: this.categories[i].name
                })
            }
            this.setState({
                categories: categories,
                loading: false,
            })
            this.loadProductList();
        });
    }

    loadProductList() {
        if (this.props.id) {
            let lease_product = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.id + '/product/?is_enabled=1');
            this.setState({
                loading: true
            })
            Promise.all([lease_product]).then((values) => {
                this.setState({
                    lease_product: this.setFieldValue(values[0]),
                    loading: false,
                    total_weight: this.getTotalWeight(values[0])
                })
                this.props.onChangeProductList(this.getTotalWeight(values[0]));
            });
        }
    }

    getTotalWeight(values) {
        let weight = 0;
        for (let i in values) {
            weight += parseFloat(values[i].weight)
        }
        return weight.toFixed(3);
    }

    setFieldValue(list) {
        let j = 1
        for (let i in list) {
            list[i].category_name = list[i].category.name
            list[i].no = j++;
        }

        return list;

    }

    handleClick(e, d) {
        let object = this.state.lease_product[d.positon]
        if (this.props.status == 3 || this.props.status == 4)
            return
        if (this.props.id) {
            if (window.confirm('ยืนยันลบรายการนี้')) {

                this.setState({
                    loading: true
                })
                Utility.delete(Settings.baseUrl + "/lease/" + this.props.id + "/product/" + object.id + "/", (s, d, c) => {
                    if (c == 400) {
                        alert(d.error)
                        this.props.reload_lease_data()
                    }
                    if (c == 403)
                        alert('ไม่สามารถลบได้ สิทธิ์ superadmin/admin/manager เท่านั้นที่สามารถลบได้')

                    this.componentDidMount();
                })
            }
        } else {
            let lease_product = this.state.lease_product
            lease_product.splice(d.positon, 1)
            this.setState({
                lease_product: lease_product
            })
            this.setState({
                lease_product: this.setFieldValue(lease_product),
                loading: false,
                total_weight: this.getTotalWeight(lease_product)
            })

            this.props.onChangeProductList(this.getTotalWeight(lease_product), lease_product);
            this.resetForm();
        }
    }

    handleSubmitProduct(formData) {
        if (this.props.id) {

            this.setState({
                loading_button: true
            })
            let url = Settings.baseUrl + '/lease/' + this.props.id + '/product/'
            formData.category = formData.category.id
            Utility.post(url, formData, (s, d) => {

                this.componentDidMount();
                this.resetForm();
                this.setState({
                    loading_button: false,
                    modal_product: false
                })
            })
        } else {

            this.lease_product.push(formData)
            this.setState({
                lease_product: this.setFieldValue(this.lease_product),
                loading: false,
                total_weight: this.getTotalWeight(this.lease_product),
                modal_product: false
            })

            this.props.onChangeProductList(this.getTotalWeight(this.lease_product), this.lease_product);
            this.resetForm();
        }
    }

    render() {

        const items = this.state.lease_product

        return (<div id="content-body">
            <ContextMenu id="menu_product_list">
                <MenuItem data={{ action: 'delete' }} onClick={this.handleClick}>ลบ </MenuItem>
            </ContextMenu>
            <Dimmer active={this.state.loading} inverted>
                <Loader inverted content='Loading' />
            </Dimmer>
            {(this.state.modal_product || this.props.lease_product_is_open) && <AddProductModal
                open={true}
                onSubmit={this.handleSubmitProduct}
                onClose={() => this.setState({ modal_product: false })}
                lease_id={this.props.id} />}
            <Form>
                <Form.Group>
                    <Form.Field width={10}>
                        <Header floated='left' as='h4'>รายการสินค้า<small><br />*กรุณาเพิ่มสินค้าทีละรายการ</small></Header>
                    </Form.Field>
                    <Form.Field width={6}>
                        <Button size='small' disabled={this.props.disabled} content='เพิ่มรายการสินค้า' onClick={this.handleModalAddProduct}
                            floated='right' icon='plus' labelPosition='left' type='button' color='green' />
                    </Form.Field>
                </Form.Group>
            </Form>
            <Form.Field disabled={this.props.status == 3 || this.props.status == 4}>
                <Table

                    rowsCount={items.length}
                    rowHeight={30}
                    headerHeight={30}
                    width={this.props.w_content}
                    height={this.state.table_hiegh}>
                    <Column
                        header={<Cell ></Cell>}
                        cell={
                            <DeleteItemsCell data={items} onClickMenu={this.handleClick} />
                        }
                        width={30}
                    />
                    <Column
                        header={<Cell className='text-right'>NO.</Cell>}
                        cell={
                            <ItemsCell position='top right' width={50} id='ID' data={items} field="no" textAlign='text-right' />
                        }
                        width={50}
                    />
                    <Column
                        header={<Cell className='text-center'>%</Cell>}
                        cell={
                            <ItemsCell position='top center' width={80} textAlign='text-center' data={items} field="category_name" />
                        }
                        width={80}
                    />
                    <Column
                        header={<Cell>ชื่อสินค้า</Cell>}
                        cell={
                            <ItemsCell position='top left' width={100} data={items} field="product_name" />
                        }
                        width={180}
                    />
                    <Column
                        header={<Cell ><div className="text-right">น้ำหนัก</div></Cell>}
                        cell={
                            <ItemsCell position='top right' width={120} data={items} field="weight" textAlign='text-right' type='weight' />
                        }
                        width={120}
                    />
                    {/* <Column
                    header={<Cell className="text-right">จำนวน</Cell>}
                    cell={
                        <ItemsCell position='top right' width={120} data={items} field="amount" textAlign='text-right' />
                    }
                    width={120}
                /> */}
                </Table>
            </Form.Field>
            <div style={{ marginTop: '5px' }}>
                <Form size='small'>
                    <Form.Field inline>
                        <label> น้ำหนักรวม</label>
                        <Input
                            readOnly
                            placeholder='น้ำหนักรวม'
                            value={Utility.weightFormat(this.state.total_weight)}
                            className="text-right" />
                    </Form.Field>
                </Form>
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
    if (state.modal_open.name == 'lease_product')
        return ({
            lease_product_is_open: state.modal_open.is_open,
        })
}

export default connect(
    mapStateToProps,
)(ProductList)
