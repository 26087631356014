/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import OptionItemsCell from './OptionItemsCell'
import MsgInput from '../Error/MsgInput'
import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader, Header, TextArea,
  Popup,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
  }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}


class Vendor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      description: '',
      address: '',
      phone: '',
      modal_open: false,
      msg_error: {}
    }
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  submitData() {
    var formData = {
      name: this.state.name,
      description: this.state.description,
      address: this.state.address,
      phone: this.state.phone
    };

    const self = this;
    this.setState({ button_class: 'loading', msg_error: {} })
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/vendors/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            if (data['name'] == 'vendor และ name มีอยู่แล้ว'){
              data['name'] = 'ชื่อโรงงาน/ร้านส่งซ้ำ'
            }
            this.setState({
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/vendors/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      description: '',
      button_class: '',
      address: '',
      phone: '',
      msg_error: {}
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/vendors/?is_enabled=1', (status, data) => {
      self.setState({
        items: data,
        loader_active: false
      });
    });
  }

  componentWillMount() {
    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        name: row.name,
        address: row.address,
        phone: row.phone,
        description: row.description,
        object_id: row.id
      });
    } else {
      this.setState({ confirm_open: true, object_id: row.id, object: row });
    }
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form >
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>โรงงาน/ร้านส่ง</Header>
            </Form.Field>
            <Form.Field width={16}>
              <Button id='btnAddVendor' content='เพิ่ม' onClick={(e) => {
                e.preventDefault();
                this.resetForm();
                this.setState({
                  modal_open: true,
                  modal_action: 'add',
                  modal_title: 'สร้างโรงงาน/ร้านส่ง'
                });
              }}
                floated='right' icon='plus' labelPosition='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} /*dimmer='blurring'*/ size='tiny'  >
          <Button
            id='btnCloseVendor'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.name}>
                  <label>*ชื่อโรงงาน/ร้านส่ง <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='inputVendorName' placeholder='ชื่อโรงงาน/ร้านส่ง' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={255} />
                </Form.Field>
                <Form.Field>
                  <label>เบอร์โทร</label>
                  <Input id='inputVendorTel' placeholder='เบอร์โทร' onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      this.setState({
                        phone: e.target.value
                      })
                    }
                  }} value={this.state.phone} maxLength={20} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.address != null}>
                  <label>ที่อยู่</label>
                  <Input id='inputVendorAddr' placeholder='ที่อยู่' onChange={(e) => this.setState({ address: e.target.value })} value={this.state.address} maxLength={255} />
                </Form.Field>
                <Form.Field>
                  <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
                  <TextArea placeholder='หมายเหตุ' onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} maxLength={255} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSaveVendor' size='small' primary onClick={(e) => {
              e.preventDefault();
              this.submitData();
              this.setState({ button_class: 'loading' })
            }}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancelVendor' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
            <div id="table_width">
        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={35}
          width={this.state.table_width}
          height={387}>
          <Column
            width={80}
            header={
              <Cell ></Cell>
            }
            cell={<OptionItemsCell onClickMenu={this.handleClick} />}
          />
          <Column
            header={<Cell>รหัส</Cell>}
            cell={
              <ItemsCell position='top left' width={200} id='branchID' data={items} field="id" />
            }
            width={200}
          />
          <Column
            header={<Cell>ชื่อโรงงาน/ร้านส่ง</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="name" />
            }
            width={200}
          />
          <Column
            header={<Cell>เบอร์โทร</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="phone" />
            }
            width={200}
          />
          <Column
            header={<Cell>ที่อยู่</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="address" />
            }
            width={200}
          />
          <Column
            header={<Cell>หมายเหตุ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="description" />
            }
            width={200}
          />
        </Table></div>
        <Form size='small'>
              <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
              </Form.Group>
            </Form>
        <Confirm
          id='modalConfirmDelete'
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {
            const url = Settings.baseUrl + '/vendors/' + this.state.object_id + "/"

            Utility.delete(url, (status, data) => {
              if (status) {
                this.setState({ confirm_open: false });
                this.componentDidMount();
                this.resetForm();
              } else {
                alert(data.error)
              }
            });
          }}
        />
      </div>
    );
  }
}
export default Vendor;
