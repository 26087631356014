/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import ProductFromModal from '../../../Files/Product/ProductFromModal'
import TableRowInput from './TableRowInput'
import {
  Form,
  Button,
  Header,
  Modal,
  Label,
  Table
} from 'semantic-ui-react';
import { connect } from 'react-redux'
import { invoice_item_close, invoice_item_add, invoice_item_set_value } from '../../../../reducers/invoice_item_modal';
import { loadProduct } from '../../../../reducers/products';
class BillItemModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      items: []
    }
  }

  componentDidMount() {
    this.props.onLoadProduct()
  }

  render() {
    const { invoice, items } = this.props.invoice_item_modal
    return (<Modal size='fullscreen' open={true}  /*dimmer='blurring'*/>
      <Modal.Header>
        เพิ่มสินค้า
        </Modal.Header>
      <Modal.Content style={{ 'min-height': '70vh' }} scrolling>

        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h3'>
                <h3>ราคาทองคำ: <Label size='large' as='span' basic>{invoice.cost}</Label></h3>
              </Header>
            </Form.Field>
            <Form.Field width={10}>
              <Button
                id='add'
                disabled={this.state.invoice_add_product_disabled}
                size='small'
                content='เพิ่มรายการ'
                onClick={(e) => {
                  this.props.addItem()
                }}
                floated='right'
                icon='plus'
                labelPosition='left'
                type='button'
                color='green' />
            </Form.Field>
          </Form.Group>
        </Form>
        <Form className='attached fluid' size='small' onSubmit={this.handleSubmit}>
          <Table >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing></Table.HeaderCell>
                <Table.HeaderCell>รหัสสินค้า,ชื่อสินค้า</Table.HeaderCell>
                <Table.HeaderCell>จำนวน</Table.HeaderCell>
                <Table.HeaderCell>น.น.(กรัม)</Table.HeaderCell>
                <Table.HeaderCell>น.น.รวม(กรัม)</Table.HeaderCell>
                {/* <Table.HeaderCell>น.น.ชั่ง(กรัม)</Table.HeaderCell> */}
                <Table.HeaderCell>ราคาต้นทุน/ชิ้น</Table.HeaderCell>
                <Table.HeaderCell>ค่าแรง/ชิ้น</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((o, i) => {
                return (<TableRowInput index={i} />)
              })}
            </Table.Body>
          </Table>

        </Form>
        {this.state.product_modal_open && <ProductFromModal
          onClose={() => {
            this.setState({ product_modal_open: false })
          }}
          action='add'
          loading={this.state.loading_product}
          onSave={async (data) => {
            this.setState({loading_product:true})
            await this.props.onLoadProduct()
            
            if (data.is_enabled) {
              if (this.props.invoice_item_modal.items[this.props.invoice_item_modal.items.length - 1] == undefined) {
                this.props.invoice_item_modal.items.pop()
              } else {
                if (this.props.invoice_item_modal.items[this.props.invoice_item_modal.items.length - 1].product == '') {
                  this.props.invoice_item_modal.items.pop()
                }
              }
              this.props.addItem({
                product: data.id,
                product_error: '',
                amount: '',
                amount_error: '',
                weight: data.weight,
                weight_total: '',
                weight_real: '',
                weight_real_error: '',
                cost: '',
                cost_error: '',
                fee: '',
                fee_error: '',
              })
            }

            this.setState({loading_product:false})
            this.setState({ product_modal_open: false })
          }} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          id='createimport'
          size='small'
          icon='add'
          onClick={(e) => {
            e.preventDefault();
            this.setState({ product_modal_open: true })
          }}
          content="สร้างสินค้า" />
        <Button
          id='addimport'
          size='small'
          disabled={this.state.invoice_add_product_disabled}
          className={this.state.button_product_loading}
          onClick={this.props.onClose}>ยกเลิก</Button>
        <Button
          id='addimport'
          size='small'
          primary
          disabled={this.state.invoice_add_product_disabled}
          className={this.state.button_product_loading}
          onClick={this.props.handleSubmit}>เพิ่ม</Button>
      </Modal.Actions>
    </Modal>)
  }

}



const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches,
    invoice_item_modal: state.invoice_item_modal
  })
}
const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(invoice_item_close())
    },
    addItem: (item = null) => {
      dispatch(invoice_item_add(item))
    },
    onLoadProduct: async () => {
      await loadProduct(dispatch)
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillItemModal)