/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, FormField , Checkbox
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            is_branch:true,
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    resetForm(e) {
        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            is_branch: true,
        })
    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/report/classify_old_gold_melt/?" + fromData
        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: d.report_data,
                })
            }
        })
    }
    
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    render() {
        let title = this.state.is_branch ? 'รายงานแยกประเภททองเก่าส่งหลอม (แยกตามสาขา)' : 'รายงานแยกประเภททองเก่าส่งหลอม (แยกตามประเภท)';
        let filename = 'OGM-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }
        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let summary_count_bu = 0
        let summary_weight_bu = 0
        let summary_cost_bu = 0
        let summary_count_xc = 0
        let summary_weight_xc = 0
        let summary_cost_xc = 0
        let summary_count_df = 0
        let summary_weight_df = 0
        let summary_cost_df = 0
        let summary_count_ej = 0
        let summary_weight_ej = 0
        let summary_cost_ej = 0
        let summary_count = 0
        let summary_cost = 0

        for (let i in this.state.items) {
            summary_count_bu += this.state.items[i].count_bu
            summary_weight_bu += this.state.items[i].weight_bu
            summary_cost_bu += this.state.items[i].cost_bu
            summary_count_xc += this.state.items[i].count_xc
            summary_weight_xc += this.state.items[i].weight_xc
            summary_cost_xc += this.state.items[i].cost_xc
            summary_count_df += this.state.items[i].count_df
            summary_weight_df += this.state.items[i].weight_df
            summary_cost_df += this.state.items[i].cost_df
            summary_count_ej += this.state.items[i].count_ej
            summary_weight_ej += this.state.items[i].weight_ej
            summary_cost_ej += this.state.items[i].cost_ej
            summary_count += this.state.items[i].total_count
            summary_cost += this.state.items[i].total_cost
        }
        
        return (
            <div>
                <Segment color='black' style={{
                    height: '90vh',
                    overflow: 'auto'
                }}>
                    <Form >
                        <Form.Group>
                            <Form.Field>
                                <Header floated='left' as='h4'>รายงานแยกประเภททองเก่าส่งหลอม</Header>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <div className="box-login">
                        <Segment textAlign='left' >

                            <Header size='small'>เงื่อนไขค้นหา</Header>
                            <Form size='small' onSubmit={this.handlerSubmit}>
                                <Form.Field >
                                    <label>จากวันที่</label>
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.search.start_date}
                                        selected={this.state.search.start_date}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            if (this.state.search.end_date < date) {
                                                this.handlerInput(null, {
                                                    name: 'end_date',
                                                    value: date
                                                });
                                            }
                                            this.handlerInput(null, {
                                                name: 'start_date',
                                                value: date
                                            });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>ถึงวันที่</label>
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.search.end_date}
                                        selected={this.state.search.end_date}
                                        minDate={this.state.search.start_date}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }}
                                    />
                                </Form.Field>
                                <Checkbox radio label='แยกตามสาขา' checked={this.state.is_branch} onChange={(e, v) => { this.setState({ is_branch: true }) }} />
                                <br />
                                <Checkbox radio label='แยกตามประเภท' checked={!this.state.is_branch} onChange={(e, v) => { this.setState({ is_branch: false }) }} />
                                <br /><br />
                                <Button>พิมพ์</Button>
                                <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                            </Form>
                        </Segment>
                    </div>
                    <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                        <Modal.Header>Preview</Modal.Header>
                        <Modal.Content className='scrolling'>
                            {this.state.loading && <Dimmer active={this.state.loading} inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>}
                            <div id='view-print'>
                                <div id='paperA4-portrait'>
                                    {this.state.is_branch ?
                                        <Table basic id='table-to-xls' style={divStyle}>
                                            <Table.Header>
                                                <Table.Row >
                                                    <Table.HeaderCell colSpan='16'><center>{title}</center></Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan='16'><center id='branch'>วันที่ {moment(this.state.search.start_date).format('DD/MM/YYYY')} ถึงวันที่ {moment(this.state.search.end_date).format('DD/MM/YYYY')}</center></Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell style={textCenter} >ลำดับ</Table.HeaderCell>
                                                    <Table.HeaderCell style={textLeft} >สาขา</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight} >ทองรับซื้อ</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>น้ำหนักทองเก่ารวม</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight} >ต้นทุนทองรับซื้อ</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>ทองเปลี่ยน</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>น้ำหนักทองเก่ารวม</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>ต้นทุนทองเปลี่ยน</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>ทองชำรุด</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>น้ำหนักทองเก่ารวม</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>ต้นทุนทองชำรุด</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>ทองหลุดจำนำ</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>น้ำหนักทองเก่ารวม</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>ต้นทุนทองหลุดจำนำ</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>รวมรายการ</Table.HeaderCell>
                                                    <Table.HeaderCell style={textRight}>รวมต้นทุน</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {this.state.items.map((row, i) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell style={textCenter}>{Utility.numberFormat(i+1)}</Table.Cell>
                                                            <Table.Cell style={textLeft}>{row.branch_name}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_bu)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_bu)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_bu)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_xc)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_xc)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_xc)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_df)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_df)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_df)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_ej)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_ej)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_ej)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.total_count)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.total_cost)}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table.Row>
                                                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_bu)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_bu)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_bu)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_xc)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_xc)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_xc)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_df)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_df)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_df)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_ej)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_ej)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_ej)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost)}</b></div></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    :
                                        <Table basic id='table-to-xls' style={divStyle}>
                                            <Table.Header>
                                                <Table.Row >
                                                    <Table.HeaderCell colSpan='5'><center>{title}</center></Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan='5'><center id='branch'>วันที่ {moment(this.state.search.start_date).format('DD/MM/YYYY')} ถึงวันที่ {moment(this.state.search.end_date).format('DD/MM/YYYY')}</center></Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell style={textLeft} colSpan='5'><b>ประเภท : ทองรับซื้อ</b></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textCenter}><b>ลำดับ</b></Table.Cell>
                                                    <Table.Cell style={textLeft}><b>สาขา</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>รายการ</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>น้ำหนักทองเก่ารวม</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>ต้นทุน</b></Table.Cell>
                                                </Table.Row>
                                                {this.state.items.sort((a, b) => b.count_bu - a.count_bu || a.branch_id - b.branch_id).map((row, i) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell style={textCenter}>{Utility.numberFormat(i+1)}</Table.Cell>
                                                            <Table.Cell style={textLeft}>{row.branch_name}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_bu)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_bu)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_bu)}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table.Row>
                                                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_bu)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_bu)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_bu)}</b></div></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textLeft} colSpan='5'><b>ประเภท : ทองเปลี่ยน</b></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textCenter}><b>ลำดับ</b></Table.Cell>
                                                    <Table.Cell style={textLeft}><b>สาขา</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>รายการ</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>น้ำหนักทองเก่ารวม</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>ต้นทุน</b></Table.Cell>
                                                </Table.Row>
                                                {this.state.items.sort((a, b) => b.count_xc - a.count_xc || a.branch_id - b.branch_id).map((row, i) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell style={textCenter}>{Utility.numberFormat(i+1)}</Table.Cell>
                                                            <Table.Cell style={textLeft}>{row.branch_name}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_xc)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_xc)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_xc)}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table.Row>
                                                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_xc)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_xc)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_xc)}</b></div></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textLeft} colSpan='5'><b>ประเภท : ทองชำรุด</b></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textCenter}><b>ลำดับ</b></Table.Cell>
                                                    <Table.Cell style={textLeft}><b>สาขา</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>รายการ</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>น้ำหนักทองเก่ารวม</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>ต้นทุน</b></Table.Cell>
                                                </Table.Row>
                                                {this.state.items.sort((a, b) => b.count_df - a.count_df || a.branch_id - b.branch_id).map((row, i) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell style={textCenter}>{Utility.numberFormat(i+1)}</Table.Cell>
                                                            <Table.Cell style={textLeft}>{row.branch_name}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_df)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_df)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_df)}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table.Row>
                                                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_df)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_df)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_df)}</b></div></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textLeft} colSpan='5'><b>ประเภท : ทองหลุดจำนำ</b></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={textCenter}><b>ลำดับ</b></Table.Cell>
                                                    <Table.Cell style={textLeft}><b>สาขา</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>รายการ</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>น้ำหนักทองเก่ารวม</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b>ต้นทุน</b></Table.Cell>
                                                </Table.Row>
                                                {this.state.items.sort((a, b) => b.count_ej - a.count_ej || a.branch_id - b.branch_id).map((row, i) => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell style={textCenter}>{Utility.numberFormat(i+1)}</Table.Cell>
                                                            <Table.Cell style={textLeft}>{row.branch_name}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.numberFormat(row.count_ej)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_ej)}</Table.Cell>
                                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost_ej)}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table.Row>
                                                    <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(summary_count_ej)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(summary_weight_ej)}</b></div></Table.Cell>
                                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(summary_cost_ej)}</b></div></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    }
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="ui primary button small"
                                table="table-to-xls"
                                filename={filename}
                                sheet={title}
                                buttonText="Download as XLS" >
                            </ReactHTMLTableToExcel>

                            <Button primary icon='print' size='small' onClick={() => {
                                var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                                mywindow.document.write('<html><head><title>' + title + '</title>');
                                mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                                mywindow.document.write(document.getElementById('view-print').innerHTML);
                                mywindow.document.write('</body></html>');
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10*/
                                mywindow.print();
                                setTimeout(()=>{
                                    mywindow.close();
                                },2000)
                            }} labelPosition='right' content='Print' />

                            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                        </Modal.Actions>
                    </Modal>
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)