/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux'
import { Segment, Header, Form, Button, Icon, Dimmer, Loader, } from 'semantic-ui-react';
import 'react-dates/lib/css/_datepicker.css';
import BillForm from './BillForm';
import BillList from './BillList'
import { hotkeys } from 'react-keyboard-shortcuts'
import DropdownBranch from '../Widget/DropDown/DropDownBranch'
import { activations_branch, loadBills } from '../../actions'
import moment from 'moment'
import Utility from '../../Utility';
import Settings from '../../Settings';

class Pos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      kind: '',
      bill_action: 'add',
      is_update_billList: false,
      bill_form_open: false,
      bill: {}
    }
    // this.products = []
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleOpenBill = this.handleOpenBill.bind(this)
  }

  handleItemClick(e, { name, content, value, color }) {
    this.setState({
      kind: name,
      title: content,
      color: color,
      bill_action: 'add',
      bill_form_open: true
    });
  }

  componentDidMount() {
    // this.setState({ loader: true })
    // const products = Utility.getFetch(Settings.baseUrl + '/products_no_join/');
    // Promise.all([products]).then((values) => {
    //   this.products = values[0].product
    //   this.setState({ loader: false })
    // });
  }

  handleBranchChange(e, { value }) {
    const { branches } = this.props
    const branch = branches.find((item) => item.id == value)
    this.props.handleBranchChange(branch)
  }

  async handleOpenBill(bill) {

    const req = await Utility.getAsync(Settings.baseUrl + "/bills/" + bill.id + "/")
    if (req.status_code == 200)
      this.setState({
        kind: bill.kind,
        bill: req.data,
        bill_action: 'edit',
        bill_form_open: true
      })
  }

  render() {
    const { branch, role } = this.props.auth

    return (
      <div>
        <Dimmer className={this.state.loader ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Form >
          <Form.Group>
            <Form.Field width={8}>
              <Header floated='left' as='h3'>
                <div style={{ padding: '7px' }} className="pull-left"> <Icon name='cart' /> รายการซื้อ-ขายทอง </div>
                {/* <div className="pull-left">{role==='A' || role==='M' || role==='S' ? <DropdownBranch  fluid size='small' fluid selection onChange={this.handleBranchChange.bind(this)} name="branch" value={branch.id}  />:''}</div> */}
              </Header>
            </Form.Field>

            <Form.Field width={role === 'A' ? 13 : 13}>
              <Button
                id='sellGold'
                size='small0'
                name='DF'
                basic
                floated='right'
                onClick={this.handleItemClick}
                labelPosition='left'
                icon='plus'
                content='ทองชำรุด  (F4)'
                color='grey' />
              <Button
                id='changeGold'
                size='small0'
                name='XC'
                basic
                floated='right'
                onClick={this.handleItemClick}
                labelPosition='left'
                icon='plus'
                content='เปลี่ยนทอง (F3)'
                color='yellow' />
              <Button
                id='buyGold'
                size='small0'
                name='BU'
                basic
                floated='right'
                onClick={this.handleItemClick}
                labelPosition='left'
                icon='plus'
                content='ซื้อทอง (F2)'
                color='red' />
              <Button
                id='sellGold'
                size='small0'
                name='SE'
                basic
                floated='right'
                onClick={this.handleItemClick}
                labelPosition='left'
                icon='plus'
                content='ขายทอง  (F1)'
                color='green' />
            </Form.Field>
          </Form.Group>
        </Form>
        <Router>
          <Segment color='black'>

            {this.state.bill_form_open && <BillForm
              bill={this.state.bill}
              // products={this.products}
              action={this.state.bill_action}
              onClose={() => {
                this.setState({ bill_form_open: false })
                this.props.onLoadBillList(this.props.filter)
              }}
              kind={this.state.kind}
              onReset={(kind) => {
                this.setState({ bill_form_open: false })
                this.props.onLoadBillList(this.props.filter)
                setTimeout(() => {
                  this.setState({ kind: kind, bill_action: 'add', bill: null, bill_form_open: true })
                }, 200)
              }}
              onCreateBill={(bill_number) => {
                this.setState({ bill_number: bill_number })
                this.props.onLoadBillList(this.props.filter)
              }} />
            }
            <BillList
              onItemClick={this.handleOpenBill} />
          </Segment>
        </Router>
      </div>
    );
  }
  hot_keys = {
    'f1': {
      priority: 2,
      handler: (event) => {
        this.handleItemClick(event, {
          name: 'SE'
        })

        event.preventDefault()
      }
    }, 'f2': {
      priority: 2,
      handler: (event) => {
        this.handleItemClick(event, {
          name: 'BU'
        })
        event.preventDefault()
      }
    }, 'f3': {
      priority: 2,
      handler: (event) => {
        this.handleItemClick(event, {
          name: 'XC'
        })
        event.preventDefault()
      }
    }, 'f4': {
      priority: 2,
      handler: (event) => {
        this.handleItemClick(event, {
          name: 'DF'
        })
        event.preventDefault()
      }
    }
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches,
    filter: state.bills_list ? state.bills_list.filter : ''
  })
}
const mapDispatchToProps = dispatch => ({
  handleBranchChange: (branch) => {
    dispatch(activations_branch(dispatch,branch))
    let querytoday = Utility.jsonToQueryString({
      start_date: Utility.formatDate2(moment()),
      end_date: Utility.formatDate2(moment()),
      branch: branch.id,
      is_enabled: 1,
      page: 1,
    });
    loadBills(dispatch, querytoday)
  },
  onLoadBillList: filter => loadBills(dispatch, filter)

})

export default connect(
  mapStateToProps, mapDispatchToProps
)(hotkeys(Pos))
