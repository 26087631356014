/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Input,
  Button,
  Modal,
  TextArea,
  Dropdown,
  Image
} from 'semantic-ui-react';
class StockRewardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kind: 'I',
      msg_error: {},
      is_enabled: 1,
      loading: false,
      modal_title: props.action=='edit'?'แก้ไข':'สร้างสต็อกของรางวัล',
      input_amount : '',
    }

    if(props.action=='edit'){
        this.state['amount'] = props.stock_reward.amount
        this.state['reward'] = props.stock_reward.reward.id
    }

    this.handlerInput = this.handlerInput.bind(this)
    this.submitData = this.submitData.bind(this)
  }

  componentDidMount() {

    var reward = Utility.getFetch(Settings.baseUrl + '/reward/?is_enabled=1&page_size=10000');
    Promise.all([reward]).then((values) => {

        this.reward = values[0].results;
        let reward = []
        for (let i in this.reward) {
          reward.push({
                value: this.reward[i].id,
                key: this.reward[i].id,
                text: this.reward[i].name
            });
        }

        this.setState({
            loader_active: false,
            reward_option: reward,
        });
    });
}


  submitData(e){
    e.preventDefault()

    if(this.props.action=='add'){ // create

      var formData = {
        reward: this.state.reward,
        amount: this.state.input_amount,        
      }

      if( this.state.input_amount<=0){
        this.setState({
            msg_error: {
              amount: 'ต้องมากกว่า 0'
            }
        })
        return false;
      }
      let url = Settings.baseUrl+"/stock_reward/"
      this.setState({loading: true})
      Utility.post(url,formData,(status,res,code)=>{

        if(status){
            this.props.onSaved()
        }else{

            if(res['non_field_errors']!=null){
              alert('ไม่สามารถสร้างได้ เนื้องจากมีการสร้างสต็อกนี้แล้ว')
            }
            this.setState({
                msg_error: res
            })
        }
        this.setState({loading: false})
      })
    }else{ // update
      
      let url = Settings.baseUrl+"/stock_reward_item/"
      var formData = {
        reward: this.state.reward,
        amount: this.state.input_amount,
        description: this.state.description  ,
        kind: this.state.kind  
      }

      if(this.kind=='O' && this.state.input_amount>this.props.stock_reward.amount){

        this.setState({
            msg_error: {input_amount: 'จำนวนสินค้าไม่เพียงพอ สำหรับนำออก'}
        })
        return false;
      }
      if( this.state.input_amount<=0){
        this.setState({
            msg_error: {
              amount: 'ต้องมากกว่า 0'
            }
        })
        return false;
      }
      this.setState({loading: true})
      formData['stock_reward'] = this.props.stock_reward.id
      Utility.post(url,formData,(status,res,code)=>{

        if(status){
            this.props.onSaved()
        }else{
            this.setState({
                msg_error: res
            })
        }
        this.setState({loading: false})
        
      })
    }
  }

  handlerInput(e, v) {
    this.setState({
      [v.name] : v.value
    })
}

  render() {

    return (
      <div>
          <Modal open={true} size='tiny' /*dimmer='blurring'*/  >
      <Button
        circular
        icon='close'
        basic
        floated='right'
        name=''
        onClick={()=>this.props.onClose()}/>
        <Modal.Header>{this.state.modal_title}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <center> {this.props.action=='edit' && 
              <Button.Group>
                <Button onClick={()=>this.setState({kind: 'I'})} className={this.state.kind=='I'?'green':''}>นำเข้า</Button>
                <Button.Or />
                <Button onClick={()=>this.setState({kind: 'O'})} className={this.state.kind=='O'?'red':''}>นำออก</Button>
              </Button.Group>}</center>
            <Form size='small'>
              <Form.Field error={this.state.msg_error.reward!=null}>
                <label>*ของรางวัล <MsgInput text={this.state.msg_error.reward} /></label>
                <Dropdown placeholder='ของรางวัล' search selection options={this.state.reward_option} value={this.state.reward} name='reward' onChange={this.handlerInput} />
              </Form.Field>
              {this.props.action=='edit' && 
              <Form.Field >
                <Input kind="number" onChange={(e) => this.setState({ amount: Number(e.target.value) })} value={this.state.amount} readOnly className='text-right' />
              </Form.Field>
              }
              <Form.Field>
             
              </Form.Field>           
              <Form.Field error={this.state.msg_error.amount!=null}>
                <label>*จำนวน {this.state.kind=='I'?'นำเข้า':'นำออก'}<MsgInput text={this.state.msg_error.amount} /></label>
                <Input className='text-right' 
                  placeholder={this.state.kind=='I'?'จำนวนนำเข้า':'จำนวนนำออก'}
                  onChange={(e) => {
                    if (isNaN(Number(e.target.value))){
                    } else{
                      this.setState({ input_amount: Number(e.target.value) })
                    }      
                  } 
                  } 
                  value={this.state.input_amount}  />
              </Form.Field>
              {this.props.action=='edit' && 
            <Form.Field error={this.state.msg_error.description}>
            <label>*หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label>
              <TextArea name="description" onChange={(e) => this.setState({ description: e.target.value})} value={this.state.description}  />
          </Form.Field>}
              
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button size='small' primary onClick={this.submitData}
            className={this.state.button_class} loading={this.state.loading}>บันทึก</Button>
          <Button size='small' onClick={(e) =>this.props.onClose()}>ยกเลิก</Button>
        </Modal.Actions>
      </Modal>
    </div>

    );
  }
}

export default StockRewardForm;
