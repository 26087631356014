/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from "react";
// import MsgInput from '../../Error/MsgInput'
import {
  Form,
  Message,
  Button,
  Modal,
  Input,
  Dimmer,
  Loader,
  Table,
  Header,
  Label,
  FormGroup,
  FormField,
  Icon,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
// import Utility from '../../../Utility';
// import Settings from '../../../Settings';
import moment from "moment";
import {hotkeys} from "react-keyboard-shortcuts";
import {connect} from "react-redux";
import InputSearchCustomer from "../Customer/InputSearchCustomer";
import CustomerModal from "../Customer/CustomerModal";
import Utility from "../../Utility";
import Settings from '../../Settings';

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      remark:"",
      is_loading: false,
    };
    this.handlerCustomer = this.handlerCustomer.bind(this);
    this.handlerAddCustomer = this.handlerAddCustomer.bind(this);
    this.handlerCreateCustomer = this.handlerCreateCustomer.bind(this);
    this.submitData = this.submitData.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }

  componentDidMount() {
    var staffs = Utility.getFetch(Settings.baseUrl + '/staffs/?is_enabled=1&branch='+this.props.auth.branch.id);
    Promise.all([staffs]).then((values) => {

      this.staffs = values[0];
      let staffs = []
      for (let i in this.staffs) {
          staffs.push({
              value: this.staffs[i].id,
              key: this.staffs[i].id,
              text: this.staffs[i].name
          });
      }

      this.setState({
          staffs: staffs,
          st: false,
          number:this.props.items.number,
          name:this.props.items.name,
          balance:this.props.balance,
      });
  });

  }

  handlerCustomer(e, d) {
    let data = {};
    for (let i in d.options) {
      if (d.options[i].value == d.value) {
        data = d.options[i].data;
        break;
      }
    }
    this.setState({
      customer: data,
      customer_id: data.id,
      name: data.name,
      code: data.code,
      citizen_id: data.citizen_id,
      phone: data.mobile === null ? "" : data.mobile,
    });
  }
  handlerAddCustomer(e) {
    e.preventDefault();
    this.setState({
      modal_open: true,
    });
  }
  handlerCreateCustomer(data) {
    this.setState({
      modal_open: false,
      customer_id: data.id,
      citizen_id: data.citizen_id,
      customer: data.id,
      name: data.name,
      code: data.code,
      phone: data.mobile === null ? "" : data.mobile,
    });
  }
  submitData() {
    this.setState({is_loading:true})
    if(this.state.amount == undefined || this.state.amount == null || this.state.amount == ''){
      alert('กรุณากรอกจำนวนเงินฝาก')
      this.setState({is_loading:false})
      return
    }
    if(this.state.staff == undefined || this.state.staff == null || this.state.staff == ''){
      alert('กรุณาเลือกพนักงาน')
      this.setState({is_loading:false})
      return
    }
    if (!window.confirm('ยืนยันการฝากเงิน เลขที่บัญชี ' + this.state.number)){
      this.setState({is_loading:false})
      return
    }
    var formData = {
      staff: this.state.staff,
      amount: this.state.amount,
      branch: this.props.auth.branch.id,
      remark: this.state.remark,
    };
    // const url = Settings.baseUrl + '/saving/'+this.props.items.id+'/deposit/';
    // Utility.post(url, formData, (status, data) => {
    //     if(status){
    //         alert('บันทึกข้อมูลสำเร็จ!');
    //         this.setState({is_loading:false})
    //         this.props.onSubmit()
    //       }
    //       else{
    //         this.setState({is_loading:false})
    //         alert(data.detail)
    //       }
    // })
    let url = Settings.baseUrlV3 + '/saving/'+this.props.items.id+'/deposit/';
    Utility.post(url, formData, (status, data) => {
      if(status){
        this.GetQueue(data.qid)
        // console.log(data.qid)
      } else {
        this.setState({is_loading:false})
        alert(data.detail)
      }
    });
  }
  GetQueue(id) {
    setTimeout(() => {
        let url = Settings.baseUrlV3 + "/queue/"+id+"/"
        Utility.get(url, (e, res) => {
            if(res.result.status == 0) {
               this.GetQueue(id)
            }else{
                if(res.result.status == 1){
                    alert('บันทึกข้อมูลสำเร็จ!');
                    this.setState({is_loading:false})
                    this.props.onSubmit()
                }else{
                    alert(res.result.message)
                    this.setState({is_loading:false})
                    return;
                }

            }
        })
        
    }, 1000);
}
  handlerInput(e, v) {
    this.setState({staff:v.value})
  }
  render() {
    const {auth} = this.props;

    return (
      <Modal open={this.props.open} size="mini" /*dimmer='blurring'*/>
        <Dimmer active={this.state.is_loading} inverted>
            <Loader inverted>Loading</Loader>
        </Dimmer>
        <Button
          id="btnCloseProductSelect"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header>ฝากเงิน</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size="small">
            <Form.Field >
                <label>
                  เลขที่บัญชีออมทอง
                </label>
                <Input
                  value={this.state.number}
                readOnly
                />
              </Form.Field>
              <Form.Field >
                <label>
                  ชื่อบัญชี
                </label>
                <Input
                  value={this.state.name}
                readOnly
                />
              </Form.Field>
              <Form.Field >
                <label>
                  ยอดเงินคงเหลือ
                </label>
                <Input
                    className='text-right'
                  value={this.state.balance}
                readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>
                  *จำนวนเงินฝาก
                </label>
                <Input
                  maxLength={9}
                  className='text-right'
                  value={
                    this.state.amount == ""
                      ? ""
                      : this.state.focus
                      ? Utility.removeCommas(this.state.amount)
                      : Utility.priceFormat(this.state.amount)
                  }
                  onChange={(e, data) => {
                    data.value = data.value.toString().replace(/,/g, "");
                    let amount = Utility.removeCommas(data.value);
                    if (isNaN(Number(amount)) || Number(amount) < 0) {
                    } else {
                      let str = data.value.split(".");
                      str[0] = str[0]
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                      str[1] = str[1] == undefined ? "" : "." + str[1];
                      if (str.length != 1) {
                        if (str[1].length > 3) {
                          return;
                        }
                      }
                      this.setState({amount: data.value});
                    }
                  }}
                  onFocus={() => {
                    if (!this.state.focus) {
                      this.setState({
                        focus: true,
                      });
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      if (this.state.focus) {
                        this.setState({
                          focus: false,
                        });
                      }
                    }, 0);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>*พนักงาน</label>
                <Dropdown placeholder='พนักงาน' search selection 
                options={this.state.staffs} 
                // value={this.state.search.staff} 
                name='staff'
                onChange={this.handlerInput} 
                loading={this.state.st} />
            </Form.Field>
              <Form.Field>
                <label>
                  หมายเหตุ
                </label>
                <TextArea
                  id="IDstaff"
                  maxLength={255}
                  value={this.state.remark}
                  onChange={(e) => this.setState({remark: e.target.value})}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
          <CustomerModal
            open={this.state.modal_open}
            onCreate={this.handlerCreateCustomer}
            onClose={() => {
              this.setState({modal_open: false});
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="cancelAddstaff"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.props.onClose();
            }}
          >
            ยกเลิก
          </Button>
          <Button
            id="submitAddstaff"
            size="small"
            primary
            onClick={this.submitData}
            className={this.state.button_loading ? "loading" : ""}
          >
            บันทึก
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  hot_keys = {
    esc: {
      priority: 4,
      handler: this.props.onClose,
    },
    enter: {
      priority: 4,
      handler: (e) => {
        // e.preventDefault()
        // this.handlerSubmit(e)
      },
    },
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(hotkeys(Deposit));
