/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import {
    Table,
    Column,
    Cell,
} from 'fixed-data-table';

import {
    Form, Button, Modal, Pagination, Loader, Dimmer,
    Popup,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

class ItemsCell extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { rowIndex, field, data, ...props } = this.props;
        let width = (this.props.width - 10) + 'px'
        var ellipsisStyle = {
            whiteSpace: 'nowrap',
            width: width,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        const Width300 = {
            // 'width':'1000px',
            'word-break': 'break-word'
        }
        return (
            <Cell {...props} >
                <Popup
                    position={this.props.position}
                    content={(field === 'amount_out_w') ?
                        (data[rowIndex][field] === undefined) ?
                            data[rowIndex][field]
                            : data[rowIndex][field].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : data[rowIndex][field]
                    }
                    style={Width300}
                    trigger=
                    {(field === 'amount_out_w') ?
                        (data[rowIndex][field] === undefined) ?
                            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>
                            : <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                        : <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>
                    }
                />
            </Cell>
        );
    }
}


class StockCategoryDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            totalPages: 1,
            page: 1,
            table_hiegh: Settings.table_hiegh,
        }
        this.loaddata = this.loaddata.bind(this)
    }

    loaddata(page) {
        this.setState({
            loader_active: true
        });
        var stock_category = Utility.getFetch(Settings.baseUrl + '/stock_old_product/' + this.props.id + '/items?&page=' + page + '&page_size=' + 10);
        Promise.all([stock_category]).then((values) => {
            // this.setState({loader_active: false})
            // this.setState({totalPages: Math.ceil(values[0].count / 10)})
            this.setState({
                loader_active: false,
                items: this.setValue2(values[0].results),
                totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
            });
            window.addEventListener('resize', this.updateDimensions);
            this.updateDimensions()
        })


    }

    componentDidMount() {

        this.setState({
            loader_active: true
        });
        this.loaddata(1)

        
      }
    
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    
      updateDimensions = () => {
        let elHeight = document.getElementById('table_width2')
        this.setState({ table_width: elHeight.clientWidth });
      };

    setValue2(items) {
        for (let i = 0; i < items.length; i++) {
            // items[i]['in_date_title'] = Utility.formatDate(items[i].in_date)
            // items[i]['out_date_title'] = Utility.formatDate(items[i].out_date)
            // let d = parseFloat(items[i].category.weight)
            items[i]['billID'] = items[i].object_number
            items[i]['kind_display'] = items[i].kind_display
            items[i]['product_name'] = items[i].name
            items[i]['weight'] = Utility.weightFormat(items[i].weight)
            items[i]['cost'] = Utility.priceFormat(items[i].cost)
            // items[i]['average'] = items[i].weight == 0 ? 0 : Utility.weightFormat(parseFloat(items[i].total) / (parseFloat(items[i].weight) / d))
        }

        return items;
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ page: activePage })
        this.loaddata(activePage)
    }

    render() {

        const items = this.state.items;
        return (



            <Modal size='large' open={this.props.open} /*dimmer='blurring'*/>
                {this.state.loader_active && <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
                    <Loader content='Loading' inverted />
                </Dimmer>}
                <Button id='btnClose' circular icon='close' title='ปิด' basic floated='right' name='' onClick={(e) => {
                    e.preventDefault();
                    this.props.onClose();
                }} />
                <Modal.Header>กลุ่มสินค้า {this.props.name}</Modal.Header>

                <Modal.Content className=''>
                    <Modal.Description>
                        <div id="table_width2">
                            <Table
                                rowsCount={items.length}
                                rowHeight={35}
                                headerHeight={30}
                                width={this.state.table_width}
                                height={400} >
                                <Column
                                    header={<Cell>เลขที่บิล</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={180} id='billID' data={items} field="object_number" />
                                    }
                                    width={180}
                                />
                                <Column
                                    header={<Cell>ประเภท</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={120} data={items} field="kind_display" />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell>ชื่อสินค้า</Cell>}
                                    cell={
                                        <ItemsCell position='top left' width={300} data={items} field="product_name" />
                                    }
                                    width={300}
                                />
                                <Column
                                    header={<Cell className='text-right'>น้ำหนัก</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={120} data={items} field="weight" textAlign='text-right' color='pink' />
                                    }
                                    width={120}
                                />
                                <Column
                                    header={<Cell className='text-right'>ราคา</Cell>}
                                    cell={
                                        <ItemsCell position='top right' width={120} data={items} field="cost" textAlign='text-right' color='blue' />
                                    }
                                    width={120}
                                />

                            </Table>
                            <br />
                            {this.state.totalPages > 1 && <Pagination
                            size='tiny'
                                activePage={this.state.page}
                                onPageChange={this.handlePaginationChange.bind(this)}
                                totalPages={this.state.totalPages}
                            />}
                        </div>
                    </Modal.Description>

                </Modal.Content>
            </Modal>


        );
    }
}


export default StockCategoryDetail;