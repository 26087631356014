/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment, Button, Loader, Dimmer, Modal
} from 'semantic-ui-react';

import Utility from '../../Utility';
import Settings from '../../Settings';

class ProductDetailPrintPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pdf_url: null,
        }
    }

    componentDidMount() {

        if (this.props.id) {
            let lease = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.id + '/');
            let product = Utility.getFetch(Settings.baseUrl + '/lease/' + this.props.id + '/product/?is_enabled=1');
            this.setState({
                loading: true
            })

            Promise.all([lease, product]).then(async (values) => {
                let lease = values[0]
                let product = values[1]
                let products = []
                let total_weight = 0
                let total_amount = 0
                for (let i in product) {
                    total_weight += Number(product[i].weight)
                    total_amount += Number(1)
                    products.push({
                        'name': product[i].product_name.toString().substr(0, 12),
                        'weight': `${Utility.weightFormat(Number(product[i].weight))} กรัม`,
                        'qrcode': product[i].qrcode,
                    })
                }
                const data = {
                    lease: JSON.stringify({
                        'citizen_id': lease.citizen_id,
                        'number': lease.number,
                        'customer': lease.customer.name,
                        'phone': lease.phone,
                        'products': products,
                        'total_amount': total_amount,
                        'total_weight': Utility.weightFormat(total_weight),
                        'start_date': Utility.dateThai(lease.start_date),
                        'end_date': Utility.dateThai(lease.end_date),
                        'amount': Utility.priceFormat(lease.amount),
                        'amount_th': Utility.arabicNumberToText(lease.amount),
                    })
                }
                const res = await Utility.postAsync(Settings.url_genpdf_lease, data)

                if (res.status_code == 200) {
                    this.setState({ pdf_url: res.data.data.url, loading: false })
                }
            });
        }

    }
    render() {
        return (<div>
            <Modal id='ModalLeasePreview' open={true} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                <Modal.Header>Preview</Modal.Header>
                <Modal.Content className='scrolling'>
                    {this.state.loading ? <Segment>
                        <Dimmer active inverted>
                            <Loader inverted content='Loading' />
                        </Dimmer>
                    </Segment> :
                        <div>
                            <iframe id='view-print' style={{ width: '100%', height: '400px' }} src={this.state.pdf_url}></iframe>
                        </div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <span style={{ 'float': 'left', 'font-size': '10px', color: 'red' }}>*ตั้งค่า google chrome -> Paper size: A4 / scale: 100 / margin: None</span>
                    <br />
                </Modal.Actions>
                <Modal.Actions>
                    <Button id='btnCloseLeasePreview' size='small' type='button' onClick={() => { this.props.onClose() }}>ปิด</Button>
                </Modal.Actions>
            </Modal>
        </div>)
    }
}

export default ProductDetailPrintPreview;