/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment()
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var ledger_category = Utility.getFetch(Settings.baseReportUrl + "/ledger_category/?is_enabled=1")
        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var bank_cards = Utility.getFetch(Settings.baseReportUrl + '/bank_cards/?is_enabled=1');
        Promise.all([branches, ledger_category, bank_cards]).then((values) => {
            this.branches = values[0];
            this.bank_cards = values[2]
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.ledger_category = values[1];

            this.setState({
                loader_active: false,
                branches: branches
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        if (temp_start !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.start_date)
        let fromData = Utility.jsonToQueryString(search)

        this.setState({
            loading: true
        })

        var ledger = Utility.getFetch(Settings.baseReportUrl + "/ledger/?" + fromData)
        var bill_items = Utility.getFetch(Settings.baseReportUrl + "/bill_items/?status_stock=Y&" + fromData)
        var lease = Utility.getFetch(Settings.baseReportUrl + "/lease/?" + fromData)
        Promise.all([ledger, bill_items, lease]).then((values) => {

            let bill_items = values[1]
            let leases = values[2]
            let items = []
            for (let i in values[0]) {
                let item = values[0][i]
                let title = item.ledger_category.title
                item.n_neck = 0
                item.n_nl = 0
                item.n_r = 0
                item.n_o = 0
                item.n_w = 0
                item.sell96 = 0
                item.buy96 = 0
                item.amount_ledger_category10 = 0
                item.amount_other = 0
                item.card_type = ''
                item.online = item.payment === 'TF' ? item.transfer : 0
                item.transfer = item.payment !== 'TF' ? item.transfer : 0
                item.lease_amount = 0
                item.buy_n_w = 0
                item.pay_other = 0

                if (item.kind === 'IN') {
                    item.amount_other = item.total

                } else {
                    item.cash = 0
                    if (item.ledger_category.id > 4) {
                        item.pay_other = item.total
                    }
                }


                if (item.ledger_category.id <= 2) {
                    title = title + ' ' + item.object_number
                    if (item.ledger_category.id === 1) {
                        for (let i in bill_items) {
                            let bitem = bill_items[i]
                            if (item.object_id === bitem.bill.id && bitem.product.category.code === '96') {
                                if (bitem.product.kind.code === 'N' || bitem.product.kind.code === 'NN')
                                    item.n_neck += parseInt(bitem.amount)
                                else if (bitem.product.kind.code === 'NL' || bitem.product.kind.code === 'NL')
                                    item.n_nl += parseInt(bitem.amount)
                                else if (bitem.product.kind.code === 'R' || bitem.product.kind.code === 'RR')
                                    item.n_r += parseInt(bitem.amount)
                                else
                                    item.n_o += parseInt(bitem.amount)

                                item.n_w = parseFloat(bitem.weight)
                                item.sell96 = parseFloat(bitem.sell)
                            }
                        }

                    } else if (item.ledger_category.id === 2) {
                        for (let i in bill_items) {
                            let bitem = bill_items[i]
                            if (item.object_id === bitem.bill.id && bitem.category.code === '96') {


                                item.buy_n_w = parseFloat(bitem.weight)
                                item.buy96 = parseFloat(bitem.sell)
                            }
                        }
                    }
                } else if (item.ledger_category.id === 10) {
                    title = title + ' ' + item.object_number


                    item.amount_ledger_category10 = parseFloat(item.object_lease.amount)
                    item.amount_other = parseFloat(item.total) - parseFloat(item.object_lease.amount)

                } else if (item.ledger_category.id === 4) {
                    title = title + ' ' + item.object_number
                    for (let i in leases) {
                        let lease = leases[i];
                        if (item.object_id === lease.id) {
                            item.lease_amount = lease.amount
                            break
                        }
                    }
                } else if (item.ledger_category.id === 7 || item.ledger_category.id === 8 || item.ledger_category.id === 9 || item.ledger_category.id === 3) {
                    title = title + ' ' + item.object_number
                }
                if (item.card_bank_card) {
                    let o = Utility.getObject(this.bank_cards, item.card_bank_card.id);
                    item.card_type = o.kind
                }
                item.title = title
                items.push(item)
            }
            this.setState({
                loading: false,
                items: items
            })
        })
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }

    getSumItem(items, key) {
        let s = 0
        for (let i in items) {
            s += parseFloat(items[i][key])
        }
        return s
    }
    render() {
        let data = this.props.data

        let title = 'รายงานรายรับ-รายจ่ายประจำวัน';
        let filename = 'ledger-' + Utility.formatDate3(this.state.search.start_date)

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace',
        };
        const textRight = {
            'text-align': 'right',
        }

        const textLeft = {
            'text-align': 'left',
        }
        const textCenter = {
            'text-align': 'center',
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }


        let items = this.state.items
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานรายรับ-รายจ่ายประจำวัน</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>

                            <Button type='button' onClick={(e) => {
                                this.setState({
                                    open: true
                                })
                                this.handlerSubmit(e)
                            }}>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='24'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='24'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='24'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} </center></Table.HeaderCell>
                                        </Table.Row>

                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center>รายรับ</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='5'><center>รายจ่าย</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='6'>ทอง 96.5%</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ไถ่คืน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>รายรับอื่นๆ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนเงินรับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินสด</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เงินโอน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต่อดอกออนไลน์</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>เครดิต</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>Fee</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าธรรมเนียม</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>บัญชีธนาคาร</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ชนิดบัตร</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนำ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} colSpan='2'>96.5%</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>รายจ่ายอื่นๆ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวนเงินจ่าย</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2' style={textCenter}></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>คอ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>มือ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>แหวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>อื่นๆ</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} colSpan='12'></Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight} colSpan='2'></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {items.map((item, i) => {

                                            items[i].total_inc = item.kind === 'IN' ? item.total : 0; item.total_inc = item.kind === 'IN' ? item.total : 0;
                                            items[i].total_exc = item.kind != 'IN' ? item.total : 0; item.total_exc = item.kind != 'IN' ? item.total : 0;
                                            return (<Table.Row>
                                                <Table.Cell style={textCenter}>{i + 1}</Table.Cell>
                                                <Table.Cell style={textLeft}>{item.title}</Table.Cell>
                                                <Table.Cell style={textRight}>{item.n_neck}</Table.Cell>
                                                <Table.Cell style={textRight}>{item.n_nl}</Table.Cell>
                                                <Table.Cell style={textRight}>{item.n_r}</Table.Cell>
                                                <Table.Cell style={textRight}>{item.n_o}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.n_w)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.sell96)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.amount_ledger_category10)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.amount_other)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.total_inc)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.cash)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.transfer)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.online)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(item.card))}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.card_fee)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(item.card_service))}</Table.Cell>
                                                <Table.Cell style={textLeft}>{item.transfer_bank_account_name}</Table.Cell>
                                                <Table.Cell style={textCenter}>{item.card_type}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.lease_amount)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.weightFormat(item.buy_n_w)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.buy96)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.pay_other)}</Table.Cell>
                                                <Table.Cell style={textRight}>{Utility.priceFormat(item.total_exc)}</Table.Cell>

                                            </Table.Row>)
                                        })}
                                        <Table.Row>
                                            <Table.Cell colSpan='2' style={textRight}><div style={textU}><b>รวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='n_neck' style={textU}><b>{this.getSumItem(items, 'n_neck')}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='n_nl' style={textU}><b>{this.getSumItem(items, 'n_nl')}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='n_r' style={textU}><b>{this.getSumItem(items, 'n_r')}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='n_o' style={textU}><b>{this.getSumItem(items, 'n_o')}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='n_w' style={textU}><b>{Utility.weightFormat(this.getSumItem(items, 'n_w'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='sell96' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'sell96'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='amount_ledger' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'amount_ledger_category10'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='amount_other' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'amount_other'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_inc' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'total_inc'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='cash' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'cash'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='transfer' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'transfer'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='online' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'online'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='card' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'card'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='card_fee' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'card_fee'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='card' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'card_service'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}><div id='lease_amount' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'lease_amount'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='buy_n_w' style={textU}><b>{Utility.weightFormat(this.getSumItem(items, 'buy_n_w'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='buy96' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'buy96'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='pay_other' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'pay_other'))}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total_exc' style={textU}><b>{Utility.priceFormat(this.getSumItem(items, 'total_exc'))}</b></div></Table.Cell>
                                        </Table.Row>

                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}


const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)