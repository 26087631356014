/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Pagination, Dimmer, Loader,
  Statistic,
  Form, Button,
  Popup
} from 'semantic-ui-react';

import 'react-dates/lib/css/_datepicker.css';
import Settings from '../../Settings';
import Utility from '../../Utility';

class ItemsCell extends Component {

  render() {
    const { rowIndex, field, data, ...props } = this.props;

    let v = data[rowIndex][field]
    if (this.props.type == 'number')
      v = Utility.numberFormat(v)
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    return (
      <Cell {...props} >
        <Popup
          position={this.props.position}
          content={v}
          trigger={<div style={ellipsisStyle}>{v}</div>}
        />
      </Cell>
    );
  }
}


class Saving extends Component {

  constructor(props) {
    super(props);

    this.limit = 10

    this.state = {
      modal_open: false,
      modal_action: '',
      billItems: [],
      items: [],
      activeItem: 'sell',
      activePage:1,
    }

    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.loadScoreItem(activePage)
  }

  componentDidMount() {

    // let querytoday = Utility.jsonToQueryString({});

    this.loadScoreItem(1)
  }


  loadScoreItem(activePage) {
    activePage = activePage < 1 ? 1 : activePage
    this.setState({
      loader_active: true
    });
    var saving_book = Utility.getFetch(Settings.baseUrl + '/savings'+ "/?customer_id=" + this.props.customer_id + '&status=O');
    Promise.all([saving_book]).then((values) => {
      const saving_book = values[0]

      if (saving_book.length > 0){
        var saving_item = Utility.getFetch(Settings.baseUrl + '/saving/'+ saving_book[0].id + "/item" + '/?page=' + activePage + "&page_size=" + this.limit);
        Promise.all([saving_item]).then((values) => {
          const saving_item = values[0]
          this.setState({
            items: this.setFieldValue(saving_item.results),
            totalPages: saving_item.next == null && saving_item.previous == null ? 0 : Math.ceil(saving_item.count / this.limit),
            totalItems: saving_item.count
          })
        })
        this.setState({
          table_width: window.innerWidth,
          loader_active: false,
          saving_book: saving_book,
          saving_balance: saving_book[0].balance
        });
      } else {
        this.setState({
          loader_active: false,
          totalPages: 1,
        })
      }
    });
    // Utility.get(url, (status, res, code) => {
    //   let items = res.results ? this.setFieldValue(res.results) : []
    //   this.setState({
    //     loader_active: false,
    //     items: items,
    //     totalPages: res.next == null && res.previous == null ? 0 : Math.ceil(res.count / this.limit),
    //   })
    // })
  }

  setFieldValue(items) {

    for (let i in items) {

      items[i].date = Utility.formatDate(items[i].record_date)
      items[i].time = Utility.formatTime(items[i].record_date)
      // items[i].amount_display = items[i].action == 'DEL' ? items[i].amount * -1 : items[i].amount

      if (items[i].creator != null) {
        items[i].creator_name = items[i].creator.username
      }

      if (items[i].branch != null) {
        items[i].branch_name = items[i].branch.name
      }

      if (items[i].kind == 'O' || items[i].kind == 'D'){
        items[i].deposit = items[i].amount
        items[i].withdraw = ''
      }else {
        items[i].withdraw = items[i].amount
        items[i].deposit = ''
      }

      

      // if (items[i].action == 'DEL' && items[i].kind == 'SE')
      //   items[i].kind_display = 'ยกเลิกรายการซื้อทอง'
    }
    return items;
  }

  componentWillMount() { this.setState({ table_width: this.props.clientWidth ? this.props.clientWidth : window.innerWidth - 70, table_height: window.innerHeight - 250 }); }
  render() {
    let items = this.state.items
    return (<div>

      <div className='relative' id='table_width'>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Form.Field width={10} height={20}>
          {/* <Button id='btnAddPoint' size='small' content='เพิ่มแต้ม ออมทอง' onClick={(e) => {
            e.preventDefault()
            // this.resetForm()
            this.setState({
              modal_open: true,
              modal_action: 'Addpoint',
              modal_title: 'เพิ่มแต้ม ออมทอง'
            });
          }}
            floated='right' icon='plus' labelPosition='left' type='button' primary /> */}
        </Form.Field>
        <Form.Field width={10} height={20}>
          <Statistic label='ยอดคงเหลือ' value={Utility.numberFormat(this.state.saving_balance | 0)} />
        </Form.Field>

        {/* {this.state.modal_open && <CustomerPoint open={true}
          onSave={() => {
            this.setState({ modal_open: false })
            this.loadScoreItem(1)
            this.componentDidMount()
          }}
          onClose={() => {
            this.setState({ modal_open: false })
          }}
          customer_id={this.props.customer_id}
          action={this.state.modal_action} />} */}

        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={387}>

          <Column
            header={<Cell>วันที่</Cell>}
            cell={
              <ItemsCell position='top left' width={180} data={items} field="date" />
            }
            width={120}
          />
          <Column
            header={<Cell className="text-right">เวลา</Cell>}
            cell={
              <ItemsCell position='top right' width={120} id='tableScoreID' className="text-right" data={items} field="time" />
            }
            width={80}
          />
          <Column
            header={<Cell className="text-right">รายการ</Cell>}
            cell={
              <ItemsCell position='top right' width={120} className="text-right" data={items} field="kind_display" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell className="text-right">เงินฝาก</Cell>}
            cell={
              <ItemsCell position='top right' width={120} className="text-right" data={items} field="deposit" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell className="text-right">เงินถอน</Cell>}
            cell={
              <ItemsCell position='top right' width={120} className="text-right" data={items} field="withdraw" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell className="text-right">ยอดคงเหลือ</Cell>}
            cell={
              <ItemsCell position='top right' width={120} className="text-right" data={items} field="balance" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell className="text-right">ผู้ทำรายการ</Cell>}
            cell={
              <ItemsCell position='top right' width={120} className="text-right" data={items} field="creator_name" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell>สาขาที่ทำรายการ</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="branch_name" />
            }
            width={120}
          />
        </Table>
        <Form size='small' style={{'padding-top':'10px'}}>
          <Form.Group>
            <Form.Input width={2} className='text-left' label='จำนวน' value={this.state.totalItems | 0} />
            <Form.Field width={14}>
              <br/>
              {this.state.totalPages > 0 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>

        </Form>
      </div>
    </div>)
  }
}


export default Saving;
