/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Message
} from 'semantic-ui-react';
class Msg extends Component {

  render() {
    let text = this.props.text
    if(text == null || text =='')
      return null
    return (<span>
      {text == null || text =='' ? '' : <span className='red'>{text}</span>}
    </span>

    );
  }
}

export default Msg;
