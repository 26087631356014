/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from "react";
import { Table, Column, Cell } from "fixed-data-table";
import {
  Form,
  Header,
  Button,
  Grid,
  Modal,
  Confirm,
  Dimmer,
  Loader,
  Input,
  TextArea,
  Popup,
  Pagination,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { hotkeys } from "react-keyboard-shortcuts";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import Settings from "../../Settings";
import Utility from "../../Utility";
import { connect } from "react-redux";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import CloseAccount from "./CloseAccount";
import AccountItems from "./AccountItems";
import { ContextMenu, Item, ContextMenuProvider } from "react-contexify";
import PrintConfirm from "./PrintConfirm";
import SelectItem from "./SelectItem";
import PrintNextPageConfirm from "./PrintNextPageConfirm";
import CoverBookPrintPreview from "./CoverBookPrintPreview";
import SlipPrintPreview from "./SlipPrintPreview";
var dateFormat = require("dateformat");

class ItemsCell extends Component {
  render() {
    const { rowIndex, field, data, type, ...props } = this.props;

    let v =
      type == "number"
        ? Utility.numberFormat(data[rowIndex][field])
        : type == "price"
          ? Utility.priceFormat(data[rowIndex][field])
          : type == "weight"
            ? Utility.weightFormat(data[rowIndex][field])
            : data[rowIndex][field];
    let width = this.props.width - 10 + "px";
    var ellipsisStyle = {
      whiteSpace: "nowrap",
      width: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    const Width300 = {
      // 'width':'1000px',
      "word-break": "break-word",
    };
    return (
      <Cell {...props}>
        <ContextMenuProvider data={rowIndex} id="menu_id">
          <Popup
            position={this.props.position}
            content={v}
            style={Width300}
            trigger={
              <div
                style={ellipsisStyle}
                className={
                  (this.props.textAlign == null ? "" : this.props.textAlign) +
                  " text-cell"
                }
              >
                {v}
              </div>
            }
          />
        </ContextMenuProvider>
      </Cell>
    );
  }
}

class AccountDetail extends Component {
  constructor(props) {
    super(props);
    this.code_change_number = {};
    this.state = {
      remark: "",
      items: [],
      totalPages: 1,
      page: 1,
      open_print_book: false,
      mode_selectitem: null,
      repair: false,
      item_repair: [],
      disabledButtons: true,
    };
    this.loaddata2 = this.loaddata2.bind(this);
    this.submitData = this.submitData.bind(this);
    this.getbalance = this.getbalance.bind(this);
    this.GetQueue = this.GetQueue.bind(this);
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage });
    this.loaddata2(activePage);
  }
  loaddata2(page) {
    this.setState({
      loader_active: true,
    });

    var savings = Utility.getFetch(
      Settings.baseUrl + "/saving/" + this.props.items.id + "/item?page=" + page
    );
    Promise.all([savings]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
    });
  }
  getbalance() {
    var savings = Utility.getFetch(Settings.baseUrl + "/saving/" + this.props.items.id);
    Promise.all([savings]).then((values) => {
      this.setState({ balance: Utility.priceFormat(values[0].balance), status: values[0].status });
    });
  }
  getbalance_before(kind) {
    var savings = Utility.getFetch(Settings.baseUrl + "/saving/" + this.props.items.id);
    Promise.all([savings]).then((values) => {
      this.setState({ balance: Utility.priceFormat(values[0].balance), status: values[0].status });
      if (kind == 'withdraw'){
        this.setState({withdraw:true})
      }else{
        this.setState({deposit:true})
      }
      
    });
  }
  submitData() {
    this.setState({ loading_button: true })
    var formData = {
      remark: this.state.remark,
    };
    const url = Settings.baseUrl + "/saving/" + this.props.items.id + "/";
    Utility.put(url, formData, (status, data) => {
      this.setState({ loading_button: false })
      if (status) {
        var savings = Utility.getFetch(
          Settings.baseUrl + "/saving/" + this.props.items.id
        );
        alert("บันทึกข้อมูลสำเร็จ!");
        Promise.all([savings]).then((values) => {
          this.setState({ remark: values[0].remark, status: values[0].status });
        });
      }
    });
  }

  componentDidMount() {
    this.setState({
      remark: this.props.items.remark == null ? "" : this.props.items.remark,
      balance: Utility.priceFormat(Utility.removeCommas(this.props.items.balance)),
      status: this.props.items.status,
      loader_active: true,
    });
    // this.handleSubmit();
    var savings = Utility.getFetch(
      Settings.baseUrl + "/saving/" + this.props.items.id + "/item?page=1"
    );
    Promise.all([savings]).then((values) => {
      var items = this.setFieldValue(values[0].results);

      this.setState({
        items: items,
        totalPages:
          values[0].next == null && values[0].previous == null
            ? 0
            : Math.ceil(values[0].count / 10),
        counter: values[0].count,
        loader_active: false,
      });
      if (this.props.action == 'deposit') {
        this.setState({ deposit: true })
      }
      if (this.props.action == 'withdraw') {
        this.setState({ withdraw: true })
      }
      if (this.props.action == 'close') {
        this.setState({ close: true })
      }
    });
    this.GetQueue(this.props.items.number)
    this.getbalance()
    // window.addEventListener("resize", this.updateDimensions);
    // this.updateDimensions();
  }
  GetQueue(id) {
    setTimeout(() => {
        let url = Settings.baseUrlV3 + "/queue/"+id+"/"
        Utility.get(url, (e, res) => {
          if(res.result){
            if(res.result.status == 0) {
               this.GetQueue(id)
            }else{  
                if(res.result.status == 1){
                    this.setState({disabledButtons:false})
                    this.props.onSubmit()
                }else{
                    alert(res.result.message)
                    this.setState({disabledButtons:false})
                    return;
                }

            }
        }else{
          this.setState({disabledButtons:false})
        }
      }
        )
        
    }, 1000);
}
  setFieldValue(item) {
    for (let i = 0; i < item.length; i++) {
      item[i]["branch_name"] = item[i].branch.name;
      item[i]["date"] = Utility.formatDate(item[i].created_datetime);
      item[i]["time"] = Utility.formatTime(item[i].created_datetime);
      item[i]["in"] =
        item[i].kind == "O" || item[i].kind == "D"
          ? Utility.priceFormat(item[i].amount)
          : "";
      item[i]["out"] =
        item[i].kind == "W" || item[i].kind == "C"
          ? Utility.priceFormat(item[i].amount)
          : "";
      item[i]["balance"] = Utility.priceFormat(item[i].balance);
      item[i]["staff"] = item[i].staff.name;
    }
    return item;
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById("client_width");
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleNextBook(e) {

    // open confirm next book
    this.setState({
      confirm_nextbook_open: true
    })

  }

  handleConfirmNextBookCancel() {
    this.setState({
      confirm_nextbook_open: false
    })
  }

  async handleConfirmNextBook() {

    this.setState({ loading: true })
    const url = Settings.baseUrl + "/saving/" + this.props.items.id + "/nextbook/";
    const request_nextbook = await Utility.postAsync(url, {})
    this.setState({ open_select_line: true, confirm_nextbook_open: false, mode_selectitem: 'newbook' })
  }

  handlePrintSlip() {

    this.setState({ open_select_line: true, mode_selectitem: 'slip' })
  }

  async onDeleteItem(e,saving_item,saving){
    let description = prompt("กรุณาระบุหมายเหตุ");
    if (description == null || description == "") {
        return;
    }
    const data_ = { 'saving_item': saving_item, remark:description }
    let url = Settings.baseUrl + "/saving/" + saving + "/cancel/"
    const res = await Utility.postAsync(url, data_);
    if (res.status_code == 200) {
      alert('ยกเลิกสำเร็จ');
      this.componentDidMount()
  } else {
    alert(res.data.detail)
  }

  }

  render() {
    const { role } = this.props.auth;
    const items = this.state.items;

    return (
      <Modal size="large" open={this.props.open} /*dimmer='blurring'*/>
        <Button
          id="btnClose"
          circular
          icon="close"
          basic
          floated="right"
          name=""
          onClick={this.props.onClose}
        />
        <Modal.Header id="headerModalPOS">
          <Header as="h4">
            {/* <Icon name='file alternate' /> */}
            <span>บัญชีออมทอง {this.props.items.number}</span>
          </Header>
        </Modal.Header>
        <Modal.Content id="contentModalPOS">
          <Dimmer active={this.state.is_loading} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Form className="attached fluid" size="small">
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>ลูกค้า</label>
                        <Input
                          value={
                            this.props.items.customer.name +
                            " (" +
                            this.props.items.customer.code +
                            ")"
                          }
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>หมายเลขบัตรประชาชน</label>
                        <Input value={this.props.items.citizen_id} readOnly />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>ชื่อบัญชี</label>
                        <Input value={this.props.items.name} readOnly />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>ยอดเงินคงเหลือ</label>
                        <Input className='text-right' value={this.state.balance} readOnly />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>หมายเหตุ</label>
                        <TextArea
                          value={this.state.remark}
                          maxLength={255}
                          onChange={(e) => this.setState({ remark: e.target.value })}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column width={12}>
                  <div id="width_table">
                    <Form size="small">
                      <Form.Group>
                        <Form.Field width={6}>
                          <Header floated="left" as="h3">
                            รายการธุรกรรม
                          </Header>
                        </Form.Field>
                        <Form.Field id="addstock" width={16}>
                          <Button
                            id="btnAddProduct"
                            content="ฝากเงิน"
                            onClick={(e) => {
                              this.getbalance_before('deposit')
                              // this.setState({ deposit: true });
                            }}
                            floated="right"
                            icon="plus"
                            labelPosition="left"
                            type="button"
                            color="green"
                            disabled={this.state.status == "C" || this.state.disabledButtons}
                          />

                          <Button
                            id="btnAddProduct"
                            content="ถอนเงิน"
                            onClick={(e) => {
                              this.getbalance_before('withdraw')
                              // this.setState({ withdraw: true });
                            }}
                            floated="right"
                            icon="plus"
                            labelPosition="left"
                            type="button"
                            color="red"
                            disabled={this.state.status == "C" || this.state.disabledButtons}
                          />
                          {/* <Button
                            id="btnAddProduct"
                            content="ปิดบัญชี"
                            onClick={(e) => {
                              this.setState({close: true});
                            }}
                            floated="right"
                            icon="plus"
                            labelPosition="left"
                            type="button"
                            color="red"
                            disabled={this.state.status == "C"}
                          /> */}
                        </Form.Field>
                      </Form.Group>
                    </Form>
                    <AccountItems onDelete={(e,saving_item,saving)=>{this.onDeleteItem(e,saving_item,saving)}} items={items} active={this.state.loader_active} />
                    <div>
                      <Form className="attached fluid" size="small">
                        <Form.Group>
                          <Form.Input
                            label="จำนวนรายการ"
                            placeholder=""
                            className="text-right"
                            width={3}
                            value={this.state.counter}
                            readOnly
                          />
                          <Form.Field width={14}>
                            <br />
                            {this.state.totalPages > 1 && (
                              <Pagination
                                floated="right"
                                size="tiny"
                                activePage={this.state.page}
                                onPageChange={this.handlePaginationChange.bind(this)}
                                totalPages={this.state.totalPages}
                              />
                            )}
                          </Form.Field>
                          {this.state.deposit && (
                            <Deposit
                              balance={this.state.balance}
                              items={this.props.items}
                              onClose={() => {
                                this.setState({ deposit: false });
                              }}
                              onSubmit={() => {
                                this.loaddata2(this.state.page);
                                this.getbalance();
                                this.setState({ deposit: false });
                              }}
                              open={true}
                            />
                          )}
                          {this.state.withdraw && (
                            <Withdraw
                              balance={this.state.balance}
                              items={this.props.items}
                              onClose={() => {
                                this.loaddata2(this.state.page);
                                this.getbalance();
                                this.setState({ withdraw: false });
                              }}
                              onSubmit={() => {
                                this.loaddata2(this.state.page);
                                this.getbalance();
                                this.setState({ withdraw: false });
                              }}
                              open={true}
                            />
                          )}
                          {this.state.close && (
                            <CloseAccount
                              balance={this.state.balance}
                              items={this.props.items}
                              onClose={() => {
                                this.setState({ close: false });
                              }}
                              onSubmit={() => {
                                this.loaddata2(this.state.page);
                                this.getbalance();
                                this.setState({ close: false });
                              }}
                              open={true}
                            />
                          )}
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          {/* /print confirm/ */}
          {this.state.open_print_book && <PrintConfirm
            repair={this.state.repair}
            line={this.state.line}
            items={this.state.item_repair}
            savings={this.props.items}
            onClose={() => {this.setState({ open_print_book: false, repair: false });this.componentDidMount()}} />}

          {/* /print cover/ */}
          {this.state.open_print_cover_page && <CoverBookPrintPreview savings={this.props.items} onClose={() => this.setState({ open_print_cover_page: false })} />}

          {/* /print select item/ */}
          {this.state.open_select_line && <SelectItem savings={this.props.items} mode={this.state.mode_selectitem}
            onPrintSlip={(item) => {
              this.setState({ open_select_line: false, open_print_slip: true, slip_item: item })
            }}
            onPrintBook={() => this.setState({ open_select_line: false, open_print_book: true })}
            onPrintRepair={(items, line) => {
              this.setState({ open_select_line: false, open_print_book: true, item_repair: items, repair: true, line: line })
            }}
            onClose={() => this.setState({ open_select_line: false })} />}

          {/* /next page/ */}
          {this.state.open_print_next_book && <PrintNextPageConfirm savings={this.props.items}
            onClose={() => this.setState({ open_print_next_book: false })}
            onSubmit={() => this.setState({ open_print_next_book: false, open_print_book: true })}
          />}

          {/* /print slip/ */}
          {this.state.open_print_slip && <SlipPrintPreview savings={this.props.items} item={this.state.slip_item} onClose={() => this.setState({ open_print_slip: false })} />}
          <Confirm
            open={this.state.confirm_nextbook_open}
            header='แจ้งเตือน'
            content='ยืนยันเริ่มพิมพ์เล่มใหม่'
            onCancel={this.handleConfirmNextBookCancel.bind(this)}
            onConfirm={this.handleConfirmNextBook.bind(this)}
          />
          <Confirm
            open={this.state.confirm_repair}
            header='แจ้งเตือน'
            content='ยืนยันเพิมพ์ซ่อม'
            onCancel={() => {
              this.setState({ confirm_repair: false })
            }}
            onConfirm={() => {
              this.setState({
                open_select_line: true,
                confirm_repair: false,
                mode_selectitem: 'repair'
              })
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Dropdown text='พิมพ์อื่นๆ' floating labeled button className='icon primary small dropdown-icon-saving'>
            <Dropdown.Menu className='left'>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ open_print_cover_page: true })
                }}>
                <Icon name='print' /> พิมพ์ปกสมุด
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ open_print_next_book: true })
                }}>
                <Icon name='print' /> พิมพ์หน้าใหม่
              </Dropdown.Item>
              <Dropdown.Item
                onClick={this.handleNextBook.bind(this)}>
                <Icon name='print' /> พิมพ์สมุดใหม่
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ confirm_repair: true })
                }}>
                <Icon name='print' /> พิมพ์สมุดซ่อม
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Button
            id="qrcode"
            primary
            size="small"
            icon="print"
            labelPosition="left"
            onClick={(e) => {
              // e.preventDefault();
              this.setState({ open_print_book: true })
            }}
            content="พิมพ์สมุดต่อ"
          />
          <Button
            id="btnPrintVat"
            size="small"
            primary
            icon="print"
            labelPosition="left"
            onClick={this.handlePrintSlip.bind(this)}
            content="พิมพ์สลิป"
          />
          <Button
            id="btnPrintVat"
            size="small"
            primary
            icon="print"
            labelPosition="left"
            disabled={this.state.loading_button}
            className={this.state.loading_button ? 'loading' : ''}
            onClick={this.submitData}
            content="บันทึก"
          />
        </Modal.Actions>
      </Modal>
    );
  }

  // hot_keys = {
  //   f2: {
  //     priority: 3,
  //     handler: (event) => {
  //       event.preventDefault();
  //       if (this.state.bill_number == "") this.saveBill(event);
  //     },
  //   },
  //   enter: {
  //     priority: 3,
  //     handler: (event) => {
  //       event.preventDefault();
  //       if (this.state.bill_number == "") this.saveBill(event);
  //     },
  //   },
  //   f1: {
  //     priority: 3,
  //     handler: (event) => {
  //       event.preventDefault();
  //       this.setState({open: true});
  //     },
  //   },
  //   f5: {
  //     priority: 3,
  //     handler: (event) => {
  //       event.preventDefault();
  //       this.props.onReset(this.props.kind);
  //     },
  //   },
  //   f8: {
  //     priority: 3,
  //     handler: (event) => {
  //       event.preventDefault();
  //       if (this.state.bill_id) this.setState({modal_open_inv: true});
  //     },
  //   },
  //   esc: {
  //     priority: 3,
  //     handler: (event) => {
  //       event.preventDefault();
  //       this.props.onClose(event);
  //     },
  //   },
  // };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(AccountDetail);
