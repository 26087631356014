const default_state = {
    list: []
  }
  const ledger_category = (state = default_state, action) => {
    switch (action.type) {
      case 'SET_LEDGER_CATEGORY_LIST':
        return {
          ...state,
          list: action.list,
        }
        
        default:
          return state
    }
  }
  
  export default ledger_category