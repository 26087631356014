/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {Table
} from 'semantic-ui-react';

import Utility from '../../Utility';
import PrintPreviewModal from '../PrintPreviewModal'

class ProductDetailPrintPreview extends Component {

    render() {
       
        let data = this.props.data

        let title = 'สรุปรายการซื้อ-ขายทอง';
        let filename = 'bills-'+Utility.formatDate3(data.start_date)+'-to-'+Utility.formatDate3(data.end_date);
      
        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU ={
            'text-decoration': 'underline'
        }
        const Width300 = {
            'width':'300px',
            'word-break':'break-word'
        }
        let amount_total = 0
        let total_sell = 0
        let total_buy = 0
        let total_diff = 0
        let weight_buy_title = 0
        let total_discount = 0
        let branch_name = '';
        let items = this.props.items
        for (let i in this.props.items) {
            let item = this.props.items[i];
            amount_total += item.amount
            total_sell += parseFloat(Utility.removeCommas(item.total_sell))
            total_buy += parseFloat(Utility.removeCommas(item.total_buy))
            weight_buy_title += parseFloat(Utility.removeCommas(item.weight_buy_title))
            total_diff += parseFloat(Utility.removeCommas(item.total_diff))
            total_discount += parseFloat(Utility.removeCommas(item.total_discount))
            branch_name = item.branch_name
            items[i].card_code = item.card_code == undefined ? '' :item.card_code.toString().replace(/(\d)(?=(\d{4})+(?!\d))/g, '$1 ')  
        }
        return (<div>
            <PrintPreviewModal title={title} filename={filename} onClose={this.props.onClose}>

                    <div id='view-print'>
                    <div id='paperA4-portrait'>
                        <Table basic id='table-to-xls' style={divStyle}>
                            <Table.Header>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='13'><center>{title}</center></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='13'><center>ตั้งแต่วันที่ {Utility.formatDate(data.start_date)} ถึง {Utility.formatDate(data.end_date)}</center></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row >
                                    <Table.HeaderCell colSpan='13'><center>สาขา {branch_name}</center></Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell style={textCenter}>เลขที่บิล</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>จำนวนขาย</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>รายรับ</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>รายจ่าย</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>ส่วนต่าง</Table.HeaderCell>
                                    <Table.HeaderCell style={textRight}>น.น.ซื้อทองเก่า</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>ชื่อลูกค้า</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>เวลา</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>ประเภทชำระ</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>รหัสบัตร</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>สถานะ</Table.HeaderCell>
                                    <Table.HeaderCell style={textCenter}>ยกเลิกโดย</Table.HeaderCell>
                                    {/* <Table.HeaderCell style={textCenter}></Table.HeaderCell> */}
                                    {/* <Table.HeaderCell style={textRight}>ส่วนลด</Table.HeaderCell> */}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {items.map((row, i) => 
                                <Table.Row key={i}>
                                    <Table.Cell style={textCenter}>{row.bill_number}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.date}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.amount}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.total_sell}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.total_buy}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.total_diff}</Table.Cell>
                                    <Table.Cell style={textRight}>{row.weight_buy_title}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.customer_name}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.time}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.type_pay}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.card_code}</Table.Cell>
                                    <Table.Cell style={textCenter}>{row.status_bill}</Table.Cell>
                                    <Table.Cell style={textCenter,Width300}>{row.canceler_name}</Table.Cell>
                                    {/* <Table.Cell style={textRight}>{row.total_discount}</Table.Cell> */}
                                    {/* <Table.Cell style={textCenter}></Table.Cell> */}
                                </Table.Row>)}

                                <Table.Row >
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    <Table.Cell style={textCenter}><div><b>รวม</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{amount_total}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_sell)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_buy)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(total_diff)}</b></div></Table.Cell>
                                    <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_buy_title)}</b></div></Table.Cell>
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    <Table.Cell style={textCenter}></Table.Cell>
                                    {/* <Table.HeaderCell style={textRight}><div style={textU}>{Utility.priceFormat(total_discount)}</div></Table.HeaderCell> */}
                                    {/* <Table.Cell style={textCenter}></Table.Cell> */}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    </div></PrintPreviewModal>
        </div>)
    }
}

export default ProductDetailPrintPreview;