/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table, FormField
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'
class PrintPreview extends Component {
    constructor(props) {
        super(props);


        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            branches: [],
            items: [],
            vendors:[],
            factory_search:0
        }
        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let fromData = Utility.jsonToQueryString(search)
        // let url = Settings.baseReportUrl + "/bill_items/?kind=XC&status_stock=Y&" + fromData
        var url = Settings.baseUrl + '/gold_99_list_report/?start_date='+moment(search.start_date).format('YYYY-MM-DD')+'&end_date='+moment(search.end_date).format('YYYY-MM-DD')+'&vendor='+this.state.factory_search

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            //alert(s)
            if (s == true) {
                this.setState({
                    loading: false,
                    items: d
                })
            }
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.branch) {
                if (item.bill.branch.id != this.state.search.branch)
                    continue;
            }

            if (this.state.search.product_type) {
                if (item.product.kind.id != this.state.search.product_type)
                    continue;
            }

            // if (this.state.search.product_type) {
                if (item.status_stock != "Y")
                    continue;
            // }



            item.date = Utility.formatDate(item.record_date)
            item.time = Utility.formatTime(item.record_date)
            item.number = item.bill.bill_number
            item.customer_name = item.bill.customer.name
            //item.product_code = item.product.code
            item.weight_b = Utility.weightFormat(item.weight / item.category.weight)

            try {
                let staffs = JSON.parse(item.bill.staffs)
                if (staffs.length > 0) {
                    let name = '';
                    let is_staff = false
                    for (let s in staffs) {
                        let st = Utility.getObject(this.staffs, staffs[s])
                        if (name != '')
                            name += ','
                        if (st) {
                            name += st.name
                        }

                        // check staff id
                        if (this.state.search.staff && is_staff == false) {
                            is_staff = this.state.search.staff == staffs[s]
                        } else {
                            is_staff = true;
                        }
                    }

                    if (!is_staff)
                        continue
                    item.staff_name = name
                } else if (this.state.search.staff != null)
                    continue
            } catch (e) {
                if (this.state.search.staff != null)
                    continue
            }
            items.push(item)
        }
        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let data = this.props.data

        let title = 'รายงานส่วนต่างทองเก่า-หลอมแล้ว';
        let filename = 'DIF-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        

        let total_weight = 0
        let total_weight_real = 0
        let total_weight_out = 0
        let total_cost = 0
        let total_b_weight = 0
        let total_b_cost = 0
        let total_dif = 0
        let goldexchange = 15.20
        let total_gold_g = 0
        let total_gold_b = 0
        let total_persen = 0
        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานส่วนต่างทองเก่า-หลอมแล้ว</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div className='modal-table-print'>
                        <div id='view-print'>
                            <div id='extra-wide2'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='18'><center id='date'>ตั้งแต่ : {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                        <Table.HeaderCell colSpan='9' style={textCenter} >ทองส่งหลอม</Table.HeaderCell>
                                        <Table.HeaderCell colSpan='7' className='borLder' style={textCenter} >นำเข้าทอง 99.99 </Table.HeaderCell>
                                        <Table.HeaderCell colSpan='1' rowspan='2' className='borLder' style={textCenter} >ส่วนต่างต้นทุนทองคำ</Table.HeaderCell>
                                        <Table.HeaderCell colSpan='1' rowspan='2' className='borLder' style={textCenter} >ส่วนต่างต้นทุนทองคำ(%) </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter} >วันที่นำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >เลขที่Lot</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนักทองเก่ารวม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนักรวมชั่ง</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนักชั่งส่งหลอม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุนทองคำ(กรัม) </Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุนทองคำ(บาท) </Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>มูลค่าทองคำส่งหลอม </Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาทองคำ 99.99 ณ วันส่งหลอม</Table.HeaderCell>


                                            <Table.HeaderCell className='borLder' style={textCenter} >วันที่นำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter} >เลขที่บิลนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น้ำหนักทองคำนำเข้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุนทองคำ(กรัม) </Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ต้นทุนทองคำ(บาท) </Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>มูลค่าทองคำนำเข้า </Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคาทองคำ 99.99 ณ วันนำเข้า </Table.HeaderCell>
                                            
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                    {this.state.items.length > 0 ? this.state.items.map((row, i) => {
                                        let gold_b = parseFloat(row.bill_import.weight.replaceAll(',', ''))*parseFloat(row.bill_import.cost.replaceAll(',', ''))
                                        let gold_g = parseFloat(row.weight_out.replaceAll(',', ''))*parseFloat(row.cost.replaceAll(',', ''))
                                        let dif_price = (gold_b) - (gold_g) 
                                        total_gold_g += gold_g
                                        total_gold_b += gold_b
                                        total_weight +=  parseFloat(row.weight.replaceAll(',', ''))
                                        total_weight_real += parseFloat(row.weight_real.replaceAll(',', ''))
                                        total_weight_out += parseFloat(row.weight_out.replaceAll(',', ''))
                                        total_cost += parseFloat(row.cost.replaceAll(',', ''))
                                        total_b_weight += parseFloat(row.bill_import.weight.replaceAll(',', ''))
                                        total_b_cost += parseFloat(row.bill_import.cost.replaceAll(',', ''))
                                        total_dif += parseFloat(dif_price)
                                        let persen_dif = parseFloat((dif_price/gold_b)*100).toFixed(2)
                                        total_persen += parseFloat(persen_dif)
                                            return (
                                            <Table.Row>
                                            <Table.Cell style={textCenter}>{row.lot_date}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.number}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_real)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(row.weight_out)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.cost)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(row.cost.replaceAll(',', ''))*goldexchange)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(gold_g)}</Table.Cell>
                                            <Table.Cell style={textRight}>{row.gold_price}</Table.Cell>  

                                            <Table.Cell className='borLder' style={textCenter}>{row.bill_import.import_date}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.bill_import.number}</Table.Cell>
                                            <Table.Cell style={textRight}>{row.bill_import.weight}</Table.Cell>
                                            <Table.Cell style={textRight}>{row.bill_import.cost}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(parseFloat(row.bill_import.cost.replaceAll(',', ''))*goldexchange)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(gold_b)}</Table.Cell>
                                            <Table.Cell style={textRight}>{row.bill_import.gold_price}</Table.Cell>
                                            <Table.Cell className='borLder' style={textRight}>{Utility.priceFormat(dif_price)}</Table.Cell>
                                            <Table.Cell className='borLder' style={textRight}>{Utility.priceFormat(persen_dif)}%</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{row.sell == 0 ? Utility.priceFormat(row.bill.total) : ''}</Table.Cell> */}
                                        </Table.Row>)
                                        }):
                                        <Table.Row>
                                            <Table.Cell colSpan='18' style={textCenter}>ไม่พบข้อมูล</Table.Cell>
                                        </Table.Row>
                                    }
                                    </Table.Body>

                                    <Table.Body>
                                        <Table.Row >
                                        <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>รวม</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.weightFormat(total_weight)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.weightFormat(total_weight_real)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.weightFormat(total_weight_out)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell> 
                                            <Table.Cell style={textRight}></Table.Cell> 
                                            <Table.Cell style={textRight}><b><u>{Utility.priceFormat(total_gold_g)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>  

                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textCenter}></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.weightFormat(total_b_weight)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.priceFormat(total_gold_b)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.priceFormat(total_dif)}</u></b></Table.Cell>
                                            <Table.Cell style={textRight}><b><u>{Utility.priceFormat(parseFloat((total_dif/total_gold_b)*100).toFixed(2))}%</u></b></Table.Cell>
                                            
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)