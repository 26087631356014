/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }

class RedeemPrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                // status: 1,
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
            status:[],
            check_branch_list: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var status = Utility.getFetch(Settings.baseReportUrl + '/orders/status');
        Promise.all([status]).then((values) => {
            this.status = values[0].data.data;
            let status = [{
                value: 0,
                    key: 0,
                    text: "ทั้งหมด"
            }]
            for (let i in this.status) {
                status.push({
                    value: this.status[i].id,
                    key: this.status[i].id,
                    text: this.status[i].name
                });
            }
            
            this.setState({
                loader_active: false,
                status: status,
                search:{
                    status:status[0].value,
                    start_date: moment(),
                    end_date: moment(),
                },
                status_name:this.status[0].name
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
           
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY'),
                }
            })
        }

        var branches = Utility.getFetch(Settings.baseUrl + '/branches/');
        var code_branch_list = []
        Promise.all([branches]).then((values) => {
			for(var i=0; i<values[0].length; i++) {
				code_branch_list.push('#'+values[0][i].code+'|');
			}
			this.setState({ check_branch_list: code_branch_list })
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                status: this.state.status[0].value,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        
        if (search.status == 0) {
            delete search["status"]
        }
        let fromData = Utility.jsonToQueryString(search)

        let url = Settings.baseReportUrl + "/redeem_report/?" + fromData

        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d.data)
                })
            }
            console.log(d.data);
        })
    }

    setFieldValue(_items) {
        let items = []
        for (let i in _items) {
            let item = _items[i]
            item.no = Number(i)+1
            item.order_products.push({is_sum:true})

            items.push(item)
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search,
            // status_name:
        })
    }


    render() {
        let status_name = 'ทั้งหมด'
        for(let i=0 ;i<this.state.status.length;i++){
            console.log(this.state.status[i])
            if(this.state.status[i].value == this.state.search.status){
                status_name = this.state.status[i].text
            }
        }
        
        let title = 'รายงานแลกของรางวัล';
        let filename = 'reward';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        const productWidth = {
            'width': '250px',
            'word-break': 'break-word',
        }
        
        let total_amount = 0
        let total_price = 0
        let total_point = 0
        let total_shipping_cost = 0

        this.state.items.sort(sortByProperty("id"));

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานแลกของรางวัล</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สถานะการจัดส่ง
                                </label>
                                <Dropdown placeholder='สถานะการจัดส่ง' search selection options={this.state.status} value={this.state.search.status} name='status' 
                                onChange={this.handlerInput} />
                            </Form.Field>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='13'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                <Table.HeaderCell colSpan='13'>สถานะ : {status_name}</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textLeft}>No.</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รหัสลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>วันที่ทำรายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>วันที่เคลื่อนไหวล่าสุด</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขที่ใบสั่งซื้อ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>สถานะใบสั่งซื้อ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>สถานะในการรับสินค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคา</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>แต้ม</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ค่าจัดส่ง</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                    {this.state.items.map((row,i)=> {
                                            let multiple = Number(row.point) / Number(row.total_price)
                                            let amount = 0
                                            let price = 0
                                            let point = 0
                                            let shipping_cost = 0
                                            var check_branch = this.state.check_branch_list.some(branch => row.order_shipping.metadata.full_name.includes(branch));
                                            return (
                                                row.order_products.map((row2,j) => {
                                                    if(!(row2.is_sum)) {
                                                        amount += Number(row2.quantity)
                                                        total_amount += Number(row2.quantity)
                                                        price += Number(row2.subtotal)
                                                        total_price += Number(row2.subtotal)
                                                        point += (Number(row2.subtotal)*multiple)
                                                        total_point += (Number(row2.subtotal)*multiple)
                                                        if(row.order_status_id == 4){
                                                            shipping_cost += Number(row.order_shipping.shipper_fee)
                                                            total_shipping_cost += Number(row.order_shipping.shipper_fee)
                                                        }
                                                    }
                                                return (
                                                row2.is_sum ? 
                                                <Table.Row >
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textLeft}></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>รวม</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.numberFormat(amount)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(price)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(point)}</b></Table.Cell>
                                                    <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(shipping_cost)}</b></Table.Cell>
                                                </Table.Row>
                                                : 
                                                <Table.Row >
                                                    <Table.Cell style={textLeft}>{j==0?Utility.numberFormat(i+1):''}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?row.member.ref_name:''}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?row.customer:''}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?Utility.formatDate(row.created_at):''}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?Utility.formatDate(row.updated_at):''}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?'#'+ Number(row.id).toString().padStart(5, '0'):''}</Table.Cell>
                                                    <Table.Cell style={textLeft,productWidth}>{row2.metadata.product.name}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?row.status.name:''}</Table.Cell>
                                                    <Table.Cell style={textLeft}>{j==0?check_branch ? 'นัดรับที่สาขา' : 'ส่งตามที่อยู่ไปรษณีย์':''}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.numberFormat(row2.quantity)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(row2.subtotal)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{Utility.priceFormat(Number(row2.subtotal)*multiple)}</Table.Cell>
                                                    <Table.Cell style={textRight}>{j==0? 
                                                    row.order_status_id != 4 ? 
                                                    Utility.priceFormat(0)
                                                    :Utility.priceFormat(row.order_shipping.shipper_fee)
                                                    :''}</Table.Cell>
                                                </Table.Row>
                                                    );
                                                })    
                                            );}
                                    )}
                                    <Table.Row >
                                            <Table.Cell colSpan='9' style={textRight}><b style={textU}>รวมทั้งหมด</b></Table.Cell>
                                            <Table.Cell style={textRight}><b style={textU}>{Utility.numberFormat(total_amount)}</b></Table.Cell>
                                            <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_price)}</b></Table.Cell>
                                            <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_point)}</b></Table.Cell>
                                            <Table.Cell style={textRight}><b style={textU}>{Utility.priceFormat(total_shipping_cost)}</b></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>
                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(RedeemPrintPreview)
