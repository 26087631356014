/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import {Table, Column, Cell} from 'fixed-data-table';
import ItemsCell from '../../ItemsCell'
import OptionItemsCell from '../../OptionItemsCell'
class BillItemTable extends Component {

    constructor(props){
        super(props)
        this.state = {
            table_width: 600,
        }
    }

    componentDidMount(){
        setTimeout(()=>{
            window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()
          },400)
    }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    
      updateDimensions = () => {
        let elHeight = document.getElementById('table_width2')
        this.setState({ table_width: elHeight.clientWidth });
      };

    render() {
        const items = this.props.items

        return (

            <div id="table_width2">
            <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={30}
                width={this.state.table_width}
                height={300}>
                <Column
                    header={< Cell > </Cell>}
                    cell={< OptionItemsCell edit={false} data = { items }  field = "status_stock_title" onClickMenu={this.props.onActionItemCell}/>}
                    width={50}/>

                <Column
                    header={< Cell className = 'text-left' >เลขที่บิล</Cell>}
                    cell={< ItemsCell position='top left' width={120} data = {
                    items
                }
                field = "number" textAlign = 'text-left' />}
                    width={120}/>
                <Column
                    header={< Cell className = 'text-right' >น้ำหนักทองเก่ารวม</Cell>}
                    cell={< ItemsCell position='top right' width={140} data = {
                    items
                }
                field = "weight" textAlign = 'text-right' />}
                    width={140}/>

                <Column
                    header={< Cell className = 'text-right' >น้ำหนักชั่งห่อรวม</Cell>}
                    cell={< ItemsCell position='top right' width={140} data = {
                    items
                }
                field = "weight_real" textAlign = 'text-right' />}
                    width={140}/>

                <Column
                    header={< Cell className = 'text-right' >น้ำหนักชั่งส่งหลอม</Cell>}
                    cell={< ItemsCell position='top right' width={140} data = {
                    items
                }
                field = "weight_out" textAlign = 'text-right' />}
                    width={140}/>

                <Column
                    header={< Cell className = 'text-right' >ราคาทองคำ99.99</Cell>}
                    cell={< ItemsCell position='top right' width={120} data = {
                    items
                }
                field = "gold_price" textAlign = 'text-right' />}
                    width={120}/>

                <Column
                    header={< Cell className = 'text-center' >วันที่</Cell>}
                    cell={< ItemsCell width={120} data = {
                    items
                }
                field = "bill_date" textAlign = 'text-center' />}
                    width={120}/>
            </Table></div>
        )
    }

}

export default BillItemTable