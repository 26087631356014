/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: 0,
                date: moment(),
            },
            total: 0,
            items: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        Promise.all([branches,]).then((values) => {
            this.branches = values[0];
            let branches = [{
                value: 0,
                key: 0,
                text: 'ทุกสาขา'
            }]
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
            });
        });
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: 0,
                date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.date = Utility.formatDate2(search.date)
        if (this.state.search.branch == 0)
            delete search['branch']
        let q = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/saving_on_date/?" + q
        this.setState({
            open: true,
            loading: true,
            items: [],
            total: 0,
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: d.data,
                    total: d.summary.total,
                })
            } else {
                this.setState({
                    loading: false,
                    items: []
                })
            }
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let title = 'รายงานยอดคงเหลือ ณ วันที่';
        let filename = 'savings-on-date-' + Utility.formatDate3(this.state.search.date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        let branch_name = 'ทุกสาขา'
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานยอดคงเหลือ ณ วันที่</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Dropdown
                                id='branch'
                                value={this.state.search.branch}
                                defaultValue={0}
                                label='สาขา'
                                fluid
                                size='small'
                                search
                                name='branch'
                                selection
                                options={this.state.branches}
                                onChange={this.handlerInput}
                            />
                            <Form.Field >
                                <label>วันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.date}
                                    selected={this.state.search.date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='7'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>สาขา</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รหัสลูกค้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่บัญชี</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อบัญชี</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>คงเหลือ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft, { 'width': '300px', 'word-break': 'break-word' }}>หมายเหตุ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((row, i) => <Table.Row key={i}>
                                            <Table.Cell style={textCenter}>{Utility.numberFormat(i+1)}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.branch_name}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.customer_code}</Table.Cell>
                                            <Table.Cell style={textCenter}>{row.number}</Table.Cell>
                                            <Table.Cell style={textLeft}>{row.name}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(row.balance_now)}</Table.Cell>
                                            <Table.Cell style={textLeft, { 'width': '300px', 'word-break': 'break-word' }}>{row.remark}</Table.Cell>
                                        </Table.Row>)}
                                        <Table.Row >
                                            <Table.Cell colSpan='5' style={textRight}><div style={textU}><b>ยอดรวม</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div id='total' style={textU}><b>{Utility.priceFormat(this.state.total)}</b></div></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)