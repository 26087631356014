/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';
import { connect } from 'react-redux'
import {
  Menu,
  Grid,
  Form,
  Segment,
  Header,
  Icon
} from 'semantic-ui-react';
import RewardList from './RewardList'
import StockRewardList from './StockRewardList'
import RedeemList from './RedeemList'
import RewardCategory from './RewardCategory'
import RewardProduct from './RewardProduct'
import RewardDashboard from './RewardDashboard'
import { activations_branch, loadBills } from '../../actions'
import RewardSetting from '../Files/RewardSetting';
import RewardPickup from './RewardPickup';
import RewardRedeemPoint from './RewardRedeemPoint';
import RedeemConfigList from './RewardRedeemConfig';

class Reward extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: this.props.location.pathname
    }

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, { name, to }) {
    this.setState({
      activeItem: to
    });
  }

  componentDidMount() {

    let elHeight = document.getElementById('content-body')

    this.setState({
      clientWidth: elHeight.clientWidth
    })
  }

  handleBranchChange(e, { value }) {
    const { branches } = this.props
    const branch = branches.find((item) => item.id == value)
    this.props.handleBranchChange(branch)
  }

  render() {
    const { auth } = this.props
    const { role, branch } = auth
    let clientWidth = this.state.clientWidth
    return (
      <Router>
        <div>
          <Form size='small'>
            <Form.Group>
              <Form.Field width={6}>
                <Header floated='left' as='h3'>
                  <div style={{ padding: '7px' }} className="pull-left"><Icon name='gift' /> ของรางวัล</div>
                </Header>
              </Form.Field>
            </Form.Group>
          </Form>
          <Grid>
            <Grid.Column width={3}>
              <Menu secondary vertical className='menu-left'>
                <Segment color='black'>
                  {/* <Menu.Item
                    name='sell'
                    active={this.state.activeItem == '/reward/redeem'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/redeem'
                  >รายการขอแลกของรางวัล</Menu.Item> */}
                  {/* {role == 'A' || role == 'M' || role == 'S' ? <Menu.Item
                    name='sell'
                    active={this.state.activeItem == '/reward/list'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/list'
                  >รายการของรางวัล</Menu.Item> : ''} */}
                  {/* {role == 'A' || role == 'M' || role == 'S' ? <Menu.Item
                    name='sell'
                    active={this.state.activeItem == '/reward/stock'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/stock'
                  >สต็อกของรางวัล</Menu.Item> : ''} */}
                  {role == 'A' || role == 'S' ? <Menu.Item
                    active={this.state.activeItem == '/reward/category'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/category'
                  >จัดการหมวดหมู่</Menu.Item> : ''}
                  {role == 'A' || role == 'S' ? <Menu.Item
                    active={this.state.activeItem == '/reward/product'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/product'
                  >จัดการสินค้า</Menu.Item> : ''}
                  {role == 'A' || role == 'S' ? <Menu.Item
                    active={this.state.activeItem == '/reward/dashboard'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/dashboard'
                  >จัดการสินค้าแนะนำ</Menu.Item> : ''}
                  {role == 'A' || role == 'S' || role == 'M' || role == 'U' ? <Menu.Item
                    active={this.state.activeItem == '/reward/pickup'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/pickup'
                  >นัดรับสินค้าที่สาขา</Menu.Item> : ''}
                  {role == 'A' || role == 'S' || role == 'M' || role == 'U' ? <Menu.Item
                    active={this.state.activeItem == '/reward/redeem_point'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/redeem_point'
                  >Redeem Point</Menu.Item> : ''}
                   {role == 'A' || role == 'S' ? <Menu.Item
                    active={this.state.activeItem == '/reward/redeemconfig'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/redeemconfig'
                  >จัดการเกม</Menu.Item> : ''}
                  {role == 'A' || role == 'S' ? <Menu.Item
                    active={this.state.activeItem == '/reward/setting'}
                    onClick={this.handleItemClick}
                    as={Link}
                    to='/reward/setting'
                  >ตั้งค่าของรางวัล</Menu.Item> : ''}
                </Segment>
              </Menu>
            </Grid.Column>
            <Grid.Column stretched width={13} className="content-body" >
              <Segment color='black'>
                <div id="content-body">

                  {/* {role == 'A' || role == 'M' || role == 'S' ? <Route path="/reward/stock" component={() => (<StockRewardList items={[]} clientWidth={clientWidth} />)} /> : ''} */}
                  {/* {role == 'A' || role == 'M' || role == 'S' ? <Route path="/reward/list" component={() => (<RewardList items={[]} clientWidth={clientWidth} />)} /> : ''} */}
                  {role == 'A' || role == 'S' ? <Route path="/reward/category" component={() => (<RewardCategory items={[]} clientWidth={clientWidth} />)} /> : ''}
                  {role == 'A' || role == 'S' ? <Route path="/reward/product" component={() => (<RewardProduct items={[]} clientWidth={clientWidth} />)} /> : ''}
                  {role == 'A' || role == 'S' ? <Route path="/reward/setting" component={() => (<RewardSetting />)} /> : ''}
                  {role == 'A' || role == 'S' ? <Route path="/reward/dashboard" component={() => (<RewardDashboard />)} /> : ''}
                  {role == 'A' || role == 'S' || role == 'M' || role == 'U' ? <Route path="/reward/pickup" component={() => (<RewardPickup />)} /> : ''}
                  {role == 'A' || role == 'S' || role == 'M' || role == 'U' ? <Route path="/reward/redeem_point" component={() => (<RewardRedeemPoint />)} /> : ''}
                  {/* <Route path="/reward/redeem" component={() => (<RedeemList items={[]} clientWidth={clientWidth} />)} /> */}
                  {role == 'A' || role == 'S' ? <Route path="/reward/redeemconfig" component={() => (<RedeemConfigList items={[]} clientWidth={clientWidth} />)} /> : ''}
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      </Router>

    );
  }
}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}
const mapDispatchToProps = dispatch => ({
  handleBranchChange: (branch) => {
    dispatch(activations_branch(dispatch, branch))
  }

})
export default connect(
  mapStateToProps, mapDispatchToProps
)(Reward)

