/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Button,
  Grid,
  Input,
  Checkbox,
  Header,
  Segment,
  Dropdown
} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';
import CalculateDay from '../Lease/CalculateDay';
import MsgInput from '../Error/MsgInput'
import { connect } from 'react-redux'


class SettingScore extends Component {

  constructor(props) {
    super(props);

    const { branch } = props.auth
    this.state = {
      error: {},
      setting: {
        branch: branch.id,
        fee_cost: 0,
        packaging_cost: 0,
        score_multiply: 0,
        xc_point: 0,
      },
      setting_id: [],
      branch_id: branch.id
    }
    this.onChangeInput = this.onChangeInput.bind(this)
    this.saveSetting = this.saveSetting.bind(this)
  }

  componentDidMount() {
    this.setState({
      loader_active: true
    });

    let system_setting = JSON.parse(localStorage.getItem('system_setting'))
    let setting_value = system_setting
    let setting = this.state.setting
    let setting_id = this.state.setting_id

    let fee_setting = setting_value.find(k => k.setting === 'FEE_COST')
    let packaging_setting = setting_value.find(k => k.setting === 'PACK_COST')
    let score_setting = setting_value.find(k => k.setting === 'SCOR')
    let xc_point_setting = setting_value.find(k => k.setting === 'XC_POINT')


    setting_id.push(fee_setting.id)
    setting_id.push(packaging_setting.id)
    setting_id.push(score_setting.id)
    setting_id.push(xc_point_setting.id)

    setting.fee_cost = fee_setting.value
    setting.packaging_cost = packaging_setting.value
    setting.score_multiply = score_setting.value
    setting.xc_point = xc_point_setting.value

    this.setState({
      setting: setting,
      setting_id: setting_id,
    })
  }

  onChangeInput(e, v) {
    let setting = this.state.setting;
    if (v.name == 'fee_cost' || v.name == 'packaging_cost' || v.name == 'score_multiply' || v.name == 'xc_point') {
      if (isNaN(Number(v.value))) {
      } else {
        if (v.value.length === 0) {
          v.value = 0
        }
        let j = parseFloat(v.value)
        if (j < 0)
          v.value = 0
      }
    }
    if (v.name == 'fee_cost' || v.name == 'packaging_cost' || v.name == 'score_multiply' || v.name == 'xc_point') {
      if (isNaN(Number(v.value))) {
      } else {
        setting[v.name] = parseInt(v.value);
      }
    } else {
      setting[v.name] = v.value;
    }

    this.setState({
      setting: setting
    })


  }

  saveSetting() {
    let setting_id = this.state.setting_id
    let setting = this.state.setting
    let success_count = 0

    for (let i in setting_id) {
      let url = Settings.baseUrl + "/system_setting/" + setting_id[i] + "/";
      let formData = {}
      if (i == 0) {
        formData.value = setting.fee_cost
      }
      if (i == 1) {
        formData.value = setting.packaging_cost
      }
      if (i == 2) {
        formData.value = setting.score_multiply
      }
      if (i == 3) {
        formData.value = setting.xc_point
      }

      Utility.patch(url, formData, (s, d) => {
        if (s) {
          success_count += 1
        }

        if (success_count == setting_id.length) {
          Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
            if (status) {
              localStorage.setItem("system_setting", JSON.stringify(data));
            }
          });
          setTimeout(() => {
            alert('บันทึกข้อมูลสำเร็จ!')
            this.componentDidMount()
          }, 200)

        }
      });
    }

  }

  render() {
    return (
      <div>
        <Form className='attached fluid ' size='small'>
          <Grid divided='vertically'>
            <Grid.Row >

              <Grid.Column width={8}>
                <Segment>
                  <Header size='huge'>ต้นทุนค่าบริการ</Header>
                  <Form.Field error={this.state.error.fee_cost != null}>
                    <label>ต้นทุน <MsgInput text={this.state.error.fee_cost} /></label>
                    <Input id='fee_cost' className='text-right'
                      value={this.state.setting.fee_cost == undefined ? '' :
                        this.state.focus_fee_cost_open ?
                          this.state.setting.fee_cost :
                          Utility.numberFormat(this.state.setting.fee_cost)}
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_fee_cost_open) {
                            this.setState({
                              focus_fee_cost_open: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_fee_cost_open) {
                          this.setState({
                            focus_fee_cost_open: true,
                          });
                        }
                      }}
                      name='fee_cost' pattern="[0-9]"
                      onChange={this.onChangeInput}
                      min='0'
                      maxLength={7} />
                    {/* <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ1ตัวอักษรเช่น A</small> */}
                  </Form.Field>

                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Header size='huge'>ต้นทุนค่า packaging</Header>
                  <Form.Field error={this.state.error.packaging_cost != null}>
                    <label>ต้นทุน <MsgInput text={this.state.error.packaging_cost} /></label>
                    <Input id='packaging_cost' className='text-right'
                      value={this.state.setting.packaging_cost == undefined ? '' :
                        this.state.focus_packaging_cost ?
                          this.state.setting.packaging_cost :
                          Utility.numberFormat(this.state.setting.packaging_cost)}
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_packaging_cost) {
                            this.setState({
                              focus_packaging_cost: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_packaging_cost) {
                          this.setState({
                            focus_packaging_cost: true,
                          });
                        }
                      }}
                      name='packaging_cost' pattern="[0-9]"
                      onChange={this.onChangeInput}
                      min='0'
                      maxLength={7} />
                    {/* <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ1ตัวอักษรเช่น A</small> */}
                  </Form.Field>

                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >

              <Grid.Column width={8}>
                <Segment>
                  <Header size='huge'>ตัวคูณแต้ม</Header>
                  <Form.Field error={this.state.error.score_multiply != null}>
                    <label>ตัวคูณ <MsgInput text={this.state.error.score_multiply} /></label>
                    <Input id='score_multiply' className='text-right'
                      value={this.state.setting.score_multiply == undefined ? '' :
                        this.state.focus_score_multiply_open ?
                          this.state.setting.score_multiply :
                          Utility.numberFormat(this.state.setting.score_multiply)}
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_score_multiply_open) {
                            this.setState({
                              focus_score_multiply_open: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_score_multiply_open) {
                          this.setState({
                            focus_score_multiply_open: true,
                          });
                        }
                      }}
                      name='score_multiply' pattern="[0-9]"
                      onChange={this.onChangeInput}
                      min='0'
                      maxLength={7} />
                    {/* <small>ต้องเป็นตัวพิมพ์ใหญ่ A-Z และ1ตัวอักษรเช่น A</small> */}
                  </Form.Field>

                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Header size='huge'>เปลี่ยนทอง</Header>
                  <Form.Field error={this.state.error.xc_point != null}>
                    <label>แต้ม <MsgInput text={this.state.error.xc_point} /></label>
                    <Input id='xc_point' className='text-right'
                      value={this.state.setting.xc_point == undefined ? '' :
                        this.state.focus_xc_point_open ?
                          this.state.setting.xc_point :
                          Utility.numberFormat(this.state.setting.xc_point)}
                      onBlur={() => {
                        setTimeout(() => {
                          if (this.state.focus_xc_point_open) {
                            this.setState({
                              focus_xc_point_open: false,
                            });
                          }
                        }, 0);
                      }}
                      onFocus={(e) => {
                        e.target.select()
                        if (!this.state.focus_xc_point_open) {
                          this.setState({
                            focus_xc_point_open: true,
                          });
                        }
                      }}
                      name='xc_point' pattern="[0-9]"
                      onChange={this.onChangeInput}
                      min='0'
                      maxLength={5} />
                  </Form.Field>

                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Form.Group>

            <Form.Field width={16}>
              <br />
              <Button id='btnsave_setting' content='บันทึก' onClick={this.saveSetting}
                loading={this.state.button_loading}
                floated='right' type='button' primary />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}

export default connect(
  mapStateToProps,
)(SettingScore)
