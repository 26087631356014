/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import BillItemModal from './BillItemModal'
import { connect } from 'react-redux'
import { Table, Column, Cell } from 'fixed-data-table';
import { invoice_item_open, invoice_item_set_value, invoice_item_close, invoice_item_add, invoice_item_remove } from '../../../../reducers/invoice_item_modal'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Dropdown,
  Input,
  Modal,
  Grid,
  Popup,
  TextArea,
  Checkbox
} from 'semantic-ui-react';
import BillPrintPreview from './BillPrintPreview'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OptionItemsCell from '../../OptionItemsCell'
import BillQrcodeModal from './BillQrcodeModal'
import ImportInvoiceItem from "../../../Import/ImportInvoiceItem";
import { loadProduct } from '../../../../reducers/products';
import ProductFromModal from '../../../Files/Product/ProductFromModal'

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rowIndex,
      field,
      data,
      ...props
    } = this.props;
    let width = (this.props.width - 10) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }

    return (
      <Cell {...props}>
        <Popup
          position={this.props.position}
          content={data[rowIndex][field]}
          style={Width300}
          trigger={
            <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
        />
      </Cell>
    );
  }
}

class TransferStock extends Component {

  constructor(props) {
    super(props);

    this.modal_status = false;
    this.state = {
      message_error_hidden: true,
      items: [],
      invoice_date: moment(),
      vendor: '',
      status_stock: 'N',
      modal_open: this.props.open,
      modal_add_item: false,
      action_title: 'เพิ่มสินค้า',
      action: 'add',
      invoice_item: [],
      button_print_disabled: true,
      button_update_stoick_disabled: true,
      button_save_disabled: false,
      button_update_stoick_loading: false,
      invoice_title: 'เพิ่มรายการแยกตะขอ',
      loader: false,
      status_bill: 'N',
      button_save_title: 'สร้าง',
      invoice_add_product_disabled: true,
      invoice_description: '',
      msg_error: {},
      cost: '',
      code: '',
      product_modal_open: false,
      weight_hook: '',
      average_cost: '',
    }

    this.product_code_all = [];
    this.product_name_all = [];
    this.categories = [];
    this.product_types = [];

    this.onOpenModal = this
      .onOpenModal
      .bind(this);
    this.onDeleteProduct = this
      .onDeleteProduct
      .bind(this);
    this.submitQRcode = this.submitQRcode.bind(this);
    this.handleSubmitAddItem = this.handleSubmitAddItem.bind(this);
    this.handleSubmitAddHook = this.handleSubmitAddHook.bind(this);
  }

  submitQRcode() {
    let data = []
    data['code'] = this.state.code.trim()
    data['stock_type'] = 'old'
    data['branch'] = this.props.invoice_detail.branch

    let qrstring = Utility.jsonToQueryString(data);
    let old_product = ''
    let name = ''
    let old_qrcode = ''
    let multiplier = ''
    let total_weight = ''
    var qrcode = Utility.getFetch(Settings.baseUrl + '/qrcode/?' + qrstring);
    var invoice_items = Utility.getFetch(Settings.baseUrl + '/invoice_split_hook/' + this.props.invoice_detail.id + "/items/");
    Promise.all([qrcode, invoice_items]).then((values) => {
      if (values[0].detail === '' || values[0].detail === undefined) {
        // this.setState({data:values[0]})
        // this.setFieldValue(values[0])
        let duplicate_qrcode = values[1].find(k => k.qrcode == values[0].qrcode)
        if (duplicate_qrcode == undefined) {
          old_product = values[0]
          name = values[0].name
          old_qrcode = values[0].qrcode
          multiplier = values[0].cost / values[0].weight
          total_weight = values[0].weight
        } else {
          alert('qrcode มีอยู่ในรายการสินค้าของบิลนี้แล้ว')
          this.setState({ code: '' })
          return;
        }
      } else {
        alert(values[0].detail)
        this.setState({ code: '' })
        return;
      }
      this.setState({
        code: '',
        modal_add_item: true,
        modal_title: 'ลงรายการตะขอ',
        old_product: old_product,
        name: name,
        old_qrcode: old_qrcode,
        multiplier: multiplier,
        total_weight: total_weight,
        old_fee: '',
        weight_hook: '',
        msg_error: {},
      })
    })
  }

  async handleSubmitAddItem(old_product, weight_hook) {

    let msg_error = {}

    if (weight_hook == '' || weight_hook == undefined) {
      msg_error['weight_hook'] = 'กรุณากรอกน้ำหนักตะขอ'
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error
      })
      return;
    }

    const url = Settings.baseUrl + `/invoice_split_hook_item/`
    const formData = {
      weight: (parseFloat(weight_hook)).toFixed(3),
      cost: parseFloat(this.state.old_fee).toFixed(2),
      invoice_split_hook: this.props.invoice_detail.id,
      ref_old_product: old_product.id,
      qrcode: this.state.old_qrcode,
      name: 'ตะขอ'
    }
    const formData2 = {
      weight: (parseFloat(this.state.total_weight - weight_hook)).toFixed(3),
      cost: parseFloat(old_product.cost - this.state.old_fee).toFixed(2),
      invoice_split_hook: this.props.invoice_detail.id,
      ref_old_product: old_product.id,
      qrcode: this.state.old_qrcode,
      name: this.state.name
    }
    await Utility.postAsync(url, formData)
    await Utility.postAsync(url, formData2)

    this.loadInvoiceItem(true)
    this.setState({
      'modal_add_item': false
    })

  }

  async handleSubmitAddHook() {
    let msg_error = {}
    if (this.state.weight_new_hook == '' || this.state.weight_new_hook == undefined) {
      msg_error['weight_new_hook'] = 'กรุณากรอกน้ำหนักตะขอ'
    }
    if (this.state.old_cost == '' || this.state.weight_new_hook == undefined) {
      msg_error['old_cost'] = 'กรุณากรอกต้นทุนเฉลี่ย'
    }
    if (this.state.category == '' || this.state.weight_new_hook == undefined) {
      msg_error['category'] = 'กรุณาเลือกกลุ่มสินค้า'
    }
    this.setState({
      msg_error: msg_error
    })
    if (Object.keys(msg_error).length > 0) {
      return;
    }

    const url = Settings.baseUrl + `/invoice_split_hook_item/`
    const formData = {
      weight: (parseFloat(this.state.weight_new_hook)).toFixed(3),
      cost: parseFloat(this.state.old_cost).toFixed(2),
      invoice_split_hook: this.props.invoice_detail.id,
      category: this.state.category,
      name: 'ตะขอ'
    }
    await Utility.postAsync(url, formData)

    this.loadInvoiceItem(true)
    this.setState({
      'modal_add_new_item': false
    })

  }

  submitSaveInvoice() {
    let msg_error = {}
    if (this.state.branch_id === undefined) {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    var formData = {
      branch: this.state.branch_id,
      vendor: this.state.vendor,
      cost: this.state.cost.replace(/,/g, ''),
      ref_number: this.state.ref_number,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: this.state.status_bill,
      status_stock: this.state.status_stock,
      kind: 'IO',
      description: this.state.invoice_description
    };

    this.setState({ button_save_loading: true, msg_error: {} })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoice_split_hook/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          msg_error = data
          if (data.description !== undefined) {
            if (data.description[0] === "Ensure this field has no more than 255 characters.") {
              msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
            }
          }
          this.setState({ msg_error: msg_error })
        }
      });
    } else {
      var formData2 = {
        branch: this.state.branch_id,
        vendor: this.state.vendor,
        ref_number: this.state.ref_number,
        // invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.invoice_description
      };

      const url = Settings.baseUrl + '/invoice_split_hook/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          msg_error = data
          if (data.description !== undefined) {
            if (data.description[0] === "Ensure this field has no more than 255 characters.") {
              msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
            }
          }
          this.setState({ msg_error: msg_error })
        }
      });
    }
  }

  submitSaveInvoice_no_alert() {
    let msg_error = {}
    if (this.state.branch_id === undefined) {
      msg_error['branch'] = ['กรุณาเลือกสาขา']
    }

    if (Object.keys(msg_error).length > 0) {
      this.setState({ msg_error: msg_error });
      return;
    }

    var formData = {
      branch: this.state.branch_id,
      vendor: this.state.vendor,
      cost: this.state.cost.replace(/,/g, ''),
      ref_number: this.state.ref_number,
      invoice_date: Utility.formatDate2(this.state.invoice_date),
      status_bill: this.state.status_bill,
      status_stock: this.state.status_stock,
      kind: 'IO',
      description: this.state.invoice_description
    };

    this.setState({ button_save_loading: true, msg_error: {} })
    if (this.props.modal_action == 'add') {
      const url = Settings.baseUrl + '/invoice_split_hook/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false, invoice_add_product_disabled: false, invoice_disabled: true, button_save_title: 'บันทึก' })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
        } else { // error
          msg_error = data
          if (data.description !== undefined) {
            if (data.description[0] === "Ensure this field has no more than 255 characters.") {
              msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
            }
          }
          this.setState({ msg_error: msg_error })
        }
      });
    } else {
      var formData2 = {
        branch: this.state.branch_id,
        vendor: this.state.vendor,
        ref_number: this.state.ref_number,
        // invoice_date: Utility.formatDate2(this.state.invoice_date),
        status_bill: this.state.status_bill,
        description: this.state.invoice_description
      };

      const url = Settings.baseUrl + '/invoice_split_hook/' + this.props.invoice_detail.id + "/";

      Utility.put(url, formData2, (status, data) => {
        this.setState({ button_save_loading: false })
        if (status) {
          this.setState({ button_save_loading: false })
          this
            .props
            .onAddInvoice(data);
          this.loadInvoiceItem()
        } else { // error
          msg_error = data
          if (data.description !== undefined) {
            if (data.description[0] === "Ensure this field has no more than 255 characters.") {
              msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
            }
          }
          this.setState({ msg_error: msg_error })
        }
      });
    }
  }


  loadInvoiceItem(reload) {

    var invoices = Utility.getFetch(Settings.baseUrl + '/invoice_split_hook/' + this.props.invoice_detail.id + "/");
    var items = Utility.getFetch(Settings.baseUrl + '/invoice_split_hook/' + this.props.invoice_detail.id + '/items/');
    this.setState({ loader: true });
    Promise
      .all([invoices, items])
      .then((values) => {
        // set invoice detail
        let item = values[0];
        let branch_option = Utility.getOptions(this.props.branches)
        for (let i in branch_option) {
          if (branch_option[i].value === item.branch) {
            this.setState({ branch_name: branch_option[i].text })
          }
        }
        let status_bill = this.state.status_bill
        let invoice_description = this.state.invoice_description
        this.setState({
          number: item.number,
          branch_id: item.branch,
          vendor: item.vendor,
          invoice_date: moment(item.invoice_date),
          cost: Utility.priceFormat(item.cost),
          status_stock: item.status_stock,
          status_bill: item.status_bill,
          ref_number: item.ref_number,
          invoice_description: item.description,
          invoice_disabled: true,
          invoice_item: this.setFieldValue(values[1]),
          button_print_disabled: false,
          button_update_stoick_disabled: values[1].length == 0 || item.status_stock == 'Y' || item.status_stock == 'D',
          button_save_title: 'บันทึก',
          invoice_add_product_disabled: item.status_stock == 'Y' || item.status_stock == 'D',
          invoice_title: <span>{this.props.action == 'import'
            ? <span>แก้ไขรายการทองใหม่เข้าสต๊อก
                </span>
            : <span>แก้ไขรายการแยกตะขอ</span>
          } </span>,
          loader: false,
          button_update_stoick_loading: item.status_stock == 'D' ? true : false,
        });
        if (reload == true) {
          this.setState({
            status_bill: status_bill,
            invoice_description: invoice_description,
          });
        }
        if (item.status_stock == 'D') {
          setTimeout(() => { this.loadInvoiceItem(true) }, 5000)
        }
      });
  }

  setFieldValue(item) {
    let invoice_weight_total = 0;
    let invoice_product_total = 0;
    let invoice_itme_total = item.length;
    // let invoice_profit_total = 0;
    let invoice_fee_total = 0
    let invoice_cost_total = 0
    for (let i = 0; i < item.length; i++) {
      item[i]['status_stock_title'] = Settings.status_stock[item[i].status_stock]
      item[i]['product_name'] = item[i].name
      item[i]['category_title'] = item[i].ref_old_product ? item[i].ref_old_product.stock_old_product.category.name : item[i].category.name
      item[i]['weight'] = Utility.weightFormat(item[i].weight)
      item[i]['fee'] = Utility.priceFormat(item[i].cost)
      item[i]['ref_qrcode'] = item[i].ref_old_product ? item[i].ref_old_product.qrcode : '-'
      item[i]['cost'] = Utility.priceFormat(item[i].cost)

      invoice_weight_total += parseFloat(Utility.removeCommas(item[i].weight));
      invoice_product_total += parseInt(1);

      // invoice_fee_total += parseFloat((Utility.removeCommas(item[i].fee)) * Utility.removeCommas(item[i].amount))
      invoice_cost_total += (parseFloat(Utility.removeCommas(item[i].cost) * 1))
    }
    this.setState({
      invoice_weight_total: Utility.weightFormat(invoice_weight_total),
      invoice_product_total: Utility.numberFormat(invoice_product_total),
      invoice_itme_total: Utility.numberFormat(invoice_itme_total),
      // invoice_profit_total: Utility.priceFormat(invoice_profit_total),
      // invoice_fee_total: Utility.priceFormat(invoice_fee_total),
      invoice_cost_total: Utility.priceFormat(invoice_cost_total)
    });
    return item;
  }

  resetFormProduct() {
    this.setState({
      product_id: '',
      product_name: '',
      product_weight: '',
      product_weight_total: '',
      product_weight_real: '',
      product_amount: '',
      action_title: 'เพิ่มสินค้า',
      action: 'add',
      msg_error: {},
      old_fee: '',
    });
  }


  onOpenModal() {
    if (this.props.modal_action == "edit") {
      this.loadInvoiceItem();
    } else {
      this.setState({ button_save_disabled: false })
    }
  }

  onClosenModal() {
    this.setState({
      number: '',
      branch_id: '',
      vendor: '',
      status_stock: 'N',
      ref_number: '',
      invoice_disabled: false,
      invoice_item: [],
      button_update_stoick_disabled: true,
      button_save_title: 'สร้าง',
      button_save_disabled: true,
      invoice_add_product_disabled: true,
      product_id: '',
      product_name: '',
      product_weight: '',
      product_weight_total: '',
      product_weight_real: '',
      product_amount: '',
      button_update_stoick_loading: false,
      categories: [],
    });
  }

  onStatusModal() {
    if (this.modal_status != this.props.open) {
      if (this.props.open)
        this.onOpenModal()
      else
        this.onClosenModal()
    }
    this.modal_status = this.props.open;
  }

  onDeleteProduct(e, d) {
    let data = this.state.invoice_item[d.positon];
    if (data.status_stock == 'N') {
      if (!window.confirm('ยืนยันลบ'))
        return
      const url = Settings.baseUrl + '/invoice_split_hook_item/' + data.id + '/';
      Utility.delete(url, (status, data) => {

        this.setState({ button_product_loading: false })
        this.resetFormProduct();
        this.loadInvoiceItem();
      });
    } else
      alert('ไม่สามารถลบรายการนี้ได้');
  }

  submitUpdateStock() {
    const { auth } = this.props
    if (!(auth.role == 'A' || auth.role == 'S')) {
      alert('คุณไม่มีสิทธิ์อัพเดทสต๊อกได้')
      return
    }
    if (this.state.invoice_description.length > 255) {
      let msg_error = {}
      msg_error.description = ["*ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร"]
      this.setState({ msg_error: msg_error })
      return
    }
    this.setState({ button_update_stoick_loading: true })
    const url = Settings.baseUrl + '/invoice_split_hook/' + this.props.invoice_detail.id + '/stock/';
    Utility.get(url, (status, data) => {
      if (data.status) {
        this
          .props
          .onAddInvoice();
        this.loadInvoiceItem();
        alert('อนุมัติและอัพเดทสต๊อกสำเร็จ')
      } else {
        alert(data.error)
      }

      this.setState({
        button_update_stoick_loading: false
      })
    });
  }


  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)
    this.props.onLoadProduct()
    var categories = Utility.getFetch(Settings.baseUrl + '/categories/?is_enabled=1');
    Promise.all([categories]).then((values) => {
      this.categories = values[0]
      let categories = []
      for (let i in this.categories) {
        categories.push({
          key: this.categories[i].id,
          value: this.categories[i].id,
          text: this.categories[i].name
        })
      }
      this.setState({
        categories: categories,
      })
    });
    this.setState({ loader_active: true, btn_stock: true });
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_w')
    this.setState({ table_width: elHeight.clientWidth });
  };

  async handleSubmitItem() {

    const { items, invoice } = this.props.invoice_item_modal
    const { products, auth } = this.props

    if (items.length == 0) {
      alert('กรุณาลงรายการสินค้า')
      return
    }
    if (!(auth.role == 'A' || auth.role == 'S' || auth.role == 'M')) {
      alert('คุณไม่มีสิทธิ์บันทึกรายการ')
      return
    }
    var is_error = false
    items.map((item, i) => {
      this.props.onInputError(i, 'product_error', '')
      this.props.onInputError(i, 'amount_error', '')
      this.props.onInputError(i, 'weight_real_error', '')
      this.props.onInputError(i, 'cost_error', '')
      this.props.onInputError(i, 'fee_error', '')


      if (item.product == '') {
        is_error = true
        this.props.onInputError(i, 'product_error', 'กรุณาเลือกสินค้า')

      } else {
        if (item.amount == '') {
          is_error = true
          this.props.onInputError(i, 'amount_error', 'กรุณาระบุจำนวน')
        }
        if (item.amount > 2147483647) {
          is_error = true
          this.props.onInputError(i, 'amount_error', 'ไม่สามรถกรอกค่ามากกว่า 2,147,483,647 ได้')
        }
        if (item.weight_real == '') {
          is_error = true
          this.props.onInputError(i, 'weight_real_error', 'กรุณาระบุน้ำหนักชั่ง')
        }
        if (item.cost == '') {
          is_error = true
          this.props.onInputError(i, 'cost_error', 'กรุณาระบุต้นทุน')
        }
        if (item.fee == '') {
          is_error = true
          this.props.onInputError(i, 'fee_error', 'กรุณาระบุค่าแรง')
        }
        if (item.weight_real.toString().split('.')[0].length > 20) {
          is_error = true
          this.props.onInputError(i, 'weight_real_error', 'ไม่สามารถกรอกตัวเลขมากกว่า 20 หลักได้')
        }
        if (item.cost.toString().split('.')[0].length > 20) {
          is_error = true
          this.props.onInputError(i, 'cost_error', 'ไม่สามารถกรอกตัวเลขมากกว่า 20 หลักได้')
        }
        if (item.fee.toString().split('.')[0].length > 20) {
          is_error = true
          this.props.onInputError(i, 'fee_error', 'ไม่สามารถกรอกตัวเลขมากกว่า 20 หลักได้')
        }
      }
    })

    if (is_error) return
    let list = []
    for (let i in items) {
      const o = list.find(k => k.product == items[i].product && k.cost == items[i].cost && k.fee == items[i].fee)
      if (o === undefined) {
        list.push(items[i])
      } else {
        const index = list.indexOf(o)
        list[index].amount = parseInt(list[index].amount) + parseInt(items[i].amount)
        list[index].weight_real = parseFloat(list[index].weight_real) + parseFloat(items[i].weight_real)
      }
    }
    await list.map(async (item, i) => {

      const url = Settings.baseUrl + `/invoice_split_hook_item/`

      const prod = Utility.getObject(products.items, item.product);
      const formData = {
        weight: (parseFloat(prod.category.weight)).toFixed(3),
        weight_total: (parseFloat(prod.weight) * parseFloat(item.amount)).toFixed(3),
        weight_real: parseFloat(item.weight_real).toFixed(3),
        amount: item.amount,
        cost: parseFloat(item.cost).toFixed(2),
        fee: parseFloat(item.fee).toFixed(2),
        invoice: this.props.invoice_detail.id,
        product: item.product
      }
      await Utility.postAsync(url, formData)
      this.loadInvoiceItem(true)
    })

    this.props.onBillItemModalClose()

  }


  render() {
    this.onStatusModal()
    let branch_option = Utility.getOptions(this.props.branches)
    let items = this.state.invoice_item;
    const { is_open } = this.props.invoice_item_modal
    const { auth } = this.props
    return (
      <div>
        <Modal size="large" open={true} /*dimmer='blurring'*/>
          <Button
            id='btnCloseModalImEx'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={
              this.props.onClose
            } />
          <Modal.Header>
            <Header as='h4'>
              <Header.Content>
                {this.state.invoice_title}
                {this.state.number && <span>[บิลเลขที่ {this.state.number}]</span>}
                <Header.Subheader>
                  <span><Icon
                    id='iconimport'
                    name={this.state.status_stock == 'N' || this.state.status_stock == 'D' ? 'warning circle' : 'check circle'}
                    color={this.state.status_stock == 'N' || this.state.status_stock == 'D' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Modal.Header>
          <Modal.Content>
            {this.state.import_branch_open ? (
              <ImportInvoiceItem
                onClose={() => {
                  this.setState({
                    import_branch_open: false,
                  });
                }}
                id={this.props.invoice_detail.id}
                onUpdate={(data) => {
                  this.setState({
                    import_branch_open: false,
                  });
                  this.loadInvoiceItem()
                }}
              />
            ) : (
                ""
              )}
            {is_open && <BillItemModal handleSubmit={this.handleSubmitItem.bind(this)} />}
            <div >
              <div className='relative'>
                {this.state.loader && <Dimmer active={this.state.loader} inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>}
                <div >
                  <Grid >
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <Form size='small'>
                          <Form.Group>
                            <Form.Field width={16} error={this.state.msg_error.branch != null}>
                              <label>*สาขา
                              <MsgInput text={this.state.msg_error.branch} /></label>
                              <Dropdown
                                id='branchimport'
                                placeholder='สาขา'
                                width={16}
                                className={this.state.branch_id_error
                                  ? 'error'
                                  : ''}
                                search
                                selection
                                options={branch_option}
                                value={this.state.branch_id}
                                onChange={(e, data) => {
                                  this.setState({ branch_id: data.value });
                                }}
                                disabled={this.state.invoice_disabled} />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group>
                            <Form.Field width={16}>
                              <label>วันที่</label>
                              <DatePicker
                                id='date'
                                readOnly
                                dateFormat="DD/MM/YYYY"
                                selected={this.state.invoice_date}
                                onChange={(date) => {
                                  if (date === null || date === '') {
                                    this.setState({ invoice_date: moment() });
                                  } else {
                                    this.setState({ invoice_date: date });
                                  }
                                }}
                                disabled={this.state.invoice_disabled} />
                            </Form.Field>
                          </Form.Group>

                          <Form.Group inline>
                            <Form.Field >

                              <Checkbox
                                id='clearbillimport'
                                checked={this.state.status_bill == 'Y'}
                                onChange={(e, v) => {
                                  this.setState({
                                    status_bill: v.checked
                                      ? 'Y'
                                      : 'N'
                                  });
                                }} />
                              <label>เคลียร์บิล</label>
                            </Form.Field>
                          </Form.Group>

                          <Form.Group >
                            <Form.Field width={16} error={this.state.msg_error.description != null}>
                              <label>หมายเหตุ
                              <MsgInput text={this.state.msg_error.description} /></label>
                              <TextArea
                                placeholder='หมายเหตุ'
                                width={16}
                                value={this.state.invoice_description}
                                onChange={(e, v) => {
                                  this.setState({ invoice_description: v.value })
                                }} />
                            </Form.Field>
                          </Form.Group>

                        </Form>

                      </Grid.Column>
                      <Grid.Column width={12}>

                        <Form size='small'>
                          <Form.Group>
                            <Form.Field width={8}>
                              <Header floated='left' as='h3'>รายการสินค้า</Header>
                            </Form.Field>
                            <Form.Field width={5}>
                              <label>QR Code</label>
                              {this.props.modal_action === 'edit' && this.state.status_stock === 'N' ?
                                <Input
                                  className='text-right'
                                  placeholder='Qr code'
                                  autoFocus={1}
                                  // this.weightRealGInput.focus()
                                  // disabled={this.state.invoice_add_product_disabled}
                                  ref={(c) => {
                                    this.qrcodee = c
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                      this.submitQRcode()
                                    }
                                  }}
                                  onChange={(e) => this.setState({ code: e.target.value })}
                                  value={this.state.code}
                                /> :
                                <Input
                                  placeholder='Qr code'
                                  className='text-right'
                                  disabled={1}

                                />
                              }
                            </Form.Field>
                            <Form.Field width={3}>
                              <label><br /></label>
                              <Button
                                primary
                                size='small'
                                icon='add'
                                fluid
                                disabled={this.props.modal_action !== 'edit' || this.state.status_stock !== 'N'}
                                onClick={(e) => {
                                  this.setState({
                                    modal_add_new_item: true,
                                    weight_new_hook: '',
                                    old_cost: '',
                                    category: '',
                                    msg_error: {},
                                    average_cost: '',
                                  })
                                }}
                                type='button'
                                content='เพิ่มตะขอ' />
                            </Form.Field>
                          </Form.Group>
                        </Form>
                        <div id='table_w'>
                          <Table
                            rowsCount={items.length}
                            rowHeight={35}
                            headerHeight={30}
                            width={this.state.table_width}
                            height={350}>
                            <Column
                              width={50}
                              header={< Cell > </Cell>}
                              cell={< OptionItemsCell edit={false} onClickMenu={
                                (e, data) => {
                                  this.onDeleteProduct(e, data)
                                }
                              } />} />

                            <Column
                              header={< Cell > สถานะปรับปรุงสต็อก </Cell>}
                              cell={< ItemsCell position='top left' width={120} data={
                                items
                              }
                                field="status_stock_title" />}
                              width={150} />
                            <Column
                              header={< Cell > กลุ่มสินค้า </Cell>}
                              cell={< ItemsCell position='top left' width={120} data={
                                items
                              }
                                field="category_title" />}
                              width={120} />
                            <Column
                              header={< Cell > ชื่อสินค้า </Cell>}
                              cell={< ItemsCell position='top left' width={200} data={
                                items
                              }
                                field="product_name" />}
                              width={200} />
                            <Column
                              header={< Cell className='text-right' > น้ำหนัก </Cell >}
                              cell={< ItemsCell position='top right' width={120} data={
                                items
                              }
                                field="weight" textAlign='text-right' />}
                              width={120} />
                            <Column
                              header={< Cell className='text-right' > ต้นทุนชิ้นละ </Cell>}
                              cell={< ItemsCell position='top right' width={120} data={items} field="cost" textAlign='text-right' />}
                              width={120} />
                            <Column
                              header={< Cell className='text-right' > Qr code อ้างอิง </Cell>}
                              cell={< ItemsCell position='top right' width={200} data={
                                items
                              }
                                field="ref_qrcode" textAlign='text-right' />}
                              width={200} />
                          </Table>
                        </div>
                        <Form className='fluid' size='small'>
                          <Form.Group >
                            <Form.Input
                              id='invoiceitem'
                              label='จำนวนรายการ'
                              placeholder=''
                              className='text-right'
                              width={4}
                              value={this.state.invoice_itme_total}
                              readOnly />
                            {/* <Form.Input
                            id='invoiceprofit'
                            label='ค่าแรงขายปลีกรวม'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_profit_total}
                            readOnly/> */}
                            <Form.Input
                              id='weighttotal'
                              label='น้ำหนักรวม'
                              placeholder=''
                              className='text-right'
                              width={4}
                              value={this.state.invoice_weight_total}
                              readOnly />
                            {/* <Form.Input
                            label='ต้นทุนค่าแรง'
                            placeholder=''
                            className='text-right'
                            width={4}
                            value={this.state.invoice_fee_total}
                            readOnly /> */}
                            <Form.Input
                              id='producttotal'
                              label='จำนวนรวม'
                              placeholder=''
                              className='text-right'
                              width={4}
                              value={this.state.invoice_product_total}
                              readOnly />

                          </Form.Group>

                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                <br />
                <br />

              </div>

              {this.state.open_print
                ? <BillPrintPreview
                  invoice_detail={this.props.invoice_detail}
                  items={items}
                  vendors={this.props.vendors}
                  product_total={this.state.invoice_product_total}
                  weight_total={this.state.invoice_weight_total}
                  fee_total={this.state.invoice_fee_total}
                  cost_total={this.state.invoice_cost_total}
                  branch_name={this.state.branch_name}
                  status_stock={this.state.status_stock}
                  onClose={() => {
                    this.setState({ open_print: false })
                  }} />
                : ''}
              {this.state.open_print_qr
                ? <BillQrcodeModal
                  invoice_detail={this.props.invoice_detail}
                  items={items}
                  vendors={this.props.vendors}
                  product_total={this.state.invoice_product_total}
                  weight_total={this.state.invoice_weight_total}
                  fee_total={this.state.invoice_fee_total}
                  cost_total={this.state.invoice_cost_total}
                  branch_name={this.state.branch_name}
                  bill_id={this.props.invoice_detail.id}
                  onClose={() => {
                    this.setState({ open_print_qr: false })
                  }} />
                : ''}
            </div>

          </Modal.Content>
          <Modal.Actions>
            <Button
              id='qrcode'
              primary
              size='small'
              icon='qrcode'
              disabled={this.state.status_stock == 'N' || this.state.status_stock == 'D'}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ open_print_qr: true })
              }}
              content='พิมพ์ qrcode' />
            <Button
              id='print'
              primary
              size='small'
              icon='print'
              disabled={this.state.button_print_disabled || this.state.status_stock == 'D'}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ open_print: true })
              }}
              content='พิมพ์' />
            <Button
              id='save'
              size='small'
              primary
              icon='save'
              disabled={this.state.status_stock == 'D'}
              labelPosition='left'
              onClick={(e) => {
                e.preventDefault();
                this.submitSaveInvoice();
              }}
              className={this.state.button_save_loading
                ? 'loading'
                : ''}
              content={this.state.button_save_title} />
            <Button
              id='updateimport'
              size='small'
              icon='lightning'
              color='red'
              onClick={(e) => {
                e.preventDefault();
                if (window.confirm('ยืนยันอนุมัติและอัพเดทสต๊อก')) {
                  this.submitSaveInvoice_no_alert();
                  setTimeout(() => {
                    this.submitUpdateStock();
                  }, 400)
                }
              }}
              className={this.state.button_update_stoick_loading
                ? 'loading'
                : ''}
              disabled={this.props.auth.role != 'M' ? this.state.button_update_stoick_disabled : true}
              content='อนุมัติและอัพเดทสต๊อก' />
            <br /><br />
            <small>*การอนุมัติต้องเป็นสิทธิ์ Super Admin และ Admin เท่านั้น</small>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.modal_add_item} size='mini' /*dimmer='blurring'*/  >
          <Button
            id='closeAddstaff'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_add_item: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.name}>
                  <label>ชื่อสินค้า <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id="name" disabled onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.old_qrcode}>
                  <label>Qr Code <MsgInput text={this.state.msg_error.old_qrcode} /></label>
                  <Input id="old_qrcode" disabled onChange={(e) => this.setState({ old_qrcode: e.target.value })} value={this.state.old_qrcode} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.weight_hook}>
                  <label>*น้ำหนักชั่งกรัม ตะขอ<MsgInput text={this.state.msg_error.weight_hook} /></label>
                  <Input id="weight_hook"
                    placeholder='น้ำหนักชั่งกรัม ตะขอ'
                    onBlur={() => {
                      setTimeout(() => {
                        if (this.state.focus_card) {
                          this.setState({
                            focus_card: false,
                          });
                        }
                      }, 0);
                    }}
                    onFocus={(e) => {
                      e.target.select()
                      if (!this.state.focus_card) {
                        this.setState({
                          focus_card: true,
                        });
                      }
                    }}
                    onChange={(e) => {
                      e.target.value = e.target.value.toString().replace(/,/g, '')

                      if (isNaN(Number(e.target.value))) {
                      } else {
                        let weight_g = e.target.value
                        if (weight_g.split('.')[0].length > 9) {
                          return;
                        }

                        if (weight_g.split('.').length != 1)
                          if (weight_g.split('.')[1].length > 3) {
                            return;
                          }
                        if (parseFloat(weight_g) > parseFloat(this.state.total_weight)) {
                          let msg_error = {}
                          msg_error['weight_hook'] = 'ไม่สามารถกรอกน้ำหนักตะขอ เกิน ' + this.state.total_weight
                          this.setState({ msg_error: msg_error })
                          return;
                        }

                        this.setState({ weight_hook: weight_g, old_fee: parseFloat(weight_g * this.state.multiplier).toFixed(2), msg_error: {} })
                      }
                    }
                    }
                    value={
                      // this.state.weight_hook
                      this.state.weight_hook == '' || this.state.weight_hook == undefined ? '' :
                        !this.state.focus_card ?
                          parseFloat(this.state.weight_hook.toString()).toFixed(3).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : this.state.weight_hook
                      // :parseFloat(this.state.weight_hook.toString().split('.')[0]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'.'+this.state.weight_hook.split('.')[1]
                    }
                    maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.old_fee}>
                  <label>ต้นทุน<MsgInput text={this.state.msg_error.old_fee} /></label>
                  <Input id="old_fee" disabled onChange={(e) => this.setState({ old_fee: e.target.value })} value={Utility.priceFormat(this.state.old_fee)} maxLength={255} />
                </Form.Field>
              </Form>
              {this.state.product_modal_open && <ProductFromModal
                onClose={() => {
                  this.setState({ product_modal_open: false })
                }}
                action='add'
                onSave={async (data) => {

                  await this.props.onLoadProduct()

                  if (data.is_enabled)
                    this.props.addItem({
                      product: data.id,
                      product_error: '',
                      amount: '',
                      amount_error: '',
                      weight: data.weight,
                      weight_total: '',
                      weight_real: '',
                      weight_real_error: '',
                      cost: '',
                      cost_error: '',
                      fee: '',
                      fee_error: '',
                    })

                  this.setState({ product_modal_open: false })
                }} />}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id="cancelAddstaff"
              size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_add_item: false }); }}>ยกเลิก</Button>
            <Button
              id='submitAddstaff'
              size='small' primary
              onClick={(e) => {
                e.preventDefault()

                this.handleSubmitAddItem(this.state.old_product, this.state.weight_hook)
              }}
              className={this.state.button_loading ? 'loading' : ''}>บันทึก</Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.modal_add_new_item} size='mini' /*dimmer='blurring'*/  >
          <Button
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_add_new_item: false })} />
          <Modal.Header>ลงรายการตะขอ</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.category}>
                  <label>*กลุ่มสินค้า <MsgInput text={this.state.msg_error.category} /></label>
                  <Dropdown
                    placeholder='กลุ่มสินค้า'
                    search selection
                    name='category'
                    value={this.state.category}
                    options={this.state.categories}
                    onChange={(e, data) => {
                      var total = Utility.getFetch(Settings.baseUrl + '/oldproduct/cost/?branch=' + this.props.auth.branch.id + '&category=' + data.value);
                      Promise.all([total]).then((values) => {
                        this.setState({
                          category: data.value,
                          average_cost: values[0].cost,
                          weight_new_hook: '',
                          old_cost: '',
                        })
                      })
                    }}
                  >
                  </Dropdown>
                </Form.Field>
                <Form.Field error={this.state.msg_error.weight_new_hook}>
                  <label>*น้ำหนักชั่งกรัม ตะขอ <MsgInput text={this.state.msg_error.weight_new_hook} /></label>
                  <Input id="weight_new_hook"
                    className='text-right'
                    placeholder='น้ำหนักชั่งกรัม ตะขอ'
                    disabled={this.state.average_cost === ''}
                    onBlur={() => {
                      setTimeout(() => {
                        if (this.state.focus_card) {
                          this.setState({
                            focus_card: false,
                          });
                        }
                      }, 0);
                    }}
                    onFocus={(e) => {
                      e.target.select()
                      if (!this.state.focus_card) {
                        this.setState({
                          focus_card: true,
                        });
                      }
                    }}
                    onChange={(e) => {
                      e.target.value = e.target.value.toString().replace(/,/g, '')
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        let weight_g = e.target.value
                        if (weight_g.split('.')[0].length > 9) {
                          return;
                        }

                        if (weight_g.split('.').length != 1)
                          if (weight_g.split('.')[1].length > 3) {
                            return;
                          }
                        this.setState({
                          weight_new_hook: weight_g,
                          old_cost: parseFloat(weight_g * Number(this.state.average_cost)).toFixed(2),
                        })
                      }
                    }}
                    value={
                      this.state.weight_new_hook == '' || this.state.weight_new_hook == undefined ? '' :
                        !this.state.focus_card ?
                          parseFloat(this.state.weight_new_hook.toString()).toFixed(3).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                          : this.state.weight_new_hook
                    }
                    maxLength={255}
                  />
                </Form.Field>
                <Form.Field error={this.state.msg_error.old_cost}>
                  <label>*ต้นทุน {this.state.average_cost !== '' && <span style={{ color: 'red' }}>( เฉลี่ยต่อกรัม {Utility.priceFormat(this.state.average_cost )})</span>} <MsgInput text={this.state.msg_error.old_cost} /></label>
                  <Input
                    placeholder='ต้นทุนเฉลี่ย'
                    className='text-right'
                    disabled={this.state.average_cost === ''}
                    onBlur={() => {
                      setTimeout(() => {
                        if (this.state.focus_cost) {
                          this.setState({
                            focus_cost: false,
                          });
                        }
                      }, 0);
                    }}
                    onFocus={(e) => {
                      e.target.select()
                      if (!this.state.focus_cost) {
                        this.setState({
                          focus_cost: true,
                        });
                      }
                    }}
                    onChange={(e) => {
                      e.target.value = e.target.value.toString().replace(/,/g, '')
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        let cost = e.target.value
                        if (cost.split('.')[0].length > 9) {
                          return;
                        }

                        if (cost.split('.').length != 1)
                          if (cost.split('.')[1].length > 2) {
                            return;
                          }
                        this.setState({ old_cost: e.target.value })
                      }
                    }}
                    value={
                      this.state.old_cost == '' || this.state.old_cost == undefined ? '' :
                        !this.state.focus_cost ?
                          Utility.priceFormat(this.state.old_cost)
                          : this.state.old_cost
                    }
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              size='small'
              onClick={(e) => {
                e.preventDefault();
                this.setState({ modal_add_new_item: false });
              }}
            >ยกเลิก</Button>
            <Button
              size='small' primary
              disabled={this.state.average_cost == ''}
              onClick={(e) => {
                e.preventDefault()
                this.handleSubmitAddHook()
              }}
              className={this.state.button_loading ? 'loading' : ''}>บันทึก</Button>
          </Modal.Actions>
        </Modal>
      </div>

    )
  }

}


const mapStateToProps = state => {
  return ({
    auth: state.auth,
    products: state.products,
    branches: state.branches,
    invoice_item_modal: state.invoice_item_modal,
    products: state.products,
    items_option: state.products.items_option,
  })
}
const mapDispatchToProps = dispatch => {
  return {
    onBillItemModalOpen: (invoice) => {
      dispatch(invoice_item_open(invoice))
    },
    onInputError: (index, key, value) => {
      dispatch(invoice_item_set_value(index, key, value))
    },
    onBillItemModalClose: () => {
      dispatch(invoice_item_close())
    },
    addItem: (item = null) => {
      dispatch(invoice_item_add(item));
    },
    onDelete: (index) => {
      dispatch(invoice_item_remove(index))
    },
    onLoadProduct: async () => {
      await loadProduct(dispatch)
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferStock)
