/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Segment,Statistic,Dimmer,Loader,Header
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';


class Gole extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        var ledger = Utility.getFetch(Settings.baseUrl + '/customers/?is_enabled=1');
        Promise.all([ledger]).then((values) => {         
            this.setState({
                amount: values[0].count,
                is_ready: true
            })
        })

    }

    render() {
        const {is_ready} = this.state
        return (
            <div> 
                <Segment color='blue'>
                                        
                    <Header as='h2'>จำนวนลูกค้า</Header>
                    <right>
                        <Statistic size='tiny'>
                            <Statistic.Value >{Utility.numberFormat(this.state.amount)}</Statistic.Value>
                        </Statistic>
                    </right>
                    <Dimmer active={!this.state.is_ready} inverted>
                        <Loader inverted>Loading..</Loader>
                    </Dimmer> 
                </Segment>
            </div>
        );
    }
}

export default Gole;
