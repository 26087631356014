/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import MsgInput from '../Error/MsgInput'
import ImportStaff from '../Import/ImportStaff'
import {
  Form, Input, Header, Dropdown, Button, Icon, Modal, Confirm, Dimmer, Loader, TextArea, Menu,
  Popup, Pagination,
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { connect } from 'react-redux'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class OptionItemsCell extends Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, v) {

  }

  render() {
    const { rowIndex } = this.props;
    return (
      <Cell><center>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'edit', positon: rowIndex }) }}><Icon name='edit' /></a>
        <a onClick={(e) => { this.props.onClickMenu(e, { action: 'delete', positon: rowIndex }) }} ><Icon name='window close outline' /></a>
      </center></Cell>
    );
  }
}

class ItemsCell extends Component {
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={
              <div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir == SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props}
        className={this.props.className ? this.props.className : ''}>
        <a onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir == SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.field,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class Staffs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      username: '',
      password: '',
      first_name: '',
      modal_open: false,
      search: { branch: 0 },
      is_enabled: 1,
      msg_error: {},
      activeItem: 'แสดง',
      colSortDirs: {
        'code': 'ASC',
      },
      totalPages: 0,
      activePage: 1,
      count: 0,
    }
    this.columnKey = 'code'
    this.sortDi = 'ASC'
    this.onSortChange = this.onSortChange.bind(this)
    this.submitData = this.submitData.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.handlerSubmitSearch = this.handlerSubmitSearch.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.clearFormSearch = this.clearFormSearch.bind(this);
  }
  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.handlerSubmitSearch()

    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({
      loader_active: true,
    });
    let status = 1
    if (this.state.activeItem === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    let search = Utility.filterSearch(this.state.search)
    let str = Utility.jsonToQueryString(search);
    Utility.get(Settings.baseUrl + '/staffs/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&page=' + activePage + '&' + str, (s, data) => {
      let results = data.results
      for (let i in results) {
        results[i].branch_name = results[i].branch.name;
      }
      this.setState({
        loader_active: false,
        items: results,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        activePage: activePage,
        count: data.count,
      });
    })
  }

  handleItemClick = (e, { name }) => {
    this.setState({
      loader_active: true,
    });
    let status = 1
    if (name === 'แสดง') {
      status = 1 //แสดง
    } else {
      status = 0 //ซ่อน
    }
    let search = Utility.filterSearch(this.state.search)
    let str = Utility.jsonToQueryString(search);
    Utility.get(Settings.baseUrl + '/staffs/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + status + '&page=1' + '&' + str, (s, data) => {
      let results = data.results
      for (let i in results) {
        results[i].branch_name = results[i].branch.name;
      }
      this.setState({
        loader_active: false,
        items: results,
        count: data.count,
        activePage: 1,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
      });
    })
    this.setState({ activeItem: name })
  }
  componentWillMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    }, 400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  submitData() {
    var formData = {
      phone: this.state.phone,
      branch: this.state.branch,
      user: 0,
      address: this.state.address,
      code: this.state.code,
      name: this.state.name,
      is_enabled: this.state.is_enabled
    };
    const self = this;

    this.setState({ button_loading: 'loading', msg_error: {} })
    if (this.state.modal_action === 'add') {
      const url = Settings.baseUrl + '/staffs/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_loading: '' })
        if (status) {
          if (data.id === null) {
            self.setState({
              button_loading: false
            });
          } else {
            self.setState({
              modal_open: false,
              button_loading: false
            });
            self.componentDidMount();
            self.resetForm();
            alert('บันทึกข้อมูลสำเร็จ!')
          }

        } else { // error
          if (data.length !== 0) {
            if (data.code != null) {
              alert('กรุณาแจ้ง manager /admin / superadmin ให้ตั้งค่าเลขที่เริ่มต้นรหัสพนักงานของสาขา' + this.state.object_branch[this.state.branch].name + 'ให้ถูกต้อง')
            }
            if (data['branch'] == 'Invalid pk "0" - object does not exist.')
              data['branch'] = 'ไม่สามารถเลือก -ทั้งหมด- ได้'
            if (data['branch'] == 'This field may not be null.')
              data['branch'] = 'กรุณาเลือกสาขา'
            this.setState({
              msg_error: data
            })
          }
        }
      });
    } else {
      const url = Settings.baseUrl + '/staffs/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              button_loading: '',
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {

    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }
    search['branch'] = 0
    this.setState({
      phone: '',
      branch: '',
      address: '',
      code: '',
      name: '',
      button_loading: false,
      search: search,
      msg_error: {},
      is_enabled: 1,
    });
    this.componentDidMount()
  }

  clearFormSearch() {

    let search = this.state.search;
    for (let i in search) {
      search[i] = '';
    }
    search['branch'] = 0
    this.setState({
      phone: '',
      branch: '',
      address: '',
      code: '',
      name: '',
      button_loading: false,
      search: search,
      msg_error: {},
      is_enabled: 1,
    });
  }

  componentDidMount() {
    var self = this;

    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/branches/', (status, data) => {
      var branches = data;
      var options_branch = []
      for (var i in branches) {
        options_branch.push({
          key: branches[i].id,
          value: branches[i].id,
          text: branches[i].name,
        });
      }
      self.setState({
        branch_select: options_branch,
      })
    });
    Utility.get(Settings.baseUrl + '/branches/?is_enabled=1', (status, data) => {
      var branches = data;
      var options_branch = [{
        key: 0,
        value: 0,
        text: "- ทั้งหมด -",
      }];
      var object_branch = {};
      for (var i in branches) {
        options_branch.push({
          key: branches[i].id,
          value: branches[i].id,
          text: branches[i].name,
        });
        object_branch[branches[i].id] = branches[i];
      }
      self.setState({
        branch_select: options_branch,
      })
      let status_a = 1
      if (this.state.activeItem != 'แสดง') {
        status_a = 0
      }
      Utility.get(Settings.baseUrl + '/staffs/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&page=1' + '&is_enabled=' + status_a, (status, data) => {
        this.staffs = data.results;
        let results = data.results
        var staffs = [];
        var staffs_code = [{
          key: 0,
          value: 0,
          text: "- ทั้งหมด -",
        }];
        for (let i in results) {
          results[i].branch_name = results[i].branch.name;
          staffs.push({
            key: results[i].id,
            value: results[i].id,
            text: results[i].name,
          });
          staffs_code.push({
            key: results[i].id,
            value: results[i].id,
            text: results[i].code + ' ' + results[i].name,
          });
        }

        self.setState({
          loader_active: false,
          options_branch: options_branch,
          items: results,
          object_branch: object_branch,
          staffs: staffs,
          staffs_code: staffs_code,
          totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
          activePage: 1,
          count: data.count,
          // activeItem: 'แสดง',
        });
      });
    });
  }

  genCode(branch_id) {

    Utility.get(Settings.baseUrl + '/staffs/next_number/?branch=' + branch_id, (s, d, c) => {
      if (c === 200) {
        let number = d.number.toString()
        if (!isNaN(Number(number.substring(number.length - 4, number.length)))) {
          let branch_name = Utility.getObjectByValue(this.state.options_branch, branch_id).text
          alert('กรุณาแจ้ง manager /admin / superadmin ให้ตั้งค่าเลขที่เริ่มต้นรหัสพนักงานของสาขา' + branch_name + 'ให้ถูก')
          let msg_error = this.state.msg_error
          msg_error['code'] = 'รูปแบบรหัสพนักงานไม่ถูกต้อง'
          this.setState({
            code: d.number,
            msg_error: msg_error,
          })
        } else
          this.setState({
            code: d.number
          })
      }
    })

  }

  getBranchName(b_id) {
    var o = this.state.object_branch[b_id];
    return o === null ? 'not branch' : o.name;
  }

  handlerSearch(event, v) {
    if (v.name) {
      let search = this.state.search;
      search[v.name] = v.value;
      if (v.name == 'text') {
        search[v.name] = v.value.trim();
      }
      this.setState({
        search: search
      });
      return;
    } else {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let search = this.state.search;
      search[name] = value;
      this.setState({
        search: search
      });
    }
  }

  handlerSubmitSearch(e, data) {
    let page = data == undefined ? this.state.activePage : 1
    this.setState({
      loader_active: true
    });
    let search = Utility.filterSearch(this.state.search)
    let str = Utility.jsonToQueryString(search);
    let active = this.state.activeItem == 'แสดง' ? 1 : 0
    Utility.get(Settings.baseUrl + '/staffs/?s=file&columnKey=' + this.columnKey + '&sortDi=' + this.sortDi + '&is_enabled=' + active + '&page=' + page + '&' + str, (s, data) => {
      let results = data.results
      for (let i in results) {
        results[i].branch_name = results[i].branch.name;
      }
      this.setState({
        loader_active: false,
        items: results,
        totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
        activePage: page,
        count: data.count,
        // activeItem: 'แสดง',
      });
    })
  }

  handleDelete(e, staff) {

    if (window.confirm('ยืนยันลบพนักงานนี้')) {
      Utility.delete(Settings.baseUrl + "/staffs/" + staff.id + "/", (s, r, code) => {

        if (s) {
          this.componentDidMount();
        } else {
          alert(r.error)
        }
      })

    }

  }

  handleClick(e, d) {
    let row = this.state.items[d.positon];
    if (d.action === 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_action: 'edit',
        modal_title: 'แก้ไข',
        branch: row.branch.id,
        name: row.name,
        code: row.code,
        phone: row.phone,
        address: row.address,
        object_id: row.id,
        object: row,
        object_branch: row.branch,
        is_enabled: row.is_enabled
      });
    } else {
      this.handleDelete(e, row)
      //this.setState({ confirm_open: true, object_id: row.id ,object: row});
    }
  }

  render() {
    const items = this.state.items;
    const { auth } = this.props
    const stateOptions = this.state.options_branch === null ? [] : this.state.options_branch;
    const optionBranch = this.state.branch_select === null ? [] : this.state.branch_select;
    return (
      <div>

        {this.state.import_open ?
          <ImportStaff
            data={this.state.items}
            onClose={() => {
              this.setState({
                import_open: false,
              });
            }}
            onUpdate={() => {
              this.setState({
                import_open: false,
              });
              this.componentDidMount()
            }} /> : ''}
        <Form >
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>พนักงาน</Header>
            </Form.Field>
            <Form.Field width={10}>
              {auth.role == 'A' || auth.role == 'S' ?
                <Button
                  id="importstaff"
                  size='small' content='Import' onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      import_open: true,
                    });
                  }}
                  floated='right'
                  icon='file alternate outline' labelPosition='left' type='button' primary />
                : ''}
              <Button
                id='addstaff'
                size='small' content='เพิ่ม' onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  this.setState({
                    modal_open: true,
                    modal_action: 'add',
                    modal_title: 'สร้างพนักงาน',

                  });
                }}
                floated='right' icon='plus' labelPosition='left' type='button' primary />

            </Form.Field>
          </Form.Group>
        </Form>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='mini' /*dimmer='blurring'*/  >
          <Button
            id='closeAddstaff'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.branch}>
                  <label>*สาขา <MsgInput text={this.state.msg_error.branch} /></label>
                  <Dropdown id="branch" placeholder='สาขา' search selection defaultValue={this.state.branch} value={this.state.branch} options={optionBranch} onChange={(e, data) => {
                    this.setState({ branch: data.value });
                    if (this.state.modal_action === 'add') {
                      this.genCode(data.value)
                    }
                  }} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.code != null}>
                  <label>*รหัสพนักงาน <MsgInput text={this.state.msg_error.code} /></label>
                  <Input
                    id='IDstaff'
                    placeholder='รหัสพนักงาน'
                    readOnly
                    value={this.state.code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                  <i>เลือกสาขาเพื่อสร้างรหัสพนักงาน</i>
                </Form.Field>

                <Form.Field error={this.state.msg_error.name}>
                  <label>*ชื่อพนักงาน <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id="nameStaff" placeholder='ชื่อพนักงาน' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.address}>
                  <label>ที่อยู่ <MsgInput text={this.state.msg_error.address} /></label>
                  <TextArea id="addressStaff" placeholder='ที่อยู่' onChange={(e) => this.setState({ address: e.target.value })} value={this.state.address} maxLength={255} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.phone}>
                  <label>เบอร์โทร <MsgInput text={this.state.msg_error.phone} /></label>
                  <Input id="phoneStaff"
                    placeholder='เบอร์โทร'
                    onChange={(e) => {
                      if (/^[0-9]*$/.test(e.target.value)) {
                        this.setState({
                          phone: e.target.value
                        })
                      }
                    }}
                    type="tel" value={this.state.phone} maxLength={20} />
                </Form.Field>
                <Form.Field error={this.state.msg_error.is_enabled != null}>
                  <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                  <Dropdown placeholder='สถานะ' id="statusStaff" selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id='submitAddstaff'
              size='small' primary onClick={this.submitData}
              className={this.state.button_loading ? 'loading' : ''}>บันทึก</Button>
            <Button
              id="cancelAddstaff"
              size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={5}>
              <label>รหัสพนักงาน,ชื่อพนักงาน</label>
              <Input maxLength={255} id='searchStaff' placeholder='รหัสพนักงาน,ชื่อพนักงาน' value={this.state.search.text} onChange={this.handlerSearch} name='text' />
            </Form.Field>
            <Form.Field width={5}>
              <label>สาขา</label>
              <Dropdown id='searchBranch' placeholder='สาขา' search selection value={this.state.search.branch} options={stateOptions} onChange={this.handlerSearch} name='branch' />
            </Form.Field>

            <Form.Field width={6}>
              <label><br /></label>
              <Button id='btnsearchStaff' onClick={this.handlerSubmitSearch} size='small' type='button'><Icon name='search' /> ค้นหา</Button>
              <Button id='allStaff' onClick={this.resetForm} size='small' type='button' >ทั้งหมด</Button>
              <Button id='clearSearch' onClick={this.clearFormSearch} size='small' type='button' >รีเซ็ต</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Menu size='mini' attached='top' tabular>
          <Menu.Item
            name='แสดง'
            active={this.state.activeItem === 'แสดง'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='ซ่อน'
            active={this.state.activeItem === 'ซ่อน'}
            onClick={this.handleItemClick}
          />
        </Menu>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />

            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='branch__name'
                sortDir={this.state.colSortDirs.branch__name}>
                สาขา
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="branch_name" />
              }
              width={200}
            />

            <Column
              header={<SortHeaderCell
                onSortChange={this.onSortChange}
                field='code'
                sortDir={this.state.colSortDirs.code}>
                รหัสพนักงาน
            </SortHeaderCell>}
              cell={
                <ItemsCell position='top left' width={200} id='table_idstaff' data={items} field="code" />
              }
              width={200}
            />

            <Column
              header={<Cell>ชื่อพนักงาน</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="name" />
              }
              width={200}
            />
            <Column
              header={<Cell>ที่อยู่</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="address" />
              }
              width={200}
            />
            <Column
              header={<Cell>เบอร์โทร</Cell>}
              cell={
                <ItemsCell position='top left' width={200} data={items} field="phone" />
              }
              width={200}
            />
          </Table></div>
        <br />
        <Form size='small'>
          <Form.Group>
            <Form.Input
              id='amounttrans'
              label='จำนวน'
              placeholder=''
              className='text-right'
              width={2}
              value={Utility.numberFormat(this.state.count)} />
            <Form.Field width={14}>
              <br />
              {this.state.totalPages > 1 && <Pagination
                floated='right'
                size='tiny'
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
              />}
            </Form.Field>
          </Form.Group>
        </Form>
        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Confirm
          content='ยืนยันลบรายการ'
          open={this.state.confirm_open}
          onCancel={() => {
            this.setState({ confirm_open: false });
          }}
          onConfirm={() => {

            const url = Settings.baseUrl + '/staffs/' + this.state.object_id + "/";
            var staffs = this.state.object
            staffs.is_enabled = 0
            staffs.branch = staffs.branch.id

            var self = this;
            Utility.put(url, staffs, function (status, data) {
              if (status) {
                self.setState({ confirm_open: false });
                self.componentDidMount();
                self.resetForm();
              }
            });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(mapStateToProps)(Staffs);
