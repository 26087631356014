/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import ProductFromModal from '../../../Files/Product/ProductFromModal'

import {
  Form,
  Button,
  Input,
  Modal,
  Dropdown
} from 'semantic-ui-react';


class BillItemModal extends Component{

    constructor(props){
        super(props)
        this.state = {
            msg_error: {},
            max: 0,
            amount: '',
            weight: '',
            weight_real: '',
            weight_total: '',
        }
        this.state['title'] = 'เพิ่ม'
        if(props.action=='edit'){
          this.state['title'] = 'แก้ไข'
          for(let k in props.data)
            if(k=='product' || k=='invoice')
              this.state[k] = props.data[k].id
            else
            this.state[k] = props.data[k]

        }

        this.handleSelectProduct = this.handleSelectProduct.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


  loadProduct(branch) {
    var products = Utility.getFetch(Settings.baseUrl + '/stock_product/?is_enabled=1&branch='+branch);
    Promise .all([products]) .then((values) => {
        let product_options = []
        this.products = values[0]
        for (let i in values[0]) {
          product_options.push({key: values[0][i].id,
            value: values[0][i].id,
            text: values[0][i].product.code + " " +values[0][i].product.name
          });
        }
        this.setState({product_options: product_options})
      });
  }


  handleSelectProduct(e,v){

    var o = Utility.getObject(this.products,v.value)

    this.setState({
        product: v.value,
        weight: o.product.weight,
        max: o.amount
    })
    this.amountInput.focus()

  }

    componentDidMount(){
      this.loadProduct(this.props.invoice.branch) 
    }

    handleSubmit(e) {
        var o = Utility.getObject(this.products,this.state.product)
        let msg_error = {}
        let formData = {
          invoice: this.props.invoice.id,
          product: o.product.id,
          status_stock: 'N',
          amount: this.state.amount,
          weight: this.state.weight,
          weight_total: this.state.weight_total,
          weight_real: this.state.weight_real
        };
    
        if (this.state.product == null || this.state.product == '') {
          msg_error['product'] = ['กรุณาเลือกสินค้า']
        }
        if (this.state.amount == null || this.state.amount == '') {
          msg_error['amount'] = ['กรุณาระบุจำนวน']
        }
    
        if (Object.keys(msg_error).length > 0) {
          this.setState({msg_error: msg_error})
          return;
        }

        this.setState({modal_product: false})
        this.setState({button_product_loading: true})
          const url = Settings.baseUrl + '/invoice_item/';
          Utility.post(url, formData, (status, data) => {
            this.setState({button_product_loading: false})
            if(status){
              this.props.onSuccess()
              this.props.onClose()
            }else{
              this.setState({
                msg_error: data
              })
            }
          });
    
      }
    render(){

        return(<Modal size='mini' open={true} /*dimmer='blurring'*/
        >
             < Button id='btnClose' circular icon = 'close' basic floated = 'right' name = '' onClick = { this.props.onClose } /> 
          <Modal.Header>
            {this.state.title}
          </Modal.Header>
          <Modal.Content>
            <Form className='attached fluid' size='small'>
              <MsgInput text={this.state.msg_error.non_field_errors}/>
              <Form.Field error={this.state.msg_error.product != null}>
                <label>*รหัสสินค้า
                  <MsgInput text={this.state.msg_error.product}/></label>
                <Dropdown
                  id='dropDownProductID'
                  search
                  selection
                  options={this.state.product_options}
                  placeholder='สินค้า'
                  defaultValue={this.state.product}
                  value={this.state.product}
                  onChange={this.handleSelectProduct}/>
              </Form.Field>
              <Form.Field error={this.state.msg_error.amount != null}>
                <label>*จำนวน(ชิ้น) {this.state.max<=0?<span className='red'>*ไม่คงเหลือ</span> : <span  className='green'> คงเหลือ {Utility.numberFormat(this.state.max)}ชิ้น</span>}
                  <MsgInput  text={this.state.msg_error.amount}/></label>
                <Input
                  id='inputAmount'
                  max={this.state.max}
                  min={0}
                  ref={(c)=>this.amountInput = c}
                  placeholder='จำนวน(ชิ้น)'
                  // type='number'
                  className='text-right'
                  value={this.state.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                  onKeyPress={(e)=> {
                    if(e.key == 'Enter'){
                        this.productWeightInputReal.focus()
                    } 
                }}
                  onChange={(e, data) => {
                    data.value=data.value.toString().replace(/,/g,'')
                    if (isNaN(Number(data.value))) {
                    } else {
                      if (data.value.length === 0) {
                          data.value = 0
                      }
                      let amount = parseInt(data.value)>this.state.max?this.state.max:parseInt(data.value)
                      this.setState({
                        amount: amount,
                        weight_total: (data.value * this.state.weight).toFixed(3),
                        weight_real: (data.value * this.state.weight).toFixed(3)
                      });
                    }
                    
                }}/>
              </Form.Field>
              <Form.Input
                id='inputWeight'
                label='น.น.(กรัม)'
                placeholder=''
                value={this.state.weight.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                className='text-right'/>
              <Form.Input
              id='inputWeightTotal'
                label='น.น.รวม(กรัม)'
                placeholder=''
                value={this.state.weight_total.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                className='text-right'/>
              <Form.Field>
              <label>น.น.ชั่ง(กรัม) <MsgInput text={this.state.msg_error.weight_real}/></label>
              <Input
                id='inpurWeightReal'
                placeholder=''
                onFocus={(e)=>{e.target.select()}}
                value={this.state.weight_real.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                ref={(c)=>this.productWeightInputReal = c}
                className='text-right'
                onKeyPress={(e)=> {
                  if(e.key == 'Enter'){
                      this.handleSubmit(e)
                  } 
              }}
                onChange={(e, data) => {
                  data.value = data.value.toString().replace(/,/g,'')
                  if (isNaN(Number(data.value))) {
                  } else {
                    this.setState({weight_real: data.value});
                  }
              }}/>
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id='btnAddProduct'
              size='small'
              primary
              disabled={this.state.invoice_add_product_disabled}
              className={this.state.button_product_loading}
              onClick={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}>{this.state.title}</Button>
          </Modal.Actions>
        </Modal>)
    }

}

export default BillItemModal