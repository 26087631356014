/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import {
  Pagination, Dimmer, Loader,
  Statistic,
  Form, Button,
  Popup
} from 'semantic-ui-react';

import 'react-dates/lib/css/_datepicker.css';
import Settings from '../../Settings';
import Utility from '../../Utility';
import CustomerPoint from './CustomerPoint'

class ItemsCell extends Component {

  render() {
    const { rowIndex, field, data, ...props } = this.props;

    let v = data[rowIndex][field]
    if (this.props.type == 'number')
      v = Utility.numberFormat(v)
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    return (
      <Cell {...props} >
        <Popup
          position={this.props.position}
          content={v}
          trigger={<div style={ellipsisStyle}>{v}</div>}
        />
      </Cell>
    );
  }
}


class Scoure extends Component {

  constructor(props) {
    super(props);

    this.limit = 20

    this.state = {
      modal_open: false,
      modal_action: '',
      billItems: [],
      items: [],
      activeItem: 'sell',
      score: {}
    }

    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage })
    this.loadScoreItem(activePage)
  }

  componentDidMount() {

    let querytoday = Utility.jsonToQueryString({});

    var score = Utility.getFetch(Settings.baseUrl + '/score/' + this.props.customer_id + "/?" + querytoday);
    Promise.all([score]).then((values) => {
      const score = values[0]
      this.setState({
        table_width: window.innerWidth,
        score: score,
      });
    });

    this.loadScoreItem(1)
  }


  loadScoreItem(activePage) {
    activePage = activePage < 1 ? 1 : activePage
    let url = Settings.baseUrl + '/score_item/' + this.props.customer_id + '/?page=' + activePage + "&page_size=" + this.limit
    this.setState({
      loader_active: true
    });
    Utility.get(url, (status, res, code) => {
      let items = res.results ? this.setFieldValue(res.results) : []
      this.setState({
        loader_active: false,
        items: items,
        totalPages: res.next == null && res.previous == null ? 0 : Math.ceil(res.count / this.limit),
      })
    })
  }

  setFieldValue(items) {

    for (let i in items) {

      items[i].date_time = Utility.formatDate(items[i].created_datetime) + " " + Utility.formatTime(items[i].created_datetime)
      items[i].amount_display = items[i].action == 'DEL' ? items[i].amount * -1 : items[i].amount
      items[i].kind_display = (items[i].action == 'DEL' && items[i].kind != 'OR' && items[i].kind != 'RP' && items[i].kind != 'RB') || (items[i].action == 'ADD' && (items[i].kind == 'RP' || items[i].kind == 'OR' || items[i].kind == 'RB')) ? 'คืนแต้มยกเลิก' + items[i].kind_display : items[i].kind_display

      if (items[i].creator != null) {
        items[i].creator_name = items[i].creator.username
      }

      if (items[i].redeem_by == this.props.customer_code) {
        items[i].redeem_by = this.props.customer_name
      }

      if (items[i].action == 'DEL' && items[i].kind == 'SE')
        items[i].kind_display = 'คืนแต้มยกเลิกรายการซื้อทอง'
    }
    return items;
  }

  componentWillMount() { this.setState({ table_width: this.props.clientWidth ? this.props.clientWidth : window.innerWidth - 70, table_height: window.innerHeight - 250 }); }
  render() {
    let items = this.state.items
    const activeItem = this.state.activeItem
    return (<div>

      <div className='relative' id='table_width'>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Form.Field width={10} height={20}>
          {/* <Button id='btnAddPoint' size='small' content='เพิ่มแต้ม ออมทอง' onClick={(e) => {
            e.preventDefault()
            // this.resetForm()
            this.setState({
              modal_open: true,
              modal_action: 'Addpoint',
              modal_title: 'เพิ่มแต้ม ออมทอง'
            });
          }}
            floated='right' icon='plus' labelPosition='left' type='button' primary /> */}
        </Form.Field>
        <Form.Field width={10} height={20}>
          <Statistic label='คะแนนสะสม' value={Utility.numberFormat(this.state.score.total | 0)} />
        </Form.Field>

        {this.state.modal_open && <CustomerPoint open={true}
          onSave={() => {
            this.setState({ modal_open: false })
            this.loadScoreItem(1)
            this.componentDidMount()
          }}
          onClose={() => {
            this.setState({ modal_open: false })
          }}
          customer_id={this.props.customer_id}
          action={this.state.modal_action} />}

        <Table
          rowsCount={items.length}
          rowHeight={35}
          headerHeight={30}
          width={this.state.table_width}
          height={this.state.table_height}>

          <Column
            header={<Cell>รายการ</Cell>}
            cell={
              <ItemsCell position='top left' width={200} data={items} field="kind_display" />
            }
            width={200}
          />
          <Column
            header={<Cell>วันที่</Cell>}
            cell={
              <ItemsCell position='top left' width={180} data={items} field="date_time" />
            }
            width={180}
          />
          <Column
            header={<Cell className="text-right">จำนวนคะแนน</Cell>}
            cell={
              <ItemsCell position='top right' width={120} id='tableScoreID' className="text-right" data={items} field="amount_display" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell className="text-right">คะแนนคงเหลือ</Cell>}
            cell={
              <ItemsCell position='top right' width={120} className="text-right" data={items} field="balance" type="number" />
            }
            width={120}
          />
          <Column
            header={<Cell>โดย</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="redeem_by" />
            }
            width={120}
          />
          <Column
            header={<Cell>อ้างอิง</Cell>}
            cell={
              <ItemsCell position='top left' width={120} data={items} field="reference" />
            }
            width={120}
          />

        </Table>

        {this.state.totalPages > 0 && <Pagination
          activePage={this.state.activePage}
          onPageChange={this.handlePaginationChange}
          totalPages={this.state.totalPages}
        />}
      </div>
    </div>)
  }
}


export default Scoure;
