/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import {
    Table,
    Column,
    Cell
} from 'fixed-data-table';

import {
    Form,
    Button,
    Modal,
    Loader,
    Dimmer,
    Pagination,
    Icon,
    Checkbox,
    Input,
    Label,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ItemsCell from '../../ItemsCell'
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux'

class SelectOldProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            items: [],
            start_date: moment(),
            end_date: moment(),
            page: 1,
            totalPages: 1,
            category: 0,
            select: [],
            weight_select: 0,
        }
        this.submitSearch = this.submitSearch.bind(this)
        this.handleAddProduct = this.handleAddProduct.bind(this)
    }

    submitSearch(e) {
        this.componentDidMount()
    }

    handleAddProduct(e) {
        this.setState({ load_item: true })
        let arr = []
        this.state.select.forEach(id => { arr.push({ product_old: id }) })
        this.props.addItems(arr)
        this.props.onClose()
    }

    componentDidMount() {
        this.setState({
            loader_active: true,
            select: [],
            weight_select: 0,
        });
        var categories = Utility.getFetch(Settings.baseUrl + '/categories/?is_enabled=1');
        Promise.all([categories]).then((values) => {
            this.categories = values[0]
            let categories = [{
                key: 0,
                value: 0,
                text: '-ทั้งหมด-',
            }]
            for (let i in this.categories) {
                categories.push({
                    key: this.categories[i].id,
                    value: this.categories[i].id,
                    text: this.categories[i].name
                })
            }
            this.setState({
                categories: categories,
                loader_active: false,
            })
        });
        this.searchProduct(1)
    }

    searchProduct(page) {
        this.setState({
            loader_active: true
        });
        let query = {
            start_date: Utility.formatDate2(this.state.start_date),
            end_date: Utility.formatDate2(this.state.end_date),
            branch: this.props.branch,
            category: this.state.category,
        }
        if (this.state.category == 0)
            delete query.category
        let q = Utility.jsonToQueryString(query)
        Utility.get(Settings.baseUrl + '/bag_old/oldproduct/?' + q + '&page=' + page, (status, data) => {
            if (status == true) {
                this.setState({
                    items: this.setFieldValue(data.results),
                    loader_active: false,
                    totalPages: data.next == null && data.previous == null ? 0 : Math.ceil(data.count / 10),
                    page: page,
                });
            }
        });
    }

    setFieldValue(item) {
        for (let i = 0; i < item.length; i++) {
            item[i]['category_title'] = item[i].stock_old_product.category.name
            item[i]['ref_number'] = item[i].object_number
            item[i]['qrcode'] = item[i].qrcode
            item[i]['product_name'] = item[i].name
            item[i]['weight'] = Utility.weightFormat(item[i].weight)
            item[i]['cost'] = Utility.priceFormat(item[i].cost)
        }
        return item;
    }

    componentWillMount() {
        setTimeout(() => {
            window.addEventListener('resize', this.updateDimensions);
            this.updateDimensions()
        }, 200)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        let elHeight = document.getElementById('table_width3')
        this.setState({ table_width: elHeight.clientWidth });
    };

    handlePaginationChange(e, { activePage }) {
        this.setState({ page: activePage, loadder: true })
        this.searchProduct(activePage)
    }

    render() {
        const items = this.state.items
        return (

            <Modal size='large' open={true} /*dimmer='blurring'*/>
                <Button id='btnClose' circular icon='close' title='ปิด' basic floated='right' name='' onClick={(e) => {
                    e.preventDefault();
                    this.props.onClose();
                }} />
                <Modal.Header>เลือกสินค้าทองเก่า</Modal.Header>

                <Modal.Content className=''>
                    <Dimmer active={this.state.loader_active} inverted>
                        <Loader />
                    </Dimmer>
                    <Modal.Description>
                        <Form className='fluid ' size='small' onSubmit={this.submitSearch}>
                            <Form.Group>
                                <Form.Field >
                                    <label>จากวันที่</label>
                                    <DatePicker
                                        id='dateStart'
                                        dateFormat="DD/MM/YYYY"
                                        value={this.state.start_date}
                                        selected={this.state.start_date}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            if (this.state.end_date < date) {
                                                this.setState({ end_date: date });
                                            }
                                            this.setState({ start_date: date });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>ถึงวันที่</label>
                                    <DatePicker
                                        id='dateEnd'
                                        dateFormat="DD/MM/YYYY"
                                        minDate={this.state.start_date}
                                        value={this.state.end_date}
                                        selected={this.state.end_date}
                                        onChange={(date) => {
                                            date = date ? date : moment()
                                            this.setState({ end_date: date });
                                        }}
                                    />
                                </Form.Field>
                                <Form.Dropdown
                                    label='% ทอง'
                                    placeholder='% ทอง'
                                    search selection width={4}
                                    name='category'
                                    value={this.state.category}
                                    options={this.state.categories}
                                    onChange={(e, data) => this.setState({ category: data.value })}
                                />
                                <Form.Field width={7} floated='right'>
                                    <label><br /></label>
                                    <Button id='search' floated='right'
                                        type='button' size='small'
                                        onClick={() => {
                                            this.submitSearch();
                                        }}
                                    ><Icon name='search' /> ค้นหา</Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                        <div id="table_width3">
                            <Table
                                rowsCount={items.length}
                                rowHeight={35}
                                headerHeight={30}
                                width={this.state.table_width}
                                height={390} >
                                <Column
                                    header={< Cell ></Cell>}
                                    cell={props => (
                                        <Cell className='text-center' {...props}>
                                            <Checkbox
                                                checked={this.state.select.includes(items[props.rowIndex].id)}
                                                onChange={(e, v) => {
                                                    let arr = this.state.select
                                                    let weight_select = 0
                                                    if (v.checked) {
                                                        arr.push(items[props.rowIndex].id)
                                                    } else {
                                                        arr = arr.filter(value => value != items[props.rowIndex].id)
                                                    }
                                                    this.state.items.forEach(i => {
                                                        if (arr.includes(i.id)) {
                                                            weight_select += Number(Utility.removeCommas(i.weight))
                                                        }
                                                    })
                                                    this.setState({
                                                        select: arr,
                                                        weight_select: weight_select,
                                                    })
                                                }}
                                            />
                                        </Cell>
                                    )}
                                    width={50} />
                                <Column
                                    header={< Cell > ชื่อสินค้า </Cell>}
                                    cell={< ItemsCell position='top left' width={150} data={
                                        items
                                    }
                                        field="product_name" />}
                                    width={150} />
                                <Column
                                    header={< Cell > กลุ่มสินค้า </Cell>}
                                    cell={< ItemsCell position='top left' width={100} data={
                                        items
                                    }
                                        field="category_title" />}
                                    width={100} />

                                <Column
                                    header={< Cell className='text-right' > น.น. </Cell>}
                                    cell={< ItemsCell position='top right' width={150} data={
                                        items
                                    }
                                        field="weight" textAlign='text-right' />}
                                    width={150} />
                                <Column
                                    header={< Cell className='text-right' > ต้นทุน </Cell>}
                                    cell={< ItemsCell position='top right' width={150} data={
                                        items
                                    }
                                        field="cost" textAlign='text-right' />}
                                    width={150} />
                                <Column
                                    header={< Cell > เลขที่อ้างอิง </Cell>}
                                    cell={< ItemsCell position='top left' width={150} data={
                                        items
                                    }
                                        field="ref_number" />}
                                    width={150} />
                                <Column
                                    header={< Cell > QR Code </Cell>}
                                    cell={< ItemsCell position='top left' width={250} data={
                                        items
                                    }
                                        field="qrcode" />}
                                    width={250} />
                            </Table></div>
                    </Modal.Description>

                </Modal.Content>
                <Modal.Actions>
                    <Form>
                        {this.state.totalPages > 1 &&
                            <Form.Group>
                                <Form.Field width={16}>
                                    <Pagination
                                        floated='right'
                                        size='tiny'
                                        activePage={this.state.page}
                                        onPageChange={this.handlePaginationChange.bind(this)}
                                        totalPages={this.state.totalPages}
                                    />
                                </Form.Field>
                            </Form.Group>
                        }
                        <Form.Group>
                            <Form.Field width={2}>
                                <Checkbox
                                    checked={this.state.select.length == this.state.items.length}
                                    label='ทั้งหมด'
                                    onChange={(e, v) => {
                                        let arr = []
                                        let weight_select = 0
                                        if (v.checked) {
                                            this.state.items.forEach(i => {
                                                arr.push(i.id)
                                                weight_select += Number(Utility.removeCommas(i.weight))
                                            })
                                        }
                                        this.setState({
                                            select: arr,
                                            weight_select: weight_select,
                                        })
                                    }}
                                />
                            </Form.Field>
                            <Form.Field width={3}>
                                <Input
                                    readOnly
                                    size='mini'
                                    className='text-right'
                                    labelPosition='right'
                                >
                                    <Label basic>จำนวน</Label>
                                    <input value={this.state.select.length} />
                                    <Label>รายการ</Label>
                                </Input>
                            </Form.Field>
                            <Form.Field width={4}>
                                <Input
                                    readOnly
                                    size='mini'
                                    className='text-right'
                                    labelPosition='right'
                                >
                                    <Label basic>น้ำหนักรวม</Label>
                                    <input value={Utility.weightFormat(this.state.weight_select)} />
                                    <Label>กรัม</Label>
                                </Input>
                            </Form.Field>
                            <Form.Field width={7}>
                                <Button
                                    size='small'
                                    primary
                                    icon='add'
                                    labelPosition='left'
                                    onClick={(e) => {
                                        this.handleAddProduct();
                                    }}
                                    className={this.state.load_item ? 'loading' : ''}
                                    content='ลงรายการ'
                                    disabled={this.state.load_item}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Actions>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return ({
        auth: state.auth,
    })
}
export default connect(
    mapStateToProps,
)(SelectOldProduct)