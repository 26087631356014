/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';

import {
    Menu,
    Button,
    Modal
} from 'semantic-ui-react';
import ImportSavingAccount from '../../Import/ImportSavingAccount';
import ImportSavingItem from '../../Import/ImportSavingItem';

// import ImportLeseIncreaseDecrease from '../../Import/ImportLeseIncreaseDecrease'

class ImportModal extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount(){

    }

    render(){
        if(this.props.open)
            return (<div>
                {this.state.saving_account_open && <ImportSavingAccount
                onClose={()=>{
                    this.setState({
                        saving_account_open: false,
                    });
                }}
                onUpdate={()=>{
                    this.setState({
                        saving_account_open: false,
                    });
                    this.props.onUpdate()
                }} />}

                {this.state.saving_item_open && <ImportSavingItem
                onClose={()=>{
                    this.setState({
                        saving_item_open: false,
                    });
                }}
                onUpdate={()=>{
                    this.setState({
                        saving_item_open: false,
                    });
                    this.props.onUpdate()
                }} />}
                
            <Modal size='mini' open={this.props.open} >
                <Button id='btnCloseModalImport' circular icon='close'  basic floated='right' name='' onClick={this.props.onClose}/>
                <Modal.Header id='headerModalImport' >Import </Modal.Header>
                <Modal.Content id='contentModalImport' >
                    <Menu vertical fluid id='menuImport'>
                        <Menu.Item onClick={()=>this.setState({saving_account_open: true})}> Import บัญชีออมทอง </Menu.Item>
                        <Menu.Item onClick={()=>this.setState({saving_item_open: true})}>Import รายการออมทอง</Menu.Item>
                        {/* <Menu.Item onClick={()=>this.setState({lease_interest_open: true})}>Import รายการดอกเบี้ยรับ</Menu.Item> */}
                        {/* <Menu.Item onClick={()=>this.setState({lease_inde_open: true})}>Import เพิ่มลดเงินต้น</Menu.Item> */}
                    </Menu>
                </Modal.Content>
            </Modal>
        </div>)
        else return null
    }
}

export default ImportModal