/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import MsgInput from '../../Error/MsgInput'
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import productHelper from '../../../Helper/ProductHelper';
import {
    Form,
    Input,
    Button,
    Modal,
    Dropdown,
    TextArea,
    Loader, Dimmer,
} from 'semantic-ui-react';

class ProductFromModal extends Component {
    constructor(props) {
        super(props);

        this.submitData = this
            .submitData
            .bind(this);
        this.resetForm = this
            .resetForm
            .bind(this);

        if (props.action == 'add') {
            this.state = {
                msg_error: {},
                name: '',
                description: '',
                code: '',
                type_sale: 1,
                type_weight: 1,
                modal_open: false,
                price_tag: 0,
                cost: 0,
                is_enabled: 1,
                score: 0,
                weight_g: '',
                weight: '',
                pattern_id: null
            }
        } else {
            let product = props.product
            this.state = {
                id: product.id,
                msg_error: {},
                type_weight: props.product.type_weight,
                category_id: product.category.id,
                product_types_id: product.kind.id,
                pattern_id: product.pattern.id,
                name: props.product.name,
                weight_g: product.weight,
                weight: (product.weight / product.category.weight).toFixed(3),
                description: props.product.description,
                code: props.product.code,
                type_sale: props.product.type_sale,
                price_tag: props.product.price_tag,
                cost: props.product.cost,
                is_enabled: props.product.is_enabled,
                score: props.product.score
            }
        }
    }

    checkCode(code, cb) {
        let s = 'code=' + code;
        Utility.get(Settings.baseUrl + '/products/?is_enabled=1&' + s, (e, d) => {
            if (d.length > 0) {
                for (let i in d) {
                    if (code == d[i].code) {
                        cb(false)
                        return;
                    }
                }
                cb(true)

            } else {
                cb(true)
            }
        });
    }

    setGram() {
        let categories = this
            .categories
            .filter((o) => {
                return o.id == this.state.category_id
            });
        if (categories.length == 1) {
            let cat = categories[0];
            let g = parseFloat(cat.weight) * parseFloat(this.state.weight.toString().replace(/,/g, ''));
            this.setState({
                weight_g: g.toFixed(3),
                score: parseInt(g)
            });
        }
    }

    getCategoriesItem(id) {
        let categories = this
            .categories
            .filter((o) => {
                return o.id == id
            });
        return categories[0];
    }

    getProductTypeItem(id) {
        let product_types = this
            .product_types
            .filter((o) => {
                return o.id == id
            });
        return product_types[0];
    }

    getTypeSale(key) {
        let type = Settings
            .type_sale
            .filter((o) => {
                return o.value == key
            });
        return type[0];
    }

    genCode() {
        this.setState({ msg_error: {} })
        let categories = this
            .categories
            .filter((o) => {
                return o.id == this.state.category_id
            });
        let product_types = this
            .product_types
            .filter((o) => {
                return o.id == this.state.product_types_id
            });
        let pattern = this
            .pattern
            .filter((o) => {
                return o.id == this.state.pattern_id
            })

        if (categories.length == 1 && product_types.length == 1 && pattern.length == 1) {
            let weight = this.state.weight.toString().replace(/,/g, '') == '' ? 0 : this.state.weight.toString().replace(/,/g, '')
            const p = productHelper.getCode(categories[0], product_types[0], pattern[0], this.state.type_weight, weight, this.state.weight_g, this.state.type_sale, this.state.price_tag.toString().replace(/,/g, ''))

            this.setState({ code: p.code.slice(0, 20), name: p.name.slice(0, 100) });

        } else {
        }
    }

    submitData() {
        var formData = {
            name: this.state.name,
            is_enabled: this.state.is_enabled,
            score: this.state.score,
            code: this.state.code,
            description: this.state.description,
            category: this.state.category_id,
            kind: this.state.product_types_id,
            pattern: this.state.pattern_id,
            type_weight: this.state.type_weight,
            weight: parseFloat(this.state.weight_g == ''
                ? 0
                : this.state.weight_g.toString().replace(/,/g, '')),
            type_sale: this.state.type_sale,
            price_tag: parseFloat(this.state.price_tag == ''
                ? 0
                : this.state.price_tag.toString().replace(/,/g, '')),
            cost: parseFloat(this.state.cost == ''
                ? 0
                : this.state.cost)
        };

        var msg_error = {}

        if (this.state.category_id == '' || this.state.category_id == null) {
            //return false;
            msg_error['category'] = ['*เลือกกลุ่มสินค้า']
        }

        if (this.state.product_types_id == '' || this.state.product_types_id == null) {
            msg_error['kind'] = ['*เลือกประเภทสินค้า']
        }

        if (this.state.product_types_id == '' || this.state.product_types_id == null) {
            msg_error['kind'] = ['*เลือกประเภทสินค้า']
        }

        if (this.state.pattern_id == '' || this.state.pattern_id == null) {
            msg_error['pattern'] = ['*เลือกลายทอง']
        }

        if (this.state.type_sale == 1) {

            if (this.state.type_weight == 1) {
                if (this.state.weight == '' || this.state.weight == null) {
                    msg_error['weight'] = ['*ต้องไม่เป็นค่าว่าง']
                }
            } else {
                if (this.state.weight_g == '' || this.state.weight_g == null) {
                    msg_error['weight_g'] = ['*ต้องไม่เป็นค่าว่าง']
                }
            }
        }

        if (this.state.name == '' || this.state.name == null) {
            msg_error['name'] = ['*ต้องไม่เป็นค่าว่าง']
        }

        if (this.state.code == '' || this.state.code == null) {
            msg_error['code'] = ['*ต้องไม่เป็นค่าว่าง']
        }

        if (this.state.type_sale != 1) {
            // if (this.state.price_tag == '' || this.state.price_tag == null) {
            //     msg_error['price_tag'] = ['*ต้องไม่เป็นค่าว่าง']
            // }

            // if (this.state.cost == '' || this.state.cost == null) {
            //     msg_error['cost'] = ['*ต้องไม่เป็นค่าว่าง']
            // }

            if (formData.cost < 0) {
                msg_error['cost'] = ['*ต้องไม่ติดลบ']
            }
            if (formData.price_tag < 0) {
                msg_error['price_tag'] = ['*ต้องไม่ติดลบ']
            }
        }

        if (Object.keys(msg_error).length > 0) {
            this.setState({ msg_error: msg_error })
            return;
        }

        this.setState({ button_class: true });
        const self = this;
        if (this.props.action == 'add') {
            const url = Settings.baseUrl + '/products/';

            Utility.post(url, formData, (status, data) => {

                this.setState({ button_class: false });
                if (status) {
                    self.setState({ modal_open: false });
                    self.props.onSave(data)
                    alert('บันทึกข้อมูลสำเร็จ!')
                } else { // error
                    if (data.length !== 0)
                        var msg_error = {}
                    if (data.code != undefined) {
                        msg_error['code'] = '*รหัสถูกใช้งานแล้ว'
                    }

                    this.setState({ msg_error: msg_error })
                }
            });
        } else {
            const url = Settings.baseUrl + '/products/' + this.state.id + "/";
            Utility.put(url, formData, (status, data) => {
                this.setState({ button_class: false });
                if (status) {
                    self.props.onSave()
                    alert('บันทึกข้อมูลสำเร็จ!')
                } else { // error
                    if (data.length !== 0)
                        this.setState({ msg_error: data })
                }
            });
        }
    }

    resetForm() {

        let search = this.state.search;
        for (let i in search) {
            search[i] = '';
        }
        this.setState({
            name: '',
            code: '',
            description: '',
            button_class: false,
            type_sale: 1,
            type_weight: 1,
            price_tag: '',
            cost: '',
            weight: '',
            weight_g: '',
            category_id: '',
            product_types_id: '',
            search_id: '',
            search_category_id: '',
            search_product_types_id: '',
            search_weight: '',
            search_weight_g: '',
            search_code: '',
            search_name: '',
            search: search,
            msg_error: {}
        })

    }

    componentDidMount() {
        var self = this;
        this.setState({ loader_active: true });
        var s = '';

        var categories = Utility.getFetch(Settings.baseUrl + '/categories/');
        var product_types = Utility.getFetch(Settings.baseUrl + '/product_types/');
        var pattern = Utility.getFetch(Settings.baseUrl + '/pattern/');
        Promise
            .all([categories, product_types, pattern])
            .then((values) => {
                this.categories = values[0];
                this.product_types = values[1];
                this.pattern = values[2];

                if (s == '') {
                    for (let i in values[3]) {
                        this
                            .product_code_all
                            .push({
                                key: values[3][i].id,
                                value: values[3][i].id,
                                text: values[3][i].code
                            });

                        this
                            .product_name_all
                            .push({
                                key: values[3][i].id,
                                value: values[3][i].id,
                                text: values[3][i].name
                            });
                    }
                }
                let cat = [];
                for (let i in values[0]) {
                    cat.push({
                        key: values[0][i].id,
                        value: values[0][i].id,
                        text: values[0][i].name
                    });
                }
                let type = [];
                for (let i in values[1]) {
                    type.push({
                        key: values[1][i].id,
                        value: values[1][i].id,
                        text: values[1][i].name
                    });
                }

                let pattern = [];
                for (let i in values[2]) {
                    pattern.push({
                        key: values[2][i].id,
                        value: values[2][i].id,
                        text: values[2][i].name + " ("+values[2][i].code+")"
                    });
                }

                this.setState({ categories: cat, product_types: type, pattern: pattern, loader_active: false });
            });

    }

    resetCategory(category_id) {
        let type = [];
        for (let i in this.product_types) {
            if (this.product_types[i].category_id == category_id)
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
        }

        if (type.length > 0)
            this.setState({ product_types: type });
        else {
            for (let i in this.product_types) {
                type.push({ key: this.product_types[i].id, value: this.product_types[i].id, text: this.product_types[i].name });
            }
            this.setState({ product_types: type });
        }
    }
    checkbeforegencode() {
        let msg_error = {}
        if (this.state.category_id == '' || this.state.category_id == null) {
            msg_error['category'] = ['*เลือกกลุ่มสินค้า']
        }
        if (this.state.product_types_id == '' || this.state.product_types_id == null) {
            msg_error['kind'] = ['*เลือกประเภทสินค้า']
        }
        if (this.state.product_types_id == '' || this.state.product_types_id == null) {
            msg_error['kind'] = ['*เลือกประเภทสินค้า']
        }
        if (this.state.pattern_id == '' || this.state.pattern_id == null) {
            msg_error['pattern'] = ['*เลือกลายทอง']
        }
        if (this.state.type_sale == 1) {
            if (this.state.type_weight == 1) {
                if (this.state.weight == '' || this.state.weight == null) {
                    msg_error['weight'] = ['*ต้องไม่เป็นค่าว่าง']
                }
            } else {
                if (this.state.weight_g == '' || this.state.weight_g == null) {
                    msg_error['weight_g'] = ['*ต้องไม่เป็นค่าว่าง']
                }
            }
        }
        return msg_error
    }

    handleAddition = (e, { value }) => {
        var pattern = this.state.pattern
        var formData = {
            name: value,
        };
        const url = Settings.baseUrl + '/add_pattern/';
        Utility.post(url, formData, (status, data)=> {
            if(status){
                this.pattern.push(data)
                pattern.push({
                    key: data.id,
                    value: data.id,
                    text: data.name + " ("+data.code+")"})
                this.setState({pattern,pattern_id:data.id})
                this.props.addPattern(data)
            }
        });
    }

    render() {
        let title = 'สร้างสินค้าใหม่'
        if (this.props.action == 'edit')
            title = 'แก้ไขสินค้า'
        return (<div>
            <Modal open={true} /*dimmer='blurring'*/>
                <Button
                    id='closeaddProduct'
                    circular
                    icon='close'
                    basic
                    floated='right'
                    name=''
                    onClick={this.props.onClose} />
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content >
                    <Modal.Description>
                    {this.props.loading && <Dimmer active={this.props.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <Form size='small'>

                            <Form.Group>
                                <Form.Field width={8} error={this.state.msg_error.category != null}>
                                    <label>*กลุ่มสินค้า
                                                        <MsgInput text={this.state.msg_error.category} /></label>
                                    <Dropdown
                                        id='productgroup'
                                        placeholder='กลุ่มสินค้า'
                                        search
                                        selection
                                        defaultValue={this.state.category_id}
                                        value={this.state.category_id}
                                        options={this.state.categories}
                                        onChange={(e, data) => {
                                            this.setState({ category_id: data.value });
                                            // this.resetCategory(data.value);
                                            this.setGram();
                                        }} />
                                </Form.Field>
                                <Form.Field width={8} error={this.state.msg_error.kind != null}>
                                    <label>*ประเภทสินค้า
                                                        <MsgInput text={this.state.msg_error.kind} /></label>
                                    <Dropdown
                                        id='producttype'
                                        placeholder='ประเภท'
                                        search
                                        selection
                                        defaultValue={this.state.product_types_id}
                                        value={this.state.product_types_id}
                                        options={this.state.product_types}
                                        onChange={(e, data) => {
                                            this.setState({ product_types_id: data.value });
                                        }} />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={8} error={this.state.msg_error.pattern != null}>
                                    <label>*ลายทอง
                                                        <MsgInput text={this.state.msg_error.pattern} /></label>
                                    <Dropdown
                                        id='pattern'
                                        placeholder='ลายทอง'
                                        search
                                        selection
                                        defaultValue={this.state.pattern_id}
                                        value={this.state.pattern_id}
                                        options={this.state.pattern}
                                        onChange={(e, data) => {
                                            this.setState({ pattern_id: data.value });
                                        }}
                                        allowAdditions
                                        onAddItem={this.handleAddition}
                                        />
                                </Form.Field>
                                <Form.Field width={8}>
                                    <label>*ประเภทขาย</label>
                                    <Dropdown
                                        id='typesell'
                                        placeholder=''
                                        search
                                        selection
                                        defaultValue={this.state.type_sale}
                                        options={Settings.type_sale}
                                        onChange={(e, data) => {
                                            if (data.value == 2) {
                                                this.setState({ price_tag: '0' })
                                            } else {
                                                this.setState({ price_tag: '' })
                                            }
                                            this.setState({ type_sale: data.value });
                                        }} />
                                </Form.Field>
                            </Form.Group>
                            {this.state.type_sale == 1 ?
                                <Form.Group>
                                    <Form.Field width={8}>
                                        <label>*ประเภทน.น.</label>
                                        <Dropdown
                                            id='weightproduct'
                                            placeholder='ประเภทน.น.'
                                            search
                                            selection
                                            defaultValue={this.state.type_weight}
                                            value={this.state.type_weight}
                                            options={Settings.type_weight}
                                            onChange={(e, data) => {
                                                this.setState({ type_weight: data.value });
                                            }} />
                                    </Form.Field>
                                    {this.state.type_weight == 1 ?
                                        <Form.Field width={8} error={this.state.msg_error.weight != null}>
                                            <label>*น.น./ชิ้น (บาท)
                                                    <MsgInput text={this.state.msg_error.weight} /></label>
                                            <Input
                                                id='weightbath'
                                                placeholder='น.น./ชิ้น (บาท)'
                                                maxLength={30}
                                                onChange={(e) => {
                                                    if (isNaN(Number(e.target.value))) {
                                                    } else {

                                                        let weight_g = e.target.value
                                                        if (weight_g.split('.')[0].length > 5) {
                                                            return;
                                                        }

                                                        if (weight_g.split('.').length != 1)
                                                            if (weight_g.split('.')[1].length > 2) {
                                                                return;
                                                            }
                                                        this.setState({ weight: e.target.value });
                                                        this.state.weight = e.target.value;
                                                        this.setGram();
                                                    }
                                                }}
                                                // type='number'
                                                className='text-right'

                                                onFocus={() => {
                                                    if (!this.state.focus_b) {
                                                        this.setState({
                                                            focus_b: true,
                                                            // weight : this.state.weight.toString().replace(/,/g,'')
                                                        });
                                                    }
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        if (this.state.focus_b) {
                                                            this.setState({
                                                                focus_b: false,
                                                                // weight : this.state.weight.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                                            });
                                                        }
                                                    }, 0);
                                                }}

                                                value={this.state.focus_b ? this.state.weight.toString().replace(/,/g, '') : this.state.weight.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                action={< Dropdown id='wb' button floating options={
                                                    Settings.weight
                                                }
                                                    onChange={
                                                        (e, data) => {
                                                            this.state.weight = data.value;
                                                            this.setState({ weight: data.value });
                                                            this.setGram();
                                                        }
                                                    }
                                                    defaultValue='1.0' />} />
                                        </Form.Field> :
                                        <Form.Field width={8} error={this.state.msg_error.weight_g != null}>
                                            <label>*น.น./ชิ้น (กรัม)
                                                    <MsgInput text={this.state.msg_error.weight_g} /></label>
                                            <Input
                                                id='weightg'
                                                placeholder='น.น./ชิ้น (กรัม)'
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.toString().replace(/,/g, '')
                                                    if (isNaN(Number(e.target.value))) {
                                                    } else {
                                                        let weight_g = e.target.value
                                                        if (weight_g.split('.')[0].length > 9) {
                                                            return;
                                                        }

                                                        if (weight_g.split('.').length != 1)
                                                            if (weight_g.split('.')[1].length > 3) {
                                                                return;
                                                            }
                                                        this.setState({ weight_g: weight_g })
                                                    }
                                                }

                                                }
                                                onFocus={() => {
                                                    if (!this.state.focus_g) {
                                                        this.setState({
                                                            focus_g: true,
                                                        });
                                                    }
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        if (this.state.focus_g) {
                                                            this.setState({
                                                                focus_g: false,
                                                            });
                                                        }
                                                    }, 0);
                                                }}
                                                // type='number'
                                                min='0'
                                                maxLength={30}
                                                className='text-right'
                                                value={this.state.focus_g ? this.state.weight_g.toString().replace(/,/g, '') : this.state.weight_g.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            />
                                        </Form.Field>
                                    }


                                </Form.Group>
                                :
                                <Form.Group>
                                    <Form.Field width={16} error={this.state.msg_error.price_tag != null}>
                                        <label>*ราคาป้ายชิ้นละ(บาท)
                                                        <MsgInput text={this.state.msg_error.price_tag} /></label>
                                        <Input
                                            id='pricetag'
                                            // type='number'

                                            onFocus={() => {
                                                if (!this.state.focus) {
                                                    this.setState({
                                                        focus: true,
                                                        price_tag: this.state.price_tag.toString().replace(/,/g, '')
                                                    });
                                                }
                                            }}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    if (this.state.focus) {
                                                        this.setState({
                                                            focus: false,
                                                            price_tag: this.state.price_tag.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                                        });
                                                    }
                                                }, 0);
                                            }}


                                            disabled={this.state.type_sale == 1}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.toString().replace(/,/g, '')
                                                if (isNaN(Number(e.target.value))) {
                                                } else {
                                                    let price_tag = e.target.value
                                                    if (price_tag.split('.')[0].length > 12) {
                                                        return;
                                                    }

                                                    if (price_tag.split('.').length != 1)
                                                        if (price_tag.split('.')[1].length > 2) {
                                                            return;
                                                        }
                                                    this.setState({ price_tag: e.target.value });
                                                }
                                            }
                                            }
                                            value={this.state.focus ? this.state.price_tag.toString().replace(/,/g, '') : this.state.price_tag.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        />
                                    </Form.Field>
                                    {/* <Form.Field width={8} error={this.state.msg_error.cost != null}>
                                                    <label>*ต้นทุนชิ้นละ(บาท)
                                                        <MsgInput text={this.state.msg_error.cost}/></label>
                                                    <Input
                                                        id='cost'
                                                        // type='number'
                                                        disabled={this.state.type_sale == 1}
                                                        onChange={(e) => 
                                                            {
                                                                e.target.value = e.target.value.toString().replace(/,/g,'')
                                                                if (isNaN(Number(e.target.value))) {
                                                                } else {
                                                                    this.setState({cost: e.target.value})
                                                                }
                                                            }}
                                                        value={this.state.cost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}/>
                                                </Form.Field> */}
                                </Form.Group>
                            }
                            <Form.Field error={this.state.msg_error.code != null}>
                                <label>*รหัสสินค้า
                                                    <MsgInput text={this.state.msg_error.code} /></label>
                                <Input
                                    id='codeproduct'
                                    placeholder='รหัสสินค้า'
                                    maxLength={20}
                                    onChange={(e) => this.setState({ code: e.target.value })}
                                    value={this.state.code}
                                    action={< Button id='codeid' onClick={
                                        (e) => {
                                            e.preventDefault();
                                            let msg = this.checkbeforegencode()

                                            if (Object.keys(msg).length > 0) {
                                                this.setState({ msg_error: msg })
                                                return;
                                            } else {
                                                this.genCode();
                                            }
                                        }
                                    }
                                        icon='angle double left' />} />
                                <i>{"กดปุ่ม << เพื่อสร้างรหัสสินค้าและชื่อสินค้า"}</i>
                            </Form.Field>
                            <Form.Field error={this.state.msg_error.name != null}>
                                <label>*ชื่อสินค้า
                                                    <MsgInput text={this.state.msg_error.name} /></label>
                                <Input
                                    maxLength={100}
                                    id='productname'
                                    placeholder='ชื่อสินค้า'
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name} />
                            </Form.Field>

                            <Form.Field error={this.state.msg_error.description != null}>
                                <label>หมายเหตุ
                                                    <MsgInput text={this.state.msg_error.description} /></label>
                                <TextArea
                                    maxLength={255}
                                    placeholder='หมายเหตุ'
                                    onChange={(e) => this.setState({ description: e.target.value })}
                                    value={this.state.description} />
                            </Form.Field>
                            <Form.Field error={this.state.msg_error.is_enabled != null}>
                                <label>สถานะ <MsgInput text={this.state.msg_error.is_enabled} /></label>
                                <Dropdown id='productstatus' selection fluid onChange={(e, v) => this.setState({ is_enabled: v.value })} value={this.state.is_enabled} options={Settings.is_enabled_option} defaultValue={1} />

                            </Form.Field>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id='confirmproduct'
                        size='small'
                        primary
                        onClick={(e) => {
                            e.preventDefault();
                            this.submitData()
                        }}
                        className={this.state.button_class
                            ? 'loading'
                            : ''}>บันทึก</Button>
                </Modal.Actions>
            </Modal>
            <Modal size='mini' open={this.state.alert_modal} /*dimmer='blurring'*/>
                <Modal.Content>
                    <p>{this.state.alert_message}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id='confirm'
                        negative
                        content='ตกลง'
                        onClick={() => {
                            this.setState({ alert_modal: false });
                        }} />
                </Modal.Actions>
            </Modal></div>
        );
    }
}

export default ProductFromModal