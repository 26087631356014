/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            },
            items: []
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {

        var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
        var staffs = Utility.getFetch(Settings.baseReportUrl + '/staffs/?is_enabled=1');
        var product_types = Utility.getFetch(Settings.baseReportUrl + '/product_types/?is_enabled=1');
        Promise.all([branches, staffs, product_types]).then((values) => {
            this.branches = values[0];
            let branches = []
            for (let i in this.branches) {
                branches.push({
                    value: this.branches[i].id,
                    key: this.branches[i].id,
                    text: this.branches[i].name
                });
            }

            this.staffs = values[1];
            let staffs = []
            for (let i in this.staffs) {
                staffs.push({
                    value: this.staffs[i].id,
                    key: this.staffs[i].id,
                    text: this.staffs[i].name
                });
            }

            this.product_types = values[2];
            let product_types = []
            for (let i in this.product_types) {
                product_types.push({
                    value: this.product_types[i].id,
                    key: this.product_types[i].id,
                    text: this.product_types[i].name
                });
            }

            this.setState({
                loader_active: false,
                branches: branches,
                staffs: staffs,
                product_types: product_types
            });
        });
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        if (temp_start !== null && temp_end !== null) {
            this.setState({
                search: {
                    start_date: moment(temp_start, 'DD/MM/YYYY'),
                    end_date: moment(temp_end, 'DD/MM/YYYY')
                }
            })
        }
    }

    resetForm(e) {

        this.setState({
            search: {
                branch: this.props.auth.branch.id,
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)

        let q = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/bill_items/?kind=DF&status_stock=Y&" + q
        this.setState({
            open: true,
            loading: true
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: this.setFieldValue(d)
                })
            } else {
                this.setState({
                    loading: false,
                    items: []
                })
            }
        })
        Utility.get(Settings.baseReportUrl + "/remain_bill_items/?kind=DF&" + q, (s, d) => {
            if (s == true) {
                this.setState({
                    amount_take: d.amount,
                    weight_take: d.weight_total,
                    cost_per_piece: d.cost_per_piece,
                    fee_per_piece: d.fee_per_piece,
                    cost_total_per_piece: d.cost_total_per_piece,
                    cost_total: d.cost_total,
                })
            } else {
                this.setState({
                    amount_take: 0,
                    weight_take: 0,
                    cost_per_piece: 0,
                    fee_per_piece: 0,
                    cost_total_per_piece: 0,
                    cost_total: 0,
                })
            }
        })
    }

    setFieldValue(_items) {


        let items = []
        for (let i in _items) {
            let item = _items[i]

            if (this.state.search.branch) {
                if (item.bill.branch.id != this.state.search.branch)
                    continue;
            }


            item.date = Utility.formatDate(item.record_date)
            item.time = Utility.formatTime(item.record_date)
            item.number = item.bill.bill_number
            item.code = item.product.code
            item.kind_display = item.product.kind.name
            item.category_display = item.category.name
            item.amount_in = Utility.numberFormat(item.amount)
            item.weight_g = Utility.weightFormat(item.weight)
            item.weight_total = Utility.weightFormat(parseFloat(item.weight) * parseFloat(item.amount))
            item.cost_per_piece = Utility.priceFormat(item.cost_df)
            item.fee_per_piece = Utility.priceFormat(item.fee_df)
            item.cost_total_per_piece = Utility.priceFormat(parseFloat(item.cost_df) + parseFloat(item.fee_df))
            item.cost_total = Utility.priceFormat((parseFloat(item.cost_df) + parseFloat(item.fee_df)) * parseFloat(item.amount))

            if (item.status_product == 'W' || item.status_product == 'M') {
                item.amount_out = Utility.numberFormat(item.amount)
                item.weight_g_out = Utility.weightFormat(item.weight)
                item.weight_total_out = Utility.weightFormat(parseFloat(item.weight) * parseFloat(item.amount))
                item.cost_per_piece_out = Utility.priceFormat(item.cost_df)
                item.fee_per_piece_out = Utility.priceFormat(item.fee_df)
                item.cost_total_per_piece_out = Utility.priceFormat(parseFloat(item.cost_df) + parseFloat(item.fee_df))
                item.cost_total_out = Utility.priceFormat((parseFloat(item.cost_df) + parseFloat(item.fee_df)) * parseFloat(item.amount))

                item.amount_remain = 0
                item.weight_g_remain = ''
                item.weight_total_remain = ''
                item.cost_per_piece_remain = Utility.priceFormat(item.cost_df)
                item.fee_per_piece_remain = Utility.priceFormat(item.fee_df)
                item.cost_total_per_piece_remain = Utility.priceFormat(parseFloat(item.cost_df) + parseFloat(item.fee_df))
                item.cost_total_remain = '-'
            } else {
                item.amount_out = ''
                item.weight_g_out = ''
                item.weight_total_out = ''
                item.cost_per_piece_out = ''
                item.fee_per_piece_out = ''
                item.cost_total_per_piece_out = ''
                item.cost_total_out = ''

                item.amount_remain = Utility.numberFormat(item.amount)
                item.weight_g_remain = Utility.weightFormat(item.weight)
                item.weight_total_remain = Utility.weightFormat(parseFloat(item.weight) * parseFloat(item.amount))
                item.cost_per_piece_remain = Utility.priceFormat(item.cost_df)
                item.fee_per_piece_remain = Utility.priceFormat(item.fee_df)
                item.cost_total_per_piece_remain = Utility.priceFormat(parseFloat(item.cost_df) + parseFloat(item.fee_df))
                item.cost_total_remain = Utility.priceFormat((parseFloat(item.cost_df) + parseFloat(item.fee_df)) * parseFloat(item.amount))
            }
            items.push(item)
        }

        return items
    }
    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search
        })
    }
    render() {
        let title = 'รายงานทองชำรุด';
        let filename = 'defect-' + Utility.formatDate3(this.state.search.start_date) + '-' + Utility.formatDate3(this.state.search.end_date);

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }
        let amount_in_total = 0
        let weight_in_total = 0
        let cost_per_piece_in_total = 0
        let fee_per_piece_in_total = 0
        let cost_in_total = 0
        let cost_all_in_total = 0

        let amount_out_total = 0
        let weight_out_total = 0
        let cost_per_piece_out_total = 0
        let fee_per_piece_out_total = 0
        let cost_out_total = 0

        let amount_remain_total = this.state.amount_take
        let weight_remain_total = this.state.weight_take
        let fee_per_piece_remain_total = (this.state.fee_per_piece * this.state.amount_take)
        let cost_remain_total = (this.state.cost_per_piece * this.state.amount_take)

        // let category = {}
        let items = []
        for (let i in this.state.items) {
            let item = this.state.items[i]
            amount_in_total += parseFloat(Utility.removeCommas(item.amount_in))
            weight_in_total += parseFloat(Utility.removeCommas(item.weight_total))
            cost_per_piece_in_total += parseFloat(Utility.removeCommas(item.cost_per_piece))
            fee_per_piece_in_total += parseFloat(Utility.removeCommas(item.fee_per_piece))
            cost_in_total += parseFloat(Utility.removeCommas(item.cost_total_per_piece))
            cost_all_in_total += parseFloat(Utility.removeCommas(item.cost_total))

            if (item.amount_out != '')
                amount_out_total += parseFloat(Utility.removeCommas(item.amount_out))
            if (item.weight_total_out != '')
                weight_out_total += parseFloat(Utility.removeCommas(item.weight_total_out))
            if (item.cost_per_piece_out != '')
                cost_per_piece_out_total += parseFloat(Utility.removeCommas(item.cost_per_piece_out))
            if (item.fee_per_piece_out != '')
                fee_per_piece_out_total += parseFloat(Utility.removeCommas(item.fee_per_piece_out))
            if (item.cost_total_out != '')
                cost_out_total += parseFloat(Utility.removeCommas(item.cost_total_out))

            amount_remain_total += parseFloat(Utility.removeCommas(item.amount_remain))
            if (item.weight_total_remain != '')
                weight_remain_total += parseFloat(Utility.removeCommas(item.weight_total_remain))
            fee_per_piece_remain_total += parseFloat(Utility.removeCommas(item.fee_per_piece_remain))
            if (item.cost_total_remain != '-')
                cost_remain_total += parseFloat(Utility.removeCommas(item.cost_total_remain))



            const o = items.find(k => k.date == item.date && k.number === item.number)
            if (o === undefined) {
                items.push(item)
            } else {
                item.date = ''
                item.number = ''
                items.push(item)
            }
        }

        let branch_name = ''
        const { branches } = this.props
        if (this.state.search.branch) {
            let b = Utility.getObject(branches, this.state.search.branch)
            if (b)
                branch_name = b.name
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>รายงานทองชำรุด</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >

                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field>
                                <label>สาขา</label>
                                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
                            </Form.Field>

                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='extra-wide'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='22'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='22'><center id='branch'>สาขา: {branch_name}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='22'><center id='date'>ช่วงเวลา: ตั้งแต่ {Utility.formatDate(this.state.search.start_date)} ถึง {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='11'><center>ทองชำรุดรับเข้า</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='5'><center>ทองชำรุดส่งโอนเข้าสต็อกทองเก่า</center></Table.HeaderCell>
                                            <Table.HeaderCell colSpan='6'><center>คงเหลือ</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>วันที่</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เลขที่บิล</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รหัส</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ประเภท</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รายการ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>%ทองเก่า</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น.ทอง (g)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>รวม น.น. (g)</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textRight}>ต้นทุน/ชิ้น</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>ค่าแรง/ชิ้น</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textRight}>ต้นทุนรวม/ชิ้น</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>ต้นทุนทั้งสิ้น</Table.HeaderCell> */}

                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น. ทอง (g)</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>รวม น.น. (g)</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textRight}>ราคาขายทอง/ชิ้น</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>ค่าแรง/ชิ้น</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textRight}>ต้นทุนโอนออก/ชิ้น</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>ต้นทุนโอนออกทั้งสิ้น</Table.HeaderCell> */}

                                            <Table.HeaderCell style={textRight}>จำนวน</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>น.น. ทอง (g)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>รวม น.น. (g)</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>ราคา/หน่วย</Table.HeaderCell>
                                            {/* <Table.HeaderCell style={textRight}>ค่าแรง/หน่วย</Table.HeaderCell> */}
                                            <Table.HeaderCell style={textRight}>มูลค่าคงเหลือ/ชิ้น</Table.HeaderCell>
                                            <Table.HeaderCell style={textRight}>มูลค่าคงเหลือ</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell style={textLeft}>ยกมา</Table.Cell>
                                            <Table.Cell colSpan='13'></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.numberFormat(this.state.amount_take)}</Table.Cell>
                                            <Table.Cell colSpan='1'></Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.weightFormat(this.state.weight_take)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.cost_per_piece)}</Table.Cell>
                                            {/* <Table.Cell style={textRight}>{Utility.priceFormat(this.state.fee_per_piece)}</Table.Cell> */}
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.cost_total_per_piece)}</Table.Cell>
                                            <Table.Cell style={textRight}>{Utility.priceFormat(this.state.cost_total)}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    {items.map((row, i) => {
                                        return (<Table.Body>
                                            <Table.Row >
                                                <Table.Cell style={textCenter}>{row.date}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.number}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.code}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.kind_display}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.product.name}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.category_display}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.weight_g}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.amount_in}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.weight_g}</Table.Cell> */}
                                                <Table.Cell style={textRight}>{row.cost_per_piece}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.fee_per_piece}</Table.Cell> */}
                                                <Table.Cell style={textRight}>{row.cost_total_per_piece}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.cost_total}</Table.Cell> */}

                                                <Table.Cell style={textRight}>{row.amount_out}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.weight_g_out}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.weight_total_out}</Table.Cell> */}
                                                <Table.Cell style={textRight}>{row.cost_per_piece_out}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.fee_per_piece_out}</Table.Cell> */}
                                                <Table.Cell style={textRight}>{row.cost_per_piece_out}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.cost_total_out}</Table.Cell> */}

                                                <Table.Cell style={textRight}>{row.amount_remain}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.weight_g_remain}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.weight_total_remain}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.cost_per_piece_remain}</Table.Cell>
                                                {/* <Table.Cell style={textRight}>{row.fee_per_piece_remain}</Table.Cell> */}
                                                <Table.Cell style={textRight}>{row.cost_total_per_piece_remain}</Table.Cell>
                                                <Table.Cell style={textRight}>{row.cost_total_remain}</Table.Cell>

                                            </Table.Row>
                                        </Table.Body>)
                                    })}
                                    <Table.Body>
                                        <Table.Row >
                                            <Table.Cell colSpan='5'></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>รวมทั้งหมด</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_in_total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(amount_in_total)}</b></div></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_in_total)}</b></div></Table.Cell> */}
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_per_piece_in_total)}</b></div></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(fee_per_piece_in_total)}</b></div></Table.Cell> */}
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_in_total)}</b></div></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(cost_all_in_total)}</b></div></Table.Cell> */}
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(amount_out_total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_out_total)}</b></div></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_out_total)}</b></div></Table.Cell> */}
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_per_piece_out_total)}</b></div></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(fee_per_piece_out_total)}</b></div></Table.Cell> */}
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_out_total)}</b></div></Table.Cell>
                                            {/* <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_out_total)}</b></div></Table.Cell> */}
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.numberFormat(amount_remain_total)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_remain_total - this.state.weight_take)}</b></div></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.weightFormat(weight_remain_total)}</b></div></Table.Cell>
                                            <Table.Cell colSpan='2'></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_remain_total)}</b></div></Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell colSpan='22'></Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell colSpan='4'></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>ต้นทุนเฉลี่ย ต่อกรัม</b></div></Table.Cell>
                                            <Table.Cell colSpan='3'></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_all_in_total / weight_in_total)}</b></div></Table.Cell>
                                            <Table.Cell colSpan='4'></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_out_total / weight_out_total)}</b></div></Table.Cell>
                                            <Table.Cell colSpan='5'></Table.Cell>
                                            <Table.Cell style={textRight}><div style={textU}><b>{Utility.priceFormat(cost_remain_total / weight_remain_total)}</b></div></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>

                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(()=>{
                                mywindow.close();
                            },2000)
                        }} labelPosition='right' content='Print' />

                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}
export default connect(
    mapStateToProps,
)(PrintPreview)