/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import {Table, Column, Cell} from 'fixed-data-table';
import ItemsCell from './ItemsCell'
class StockWaitMeltTable extends Component {

    constructor(props){
        super(props)
        this.state = {
            table_width: 800,
        }
    }

    componentDidMount(){
        setTimeout(()=>{
            window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()
          },400)
    }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    
      updateDimensions = () => {
        let elHeight = document.getElementById('table_width2')
        this.setState({ table_width: elHeight.clientWidth });
      };

    render() {
        const items = this.props.items

        return (

            <div id="table_width2">
            <Table
                rowsCount={items.length}
                rowHeight={35}
                headerHeight={30}
                width={this.state.table_width}
                height={300}>
                <Column
                    header={< Cell > %ทอง </Cell>}
                    cell={< ItemsCell position='top left' width={150} data = {
                    items
                }
                field = "category_title" />}
                    width={150}/>
                <Column
                    header={< Cell > ชื่อสินค้า </Cell>}
                    cell={< ItemsCell position='top left' width={250} data = {
                    items
                }
                field = "product_name" />}
                    width={250}/>
                <Column
                    header={< Cell > น้ำหนัก </Cell>}
                    cell={< ItemsCell position='top left' width={100} data = {
                    items
                }
                field = "weight" />}
                    width={100}/>

                <Column
                    header={< Cell className = 'text-right' > ราคา </Cell>}
                    cell={< ItemsCell position='top right' width={150} data = {
                    items
                }
                field = "cost" textAlign = 'text-right' />}
                    width={150}/>
            </Table></div>
        )
    }

}

export default StockWaitMeltTable