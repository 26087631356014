/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Segment, Header, Button, Message, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid, Popup, Label, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        branch: props.auth.branch == undefined ? '' : props.auth.branch.id,
      },
      branches: [],
      items: []
    }
    this.resetForm = this.resetForm.bind(this)
    this.handlerSubmit = this.handlerSubmit.bind(this)
    this.handlerInput = this.handlerInput.bind(this)
  }

  componentDidMount() {

    var branches = Utility.getFetch(Settings.baseReportUrl + '/branches/?is_enabled=1');
    Promise.all([branches]).then((values) => {
      this.branches = values[0];
      let branches = []
      for (let i in this.branches) {
        branches.push({
          value: this.branches[i].id,
          key: this.branches[i].id,
          text: this.branches[i].name
        });
      }
      this.setState({
        branches: branches
      });
    });
  }

  resetForm(e) {

    this.setState({
      search: {
        branch: this.props.auth.branch.id,
      }
    })
  }

  handlerSubmit(e) {
    e.preventDefault();
    let search = Utility.cloneObjectJson(this.state.search)

    let fromData = Utility.jsonToQueryString(search)
    let url = Settings.baseReportUrl + "/report/customer/?" + fromData

    this.setState({
      open: true,
      loading: true
    })
    Utility.get(url, (s, d) => {
      if (s == true) {
        this.setState({
          loading: false,
          items: d.result
        })
      }
    })
  }

  handlerInput(e, v) {
    let search = this.state.search
    search[v.name] = v.value
    this.setState({
      search: search
    })
  }
  render() {
    let data = this.props.data

    let title = 'รายงานลูกค้าในสาขา';
    let filename = 'Customer Report'

    const divStyle = {
      color: '#111',
      'font-size': '10px',
      width: '100%',
      'line-height': '20px',
      'font-family': 'monospace'
    };
    const textRight = {
      'text-align': 'right'
    }
    const textLeft = {
      'text-align': 'left'
    }
    const textCenter = {
      'text-align': 'center'
    }


    let branch_name = ''
    if (this.state.search.branch) {
      let b = Utility.getObjectByValue(this.state.branches, this.state.search.branch)
      if (b)
        branch_name = b.text
    }

    return (<div>
      <Segment color='black' style={{
        height: '90vh',
        overflow: 'auto'
      }}>
        <Form >
          <Form.Group>
            <Form.Field>
              <Header floated='left' as='h4'>รายงานลูกค้าในสาขา</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="box-login">
          <Segment textAlign='left' >

            <Header size='small'>เงื่อนไขค้นหา</Header>
            <Form size='small' onSubmit={this.handlerSubmit}>
              <Form.Field>
                <label>สาขา</label>
                <DropdownBranch fluid size='small' fluid selection onChange={this.handlerInput} name="branch" value={this.state.search.branch} />
              </Form.Field>
              <Button>พิมพ์</Button>
              <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
            </Form>
          </Segment>
        </div>
        <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
          <Modal.Header>Preview</Modal.Header>
          <Modal.Content className='scrolling'>
            {this.state.loading && <Dimmer active={this.state.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}
            <div id='view-print'>
              <div id='extra-wide2'>
                <Table basic id='table-to-xls' style={divStyle}>
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell colSpan='10'><center>{title}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell colSpan='10'><center id='branch'>สาขา : {branch_name}</center></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row >
                      <Table.HeaderCell style={textCenter}>ลำดับ</Table.HeaderCell>
                      <Table.HeaderCell >รหัสลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell >ชื่อลูกค้า</Table.HeaderCell>
                      <Table.HeaderCell >เบอร์มือมือ</Table.HeaderCell>
                      <Table.HeaderCell >อีเมล</Table.HeaderCell>
                      <Table.HeaderCell >อาชีพ</Table.HeaderCell>
                      <Table.HeaderCell >สถานที่ทำงาน</Table.HeaderCell>
                      <Table.HeaderCell >เลขประจำตัวประชาชน</Table.HeaderCell>
                      <Table.HeaderCell style={textCenter} >วันที่ทำรายการครั้งสุดท้าย</Table.HeaderCell>
                      <Table.HeaderCell >หมายเหตุ</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.items.map((row, i) =>
                      <Table.Row >
                        <Table.Cell style={textCenter}>{i + 1}</Table.Cell>
                        <Table.Cell>{row.code}</Table.Cell>
                        <Table.Cell>{row.name}</Table.Cell>
                        <Table.Cell>{row.mobile}</Table.Cell>
                        <Table.Cell>{row.email}</Table.Cell>
                        <Table.Cell>{row.career__name}</Table.Cell>
                        <Table.Cell>{row.workplace__name}</Table.Cell>
                        <Table.Cell>{row.citizen_id}</Table.Cell>
                        <Table.Cell style={textCenter}>{Utility.formatDateTime(row.last_record_date)}</Table.Cell>
                        <Table.Cell>{row.description}</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ui primary button small"
              table="table-to-xls"
              filename={filename}
              sheet={title}
              buttonText="Download as XLS" >
            </ReactHTMLTableToExcel>

            <Button primary icon='print' size='small' onClick={() => {
              var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
              mywindow.document.write('<html><head><title>' + title + '</title>');
              mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
              mywindow.document.write(document.getElementById('view-print').innerHTML);
              mywindow.document.write('</body></html>');
              mywindow.document.close(); // necessary for IE >= 10
              mywindow.focus(); // necessary for IE >= 10*/
              mywindow.print();
              setTimeout(() => {
                mywindow.close();
              }, 2000)
            }} labelPosition='right' content='Print' />

            <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>)
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
    branches: state.branches
  })
}
export default connect(
  mapStateToProps,
)(PrintPreview)