/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Settings from '../../Settings';
import Utility from '../../Utility';
import OptionItemsCell from './OptionItemsCell'
import MsgInput from '../Error/MsgInput'
import { connect } from 'react-redux'

import {
  Form,
  Input,
  Button,
  Icon,
  Modal,
  Confirm, Dimmer, Loader,
  Header,
  Popup
} from 'semantic-ui-react';
import {
  Table,
  Column,
  Cell
} from 'fixed-data-table';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
function collect(props) {
  return { positon: props.positon };
}

class ItemsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowIndex, field, data, ...props } = this.props;
    let width = (this.props.width * 0.9) + 'px'
    var ellipsisStyle = {
      whiteSpace: 'nowrap',
      width: width,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const Width300 = {
      // 'width':'1000px',
      'word-break': 'break-word'
    }
    return (
      <ContextMenuTrigger id="menu_lese_list"
        holdToDisplay={1000}
        key={rowIndex}
        positon={rowIndex}
        collect={collect}>
        <Cell {...props}>
          <Popup
            position={this.props.position}
            content={data[rowIndex][field]}
            style={Width300}
            trigger={<div style={ellipsisStyle} className={this.props.textAlign}>{data[rowIndex][field]}</div>}
          />
        </Cell>
      </ContextMenuTrigger>
    );
  }
}


class Branch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      name: '',
      modal_open: false,
      msg_error: {}
    }
    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  submitData() {
    var msg_error = {}
    if (this.state.name == null || this.state.name == '') {
      msg_error['name'] = ['*ต้องไม่เป็นค่าว่าง']
    }
    if (Object.keys(msg_error).length > 0) {
      this.setState({
        msg_error: msg_error,
        button_class: ''
      })
      return;
    }

    var formData = {
      name: this.state.name,
    };

    const self = this;
    this.setState({ button_class: 'loading' })
    if (this.state.modal_action == 'add') {
      const url = Settings.baseUrl + '/banks/';
      Utility.post(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    } else {
      const url = Settings.baseUrl + '/banks/' + this.state.object_id + "/";
      Utility.put(url, formData, (status, data) => {
        this.setState({ button_class: '' })
        if (status) {
          self.setState({
            modal_open: false
          });
          self.componentDidMount();
          self.resetForm();
          alert('บันทึกข้อมูลสำเร็จ!')
        } else { // error
          if (data.length !== 0)
            this.setState({
              msg_error: data
            })
        }
      });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      button_class: '',
      msg_error: {}
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true
    });
    Utility.get(Settings.baseUrl + '/banks/?is_enabled=1', (status, data) => {
      self.setState({
        items: data,
        loader_active: false
      });
    });
  }
  componentWillMount() {
    setTimeout(()=>{
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions()
    },400)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    let elHeight = document.getElementById('table_width')
    this.setState({ table_width: elHeight.clientWidth });
  };

  handleClick(e, d) {
    let data = this.state.items[d.positon];
    if (d.action == 'edit') {
      this.resetForm();
      this.setState({
        modal_open: true,
        modal_title: 'แก้ไข',
        modal_action: 'edit',
        name: data.name,
        description: data.description,
        object_id: data.id
      })
    } else {

      if (window.confirm('ยืนยันลบ')) {
        const url = Settings.baseUrl + '/banks/' + data.id + "/";
        Utility.delete(url, (status, data, status_code) => {
          if (status) {
            this.componentDidMount();
            this.resetForm();
          } else {
            alert(data.error)
          }
        });
      }
    }
  }

  render() {
    const items = this.state.items;
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h2'>ธนาคาร</Header>
            </Form.Field>
            <Form.Field width={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '130px', textAlign: 'end' }}>
                <Button id='btnAddBank' size='small' content='เพิ่ม' onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  this.setState({
                    modal_open: true,
                    modal_action: 'add',
                    modal_title: 'สร้างรายชื่อธนาคาร'
                  });
                }}
                  disabled={this.props.auth.role !== 'S'}
                  floated='right' icon='plus' labelPosition='right' type='button' primary />
                <small>*สิทธิ์ Super Admin เท่านั้น</small>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>

        <ContextMenu id="menu_lese_list">
          <MenuItem
            data={{ action: 'edit' }}
            onClick={this.handleClick}>แก้ไข</MenuItem>
          <MenuItem
            data={{ action: 'delete' }}
            onClick={this.handleClick}>ลบ</MenuItem>
        </ContextMenu>
        <Dimmer className={this.state.loader_active ? 'active' : ''} inverted>
          <Loader content='Loading' inverted />
        </Dimmer>
        <Modal open={this.state.modal_open} size='tiny' /*dimmer='blurring'*/  >
          <Button
            id='btnCloseBank'
            circular
            icon='close'
            basic
            floated='right'
            name=''
            onClick={() => this.setState({ modal_open: false })} />
          <Modal.Header>{this.state.modal_title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form size='small'>
                <Form.Field error={this.state.msg_error.name != null}>
                  <label>*ชื่อธนาคาร <MsgInput text={this.state.msg_error.name} /></label>
                  <Input id='inputBankName' placeholder='ชื่อธนาคาร' onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} maxLength={255}/>
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button id='btnSaveBank' size='small' primary onClick={this.submitData}
              className={this.state.button_class}>บันทึก</Button>
            <Button id='btnCancelBank' size='small' onClick={(e) => { e.preventDefault(); this.setState({ modal_open: false }); }}>ยกเลิก</Button>
          </Modal.Actions>
        </Modal>
        <div id="table_width">
          <Table
            rowsCount={items.length}
            rowHeight={35}
            headerHeight={35}
            width={this.state.table_width}
            height={387}>
            <Column
              width={80}
              header={
                <Cell ></Cell>
              }
              cell={<OptionItemsCell onClickMenu={this.handleClick} />}
            />
            <Column
              header={<Cell>ชื่อธนาคาร</Cell>}
              cell={
                <ItemsCell position='top left' width={200} id='branchID' data={items} field="name" />
              }
              width={200}
            />
          </Table></div>
        <Form size='small'>
          <Form.Group>
            <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(items.length)} />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Branch)