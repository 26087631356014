/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Button,
  Modal,
  Checkbox
} from 'semantic-ui-react';
import Settings from '../../Settings';
import Utility from '../../Utility';

class SettingModal extends Component {

  constructor(props) {
    super(props)

    this.state = JSON.parse(JSON.stringify(props))
    this.sys_setting_id = props.sys_setting_id
    this.submitSetting = this.submitSetting.bind(this)
    this.handleSeting = this.handleSeting.bind(this)
  }

  handleSeting(e, v) {

    let setting = this.state.setting
    setting[v.name].check = v.checked
    this.setState({
      setting: setting
    })
  }



  submitSetting(e) {
    e.preventDefault()
    const formData = {
      setting: 'CUSF',
      value: JSON.stringify(this.state.setting)
    }

    Utility.put(Settings.baseUrl + "/system_setting/" + this.sys_setting_id + "/", formData, (s, d) => {
      this.props.onClose()
      this.props.onChange(this.state.setting)
      Utility.get(Settings.baseUrl + '/system_setting/', (status, data) => {
        if (status) {
          localStorage.setItem("system_setting", JSON.stringify(data));
        }
      });
    })

  }

  render() {

    return (<Modal size='mini' open={true} id="settingModal"/*dimmer='blurring'*/>
      <Button id="CloseSettingModal"
        circular
        icon='close'
        basic
        floated='right'
        name=''
        onClick={this.props.onClose} />
      <Modal.Header>
        ตั้งค่าข้อมูล
        </Modal.Header>
      <Modal.Content>
        <Form.Field id="customerid" >
          <Checkbox label='รหัสลูกค้า' checked={true} />
        </Form.Field>
        <Form.Field id="customername">
          <Checkbox label='ชื่อ' checked={true} />
        </Form.Field>
        <Form.Field>
          <Checkbox label='เบอร์มือถือ' checked={true} />
        </Form.Field>
        <Form.Field>
          <Checkbox label='อีเมล' checked={true} />
        </Form.Field>
        <Form.Field>
          <Checkbox label='เลขประจำตัวประชาชน' checked={true} />
        </Form.Field>
        <Form.Field id="note">
          <Checkbox label='หมายเหตุ' checked={true} />
        </Form.Field>
        <Form.Field id="address">
          <Checkbox label='ที่อยู่' checked={true} />
        </Form.Field>
        {this.state.setting.map((row, i) =>
          <Form.Field key={i} id={row.key}>
            <Checkbox label={row.label} name={i} checked={row.check} key={i} onChange={this.handleSeting} />
          </Form.Field>)}
      </Modal.Content>
      <Modal.Actions>
        <Button id="settingconfirm" primary onClick={this.submitSetting}>
          บันทึก
          </Button>
        <Button id="settingcancel" onClick={this.props.onClose}>
          ปิด
          </Button>
      </Modal.Actions>
    </Modal>)
  }
}
export default SettingModal;