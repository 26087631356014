/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, {Component} from 'react';
import ImportCSV from './ImportCSV'
import {

} from 'semantic-ui-react';
import Utility from '../../Utility';
import Settings from '../../Settings';

class ImportStaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.setFields([]),
            is_loading: false
        }
        this.handleSubmitData = this.handleSubmitData.bind(this)
    }

    handleSubmitData(data_list){
        this.setState({
            is_loading: true,
            loading_title: '0/'+(data_list.length)
        })
        var position = 0
        let run = ()=>{
            let data = data_list[position]
            let url = Settings.baseUrl + '/stock_product/'
            Utility.post(url,data,(e,res)=>{
                position++;
                if(position<data_list.length)
                    run()
                else
                    this.props.onUpdate()
            })

        }
        run()
    }

    setData(data){

    }

    setFields(branch_options,product_options){
        return [
            {
                name: 'branch',
                title: '*สาขา',
                type: 'string',
                unique: true,
                required: true,
                options: branch_options
            },
            {
                name: 'product',
                title: '*รหัสสินค้า',
                type: 'string',
                unique: true,
                required: true,
                options: product_options
            },
            {
                name: 'amount',
                title: '*จำนวน',
                type: Number,
                unique: true,
                required: true,
            }
        ]
    }

  componentDidMount() {

    var products = Utility.getFetch(Settings.baseUrl + '/products/?is_enabled=1');
    var branches = Utility.getFetch(Settings.baseUrl + '/branches/?is_enabled=1');
    Promise.all([products, branches]).then((values) => {

        var options_branch = [];
        for (var i in values[1]) {
          options_branch.push({
            key: values[1][i].id,
            value: values[1][i].id,
            text: values[1][i].name,
          });
        }

        let product_options = []
        for (var i in values[0]) {
            product_options.push({
              key: values[0][i].id,
              value: values[0][i].id,
              text: values[0][i].code,
            });
          }

        this.setState({
            fields: this.setFields(options_branch,product_options)
        })

    });

  }

    render() {

        return (
            <div>
                <ImportCSV
                    name='StockProduct'
                    fields={this.state.fields}
                    onClose={this.props.onClose}
                    onSubmitData={this.handleSubmitData}
                    loading={this.state.is_loading}
                    loading_title={this.state.loading_title}
                 />
            </div>
        )
    }
}

export default ImportStaff